import ApiService from '../../../../services/api.service';

class SettingEmailContentService extends ApiService {
  name= 'SettingEmailContent';
  path= 'setting/emailContent/page';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api = {
    getByCompany: async (companyId)=> {
      return await this.http.get(`${this.apiLocation}/setting/emailContent/company/`+companyId)
    },
    updateByCompany: async (formData)=> {
      return await this.http.put(`${this.apiLocation}/setting/emailContent`, formData)
    }
  }
}

export default new SettingEmailContentService();
