import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Switch, Button, Chip} from 'react-md';

//import FormDialog from './Organization.dialog.view';
import BP21A1Service from './BP_21_A1.service';
import UtilService from '../../../../../services/utils.service';
import ListView from '../../../../../components/entity/listView';
import download from 'downloadjs';
import uuid from 'uuid';
import iziToast from 'izitoast';
import ListViewSpt from '../../../../../components/entity/listViewSpt';

@connect((state)=> ({
  ...BP21A1Service.stateConnectSetting()(state),
  spt: state.auth.currentSPT
}), BP21A1Service.actionConnectSetting())
export default class BP21A1View extends ListViewSpt {
  service=BP21A1Service
  FormDialog=()=> <div/>

  constructor(props){
    super(props);
    this.state = {
      ...this.state,
      total: {},
      cetak: false
    }
  }

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='Nama'
          name='ltNama__equals'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='NPWP'
          name='ltNpwp__equals'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Email'
          name='ltEmail__equals'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='NIK'
          name='ltNik__equals'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Nomor Bukti Potong'
          name='h01__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Kode Pajak'
          name='kodeObjekPajak__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Pembuat'
          name='createdUserLogin__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
      </this.SearchContainer>
    )
  }

  addDialog=false
  editDialog=false

  fetchOption() {
    return {
      path: BP21A1Service.path+'/'+this.props.spt.data.id+'/page'
    }
  }

  columns=[
    {isSortable: true, label: "word.npwp", searchField: "ltNpwp", value: "bagA.npwp", show:true, isSearchable:true},
    {isSortable: true, label: "word.nik", searchField: "ltNik", value: "bagA.nik", show:true, isSearchable:true},
    {isSortable: true, label: "word.name", searchField: "ltNama", value: "bagA.nama", show:true, isSearchable:true},
    /*{isSortable: true, label: "word.email", searchField: "ltEmail", value: "bagA.email", show:true, isSearchable:true},*/
    {isSortable: true, label: "word.email", searchField: "ltEmail", type: "func",  value: (d) => {
        let email = d.bagA.email
        if (email) {
          email = email.replace('<', '(')
          email = email.replace('>', ')')
        }
        return email
    }, isDefaultSort:false, show:true, isSearchable:true},
    {isSortable: true, label: "word.noBp", searchField: "h01", value: "no", show:true, isSearchable:true},
    {isSortable: true, label: "word.masaAwal",  searchField: "h02Month1", value: "masaPerolehanAwal", show:true, isSearchable:true, type: 'number'},
    {isSortable: true, label: "word.masaAkhir",  searchField: "h02Month2", value: "masaPerolehanAkhir", show:true, isSearchable:true, type: 'number'},
    {isSortable: true, label: "word.date", searchField: "bagC.c03", value: "bagC.tanggal", show:true, isSearchable:true},
    {isSortable: true, label: "word.taxCode", searchField: "kodeObjekPajak", value: "bagB.kodeObjekPajak", show:true, isSearchable:true},
    {isSortable: true, label: "word.bruto", searchField: "bagB.b08", value: "bagB.jmlBruto", show:true, isSearchable:false, type: 'number'},
    {isSortable: true, label: "word.pph", searchField: "bagB.b19", value: "bagB.pph21Terutang", show:true, isSearchable:false, type: 'number'},
    {isSortable: true, label: "word.reference", value: "referensi", show:false, isSearchable:true},
    {isSortable:true, label: "word.createdBy", type: "func", value: (value) => {
      if (value.createdUser) {
        let {login} = value.createdUser

        if (login !== 'system') {
          return login
        }
      }

      return value.createdBy
    }, show:true, isSearchable:true},
    {isSortable: true, label: "word.createdDate",  value:"createdDate", type:"date", show:true, isSearchable:true},
  ]

  async componentDidMount() {
    this.rootPath = this.props.match.url;

    var sptId = this.props.spt.data.id
    var total = await this.service.api.total(sptId)
    this.setState({
      total: total
    })
  }

  totalBruto() {
    var total = 0;
    if(this.state.total.bruto){
      total = this.state.total.bruto;
    }
    return total;
  }

  totalPph() {
    var total = 0;
    if(this.state.total.pph){
      total = this.state.total.pph;
    }
    return total;
  }

  async handleCetak(){
    this.setState({ cetak: true })
    if(this.props.table.selected.length === 0){
      iziToast.warning({
        title: 'Pemberitahuan !',
        message: 'Pilih salah satu data yang akan dicetak',
      });
      this.setState({ cetak: false })
    } else {
      try {
        var selected = this.props.table.selected
        var ids = [];
        selected.map(function (i) {
          ids.push(i.id)
        })

        var sptId = this.props.spt.data.id
        var report = await this.service.api.cetakBulk(sptId, ids)
        var filename = report.headers.filename
        download(report.data, filename);
        this.setState({cetak: false})
      } catch (e) {
        const reader = new FileReader();

        /// This fires after the blob has been read/loaded.
        reader.addEventListener('loadend', (e) => {
          const text = e.srcElement.result
          let json = JSON.parse(text)

          if (json.detail)
            iziToast.error({
              title: 'Error',
              message: json.detail
            })
        });

        reader.readAsText(e.response.data)

        this.setState({cetak: false})
      }
    }
  }

  async handleSendEmail(){
    try {
      let category = "BP_21_A1"
      var selected = this.props.table.selected
      var ids = [];
      selected.map(function (i){
          ids.push(i.id)
      })
      var sptId = this.props.spt.data.id
      await this.service.api.emailSpt(category, sptId, ids)
      iziToast.info({
        title: "Kirim Email",
        message: "email sedang diproses silahkan cek log untuk lebih detil"
      })
    } catch (e){
      iziToast.warning({
        title: e.name,
        message: e.message
      })
    }
  }

  async deleteSelectedDataItems(){
    var selected = this.props.table.selected

    var ids = [];

    selected.map(function (i){
      ids.push(i.id)
    })

    const sptId = this.props.match.params.sptId

    var res = await this.service.api.deleteBulk(sptId, ids)

    this.fetchData()
  }

  _barItem() {
    return (
      <div>
        <Button secondary raised style={{margin: 5}} onClick={this.handleSendEmail.bind(this)}>Kirim</Button>
        <Button disabled={this.state.cetak} primary raised style={{margin: 5}} onClick={this.handleCetak.bind(this)}>Cetak</Button>
        <Switch
          style={{float: "right"}}
          id="switch-table-setting"
          name="Switch Table Setting"
          label=""
          checked={this.state.showTableSetting}
          onChange={() => {
            this.setState({
              showTableSetting:!this.state.showTableSetting
            })
          }}
        />
      </div>
    )
  }

  footer() {
    return <div className="flex-none mpk-border thin solid top dark">
      <div className="mpk-padding-N left right">
        <table style={{width: '100%'}}>
          <tfoot className="md-table-footer">
            <tr className="md-table-column md-text-left md-table-column--data md-table-column--adjusted md-text ">
              <td>Total Jumlah Penghasilan Bruto</td>
              <td className=" md-text-right">{UtilService.number.format(this.totalBruto(), {precision: 0, thousand: '.', decimal: ','})}</td>
            </tr>
            <tr className="md-table-column md-text-left md-table-column--data md-table-column--adjusted md-text ">
              <td>Total Jumlah PPH Dipotong</td>
              <td className=" md-text-right">{UtilService.number.format(this.totalPph(), {precision: 0, thousand: '.', decimal: ','})}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  }

}
