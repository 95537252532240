import ApiService from "../../../../../../services/api.service";

class Master15TarifService extends ApiService {
  name= 'Master15Tarif';
  path= 'spt15/master/tarif';

  constructor() {
    super();
    this.init()
  }

  api = {
    byCompany: async (data) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/company`, {
        params: data
      });
      return res.data;
    },

    getMaster: async () => {
      var res = await this.http.get(`${this.apiLocation}/spt15/master/tarif`);

      return res.data;
    }
  }
}


export default new Master15TarifService();
