import React from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  Tab,
  Tabs,
  SelectionControlGroup,
  Button
} from 'react-md';
import download from 'downloadjs';
import uuid from 'uuid';
import iziToast from 'izitoast';

import FormView from '../../../../../components/entity/form.view';
import {validation, Textfield, Searchfield, Switch, Checkbox, Datepicker, TextfieldMask} from '../../../../../components/form';
import columnService from '../../../../../services/column.service';
import terbilangService from '../../../../../services/terbilang.service';
import Spt22IndukService from './SPT22.induk.service';


@reduxForm({form: 'SPT_INDUK_22_Form', destroyOnUnmount: true, initialValues: {jasaLains: []}})
@connect((state) => ({
  access      : state.entity.access.api.find,
  organization: state.auth.currentOrganization,
  spt         : state.auth.currentSPT,
  formData    : getFormValues('SPT_INDUK_22_Form')(state)
}))
export default class Spt22IndukForm extends FormView {
  service  = Spt22IndukService
  viewType = 2;

  initialData={
    bagB1: {},
    bagB2: {},
    bagC : {},
  }

  constructor(props){
    super(props);
    this.state = {
      ...this.state,
      cetak    : false,
      calculate: false,

      signer: {},
    }
  }

  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      let value = this.props.formData
      this.handleSave(value)
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  async handleSave(value) {
    try {
      this.setState({onProgress: true})

      var res                            = await this.service.api.update(value)
      if  (!res.data.bagD) res.data.bagD = {}

      // signer
      let signer = null;
      try {
        signer = await this.service.api.getSigner({
          orgId: this.props.spt.data.organization.id,
          tgl  : res.data.tgl
        });
      } catch (error) {

      }
      if(signer) {
        this.setState({signer: signer})
        if(res.data.bagD.kuasa) {
          res.data.bagD.npwp = signer.npwpKuasa;
          res.data.bagD.nama = signer.namaKuasa;
        } else {
          res.data.bagD.npwp = signer.npwpPenandaTanganSpt;
          res.data.bagD.nama = signer.namaPenandaTanganSpt;
        }
      }

      this.props.initialize(res.data);

      this.setState({onProgress: false})
      this.toast.success({title: 'Success', message: 'Saved'})
    } catch(e) {
      var msg              = e.message;
      if  (e.response) msg = e.response.data.message;
      this.setState({
        isError     : true,
        onProgress  : false,
        errorMessage: msg
      })
    }
  }

  async initData() {
    let res = await this.service.api.getBySptId(this.props.spt.data.id);

    if(!res.data.bagD) res.data.bagD = {}

    // signer
    let signer = null;
    try {
      signer = await this.service.api.getSigner({
        orgId: this.props.spt.data.organization.id,
        tgl  : res.data.tgl
      });
    } catch (error) {

    }
    if(signer) {
      this.setState({signer: signer})
      if(res.data.bagD.kuasa) {
        res.data.bagD.npwp = signer.npwpKuasa;
        res.data.bagD.nama = signer.namaKuasa;
      } else {
        res.data.bagD.npwp = signer.npwpPenandaTanganSpt;
        res.data.bagD.nama = signer.namaPenandaTanganSpt;
      }
    }

    this.props.initialize(res.data);
  }

  async changeSigner() {
    if(this.state.signer.id) {
      let formData                       = this.initialData
      if  (this.props.formData) formData = this.props.formData;
      if(formData.bagD.kuasa) {
        this.props.change('bagD.npwp', this.state.signer.npwpKuasa);
        this.props.change('bagD.nama', this.state.signer.namaKuasa);
      } else {
        this.props.change('bagD.npwp', this.state.signer.npwpPenandaTanganSpt);
        this.props.change('bagD.nama', this.state.signer.namaPenandaTanganSpt);
      }
    }
  }

  async calculate() {
    await this.setState({calculate: true})
    await this.props.dispatch({
      type: 'TOGGLE_GLOBAL_PROGRESS',
      data: true
    });

    const sptId = this.props.match.params.sptId

    try {
      var res = await this.service.api.calculate(sptId)

      if(res.data) {
        this.props.initialize(res.data);
      }
    } catch(e) {

    }


    this.setState({calculate: false})
    await this.props.dispatch({
      type: 'TOGGLE_GLOBAL_PROGRESS',
      data: false
    });
  }

  async handleCetak(){
    this.setState({ cetak: true })
    try {
      const sptId    = this.props.match.params.sptId
      var   report   = await this.service.api.cetak(sptId)
      var   filename = report.headers.filename;
      download(report.data, filename);
      this.setState({ cetak: false })
    } catch (e) {
      const reader = new FileReader();

      /// This fires after the blob has been read/loaded.
      reader.addEventListener('loadend', (e) => {
        const text = e.srcElement.result
        let json = JSON.parse(text)

        if (json.detail)
          iziToast.error({
            title: 'Error',
            message: json.detail
          })
      });

      reader.readAsText(e.response.data)

      this.setState({cetak: false})
    }
  }

  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;
    let   statusSPT                                     = "";
    let   statusInSPT                                   = false;
    if(this.props.spt){
        if(this.props.spt.data){
            if(this.props.spt.data.status){
                statusSPT   = this.props.spt.data.status;
                statusInSPT = true
            }
        }
    }

    if(statusSPT == "FINISH" || statusSPT == "WAITING"){
        return (
            <div>
              <Button disabled={this.state.cetak} primary raised style={{marginRight: 5}} onClick={this.handleCetak.bind(this)}>Cetak</Button>
            </div>
        )
    } else {
        if(statusInSPT){
            return (
              <div>
                <Button disabled={this.state.cetak} primary raised style={{marginRight: 5}} onClick={this.handleCetak.bind(this)}>Cetak</Button>
                {/* <Button disabled={this.state.calculate} primary raised style={{marginRight: 5}} onClick={this.calculate.bind(this)}>calculate</Button> */}
                <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>Save and Calculate</Button>
              </div>
            )
        } else {
            return (
                <div>
                    {/* <Button disabled={this.state.calculate} primary raised style={{marginRight: 5}} onClick={this.calculate.bind(this)}>calculate</Button> */}
                    <Button secondary flat onClick={()=> {this.props.history.goBack()}} style={{marginRight: 16}}>Back</Button>
                </div>
            )
        }
    }
  }

  async calculateTotal(e,v,pv,f) {
    await this.props.change(f, v);

    var form = this.props.formData;

    var jmlPph   = 0;
    var jmlBruto = 0;

    Object.keys(form).forEach((k, i) => {
      if(k.startsWith("nop")) {
        var x = form[k];

        var bruto          = x;
        if  (!bruto) bruto = 0;

        jmlBruto = jmlBruto+ parseFloat(bruto)
      }

      if(k.startsWith("pph")) {
        var x = form[k];

        var pph            = x;
        if  (!pph) pph     = 0;

        jmlPph   = jmlPph+ parseFloat(pph);
      }
    })

    jmlBruto = Math.floor(jmlBruto)
    jmlPph = Math.floor(jmlPph)

    this.props.change('jmlNop', jmlBruto)
    this.props.change('jmlPph', jmlPph);

    this.props.change('terbilang', terbilangService(parseInt(jmlPph)));
  }

  formView() {
    let formData                                                     = this.initialData
    if  (this.props.formData && this.props.formData.values) formData = this.props.formData.values

    var masaOptions = [
      {id:1, name: 1}, {id:2, name: 2}, {id:3, name: 3}, {id:4, name: 4}, {id:5, name: 5}, {id:6, name: 6},
      {id:7, name: 7}, {id:8, name: 8}, {id:9, name: 9}, {id:10, name: 10}, {id:11, name: 11}, {id:12, name: 12}
    ]
    return (
      <div>
        <Card>
          {/* <CardTitle title='' style={{padding: '10px 16px'}} subtitle='A. IDENTITAS PEMOTONG' />
          <Divider/> */}

          <div className='md-grid'>
            <Field
              label        = 'Tanggal Lapor'
              className    = "md-cell md-cell--12"
              name         = 'tgl'
              component    = {Datepicker}
              validate     = {validation.required}
              onDateChange = { async (d)=> {
                let signer = null;

                try {
                  signer = await this.service.api.getSigner({
                    orgId: this.props.spt.data.organization.id,
                    tgl  : moment(d).format("YYYY-MM-DD")
                  })

                  await this.setState({signer: signer})
                } catch (error) {
                }

                await this.changeSigner()
              }}
              />
          </div>
        </Card>
        <br/>

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='PPH PASAL 22 YANG TELAH DIPOTONG' />
          <Divider/>
          <DataTable plain fixedScrollWrapperStyle={{overflow: 'inherit'}} style={{overflow: 'inherit'}}>
            <TableHeader>
              <TableRow>
                <TableColumn>No.</TableColumn>
                <TableColumn>Uraian</TableColumn>
                <TableColumn>MAP/KJS</TableColumn>
                <TableColumn className='md-text-right'>Nilai Objek Pajak (Rp)</TableColumn>
                <TableColumn className='md-text-right'>PPH Yang Dipungut (Rp)</TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableColumn>1.</TableColumn>
                <TableColumn>Badan Usaha Industri/Eksportir</TableColumn>
                <TableColumn>
                411122/100
                </TableColumn>
                <TableColumn>
                  <Field
                    name = 'nop14'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'pph15'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>2.</TableColumn>
                <TableColumn>Penjualan Barang yang Tergolong Sangat Mewah</TableColumn>
                <TableColumn>
                411122/403
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'nop24'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'pph25'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>3.</TableColumn>
                <TableColumn>Pembelian Barang Oleh Bendaharawan/Badan Tertentu Yang Ditunjuk</TableColumn>
                <TableColumn>
                411122/100
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'nop34'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'pph35'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>4.</TableColumn>
                <TableColumn>Nilai Impor Bank Devisa/Ditjen Bea Cukai</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>a. Nilai Impor Bank Devisa/Ditjen Bea dan Cukai API</TableColumn>
                <TableColumn>
                411122/100
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'nop44a'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'pph45a'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>b. Nilai Impor Bank Devisa/Ditjen Bea dan Cukai Non API</TableColumn>
                <TableColumn>
                411122/100
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'nop44b'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'pph45b'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>5.</TableColumn>
                <TableColumn>Hasil Lelang (Ditjen Bea dan Cukai)</TableColumn>
                <TableColumn>
                411122/100
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'nop54'
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                    onChange  = {(e, v, vp, f) => this.calculateTotal(e, v, vp, f, 'nop54')}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'pph55'
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                    onChange  = {(e, v, vp, f) => this.calculateTotal(e, v, vp, f, 'pph55')}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>6.</TableColumn>
                <TableColumn>Penjualan Migas Oleh Pertamina/Badan Usaha Selain Pertamina</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>a. Penjualan Migas Oleh Pertamina / Badan Usaha Selain Pertamina SPBU/Agen/Penyalur (Final)</TableColumn>
                <TableColumn>
                411122/401
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'nop64a'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'pph65a'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>b. Penjualan Migas Oleh Pertamina / Badan Usaha Selain Pertamina SPBU/Agen/Penyalur/ (Tidak Final)</TableColumn>
                <TableColumn>
                411122/100
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'nop64b'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'pph65b'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>7.</TableColumn>
                <TableColumn><Field
                    // label='Tarif'
                    name = 'uraian7'
                    component = {Textfield}
                  /></TableColumn>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'nop7'
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                    onChange  = {(e, v, vp, f) => this.calculateTotal(e, v, vp, f, 'nop7')}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'pph7'
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                    onChange  = {(e, v, vp, f) => this.calculateTotal(e, v, vp, f, 'pph7')}
                  />
                </TableColumn>
              </TableRow>

              <TableRow style={{background: '#ddd'}}>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  Jumlah
                </TableColumn>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name      = 'jmlNop'
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    disabled
                    money = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'jmlPph'
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    disabled
                    money = {','}
                  />
                </TableColumn>
              </TableRow>



              <TableRow style={{background: '#ddd'}}>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  Terbilang
                </TableColumn>
                <TableColumn colSpan={3}>
                  <Field
                    // label='Bruto'
                    name      = 'terbilang'
                    component = {Textfield}
                    disabled
                    money = {','}
                  />
                </TableColumn>
              </TableRow>
            </TableBody>

          </DataTable>
        </Card>

        <br/>


         <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='LAMPIRAN' />
          <Divider/>
          <DataTable plain fixedScrollWrapperStyle={{overflow: 'inherit'}} style={{overflow: 'inherit'}}>
            <TableHeader>
              <TableRow>
                <TableColumn>Cek</TableColumn>
                <TableColumn>Keterangan</TableColumn>
                <TableColumn>Lembar</TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody>

              <TableRow>
                <TableColumn>
                  <Field
                    name      = 'c1'
                    component = {Checkbox}
                  />
                </TableColumn>
                <TableColumn>
                  Daftar Surat Setoran Pajak PPh Pasal 22 (Khusus untuk Bank Devisa, Bendaharawan/Badan Tertentu Yang Ditunjuk, dan Pertamina/Badan Usaha Selain Pertamina)
                </TableColumn>
                <TableColumn>
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  <Field
                    name      = 'c2'
                    component = {Checkbox}
                    disabled
                  />
                </TableColumn>

                <TableColumn>
                Surat Setoran Pajak (SSP) yg disetor oleh Importir atau Pembeli Barang (Khusus untuk Bank Devisa,Bendaharawan/Badan Tertentu yg Ditunjuk, dan Pertamina/Badan Usaha Selain Pertamina)
                </TableColumn>
                <TableColumn>
                  <Field
                    name      = 'qty2'
                    component = {Textfield}
                    disabled
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  <Field
                    name      = 'c3'
                    component = {Checkbox}
                    disabled
                  />
                </TableColumn>

                <TableColumn>
                SSP yang disetor oleh Pemungut Pajak (Khusus untuk Badan Usaha Industri/Eksportir Tertentu, Ditjen Bea dan Cukai)
                </TableColumn>
                <TableColumn>
                  <Field
                    name      = 'qty3'
                    component = {Textfield}
                    disabled
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  <Field
                    name      = 'c4'
                    component = {Checkbox}
                  />
                </TableColumn>

                <TableColumn>
                Daftar Bukti Pemungutan PPh Pasal 22 (Khusus untuk Badan Usaha Industri/Eksportir Tertentu dan Ditjen Bea dan Cukai)
                </TableColumn>
                <TableColumn></TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  <Field
                    name      = 'c5'
                    component = {Checkbox}
                  />
                </TableColumn>

                <TableColumn>
                Bukti Pemungutan PPh Pasal 22 (Khusus untuk Badan Usaha Industri/Eksportir Tertentu dan Ditjen Bea Cukai)
                </TableColumn>
                <TableColumn></TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  <Field
                    name      = 'c6'
                    component = {Checkbox}
                  />
                </TableColumn>

                <TableColumn>
                Daftar rincian penjualan dan retur penjualan (dalam hal ada penjualan retur)
                </TableColumn>
                <TableColumn></TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  <Field
                    name      = 'c7'
                    component = {Checkbox}
                  />
                </TableColumn>

                <TableColumn>
                Risalah Lelang (Dalam Hal Pelaksanaan Lelang)
                </TableColumn>
                <TableColumn></TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  <Field
                    name      = 'c8'
                    component = {Checkbox}
                  />
                </TableColumn>

                <TableColumn>
                Surat Kuasa Khusus
                </TableColumn>
                <TableColumn></TableColumn>
              </TableRow>

            </TableBody>
          </DataTable>
        </Card>


        <br />


      </div>
    )
  }
}
