import ApiService from '../../../../services/api.service';

class MasterLawanTransaksiA1Service extends ApiService {
  name= 'masterLawanTransaksiA1';
  path= 'master/spt21/lt/a1';

  constructor() {
    super();
    this.init()
  }

  api = {
    findOrCreate: async (data)=> {
      var res = await this.http.post(this.apiLocation+'/'+this.path+'/findOrCreate', data)
      return res.data;
    },
    downloadTemplate: async ()=> {
      var res = await this.http.get(this.apiLocation+'/'+this.path+'/download-template');
      return res;
    },
  }
}


export default new MasterLawanTransaksiA1Service();
