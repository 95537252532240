import ApiService from '../../../../../services/api.service';

class LogExportDataInquiryService extends ApiService {
  name= 'LogExportDataInquiry';
  path= 'ebilling/log-export-inquiry';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api= {
    download: async (id) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/download/${id}`, {
        responseType: 'blob'
      })

      return res;
    },

    delete: async (id) => {
      var res = await this.http.delete(`${this.apiLocation}/${this.path}/delete/${id}`)

      return res.data;
    },

    deleteSelected: async (ids) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/delete`, ids)

      return res.data;
    },
  }
}


export default new LogExportDataInquiryService();
