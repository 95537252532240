import * as CONSTANT from '../../config/constant';
import env from 'env'

const mainMenusBupot = [{
  label: {
    id: 'Main',
    en: 'Main'
  },
  children: [{
    label: {
      id: 'Home',
      en: 'Home'
    },
    iconClassName: 'mdi mdi-view-grid',
    path: '/dashboard',
  }]
},
  // ORGANISASI
  {
    label: {
      id: 'Perusahaan',
      en: 'Company'
    },
    children: [{
      label: {
        id: 'Perusahaan',
        en: 'Company'
      },
      iconClassName: 'mdi mdi-contacts',
      path: '/company',
      admin: true,
      level: CONSTANT.LEVEL.ROLE_OWNER
    },{
      label: {
        id: 'Organisasi',
        en: 'Organization'
      },
      iconClassName: 'mdi mdi-contacts',
      path: '/organization',
      admin: true,
      level: CONSTANT.LEVEL.ROLE_OWNER
    }]
  },

  // IMPORT USER
  {
    label: {
      id: 'Import',
      en: 'Import'
    },
    children: [
      {
        label: {
          id: 'Organisasi',
          en: 'Organization'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/ORGANIZATION',
        // permission: 'IMPORT_BP_21_TF',
        level: CONSTANT.LEVEL.ROLE_OWNER,
        owner: true
      },
      {
        label: {
          id: 'Pengguna',
          en: 'User'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/USER',
        // permission: 'IMPORT_BP_21_TF',
        level: CONSTANT.LEVEL.ROLE_OWNER,
        owner: true
      },
      {
        label: {
          id: 'User Organisasi',
          en: 'Organization User'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/ORGANIZATION/USER',
        // permission: 'IMPORT_BP_21_TF',
        level: CONSTANT.LEVEL.ROLE_OWNER,
        owner: true
      },
      {
        label: {
          id: 'Riwayat KPP Organisasi',
          en: 'Riwayat KPP Organisasi'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/ORGANIZATION/KPP',
        // permission: 'IMPORT_BP_21_TF',
        level: CONSTANT.LEVEL.ROLE_OWNER,
        owner: true
      },
      {
        label: {
          id: 'Penandatangan PPh 21',
          en: 'Signer PPh 21'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/SIGNER21',
        // permission: 'IMPORT_BP_21_TF',
        level: CONSTANT.LEVEL.ROLE_OWNER,
        owner: true
      },
      {
        label: {
          id: 'Penandatangan PPh 23',
          en: 'Signer PPh 23'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/SIGNER23',
        // permission: 'IMPORT_BP_21_TF',
        level: CONSTANT.LEVEL.ROLE_OWNER,
        owner: true
      },
      {
        label: {
          id: 'Penandatangan PPh 4(2)',
          en: 'Signer PPh 4(2)'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/SIGNER4A2',
        // permission: 'IMPORT_BP_21_TF',
        level: CONSTANT.LEVEL.ROLE_OWNER,
        owner: true
      },
      {
        label: {
          id: 'Penandatangan PPh 22',
          en: 'Signer PPh 22'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/SIGNER22',
        // permission: 'IMPORT_BP_21_TF',
        level: CONSTANT.LEVEL.ROLE_OWNER,
        owner: true
      },
      {
        label: {
          id: 'Penandatangan PPh 15',
          en: 'Signer PPh 15'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/SIGNER15',
        // permission: 'IMPORT_BP_21_TF',
        level: CONSTANT.LEVEL.ROLE_OWNER,
        owner: true
      },
      /*{
        label: {
          id: 'Import eFiling',
          en: 'Import eFiling'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/EFILING',
        // permission: 'IMPORT_BP_21_TF',
        level: CONSTANT.LEVEL.ROLE_OWNER,
        owner: true
      },*/
      {
        label: {
          id: 'Import Unit',
          en: 'Import Unit'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/UNIT',
        // permission: 'IMPORT_BP_21_TF',
        level: CONSTANT.LEVEL.ROLE_OWNER,
        owner: true
      },
      {
        label: {
          id: 'Import Credential eBilling',
          en: 'Import Credential eBilling'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/CREDENTIAL_EBILLING',
        // permission: 'IMPORT_BP_21_TF',
        level: CONSTANT.LEVEL.ROLE_OWNER,
        owner: true
      },
    ]
  },
  // END IMPORT USER

  // ORGANISASI OWNER
  {
    label: {
      id: 'Organisasi',
      en: 'Organization'
    },
    children: [{
      label: {
        id: 'Organisasi',
        en: 'Organization'
      },
      iconClassName: 'mdi mdi-view-list',
      rootPath: '/company',
      path: '/organization',
      permission: 'organization',
      owner: true
    },{
      label: {
        id: 'User Organisasi',
        en: 'Organization User'
      },
      iconClassName: 'mdi mdi-contacts',
      rootPath: '/company',
      path: '/organizationUser',
      owner: true
    },{
      label: {
        id: 'Flow Approval',
        en: 'Flow Approval'
      },
      iconClassName: 'mdi mdi-contacts',
      path: '/mainFlow',
      owner: true
    },{
      label: {
        id: 'Managemen File',
        en: 'File Management'
      },
      iconClassName: 'mdi mdi-contacts',
      path: '/file',
      owner: true
    },{
      label: {
        id: 'Riwayat KPP Organisasi',
        en: 'Riwayat KPP Organisasi'
      },
      iconClassName: 'mdi mdi-contacts',
      rootPath: '/company',
      path: '/organizationKpp',
      owner: true
    }]
  },
  // SIGNER OWNER
  {
    label: {
      id: 'Daftar Signer',
      en: 'Daftar Signer'
    },
    children: [{
      label: {
        id: 'Pasal 21',
        en: 'Pasal 21'
      },
      iconClassName: 'mdi mdi-contacts',
      rootPath: '/company',
      path: '/signer21',
      owner: true
    },{
      label: {
        id: 'Pasal 23',
        en: 'Pasal 23'
      },
      iconClassName: 'mdi mdi-contacts',
      rootPath: '/company',
      path: '/signer23',
      owner: true
    },{
      label: {
        id: 'Pasal 4 (2)',
        en: 'Pasal 4 (2)'
      },
      iconClassName: 'mdi mdi-contacts',
      rootPath: '/company',
      path: '/signer4a2',
      owner: true
    },{
      label: {
        id: 'Pasal 22',
        en: 'Pasal 22'
      },
      iconClassName: 'mdi mdi-contacts',
      rootPath: '/company',
      path: '/signer22',
      owner: true
    },{
      label: {
        id: 'Pasal 15',
        en: 'Pasal 15'
      },
      iconClassName: 'mdi mdi-contacts',
      rootPath: '/company',
      path: '/signer15',
      owner: true
    },]
  },
   // Key
   {
    label: {
      id: 'Key',
      en: 'Key'
    },
    children: [{
      label: {
        id: 'SPT 21',
        en: 'SPT 21'
      },
      iconClassName: 'mdi mdi-view-list',
      // rootPath: '/company',
      path: '/key/21',
      permission: 'organization',
      owner: true
    },
    {
      label: {
        id: 'SPT 22',
        en: 'SPT 22'
      },
      iconClassName: 'mdi mdi-view-list',
      // rootPath: '/company',
      path: '/key/22',
      permission: 'organization',
      owner: true
    },
    {
      label: {
        id: 'SPT 23/26',
        en: 'SPT 23/26'
      },
      iconClassName: 'mdi mdi-view-list',
      // rootPath: '/company',
      path: '/key/23',
      permission: 'organization',
      owner: true
    },
    {
      label: {
        id: 'SPT 4(2)',
        en: 'SPT 4(2)'
      },
      iconClassName: 'mdi mdi-view-list',
      // rootPath: '/company',
      path: '/key/4a2',
      permission: 'organization',
      owner: true
    },
    {
      label: {
        id: 'SPT 15',
        en: 'SPT 15'
      },
      iconClassName: 'mdi mdi-view-list',
      // rootPath: '/company',
      path: '/key/15',
      permission: 'organization',
      owner: true
    },
    // {
    //   label: {
    //     id: 'SPT 15',
    //     en: 'SPT 15'
    //   },
    //   iconClassName: 'mdi mdi-view-list',
    //   // rootPath: '/company',
    //   path: '/key/15',
    //   permission: 'organization',
    //   owner: true
    // }
  ]
  },
  // Key
  {
    label: {
      id: 'Data Unit',
      en: 'Data Unit'
    },
    children: [{
      label: {
        id: 'Unit',
        en: 'Unit'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/unit',
      // permission: 'organization',
      owner: true
    }]
  },
  //
  {
    label: {
      id: 'Pengguna',
      en: 'User'
    },
    children: [{
      label: {
        id: 'Pengguna',
        en: 'User'
      },
      rootPath: '/company',
      iconClassName: 'mdi mdi-view-list',
      path: '/user',
      permission: 'user',
      owner: true
    },{
      label: {
        id: 'Pemilik',
        en: 'Owner'
      },
      iconClassName: 'mdi mdi-view-list',
      rootPath: '/company',
      path: '/owner',
      permission: 'owner',
      owner: true
    },{
      label: {
        id: 'Daftar User Login ',
        en: 'Logged In List'
      },
      owner: true,
      iconClassName: 'mdi mdi-settings',
      path: '/logged-in',
      permission: 'SETTING_EMAIL'
    },{
      label: {
        id: 'Daftar User Login',
        en: 'Logged In List'
      },
      admin: true,
      iconClassName: 'mdi mdi-settings',
      path: '/logged-in',
      permission: 'SETTING_EMAIL'
    },]
  },
  // END ORGANISASI OWNER


  // PPH21
  {
    label: {
      id: 'PPh 21',
      en: 'PPh 21'
    },
    children: [{
      label: {
        id: 'Daftar SPT',
        en: 'SPT List'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/SPT_21',
      permission: 'SPT_21',
      level: CONSTANT.LEVEL.ROLE_USER,
      user: true
    },
      {
        label: {
          id: 'BP Final',
          en: 'BP Final'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/BP_21_F',
        permission: 'BP_21_F',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      {
        label: {
          id: 'BP Tidak Final',
          en: 'BP Tidak Final'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/BP_21_TF',
        permission: 'BP_21_TF',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      {
        label: {
          id: 'BP 1721 A1',
          en: 'BP 1721 A1'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/BP_21_A1',
        permission: 'BP_21_A1',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      {
        label: {
          id: 'BP 1721 A2',
          en: 'BP 1721 A2'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/BP_21_A2',
        permission: 'BP_21_A2',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      {
        label: {
          id: 'BP Satu Masa',
          en: 'BP Satu Masa'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/ALL_BP_21_SATU_MASA',
        permission: 'BP_21_SATU_MASA',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      {
        label: {
          id: 'BP Satu Tahun',
          en: 'BP Satu Tahun'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/BP_21_SATU_TAHUN',
        permission: 'BP_21_SATU_TAHUN',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      // {
      //   label: {
      //     id: 'Daftar Biaya',
      //     en: 'Daftar Biaya'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/DAFTAR_BIAYA_21',
      //   permission: 'DAFTAR_BIAYA_21',
      //   level: CONSTANT.LEVEL.ROLE_USER,
      //   // decemberOnly: true,
      //   user: true
      // },
      {
        label: {
          id: 'Daftar SSP',
          en: 'Daftar SSP'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/ALL_BP_21_SSP',
        permission: 'DAFTAR_SSP_21',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      // {
      //   label: {
      //     id: 'SPT Induk',
      //     en: 'SPT Induk'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/SPT_INDUK_21',
      //   permission: 'SPT_INDUK_21',
      //   level: CONSTANT.LEVEL.ROLE_USER,
      //   user: true
      // }
    ]
  },
  {
    label: {
      id: 'PPh 23/26',
      en: 'PPh 23/26'
    },
    children: [{
      label: {
        id: 'Daftar SPT',
        en: 'SPT List'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/SPT_23',
      permission: 'SPT_23',
      level: CONSTANT.LEVEL.ROLE_USER,
      user: true
    },
      {
        label: {
          id: 'BP 23',
          en: 'BP 23'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/ALLBP23',
        permission: 'BP_23',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      {
        label: {
          id: 'BP 26',
          en: 'BP 26'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/ALLBP26',
        permission: 'BP_26',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      // {
      //   label: {
      //     id: 'SPT 23 Induk',
      //     en: 'SPT 23 Induk'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/ALLSPT23Induk',
      //   permission: 'SSP_23',
      //   level: CONSTANT.LEVEL.ROLE_USER,
      //   user: true
      // },
      {
        label: {
          id: 'SSP 23',
          en: 'SSP 23'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/ALLSSP23',
        permission: 'SSP_23',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
    ]
  },

  /*{
    label: {
      id: 'Ebupot 23/26',
      en: 'Ebupot 23/26'
    },
    children: [
      {
        label: {
          id: 'Organisasi',
          en: 'Organization'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/ebupot/organization',
        permission: '',
        level: CONSTANT.LEVEL.ROLE_OWNER,
        owner: true
      },
      {
        label: {
          id: 'User Organisasi',
          en: 'Organization User'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/ebupot/organization-users',
        permission: '',
        level: CONSTANT.LEVEL.ROLE_OWNER,
        owner: true
      },
      {
        label: {
          id: 'Daftar SPT',
          en: 'SPT List'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/ebupot-23',
        permission: '',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      {
        label: {
          id: 'Bupot 23',
          en: 'Bupot 23'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/bp-23',
        permission: '',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      {
        label: {
          id: 'Bupot 26',
          en: 'Bupot 26'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/bp-26',
        permission: '',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      {
        label: {
          id: 'Lawan Transaksi 23',
          en: 'Lawan Transaksi 23'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/lawan-transaksi-23',
        permission: '',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      {
        label: {
          id: 'Lawan Transaksi 26',
          en: 'Lawan Transaksi 26'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/lawan-transaksi-26',
        permission: '',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      // {
      //   label: {
      //     id: 'BP 23',
      //     en: 'BP 23'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/all-ebupot-23',
      //   permission: '',
      //   level: CONSTANT.LEVEL.ROLE_USER,
      //   user: true
      // },
      // {
      //   label: {
      //     id: 'BP 26',
      //     en: 'BP 26'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/all-ebupot-26',
      //   permission: '',
      //   level: CONSTANT.LEVEL.ROLE_USER,
      //   user: true
      // },
      {
        label: {
          id: 'Excel',
          en: 'Excel'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/log-file',
        permission: '',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      // {
      //   label: {
      //     id: 'Csv',
      //     en: 'Csv'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/log-file-csv',
      //   permission: '',
      //   level: CONSTANT.LEVEL.ROLE_USER,
      //   user: true
      // }
    ]
  },*/

  // {
  //   label: {
  //     id: 'Lawan Transaksi 23/26',
  //     en: 'Lawan Transaksi 23/26'
  //   },
  //   children: [

  //   ]
  // },

  {
    label: {
      id: 'PPh 4 (2)',
      en: 'PPh 4 (2)'
    },
    children: [{
      label: {
        id: 'Daftar SPT',
        en: 'SPT List'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/SPT_4a2',
      permission: 'SPT_4a2',
      level: CONSTANT.LEVEL.ROLE_USER,
      user: true
    },
    {
      label: {
        id: 'BP 4(2)',
        en: 'BP 4(2)'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/ALLBP4A2',
      permission: 'BP_4A2',
      level: CONSTANT.LEVEL.ROLE_USER,
      user: true
    },
    {
      label: {
        id: 'Sewa Tanah/Bangunan',
        en: 'Sewa Tanah/Bangunan'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/ALL_SEWA_TANAH_4A2',
      permission: 'BP_4A2',
      level: CONSTANT.LEVEL.ROLE_USER,
      user: true
    },
    {
      label: {
        id: 'Usaha Jasa Konstruksi',
        en: 'Usaha Jasa Konstruksi'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/ALL_JASA_KONSTRUKSI_4A2',
      permission: 'BP_4A2',
      level: CONSTANT.LEVEL.ROLE_USER,
      user: true
    },
    {
      label: {
        id: 'Pengalihan Hak Tanah/Bangunan',
        en: 'Pengalihan Hak Tanah/Bangunan'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/ALL_WP_HAK_ATAS_TANAH_4A2',
      permission: 'BP_4A2',
      level: CONSTANT.LEVEL.ROLE_USER,
      user: true
    },
    {
      label: {
        id: 'SSP 4(2)',
        en: 'SSP 4(2)'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/ALLSSP4A2',
      permission: 'SSP_4a2',
      level: CONSTANT.LEVEL.ROLE_USER,
      user: true
    },
      // {
      //   label: {
      //     id: 'PBK 4(2)',
      //     en: 'PBK 4(2)'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/ALLPBK4A2',
      //   permission: 'PBK_4a2',
      //   level: CONSTANT.LEVEL.ROLE_USER,
      //   user: true
      // },
      // {
      //   label: {
      //     id: 'SPT 4(2) Induk',
      //     en: 'SPT 4(2) Induk'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/ALLSPT4a2Induk',
      //   permission: 'PBK_4a2',
      //   level: CONSTANT.LEVEL.ROLE_USER,
      //   user: true
      // },
    ]
  },
  {
    label: {
      id: 'PPh 22',
      en: 'PPh 22'
    },
    children: [{
      label: {
        id: 'Daftar SPT',
        en: 'SPT List'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/SPT_22',
      permission: 'SPT_22',
      level: CONSTANT.LEVEL.ROLE_USER,
      user: true
    },{
      label: {
        id: 'Bukti Pungut 22',
        en: 'Bukti Pungut 22'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/ALLBP22',
      permission: 'SPT_22',
      level: CONSTANT.LEVEL.ROLE_USER,
      user: true
    },{
      label: {
        id: 'Pembelian Barang',
        en: 'Pembelian Barangn'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/ALL_SSP22_PEMBELIAN_BARANG',
      permission: 'SPT_22',
      level: CONSTANT.LEVEL.ROLE_USER,
      user: true
    },{
      label: {
        id: 'Oleh Bank Devisa',
        en: 'Oleh Bank Devisa'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/ALL_SSP22_OLEH_BANK_DEVISA',
      permission: 'SPT_22',
      level: CONSTANT.LEVEL.ROLE_USER,
      user: true
    },{
      label: {
        id: 'Dibayar Sendiri',
        en: 'Dibayar Sendiri'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/ALL_SSP22_DIBAYAR_SENDIRI',
      permission: 'SPT_22',
      level: CONSTANT.LEVEL.ROLE_USER,
      user: true
    },{
      label: {
        id: 'SSP 22',
        en: 'SSP 22'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/ALLSSP22',
      permission: 'SPT_22',
      level: CONSTANT.LEVEL.ROLE_USER,
      user: true
    }]
  },
  {
    label: {
      id: 'PPh 15',
      en: 'PPh 15'
    },
    children: [{
      label: {
        id: 'Daftar SPT',
        en: 'SPT List'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/SPT_15',
      permission: 'SPT_15',
      level: CONSTANT.LEVEL.ROLE_USER,
      user: true
    },{
      label: {
        id: 'BP 15 Dipotong Pihak Lain',
        en: 'BP 15 Dipotong Pihak Lain'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/ALLBP15DipotongPihakLain',
      permission: 'BP_15',
      level: CONSTANT.LEVEL.ROLE_USER,
      user: true
    },{
      label: {
        id: 'BP 15 Pihak Lain Dipotong',
        en: 'BP 15 Pihak Lain Dipotong'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/ALLBP15PihakLainDipotong',
      permission: 'BP_15',
      level: CONSTANT.LEVEL.ROLE_USER,
      user: true
    },{
      label: {
        id: 'BP 24',
        en: 'BP 24'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/ALLBP24',
      permission: 'BP_15',
      level: CONSTANT.LEVEL.ROLE_USER,
      user: true
    }, {
      label: {
        id: 'SSP Setor Sendiri',
        en: 'SSP Setor Sendiri'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/ALLSSP15SETORSENDIRI',
      permission: 'BP_15',
      level: CONSTANT.LEVEL.ROLE_USER,
      user: true
    }, {
      label: {
        id: 'SSP 15',
        en: 'SSP 15'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/ALLSSP15',
      permission: 'BP_15',
      level: CONSTANT.LEVEL.ROLE_USER,
      user: true
    }
  ]},
  {
    label: {
      id: 'PPh 25',
      en: 'PPh 25'
    },
    children: [
      {
        label: {
          id: 'Daftar SPT',
          en: 'SPT List'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/SPT_25',
        permission: 'SPT_25',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      {
        label: {
          id: 'Daftar Angsuran 25',
          en: 'Daftar Angsuran 25'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/ALLBP25',
        permission: 'BP_25',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      {
        label: {
          id: 'SSP 25',
          en: 'SSP 25'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/ALLSSP25',
        permission: 'SSP_25',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
    ],
  },

  {
    label: {
      id: 'Import PPh 21',
      en: 'Import PPh 21'
    },
    children: [
      {
        label: {
          id: 'BP Tidak Final',
          en: 'BP Tidak Final'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_21_TF',
        permission: 'IMPORT_BP_21_TF',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      {
        label: {
          id: 'BP Final',
          en: 'BP Final'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_21_F',
        permission: 'IMPORT_BP_21_F',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      {
        label: {
          id: 'BP 1721 A1',
          en: 'BP 1721 A1'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_21_A1',
        permission: 'IMPORT_BP_21_A1',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      {
        label: {
          id: 'BP 1721 A2',
          en: 'BP 1721 A2'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_21_A2',
        permission: 'IMPORT_BP_21_A2',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      {
        label: {
          id: 'Pemotongan Satu Masa Pajak',
          en: 'Pemotongan Satu Masa Pajak'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_21_SATU_MASA',
        permission: 'IMPORT_BP_21_SATU_MASA',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      {
        label: {
          id: 'Pemotongan Satu Masa Pajak TL',
          en: 'Pemotongan Satu Masa Pajak TL'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_21_SATU_MASA_TIDAK_LEBIH',
        permission: 'IMPORT_BP_21_SATU_MASA',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      {
        label: {
          id: 'SSP Pasal 21',
          en: 'SSP Pasal 21'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/SSP_21',
        permission: 'IMPORT_SSP_21',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      {
        label: {
          id: 'Daftar Biaya Pasal 21',
          en: 'Daftar Biaya Pasal 21'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/DAFTAR_BIAYA_21',
        permission: 'IMPORT_DAFTAR_BIAYA_21',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      {
        label: {
          id: 'Induk Pasal 21',
          en: 'Induk Pasal 21'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/INDUK_21',
        permission: 'IMPORT_INDUK_21',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      {
        label: {
          id: 'Lawan Transaksi 21',
          en: 'Lawan Transaksi 21'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/LK_21',
        permission: 'IMPORT_LK_21',
        user: true
      },
    ]
  },

  {
    label: {
      id: 'Import PPh 23/26',
      en: 'Import PPh 23/26'
    },
    children: [
      {
        label: {
          id: 'BP 23',
          en: 'BP 23'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_23',
        permission: 'IMPORT_BP_23',
        user: true
      },
      {
        label: {
          id: 'BP 26',
          en: 'BP 26'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_26',
        permission: 'IMPORT_BP_26',
        user: true
      },
      {
        label: {
          id: 'BP 23 Gabung BP 26',
          en: 'BP 23 Gabung BP 26'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_2326',
        permission: 'IMPORT_BP_2326',
        user: true
      },
      {
        label: {
          id: 'Tarif BP 23 Jasa Lain',
          en: 'Tarif BP 23 Jasa Lain'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/23_TARIF_JASA_LAIN',
        permission: 'IMPORT_23_TARIF_JASA_LAIN',
        admin: true
      },
      {
        label: {
          id: 'SSP Pasal 23/26',
          en: 'SSP Pasal 23/26'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/SSP_23',
        permission: 'IMPORT_SSP_23',
        user: true
      },
      {
        label: {
          id: 'PBK 23/26',
          en: 'PBK 23/26'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/IMPORT_PBK',
        permission: 'IMPORT_PBK',
        user: true
      },
      {
        label: {
          id: 'Induk Pasal 23',
          en: 'Induk Pasal 23'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/INDUK_23',
        permission: 'SPT_INDUK_23',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      {
        label: {
          id: 'Lawan Transaksi 23/26',
          en: 'Lawan Transaksi 23/26'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/IMPORT_PBK23',
        permission: 'IMPORT_PBK',
        user: true
      }
    ]
  },

  {
    label: {
      id: 'Import PPh 4(2)',
      en: 'Import PPh 4(2)'
    },
    children: [
      {
        label: {
          id: 'BP 4(2)',
          en: 'BP 4(2)'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_4a2',
        permission: 'IMPORT_BP_4a2',
        user: true
      },
      {
        label: {
          id: 'BP 4(2) Gabung',
          en: 'BP 4(2) Gabung'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_4a2_GABUNG',
        permission: 'IMPORT_BP_4a2_GABUNG',
        user: true
      },
      {
        label: {
          id: 'Daftar Pemotongan 4 (2)',
          en: 'Daftar Pemotongan 4 (2)'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/DAFTAR_PEMOTONGAN_4a2',
        permission: 'IMPORT_BP_4a2',
        user: true
      },
      {
        label: {
          id: 'SSP Pasal 4(2)',
          en: 'SSP Pasal 4(2)'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/SSP_4a2',
        permission: 'IMPORT_SSP_4a2',
        user: true
      },
      {
        label: {
          id: 'PBK 4(2)',
          en: 'PBK 4(2)'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/PBK_4a2',
        permission: 'IMPORT_4a2',
        user: true
      },
      {
        label: {
          id: 'Induk Pasal 4 (2)',
          en: 'Induk Pasal 4 (2)'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/INDUK_4A2',
        permission: 'SPT_INDUK_4A2',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      {
        label: {
          id: 'Induk 4(2) Penghasilan Tertentu',
          en: 'Induk 4(2) Penghasilan Tertentu'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/INDUK_PT_4a2',
        permission: 'IMPORT_4a2',
        user: true
      },
      {
        label: {
          id: 'Lawan Transaksi 4(2)',
          en: 'Lawan Transaksi 4(2)'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/LK_4a2',
        permission: 'IMPORT_4a2',
        user: true
      }
    ]
  },

  {
    label: {
      id: 'Import PPh 22',
      en: 'Import PPh 22'
    },
    children: [
      {
        label: {
          id: 'Bukti Pungut ',
          en: 'Bukti Pungut '
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_22',
        permission: 'BP_22',
        user: true
      },
      // {
      //   label: {
      //     id: 'Bukti Pungut Industri Eksportir',
      //     en: 'Bukti Pungut Industri Eksportir'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/IMPORT/BP_22_industri_eksportir',
      //   permission: 'BP_22_industri_eksportir',
      //   user: true
      // },
      // {
      //   label: {
      //     id: 'Bukti Pungut Industri Importir',
      //     en: 'Bukti Pungut Industri Importir'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/IMPORT/BP_22_industri_importir',
      //   permission: 'BP_22_industri_eksportir',
      //   user: true
      // },
      // {
      //   label: {
      //     id: 'Bukti Pungut Badan Tertentu',
      //     en: 'Bukti Pungut Badan Tertentu'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/IMPORT/BP_22_badan_tertentu',
      //   permission: 'BP_22_badan_tertentu',
      //   user: true
      // },
      {
        label: {
          id: 'SSP Dibayar Sendiri',
          en: 'SSP Dibayar Sendiri'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/SSP_22_dibayar_sendiri',
        permission: 'SSP_22_dibayar_sendiri',
        user: true
      },
      // {
      //   label: {
      //     id: 'SSP Bank Devisa',
      //     en: 'SSP Bank Devisa'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/IMPORT/SSP_22_bank_devisa',
      //   permission: 'SSP_22_bank_devisa',
      //   user: true
      // },
      // {
      //   label: {
      //     id: 'SSP Bendaharawan',
      //     en: 'SSP Bendaharawan'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/IMPORT/SSP_22_bendaharawan',
      //   permission: 'SSP_22_bendaharawan',
      //   user: true
      // },
      {
        label: {
          id: 'SSP 22',
          en: 'SSP 22'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/SSP_22',
        permission: 'SSP_22',
        user: true
      },
      {
        label: {
          id: 'PBK 22',
          en: 'PBK 22'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/PBK_22',
        permission: 'PBK_22',
        user: true
      },
      {
        label: {
          id: 'Induk Pasal 22',
          en: 'Induk Pasal 22'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/INDUK_22',
        permission: 'SPT_INDUK_22',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      {
        label: {
          id: 'Lawan Transaksi 22',
          en: 'Lawan Transaksi 22'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/LK_22',
        permission: 'LK_22',
        user: true
      }
    ]
  },

  {
    label: {
      id: 'Import PPh 15',
      en: 'Import PPh 15'
    },
    children: [
      {
        label: {
          id: 'Bukti Potong 15',
          en: 'Bukti Potong 15'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_15',
        permission: 'BP_15',
        user: true
      },
      {
        label: {
          id: 'Bukti Potong 24',
          en: 'Bukti Potong 24'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_24',
        permission: 'BP_24',
        user: true
      },
      {
        label: {
          id: 'SSP Setor Sendiri',
          en: 'SSP Setor Sendiri'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/SSP_15_SETOR_SENDIRI',
        permission: 'SSP_15',
        user: true
      },
      {
        label: {
          id: 'SSP 15',
          en: 'SSP 15'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/SSP_15',
        permission: 'SSP_15',
        user: true
      },
      {
        label: {
          id: 'PBK 15',
          en: 'PBK 15'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/PBK_15',
        permission: 'PBK_15',
        user: true
      },
      {
        label: {
          id: 'Induk Pasal 15',
          en: 'Induk Pasal 15'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/INDUK_15',
        permission: 'SPT_INDUK_15',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      {
        label: {
          id: 'Lawan Transaksi 15',
          en: 'Lawan Transaksi 15'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/LK_15',
        permission: 'LK_15',
        user: true
      }
    ]
  },

  {
    label: {
      id: 'Import PPh 25',
      en: 'Import PPh 25'
    },
    children: [
      {
        label: {
          id: 'Daftar Angsuran 25',
          en: 'Daftar Angsuran 25'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_25',
        permission: 'BP_25',
        user: true
      },
      {
        label: {
          id: 'SSP 25',
          en: 'SSP 25'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/SSP_25',
        permission: 'SSP_25',
        user: true
      },
    ]
  },

  {
    label: {
      id: 'Summary',
      en: 'Summary'
    },
    children: [{
      label: {
        id: 'Pasal 21',
        en: 'Pasal 21'
      },
      iconClassName: 'mdi mdi-file-multiple',
      path: '/summary/21',
      user: true
    },{
      label: {
        id: 'Pasal 23',
        en: 'Pasal 23'
      },
      iconClassName: 'mdi mdi-file-multiple',
      path: '/summary/23',
      user: true
    },{
      label: {
        id: 'Pasal 26',
        en: 'Pasal 26'
      },
      iconClassName: 'mdi mdi-file-multiple',
      path: '/summary/26',
      user: true
    },{
      label: {
        id: 'Pasal 4(2)',
        en: 'Pasal 4(2)'
      },
      iconClassName: 'mdi mdi-file-multiple',
      path: '/summary/4A2',
      user: true
    },{
      label: {
        id: 'Pasal 22',
        en: 'Pasal 22'
      },
      iconClassName: 'mdi mdi-file-multiple',
      path: '/summary/22',
      user: true
    },{
      label: {
        id: 'Pasal 15',
        en: 'Pasal 15'
      },
      iconClassName: 'mdi mdi-file-multiple',
      path: '/summary/15',
      user: true
    },{
      label: {
        id: 'SSP/PBK',
        en: 'SSP/PBK'
      },
      iconClassName: 'mdi mdi-file-multiple',
      path: '/summary/SSPPBK',
      user: true
    },{
      label: {
        id: 'Induk Pasal 21',
        en: 'Induk Pasal 21'
      },
      iconClassName: 'mdi mdi-file-multiple',
      path: '/summary/INDUK_21',
      user: true
    },{
      label: {
        id: 'Unit Pasal 21',
        en: 'Unit Pasal 21'
      },
      iconClassName: 'mdi mdi-file-multiple',
      path: '/summary/UNIT_21',
      user: true
    }]
  },


  // {
  //   label: {
  //     id: 'eFiling',
  //     en: 'eFiling'
  //   },
  //   children: [{
  //     label: {
  //       id: 'PPh 21',
  //       en: 'PPh 21'
  //     },
  //     iconClassName: 'mdi mdi-file-multiple',
  //     path: '/eFiling/21',
  //   },{
  //     label: {
  //       id: 'PPh 23/26',
  //       en: 'PPh 23/26'
  //     },
  //     iconClassName: 'mdi mdi-file-multiple',
  //     path: '/eFiling/23',
  //   },{
  //     label: {
  //       id: 'PPh 4(2)',
  //       en: 'PPh 4(2)'
  //     },
  //     iconClassName: 'mdi mdi-file-multiple',
  //     path: '/eFiling/4A2',
  //   },
      // {
      //   label: {
      //     id: 'PPh 15',
      //     en: 'PPh 15'
      //   },
      //   iconClassName: 'mdi mdi-file-multiple',
      //   path: '/eFiling/15',
      // }
  //   ]
  // },

  {
    label: {
      id: 'eBilling',
      en: 'eBilling'
    },
    children: [
      {
        label: {
          id: 'Pengaturan',
          en: 'Pengaturan'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/EBILLING/SETTING',
        // permission: 'EBILLING_IMPORT_INQUIRY',
        user: true
      },
     /* {
        label: {
          id: 'Import Inquiry',
          en: 'Import Inquiry'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/EBILLING/IMPORT/INQUIRY',
        // permission: 'EBILLING_IMPORT_INQUIRY',
        user: true
      },
      {
        label: {
          id: 'Data Inquiry',
          en: 'Data Inquiry'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/EBILLING/INQUIRY',
        // permission: 'EBILLING_IMPORT_INQUIRY',
        user: true
      },*/
      {
        label: {
          id: 'Import ESSP',
          en: 'Import ESSP'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/EBILLING/IMPORT/ESSP',
        // permission: 'EBILLING_IMPORT_INQUIRY',
        user: true
      },
      {
        label: {
          id: 'Import ESSP WP Peredaran Tertentu',
          en: 'Import ESSP WP Peredaran Tertentu'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/EBILLING/IMPORT/ESSP-WP-PEREDARAN-TERTENTU',
        // permission: 'EBILLING_IMPORT_INQUIRY',
        user: true
      },
      {
        label: {
          id: 'Data ESSP',
          en: 'Data ESSP'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/EBILLING/DataESSP',
        // permission: 'EBILLING_IMPORT_INQUIRY',
        user: true
      },
      {
        label: {
          id: 'Payment Order',
          en: 'Payment Order'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/EBILLING/paymentOrder',
        // permission: 'EBILLING_IMPORT_INQUIRY',
        user: true
      },
      {
        label: {
          id: 'Payment Notification',
          en: 'Payment Notification'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/EBILLING/paymentNotification',
        // permission: 'EBILLING_IMPORT_INQUIRY',
        user: true
      },
      {
        label: {
          id: 'Akun Pembayaran',
          en: 'Akun Pembayaran'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/EBILLING/akunPembayaran',
        // permission: 'EBILLING_IMPORT_INQUIRY',
        user: true
      }
    ]
  },
  {
    label: {
      id: 'Log eBilling',
      en: 'Log eBilling'
    },
    children: [/*{
      label: {
        id: 'Log Export Data Inquiry',
        en: 'Log Export Data Inquiry'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/EBILLING/LOG/LogExportDataInquiry',
      user: true
    },*/{
      label: {
        id: 'Log Export Data SSP',
        en: 'Log Export Data SSP'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/EBILLING/LOG/LogExportDataSSP',
      user: true
    },/*{
      label: {
        id: 'Log Cetak Payment Order',
        en: 'Log Cetak Payment Order'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/EBILLING/LOG/LogCetakPaymentOrder',
      user: true
    },*/{
      label: {
        id: 'Log Cetak Payment Notification',
        en: 'Log Cetak Payment Notification'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/EBILLING/LOG/LogCetakPaymenNotification',
      user: true
    },{
      label: {
        id: 'Log Cetak BPN',
        en: 'Log Cetak BPN'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/EBILLING/LOG/LogCetakBPN',
      user: true
    },{
      label: {
        id: 'Log Cetak Laporan PO',
        en: 'Log Cetak Laporan PO'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/EBILLING/LOG/LogCetakLaporanPO',
      user: true
    },{
      label: {
        id: 'Log Cetak Laporan PN',
        en: 'Log Cetak Laporan PN'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/EBILLING/LOG/LogCetakLaporanPN',
      user: true
    }]
  },
  {
    label: {
      id: 'Log',
      en: 'Log'
    },
    children: [{
      label: {
        id: 'Log Csv',
        en: 'Log Csv'
      },
      iconClassName: 'mdi mdi-file-multiple',
      path: '/logExport/CSV',
      user: true
    },
      {
        label: {
          id: 'Log Pdf',
          en: 'Log Pdf'
        },
        iconClassName: 'mdi mdi-file-multiple',
        path: '/logExport/PDF',
        user: true
      },
      {
        label: {
          id: 'Log eFiling',
          en: 'Log eFiling'
        },
        iconClassName: 'mdi mdi-file-multiple',
        path: '/eFiling',
        user: true
      },
      {
        label: {
          id: 'Log Email',
          en: 'Log Email'
        },
        iconClassName: 'mdi mdi-file-multiple',
        path: '/LOG_EMAIL',
        permission: 'LOG_EMAIL',
        user: true
      }
    ]
  },
  {
    label: {
      id: 'Lapor SPT Lainnya',
      en: 'Lapor SPT Lainnya'
    },
    children: [
      {
        label: {
          id: 'Upload File Lapor',
          en: 'Upload File Lapor'
        },
        iconClassName: 'mdi mdi-file-multiple',
        path: '/eFiling/lainnya',
        user: true
      },
      /*{
        label: {
          id: 'SPT Tahunan Badan',
          en: 'SPT Tahunan Badan'
        },
        iconClassName: 'mdi mdi-file-multiple',
        path: '/eFiling/tahunan',
        user: true,
        show: env.enableEfilingBadan
      },*/
    ]
  },
  {
    label: {
      id: 'Master Data Tarif Pajak Pasal 21',
      en: 'Master Data Tarif Pajak Pasal 21'
    },
    children: [
      {
        label: {
          id: 'Pasal 17 Berlapis',
          en: 'Pasal 17 Berlapis'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-pasal-17-berlapis',
        permission: '',
        admin: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'Biaya Jabatan',
          en: 'Biaya Jabatan'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-biaya-jabatan',
        permission: '',
        admin: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'Biaya Pensiun',
          en: 'Biaya Pensiun'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-biaya-pensiun',
        permission: '',
        admin: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'PTKP',
          en: 'PTKP'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-ptkp',
        permission: '',
        admin: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'Tarif Pensiun',
          en: 'Tarif Pensiun'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-pensiun',
        permission: '',
        admin: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'Upah Harian',
          en: 'Upah Harian'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-upah-harian',
        permission: '',
        admin: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'Upah Kumulatif',
          en: 'Upah Kumulatif'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-upah-kumulatif',
        permission: '',
        admin: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'Final Pesangon',
          en: 'Final Pesangon'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-final-pesangon',
        permission: '',
        admin: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'Honor/Imbalan PNS',
          en: 'Honor/Imbalan PNS'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-honor-pns',
        permission: '',
        admin: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      }
    ]
  },
  {
    label: {
      id: 'Master Data Kode SPT Pasal 21',
      en: 'Master Data Kode SPT Pasal 21'
    },
    children: [
      {
        label: {
          id: 'Status Karyawan A1',
          en: 'Status Karyawan A1'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-status-karyawan-a1',
        permission: '',
        admin: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'Status Karyawan A2',
          en: 'Status Karyawan A2'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-status-karyawan-a2',
        permission: '',
        admin: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'KAP',
          en: 'KAP'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-kap',
        permission: '',
        admin: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'KJS',
          en: 'KJS'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-kjs',
        permission: '',
        admin: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'SSP Description',
          en: 'SSP Description'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-ssp-description',
        permission: '',
        admin: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'Golongan A2',
          en: 'Golongan A2'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-golongan-a2',
        permission: '',
        admin: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      }
    ]
  },
  {
    label: {
      id: 'Master Data 23/26',
      en: 'Master Data 23/26'
    },
    children: [
      {
        label: {
          id: 'KAP',
          en: 'KAP'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-23-kap',
        permission: '',
        admin: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'KJS',
          en: 'KJS'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-23-kjs',
        permission: '',
        admin: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'Tarif 23',
          en: 'Tarif 23'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/tarif-23-admin',
        permission: '',
        admin: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'Tarif 26',
          en: 'Tarif 26'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/tarif-26-admin',
        permission: '',
        admin: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'Tarif 23 Lain',
          en: 'Tarif 23 Lain'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/tarif-23-lain',
        permission: '',
        admin: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'Tarif 23',
          en: 'Tarif 23'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/tarif-23',
        permission: '',
        owner: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'Tarif 26',
          en: 'Tarif 26'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/tarif-26',
        permission: '',
        owner: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      }
    ]
  },
  {
    label: {
      id: 'Master Data 4 (2)',
      en: 'Master Data 4 (2)'
    },
    children: [
      {
        label: {
          id: 'Tarif 4 (2)',
          en: 'Tarif 4 (2)'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/tarif-4a2-admin',
        permission: '',
        admin: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'Tarif 4 (2)',
          en: 'Tarif 4 (2)'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/tarif-4a2',
        permission: '',
        owner: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
    ]
  },
  {
    label: {
      id: 'Master Data 22',
      en: 'Master Data 22'
    },
    children: [
      {
        label: {
          id: 'Komoditi 22',
          en: 'Komoditi 22'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/komoditi-22',
        permission: '',
        admin: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'Master Tarif 22',
          en: 'Master Tarif 22'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/tarif-22',
        permission: '',
        admin: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'Tarif 22',
          en: 'Tarif 22'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/company/tarif-22-admin',
        permission: '',
        admin: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
    ]
  },
  /*{
    label: {
      id: 'Master Data 15',
      en: 'Master Data 15'
    },
    children: [
      {
        label: {
          id: 'Tarif 15',
          en: 'Tarif 15'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/tarif-15',
        permission: '',
        admin: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
    ]
  },*/
  {
    label: {
      id: 'Master Data 22',
      en: 'Master Data 22'
    },
    children: [
      {
        label: {
          id: 'Tarif 22',
          en: 'Tarif 22'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/company/tarif-22',
        permission: '',
        owner: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
    ]
  },
  {
    label: {
      id: 'Master Data 15',
      en: 'Master Data 15'
    },
    children: [
      {
        label: {
          id: 'Tarif 15',
          en: 'Tarif 15'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/company/tarif-15-admin',
        permission: '',
        admin: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'Tarif 15',
          en: 'Tarif 15'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/company/tarif-15',
        permission: '',
        owner: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
    ]
  },
  {
    label: {
      id: 'Lawan Transaksi',
      en: 'Lawan Transaksi'
    },
    children: [
      {
        label: {
          id: 'PPH 21 A1',
          en: 'PPH 21 A1'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/lawanTransaksi/pph21/a1',
        permission: '',
        user: true,
        // owner: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'PPH 21 A2',
          en: 'PPH 21 A2'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/lawanTransaksi/pph21/a2',
        permission: '',
        user: true,
        // owner: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'PPH 21 Pp',
          en: 'PPH 21 Pp'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/lawanTransaksi/pph21/pp',
        permission: '',
        user: true,
        // owner: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'PPH 23/26',
          en: 'PPH 23/26'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/lawanTransaksi/pph23',
        permission: '',
        user: true,
        // owner: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'PPH 4(2)',
          en: 'PPH 4(2)'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/lawanTransaksi/4a2',
        permission: '',
        user: true,
        // owner: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'PPH 22',
          en: 'PPH 22'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/lawanTransaksi/22',
        permission: '',
        user: true,
        // owner: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'PPH 15',
          en: 'PPH 15'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/lawanTransaksi/15',
        permission: '',
        user: true,
        // owner: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
    ]
  },
  {
    label: {
      id: 'Pengaturan',
      en: 'Pengaturan'
    },
    children: [
      {
        label: {
          id: 'Ekspor PDF & Email A1',
          en: 'Ekspor PDF & Email A1'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/SETTING_EXPORT',
        permission: '',
        user: true,
        // owner: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
    ]
  },

  /*{
    label: {
      id: 'Kalkulasi Induk',
      en: 'Kalkulasi Induk'
    },
    children: [{
      label: {
        id: 'Pasal 21',
        en: 'Pasal 21'
      },
      iconClassName: 'mdi mdi-file-multiple',
      path: '/kalkulasiInduk/21',
      admin: true
    },{
      label: {
        id: 'Pasal 23',
        en: 'Pasal 23'
      },
      iconClassName: 'mdi mdi-file-multiple',
      path: '/kalkulasiInduk/23',
      admin: true
    },{
      label: {
        id: 'Pasal 4(2)',
        en: 'Pasal 4(2)'
      },
      iconClassName: 'mdi mdi-file-multiple',
      path: '/kalkulasiInduk/4A2',
      admin: true
    }]
  },*/

  {
    label: {
      id: 'Pengaturan Nomor BP',
      en: 'Pengaturan Nomor BP'
    },
    children: [
      {
        label: {
          id: 'Nomor BP 21',
          en: 'Nomor BP 21'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/nomorBp/21',
        permission: '',
        // user: true,
        owner: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },{
        label: {
          id: 'Nomor BP 23',
          en: 'Nomor BP 23'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/nomorBp/23',
        permission: '',
        // user: true,
        owner: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },{
        label: {
          id: 'Nomor BP 4(2)',
          en: 'Nomor BP 4(2)'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/nomorBp/4a2',
        permission: '',
        // user: true,
        owner: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },{
        label: {
          id: 'Nomor BP 22',
          en: 'Nomor BP 22'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/nomorBp/22',
        permission: '',
        // user: true,
        owner: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },{
        label: {
          id: 'Nomor BP 15',
          en: 'Nomor BP 15'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/nomorBp/15',
        permission: '',
        // user: true,
        owner: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
    ]
  },
  {
    label: {
      id: 'Summary',
      en: 'Summary'
    },
    children: [{
      label: {
        id: 'Admin',
        en: 'Admin'
      },
      iconClassName: 'mdi mdi-file-multiple',
      path: '/summary-admin',
      admin: true
    }],
  },
  // ADMIN
  {
    label: {
      id: 'Administrator',
      en: 'Administrator'
    },
    children: [{
      label: {
        id: 'Flow Approval',
        en: 'Flow Approval'
      },
      iconClassName: 'mdi mdi-contacts',
      path: '/mainFlow',
      admin: true,
      level: CONSTANT.LEVEL.ROLE_OWNER
    },{
      label: {
        id: 'User',
        en: 'User'
      },
      iconClassName: 'mdi mdi-contacts',
      path: '/user',
      admin: true,
      level: CONSTANT.LEVEL.ROLE_ADMIN
    },
    {
      label: {
        id: 'Master KPP',
        en: 'Master KPP'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/master/kpp',
      permission: '',
      admin: true,
      level: CONSTANT.LEVEL.ROLE_ADMIN
    },
    {
      label: {
        id: 'Master Aplikasi',
        en: 'Master Aplikasi'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/master/applications',
      permission: '',
      admin: true,
      level: CONSTANT.LEVEL.ROLE_ADMIN
    },
    {
      label: {
        id: 'Master Custom Import',
        en: 'Master Custom Import'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/master/custom-import-headers',
      permission: '',
      admin: true,
      level: CONSTANT.LEVEL.ROLE_ADMIN
    },
    {
      label: {
        id: 'Log Import By App',
        en: 'Log Import By App'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/logImportCsvByApp',
      permission: '',
      admin: true,
      level: CONSTANT.LEVEL.ROLE_ADMIN
    },
      // {
      //   label: {
      //     id: 'Organisasi',
      //     en: 'Organization'
      //   },
      //   iconClassName: 'mdi mdi-contacts',
      //   path: '/organization',
      //   admin: true,
      //   level: CONSTANT.LEVEL.ROLE_ADMIN
      // },
      /*{
        label: {
          id: 'Permissions',
          en: 'Perizinan'
        },
        iconClassName: 'mdi mdi-contacts',
        path: '/permissions',
        admin: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },*/
      // {
      //   label: {
      //     id: 'Akses',
      //     en: 'Access'
      //   },
      //   iconClassName: 'mdi mdi-contacts',
      //   path: '/access',
      //   admin: true
      // },
      {
        label: {
          id: 'Pengaturan Akses',
          en: 'Akses Configuration'
        },
        iconClassName: 'mdi mdi-contacts',
        path: '/access-control',
        admin: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'Role Template',
          en: 'Template Role'
        },
        iconClassName: 'mdi mdi-contacts',
        admin: true,
        path: '/templateRole'
      },
      /*{
        label: {
          id: 'Logging Events',
          en: 'Logging Events'
        },
        iconClassName: 'mdi mdi-contacts',
        admin: true,
        path: '/loggingEvent'
      },*/
      {
        label: {
          id: 'Log Sign In',
          en: 'Log Sign In'
        },
        iconClassName: 'mdi mdi-contacts',
        admin: true,
        path: '/signInLog'
      },
      {
        label: {
          id: 'Log Validasi',
          en: 'Log Validasi'
        },
        iconClassName: 'mdi mdi-contacts',
        admin: true,
        path: '/validationLog'
      },
      {
        label: {
          id: 'Log Aplikasi',
          en: 'Log Aplikasi'
        },
        iconClassName: 'mdi mdi-contacts',
        admin: true,
        path: '/logApp'
      },
      {
        label: {
          id: 'Log eFiling',
          en: 'Log eFiling'
        },
        iconClassName: 'mdi mdi-contacts',
        admin: true,
        path: '/logEfiling',
      },
      {
        label: {
          id: 'Lapor SPT Lainnya',
          en: 'Lapor SPT Lainnya'
        },
        iconClassName: 'mdi mdi-file-multiple',
        path: '/eFiling/lainnya',
        admin: true
      },
      /*{
        label: {
          id: 'SPT Tahunan Badan',
          en: 'SPT Tahunan Badan'
        },
        iconClassName: 'mdi mdi-file-multiple',
        path: '/eFiling/tahunan',
        user: true,
        show: env.enableEfilingBadan
      },*/
    ]
  },
  {
    label: {
      id: 'Pengaturan',
      en: 'Setting'
    },
    children: [
      {
        label: {
          id: 'E-Mail',
          en: 'E-Mail'
        },
        owner: true,
        iconClassName: 'mdi mdi-settings',
        path: '/SETTING_EMAIL',
        permission: 'SETTING_EMAIL'
      },
      {
        label: {
          id: 'Konten E-Mail',
          en: 'E-Mail Content'
        },
        owner: true,
        iconClassName: 'mdi mdi-settings',
        path: '/SETTING_EMAIL_CONTENT',
        permission: 'SETTING_EMAIL_CONTENT'
      },
      {
        label: {
          id: 'Jadwal E-Mail',
          en: 'E-Mail Schedule'
        },
        owner: true,
        iconClassName: 'mdi mdi-settings',
        path: '/SETTING_EMAIL_SCHEDULE',
        permission: 'SETTING_EMAIL_SCHEDULE'
      },
      {
        label: {
          id: 'E-Filing',
          en: 'E-Filing'
        },
        owner: true,
        iconClassName: 'mdi mdi-settings',
        path: '/SETTING_EFILING',
        permission: 'SETTING_EFILING'
      },
      /*{
        label: {
          id: 'E-Bupot',
          en: 'E-Bupot'
        },
        owner: true,
        iconClassName: 'mdi mdi-settings',
        path: '/SETTING_EBUPOT',
        permission: 'SETTING_EBUPOT'
      },
      {
        label: {
          id: 'Email Ebupot',
          en: 'Email Ebupot'
        },
        owner: true,
        iconClassName: 'mdi mdi-settings',
        path: '/application-email',
        permission: 'SETTING_EBUPOT'
      },*/
      {
        label: {
          id: 'Tampilan BP',
          en: 'Tampilan BP'
        },
        owner: true,
        iconClassName: 'mdi mdi-settings',
        path: '/SETTING_VIEW',
        permission: 'SETTING_VIEW'
      },
      {
        label: {
          id: 'Impor',
          en: 'Import'
        },
        owner: true,
        iconClassName: 'mdi mdi-settings',
        path: '/SETTING_IMPORT',
        permission: 'SETTING_IMPORT'
      },
      {
        label: {
          id: 'Role Template',
          en: 'Template Role'
        },
        iconClassName: 'mdi mdi-settings',
        owner: true,
        path: '/templateRole'
      },
      {
        label: {
          id: 'About',
          en: 'About'
        },
        iconClassName: 'mdi mdi-settings',
        owner: true,
        path: '/aboutCompany'
      }
    ]
  },
  {
    label: {
      id: 'Pengaturan',
      en: 'Setting'
    },
    children: [
      {
        label: {
          id: 'E-Mail',
          en: 'E-Mail'
        },
        admin: true,
        iconClassName: 'mdi mdi-settings',
        path: '/SETTING_EMAIL_ALL',
        permission: 'SETTING_EMAIL_ALL'
      },
      {
        label: {
          id: 'Konten E-Mail',
          en: 'E-Mail Content'
        },
        admin: true,
        iconClassName: 'mdi mdi-settings',
        path: '/SETTING_EMAIL_CONTENT_ALL',
        permission: 'SETTING_EMAIL_CONTENT_ALL'
      },
      {
        label: {
          id: 'Jadwal E-Mail',
          en: 'E-Mail Schedule'
        },
        admin: true,
        iconClassName: 'mdi mdi-settings',
        path: '/SETTING_EMAIL_SCHEDULE_ALL',
        permission: 'SETTING_EMAIL_SCHEDULE_ALL'
      },
      {
        label: {
          id: 'E-Filing',
          en: 'E-Filing'
        },
        admin: true,
        iconClassName: 'mdi mdi-settings',
        path: '/SETTING_EFILING_ALL',
        permission: 'SETTING_EFILING_ALL'
      },
      {
        label: {
          id: 'Impor Global',
          en: 'Import Global'
        },
        admin: true,
        iconClassName: 'mdi mdi-settings',
        path: '/SETTING_IMPORT_GLOBAL',
        permission: 'SETTING_IMPORT_GLOBAL'
      },
      {
        label: {
          id: 'Tampilan BP',
          en: 'Tampilan BPt'
        },
        admin: true,
        iconClassName: 'mdi mdi-settings',
        path: '/SETTING_VIEW_ALL',
        permission: 'SETTING_VIEW_ALL'
      },
      {
        label: {
          id: 'Impor',
          en: 'Import'
        },
        admin: true,
        iconClassName: 'mdi mdi-settings',
        path: '/SETTING_IMPORT_ALL',
        permission: 'SETTING_IMPORT_ALL'
      },
      {
        label: {
          id: 'Ekspor',
          en: 'Export'
        },
        admin: true,
        iconClassName: 'mdi mdi-settings',
        path: '/SETTING_EXPORT_ADMIN',
        permission: 'SETTING_EXPORT_ADMIN'
      },
    ]
  },
]

const mainMenusNoBupot = [{
  label: {
    id: 'Main',
    en: 'Main'
  },
  children: [{
    label: {
      id: 'Home',
      en: 'Home'
    },
    iconClassName: 'mdi mdi-view-grid',
    path: '/dashboard',
  }]
},
  // ORGANISASI
  {
    label: {
      id: 'Perusahaan',
      en: 'Company'
    },
    children: [{
      label: {
        id: 'Perusahaan',
        en: 'Company'
      },
      iconClassName: 'mdi mdi-contacts',
      path: '/company',
      admin: true,
      level: CONSTANT.LEVEL.ROLE_OWNER
    },{
      label: {
        id: 'Organisasi',
        en: 'Organization'
      },
      iconClassName: 'mdi mdi-contacts',
      path: '/organization',
      admin: true,
      level: CONSTANT.LEVEL.ROLE_OWNER
    }]
  },

  // IMPORT USER
  {
    label: {
      id: 'Import',
      en: 'Import'
    },
    children: [
      {
        label: {
          id: 'Organisasi',
          en: 'Organization'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/ORGANIZATION',
        // permission: 'IMPORT_BP_21_TF',
        level: CONSTANT.LEVEL.ROLE_OWNER,
        owner: true
      },
      {
        label: {
          id: 'Pengguna',
          en: 'User'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/USER',
        // permission: 'IMPORT_BP_21_TF',
        level: CONSTANT.LEVEL.ROLE_OWNER,
        owner: true
      },
      {
        label: {
          id: 'User Organisasi',
          en: 'Organization User'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/ORGANIZATION/USER',
        // permission: 'IMPORT_BP_21_TF',
        level: CONSTANT.LEVEL.ROLE_OWNER,
        owner: true
      },
      {
        label: {
          id: 'Riwayat KPP Organisasi',
          en: 'Riwayat KPP Organisasi'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/ORGANIZATION/KPP',
        // permission: 'IMPORT_BP_21_TF',
        level: CONSTANT.LEVEL.ROLE_OWNER,
        owner: true
      },
      {
        label: {
          id: 'Penandatangan PPh 21',
          en: 'Signer PPh 21'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/SIGNER21',
        // permission: 'IMPORT_BP_21_TF',
        level: CONSTANT.LEVEL.ROLE_OWNER,
        owner: true
      },
      {
        label: {
          id: 'Penandatangan PPh 23',
          en: 'Signer PPh 23'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/SIGNER23',
        // permission: 'IMPORT_BP_21_TF',
        level: CONSTANT.LEVEL.ROLE_OWNER,
        owner: true
      },
      {
        label: {
          id: 'Penandatangan PPh 4(2)',
          en: 'Signer PPh 4(2)'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/SIGNER4A2',
        // permission: 'IMPORT_BP_21_TF',
        level: CONSTANT.LEVEL.ROLE_OWNER,
        owner: true
      },
      {
        label: {
          id: 'Penandatangan PPh 22',
          en: 'Signer PPh 22'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/SIGNER22',
        // permission: 'IMPORT_BP_21_TF',
        level: CONSTANT.LEVEL.ROLE_OWNER,
        owner: true
      },
      {
        label: {
          id: 'Penandatangan PPh 15',
          en: 'Signer PPh 15'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/SIGNER15',
        // permission: 'IMPORT_BP_21_TF',
        level: CONSTANT.LEVEL.ROLE_OWNER,
        owner: true
      },
      /*{
        label: {
          id: 'Import eFiling',
          en: 'Import eFiling'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/EFILING',
        // permission: 'IMPORT_BP_21_TF',
        level: CONSTANT.LEVEL.ROLE_OWNER,
        owner: true
      },*/
      {
        label: {
          id: 'Import Unit',
          en: 'Import Unit'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/UNIT',
        // permission: 'IMPORT_BP_21_TF',
        level: CONSTANT.LEVEL.ROLE_OWNER,
        owner: true
      },
      {
        label: {
          id: 'Import Credential eBilling',
          en: 'Import Credential eBilling'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/CREDENTIAL_EBILLING',
        // permission: 'IMPORT_BP_21_TF',
        level: CONSTANT.LEVEL.ROLE_OWNER,
        owner: true
      },
    ]
  },
  // END IMPORT USER

  // ORGANISASI OWNER
  {
    label: {
      id: 'Organisasi',
      en: 'Organization'
    },
    children: [{
      label: {
        id: 'Organisasi',
        en: 'Organization'
      },
      iconClassName: 'mdi mdi-view-list',
      rootPath: '/company',
      path: '/organization',
      permission: 'organization',
      owner: true
    },{
      label: {
        id: 'User Organisasi',
        en: 'Organization User'
      },
      iconClassName: 'mdi mdi-contacts',
      rootPath: '/company',
      path: '/organizationUser',
      owner: true
    },{
      label: {
        id: 'Flow Approval',
        en: 'Flow Approval'
      },
      iconClassName: 'mdi mdi-contacts',
      path: '/mainFlow',
      owner: true
    },{
      label: {
        id: 'Managemen File',
        en: 'File Management'
      },
      iconClassName: 'mdi mdi-contacts',
      path: '/file',
      owner: true
    },{
      label: {
        id: 'Riwayat KPP Organisasi',
        en: 'Riwayat KPP Organisasi'
      },
      iconClassName: 'mdi mdi-contacts',
      rootPath: '/company',
      path: '/organizationKpp',
      owner: true
    }]
  },
   // Key
   {
    label: {
      id: 'Key',
      en: 'Key'
    },
    children: [{
      label: {
        id: 'SPT 21',
        en: 'SPT 21'
      },
      iconClassName: 'mdi mdi-view-list',
      // rootPath: '/company',
      path: '/key/21',
      permission: 'organization',
      owner: true
    },
    {
      label: {
        id: 'SPT 22',
        en: 'SPT 22'
      },
      iconClassName: 'mdi mdi-view-list',
      // rootPath: '/company',
      path: '/key/22',
      permission: 'organization',
      owner: true
    },
    {
      label: {
        id: 'SPT 23/26',
        en: 'SPT 23/26'
      },
      iconClassName: 'mdi mdi-view-list',
      // rootPath: '/company',
      path: '/key/23',
      permission: 'organization',
      owner: true
    },
    {
      label: {
        id: 'SPT 4(2)',
        en: 'SPT 4(2)'
      },
      iconClassName: 'mdi mdi-view-list',
      // rootPath: '/company',
      path: '/key/4a2',
      permission: 'organization',
      owner: true
    },
    {
      label: {
        id: 'SPT 15',
        en: 'SPT 15'
      },
      iconClassName: 'mdi mdi-view-list',
      // rootPath: '/company',
      path: '/key/15',
      permission: 'organization',
      owner: true
    },
    // {
    //   label: {
    //     id: 'SPT 15',
    //     en: 'SPT 15'
    //   },
    //   iconClassName: 'mdi mdi-view-list',
    //   // rootPath: '/company',
    //   path: '/key/15',
    //   permission: 'organization',
    //   owner: true
    // }
  ]
  },
  // Key
  {
    label: {
      id: 'Data Unit',
      en: 'Data Unit'
    },
    children: [{
      label: {
        id: 'Unit',
        en: 'Unit'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/unit',
      // permission: 'organization',
      owner: true
    }]
  },
  //
  {
    label: {
      id: 'Pengguna',
      en: 'User'
    },
    children: [{
      label: {
        id: 'Pengguna',
        en: 'User'
      },
      rootPath: '/company',
      iconClassName: 'mdi mdi-view-list',
      path: '/user',
      permission: 'user',
      owner: true
    },{
      label: {
        id: 'Pemilik',
        en: 'Owner'
      },
      iconClassName: 'mdi mdi-view-list',
      rootPath: '/company',
      path: '/owner',
      permission: 'owner',
      owner: true
    },{
      label: {
        id: 'Daftar User Login ',
        en: 'Logged In List'
      },
      owner: true,
      iconClassName: 'mdi mdi-settings',
      path: '/logged-in',
      permission: 'SETTING_EMAIL'
    },{
      label: {
        id: 'Daftar User Login',
        en: 'Logged In List'
      },
      admin: true,
      iconClassName: 'mdi mdi-settings',
      path: '/logged-in',
      permission: 'SETTING_EMAIL'
    },]
  },
  // END ORGANISASI OWNER


  // PPH21
  {
    label: {
      id: 'PPh 21',
      en: 'PPh 21'
    },
    children: [{
      label: {
        id: 'Daftar SPT',
        en: 'SPT List'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/SPT_21',
      permission: 'SPT_21',
      level: CONSTANT.LEVEL.ROLE_USER,
      user: true
    },
      {
        label: {
          id: 'BP Final',
          en: 'BP Final'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/BP_21_F',
        permission: 'BP_21_F',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      {
        label: {
          id: 'BP Tidak Final',
          en: 'BP Tidak Final'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/BP_21_TF',
        permission: 'BP_21_TF',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      {
        label: {
          id: 'BP 1721 A1',
          en: 'BP 1721 A1'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/BP_21_A1',
        permission: 'BP_21_A1',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      {
        label: {
          id: 'BP 1721 A2',
          en: 'BP 1721 A2'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/BP_21_A2',
        permission: 'BP_21_A2',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      {
        label: {
          id: 'BP Satu Masa',
          en: 'BP Satu Masa'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/ALL_BP_21_SATU_MASA',
        permission: 'BP_21_SATU_MASA',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      {
        label: {
          id: 'BP Satu Tahun',
          en: 'BP Satu Tahun'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/BP_21_SATU_TAHUN',
        permission: 'BP_21_SATU_TAHUN',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      // {
      //   label: {
      //     id: 'Daftar Biaya',
      //     en: 'Daftar Biaya'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/DAFTAR_BIAYA_21',
      //   permission: 'DAFTAR_BIAYA_21',
      //   level: CONSTANT.LEVEL.ROLE_USER,
      //   // decemberOnly: true,
      //   user: true
      // },
      {
        label: {
          id: 'Daftar SSP',
          en: 'Daftar SSP'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/ALL_BP_21_SSP',
        permission: 'DAFTAR_SSP_21',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      // {
      //   label: {
      //     id: 'SPT Induk',
      //     en: 'SPT Induk'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/SPT_INDUK_21',
      //   permission: 'SPT_INDUK_21',
      //   level: CONSTANT.LEVEL.ROLE_USER,
      //   user: true
      // }
    ]
  },
  {
    label: {
      id: 'PPh 23/26',
      en: 'PPh 23/26'
    },
    children: [{
      label: {
        id: 'Daftar SPT',
        en: 'SPT List'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/SPT_23',
      permission: 'SPT_23',
      level: CONSTANT.LEVEL.ROLE_USER,
      user: true
    },
      {
        label: {
          id: 'BP 23',
          en: 'BP 23'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/ALLBP23',
        permission: 'BP_23',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      {
        label: {
          id: 'BP 26',
          en: 'BP 26'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/ALLBP26',
        permission: 'BP_26',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      // {
      //   label: {
      //     id: 'SPT 23 Induk',
      //     en: 'SPT 23 Induk'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/ALLSPT23Induk',
      //   permission: 'SSP_23',
      //   level: CONSTANT.LEVEL.ROLE_USER,
      //   user: true
      // },
      {
        label: {
          id: 'SSP 23',
          en: 'SSP 23'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/ALLSSP23',
        permission: 'SSP_23',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
    ]
  },

  // {
  //   label: {
  //     id: 'Lawan Transaksi 23/26',
  //     en: 'Lawan Transaksi 23/26'
  //   },
  //   children: [

  //   ]
  // },

  {
    label: {
      id: 'PPh 4 (2)',
      en: 'PPh 4 (2)'
    },
    children: [{
      label: {
        id: 'Daftar SPT',
        en: 'SPT List'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/SPT_4a2',
      permission: 'SPT_4a2',
      level: CONSTANT.LEVEL.ROLE_USER,
      user: true
    },
    {
      label: {
        id: 'BP 4(2)',
        en: 'BP 4(2)'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/ALLBP4A2',
      permission: 'BP_4A2',
      level: CONSTANT.LEVEL.ROLE_USER,
      user: true
    },
    {
      label: {
        id: 'Sewa Tanah/Bangunan',
        en: 'Sewa Tanah/Bangunan'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/ALL_SEWA_TANAH_4A2',
      permission: 'BP_4A2',
      level: CONSTANT.LEVEL.ROLE_USER,
      user: true
    },
    {
      label: {
        id: 'Usaha Jasa Konstruksi',
        en: 'Usaha Jasa Konstruksi'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/ALL_JASA_KONSTRUKSI_4A2',
      permission: 'BP_4A2',
      level: CONSTANT.LEVEL.ROLE_USER,
      user: true
    },
    {
      label: {
        id: 'Pengalihan Hak Tanah/Bangunan',
        en: 'Pengalihan Hak Tanah/Bangunan'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/ALL_WP_HAK_ATAS_TANAH_4A2',
      permission: 'BP_4A2',
      level: CONSTANT.LEVEL.ROLE_USER,
      user: true
    },
    {
      label: {
        id: 'SSP 4(2)',
        en: 'SSP 4(2)'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/ALLSSP4A2',
      permission: 'SSP_4a2',
      level: CONSTANT.LEVEL.ROLE_USER,
      user: true
    },
      // {
      //   label: {
      //     id: 'PBK 4(2)',
      //     en: 'PBK 4(2)'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/ALLPBK4A2',
      //   permission: 'PBK_4a2',
      //   level: CONSTANT.LEVEL.ROLE_USER,
      //   user: true
      // },
      // {
      //   label: {
      //     id: 'SPT 4(2) Induk',
      //     en: 'SPT 4(2) Induk'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/ALLSPT4a2Induk',
      //   permission: 'PBK_4a2',
      //   level: CONSTANT.LEVEL.ROLE_USER,
      //   user: true
      // },
    ]
  },
  {
    label: {
      id: 'PPh 22',
      en: 'PPh 22'
    },
    children: [{
      label: {
        id: 'Daftar SPT',
        en: 'SPT List'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/SPT_22',
      permission: 'SPT_22',
      level: CONSTANT.LEVEL.ROLE_USER,
      user: true
    },{
      label: {
        id: 'Bukti Pungut 22',
        en: 'Bukti Pungut 22'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/ALLBP22',
      permission: 'SPT_22',
      level: CONSTANT.LEVEL.ROLE_USER,
      user: true
    },{
      label: {
        id: 'Pembelian Barang',
        en: 'Pembelian Barangn'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/ALL_SSP22_PEMBELIAN_BARANG',
      permission: 'SPT_22',
      level: CONSTANT.LEVEL.ROLE_USER,
      user: true
    },{
      label: {
        id: 'Oleh Bank Devisa',
        en: 'Oleh Bank Devisa'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/ALL_SSP22_OLEH_BANK_DEVISA',
      permission: 'SPT_22',
      level: CONSTANT.LEVEL.ROLE_USER,
      user: true
    },{
      label: {
        id: 'Dibayar Sendiri',
        en: 'Dibayar Sendiri'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/ALL_SSP22_DIBAYAR_SENDIRI',
      permission: 'SPT_22',
      level: CONSTANT.LEVEL.ROLE_USER,
      user: true
    },{
      label: {
        id: 'SSP 22',
        en: 'SSP 22'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/ALLSSP22',
      permission: 'SPT_22',
      level: CONSTANT.LEVEL.ROLE_USER,
      user: true
    }]
  },
  {
    label: {
      id: 'PPh 15',
      en: 'PPh 15'
    },
    children: [{
      label: {
        id: 'Daftar SPT',
        en: 'SPT List'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/SPT_15',
      permission: 'SPT_15',
      level: CONSTANT.LEVEL.ROLE_USER,
      user: true
    },{
      label: {
        id: 'BP 15 Dipotong Pihak Lain',
        en: 'BP 15 Dipotong Pihak Lain'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/ALLBP15DipotongPihakLain',
      permission: 'BP_15',
      level: CONSTANT.LEVEL.ROLE_USER,
      user: true
    },{
      label: {
        id: 'BP 15 Pihak Lain Dipotong',
        en: 'BP 15 Pihak Lain Dipotong'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/ALLBP15PihakLainDipotong',
      permission: 'BP_15',
      level: CONSTANT.LEVEL.ROLE_USER,
      user: true
    },{
      label: {
        id: 'BP 24',
        en: 'BP 24'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/ALLBP24',
      permission: 'BP_15',
      level: CONSTANT.LEVEL.ROLE_USER,
      user: true
    }, {
      label: {
        id: 'SSP Setor Sendiri',
        en: 'SSP Setor Sendiri'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/ALLSSP15SETORSENDIRI',
      permission: 'BP_15',
      level: CONSTANT.LEVEL.ROLE_USER,
      user: true
    }, {
      label: {
        id: 'SSP 15',
        en: 'SSP 15'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/ALLSSP15',
      permission: 'BP_15',
      level: CONSTANT.LEVEL.ROLE_USER,
      user: true
    }
  ]},
  {
    label: {
      id: 'PPh 25',
      en: 'PPh 25'
    },
    children: [
      {
        label: {
          id: 'Daftar SPT',
          en: 'SPT List'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/SPT_25',
        permission: 'SPT_25',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      {
        label: {
          id: 'Daftar Angsuran 25',
          en: 'Daftar Angsuran 25'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/ALLBP25',
        permission: 'BP_25',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      {
        label: {
          id: 'SSP 25',
          en: 'SSP 25'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/ALLSSP25',
        permission: 'SSP_25',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
    ],
  },

  {
    label: {
      id: 'Import PPh 21',
      en: 'Import PPh 21'
    },
    children: [
      {
        label: {
          id: 'BP Tidak Final',
          en: 'BP Tidak Final'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_21_TF',
        permission: 'IMPORT_BP_21_TF',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      {
        label: {
          id: 'BP Final',
          en: 'BP Final'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_21_F',
        permission: 'IMPORT_BP_21_F',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      {
        label: {
          id: 'BP 1721 A1',
          en: 'BP 1721 A1'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_21_A1',
        permission: 'IMPORT_BP_21_A1',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      {
        label: {
          id: 'BP 1721 A2',
          en: 'BP 1721 A2'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_21_A2',
        permission: 'IMPORT_BP_21_A2',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      {
        label: {
          id: 'Pemotongan Satu Masa Pajak',
          en: 'Pemotongan Satu Masa Pajak'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_21_SATU_MASA',
        permission: 'IMPORT_BP_21_SATU_MASA',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      {
        label: {
          id: 'Pemotongan Satu Masa Pajak TL',
          en: 'Pemotongan Satu Masa Pajak TL'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_21_SATU_MASA_TIDAK_LEBIH',
        permission: 'IMPORT_BP_21_SATU_MASA',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      {
        label: {
          id: 'SSP Pasal 21',
          en: 'SSP Pasal 21'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/SSP_21',
        permission: 'IMPORT_SSP_21',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      {
        label: {
          id: 'Daftar Biaya Pasal 21',
          en: 'Daftar Biaya Pasal 21'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/DAFTAR_BIAYA_21',
        permission: 'IMPORT_DAFTAR_BIAYA_21',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      {
        label: {
          id: 'Induk Pasal 21',
          en: 'Induk Pasal 21'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/INDUK_21',
        permission: 'IMPORT_INDUK_21',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      {
        label: {
          id: 'Lawan Transaksi 21',
          en: 'Lawan Transaksi 21'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/LK_21',
        permission: 'IMPORT_LK_21'
      },
    ]
  },

  {
    label: {
      id: 'Import PPh 23/26',
      en: 'Import PPh 23/26'
    },
    children: [
      {
        label: {
          id: 'BP 23',
          en: 'BP 23'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_23',
        permission: 'IMPORT_BP_23',
        user: true
      },
      {
        label: {
          id: 'BP 26',
          en: 'BP 26'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_26',
        permission: 'IMPORT_BP_26',
        user: true
      },
      {
        label: {
          id: 'BP 23 Gabung BP 26',
          en: 'BP 23 Gabung BP 26'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_2326',
        permission: 'IMPORT_BP_2326',
        user: true
      },
      {
        label: {
          id: 'Tarif BP 23 Jasa Lain',
          en: 'Tarif BP 23 Jasa Lain'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/23_TARIF_JASA_LAIN',
        permission: 'IMPORT_23_TARIF_JASA_LAIN',
        admin: true
      },
      {
        label: {
          id: 'SSP Pasal 23/26',
          en: 'SSP Pasal 23/26'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/SSP_23',
        permission: 'IMPORT_SSP_23',
        user: true
      },
      {
        label: {
          id: 'PBK 23/26',
          en: 'PBK 23/26'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/IMPORT_PBK',
        permission: 'IMPORT_PBK',
        user: true
      },
      {
        label: {
          id: 'Induk Pasal 23',
          en: 'Induk Pasal 23'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/INDUK_23',
        permission: 'SPT_INDUK_23',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      {
        label: {
          id: 'Lawan Transaksi 23/26',
          en: 'Lawan Transaksi 23/26'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/IMPORT_PBK23',
        permission: 'IMPORT_PBK',
        user: true
      }
    ]
  },

  {
    label: {
      id: 'Import PPh 4(2)',
      en: 'Import PPh 4(2)'
    },
    children: [
      {
        label: {
          id: 'BP 4(2)',
          en: 'BP 4(2)'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_4a2',
        permission: 'IMPORT_BP_4a2',
        user: true
      },
      {
        label: {
          id: 'BP 4(2) Gabung',
          en: 'BP 4(2) Gabung'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_4a2_GABUNG',
        permission: 'IMPORT_BP_4a2_GABUNG',
        user: true
      },
      {
        label: {
          id: 'Daftar Pemotongan 4 (2)',
          en: 'Daftar Pemotongan 4 (2)'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/DAFTAR_PEMOTONGAN_4a2',
        permission: 'IMPORT_BP_4a2',
        user: true
      },
      {
        label: {
          id: 'SSP Pasal 4(2)',
          en: 'SSP Pasal 4(2)'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/SSP_4a2',
        permission: 'IMPORT_SSP_4a2',
        user: true
      },
      {
        label: {
          id: 'PBK 4(2)',
          en: 'PBK 4(2)'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/PBK_4a2',
        permission: 'IMPORT_4a2',
        user: true
      },
      {
        label: {
          id: 'Induk Pasal 4 (2)',
          en: 'Induk Pasal 4 (2)'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/INDUK_4A2',
        permission: 'SPT_INDUK_4A2',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      {
        label: {
          id: 'Induk 4(2) Penghasilan Tertentu',
          en: 'Induk 4(2) Penghasilan Tertentu'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/INDUK_PT_4a2',
        permission: 'IMPORT_4a2',
        user: true
      },
      {
        label: {
          id: 'Lawan Transaksi 4(2)',
          en: 'Lawan Transaksi 4(2)'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/LK_4a2',
        permission: 'IMPORT_4a2',
        user: true
      }
    ]
  },

  {
    label: {
      id: 'Import PPh 22',
      en: 'Import PPh 22'
    },
    children: [
      {
        label: {
          id: 'Bukti Pungut ',
          en: 'Bukti Pungut '
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_22',
        permission: 'BP_22',
        user: true
      },
      // {
      //   label: {
      //     id: 'Bukti Pungut Industri Eksportir',
      //     en: 'Bukti Pungut Industri Eksportir'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/IMPORT/BP_22_industri_eksportir',
      //   permission: 'BP_22_industri_eksportir',
      //   user: true
      // },
      // {
      //   label: {
      //     id: 'Bukti Pungut Industri Importir',
      //     en: 'Bukti Pungut Industri Importir'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/IMPORT/BP_22_industri_importir',
      //   permission: 'BP_22_industri_eksportir',
      //   user: true
      // },
      // {
      //   label: {
      //     id: 'Bukti Pungut Badan Tertentu',
      //     en: 'Bukti Pungut Badan Tertentu'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/IMPORT/BP_22_badan_tertentu',
      //   permission: 'BP_22_badan_tertentu',
      //   user: true
      // },
      {
        label: {
          id: 'SSP Dibayar Sendiri',
          en: 'SSP Dibayar Sendiri'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/SSP_22_dibayar_sendiri',
        permission: 'SSP_22_dibayar_sendiri',
        user: true
      },
      // {
      //   label: {
      //     id: 'SSP Bank Devisa',
      //     en: 'SSP Bank Devisa'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/IMPORT/SSP_22_bank_devisa',
      //   permission: 'SSP_22_bank_devisa',
      //   user: true
      // },
      // {
      //   label: {
      //     id: 'SSP Bendaharawan',
      //     en: 'SSP Bendaharawan'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/IMPORT/SSP_22_bendaharawan',
      //   permission: 'SSP_22_bendaharawan',
      //   user: true
      // },
      {
        label: {
          id: 'SSP 22',
          en: 'SSP 22'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/SSP_22',
        permission: 'SSP_22',
        user: true
      },
      {
        label: {
          id: 'PBK 22',
          en: 'PBK 22'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/PBK_22',
        permission: 'PBK_22',
        user: true
      },
      {
        label: {
          id: 'Induk Pasal 22',
          en: 'Induk Pasal 22'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/INDUK_22',
        permission: 'SPT_INDUK_22',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      {
        label: {
          id: 'Lawan Transaksi 22',
          en: 'Lawan Transaksi 22'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/LK_22',
        permission: 'LK_22',
        user: true
      }
    ]
  },

  {
    label: {
      id: 'Import PPh 15',
      en: 'Import PPh 15'
    },
    children: [
      {
        label: {
          id: 'Bukti Potong 15',
          en: 'Bukti Potong 15'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_15',
        permission: 'BP_15',
        user: true
      },
      {
        label: {
          id: 'Bukti Potong 24',
          en: 'Bukti Potong 24'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_24',
        permission: 'BP_24',
        user: true
      },
      {
        label: {
          id: 'SSP Setor Sendiri',
          en: 'SSP Setor Sendiri'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/SSP_15_SETOR_SENDIRI',
        permission: 'SSP_15',
        user: true
      },
      {
        label: {
          id: 'SSP 15',
          en: 'SSP 15'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/SSP_15',
        permission: 'SSP_15',
        user: true
      },
      {
        label: {
          id: 'PBK 15',
          en: 'PBK 15'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/PBK_15',
        permission: 'PBK_15',
        user: true
      },
      {
        label: {
          id: 'Induk Pasal 15',
          en: 'Induk Pasal 15'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/INDUK_15',
        permission: 'SPT_INDUK_15',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      {
        label: {
          id: 'Lawan Transaksi 15',
          en: 'Lawan Transaksi 15'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/LK_15',
        permission: 'LK_15',
        user: true
      }
    ]
  },

  {
    label: {
      id: 'Import PPh 25',
      en: 'Import PPh 25'
    },
    children: [
      {
        label: {
          id: 'Daftar Angsuran 25',
          en: 'Daftar Angsuran 25'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_25',
        permission: 'BP_25',
        user: true
      },
      {
        label: {
          id: 'SSP 25',
          en: 'SSP 25'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/SSP_25',
        permission: 'SSP_25',
        user: true
      },
    ]
  },

  {
    label: {
      id: 'Summary',
      en: 'Summary'
    },
    children: [{
      label: {
        id: 'Pasal 21',
        en: 'Pasal 21'
      },
      iconClassName: 'mdi mdi-file-multiple',
      path: '/summary/21',
      user: true
    },{
      label: {
        id: 'Pasal 23',
        en: 'Pasal 23'
      },
      iconClassName: 'mdi mdi-file-multiple',
      path: '/summary/23',
      user: true
    },{
      label: {
        id: 'Pasal 26',
        en: 'Pasal 26'
      },
      iconClassName: 'mdi mdi-file-multiple',
      path: '/summary/26',
      user: true
    },{
      label: {
        id: 'Pasal 4(2)',
        en: 'Pasal 4(2)'
      },
      iconClassName: 'mdi mdi-file-multiple',
      path: '/summary/4A2',
      user: true
    },{
      label: {
        id: 'Pasal 22',
        en: 'Pasal 22'
      },
      iconClassName: 'mdi mdi-file-multiple',
      path: '/summary/22',
      user: true
    },{
      label: {
        id: 'Pasal 15',
        en: 'Pasal 15'
      },
      iconClassName: 'mdi mdi-file-multiple',
      path: '/summary/15',
      user: true
    },{
      label: {
        id: 'SSP/PBK',
        en: 'SSP/PBK'
      },
      iconClassName: 'mdi mdi-file-multiple',
      path: '/summary/SSPPBK',
      user: true
    },{
      label: {
        id: 'Induk Pasal 21',
        en: 'Induk Pasal 21'
      },
      iconClassName: 'mdi mdi-file-multiple',
      path: '/summary/INDUK_21',
      user: true
    },{
      label: {
        id: 'Unit Pasal 21',
        en: 'Unit Pasal 21'
      },
      iconClassName: 'mdi mdi-file-multiple',
      path: '/summary/UNIT_21',
      user: true
    }]
  },


  // {
  //   label: {
  //     id: 'eFiling',
  //     en: 'eFiling'
  //   },
  //   children: [{
  //     label: {
  //       id: 'PPh 21',
  //       en: 'PPh 21'
  //     },
  //     iconClassName: 'mdi mdi-file-multiple',
  //     path: '/eFiling/21',
  //   },{
  //     label: {
  //       id: 'PPh 23/26',
  //       en: 'PPh 23/26'
  //     },
  //     iconClassName: 'mdi mdi-file-multiple',
  //     path: '/eFiling/23',
  //   },{
  //     label: {
  //       id: 'PPh 4(2)',
  //       en: 'PPh 4(2)'
  //     },
  //     iconClassName: 'mdi mdi-file-multiple',
  //     path: '/eFiling/4A2',
  //   },
      // {
      //   label: {
      //     id: 'PPh 15',
      //     en: 'PPh 15'
      //   },
      //   iconClassName: 'mdi mdi-file-multiple',
      //   path: '/eFiling/15',
      // }
  //   ]
  // },

  {
    label: {
      id: 'eBilling',
      en: 'eBilling'
    },
    children: [
      {
        label: {
          id: 'Pengaturan',
          en: 'Pengaturan'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/EBILLING/SETTING',
        // permission: 'EBILLING_IMPORT_INQUIRY',
        user: true
      },
      {
        label: {
          id: 'Import Inquiry',
          en: 'Import Inquiry'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/EBILLING/IMPORT/INQUIRY',
        // permission: 'EBILLING_IMPORT_INQUIRY',
        user: true
      },
      {
        label: {
          id: 'Data Inquiry',
          en: 'Data Inquiry'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/EBILLING/INQUIRY',
        // permission: 'EBILLING_IMPORT_INQUIRY',
        user: true
      },
      {
        label: {
          id: 'Import ESSP',
          en: 'Import ESSP'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/EBILLING/IMPORT/ESSP',
        // permission: 'EBILLING_IMPORT_INQUIRY',
        user: true
      },
      {
        label: {
          id: 'Import ESSP WP Peredaran Tertentu',
          en: 'Import ESSP WP Peredaran Tertentu'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/EBILLING/IMPORT/ESSP-WP-PEREDARAN-TERTENTU',
        // permission: 'EBILLING_IMPORT_INQUIRY',
        user: true
      },
      {
        label: {
          id: 'Data ESSP',
          en: 'Data ESSP'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/EBILLING/DataESSP',
        // permission: 'EBILLING_IMPORT_INQUIRY',
        user: true
      },
      {
        label: {
          id: 'Payment Order',
          en: 'Payment Order'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/EBILLING/paymentOrder',
        // permission: 'EBILLING_IMPORT_INQUIRY',
        user: true
      },
      {
        label: {
          id: 'Payment Notification',
          en: 'Payment Notification'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/EBILLING/paymentNotification',
        // permission: 'EBILLING_IMPORT_INQUIRY',
        user: true
      },
      {
        label: {
          id: 'Akun Pembayaran',
          en: 'Akun Pembayaran'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/EBILLING/akunPembayaran',
        // permission: 'EBILLING_IMPORT_INQUIRY',
        user: true
      }
    ]
  },
  {
    label: {
      id: 'Log eBilling',
      en: 'Log eBilling'
    },
    children: [{
      label: {
        id: 'Log Export Data Inquiry',
        en: 'Log Export Data Inquiry'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/EBILLING/LOG/LogExportDataInquiry',
      user: true
    },{
      label: {
        id: 'Log Export Data SSP',
        en: 'Log Export Data SSP'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/EBILLING/LOG/LogExportDataSSP',
      user: true
    },{
      label: {
        id: 'Log Cetak Payment Order',
        en: 'Log Cetak Payment Order'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/EBILLING/LOG/LogCetakPaymentOrder',
      user: true
    },{
      label: {
        id: 'Log Cetak Payment Notification',
        en: 'Log Cetak Payment Notification'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/EBILLING/LOG/LogCetakPaymenNotification',
      user: true
    },{
      label: {
        id: 'Log Cetak BPN',
        en: 'Log Cetak BPN'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/EBILLING/LOG/LogCetakBPN',
      user: true
    },{
      label: {
        id: 'Log Cetak Laporan PO',
        en: 'Log Cetak Laporan PO'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/EBILLING/LOG/LogCetakLaporanPO',
      user: true
    },{
      label: {
        id: 'Log Cetak Laporan PN',
        en: 'Log Cetak Laporan PN'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/EBILLING/LOG/LogCetakLaporanPN',
      user: true
    }]
  },
  {
    label: {
      id: 'Log',
      en: 'Log'
    },
    children: [{
      label: {
        id: 'Log Csv',
        en: 'Log Csv'
      },
      iconClassName: 'mdi mdi-file-multiple',
      path: '/logExport/CSV',
      user: true
    },
      {
        label: {
          id: 'Log Pdf',
          en: 'Log Pdf'
        },
        iconClassName: 'mdi mdi-file-multiple',
        path: '/logExport/PDF',
        user: true
      },
      {
        label: {
          id: 'Log eFiling',
          en: 'Log eFiling'
        },
        iconClassName: 'mdi mdi-file-multiple',
        path: '/eFiling',
        user: true
      },
      {
        label: {
          id: 'Log Email',
          en: 'Log Email'
        },
        iconClassName: 'mdi mdi-file-multiple',
        path: '/LOG_EMAIL',
        permission: 'LOG_EMAIL',
        user: true
      }
    ]
  },
  {
    label: {
      id: 'Master Data Tarif Pajak Pasal 21',
      en: 'Master Data Tarif Pajak Pasal 21'
    },
    children: [
      {
        label: {
          id: 'Pasal 17 Berlapis',
          en: 'Pasal 17 Berlapis'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-pasal-17-berlapis',
        permission: '',
        admin: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'Biaya Jabatan',
          en: 'Biaya Jabatan'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-biaya-jabatan',
        permission: '',
        admin: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'Biaya Pensiun',
          en: 'Biaya Pensiun'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-biaya-pensiun',
        permission: '',
        admin: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'PTKP',
          en: 'PTKP'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-ptkp',
        permission: '',
        admin: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'Tarif Pensiun',
          en: 'Tarif Pensiun'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-pensiun',
        permission: '',
        admin: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'Upah Harian',
          en: 'Upah Harian'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-upah-harian',
        permission: '',
        admin: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'Upah Kumulatif',
          en: 'Upah Kumulatif'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-upah-kumulatif',
        permission: '',
        admin: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'Final Pesangon',
          en: 'Final Pesangon'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-final-pesangon',
        permission: '',
        admin: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'Honor/Imbalan PNS',
          en: 'Honor/Imbalan PNS'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-honor-pns',
        permission: '',
        admin: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      }
    ]
  },
  {
    label: {
      id: 'Master Data Kode SPT Pasal 21',
      en: 'Master Data Kode SPT Pasal 21'
    },
    children: [
      {
        label: {
          id: 'Status Karyawan A1',
          en: 'Status Karyawan A1'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-status-karyawan-a1',
        permission: '',
        admin: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'Status Karyawan A2',
          en: 'Status Karyawan A2'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-status-karyawan-a2',
        permission: '',
        admin: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'KAP',
          en: 'KAP'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-kap',
        permission: '',
        admin: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'KJS',
          en: 'KJS'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-kjs',
        permission: '',
        admin: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'SSP Description',
          en: 'SSP Description'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-ssp-description',
        permission: '',
        admin: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'Golongan A2',
          en: 'Golongan A2'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-golongan-a2',
        permission: '',
        admin: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      }
    ]
  },
  {
    label: {
      id: 'Master Data 23/26',
      en: 'Master Data 23/26'
    },
    children: [
      {
        label: {
          id: 'KAP',
          en: 'KAP'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-23-kap',
        permission: '',
        admin: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'KJS',
          en: 'KJS'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-23-kjs',
        permission: '',
        admin: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'Tarif 23 Lain',
          en: 'Tarif 23 Lain'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/tarif-23-lain',
        permission: '',
        admin: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'Tarif 23',
          en: 'Tarif 23'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/tarif-23',
        permission: '',
        owner: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'Tarif 26',
          en: 'Tarif 26'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/tarif-26',
        permission: '',
        owner: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
    ]
  },
  {
    label: {
      id: 'Master Data 4 (2)',
      en: 'Master Data 4 (2)'
    },
    children: [
      {
        label: {
          id: 'Tarif 4 (2)',
          en: 'Tarif 4 (2)'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/tarif-4a2',
        permission: '',
        owner: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
    ]
  },
  {
    label: {
      id: 'Master Data 22',
      en: 'Master Data 22'
    },
    children: [
      {
        label: {
          id: 'Komoditi 22',
          en: 'Komoditi 22'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/komoditi-22',
        permission: '',
        admin: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'Tarif 22',
          en: 'Tarif 22'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/tarif-22',
        permission: '',
        admin: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
    ]
  },
  /*{
    label: {
      id: 'Master Data 15',
      en: 'Master Data 15'
    },
    children: [
      {
        label: {
          id: 'Tarif 15',
          en: 'Tarif 15'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/tarif-15',
        permission: '',
        admin: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
    ]
  },*/
  {
    label: {
      id: 'Master Data 22',
      en: 'Master Data 22'
    },
    children: [
      {
        label: {
          id: 'Tarif 22',
          en: 'Tarif 22'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/company/tarif-22',
        permission: '',
        owner: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
    ]
  },
  {
    label: {
      id: 'Master Data 15',
      en: 'Master Data 15'
    },
    children: [
      {
        label: {
          id: 'Tarif 15',
          en: 'Tarif 15'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/company/tarif-15',
        permission: '',
        owner: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
    ]
  },
  {
    label: {
      id: 'Lawan Transaksi',
      en: 'Lawan Transaksi'
    },
    children: [
      {
        label: {
          id: 'PPH 21 A1',
          en: 'PPH 21 A1'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/lawanTransaksi/pph21/a1',
        permission: '',
        user: true,
        // owner: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'PPH 21 A2',
          en: 'PPH 21 A2'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/lawanTransaksi/pph21/a2',
        permission: '',
        user: true,
        // owner: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'PPH 21 Pp',
          en: 'PPH 21 Pp'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/lawanTransaksi/pph21/pp',
        permission: '',
        user: true,
        // owner: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'PPH 23/26',
          en: 'PPH 23/26'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/lawanTransaksi/pph23',
        permission: '',
        user: true,
        // owner: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'PPH 4(2)',
          en: 'PPH 4(2)'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/lawanTransaksi/4a2',
        permission: '',
        user: true,
        // owner: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'PPH 22',
          en: 'PPH 22'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/lawanTransaksi/22',
        permission: '',
        user: true,
        // owner: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'PPH 15',
          en: 'PPH 15'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/lawanTransaksi/15',
        permission: '',
        user: true,
        // owner: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
    ]
  },
  {
    label: {
      id: 'Pengaturan',
      en: 'Pengaturan'
    },
    children: [
      {
        label: {
          id: 'Ekspor PDF & Email A1',
          en: 'Ekspor PDF & Email A1'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/SETTING_EXPORT',
        permission: '',
        user: true,
        // owner: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
    ]
  },

  /*{
    label: {
      id: 'Kalkulasi Induk',
      en: 'Kalkulasi Induk'
    },
    children: [{
      label: {
        id: 'Pasal 21',
        en: 'Pasal 21'
      },
      iconClassName: 'mdi mdi-file-multiple',
      path: '/kalkulasiInduk/21',
      admin: true
    },{
      label: {
        id: 'Pasal 23',
        en: 'Pasal 23'
      },
      iconClassName: 'mdi mdi-file-multiple',
      path: '/kalkulasiInduk/23',
      admin: true
    },{
      label: {
        id: 'Pasal 4(2)',
        en: 'Pasal 4(2)'
      },
      iconClassName: 'mdi mdi-file-multiple',
      path: '/kalkulasiInduk/4A2',
      admin: true
    }]
  },*/

  {
    label: {
      id: 'Pengaturan Nomor BP',
      en: 'Pengaturan Nomor BP'
    },
    children: [
      {
        label: {
          id: 'Nomor BP 21',
          en: 'Nomor BP 21'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/nomorBp/21',
        permission: '',
        // user: true,
        owner: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },{
        label: {
          id: 'Nomor BP 23',
          en: 'Nomor BP 23'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/nomorBp/23',
        permission: '',
        // user: true,
        owner: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },{
        label: {
          id: 'Nomor BP 4(2)',
          en: 'Nomor BP 4(2)'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/nomorBp/4a2',
        permission: '',
        // user: true,
        owner: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },{
        label: {
          id: 'Nomor BP 22',
          en: 'Nomor BP 22'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/nomorBp/22',
        permission: '',
        // user: true,
        owner: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },{
        label: {
          id: 'Nomor BP 15',
          en: 'Nomor BP 15'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/nomorBp/15',
        permission: '',
        // user: true,
        owner: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
    ]
  },
  {
    label: {
      id: 'Summary',
      en: 'Summary'
    },
    children: [{
      label: {
        id: 'Admin',
        en: 'Admin'
      },
      iconClassName: 'mdi mdi-file-multiple',
      path: '/summary-admin',
      admin: true
    }],
  },
  // ADMIN
  {
    label: {
      id: 'Administrator',
      en: 'Administrator'
    },
    children: [{
      label: {
        id: 'Flow Approval',
        en: 'Flow Approval'
      },
      iconClassName: 'mdi mdi-contacts',
      path: '/mainFlow',
      admin: true,
      level: CONSTANT.LEVEL.ROLE_OWNER
    },{
      label: {
        id: 'User',
        en: 'User'
      },
      iconClassName: 'mdi mdi-contacts',
      path: '/user',
      admin: true,
      level: CONSTANT.LEVEL.ROLE_ADMIN
    },
    {
      label: {
        id: 'Master KPP',
        en: 'Master KPP'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/master/kpp',
      permission: '',
      admin: true,
      level: CONSTANT.LEVEL.ROLE_ADMIN
    },
    {
      label: {
        id: 'Master Aplikasi',
        en: 'Master Aplikasi'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/master/applications',
      permission: '',
      admin: true,
      level: CONSTANT.LEVEL.ROLE_ADMIN
    },
    {
      label: {
        id: 'Master Custom Import',
        en: 'Master Custom Import'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/master/custom-import-headers',
      permission: '',
      admin: true,
      level: CONSTANT.LEVEL.ROLE_ADMIN
    },
    {
      label: {
        id: 'Log Import By App',
        en: 'Log Import By App'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/logImportCsvByApp',
      permission: '',
      admin: true,
      level: CONSTANT.LEVEL.ROLE_ADMIN
    },
      // {
      //   label: {
      //     id: 'Organisasi',
      //     en: 'Organization'
      //   },
      //   iconClassName: 'mdi mdi-contacts',
      //   path: '/organization',
      //   admin: true,
      //   level: CONSTANT.LEVEL.ROLE_ADMIN
      // },
      /*{
        label: {
          id: 'Permissions',
          en: 'Perizinan'
        },
        iconClassName: 'mdi mdi-contacts',
        path: '/permissions',
        admin: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },*/
      // {
      //   label: {
      //     id: 'Akses',
      //     en: 'Access'
      //   },
      //   iconClassName: 'mdi mdi-contacts',
      //   path: '/access',
      //   admin: true
      // },
      {
        label: {
          id: 'Pengaturan Akses',
          en: 'Akses Configuration'
        },
        iconClassName: 'mdi mdi-contacts',
        path: '/access-control',
        admin: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'Role Template',
          en: 'Template Role'
        },
        iconClassName: 'mdi mdi-contacts',
        admin: true,
        path: '/templateRole'
      },
      /*{
        label: {
          id: 'Logging Events',
          en: 'Logging Events'
        },
        iconClassName: 'mdi mdi-contacts',
        admin: true,
        path: '/loggingEvent'
      },*/
      {
        label: {
          id: 'Log Sign In',
          en: 'Log Sign In'
        },
        iconClassName: 'mdi mdi-contacts',
        admin: true,
        path: '/signInLog'
      },
      {
        label: {
          id: 'Log Validasi',
          en: 'Log Validasi'
        },
        iconClassName: 'mdi mdi-contacts',
        admin: true,
        path: '/validationLog'
      },
      {
        label: {
          id: 'Log Aplikasi',
          en: 'Log Aplikasi'
        },
        iconClassName: 'mdi mdi-contacts',
        admin: true,
        path: '/logApp'
      },
      {
        label: {
          id: 'Log eFiling',
          en: 'Log eFiling'
        },
        iconClassName: 'mdi mdi-contacts',
        admin: true,
        path: '/logEfiling',
      }
    ]
  },
  {
    label: {
      id: 'Pengaturan',
      en: 'Setting'
    },
    children: [
      {
        label: {
          id: 'E-Mail',
          en: 'E-Mail'
        },
        owner: true,
        iconClassName: 'mdi mdi-settings',
        path: '/SETTING_EMAIL',
        permission: 'SETTING_EMAIL'
      },
      {
        label: {
          id: 'Konten E-Mail',
          en: 'E-Mail Content'
        },
        owner: true,
        iconClassName: 'mdi mdi-settings',
        path: '/SETTING_EMAIL_CONTENT',
        permission: 'SETTING_EMAIL_CONTENT'
      },
      {
        label: {
          id: 'Jadwal E-Mail',
          en: 'E-Mail Schedule'
        },
        owner: true,
        iconClassName: 'mdi mdi-settings',
        path: '/SETTING_EMAIL_SCHEDULE',
        permission: 'SETTING_EMAIL_SCHEDULE'
      },
      {
        label: {
          id: 'E-Filing',
          en: 'E-Filing'
        },
        owner: true,
        iconClassName: 'mdi mdi-settings',
        path: '/SETTING_EFILING',
        permission: 'SETTING_EFILING'
      },
      {
        label: {
          id: 'Tampilan BP',
          en: 'Tampilan BP'
        },
        owner: true,
        iconClassName: 'mdi mdi-settings',
        path: '/SETTING_VIEW',
        permission: 'SETTING_VIEW'
      },
      {
        label: {
          id: 'Impor',
          en: 'Import'
        },
        owner: true,
        iconClassName: 'mdi mdi-settings',
        path: '/SETTING_IMPORT',
        permission: 'SETTING_IMPORT'
      },
      {
        label: {
          id: 'Role Template',
          en: 'Template Role'
        },
        iconClassName: 'mdi mdi-settings',
        owner: true,
        path: '/templateRole'
      },
      {
        label: {
          id: 'About',
          en: 'About'
        },
        iconClassName: 'mdi mdi-settings',
        owner: true,
        path: '/aboutCompany'
      }
    ]
  },
  {
    label: {
      id: 'Pengaturan',
      en: 'Setting'
    },
    children: [
      {
        label: {
          id: 'E-Mail',
          en: 'E-Mail'
        },
        admin: true,
        iconClassName: 'mdi mdi-settings',
        path: '/SETTING_EMAIL_ALL',
        permission: 'SETTING_EMAIL_ALL'
      },
      {
        label: {
          id: 'Konten E-Mail',
          en: 'E-Mail Content'
        },
        admin: true,
        iconClassName: 'mdi mdi-settings',
        path: '/SETTING_EMAIL_CONTENT_ALL',
        permission: 'SETTING_EMAIL_CONTENT_ALL'
      },
      {
        label: {
          id: 'Jadwal E-Mail',
          en: 'E-Mail Schedule'
        },
        admin: true,
        iconClassName: 'mdi mdi-settings',
        path: '/SETTING_EMAIL_SCHEDULE_ALL',
        permission: 'SETTING_EMAIL_SCHEDULE_ALL'
      },
      {
        label: {
          id: 'E-Filing',
          en: 'E-Filing'
        },
        admin: true,
        iconClassName: 'mdi mdi-settings',
        path: '/SETTING_EFILING_ALL',
        permission: 'SETTING_EFILING_ALL'
      },
      {
        label: {
          id: 'Impor Global',
          en: 'Import Global'
        },
        admin: true,
        iconClassName: 'mdi mdi-settings',
        path: '/SETTING_IMPORT_GLOBAL',
        permission: 'SETTING_IMPORT_GLOBAL'
      },
      {
        label: {
          id: 'Tampilan BP',
          en: 'Tampilan BP'
        },
        admin: true,
        iconClassName: 'mdi mdi-settings',
        path: '/SETTING_VIEW_ALL',
        permission: 'SETTING_VIEW_ALL'
      },
      {
        label: {
          id: 'Impor',
          en: 'Import'
        },
        admin: true,
        iconClassName: 'mdi mdi-settings',
        path: '/SETTING_IMPORT_ALL',
        permission: 'SETTING_IMPORT_ALL'
      },
      {
        label: {
          id: 'Ekspor',
          en: 'Export'
        },
        admin: true,
        iconClassName: 'mdi mdi-settings',
        path: '/SETTING_EXPORT_ADMIN',
        permission: 'SETTING_EXPORT_ADMIN'
      },
    ]
  },
]

const companyMenu = [
  {
    label: {
      id: 'Main',
      en: 'Main'
    },
    children: [{
      label: {
        id: 'Dasboard',
        en: 'Dasboard'
      },
      iconClassName: 'mdi mdi-view-grid',
      path: '/'
    }]
  },
  {
    label: {
      id: 'Organisasi',
      en: 'Organization'
    },
    children: [{
      label: {
        id: 'Organisasi',
        en: 'Organization'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/organization',
      permission: 'organization'
    }]
  },
  {
    label: {
      id: 'Pengguna',
      en: 'User'
    },
    children: [{
      label: {
        id: 'User',
        en: 'User'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/user',
      permission: 'user'
    },{
      label: {
        id: 'Owner',
        en: 'Owner'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/owner',
      permission: 'owner'
    }]
  },
  // IMPORT OWNER
  {
    label: {
      id: 'Import',
      en: 'Import'
    },
    children: [
      {
        label: {
          id: 'Pengguna',
          en: 'User'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/USER',
        level: CONSTANT.LEVEL.ROLE_OWNER,
        user: true
      },
      {
        label: {
          id: 'Penandatangan PPh 21',
          en: 'Signer PPh 21'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/SIGNER21',
        // permission: 'IMPORT_BP_21_TF',
        level: CONSTANT.LEVEL.ROLE_OWNER,
        user: true
      },
      {
        label: {
          id: 'Penandatangan PPh 23',
          en: 'Signer PPh 23'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/SIGNER23',
        // permission: 'IMPORT_BP_21_TF',
        level: CONSTANT.LEVEL.ROLE_OWNER,
        user: true
      },
      {
        label: {
          id: 'Penandatangan PPh 4(2)',
          en: 'Signer PPh 4(2)'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/SIGNER4A2',
        // permission: 'IMPORT_BP_21_TF',
        level: CONSTANT.LEVEL.ROLE_OWNER,
        user: true
      },
      {
        label: {
          id: 'Penandatangan PPh 22',
          en: 'Signer PPh 22'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/SIGNER22',
        // permission: 'IMPORT_BP_21_TF',
        level: CONSTANT.LEVEL.ROLE_OWNER,
        user: true
      },
      {
        label: {
          id: 'Penandatangan PPh 15',
          en: 'Signer PPh 15'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/SIGNER15',
        // permission: 'IMPORT_BP_21_TF',
        level: CONSTANT.LEVEL.ROLE_OWNER,
        user: true
      },
    ]
  },
  {
    label: {
      id: 'PPh 21',
      en: 'PPh 21'
    },
    children: [{
      label: {
        id: 'Daftar SPT',
        en: 'SPT List'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/SPT_21',
      permission: 'SPT_21',
      user: true
    }
    ]
  },


  // {
  //   label: {
  //     id: 'eFiling',
  //     en: 'eFiling'
  //   },
  //   children: [{
  //     label: {
  //       id: 'PPh 21',
  //       en: 'PPh 21'
  //     },
  //     iconClassName: 'mdi mdi-file-multiple',
  //     path: '/eFiling/21',
  //   },{
  //     label: {
  //       id: 'PPh 23/26',
  //       en: 'PPh 23/26'
  //     },
  //     iconClassName: 'mdi mdi-file-multiple',
  //     path: '/eFiling/23',
  //   },{
  //     label: {
  //       id: 'PPh 4(2)',
  //       en: 'PPh 4(2)'
  //     },
  //     iconClassName: 'mdi mdi-file-multiple',
  //     path: '/eFiling/4A2',
  //   },
      // {
      //   label: {
      //     id: 'PPh 15',
      //     en: 'PPh 15'
      //   },
      //   iconClassName: 'mdi mdi-file-multiple',
      //   path: '/eFiling/15',
      // }
  //   ]
  // },

  {
    label: {
      id: 'Log',
      en: 'Log'
    },
    children: [{
      label: {
        id: 'Log Csv',
        en: 'Log Csv'
      },
      iconClassName: 'mdi mdi-file-multiple',
      path: '/logExport/all',
      user: true
    },
      {
        label: {
          id: 'Log Pdf',
          en: 'Log Pdf'
        },
        iconClassName: 'mdi mdi-file-multiple',
        path: '/logExport/PDF',
        user: true
      },
      {
        label: {
          id: 'Log eFiling',
          en: 'Log eFiling'
        },
        iconClassName: 'mdi mdi-file-multiple',
        path: '/eFiling',
        user: true
      },
      // {
      //   label: {
      //     id: 'PPh 23/26',
      //     en: 'PPh 23/26'
      //   },
      //   iconClassName: 'mdi mdi-file-multiple',
      //   path: '/logExport/23',
      // },{
      //   label: {
      //     id: 'PPh 4(2)',
      //     en: 'PPh 4(2)'
      //   },
      //   iconClassName: 'mdi mdi-file-multiple',
      //   path: '/logExport/4A2',
      // },
      // {
      //   label: {
      //     id: 'PPh 15',
      //     en: 'PPh 15'
      //   },
      //   iconClassName: 'mdi mdi-file-multiple',
      //   path: '/logExport/15',
      // }
    ]
  },

  // {
  //   label: {
  //     id: 'Log',
  //     en: 'Log'
  //   },
  //   children: [
  //     // {
  //     //   label: {
  //     //     id: 'Log File Lapor',
  //     //     en: 'Log File Lapor'
  //     //   },
  //     //   iconClassName: 'mdi mdi-file-multiple',
  //     //   path: '/LOG_FILE_LAPOR_21',
  //     //   permission: 'LOG_FILE_LAPOR_21'
  //     // },
  //     {
  //       label: {
  //         id: 'Log Export CSV',
  //         en: 'Log Export CSV'
  //       },
  //       iconClassName: 'mdi mdi-file-multiple',
  //       path: '/LOG_EXPORT_CSV_21',
  //       permission: 'LOG_EXPORT_CSV_21'
  //     },
  //     {
  //       label: {
  //         id: 'Log Email',
  //         en: 'Log Email'
  //       },
  //       iconClassName: 'mdi mdi-file-multiple',
  //       path: '/LOG_EMAIL',
  //       permission: 'LOG_EMAIL'
  //     }
  //   ]
  // },
  {
    label: {
      id: 'Master Data 23/26',
      en: 'Master Data 23/26'
    },
    children: [
      {
        label: {
          id: 'Tarif 23',
          en: 'Tarif 23'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/tarif-23',
        permission: '',
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'Tarif 23 Lain',
          en: 'Tarif 23 Lain'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/tarif-23-lain',
        permission: '',
        owner: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'Tarif 26',
          en: 'Tarif 26'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/tarif-26',
        permission: '',
        owner: true,
        level: CONSTANT.LEVEL.ROLE_ADMIN
      }
    ]
  },
  {
    label: {
      id: 'Master Data 4 (2)',
      en: 'Master Data 4 (2)'
    },
    children: [
      {
        label: {
          id: 'Tarif 4 (2)',
          en: 'Tarif 4 (2)'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/tarif-4a2',
        permission: '',
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
    ]
  },
  // END IMPORT OWNER
  {
    label: {
      id: 'Lawan Transaksi',
      en: 'Lawan Transaksi'
    },
    children: [
      {
        label: {
          id: 'PPH 21 A1',
          en: 'PPH 21 A1'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/lawanTransaksi/pph21/a1',
        permission: '',
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'PPH 21 A2',
          en: 'PPH 21 A2'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/lawanTransaksi/pph21/a2',
        permission: '',
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'PPH 21 Pp',
          en: 'PPH 21 Pp'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/lawanTransaksi/pph21/pp',
        permission: '',
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'PPH 23/26',
          en: 'PPH 23/26'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/lawanTransaksi/pph23',
        permission: '',
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'PPH 4(2)',
          en: 'PPH 4(2)'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/lawanTransaksi/4a2',
        permission: '',
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
      {
        label: {
          id: 'PPH 22',
          en: 'PPH 22'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/lawanTransaksi/22',
        permission: '',
        level: CONSTANT.LEVEL.ROLE_ADMIN
      },
    ]
  },
  {
    label: {
      id: 'Pengaturan',
      en: 'Setting'
    },
    children: [
      {
        label: {
          id: 'E-Mail',
          en: 'E-Mail'
        },
        admin: true,
        iconClassName: 'mdi mdi-settings',
        path: '/SETTING_EMAIL',
        permission: 'SETTING_EMAIL'
      },
      {
        label: {
          id: 'Konten E-Mail',
          en: 'E-Mail Content'
        },
        admin: true,
        iconClassName: 'mdi mdi-settings',
        path: '/SETTING_EMAIL_CONTENT',
        permission: 'SETTING_EMAIL_CONTENT'
      },
      {
        label: {
          id: 'Jadwal E-Mail',
          en: 'E-Mail Schedule'
        },
        admin: true,
        iconClassName: 'mdi mdi-settings',
        path: '/SETTING_EMAIL_SCHEDULE',
        permission: 'SETTING_EMAIL_SCHEDULE'
      },
      {
        label: {
          id: 'E-Filing',
          en: 'E-Filing'
        },
        admin: true,
        iconClassName: 'mdi mdi-settings',
        path: '/SETTING_EFILING',
        permission: 'SETTING_EFILING'
      },
      {
        label: {
          id: 'Role Template',
          en: 'Template Role'
        },
        iconClassName: 'mdi mdi-settings',
        admin: true,
        path: '/templateRole'
      },
      {
        label: {
          id: 'About',
          en: 'About'
        },
        iconClassName: 'mdi mdi-settings',
        path: '/aboutCompany'
      }
    ]
  }
]

const sptPph21Menus = [
  {
    label: {
      id: 'Bukti Potong',
      en: 'Bukti Potong'
    },
    children: [{
      label: {
        id: 'Tidak Final',
        en: 'Tidak Final'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/BP_21_TF',
      permission: 'BP_21_TF'
    },
      {
        label: {
          id: 'Final',
          en: 'Final'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/BP_21_F',
        permission: 'BP_21_F'
      },
      {
        label: {
          id: 'BP 1721 A1',
          en: 'BP 1721 A1'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/BP_21_A1',
        permission: 'BP_21_A1',
        decemberOnly: true
      },
      {
        label: {
          id: 'BP 1721 A2',
          en: 'BP 1721 A2'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/BP_21_A2',
        permission: 'BP_21_A2',
        decemberOnly: true,
        bendaharaOnly: true
      }
    ]
  },
  {
    label: {
      id: 'Pemotongan',
      en: 'Pemotongan'
    },
    children: [{
      label: {
        id: 'Satu Masa',
        en: 'Satu Masa'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/BP_21_SATU_MASA',
      permission: 'BP_21_SATU_MASA'
    },
      {
        label: {
          id: 'Satu Tahun',
          en: 'Satu Tahun'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/BP_21_SATU_TAHUN',
        permission: 'BP_21_SATU_TAHUN',
        decemberOnly: true
      },
    ]
  },
  {
    label: {
      id: 'Lainnya',
      en: 'Lainnya'
    },
    children: [{
      label: {
        id: 'Daftar Biaya',
        en: 'Daftar Biaya'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/DAFTAR_BIAYA_21',
      permission: 'DAFTAR_BIAYA_21',
      decemberOnly: true
    },
      {
        label: {
          id: 'Daftar SSP',
          en: 'Daftar SSP'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/DAFTAR_SSP_21',
        permission: 'DAFTAR_SSP_21'
      },
      {
        label: {
          id: 'SPT Induk',
          en: 'SPT Induk'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/SPT_INDUK_21',
        permission: 'SPT_INDUK_21'
      },
    ]
  },
  {
    label: {
      id: 'Lapor',
      en: 'Lapor'
    },
    children: [
      {
        label: {
          id: 'File Lapor',
          en: 'File Lapor'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/FILE_LAPOR_21',
        permission: 'FILE_LAPOR_21'
      }
    ]
  },
  {
    label: {
      id: 'Import',
      en: 'Import'
    },
    children: [
      {
        label: {
          id: 'BP Tidak Final',
          en: 'BP Tidak Final'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_21_TF',
        permission: 'IMPORT_BP_21_TF'
      },
      {
        label: {
          id: 'BP Final',
          en: 'BP Final'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_21_F',
        permission: 'IMPORT_BP_21_F'
      },
      {
        label: {
          id: 'BP 1721 A1',
          en: 'BP 1721 A1'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_21_A1',
        permission: 'IMPORT_BP_21_A1',
        decemberOnly: true
      },
      {
        label: {
          id: 'BP 1721 A2',
          en: 'BP 1721 A2'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_21_A2',
        permission: 'IMPORT_BP_21_A2',
        decemberOnly: true,
        bendaharaOnly: true
      },
      {
        label: {
          id: 'Pemotongan Satu Masa Pajak',
          en: 'Pemotongan Satu Masa Pajak'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_21_SATU_MASA',
        permission: 'IMPORT_BP_21_SATU_MASA'
      },
      {
        label: {
          id: 'Pemotongan Satu Masa Pajak TL',
          en: 'Pemotongan Satu Masa Pajak TL'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_21_SATU_MASA_TIDAK_LEBIH',
        permission: 'BP_21_SATU_MASA_TIDAK_LEBIH'
      },
      {
        label: {
          id: 'SSP Pasal 21',
          en: 'SSP Pasal 21'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/SSP_21',
        permission: 'IMPORT_SSP_21'
      },
      {
        label: {
          id: 'Daftar Biaya Pasal 21',
          en: 'Daftar Biaya Pasal 21'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/DAFTAR_BIAYA_21',
        permission: 'IMPORT_DAFTAR_BIAYA_21'
      },
      {
        label: {
          id: 'Induk Pasal 21',
          en: 'Induk Pasal 21'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/INDUK_21',
        permission: 'IMPORT_INDUK_21'
      },
      {
        label: {
          id: 'Lawan Transaksi 21',
          en: 'Lawan Transaksi 21'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/LK_21',
        permission: 'IMPORT_LK_21'
      },
    ]
  },
  {
    label: {
      id: 'Export',
      en: 'Export'
    },
    children: [
      {
        label: {
          id: 'Export CSV',
          en: 'Export CSV'
        },
        iconClassName: 'mdi mdi-print',
        path: '/EXPORT_CSV_21',
        permission: 'EXPORT_CSV_21'
      }
    ]
  },
  {
    label: {
      id: 'Log',
      en: 'Log'
    },
    children: [
      {
        label: {
          id: 'Log Csv',
          en: 'Log Csv'
        },
        iconClassName: 'mdi mdi-file-multiple',
        path: '/logExport/CSV',
        user: true
      },
      {
        label: {
          id: 'Log Pdf',
          en: 'Log Pdf'
        },
        iconClassName: 'mdi mdi-file-multiple',
        path: '/logExport/PDF',
        user: true
      },
      {
        label: {
          id: 'Log eFiling',
          en: 'Log eFiling'
        },
        iconClassName: 'mdi mdi-file-multiple',
        path: '/eFiling',
        user: true
      },
      {
        label: {
          id: 'Log Email',
          en: 'Log Email'
        },
        iconClassName: 'mdi mdi-file-multiple',
        path: '/LOG_EMAIL',
        permission: 'LOG_EMAIL',
        user: true
      }
    ]
  },
  {
    label: {
      id: 'Pengaturan',
      en: 'Setting'
    },
    children: [
      {
        label: {
          id: 'Nomor Bukti Potong',
          en: 'Nomor Bukti Potong'
        },
        iconClassName: 'mdi mdi-file-multiple',
        path: '/SETTING_NO_BUPOT_21',
        user: true
      },
    ]
  },
]

const sptPph22Menus = [

  {
    label: {
      id: 'Bukti Pungut',
      en: 'Bukti Pungut'
    },
    children: [{
      label: {
        id: 'Pph 22',
        en: 'Pph 22'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/BP_22',
      permission: 'BP_22',
      pemungut: true
    }
    ]
  },
  {
    label: {
      id: 'Daftar SSP PPh 22',
      en: 'Daftar SSP PPh 22'
    },
    children: [{
      label: {
        id: 'Atas Pembelian Barang Oleh Bendaharawan',
        en: 'Atas Pembelian Barang Oleh Bendaharawan'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/ssp_pasal22_pembelian_barang',
      permission: 'ssp-pasal22-pembelian-barang'
    }
    ]
  },
  {
    label: {
      id: 'Daftar SSP PPh 22 Impor',
      en: 'Daftar SSP PPh 22 Impor'
    },
    children: [{
      label: {
        id: 'Oleh Bank Devisa dan Bendaharawan/Badan Tertentu Yang Ditunjuk',
        en: 'Oleh Bank Devisa dan Bendaharawan/Badan Tertentu Yang Ditunjuk'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/ssp_bank_devisa',
      permission: 'ssp_bank_devisa'
    },
    {
      label: {
        id: 'Yang Dibayar Sendiri',
        en: 'Yang Dibayar Sendiri'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/ssp_dibayar_sendiri',
      permission: 'ssp_dibayar_sendiri'
    }
    ]
  },
  {
    label: {
      id: 'Lainnya',
      en: 'Lainnya'
    },
    children: [
      /*{
        label: {
          id: 'Daftar Biaya',
          en: 'Daftar Biaya'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/DAFTAR_BIAYA_22',
        permission: 'DAFTAR_BIAYA_22',
        decemberOnly: true
      },*/
      {
        label: {
          id: 'Daftar SSP',
          en: 'Daftar SSP'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/SSP22',
        permission: 'SSP22'
      },
      {
        label: {
          id: 'Daftar PBK',
          en: 'Daftar PBK',
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/PBK22',
        permission: 'PBK22'
      },
      {
        label: {
          id: 'SPT Induk',
          en: 'SPT Induk'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/SPT22INDUK',
        permission: 'SPT22INDUK'
      },
    ]
  },
  {
    label: {
      id: 'Lapor',
      en: 'Lapor'
    },
    children: [
      {
        label: {
          id: 'File Lapor',
          en: 'File Lapor'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/FILE_LAPOR_22',
        permission: 'FILE_LAPOR_22'
      }
    ]
  },
  {
    label: {
      id: 'Import',
      en: 'Import'
    },
    children: [
      {
        label: {
          id: 'Bukti Pungut ',
          en: 'Bukti Pungut '
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_22',
        permission: 'BP_22',
        user: true,
        pemungut: true
      },
      // {
      //   label: {
      //     id: 'Bukti Pungut Industri Eksportir',
      //     en: 'Bukti Pungut Industri Eksportir'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/IMPORT/BP_22_industri_eksportir',
      //   permission: 'BP_22_industri_eksportir',
      //   user: true
      // },
      // {
      //   label: {
      //     id: 'Bukti Pungut Industri Importir',
      //     en: 'Bukti Pungut Industri Importir'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/IMPORT/BP_22_industri_importir',
      //   permission: 'BP_22_industri_eksportir',
      //   user: true
      // },
      // {
      //   label: {
      //     id: 'Bukti Pungut Badan Tertentu',
      //     en: 'Bukti Pungut Badan Tertentu'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/IMPORT/BP_22_badan_tertentu',
      //   permission: 'BP_22_badan_tertentu',
      //   user: true
      // },
      {
        label: {
          id: 'SSP Dibayar Sendiri',
          en: 'SSP Dibayar Sendiri'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/SSP_22_dibayar_sendiri',
        permission: 'SSP_22_dibayar_sendiri',
        user: true
      },
      // {
      //   label: {
      //     id: 'SSP Bank Devisa',
      //     en: 'SSP Bank Devisa'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/IMPORT/SSP_22_bank_devisa',
      //   permission: 'SSP_22_bank_devisa',
      //   user: true
      // },
      // {
      //   label: {
      //     id: 'SSP Bendaharawan',
      //     en: 'SSP Bendaharawan'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/IMPORT/SSP_22_bendaharawan',
      //   permission: 'SSP_22_bendaharawan',
      //   user: true
      // },
      {
        label: {
          id: 'SSP 22',
          en: 'SSP 22'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/SSP_22',
        permission: 'SSP_22',
        user: true
      },
      {
        label: {
          id: 'PBK 22',
          en: 'PBK 22'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/PBK_22',
        permission: 'PBK_22',
        user: true
      },
      {
        label: {
          id: 'Induk Pasal 22',
          en: 'Induk Pasal 22'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/INDUK_22',
        permission: 'SPT_INDUK_22',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      {
        label: {
          id: 'Lawan Transaksi 22',
          en: 'Lawan Transaksi 22'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/LK_22',
        permission: 'LK_22',
        user: true
      }
    ]
  },
  {
    label: {
      id: 'Export',
      en: 'Export'
    },
    children: [
      {
        label: {
          id: 'Export CSV',
          en: 'Export CSV'
        },
        iconClassName: 'mdi mdi-print',
        path: '/EXPORT_CSV_22',
        permission: 'EXPORT_CSV_22'
      }
    ]
  },
  {
    label: {
      id: 'Pengaturan',
      en: 'Setting'
    },
    children: [
      {
        label: {
          id: 'Nomor Bukti Potong',
          en: 'Nomor Bukti Potong'
        },
        iconClassName: 'mdi mdi-file-multiple',
        path: '/SETTING_NO_BUPOT_22',
        user: true
      },
    ]
  },
  // {
  //   label: {
  //     id: 'Log',
  //     en: 'Log'
  //   },
  //   children: [
  // {
  //   label: {
  //     id: 'Log File Lapor',
  //     en: 'Log File Lapor'
  //   },
  //   iconClassName: 'mdi mdi-file-multiple',
  //   path: '/LOG_FILE_LAPOR_21',
  //   permission: 'LOG_FILE_LAPOR_21'
  // },
  // {
  //   label: {
  //     id: 'Log Export CSV',
  //     en: 'Log Export CSV'
  //   },
  //   iconClassName: 'mdi mdi-file-multiple',
  //   path: '/LOG_EXPORT_CSV_23',
  //   permission: 'LOG_EXPORT_CSV_23'
  // },
  // {
  //   label: {
  //     id: 'Log Email',
  //     en: 'Log Email'
  //   },
  //   iconClassName: 'mdi mdi-file-multiple',
  //   path: '/LOG_EMAIL_23',
  //   permission: 'LOG_EMAIL_23'
  // }
  //   ]
  // },
];

/*const sptEbupot23Menus = [
  {
    label: {
      id: 'Bukti Potong',
      en: 'Bukti Potong'
    },
    children: [{
      label: {
        id: 'Bupot 23',
        en: 'Bupot 23'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/bp-23',
      permission: ''
    },
      {
        label: {
          id: 'Bupot 26',
          en: 'Bupot 26'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/bp-26',
        permission: ''
      }
    ]
  },
  {
    label: {
      id: 'Lainnya',
      en: 'Lainnya'
    },
    children: [
      {
        label: {
          id: 'Daftar SSP',
          en: 'Daftar SSP'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/ssp',
        permission: ''
      },
      {
        label: {
          id: 'Daftar PBK',
          en: 'Daftar PBK'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/pbk',
        permission: ''
      },
      {
        label: {
          id: 'SPT Induk',
          en: 'SPT Induk'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/induk',
        permission: ''
      },
      {
        label: {
          id: 'List BPE',
          en: 'List BPE'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/bpe',
        permission: ''
      }
    ]
  },
  {
    label: {
      id: 'Upload',
      en: 'Upload'
    },
    children: [
      {
        label: {
          id: 'Excel',
          en: 'Excel'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/log-file',
        permission: ''
      },
      // {
      //   label: {
      //     id: 'Csv',
      //     en: 'Csv'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/log-file-csv',
      //   permission: ''
      // }
    ]
  },
]*/

const sptPph23Menus = [

  {
    label: {
      id: 'Bukti Potong',
      en: 'Bukti Potong'
    },
    children: [{
      label: {
        id: 'Pph 23',
        en: 'Pph 23'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/BP_23',
      permission: 'BP_23'
    },
      {
        label: {
          id: 'Pph 26',
          en: 'Pph 26'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/BP_26',
        permission: 'BP_26'
      }
    ]
  },
  {
    label: {
      id: 'Lainnya',
      en: 'Lainnya'
    },
    children: [
      /*{
        label: {
          id: 'Daftar Biaya',
          en: 'Daftar Biaya'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/DAFTAR_BIAYA_23',
        permission: 'DAFTAR_BIAYA_23',
        decemberOnly: true
      },*/
      {
        label: {
          id: 'Daftar SSP',
          en: 'Daftar SSP'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/SSP23',
        permission: 'SSP23'
      },
      {
        label: {
          id: 'Daftar PBK',
          en: 'Daftar PBK',
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/PBK23',
        permission: 'PBK23'
      },
      {
        label: {
          id: 'SPT Induk',
          en: 'SPT Induk'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/SPT23INDUK',
        permission: 'SPT23INDUK'
      },
    ]
  },
  {
    label: {
      id: 'Lapor',
      en: 'Lapor'
    },
    children: [
      {
        label: {
          id: 'File Lapor',
          en: 'File Lapor'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/FILE_LAPOR_23',
        permission: 'FILE_LAPOR_23'
      }
    ]
  },
  {
    label: {
      id: 'Import',
      en: 'Import'
    },
    children: [
      {
        label: {
          id: 'BP 23',
          en: 'BP 23'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_23',
        permission: 'IMPORT_BP_23'
      },
      {
        label: {
          id: 'BP 26',
          en: 'BP 26'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_26',
        permission: 'IMPORT_BP_26'
      },
      {
        label: {
          id: 'BP 23 Gabung BP 26',
          en: 'BP 23 Gabung BP 26'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_2326',
        permission: 'IMPORT_BP_2326'
      },
      {
        label: {
          id: 'SSP Pasal 23/26',
          en: 'SSP Pasal 23/26'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/SSP_23',
        permission: 'IMPORT_SSP_23'
      },
      {
        label: {
          id: 'PBK 23/26',
          en: 'PBK 23/26'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/IMPORT_PBK',
        permission: 'IMPORT_PBK'
      },
      {
        label: {
          id: 'Induk Pasal 23',
          en: 'Induk Pasal 23'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/INDUK_23',
        permission: 'SPT_INDUK_23',
      },
    ]
  },
  {
    label: {
      id: 'Export',
      en: 'Export'
    },
    children: [
      {
        label: {
          id: 'Export CSV',
          en: 'Export CSV'
        },
        iconClassName: 'mdi mdi-print',
        path: '/EXPORT_CSV_23',
        permission: 'EXPORT_CSV_23'
      }
    ]
  },
  {
    label: {
      id: 'Log',
      en: 'Log'
    },
    children: [
      {
        label: {
          id: 'Log Csv',
          en: 'Log Csv'
        },
        iconClassName: 'mdi mdi-file-multiple',
        path: '/logExport/CSV',
        user: true
      },
      {
        label: {
          id: 'Log Pdf',
          en: 'Log Pdf'
        },
        iconClassName: 'mdi mdi-file-multiple',
        path: '/logExport/PDF',
        user: true
      },
      {
        label: {
          id: 'Log eFiling',
          en: 'Log eFiling'
        },
        iconClassName: 'mdi mdi-file-multiple',
        path: '/eFiling',
        user: true
      },
      {
        label: {
          id: 'Log Email',
          en: 'Log Email'
        },
        iconClassName: 'mdi mdi-file-multiple',
        path: '/LOG_EMAIL',
        permission: 'LOG_EMAIL',
        user: true
      }
    ]
  },
  {
    label: {
      id: 'Pengaturan',
      en: 'Setting'
    },
    children: [
      {
        label: {
          id: 'Nomor Bukti Potong',
          en: 'Nomor Bukti Potong'
        },
        iconClassName: 'mdi mdi-file-multiple',
        path: '/SETTING_NO_BUPOT_2326',
        user: true
      },
    ]
  },
];

const sptPph4a2Menus = [

  {
    label: {
      id: 'Bukti Potong',
      en: 'Bukti Potong'
    },
    children: [
      {
        label: {
          id: 'Pph 4 (2)',
          en: 'Pph 4 (2)'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/BP_4a2',
        permission: 'BP_4a2'
      },
      {
        label: {
          id: 'Daftar Pemotongan 4 (2)',
          en: 'Daftar Pemotongan 4 (2)'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/daftar_pemotong',
        permission: 'daftar_pemotong'
      }
    ]
  },
  {
    label: {
      id: 'PPh 4 (2) Disetor Sendiri',
      en: 'PPh 4 (2) Disetor Sendiri'
    },
    children: [{
      label: {
        id: 'Sewa Tanah/Bangunan',
        en: 'Sewa Tanah/Bangunan'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/sewa_tanah',
      permission: 'sewa_tanah'
    },
      {
        label: {
          id: 'Usaha Jasa Konstruksi',
          en: 'Usaha Jasa Konstruksi'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/jasa_konstruksi',
        permission: 'jasa_konstruksi'
      },
      {
        label: {
          id: 'WP Melakukan Pengalihan Hak Atas Tanah/Bangunan',
          en: 'WP Melakukan Pengalihan Hak Atas Tanah/Bangunan'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/hak_atas_tanah',
        permission: 'hak_atas_tanah'
      }
    ]
  },
  {
    label: {
      id: 'Lainnya',
      en: 'Lainnya'
    },
    children: [
      {
        label: {
          id: 'Daftar SSP',
          en: 'Daftar SSP'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/SSP4a2',
        permission: 'SSP4a2'
      },
      {
        label: {
          id: 'Daftar PBK',
          en: 'Daftar PBK',
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/PBK4a2',
        permission: 'PBK4a2'
      },
      {
        label: {
          id: 'SPT Induk',
          en: 'SPT Induk'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/SPT4a2INDUK',
        permission: 'SPT4a2INDUK'
      },
    ]
  },
  {
    label: {
      id: 'Lapor',
      en: 'Lapor'
    },
    children: [
      {
        label: {
          id: 'File Lapor',
          en: 'File Lapor'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/FILE_LAPOR_4a2',
        permission: 'FILE_LAPOR_4a2'
      }
    ]
  },
  {
    label: {
      id: 'Import',
      en: 'Import'
    },
    children: [
      {
        label: {
          id: 'BP 4(2)',
          en: 'BP 4(2)'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_4a2',
        permission: 'IMPORT_BP_4a2',
        user: true
      },
      {
        label: {
          id: 'BP 4(2) Gabung',
          en: 'BP 4(2) Gabung'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_4a2_GABUNG',
        permission: 'IMPORT_BP_4A2_GABUNG'
      },
      {
        label: {
          id: 'Daftar Pemotongan 4 (2)',
          en: 'Daftar Pemotongan 4 (2)'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/DAFTAR_PEMOTONGAN_4a2',
        permission: 'IMPORT_BP_4a2',
        user: true
      },
      {
        label: {
          id: 'SSP Pasal 4(2)',
          en: 'SSP Pasal 4(2)'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/SSP_4a2',
        permission: 'IMPORT_SSP_4a2',
        user: true
      },
      {
        label: {
          id: 'PBK 4(2)',
          en: 'PBK 4(2)'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/PBK_4a2',
        permission: 'IMPORT_4a2',
        user: true
      },
      {
        label: {
          id: 'Induk Pasal 4 (2)',
          en: 'Induk Pasal 4 (2)'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/INDUK_4A2',
        permission: 'SPT_INDUK_4A2',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
      {
        label: {
          id: 'Induk 4(2) Penghasilan Tertentu',
          en: 'Induk 4(2) Penghasilan Tertentu'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/INDUK_PT_4a2',
        permission: 'IMPORT_4a2',
        user: true
      },
      {
        label: {
          id: 'Lawan Transaksi 4(2)',
          en: 'Lawan Transaksi 4(2)'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/LK_4a2',
        permission: 'IMPORT_4a2',
        user: true
      }
    ]
  },
  {
    label: {
      id: 'Export',
      en: 'Export'
    },
    children: [
      {
        label: {
          id: 'Export CSV',
          en: 'Export CSV'
        },
        iconClassName: 'mdi mdi-print',
        path: '/EXPORT_CSV_4a2',
        permission: 'EXPORT_CSV_4a2'
      }
    ]
  },
  {
    label: {
      id: 'Log',
      en: 'Log'
    },
    children: [
      {
        label: {
          id: 'Log Csv',
          en: 'Log Csv'
        },
        iconClassName: 'mdi mdi-file-multiple',
        path: '/logExport/CSV',
        user: true
      },
      {
        label: {
          id: 'Log Pdf',
          en: 'Log Pdf'
        },
        iconClassName: 'mdi mdi-file-multiple',
        path: '/logExport/PDF',
        user: true
      },
      {
        label: {
          id: 'Log eFiling',
          en: 'Log eFiling'
        },
        iconClassName: 'mdi mdi-file-multiple',
        path: '/eFiling',
        user: true
      },
      {
        label: {
          id: 'Log Email',
          en: 'Log Email'
        },
        iconClassName: 'mdi mdi-file-multiple',
        path: '/LOG_EMAIL',
        permission: 'LOG_EMAIL',
        user: true
      }
    ]
  },
  {
    label: {
      id: 'Pengaturan',
      en: 'Setting'
    },
    children: [
      {
        label: {
          id: 'Nomor Bukti Potong',
          en: 'Nomor Bukti Potong'
        },
        iconClassName: 'mdi mdi-file-multiple',
        path: '/SETTING_NO_BUPOT_4A2',
        user: true
      },
    ]
  },
];

const pph21 = [
  {
  label: {
    id: 'PPh 21',
    en: 'PPh 21'
  },
  children: [{
      label: {
        id: 'Daftar SPT',
        en: 'SPT List'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/SPT_21',
      permission: 'SPT_21'
    },
    {
      label: {
        id: 'BP Final',
        en: 'BP Final'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/BP_21_F',
      permission: 'BP_21_F',
      user: true
    },
    {
      label: {
        id: 'BP Tidak Final',
        en: 'BP Tidak Final'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/BP_21_TF',
      permission: 'BP_21_TF',
      user: true
    },
    {
      label: {
        id: 'BP 1721 A1',
        en: 'BP 1721 A1'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/BP_21_A1',
      permission: 'BP_21_A1',
      user: true
    },
    {
      label: {
        id: 'BP 1721 A2',
        en: 'BP 1721 A2'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/BP_21_A2',
      permission: 'BP_21_A2',
      user: true
    },
    {
      label: {
        id: 'BP Satu Masa',
        en: 'BP Satu Masa'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/ALL_BP_21_SATU_MASA',
      permission: 'BP_21_SATU_MASA',
      user: true
    },
    {
      label: {
        id: 'BP Satu Masa',
        en: 'BP Satu Masa'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/ALL_BP_21_SATU_MASA',
      permission: 'BP_21_SATU_MASA',
      user: true
    },
    {
      label: {
        id: 'BP Satu Tahun',
        en: 'BP Satu Tahun'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/BP_21_SATU_TAHUN',
      permission: 'BP_21_SATU_TAHUN',
      user: true
    },
    {
      label: {
        id: 'Daftar Biaya',
        en: 'Daftar Biaya'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/DAFTAR_BIAYA_21',
      permission: 'DAFTAR_BIAYA_21',
      decemberOnly: true,
      user: true
    },
    {
      label: {
        id: 'Daftar SSP',
        en: 'Daftar SSP'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/ALL_BP_21_SSP',
      permission: 'DAFTAR_SSP_21',
      user: true
    },
    {
      label: {
        id: 'SPT Induk',
        en: 'SPT Induk'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/SPT_INDUK_21',
      permission: 'SPT_INDUK_21',
      user: true
    }
  ]
  },
  {
    label: {
      id: 'Import PPh 21',
      en: 'Import PPh 21'
    },
    children: [
      {
        label: {
          id: 'BP Tidak Final',
          en: 'BP Tidak Final'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_21_TF',
        permission: 'IMPORT_BP_21_TF',
        user: true
      },
      {
        label: {
          id: 'BP Final',
          en: 'BP Final'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_21_F',
        permission: 'IMPORT_BP_21_F',
        user: true
      },
      {
        label: {
          id: 'BP 1721 A1',
          en: 'BP 1721 A1'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_21_A1',
        permission: 'IMPORT_BP_21_A1',
        user: true
      },
      {
        label: {
          id: 'BP 1721 A2',
          en: 'BP 1721 A2'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_21_A2',
        permission: 'IMPORT_BP_21_A2',
        user: true
      },
      {
        label: {
          id: 'Pemotongan Satu Masa Pajak',
          en: 'Pemotongan Satu Masa Pajak'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_21_SATU_MASA',
        permission: 'IMPORT_BP_21_SATU_MASA',
        user: true
      },{
        label: {
          id: 'Pemotongan Satu Masa Pajak TL',
          en: 'Pemotongan Satu Masa Pajak TL'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_21_SATU_MASA_TIDAK_LEBIH',
        permission: 'IMPORT_BP_21_SATU_MASA_TIDAK_LEBIH',
        user: true
      },
      {
        label: {
          id: 'SSP Pasal 21',
          en: 'SSP Pasal 21'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/SSP_21',
        permission: 'IMPORT_SSP_21',
        user: true
      },
      {
        label: {
          id: 'Daftar Biaya Pasal 21',
          en: 'Daftar Biaya Pasal 21'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/DAFTAR_BIAYA_21',
        permission: 'IMPORT_DAFTAR_BIAYA_21',
        user: true
      },
      {
        label: {
          id: 'Induk Pasal 21',
          en: 'Induk Pasal 21'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/INDUK_21',
        permission: 'IMPORT_INDUK_21',
        user: true
      },
      {
        label: {
          id: 'Lawan Transaksi 21',
          en: 'Lawan Transaksi 21'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/LK_21',
        permission: 'IMPORT_LK_21',
        user: true
      },
    ]
  }
]

const setup = [{
  label: {
    id: 'Master Data Tarif Pajak Pasal 21',
    en: 'Master Data Tarif Pajak Pasal 21'
  },
  children: [
    {
      label: {
        id: 'Pasal 17 Berlapis',
        en: 'Pasal 17 Berlapis'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/master/pasal-21-pasal-17-berlapis',
      permission: '',
      admin: true
    },
    {
      label: {
        id: 'Biaya Jabatan',
        en: 'Biaya Jabatan'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/master/pasal-21-biaya-jabatan',
      permission: '',
      admin: true
    },
    {
      label: {
        id: 'Biaya Pensiun',
        en: 'Biaya Pensiun'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/master/pasal-21-biaya-pensiun',
      permission: '',
      admin: true
    },
    {
      label: {
        id: 'PTKP',
        en: 'PTKP'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/master/pasal-21-ptkp',
      permission: '',
      admin: true
    },
    {
      label: {
        id: 'Tarif Pensiun',
        en: 'Tarif Pensiun'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/master/pasal-21-pensiun',
      permission: '',
      admin: true
    },
    {
      label: {
        id: 'Upah Harian',
        en: 'Upah Harian'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/master/pasal-21-upah-harian',
      permission: '',
      admin: true
    },
    {
      label: {
        id: 'Upah Kumulatif',
        en: 'Upah Kumulatif'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/master/pasal-21-upah-kumulatif',
      permission: '',
      admin: true
    },
    {
      label: {
        id: 'Final Pesangon',
        en: 'Final Pesangon'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/master/pasal-21-final-pesangon',
      permission: '',
      admin: true
    },
    {
      label: {
        id: 'Honor/Imbalan PNS',
        en: 'Honor/Imbalan PNS'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/master/pasal-21-honor-pns',
      permission: '',
      admin: true
    }
  ]
  },
  {
    label: {
      id: 'Master Data Kode SPT Pasal 21',
      en: 'Master Data Kode SPT Pasal 21'
    },
    children: [
      {
        label: {
          id: 'Status Karyawan A1',
          en: 'Status Karyawan A1'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-status-karyawan-a1',
        permission: '',
        admin: true
      },
      {
        label: {
          id: 'Status Karyawan A2',
          en: 'Status Karyawan A2'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-status-karyawan-a2',
        permission: '',
        admin: true
      },
      {
        label: {
          id: 'KAP',
          en: 'KAP'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-kap',
        permission: '',
        admin: true
      },
      {
        label: {
          id: 'KJS',
          en: 'KJS'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-kjs',
        permission: '',
        admin: true
      },
      {
        label: {
          id: 'SSP Description',
          en: 'SSP Description'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-ssp-description',
        permission: '',
        admin: true
      },
      {
        label: {
          id: 'Golongan A2',
          en: 'Golongan A2'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-golongan-a2',
        permission: '',
        admin: true
      }
    ]
  },
  {
    label: {
      id: 'Summary',
      en: 'Summary'
    },
    children: [{
      label: {
        id: 'Admin',
        en: 'Admin'
      },
      iconClassName: 'mdi mdi-file-multiple',
      path: '/summary-admin',
      admin: true
    }],
  },
  {
    label: {
      id: 'Administrator',
      en: 'Administrator'
    },
    children: [{
        label: {
          id: 'User',
          en: 'User'
        },
        iconClassName: 'mdi mdi-contacts',
        path: '/user',
        admin: true
      },
      /*{
        label: {
          id: 'Permissions',
          en: 'Perizinan'
        },
        iconClassName: 'mdi mdi-contacts',
        path: '/permissions',
        admin: true
      },*/
      // {
      //   label: {
      //     id: 'Akses',
      //     en: 'Access'
      //   },
      //   iconClassName: 'mdi mdi-contacts',
      //   path: '/access',
      //   admin: true
      // },
      {
        label: {
          id: 'Akses Kontrol',
          en: 'Access Control'
        },
        iconClassName: 'mdi mdi-contacts',
        path: '/access-control',
        admin: true
      }
    ]
  }
]

const organization = [{
  label: {
    id: 'Organisasi',
    en: 'Organization'
  },
  children: [{
    label: {
      id: 'Perusahaan',
      en: 'Company'
    },
    iconClassName: 'mdi mdi-contacts',
    path: '/company',
    admin: true
  },{
    label: {
      id: 'Organisasi',
      en: 'Organization'
    },
    iconClassName: 'mdi mdi-contacts',
    path: '/organization',
    admin: true
  },{
    label: {
      id: 'Flow Approval',
      en: 'Flow Approval'
    },
    iconClassName: 'mdi mdi-contacts',
    path: '/mainFlow',
    admin: true
  }]
  },
  // ORGANISASI OWNER
  {
    label: {
      id: 'Organisasi',
      en: 'Organization'
    },
    children: [{
        label: {
          id: 'Organisasi',
          en: 'Organization'
        },
        iconClassName: 'mdi mdi-view-list',
        rootPath: '/company',
        path: '/organization',
        permission: 'organization',
        owner: true
    },{
      label: {
        id: 'Flow Approval',
        en: 'Flow Approval'
      },
      iconClassName: 'mdi mdi-contacts',
      path: '/mainFlow',
      owner: true
    }]
  },
  {
  label: {
    id: 'Pengguna',
    en: 'User'
  },
  children: [{
      label: {
        id: 'Pengguna',
        en: 'User'
      },
      rootPath: '/company',
      iconClassName: 'mdi mdi-view-list',
      path: '/user',
      permission: 'user',
      owner: true
  },{
    label: {
      id: 'Pemilik',
      en: 'Owner'
    },
    iconClassName: 'mdi mdi-view-list',
    rootPath: '/company',
    path: '/owner',
    permission: 'owner',
    owner: true
  }]
}]

const sptPph15Menus = [

  {
    label: {
      id: 'Bukti Potong',
      en: 'Bukti Potong'
    },
    children: [{
      label: {
        id: 'Pph 15',
        en: 'Pph 15'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/BP_15',
      permission: 'BP_15'
    },
    {
      label: {
        id: 'SSP Pasal 15 Disetor Sendiri',
        en: 'SSP Pasal 15 Disetor Sendiri'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/SSP_15_Sendiri',
      permission: 'SSP_15_Sendiri'
    }
    ]
  },
  {
    label: {
      id: 'Lainnya',
      en: 'Lainnya'
    },
    children: [
      {
        label: {
          id: 'Daftar SSP',
          en: 'Daftar SSP'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/SSP15',
        permission: 'SSP15'
      },
      {
        label: {
          id: 'Daftar PBK',
          en: 'Daftar PBK',
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/PBK15',
        permission: 'PBK15'
      },
      {
        label: {
          id: 'SPT Induk',
          en: 'SPT Induk'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/SPT15INDUK',
        permission: 'SPT15INDUK'
      },
    ]
  },
  {
    label: {
      id: 'Lapor',
      en: 'Lapor'
    },
    children: [
      {
        label: {
          id: 'File Lapor',
          en: 'File Lapor'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/FILE_LAPOR_15',
        permission: 'FILE_LAPOR_15'
      }
    ]
  },
  {
    label: {
      id: 'Import',
      en: 'Import'
    },
    children: [
      {
        label: {
          id: 'BP 15',
          en: 'BP 15'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_15',
        permission: 'IMPORT_BP_15',
        user: true
      },
      {
        label: {
          id: 'SSP Pasal 15',
          en: 'SSP Pasal 15'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/SSP_15',
        permission: 'IMPORT_SSP_15',
        user: true
      },
      {
        label: {
          id: 'PBK 15',
          en: 'PBK 15'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/PBK_15',
        permission: 'IMPORT_15',
        user: true
      },
      {
        label: {
          id: 'Induk Pasal 15',
          en: 'Induk Pasal 15'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/INDUK_15',
        permission: 'SPT_INDUK_15',
        level: CONSTANT.LEVEL.ROLE_USER,
        user: true
      },
    ]
  },
  {
    label: {
      id: 'Export',
      en: 'Export'
    },
    children: [
      {
        label: {
          id: 'Export CSV',
          en: 'Export CSV'
        },
        iconClassName: 'mdi mdi-print',
        path: '/EXPORT_CSV_15',
        permission: 'EXPORT_CSV_15'
      }
    ]
  },
  {
    label: {
      id: 'Pengaturan',
      en: 'Setting'
    },
    children: [
      {
        label: {
          id: 'Nomor Bukti Potong',
          en: 'Nomor Bukti Potong'
        },
        iconClassName: 'mdi mdi-file-multiple',
        path: '/SETTING_NO_BUPOT_15',
        user: true
      },
    ]
  },
  // {
  //   label: {
  //     id: 'Log',
  //     en: 'Log'
  //   },
  //   children: [
  // {
  //   label: {
  //     id: 'Log File Lapor',
  //     en: 'Log File Lapor'
  //   },
  //   iconClassName: 'mdi mdi-file-multiple',
  //   path: '/LOG_FILE_LAPOR_21',
  //   permission: 'LOG_FILE_LAPOR_21'
  // },
  // {
  //   label: {
  //     id: 'Log Export CSV',
  //     en: 'Log Export CSV'
  //   },
  //   iconClassName: 'mdi mdi-file-multiple',
  //   path: '/LOG_EXPORT_CSV_4a2',
  //   permission: 'LOG_EXPORT_CSV_4a2'
  // },
  // {
  //   label: {
  //     id: 'Log Email',
  //     en: 'Log Email'
  //   },
  //   iconClassName: 'mdi mdi-file-multiple',
  //   path: '/LOG_EMAIL_4a2',
  //   permission: 'LOG_EMAIL_4a2'
  // }
  //   ]
  // },
];

const sptPph25Menus = [
  {
    label: {
      id: 'Daftar Angsuran',
      en: 'Daftar Angsuran'
    },
    children: [{
      label: {
        id: 'Pph 25',
        en: 'Pph 25'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/BP_25',
      permission: 'BP_25'
    },
    ]
  },
  {
    label: {
      id: 'Lainnya',
      en: 'Lainnya'
    },
    children: [
      {
        label: {
          id: 'Daftar SSP',
          en: 'Daftar SSP'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/SSP25',
        permission: 'SSP25'
      },
    ]
  },
  {
    label: {
      id: 'Import',
      en: 'Import'
    },
    children: [
      {
        label: {
          id: 'Daftar Angsuran 25',
          en: 'Daftar Angsuran 25'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_25',
        permission: 'IMPORT_BP_25',
        user: true
      },
      {
        label: {
          id: 'SSP 25',
          en: 'SSP 25'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/SSP_25',
        permission: 'IMPORT_SSP_25',
        user: true
      },
    ]
  },
  {
    label: {
      id: 'Export',
      en: 'Export'
    },
    children: [
      {
        label: {
          id: 'Export CSV',
          en: 'Export CSV'
        },
        iconClassName: 'mdi mdi-print',
        path: '/EXPORT_CSV_25',
        permission: 'EXPORT_CSV_25'
      }
    ]
  },
];

const none = [
  //   {
  //   label: {
  //     id: 'Home',
  //     en: 'Home'
  //   },
  //   children: [{
  //     label: {
  //       id: 'Dashboard',
  //       en: 'Dashboard'
  //     },
  //     iconClassName: 'mdi mdi-view-list',
  //     path: '/',
  //     permission: ''
  //   }]
  // }
]

var mainMenus = []
if(env.enableEbupot){
  mainMenus = mainMenusBupot
} else {
  mainMenus = mainMenusNoBupot
}

var mainmenus = {
  mainMenus,

  //company
  companyMenu,

  //bupot
  // sptEbupot23Menus,

  //spt
  sptPph21Menus,
  sptPph22Menus,
  sptPph23Menus,
  sptPph4a2Menus,
  sptPph15Menus,
  sptPph25Menus,
};

export default mainmenus
