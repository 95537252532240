import ApiService from '../../../services/api.service';

class ValidationCsvByAppService extends ApiService {
  name= 'validationCsvByApp';
  path= 'validation-csv-by-app/page';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api={
    downloadCsv: async (uploadId)=> {
      var res = await this.http.get(`${this.apiLocation}/validation-csv-by-app/download/${uploadId}`, {
        responseType: 'blob'
      })

      return res;
    },
  }
}


export default new ValidationCsvByAppService();
