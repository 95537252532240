import React, { Component } from 'react';
import {connect} from 'react-redux';
import { Grid } from 'react-md';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { validation, Textfield, TextfieldMask } from '../../../../components/form';
import { Searchfield, Switch } from "src/components/form";
import DialogView from '../../../../components/entity/dialog.view';
import MasterLawanTransaksiPpService from './MasterLawanTransaksiPp.service';
import NegaraService from '../../Master/MasterNegara/MasterNegara.service';
import OrganizationService from "../../../Main/Administrator/Organization/Organization.service";

@reduxForm({form: 'master21LtPp', destroyOnUnmount: false})
@connect((state) => ({
  form:getFormValues('master21LtPp')(state),
  organization: state.auth.currentOrganization,
}))
export default class MasterLawanTransaksiPpDialogView extends DialogView {
  service=MasterLawanTransaksiPpService
  initialData = {
  }

  formView() {
    var npwpNotValid = this.state.npwpNotValid
    var hideNegara = false;
    if(this.props.form){
      if(this.props.form){
        if(this.props.form.wpLuarNegeri){
          hideNegara = true
        }
      }
    }

    return (
      <Grid style={{padding:'none'}}>
        <Field

          label='Organization'
          name='organization'
          id="spt2126-organization"
          className="md-cell md-cell--12"
          valueField='parent'
          service={OrganizationService}
          searchField  = {['name', 'aliasName', 'npwp']}
          component={Searchfield}
          itemTemplate={function(d) {
            let nameAndAlias = ""

            if (d.name)
              nameAndAlias = d.name

            if (d.aliasName)
              nameAndAlias += " - " + d.aliasName

            return {
              primaryText  : nameAndAlias,
              secondaryText: d.npwp,
              onClick: () => {
                if(!this.props.disabled){
                  this.handleItemClick(d); this.hide()
                }
              }
            }
          }}
          validate={validation.required}
        />

        <Field
          label='NPWP'
          name='npwp'
          className="md-cell md-cell--6"
          component={TextfieldMask}
          maskFormat="##.###.###.#-###-###"
          mask="_"
          length={15}
          validate={validation.required}
        />

        <Field
          label='NIK'
          name='nik'
          className="md-cell md-cell--6"
          component={Textfield}
          validate={validation.required}
        />

        <Field
          label='Name'
          name='nama'
          className="md-cell md-cell--6"
          component={Textfield}
          validate={validation.required}
        />

        <Field
          label='Alamat'
          name='alamat'
          className="md-cell md-cell--6"
          component={Textfield}
        />

        <Field
          label='Email'
          name='email'
          className="md-cell md-cell--6"
          component={Textfield}
        />

        <Field
          label='Warga Negara Asing'
          name='wpLuarNegeri'
          component={Switch}
          className="md-cell md-cell--6"
          valueField="wpLuarNegeri"
          disabled={npwpNotValid}
        />

        {hideNegara &&
          <Field
            label='Kode Negara Domisili'
            name='kodeNegara'
            component={Searchfield}
            service={NegaraService}
            disabled={npwpNotValid}
            valueField="code"
            className="md-cell md-cell--6"
          />
        }
      </Grid>
    )
  }
}
