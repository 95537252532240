import ApiService from '../../../../../services/api.service';
import http from "../../../../../services/http.service";

class BP22Service extends ApiService {
  name= 'BP22';
  path= 'spt22/bp';

  api= {
    calculate: async (param) => {
      var res = await this.http.post(this.apiLocation+'/calculation/pph22/bp-22', param)

      return res.data;
    },
    downloadCsv: async (sptId, category)=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/csv/${sptId}/${category}`, {
        responseType: 'blob'
      })

      return res;
    },
    total: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/total`)

      return res.data;

    },
    cetak: async (id) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/report/${id}`, {
        responseType: 'blob'
      })

      return res;
    },
    cetakBulk: async (sptId, ids) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/${sptId}/report/bulk`, ids, {
        responseType: 'blob'
      })

      return res;
    },
    cetakBulkMerge: async (ids) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/report/bulk/merge`, ids, {
        responseType: 'blob'
      })

      return res;
    },
    cetakDaftar: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/report-daftar`, {
        responseType: 'blob'
      })

      return res;
    },
    email: async (category, ids) => {
      return await this.http.post(`${this.apiLocation}/email?category=${category}`, ids)
    },
    emailSpt: async (category, sptId, ids) => {
      return await this.http.post(`${this.apiLocation}/email?category=${category}&sptId=${sptId}`, ids)
    },
    deleteBulk: async (sptId, param)=> {
      let res = await http.post(`${this.apiLocation}/${this.path}/${sptId}/delete`, param);

      return res;
    },
  }

  // apiMockup= {
  //   find: [{
  //     id: 1,
  //     status: 'Process',
  //     npwp: '023945840000',
  //     name: 'PT. A',
  //     pasal: '23/26',
  //     year: '2018',
  //     organization: {name: 'PT. A', npwp: '123123'},
  //     month: 'Januari',
  //     pembetulan: '0'
  //   }],
  //   findOne: {data: {
  //     id: 1,
  //     status: 'Process',
  //     npwp: '023945840000',
  //     name: 'PT. A',
  //     pasal: '23/26',
  //     year: '2018',
  //     organization: {name: 'PT. A', npwp: '123123'},
  //     month: 'Januari',
  //     pembetulan: '0'
  //   }}
  // }

  constructor() {
    super()
    this.init()
    this.initApi()
  }
}


export default new BP22Service();
