import React from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  Tab,
  Tabs,
  SelectionControlGroup,
  Button
} from 'react-md';
import download from 'downloadjs';
import uuid from 'uuid';
import iziToast from 'izitoast';

import FormView from '../../../../../components/entity/form.view';
import {validation, Textfield, Searchfield, Switch, Checkbox, Datepicker, TextfieldMask} from '../../../../../components/form';
import columnService from '../../../../../services/column.service';
import terbilangService from '../../../../../services/terbilang.service';
import Spt4a2IndukService from './Spt4a2Induk.service';


@reduxForm({form: 'SPT_INDUK_4a2_Form', destroyOnUnmount: true, initialValues: {jasaLains: []}})
@connect((state) => ({
  access      : state.entity.access.api.find,
  organization: state.auth.currentOrganization,
  spt         : state.auth.currentSPT,
  formData    : getFormValues('SPT_INDUK_4a2_Form')(state)
}))
export default class Spt4a2IndukForm extends FormView {
  service  = Spt4a2IndukService
  viewType = 2;

  initialData={
    bagB1: {},
    bagB2: {},
    bagC : {},
  }

  constructor(props){
    super(props);
    this.state = {
      ...this.state,
      cetak    : false,
      calculate: false,

      signer: {},
    }
  }

  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      let value = this.props.formData
      this.handleSave(value)
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  async handleSave(value) {
    try {
      this.setState({onProgress: true})

      var res                            = await this.service.api.update(value)
      if  (!res.data.bagD) res.data.bagD = {}
      // signer
      let signer = null;
      try {
        signer = await this.service.api.getSigner({
          orgId: this.props.spt.data.organization.id,
          tgl  : res.data.tanggal
        });
      } catch (error) {

      }
      if(signer) {
        this.setState({signer: signer})

        if (res.data.bagC) {
          res.data.bagD.kuasa = res.data.bagC.checkSuratKuasa;
        }

        if(res.data.bagD.kuasa) {
          res.data.bagD.npwp = signer.npwpKuasa;
          res.data.bagD.nama = signer.namaKuasa;
        } else {
          res.data.bagD.npwp = signer.npwpPenandaTanganSpt;
          res.data.bagD.nama = signer.namaPenandaTanganSpt;
        }
      }

      this.props.initialize(res.data);

      this.setState({onProgress: false})
      this.toast.success({title: 'Success', message: 'Saved'})
    } catch(e) {
      var msg              = e.message;
      if  (e.response) msg = e.response.data.message;
      this.setState({
        isError     : true,
        onProgress  : false,
        errorMessage: msg
      })
    }
  }

  async initData() {
    let res = await this.service.api.getBySptId(this.props.spt.data.id);

    if(!res.data.bagD) res.data.bagD = {}

    // signer
    let signer = null;
    try {
      signer = await this.service.api.getSigner({
        orgId: this.props.spt.data.organization.id,
        tgl  : res.data.tanggal
      });
    } catch (error) {

    }
    if(signer) {
      this.setState({signer: signer})

      if (res.data) {
        if (res.data.bagC) {
          res.data.bagD.kuasa = res.data.bagC.checkSuratKuasa;
        }
      }

      if(res.data.bagD.kuasa) {
        res.data.bagD.npwp = signer.npwpKuasa;
        res.data.bagD.nama = signer.namaKuasa;
      } else {
        res.data.bagD.npwp = signer.npwpPenandaTanganSpt;
        res.data.bagD.nama = signer.namaPenandaTanganSpt;
      }
    }

    this.props.initialize(res.data);
  }

  async changeSigner() {
    if(this.state.signer.id) {
      let formData                       = this.initialData
      if  (this.props.formData) formData = this.props.formData;
      if(formData.bagD.kuasa) {
        this.props.change('bagD.npwp', this.state.signer.npwpKuasa);
        this.props.change('bagD.nama', this.state.signer.namaKuasa);
      } else {
        this.props.change('bagD.npwp', this.state.signer.npwpPenandaTanganSpt);
        this.props.change('bagD.nama', this.state.signer.namaPenandaTanganSpt);
      }
    }
  }

  async calculate() {
    await this.setState({calculate: true})
    await this.props.dispatch({
      type: 'TOGGLE_GLOBAL_PROGRESS',
      data: true
    });

    const sptId = this.props.match.params.sptId

    try {
      var res = await this.service.api.calculate(sptId)

      if(res.data) {
        this.props.initialize(res.data);
      }
    } catch(e) {

    }


    this.setState({calculate: false})
    await this.props.dispatch({
      type: 'TOGGLE_GLOBAL_PROGRESS',
      data: false
    });
  }

  async handleCetak(){
    this.setState({ cetak: true })
    try {
      const sptId    = this.props.match.params.sptId
      var   report   = await this.service.api.cetak(sptId)
      var   filename = report.headers.filename;
      download(report.data, filename);
      this.setState({ cetak: false })
    } catch (e) {
      const reader = new FileReader();

      /// This fires after the blob has been read/loaded.
      reader.addEventListener('loadend', (e) => {
        const text = e.srcElement.result
        let json = JSON.parse(text)

        if (json.detail)
          iziToast.error({
            title: 'Error',
            message: json.detail
          })
      });

      reader.readAsText(e.response.data)

      this.setState({cetak: false})
    }
  }

  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;
    let   statusSPT                                     = "";
    let   statusInSPT                                   = false;
    if(this.props.spt){
        if(this.props.spt.data){
            if(this.props.spt.data.status){
                statusSPT   = this.props.spt.data.status;
                statusInSPT = true
            }
        }
    }

    if(statusSPT == "FINISH" || statusSPT == "WAITING"){
        return (
            <div>
              <Button disabled={this.state.cetak} primary raised style={{marginRight: 5}} onClick={this.handleCetak.bind(this)}>Cetak</Button>
            </div>
        )
    } else {
        if(statusInSPT){
            return (
              <div>
                <Button disabled={this.state.cetak} primary raised style={{marginRight: 5}} onClick={this.handleCetak.bind(this)}>Cetak</Button>
                {/* <Button disabled={this.state.calculate} primary raised style={{marginRight: 5}} onClick={this.calculate.bind(this)}>calculate</Button> */}
                <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>Save and Calculate</Button>
              </div>
            )
        } else {
            return (
                <div>
                    {/* <Button disabled={this.state.calculate} primary raised style={{marginRight: 5}} onClick={this.calculate.bind(this)}>calculate</Button> */}
                    <Button secondary flat onClick={()=> {this.props.history.goBack()}} style={{marginRight: 16}}>Back</Button>
                </div>
            )
        }
    }
  }

  async calculateTotal(e,v,pv,f) {
    await this.props.change(f, v);

    var form = this.props.formData;

    var b1    = form.bagB1;
    var b2    = form.bagB2;

    var jmlPph   = 0;
    var jmlBruto = 0;

    Object.keys(b1).forEach((k, i) => {
      if(!(k == 'createdBy' || k == 'createdDate' || k == 'id' || k == 'lastModifiedBy' || k == 'lastModifiedDate')) {
        var x = b1[k];

        var pph            = x.pph;
        var bruto          = x.bruto;
        if  (!bruto) bruto = 0;
        if  (!pph) pph     = 0;

        jmlPph   = jmlPph+ parseFloat(pph);
        jmlBruto = jmlBruto+ parseFloat(bruto)
      }
    })

    Object.keys(b2).forEach((k, i) => {
      if(!(k == 'createdBy' || k == 'createdDate' || k == 'id' || k == 'lastModifiedBy' || k == 'lastModifiedDate')) {
        var x = b2[k];

        var pph            = x.pph;
        var bruto          = x.bruto;
        if  (!bruto) bruto = 0;
        if  (!pph) pph     = 0;

        jmlPph   = jmlPph+ parseFloat(pph);
        jmlBruto = jmlBruto+ parseFloat(bruto)
      }
    })

    jmlBruto = Math.floor(jmlBruto)
    jmlPph = Math.floor(jmlPph)

    this.props.change('totalBruto', jmlBruto)
    this.props.change('totalPph', jmlPph);

    this.props.change('terbilang', terbilangService(parseInt(jmlPph)));
  }

  async calculatePph(e,v,pv,f, parent) {
    var form = this.props.formData;
    var d    = form.bagB2[parent];

    var bruto = parseFloat(d.bruto);
    var tarif = parseFloat(d.tarif);

    if(f.search('bruto') >= 0) {
      bruto = parseFloat(v)
    } else if(f.search('tarif') >= 0) {
      tarif = parseFloat(v)
    }

    if(!bruto) bruto = 0;
    if(!tarif) tarif = 0;

    var pph = Math.floor(bruto * tarif / 100)

    await this.props.change(`bagB2.${parent}.pph`, pph);
    this.calculateTotal()
  }

  formView() {
    let formData                                                     = this.initialData
    if  (this.props.formData && this.props.formData.values) formData = this.props.formData.values

    var masaOptions = [
      {id:1, name: 1}, {id:2, name: 2}, {id:3, name: 3}, {id:4, name: 4}, {id:5, name: 5}, {id:6, name: 6},
      {id:7, name: 7}, {id:8, name: 8}, {id:9, name: 9}, {id:10, name: 10}, {id:11, name: 11}, {id:12, name: 12}
    ]
    return (
      <div>
        <Card>
          {/* <CardTitle title='' style={{padding: '10px 16px'}} subtitle='A. IDENTITAS PEMOTONG' />
          <Divider/> */}

          <div className='md-grid'>
            <Field
              label        = 'Tanggal Lapor'
              className    = "md-cell md-cell--12"
              name         = 'tanggal'
              component    = {Datepicker}
              validate     = {validation.required}
              onDateChange = { async (d)=> {
                let signer = null;

                try {
                  signer = await this.service.api.getSigner({
                    orgId: this.props.spt.data.organization.id,
                    tgl  : moment(d).format("YYYY-MM-DD")
                  })

                  await this.setState({signer: signer})
                } catch (error) {
                }

                await this.changeSigner()
              }}
              />
          </div>
        </Card>
        <br/>

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='PPH PASAL 4 (2) YANG TELAH DIPOTONG' />
          <Divider/>
          <DataTable plain fixedScrollWrapperStyle={{overflow: 'inherit'}} style={{overflow: 'inherit'}}>
            <TableHeader>
              <TableRow>
                <TableColumn>No.</TableColumn>
                <TableColumn>Jenis Penghasilan</TableColumn>
                <TableColumn>KAP/KJS</TableColumn>
                <TableColumn className='md-text-right'>Jumlah Penghasilan Bruto(Rp)</TableColumn>
                <TableColumn className='md-text-right'>Tarif</TableColumn>
                <TableColumn className='md-text-right'>PPH Di Potong(Rp)</TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableColumn>
                  1.
                </TableColumn>
                <TableColumn>Bunga Deposito/Tabungan, Diskonto SBI dan Jasa Giro: </TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                </TableColumn>
                <TableColumn>a. Bunga Deposito/Tabungan</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>&nbsp;&nbsp;&nbsp;&nbsp;1) Yang ditempatkan di Dalam negeri</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB1.bungaDepositoDalamNegeri.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB1.bungaDepositoDalamNegeri.bruto'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB1.bungaDepositoDalamNegeri.tarif'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB1.bungaDepositoDalamNegeri.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>&nbsp;&nbsp;&nbsp;&nbsp; 2) Yang ditempatkan di Luar negeri</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB1.bungaDepositoLuarNegeri.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB1.bungaDepositoLuarNegeri.bruto'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB1.bungaDepositoLuarNegeri.tarif'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB1.bungaDepositoLuarNegeri.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>b. Diskonto Sertifikat Bank Indonesia</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB1.diskontoSertifikat.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB1.diskontoSertifikat.bruto'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB1.diskontoSertifikat.tarif'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB1.diskontoSertifikat.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>c. Jasa Giro</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB1.jasaGiro.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB1.jasaGiro.bruto'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB1.jasaGiro.tarif'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB1.jasaGiro.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>2</TableColumn>
                <TableColumn>Transaksi Penjualan Saham</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>a. Saham Pendiri</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB1.transaksiPenjualanSahamSendiri.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB1.transaksiPenjualanSahamSendiri.bruto'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB1.transaksiPenjualanSahamSendiri.tarif'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB1.transaksiPenjualanSahamSendiri.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>b. Bukan Saham Pendiri</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB1.transaksiPenjualanSahamBukanSahamSendiri.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB1.transaksiPenjualanSahamBukanSahamSendiri.bruto'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB1.transaksiPenjualanSahamBukanSahamSendiri.tarif'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB1.transaksiPenjualanSahamBukanSahamSendiri.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>3</TableColumn>
                <TableColumn>Bunga/Diskonto Obligasi dan Surat Berharga Negara</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB1.bungaObligasi.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB1.bungaObligasi.bruto'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB1.bungaObligasi.tarif'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB1.bungaObligasi.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>4</TableColumn>
                <TableColumn>Hadiah Undian</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB1.hadiahUndian.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB1.hadiahUndian.bruto'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB1.hadiahUndian.tarif'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB1.hadiahUndian.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>5</TableColumn>
                <TableColumn>Persewaan Tanah dan/atau Bangunan</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
              </TableRow>


              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>a. Penyewa sebagai Pemotong Pajak</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB1.persewaanTanahPemotongPajak.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB1.persewaanTanahPemotongPajak.bruto'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB1.persewaanTanahPemotongPajak.tarif'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB1.persewaanTanahPemotongPajak.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>b. Orang Pribadi/Badan yang Menyetor Sendiri PPh</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB1.persewaanTanahSendiri.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB1.persewaanTanahSendiri.bruto'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB1.persewaanTanahSendiri.tarif'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB1.persewaanTanahSendiri.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>6</TableColumn>
                <TableColumn>Jasa Konstruksi</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>a. Perencana Konstruksi</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>&nbsp;&nbsp;&nbsp;&nbsp;1) Pengguna Jasa sebagai Pemotong PPh</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB2.jkPerencanaKonstruksiPemotongPph.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB2.jkPerencanaKonstruksiPemotongPph.bruto'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB2.jkPerencanaKonstruksiPemotongPph.tarif'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB2.jkPerencanaKonstruksiPemotongPph.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>&nbsp;&nbsp;&nbsp;&nbsp; 2) Penyedia Jasa yang Menyetor Sendiri PPh	</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB2.jkPerencanaKonstruksiSendiriPph.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB2.jkPerencanaKonstruksiSendiriPph.bruto'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB2.jkPerencanaKonstruksiSendiriPph.tarif'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB2.jkPerencanaKonstruksiSendiriPph.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>b. Pelaksana Konstruksi</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>&nbsp;&nbsp;&nbsp;&nbsp; 1) Pengguna Jasa sebagai Pemotong PPh</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB2.jkPelaksanaKonstruksiPemotongPph.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB2.jkPelaksanaKonstruksiPemotongPph.bruto'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB2.jkPelaksanaKonstruksiPemotongPph.tarif'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB2.jkPelaksanaKonstruksiPemotongPph.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>&nbsp;&nbsp;&nbsp;&nbsp;2) Penyedia Jasa yang Menyetor Sendiri PPh</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB2.jkPelaksanaKonstruksiSendiriPph.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB2.jkPelaksanaKonstruksiSendiriPph.bruto'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB2.jkPelaksanaKonstruksiSendiriPph.tarif'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB2.jkPelaksanaKonstruksiSendiriPph.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>c. Pengawas Konstruksi</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>&nbsp;&nbsp;&nbsp;&nbsp;1) Pengguna Jasa sebagai Pemotong PPh</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB2.jkPengawasKonstruksiPemotongPph.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB2.jkPengawasKonstruksiPemotongPph.bruto'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB2.jkPengawasKonstruksiPemotongPph.tarif'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB2.jkPengawasKonstruksiPemotongPph.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>&nbsp;&nbsp;&nbsp;&nbsp; 2) Penyedia Jasa yang Menyetor Sendiri PPh</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB2.jkPengawasKonstruksiSendiriPph.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB2.jkPengawasKonstruksiSendiriPph.bruto'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB2.jkPengawasKonstruksiSendiriPph.tarif'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB2.jkPengawasKonstruksiSendiriPph.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>7</TableColumn>
                <TableColumn>Wajib Pajak yang melakukan pengalihan Hak atas Tanah/Bangunan</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB2.wpPengalihanBangunan.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB2.wpPengalihanBangunan.bruto'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB2.wpPengalihanBangunan.tarif'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB2.wpPengalihanBangunan.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>8</TableColumn>
                <TableColumn>Bunga Simpanan yang dibayarkan oleh Koperasi kepada Anggota Wajib Pajak Orang Pribadi</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB2.bungaSimpananKoperasi.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB2.bungaSimpananKoperasi.bruto'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB2.bungaSimpananKoperasi.tarif'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB2.bungaSimpananKoperasi.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>9</TableColumn>
                <TableColumn>Transaksi Derivatif berupa kontrak berjangka yang diperdagangkan di Bursa</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB2.transaksiDerivatif.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB2.transaksiDerivatif.bruto'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB2.transaksiDerivatif.tarif'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB2.transaksiDerivatif.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>10</TableColumn>
                <TableColumn>Dividen yang Diterima/Diperoleh Wajib Pajak Orang Pribadi Dalam Negeri</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB2.dividenDiterima.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB2.dividenDiterima.bruto'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB2.dividenDiterima.tarif'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB2.dividenDiterima.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>11</TableColumn>
                <TableColumn>Penghasilan tertentu lainnya</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>a <Field
                    // label='Tarif'
                    name = 'bagB2.penghasilanLainA.jenisPenghasilan'
                    // disabled
                    component = {Textfield}
                  /></TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB2.penghasilanLainA.kapKjs'
                    // disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB2.penghasilanLainA.bruto'
                    // disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                    onChange  = {(e, v, vp, f) => this.calculatePph(e, v, vp, f, 'penghasilanLainA')}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB2.penghasilanLainA.tarif'
                    className = 'md-text-right'
                    // disabled
                    component = {TextfieldMask}
                    money     = {','}
                    onChange  = {(e, v, vp, f) => this.calculatePph(e, v, vp, f, 'penghasilanLainA')}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB2.penghasilanLainA.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>b <Field
                    // label='Tarif'
                    name = 'bagB2.penghasilanLainB.jenisPenghasilan'
                    // disabled
                    component = {Textfield}
                  /></TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB2.penghasilanLainB.kapKjs'
                    // disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB2.penghasilanLainB.bruto'
                    // disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                    onChange  = {(e, v, vp, f) => this.calculatePph(e, v, vp, f, 'penghasilanLainB')}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB2.penghasilanLainB.tarif'
                    className = 'md-text-right'
                    // disabled
                    component = {TextfieldMask}
                    money     = {','}
                    onChange  = {(e, v, vp, f) => this.calculatePph(e, v, vp, f, 'penghasilanLainB')}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB2.penghasilanLainB.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>c <Field
                    // label='Tarif'
                    name = 'bagB2.penghasilanLainC.jenisPenghasilan'
                    // disabled
                    component = {Textfield}
                  /></TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB2.penghasilanLainC.kapKjs'
                    // disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB2.penghasilanLainC.bruto'
                    // disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                    onChange  = {(e, v, vp, f) => this.calculatePph(e, v, vp, f, 'penghasilanLainC')}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB2.penghasilanLainC.tarif'
                    className = 'md-text-right'
                    // disabled
                    component = {TextfieldMask}
                    money     = {','}
                    onChange  = {(e, v, vp, f) => this.calculatePph(e, v, vp, f, 'penghasilanLainC')}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB2.penghasilanLainC.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow style={{background: '#ddd'}}>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  Jumlah
                </TableColumn>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name      = 'totalBruto'
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    disabled
                    money = {','}
                  />
                </TableColumn>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'totalPPh'
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    disabled
                    money = {','}
                  />
                </TableColumn>
              </TableRow>



              <TableRow style={{background: '#ddd'}}>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  Terbilang
                </TableColumn>
                <TableColumn colSpan={4}>
                  <Field
                    // label='Bruto'
                    name      = 'terbilang'
                    component = {Textfield}
                    disabled
                    money = {','}
                  />
                </TableColumn>
              </TableRow>

            </TableBody>

          </DataTable>
        </Card>

        <br/>


         <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='LAMPIRAN' />
          <Divider/>
          <DataTable plain fixedScrollWrapperStyle={{overflow: 'inherit'}} style={{overflow: 'inherit'}}>
            <TableHeader>
              <TableRow>
                <TableColumn>Cek</TableColumn>
                <TableColumn>Keterangan</TableColumn>
                <TableColumn>Lembar</TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableColumn>
                  <Field
                    name      = 'bagC.checkSsp'
                    component = {Checkbox}
                    disabled
                  />
                </TableColumn>

                <TableColumn>
                  Surat Setoran Pajak
                </TableColumn>
                <TableColumn>
                  <Field
                    name      = 'bagC.lembarSsp'
                    component = {Textfield}
                    disabled
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  <Field
                    name      = 'bagC.checkDaftarBp'
                    component = {Checkbox}
                    disabled
                  />
                </TableColumn>

                <TableColumn>
                  Daftar Bukti Pemotongan PPh Final Pasal 4 ayat 2
                </TableColumn>
                <TableColumn>
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>
                  <Field
                    name      = 'bagC.checkBp'
                    component = {Checkbox}
                    disabled
                  />
                </TableColumn>

                <TableColumn>
                  Bukti Pemotongan/Pemungutan PPh Final Pasal 4 Ayat 2
                </TableColumn>
                <TableColumn>
                  <Field
                    name      = 'bagC.lembarBp'
                    component = {Textfield}
                    disabled
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  <Field
                    name      = 'bagC.checkSuratKuasa'
                    component = {Checkbox}
                    onChange  = {async (ev, v)=> {
                      await this.props.change('bagD.kuasa', v);

                      await this.changeSigner()
                    }}
                  />
                </TableColumn>

                <TableColumn>
                Surat Kuasa Khusus
                </TableColumn>
                <TableColumn>
                </TableColumn>
              </TableRow>
            </TableBody>
          </DataTable>
        </Card>


        <br />

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='TANDA TANGAN PEMOTONG' />
          <Divider/>
          {/* <div className='md-grid'>
            <div className="md-cell md-cell--12">
              <p style={{textAlign: 'center'}}>Dengan menyadari sepenuhnya atas segala akibatnya termasuk sanksi-sanksi sesuai dengan ketentuan yang berlaku, saya menyatakan bahwa apa yang telah saya beritahukan di atas beserta lampiran-lampirannya adalah benar, lengkap dan jelas.</p>
            </div>
          </div> */}
          <div className='md-grid'>
            <Field
              className = "md-cell md-cell--12"
              label     = 'Jenis Penandatangan'
              name      = 'bagD.kuasa'
              component = {Searchfield}
              options   = {[
                {id: false, name: 'Pemotong'},
                {id: true, name: 'Kuasa'}
              ]}
              onChange={async (e, v)=> {
                await this.props.change('bagD.kuasa', v);

                await this.changeSigner()
              }}
              //validate = {validation.required}
            />

            <div className="md-cell md-cell--6">
              <Field
                label      = 'NPWP Pemotong'
                name       = 'bagD.npwp'
                component  = {TextfieldMask}
                maskFormat = "##.###.###.#-###-###"
                validate   = {validation.required}
                // disabled
              />

            </div>
            <div className="md-cell md-cell--6">
              <Field
                label     = 'Nama Pemotong'
                name      = 'bagD.nama'
                component = {Textfield}
                validate  = {validation.required}
                // disabled
              />
            </div>
          </div>
        </Card>
      </div>
    )
  }
}
