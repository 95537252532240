import React, { Component } from 'react';
import { connect } from 'react-redux';

import {Field, getFormValues, reduxForm} from 'redux-form';
import { Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab } from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Switch as Switch2} from '../../../../../../components/form';

import ImportPBK23Service from './ImportPBK23.service';
import ListView from '../../../../../../components/entity/listView';
import ColumnService from '../../../../../../services/column.service';
// import ImportService from './../Import/Import.service';
import axios from 'axios';
import izitoast from 'izitoast';
import FormDialog from './ImportPBK23.dialog';
import download from "downloadjs";
import ListViewImport from '../../../../../../components/entity/ListViewImport';

@reduxForm({form: 'ImportPBK23Form', destroyOnUnmount: true})
@connect((state) => ({
  ...ImportPBK23Service.stateConnectSetting()(state),
  formData: getFormValues('ImportPBK23Form')(state)
}), ImportPBK23Service.actionConnectSetting())
export default class ImportPBK23View extends ListViewImport {
  service=ImportPBK23Service
  FormDialog=FormDialog

  category='PBK_23'
  importPath='/api/import/spt/23'
  showAutomatisTab=false
  showPembetulan=true
  // viewType=2

  beforeFetch(params) {
    params["category.equals"] = "PBK_23"
  }

  async handleDownloadTemplate() {
    var report = await ImportPBK23Service.api.downloadTemplate()
    download(report.data, 'TEMPLATE_PBK_23.csv');
  }
}
