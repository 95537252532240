import ApiService from '../../../../services/api.service';

class BankService extends ApiService {
  name= 'Bank';
  path = 'ebilling/banks';

  constructor() {
    super()
    this.init()
    this.initApi()
  }
}

export default new BankService();
