import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Field, reduxForm } from 'redux-form';
import { Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab } from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Switch as Switch2} from '../../../../../components/form';

import ImportBp21IndukService from './ImportBp21Induk.service';
import ListView from '../../../../../components/entity/listView';
import ColumnService from '../../../../../services/column.service';
import ImportService from './../Import/Import.service';
import axios from 'axios';
import izitoast from 'izitoast';
import FormDialog from './ImportBp21Induk.dialog.view';
import download from "downloadjs";
import ListViewImport from '../../../../../components/entity/ListViewImport';

@reduxForm({form: 'IMPORT_21_BP_INDUK_Form', destroyOnUnmount: true})
@connect(ImportBp21IndukService.stateConnectSetting(), ImportBp21IndukService.actionConnectSetting())
export default class ImportBp21IndukView extends ListViewImport {
  service=ImportBp21IndukService
  FormDialog=FormDialog

  category='INDUK_21'
  importPath='/api/import/spt/21'
  showAutomatisTab=false
  showPembetulan=true
  // viewType=2

  beforeFetch(params) {
    params["category.equals"] = "INDUK_21"
  }

  async handleDownloadTemplate() {
    var report = await ImportBp21IndukService.api.downloadTemplate()
    download(report.data, 'TEMPLATE_INDUK_21.csv');
  }

}
