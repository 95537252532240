import React, { Component } from 'react';
import { connect } from 'react-redux';

//import FormDialog from './Organization.dialog.view';
import SPT2326Service from './23_26.spt.service';
import authService from '../../../../../services/auth.service';
import * as constant from '../../../../../config/constant';
import ListViewSptRoot from '../../../../../components/entity/ListViewSptRoot';

@connect((state)=> ({
  ...SPT2326Service.stateConnectSetting()(state),
  user: state.auth.user,
  orgAccess: state.auth.orgAccess,
  company: state.auth.currentCompany
}), SPT2326Service.actionConnectSetting())
export default class SPT2326View extends ListViewSptRoot {
  service=SPT2326Service
  FormDialog=()=> <div/>

  addDialog=false
  editDialog=false

  permissionName="SPT_23"
  urlPath="SPT_23"
  formTypeName="PPH 23"
  shortPasalName="23"

  fetchOption() {
    var userAuth = authService.api.getAuth(this.props.user);
    var path = null;
    if(userAuth === constant.ROLE_OWNER) {
      path =  SPT2326Service.path+'/company/page/'+this.props.company.id
    }

    if(this.props.match.params.companyId) path =  SPT2326Service.path+'/company/page/'+this.props.match.params.companyId;
    // if(this.props.user.company && this.props.user.company.id) path =  SPT2126Service.path+'/company/page/'+this.props.user.company.id;

    return {
      path: path
    }
  }
}
