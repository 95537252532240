import React, { Component } from 'react';
import { connect } from 'react-redux';

import authService from '../../../../../services/auth.service';
import * as constant from '../../../../../config/constant';
import ListViewSptRoot from '../../../../../components/entity/ListViewSptRoot';
import SPT22Service from './SPT22.service';

@connect((state)=> ({
  ...SPT22Service.stateConnectSetting()(state),
  user: state.auth.user,
  orgAccess: state.auth.orgAccess,
  company: state.auth.currentCompany
}), SPT22Service.actionConnectSetting())
export default class SPT22View extends ListViewSptRoot {
  service=SPT22Service
  FormDialog=()=> <div/>

  addDialog=false
  editDialog=false

  permissionName="SPT_22"
  urlPath="SPT_22"
  formTypeName="PPH 22"
  shortPasalName="22"

  fetchOption() {
    var userAuth = authService.api.getAuth(this.props.user);
    var path = null;
    if(userAuth === constant.ROLE_OWNER) {
      path =  SPT22Service.path+'/company/page/'+this.props.company.id
    }

    if(this.props.match.params.companyId) path =  SPT22Service.path+'/company/page/'+this.props.match.params.companyId;
    // if(this.props.user.company && this.props.user.company.id) path =  SPT2126Service.path+'/company/page/'+this.props.user.company.id;

    return {
      path: path
    }
  }
}
