import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Field, reduxForm } from 'redux-form';
import { Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab } from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Switch as Switch2} from '../../../../../components/form';

import ImportBp4a2IndukService from './ImportBp4a2Induk.service';
import ListView from '../../../../../components/entity/listView';
import ColumnService from '../../../../../services/column.service';
import ImportService from './../../21_26/Import/Import.service';
import axios from 'axios';
import izitoast from 'izitoast';
import FormDialog from './ImportBp4a2Induk.dialog.view';
import download from "downloadjs";
import ListViewImport from '../../../../../components/entity/ListViewImport';

@reduxForm({form: 'IMPORT_4A2_BP_INDUK_Form', destroyOnUnmount: true})
@connect(ImportBp4a2IndukService.stateConnectSetting(), ImportBp4a2IndukService.actionConnectSetting())
export default class ImportBp4a2IndukView extends ListViewImport {
  service=ImportBp4a2IndukService
  FormDialog=FormDialog

  category='INDUK_4A2'
  importPath='/api/import/spt/4a2'
  showAutomatisTab=false
  showPembetulan=true
  // viewType=2

  beforeFetch(params) {
    params["category.equals"] = "INDUK_4A2"
  }

  async handleDownloadTemplate() {
    var report = await ImportBp4a2IndukService.api.downloadTemplate()
    download(report.data, 'TEMPLATE_INDUK_4A2.csv');
  }

}
