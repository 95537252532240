import ApiService from '../../../../../services/api.service';

class Master21Pasal17Service extends ApiService {
  name= 'Master21Pasal17';
  path= 'master/tarif-21-pasal-17';
  constructor() {
    super();
    this.init()
  }
}


export default new Master21Pasal17Service();

