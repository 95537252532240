
import React, { Component } from 'react'; import PropTypes from 'prop-types';
import {SelectField, DropdownMenu, TextField, Button, FontIcon, Avatar, Chip, SVGIcon, ListItem, ListItemControl, Checkbox} from 'react-md';

export default class MultiSelectCustom extends Component {
  // options = [{id: , name: }]
  static defaultProps = {
    apiPath: 'find',
    valueField: 'id',
    viewField: 'name',
    itemTemplate: function(d) {
      if(this.props.noChip){
        return {
          // leftIcon: <Checkbox style={{ marginTop: -10 }}></Checkbox>,
          primaryText: d[this.props.viewField],
          onClick: () => this.handleItemClick(d)
        }
      } else {
        return {
          primaryText: d[this.props.viewField],
          onClick: () => this.handleItemClick(d)
        }
      }
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      viewValue: '',
      menuItems: [],
      chips    : [],
      chipsText: [],
      visible: false
    }
  }

  componentDidMount() {
    var value = this.props.input.value
    if(value.length != ""){
      var options = this.props.options
      var option = options.filter((option)=>{
        return option.id == value
      })
      var viewValue = option[0]
      var valueText = ""
      if(viewValue){
        valueText = viewValue.name
      }
      this.setState({ chips : value })
    } else {}
    this.buildMenuItems()
  }

  componentDidUpdate(prevProps, prevState){
    try {
      if((prevProps.input.value.length != this.props.input.value.length) && this.props.input.value.length == 0){
        this.setState({
          chips: [],
          chipsText: []
        })
      }
    } catch(e){}
    if(prevState.menuItems.length != this.state.menuItems.length){
      this.buildMenuItems()
    }
  }

  handleItemClick(d) {
    var val = d[this.props.valueField];
    if(this.props.valueField == 'parent') val = d;
    this.props.input.value = val;
    this.setState({viewValue: ''})
    // Chips
    var chipsText = []
    var chipsArray = []
    if(this.state.chips.length == 0) chipsArray.push(val)
    if(this.state.chipsText.length == 0) chipsText.push(val)

    if(this.state.chips.length > 0){
      var chipsArray = this.state.chips
      var chipsText = this.state.chipsText
      if(this.props.valueField == 'parent'){
        var chips = chipsArray
        var filter = chips.filter((i)=>{
          var compare = "id"
          var filter = chips.filter((i)=> {
            return i[compare] == val[compare]
          })
          if(filter.length == 0){
            chipsText.push(val)
            chipsArray.push(val)
          }
        })
      } else {
        var chips = chipsArray
        var filter = chips.filter((i)=>{
          return i == val
        })
        if(filter.length == 0) {
          chipsText.push(val)
          chipsArray.push(val)
        }
      }
    }
    this.setState({ chips: chipsArray, chipsText: chipsText })
    this.props.input.onChange(chipsArray)
  }

  async handleViewChange(val) {
    this.setState({ visible: true })
    this.setState({viewValue: val});
    if(val.length >= 1) {
      var searchField = this.props.viewField;
      if(this.props.searchField) searchField = this.props.searchField;
      var menuItems = [];

      if(!this.props.remoteSearch) {
        var filtered = _.filter(this.state.defData, (item) => {
          return item[searchField].toLowerCase().indexOf(val.toLowerCase()) > -1;
        })
        menuItems = filtered.map(this.props.itemTemplate.bind(this))
      } else {
        var filter = val
        // let res = await this.props.service.api[this.props.apiPath](filter, ()=>{}, this.props.fetchOption);
        let apiParam = {}
        if(this.props.apiParam){
          apiParam = this.props.apiParam
        }

        if (searchField.length) {
          searchField.forEach(search => {
            apiParam[search + ".contains"] = filter
          })
        }

        let apiPath = this.props.apiPath
        let fetchOption = this.props.fetchOption
        apiParam.size = 1000
        // let res = await this.props.service.api[apiPath](apiParam, filter, fetchOption);
        let res = await this.props.service.api[apiPath](apiParam);
        menuItems = res.data.map(this.props.itemTemplate.bind(this))
      }
      this.setState({menuItems})
    } else {
      menuItems = this.state.defData.map(this.props.itemTemplate.bind(this))
      this.setState({menuItems})
    }

  }

  handleChipClick(index) {
    var array = this.state.chips
    var arrayText = this.state.chipsText
    array.splice(index, index+ 1)
    arrayText.splice(index, index + 1)
    this.setState({ chips: array, chipsText: arrayText })
    this.props.input.onChange(array)
  }

  async buildMenuItems() {
    var menuItems = []
    var defData = []
    if(this.props.service) {
      let apiParam = {}
      if(this.props.apiParam){
        apiParam = this.props.apiParam
      }
      let res = await this.props.service.api[this.props.apiPath](apiParam, ()=>{}, this.props.fetchOption);
      defData = res.data;
      menuItems = res.data.map(this.props.itemTemplate.bind(this))
    } else if(this.props.options) {
      defData = this.props.options;
      menuItems = this.props.options.map(this.props.itemTemplate.bind(this))
    }

    this.setState({
      menuItems,
      defData
    })
    return menuItems;
  }

  render() {
    let input = this.props.input;
    let meta = this.props.meta;
    if(!meta) meta = {}
    let optInput = {...input}
    let idProps = Math.random().toString(36).substring(7)+this.props.name;
    var _this = this
    let chipView = true
    let menuItemsView = this.state.menuItems
    if(this.props.chipView == false){
      chipView = false
      menuItemsView = []
      this.state.menuItems.map((i)=> {
        menuItemsView.push(
          <ListItem
            primaryText={i.primaryText}
          />
        )
      })
    }

    return (
        <div className={this.props.className}>
            {!this.props.noChip &&
            <DropdownMenu
                id={this.props.id+"_menu"}
                menuItems={menuItemsView}
                toggleQuery=".md-text-field-container"
                sameWidth
                className={this.props.className}
                style={{
                    display: 'block',
                    width: '100%',
                    margin: '0px',
                    ...this.props.style
                }}
                anchor={{
                    x: DropdownMenu.HorizontalAnchors.INNER_LEFT,
                    y: DropdownMenu.VerticalAnchors.BOTTOM,
                }}
                position={DropdownMenu.Positions.BELOW}
                {...this.props.dropdownProps}
                >
                <div>
                <TextField
                    id={idProps}
                    onChange={(val) => this.handleViewChange(val)}
                    value={this.state.viewValue}
                    label={this.props.label}
                    inlineIndicator={<Button icon className="text-fields__inline-btn">keyboard_arrow_down</Button>}
                    placeholder={this.props.placeholder}
                    error={meta.touched && !!meta.error} errorText={meta.error}
                />
                </div>
            </DropdownMenu>
            }
            {this.props.noChip &&
            <DropdownMenu
                id={this.props.id+"_menu"}
                menuItems={menuItemsView}
                toggleQuery=".md-text-field-container"
                sameWidth
                className={this.props.className}
                style={{
                    display: 'block',
                    width: '100%',
                    margin: '0px',
                    ...this.props.style
                }}
                anchor={{
                    x: DropdownMenu.HorizontalAnchors.INNER_LEFT,
                    y: DropdownMenu.VerticalAnchors.BOTTOM,
                }}
                position={DropdownMenu.Positions.BELOW}
                {...this.props.dropdownProps}
                visible={this.state.visible}
                onFocus={()=> { this.setState({ visible: true }) }}
                onMouseLeave={()=> { this.setState({ visible: false }) }}
                >
                <div>
                <TextField
                    id={idProps}
                    onChange={(val) => this.handleViewChange(val)}
                    value={this.state.viewValue}
                    label={this.props.label}
                    inlineIndicator={<Button icon className="text-fields__inline-btn">keyboard_arrow_down</Button>}
                    placeholder={this.props.placeholder}
                    error={meta.touched && !!meta.error} errorText={meta.error}
                />
                </div>
            </DropdownMenu>
            }
            {chipView &&
              <div className={this.props.className + " chips__list"}>
                {this.state.chipsText.map(function (i, ii){
                    if(_this.props.valueField == "parent"){ i = i[_this.props.viewField] }
                    if(_this.props.parent) { i = i[_this.props.viewField] }
                    return <Chip key={ii} label={i} removable style={{cursor: 'pointer', marginRight: 10, marginTop: 10}} onClick={_this.handleChipClick.bind(_this, ii)} />
                })}
            </div>
            }
        </div>
    )
  }
}

