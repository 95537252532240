import React, { Component } from 'react'; import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {Button, Chip, DialogContainer, Switch} from 'react-md';

//import FormDialog from './Organization.dialog.view';
import Bupot23InEditService from './Bupot23InEdit.service'
import ListView from '../../../../../../../components/entity/ListViewEbupot'
import UtilService from '../../../../../../../services/utils.service'
import download from 'downloadjs';
import uuid from 'uuid';
import iziToast from 'izitoast';
import moment from 'moment'
import Bupot23Service from '../../../Bupot23/Bupot23.service';
import Bupot23InService from '../Bupot23In/Bupot23In.service';

@connect((state)=> ({
  ...Bupot23InEditService.stateConnectSetting()(state),
  spt: state.auth.currentSPT
}), Bupot23InEditService.actionConnectSetting())
export default class Bupot23InEditFView extends ListView {
  service=Bupot23InEditService
  FormDialog=()=> <div/>

  // Edit Addon
  fetchData = async (params=this.props.table.params, onSuccess, onError) => {
    if(!onSuccess) {
      this.props.tableActions.setProperties({
        isLoading: true
      })
    }

    try {
      await this.beforeFetch(params)
      //var res = await this.service.api[this.apiPath](params, this.props.dispatch, this.fetchOption());
      var data = JSON.parse(localStorage.getItem('editBp23'));
      var total = parseInt(data.length);
      if(onSuccess) onSuccess(data, total)

      if(!onSuccess) {
        this.props.tableActions.setProperties({
          isLoading: false,
          data,
          params:{
            total
          }
        })
      }

      this.setState({data})
      return {
        data,
        total
      }
    } catch(e) {
      if(onError) onError(e);
      if(!onError) {
        this.props.tableActions.setProperties({
          isLoading: false,
          error:{
            isError:true,
            title:e.statusText,
            message:e.message
          }
        });
      }
      throw e;
    }
  };
  // Edit Addon

  constructor(props){
    super(props);
    this.state = {
      ...this.state,
      total: {},
      cetak: false,
      cetakDaftar: false,
      showLoading: false
    }
  }

  addDialog=false
  editDialog=false

  addItem = () => {
    this.props.history.push(this.props.match.url + "/new");
  }

  editItem = (item) => {
    if(this.props.match.path.indexOf('ebupot-23') == -1){
      this.props.history.push(this.props.match.url + "/" + item.spt.id + "/" + item.id);
    } else {
      this.props.history.push(this.props.match.url + "/" + item.id);
    }
  }

  fetchOption() {
    const sptId = this.props.match.params.sptId
    return {
      path: Bupot23InEditService.path+'/'+sptId+'/espt23'
    }
  }

  columns=Bupot23InService.columns

  async componentDidMount() {
    window.onbeforeunload = function () {return false ;}
    document.getElementsByClassName('mpk-sidebar')[0].style.display = "none"
    this.rootPath = this.props.match.url;

    var sptId = this.props.match.params.sptId
    // var total = await Bupot23InEditService.api.summary(sptId)

    var data = JSON.parse(localStorage.getItem('editBp23'));
    var bruto = 0
    var pph = 0
    data.map((d, i)=> {
      bruto += d.bruto
      pph += d.pph
    })

    this.setState({
      total: {
        bruto: bruto,
        pph: pph
      }
    })
  }

  totalBruto() {
    var total = 0;
    if(this.state.total.bruto){
      total = this.state.total.bruto;
    }
    return total;
  }

  totalPph() {
    var total = 0;
    if(this.state.total.pph){
      total = this.state.total.pph;
    }
    return total;
  }

  async handleCetakSingle(){
    this.setState({ cetak: true })
    if(this.props.table.selected.length === 0){
      iziToast.warning({
        title: 'Pemberitahuan !',
        message: 'Pilih salah satu data yang akan dicetak',
      });
      this.setState({ cetak: false })
    } else if(this.props.table.selected.length > 1) {
      iziToast.warning({
        title: 'Pemberitahuan !',
        message: 'Hanya satu data yang dapat dicetak',
      });
      this.setState({ cetak: false })
    } else {
      try {
        var selected = this.props.table.selected
        // alert(JSON.stringify(selected))
        var selectedById = selected[0]
        // var ids = [];
        // selected.map(function (i) {
        //   ids.push(i.id)
        // })

        // var sptId = this.props.spt.data.id
        var report = await this.service.api.cetak(selectedById.id)
        var filename = report.headers.filename
        download(report.data, filename);
        this.setState({cetak: false})
      } catch (e) {
        this.setState({cetak: false})
      }
    }
  }

  async handleCetak(){
    this.setState({ cetak: true })
    if(this.props.table.selected.length === 0){
      iziToast.warning({
        title: 'Pemberitahuan !',
        message: 'Pilih salah satu data yang akan dicetak',
      });
      this.setState({ cetak: false })
    } else {
      try {
        var selected = this.props.table.selected
        var ids = [];
        selected.map(function (i) {
          ids.push(i.id)
        })

        var sptId = this.props.spt.data.id
        var report = await this.service.api.cetakBulk(sptId, ids)
        var filename = report.headers.filename
        download(report.data, filename);
        this.setState({cetak: false})
      } catch (e) {
        this.setState({cetak: false})
      }
    }
  }

  async handleCetakDaftar(){
    this.setState({ cetakDaftar: true })
    try {
      var sptId = this.props.spt.data.id
      var report = await Bupot23InEditService.api.cetakDaftar(sptId)
      var filename = report.headers.filename
      download(report.data, filename);
      this.setState({ cetakDaftar: false })
    } catch (e){
      this.setState({ cetakDaftar: false })
    }
  }

  async handleSendEmail(){
    try {
      let category = "BP_23"
      var selected = this.props.table.selected
      var ids = [];
      selected.map(function (i){
          ids.push(i.id)
      })
      var sptId = this.props.spt.data.id
      await this.service.api.emailSpt(category, sptId, ids)
      iziToast.info({
        title: "Kirim Email",
        message: "email sedang diproses silahkan cek log untuk lebih detil"
      })
    } catch (e){
      iziToast.warning({
        title: e.name,
        message: e.message
      })
    }
  }

  async deleteSelectedDataItems(){
    var selected = this.props.table.selected

    var ids = [];

    selected.map(function (i){
      ids.push(i.id)
    })

    const sptId = this.props.match.params.sptId

    var res = await this.service.api.deleteBulk(sptId, ids)

    this.fetchData()
  }

  handleEdit(){
    var selected = this.props.table.selected
    localStorage.setItem("editBp23", JSON.stringify(selected))
  }

  _barActions = [
    // {
    //   label:'word.create',
    //   iconClassName:'mdi mdi-plus',
    //   onClick:() => this.addItem()
    // },
    // {
    //   label:'word.delete',
    //   iconClassName:'mdi mdi-delete',
    //   onClick:() => {
    //     this.setState({showDialogConfirmDeleteSelected:true})
    //   },
    //   disabledFunc:() => this.props.table.selected.length === 0
    // },
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => this.fetchData()
    },
  ]

  _tableActions = [
    {
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    },
    // {label:"divider", iconClassName:"-"},
    // {
    //   label:"Delete",
    //   iconClassName:"mdi mdi-delete",
    //   onClick:(item, callback) => this.deleteItem(item, callback),
    //   confirmation:{
    //     title:"sentence.custom.deleteItem",
    //     message:"sentence.custom.deleteItemConfirmation"
    //   }
    // }
  ]

  static contextTypes = {
    showDialog: PropTypes.func.isRequired,
  };

  async handleSubmitBp(){
    var dispatchName = this.service.name.toUpperCase() + "/TABLE_SET_PROPERTIES"
    this.props.dispatch(
      {
        type: dispatchName,
        properties: {
          index: 0,
          isSelect: false,
          selected: []
        }
      }
    )
    var askSubmit = await this.context.showDialog(()=> ({
      title: null,
      width: 500,
      height: 132,
      text: (
        <div>
          <p>Apa anda yakin akan mensubmit BP terpilih ?</p>
        </div>
      )
    }))
    if(askSubmit){
      this.setState({ showLoading: true })
      try {
        var data = JSON.parse(localStorage.getItem("editBp23"))
        var res = await this.service.api.revision(data)
        iziToast.success({
          title: "Success",
          message: "proses submit success"
        })
        this.setState({ showLoading: false })
        document.getElementsByClassName('mpk-sidebar')[0].style.display = "block"
        this.props.history.push('bp-23')
      } catch (e){
        iziToast.error({
          title: "Error",
          message: "proses submit gagal, " + e.message
        })
        this.setState({ showLoading: false })
      }
    }
  }

  belowTopBar(){
    return (
      <DialogContainer
        fullPage={true}
        visible={this.state.showLoading}
        dialogStyle={{ opacity: 0.80 }}
      >
        <div style={{textAlign: 'center', padding: 300}}>
          <p>Sedang memproses data mohon ditunggu . . .</p>
        </div>
      </DialogContainer>
    )
  }

  _barItem() {
    // pembetulan = tampil hanya saat status spt finish dan pembetulan > 0
    var pembetulan = true
    if(this.props.spt && this.props.spt.data && this.props.spt.data.id && this.props.spt.data.pembetulan && this.props.spt.data.pembetulan > 0) pembetulan = true
    return (
      <div>
        <Button secondary flat onClick={()=> {
          localStorage.setItem("editBp23", "[]")
          this.props.history.push('bp-23')
          document.getElementsByClassName('mpk-sidebar')[0].style.display = "block"
        }
        } style={{marginRight: 16}}>Back</Button>
        <Button primary raised onClick={this.handleSubmitBp.bind(this)} disabled={this.state.showLoading}>Submit</Button>
        {/* {pembetulan &&
          <Button secondary raised style={{margin: 5}} onClick={this.handleEdit.bind(this)}>Edit</Button>
        }
        <Button secondary raised style={{margin: 5}} onClick={this.handleSendEmail.bind(this)}>Kirim</Button>
        <Button disabled={this.state.cetakDaftar} primary raised style={{margin: 5}} onClick={this.handleCetakDaftar.bind(this)}>Cetak Daftar</Button>
        <Button disabled={this.state.cetak} primary raised style={{margin: 5}} onClick={this.handleCetakSingle.bind(this)}>Cetak</Button> */}
        {/* <Switch
          style={{float: "right"}}
          id="switch-table-setting"
          name="Switch Table Setting"
          label=""
          checked={this.state.showTableSetting}
          onChange={() => {
            this.setState({
              showTableSetting:!this.state.showTableSetting
            })
          }}
        /> */}
      </div>
    )
  }

  footer() {
    return <div className="flex-none mpk-border thin solid top dark">
      <div className="mpk-padding-N left right">
        <table style={{width: '100%'}}>
          <tfoot className="md-table-footer">
            <tr className="md-table-column md-text-left md-table-column--data md-table-column--adjusted md-text ">
              <td>Total Jumlah Penghasilan Bruto</td>
              <td className=" md-text-right">{UtilService.number.format(this.totalBruto(), {precision: 0, thousand: '.', decimal: ','})}</td>
            </tr>
            <tr className="md-table-column md-text-left md-table-column--data md-table-column--adjusted md-text ">
              <td>Total Jumlah PPH Dipotong</td>
              <td className=" md-text-right">{UtilService.number.format(this.totalPph(), {precision: 0, thousand: '.', decimal: ','})}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  }

}
