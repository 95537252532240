import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  Tab,
  Tabs,
  SelectionControlGroup,
  Checkbox,
  Button
} from 'react-md';

import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Radio, convert, CheckboxSelection} from '../../../../../components/form';
import UtilService from '../../../../../services/utils.service'
import FormView from '../../../../../components/entity/form.view';
import DialogView from '../../../../../components/entity/dialog.view';
import LogCheckService from './LogCheck.service'
import moment from 'moment'
import counterpart from 'counterpart'
import UserService from './../User/User.service'

@reduxForm({form: 'LogCheckForm', destroyOnUnmount: true})
@connect((state) => ({
  formData:state.form.LogCheckForm,
  user: state.auth.user
}))
export default class LogCheckFormView extends FormView {
  service=LogCheckService
  viewType=2;

  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;

    return (
      <div>
        <Button secondary flat onClick={()=> {this.props.history.goBack()}} style={{marginRight: 16}}>Back</Button>
      </div>
    )
  }

  formView() {
    return (
        <Card>
            <div className='md-grid'>
                <Field
                    label='XML ID'
                    name='xmlId'
                    className="md-cell md-cell--6"
                    component={Textfield}
                    validate={validation.required}
                    disabled={true}
                />
                <Field
                    label='SPT ID'
                    name='sptId'
                    className="md-cell md-cell--6"
                    component={Textfield}
                    validate={validation.required}
                    disabled={true}
                />
                <Field
                    label='Pembetulan'
                    name='spt.pembetulan'
                    className="md-cell md-cell--4"
                    component={Textfield}
                    validate={validation.required}
                    disabled={true}
                />
                <Field
                    label='Masa'
                    name='spt.masa'
                    className="md-cell md-cell--4"
                    component={Searchfield}
                    validate={validation.required}
                    disabled={true}
                    options={[
                        {id: 1, name: 'Januari'},
                        {id: 2, name: 'Februari'},
                        {id: 3, name: 'Maret'},
                        {id: 4, name: 'April'},
                        {id: 5, name: 'Mei'},
                        {id: 6, name: 'Juni'},
                        {id: 7, name: 'Juli'},
                        {id: 8, name: 'Agustus'},
                        {id: 9, name: 'September'},
                        {id: 10, name: 'Oktober'},
                        {id: 11, name: 'November'},
                        {id: 12, name: 'Desember'}
                    ]}
                />
                <Field
                    label='Tahun'
                    name='spt.tahun'
                    className="md-cell md-cell--4"
                    component={TextfieldMask}
                    validate={validation.required}
                    disabled={true}
                />
                <Field
                    label='Name'
                    name='name'
                    className="md-cell md-cell--6"
                    component={Textfield}
                    validate={validation.required}
                    disabled={true}
                />
                <Field
                    label='Npwp'
                    name='npwp'
                    className="md-cell md-cell--6"
                    component={TextfieldMask}
                    maskFormat="##.###.###.#-###-###"
                    mask="_"
                    length="15"
                    validate={validation.required}
                    disabled={true}
                />
                <Field
                    label='Status'
                    name='status'
                    className="md-cell md-cell--6"
                    component={Textfield}
                    validate={validation.required}
                    disabled={true}
                />
                <Field
                    label='Success'
                    name='success'
                    className="md-cell md-cell--6"
                    component={Switch}
                    validate={validation.required}
                    disabled={true}
                />
                <Field
                    label='Message'
                    name='message'
                    className="md-cell md-cell--12"
                    component={Textfield}
                    validate={validation.required}
                    disabled={true}
                />
            </div>
        </Card>
    )
  }
}
