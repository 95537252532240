import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Field, reduxForm } from 'redux-form';
import {
  Card,
  Switch,
  Button,
  LinearProgress,
  TabsContainer,
  Tabs,
  Tab,
  Chip,
  DataTable,
  TableHeader,
  TableRow, TableColumn, TableBody
} from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Switch as Switch2} from '../../../components/form';

import ListView from '../../../components/entity/listView';
import axios from 'axios';
import izitoast from 'izitoast';
import LogEfillingService from './LogEfilling.service';
import download from 'downloadjs';
import ParamService from "src/services/param.service";
import _ from "lodash";
import counterpart from "counterpart";


@reduxForm({form: 'LogEfilling_Form', destroyOnUnmount: true})
@connect((state)=> ({
  ...LogEfillingService.stateConnectSetting()(state),
  user: state.auth.user,
  company: state.auth.currentCompany,
}), LogEfillingService.actionConnectSetting())
export default class LogEfillingView extends ListView {
  service=LogEfillingService
  FormDialog=()=> <div/>
  // viewType=2

  constructor(props){
    super(props);
    this.state = {
      showTableSetting:true,
      showDialogConfirmDeleteSelected:false,
      showForm:false,
      formData: {},
      data: [],
      onProgress: false,
      isError: false,
      errorMessage: ''
    }
  }

  beforeFetch(params) {
    // params["category.equals"] = "ORGANIZATION"
    // params['pasal.equals'] = this.props.match.params.pasal;
  }

  fetchOption() {
    let companyId;
    if(this.props.company) companyId = this.props.company.id;
    if(this.props.match.params.companyId && this.props.match.params.companyId != '') companyId = this.props.match.params.companyId;
    // if(companyId) {
    //   return {
    //     path: this.service.path+'/'+companyId+'/company'
    //   }

    // } else {
    //   return {
    //     path: this.service.path+'/'+this.props.match.params.pasal+'/organization'
    //   }
    // }

  }

  tableActions = (data) => {
    let actions = [
      {
        label:'Cek Status',
        iconClassName:'mdi mdi-shuffle',
        onClick: (item) => this.handleCheckStatus(item)
      },
    ]

    let divider = {
      label:"divider",
        iconClassName:"-"
    }

    if(data.status === 'FAILED') {
      let deleteAction = {
        label:"Delete",
        iconClassName:"mdi mdi-delete",
        onClick:(item, callback) => this.deleteItem(item, callback),
        confirmation:{
          title:"sentence.custom.deleteItem",
          message:"sentence.custom.deleteItemConfirmation"
        }
      }

      actions.push(divider)
      actions.push(deleteAction)
    }

    /*actions = [
      {
        label:'word.checkStatus',
        iconClassName:'mdi mdi-shuffle',
        onClick: (item) => this.handleCheckStatus(item)
      },
    ]*/

    return actions
  }

  async handleDownloadAttachment() {
    if (this.props.table.selected.length > 0) {
      let selected = this.props.table.selected

      let ids = [];

      selected.map(function (i) {
        if (i.hasAttachment) {
          ids.push(i.id)
        }
      })

      if (ids.length === 0) {
        izitoast.warning({title: 'Download Lampiran', message: 'Anda harus memilih minimal 1 log yang mempunyai lampiran.'})

        return
      }

      let fileRes = await this.service.api.downloadAttachmentBulk(ids);

      let filename = fileRes.headers.filename

      download(fileRes.data, filename);
    }
  }

  async handleReqNoBpeChecked() {
    if (this.props.table.selected.length > 0) {
      var selected = this.props.table.selected

      var datas = [];

      selected.map(function (i) {
        let step = i.step

        if (step >= 3) {
          datas.push(i.id)
        }
      })

      if (datas.length) {
        var res = await this.service.api.reqNoBpeChecked(datas);

        izitoast.success({title: 'Req No BPE', message: 'Request no BPE akan segara di proses.'})

        this.fetchData()
      }
      else {
        izitoast.warning({title: 'Req No BPE', message: 'Request no BPE hanya bisa untuk status minimal PROCESS REQ NO BPE.'})
      }
    }
  }

  async handleDownloadChecked() {

    if (this.props.table.selected.length > 0) {
      let selected = this.props.table.selected

      let datas = [];

      selected.map(function (i) {
        let data = {
          pasal: i.pasal,
          sptId: i.sptId
        }

        datas.push(data)
      })

      let fileRes = await this.service.api.downloadBpeChecked(datas);

      let filename = fileRes.headers.filename

      download(fileRes.data, filename);
    }
  }

  async handleCheckStatus(item) {
    let datas = [];

    let data = {
      pasal: item.pasal,
      sptId: item.sptId
    }

    datas.push(data)

    var res = await this.service.api.checkStatusChecked(datas);

    izitoast.success({title: 'Cek Status', message: 'Cek status berhasil.'})

    this.fetchData()
  }

  async handleCheckStatusChecked() {

    if (this.props.table.selected.length > 0) {
      var selected = this.props.table.selected

      var datas = [];

      selected.map(function (i) {
        let data = {
          pasal: i.pasal,
          sptId: i.sptId
        }

        datas.push(data)
      })

      var res = await this.service.api.checkStatusChecked(datas);

      izitoast.success({title: 'Cek Status', message: 'Cek status berhasil.'})

      this.fetchData()
    }
  }

  async handleGetNtpaChecked() {
    if (this.props.table.selected.length > 0) {
      var selected = this.props.table.selected

      var ids = [];

      selected.map(function (i) {
        ids.push(i.id)
      })

      let data = {
        idList: ids
      }

      var res = await this.service.api.getNtpaChecked(data);

      izitoast.success({title: 'Get NTPA', message: res.message})

      this.fetchData()
    }
  }

  async handleGetNtteChecked() {
    if (this.props.table.selected.length > 0) {
      var selected = this.props.table.selected

      var ids = [];

      selected.map(function (i) {
        if (i.ntte && !i.ntteCode) {
          ids.push(i.id)
        }
      })

      if (ids.length === 0) {
        izitoast.error({title: 'Get NTTE', message: 'Anda harus menceklis minimal 1 data yang sudah dapat tarik NTTE.'})

        return
      }

      let data = {
        idList: ids
      }

      var res = await this.service.api.getNtteChecked(data);

      izitoast.success({title: 'Get NTTE', message: res.message})

      this.fetchData()
    }
  }

  async handleForceUploadChecked() {

    if (this.props.table.selected.length > 0) {
      var selected = this.props.table.selected

      var datas = [];

      selected.map(function (i) {
        let data = {
          pasal: i.pasal,
          sptId: i.sptId
        }

        datas.push(data)
      })

      var res = await this.service.api.forceUploadChecked(datas);

      izitoast.success({title: 'Force Upload', message: 'File lapor akan segera diupload, silahkan tunggu.'})

      this.fetchData()
    }
  }

  async handleSetFailed(item) {
    let datas = [];

    datas.push(item.id)

    var res = await this.service.api.setFailedChecked(datas);

    izitoast.success({title: 'Set Failed', message: 'Set failed berhasil.'})

    this.fetchData()
  }

  async handleSetFailedChecked() {
    if (this.props.table.selected.length > 0) {
      var selected = this.props.table.selected

      var datas = [];

      selected.map(function (i) {
        datas.push(i.id)
      })

      var res = await this.service.api.setFailedChecked(datas);

      izitoast.success({title: 'Set Failed', message: 'Set failed berhasil.'})

      this.fetchData()
    }
  }

  async handleSetSkemaBaruChecked() {
    if (this.props.table.selected.length > 0) {
      var selected = this.props.table.selected

      var datas = [];

      selected.map(function (i) {
        datas.push(i.id)
      })

      var res = await this.service.api.setSkemaBaruChecked(datas);

      izitoast.success({title: 'Set Skema Baru', message: 'Set skema baru berhasil.'})

      this.fetchData()
    }
  }

  async handleSetSkemaLamaChecked() {
    if (this.props.table.selected.length > 0) {
      var selected = this.props.table.selected

      var datas = [];

      selected.map(function (i) {
        datas.push(i.id)
      })

      var res = await this.service.api.setSkemaLamaChecked(datas);

      izitoast.success({title: 'Set Skema Lama', message: 'Set skema lama berhasil.'})

      this.fetchData()
    }
  }

  async handleExportExcel() {
    let selected = this.props.table.selected

    if (selected.length > 0) {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export Excel',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: 'Apakah anda yakin akan meng-export Excel data yang diceklis?'
      }));

      if(dialog) {
        let ids = [];

        selected.map(function (i) {
          ids.push(i.id)
        });

        let res = await LogEfillingService.api.exportBulkExcel(ids);

        let filename = res.headers.filename;

        download(res.data, filename);
      }
    }
    else {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export Excel',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: (
          <div>
            <this.ig.Field
              label='Nama'
              name='nama__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Nama Alias'
              name='namaAlias__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='NPWP'
              name='npwp__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Pasal'
              name='pasal__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Dari Bulan'
              name='masa__greaterOrEqualThan'
              className="md-cell md-cell--12"
              component={this.ig.Searchfield}
              options={[
                {id: 1, name: "1"},
                {id: 2, name: "2"},
                {id: 3, name: "3"},
                {id: 4, name: "4"},
                {id: 5, name: "5"},
                {id: 6, name: "6"},
                {id: 7, name: "7"},
                {id: 8, name: "8"},
                {id: 9, name: "9"},
                {id: 10, name: "10"},
                {id: 11, name: "11"},
                {id: 12, name: "12"}
              ]}
            />
            <this.ig.Field
              label='Sampai Bulan'
              name='masa__lessOrEqualThan'
              className="md-cell md-cell--12"
              component={this.ig.Searchfield}
              options={[
                {id: 1, name: "1"},
                {id: 2, name: "2"},
                {id: 3, name: "3"},
                {id: 4, name: "4"},
                {id: 5, name: "5"},
                {id: 6, name: "6"},
                {id: 7, name: "7"},
                {id: 8, name: "8"},
                {id: 9, name: "9"},
                {id: 10, name: "10"},
                {id: 11, name: "11"},
                {id: 12, name: "12"}
              ]}
            />

            <this.ig.Field
              label='Bulan'
              name='masa__equals'
              className="md-cell md-cell--12"
              component={this.ig.Searchfield}
              options={[
                {id: 1, name: "1"},
                {id: 2, name: "2"},
                {id: 3, name: "3"},
                {id: 4, name: "4"},
                {id: 5, name: "5"},
                {id: 6, name: "6"},
                {id: 7, name: "7"},
                {id: 8, name: "8"},
                {id: 9, name: "9"},
                {id: 10, name: "10"},
                {id: 11, name: "11"},
                {id: 12, name: "12"}
              ]}
            />

            <this.ig.Field
              label='Tahun'
              name='tahun__equals'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />

            <this.ig.Field
              label='WAITING'
              name='status__in_WAITING'
              className="md-cell md-cell--12"
              component={this.ig.Checkbox}
            />
            <this.ig.Field
              label='PROCESS'
              name='status__in_PROCESS'
              className="md-cell md-cell--12"
              component={this.ig.Checkbox}
            />
            <this.ig.Field
              label='FINISH'
              name='status__in_FINISH'
              className="md-cell md-cell--12"
              component={this.ig.Checkbox}
            />
            <this.ig.Field
              label='FAILED'
              name='status__in_FAILED'
              className="md-cell md-cell--12"
              component={this.ig.Checkbox}
            />

            <this.ig.Field
              label='UPLOAD'
              name='step__in_1'
              className="md-cell md-cell--12"
              component={this.ig.Checkbox}
            />
            <this.ig.Field
              label='CHECK'
              name='step__in_2'
              className="md-cell md-cell--12"
              component={this.ig.Checkbox}
            />
            <this.ig.Field
              label='REQ BPE'
              name='step__in_3'
              className="md-cell md-cell--12"
              component={this.ig.Checkbox}
            />
            <this.ig.Field
              label='DOWNLOAD BPE'
              name='step__in_4'
              className="md-cell md-cell--12"
              component={this.ig.Checkbox}
            />
          </div>
        )
      }));

      if (dialog === false)
        return

      if (dialog === undefined)
        dialog = {}

      if (dialog) {
        let param = ParamService.convert(dialog)

        let res = await LogEfillingService.api.exportByParamExcel(param);

        let filename = res.headers.filename;

        download(res.data, filename);
      }
    }
  }

  async handleExportExcelNtpn() {
    let selected = this.props.table.selected

    if (selected.length > 0) {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export Excel Dengan NTPN',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: 'Apakah anda yakin akan meng-export Excel dengan NTPN data yang diceklis?'
      }));

      if(dialog) {
        let ids = [];

        selected.map(function (i) {
          ids.push(i.id)
        });

        let res = await LogEfillingService.api.exportBulkExcelNtpn(ids);

        let filename = res.headers.filename;

        download(res.data, filename);
      }
    }
    else {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export Excel Dengan NTPN',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: (
          <div>
            <this.ig.Field
              label='Nama'
              name='nama__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Nama Alias'
              name='namaAlias__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='NPWP'
              name='npwp__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Pasal'
              name='pasal__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Dari Bulan'
              name='masa__greaterOrEqualThan'
              className="md-cell md-cell--12"
              component={this.ig.Searchfield}
              options={[
                {id: 1, name: "1"},
                {id: 2, name: "2"},
                {id: 3, name: "3"},
                {id: 4, name: "4"},
                {id: 5, name: "5"},
                {id: 6, name: "6"},
                {id: 7, name: "7"},
                {id: 8, name: "8"},
                {id: 9, name: "9"},
                {id: 10, name: "10"},
                {id: 11, name: "11"},
                {id: 12, name: "12"}
              ]}
            />
            <this.ig.Field
              label='Sampai Bulan'
              name='masa__lessOrEqualThan'
              className="md-cell md-cell--12"
              component={this.ig.Searchfield}
              options={[
                {id: 1, name: "1"},
                {id: 2, name: "2"},
                {id: 3, name: "3"},
                {id: 4, name: "4"},
                {id: 5, name: "5"},
                {id: 6, name: "6"},
                {id: 7, name: "7"},
                {id: 8, name: "8"},
                {id: 9, name: "9"},
                {id: 10, name: "10"},
                {id: 11, name: "11"},
                {id: 12, name: "12"}
              ]}
            />

            <this.ig.Field
              label='Bulan'
              name='masa__equals'
              className="md-cell md-cell--12"
              component={this.ig.Searchfield}
              options={[
                {id: 1, name: "1"},
                {id: 2, name: "2"},
                {id: 3, name: "3"},
                {id: 4, name: "4"},
                {id: 5, name: "5"},
                {id: 6, name: "6"},
                {id: 7, name: "7"},
                {id: 8, name: "8"},
                {id: 9, name: "9"},
                {id: 10, name: "10"},
                {id: 11, name: "11"},
                {id: 12, name: "12"}
              ]}
            />

            <this.ig.Field
              label='Tahun'
              name='tahun__equals'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />

            <this.ig.Field
              label='WAITING'
              name='status__in_WAITING'
              className="md-cell md-cell--12"
              component={this.ig.Checkbox}
            />
            <this.ig.Field
              label='PROCESS'
              name='status__in_PROCESS'
              className="md-cell md-cell--12"
              component={this.ig.Checkbox}
            />
            <this.ig.Field
              label='FINISH'
              name='status__in_FINISH'
              className="md-cell md-cell--12"
              component={this.ig.Checkbox}
            />
            <this.ig.Field
              label='FAILED'
              name='status__in_FAILED'
              className="md-cell md-cell--12"
              component={this.ig.Checkbox}
            />

            <this.ig.Field
              label='UPLOAD'
              name='step__in_1'
              className="md-cell md-cell--12"
              component={this.ig.Checkbox}
            />
            <this.ig.Field
              label='CHECK'
              name='step__in_2'
              className="md-cell md-cell--12"
              component={this.ig.Checkbox}
            />
            <this.ig.Field
              label='REQ BPE'
              name='step__in_3'
              className="md-cell md-cell--12"
              component={this.ig.Checkbox}
            />
            <this.ig.Field
              label='DOWNLOAD BPE'
              name='step__in_4'
              className="md-cell md-cell--12"
              component={this.ig.Checkbox}
            />
          </div>
        )
      }));

      if (dialog === false)
        return

      if (dialog === undefined)
        dialog = {}

      if (dialog) {
        let param = ParamService.convert(dialog)

        let res = await LogEfillingService.api.exportByParamExcelNtpn(param);

        let filename = res.headers.filename;

        download(res.data, filename);
      }
    }
  }

  async handleExportExcelUnit() {
    let selected = this.props.table.selected

    if (selected.length > 0) {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export Excel Dengan Unit',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: 'Apakah anda yakin akan meng-export Excel dengan Unit data yang diceklis?'
      }));

      if(dialog) {
        let ids = [];

        selected.map(function (i) {
          ids.push(i.id)
        });

        let res = await LogEfillingService.api.exportBulkExcelUnit(ids);

        let filename = res.headers.filename;

        download(res.data, filename);
      }
    }
    else {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export Excel Dengan Unit',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: (
          <div>
            <this.ig.Field
              label='Nama'
              name='nama__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Nama Alias'
              name='namaAlias__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='NPWP'
              name='npwp__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Pasal'
              name='pasal__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Dari Bulan'
              name='masa__greaterOrEqualThan'
              className="md-cell md-cell--12"
              component={this.ig.Searchfield}
              options={[
                {id: 1, name: "1"},
                {id: 2, name: "2"},
                {id: 3, name: "3"},
                {id: 4, name: "4"},
                {id: 5, name: "5"},
                {id: 6, name: "6"},
                {id: 7, name: "7"},
                {id: 8, name: "8"},
                {id: 9, name: "9"},
                {id: 10, name: "10"},
                {id: 11, name: "11"},
                {id: 12, name: "12"}
              ]}
            />
            <this.ig.Field
              label='Sampai Bulan'
              name='masa__lessOrEqualThan'
              className="md-cell md-cell--12"
              component={this.ig.Searchfield}
              options={[
                {id: 1, name: "1"},
                {id: 2, name: "2"},
                {id: 3, name: "3"},
                {id: 4, name: "4"},
                {id: 5, name: "5"},
                {id: 6, name: "6"},
                {id: 7, name: "7"},
                {id: 8, name: "8"},
                {id: 9, name: "9"},
                {id: 10, name: "10"},
                {id: 11, name: "11"},
                {id: 12, name: "12"}
              ]}
            />

            <this.ig.Field
              label='Bulan'
              name='masa__equals'
              className="md-cell md-cell--12"
              component={this.ig.Searchfield}
              options={[
                {id: 1, name: "1"},
                {id: 2, name: "2"},
                {id: 3, name: "3"},
                {id: 4, name: "4"},
                {id: 5, name: "5"},
                {id: 6, name: "6"},
                {id: 7, name: "7"},
                {id: 8, name: "8"},
                {id: 9, name: "9"},
                {id: 10, name: "10"},
                {id: 11, name: "11"},
                {id: 12, name: "12"}
              ]}
            />

            <this.ig.Field
              label='Tahun'
              name='tahun__equals'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />

            <this.ig.Field
              label='WAITING'
              name='status__in_WAITING'
              className="md-cell md-cell--12"
              component={this.ig.Checkbox}
            />
            <this.ig.Field
              label='PROCESS'
              name='status__in_PROCESS'
              className="md-cell md-cell--12"
              component={this.ig.Checkbox}
            />
            <this.ig.Field
              label='FINISH'
              name='status__in_FINISH'
              className="md-cell md-cell--12"
              component={this.ig.Checkbox}
            />
            <this.ig.Field
              label='FAILED'
              name='status__in_FAILED'
              className="md-cell md-cell--12"
              component={this.ig.Checkbox}
            />

            <this.ig.Field
              label='UPLOAD'
              name='step__in_1'
              className="md-cell md-cell--12"
              component={this.ig.Checkbox}
            />
            <this.ig.Field
              label='CHECK'
              name='step__in_2'
              className="md-cell md-cell--12"
              component={this.ig.Checkbox}
            />
            <this.ig.Field
              label='REQ BPE'
              name='step__in_3'
              className="md-cell md-cell--12"
              component={this.ig.Checkbox}
            />
            <this.ig.Field
              label='DOWNLOAD BPE'
              name='step__in_4'
              className="md-cell md-cell--12"
              component={this.ig.Checkbox}
            />
          </div>
        )
      }));

      if (dialog === false)
        return

      if (dialog === undefined)
        dialog = {}

      if (dialog) {
        let param = ParamService.convert(dialog)

        let res = await LogEfillingService.api.exportByParamExcelUnit(param);

        let filename = res.headers.filename;

        download(res.data, filename);
      }
    }
  }

  async deleteSelectedDataItems() {
    var items = this.props.table.selected

    var dialog = await this.context.showDialog((props, res, rej) =>({
      title: 'Delete',
      initialValue: {},
      height: 'auto',
      width: 400,
      okText: 'Ya',
      text: 'Apakah anda yakin akan meng-hapus data yang terpilih?'
    }));

    if(dialog) {
      try {
        let ids = []

        items.forEach(item => {
          if (item.status === 'FAILED') {
            ids.push(item.id)
          }
        })

        if (ids.length) {
          await this.service.api.deleteSelected(ids)
          await this.fetchData()
        }
        else {
          izitoast.error({title: 'Gagal', message: 'Yang bisa dihapus hanya yang statusnya FAILED.'})
        }

      } catch(e) {
      }
    }
  }

  pad(v) {
    return ('0' + v).slice(-2)
  }

  _barActions = [
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => this.fetchData()
    },
    {
      label:'word.downloadAttachment',
      iconClassName:'mdi mdi-briefcase-download',
      disabledFunc:() => this.props.table.selected.length === 0,
      onClick:() => this.handleDownloadAttachment()
    },
    {
      label:'word.requestNoBpe',
      iconClassName:'mdi mdi-autorenew',
      disabledFunc:() => this.props.table.selected.length === 0,
      onClick:() => this.handleReqNoBpeChecked()
    },
    {
      label:'word.downloadBpe',
      iconClassName:'mdi mdi-download',
      disabledFunc:() => this.props.table.selected.length === 0,
      onClick:() => this.handleDownloadChecked()
    },
    {
      label:'word.checkStatus',
      iconClassName:'mdi mdi-shuffle',
      disabledFunc:() => this.props.table.selected.length === 0,
      onClick:() => this.handleCheckStatusChecked()
    },
    {
      label:'word.getNtpa',
      iconClassName:'mdi mdi-shuffle-variant',
      disabledFunc:() => this.props.table.selected.length === 0,
      onClick:() => this.handleGetNtpaChecked()
    },
    {
      label:'word.getNtte',
      iconClassName:'mdi mdi-shuffle-disabled',
      disabledFunc:() => this.props.table.selected.length === 0,
      onClick:() => this.handleGetNtteChecked()
    },
    {
      label:'word.forceUpload',
      iconClassName:'mdi mdi-alert-octagon',
      disabledFunc:() => this.props.table.selected.length === 0,
      onClick:() => this.handleForceUploadChecked()
    },
    {
      label:'word.setFailed',
      iconClassName:'mdi mdi-minus-circle',
      disabledFunc:() => this.props.table.selected.length === 0,
      onClick:() => this.handleSetFailedChecked()
    },
    {
      label:'word.setSkemaBaru',
      iconClassName:'mdi mdi-access-point-network',
      disabledFunc:() => this.props.table.selected.length === 0,
      onClick:() => this.handleSetSkemaBaruChecked()
    },
    {
      label:'word.setSkemaLama',
      iconClassName:'mdi mdi mdi-access-point',
      disabledFunc:() => this.props.table.selected.length === 0,
      onClick:() => this.handleSetSkemaLamaChecked()
    },
    {
      label:'word.exportExcel',
      iconClassName:'mdi mdi-clipboard-text',
      // disabledFunc:() => this.props.table.selected.length === 0,
      onClick:() => this.handleExportExcel()
    },
    {
      label:'word.exportExcelNtpn',
      iconClassName:'mdi mdi-clipboard-pulse',
      // disabledFunc:() => this.props.table.selected.length === 0,
      onClick:() => this.handleExportExcelNtpn()
    },
    {
      label:'word.exportExcelUnit',
      iconClassName:'mdi mdi-clipboard-check',
      // disabledFunc:() => this.props.table.selected.length === 0,
      onClick:() => this.handleExportExcelUnit()
    },
    {
      label:'word.delete',
      iconClassName:'mdi mdi-delete',
      onClick: async () => {
        await this.deleteSelectedDataItems()
      },
      disabledFunc:() => {
        let selected = this.props.table.selected
        let deleteable = []

        selected.forEach(data => {
          if(data.status === 'FAILED') {
            deleteable.push(data.id)
          }
        })

        return deleteable.length === 0
      }
    },
  ]

  async showDetail(d) {
    var d = await this.context.showDialog((props, res, rej) =>({
      title: 'Details',
      initialValue: {},

      okText: 'Back',
      text: (
        <div>
        {d.isJoin && <span className='mpk-badge md-green-500--background'>JOIN</span> }
        {!d.isJoin && <span className='mpk-badge md-red-500--background'>JOIN</span> }

        {d.isEncrypt && <span className='mpk-badge md-green-500--background'>ENCRYPT</span> }
        {!d.isEncrypt && <span className='mpk-badge md-red-500--background'>ENCRYPT</span> }

        {d.isWrap && <span className='mpk-badge md-green-500--background'>WRAP</span> }
        {!d.isWrap && <span className='mpk-badge md-red-500--background'>WRAP</span> }

        <br/>

        {d.isComplete && <span className='mpk-badge md-green-500--background'>COMPLETE</span> }
        {!d.isComplete && <span className='mpk-badge md-red-500--background'>COMPLETE</span> }

        {d.isWrap && <span className='mpk-badge md-green-500--background'>WRAP</span> }
        {!d.isWrap && <span className='mpk-badge md-red-500--background'>WRAP</span> }

        {d.isBpe && <span className='mpk-badge md-green-500--background'>BPE</span> }
        {!d.isBpe && <span className='mpk-badge md-red-500--background'>BPE</span> }

        {d.isSendingToASP && <span className='mpk-badge md-green-500--background'>SENDING TO ASP</span> }
        {!d.isSendingToASP && <span className='mpk-badge md-red-500--background'>SENDING TO ASP</span> }

        {d.isReceivedByASP && <span className='mpk-badge md-green-500--background'>RECEIVED BY ASP</span> }
        {!d.isReceivedByASP && <span className='mpk-badge md-red-500--background'>RECEIVED BY ASP</span> }

        <br/>

        {d.isSendingToDJP && <span className='mpk-badge md-green-500--background'>SENDING TO DJP</span> }
        {!d.isSendingToDJP && <span className='mpk-badge md-red-500--background'>SENDING TO DJP</span> }

        {d.isReceivedByDJP && <span className='mpk-badge md-green-500--background'>RECEIVED BY DJP</span> }
        {!d.isReceivedByDJP && <span className='mpk-badge md-red-500--background'>RECEIVED BY DJP</span> }

        {d.isReceivedByDJP && <span className='mpk-badge md-green-500--background'>RECEIVED BY DJP</span> }
        {!d.isReceivedByDJP && <span className='mpk-badge md-red-500--background'>RECEIVED BY DJP</span> }
        </div>
      )
    }));
  }

  columns=[
    {isSortable: true, label: "word.name",  value: "nama", isDefaultSort:false, show:true, isSearchable:true},
    {isSortable: true, label: "word.aliasName",  value: "namaAlias", isDefaultSort:false, show:true, isSearchable:true},
    {isSortable: true, label: "word.npwp",  value: "npwp", isDefaultSort:false, show:true, isSearchable:true},
    {isSortable: true, label: "word.month",  value: "masa", isDefaultSort:false, show:true, isSearchable:true, type: 'number'},
    {isSortable: true, label: "word.tahun",  searchField: "tahun", value: (d)=> d.tahun, show:true, isSearchable:true, type: 'func'},
    {isSortable: true, label: "word.pembetulan",  value: "pembetulan", isDefaultSort:false, show:true, isSearchable:true, type: 'number'},

    {isSortable: true, label: "word.pasal",  value: (d)=> {
      var style = {
        background: 'grey'
      }

      switch (d.pasal) {
        case '21':
          style.background = '#4caf50'
          break;
        case '23':
          style.background = '#ff5722'
          break;
        case '4A2':
          style.background = '#2196f3'
          break;
        case '22':
          style.background = '#e91e63'
          break;
        case '15':
          style.background = '#9c27b0'
          break;
        default:
          break;
      }

      return <Chip label={d.pasal} style={style} />
    }, isDefaultSort:false, show:true, isSearchable:false, searchField: "pasal", type: 'func'},

    {isSortable: false, label: "word.fileName",  value: (d)=> {
      let pasal = d.pasal
      let skemaBaru = d.skemaBaru
      let kodeForm = ""

      switch (pasal) {
        case "21":
          kodeForm = "F1132010413"
          break;
        case "23":
          kodeForm = "F1132031009"
          break;
        case "4A2":
          if (skemaBaru) {
            kodeForm = "F1132041218"
          }
          else {
            kodeForm = "F1132041009"
          }
          break;
        case "22":
          kodeForm = "F1132021009"
          break;
        case "15":
          kodeForm = "F1132051009"
          break;
      }

      let npwp = d.npwp;
      let masa = this.pad(d.masa);
      let tahun = d.tahun;
      let pembetulan = this.pad(d.pembetulan);
      let fileName = npwp +  masa + masa + tahun + pembetulan + kodeForm

      return fileName
    }, isDefaultSort:false, show:true, isSearchable:false, type: 'func'},

    {isSortable: true, label: "word.attachment",  value: "hasAttachment", isDefaultSort:false, show:true, isSearchable:false, type: "boolean", searchField: "hasAttachment"},

    {
      searchField: "status",
      label: "word.status",
      type: 'func',
      value: (d)=> {
        var style = {
          background: 'grey'
        }
        switch (d.status) {
          case 'PROCESS':
            style.background = '#2196F3'
            break;
          case 'FINISH':
            style.background = '#4CAF50'
            break;
          case 'FAILED':
            style.background = '#f44336'
            break;
          default:
            break;
        }

        var step = '';
        switch (d.step) {
          case 1:
            step = 'UPLOAD'
            break;
          case 2:
            step = 'CHECK'
            break;
          case 3:
            step = 'REQ BPE'
            break;
          case 4:
            step = 'DOWNLOAD BPE'
            break;

          default:
            break;
        }
        return <div style={{width: 200}}>
          <Chip label={d.status} style={style} />

          {d.step &&
            <Chip label={step} />
          }
          </div>
      },
      className: "mpk-font-size-S", show:true, isSearchable:true},
      {isSortable: true, label: "word.message",  value: "message", isDefaultSort:false, show:true, isSearchable:false},
      {isSortable: true, label: "word.ntpa",  value: "ntpa", isDefaultSort:false, show:true, isSearchable:false},
      {isSortable: true, label: "word.ntte",  value: "ntte", isDefaultSort:false, show:true, isSearchable:false, type: "boolean", searchField: "ntte"},
      {isSortable: true, label: "word.ntteCode",  value: "ntteCode", isDefaultSort:false, show:true, isSearchable:false},
      {isSortable: true, label: "word.bpe",  value: "bpe", isDefaultSort:false, show:true, isSearchable:false, type: "boolean", searchField: "bpe"},
      {isSortable: true, label: "word.taxType",  value: "taxType", isDefaultSort:false, show:false, isSearchable:false},
      {isSortable: true, label: "word.amount",  value: "amount", isDefaultSort:false, show:false, isSearchable:false},
  ]

  async handleSave(value) {
    var _this = this
    try {
      this.setState({onProgress: true})
      let formData = new FormData();
      formData.append("type", value.type)
      formData.append("category", "ORGANIZATION")
      formData.append("file", value.file)
      formData.append("no", value.no)
      axios.post(`/api/${ImportService.pathImportOrganization}`, formData).then(function (res){
        izitoast.info({title: 'Import File', message: 'sedang diproses cek log untuk lebih detil'})
        _this.setState({onProgress: false})
        _this.fetchData()
      }).catch(function (e){
        _this.fetchData()
        var msg = e.message;
        if(e.response) msg = e.response.data.message;
        _this.setState({
          isError: true,
          onProgress: false,
          errorMessage: msg
        })
      })
    } catch(e) {
      _this.fetchData()
      var msg = e.message;
      if(e.response) msg = e.response.data.message;
      _this.setState({
        isError: true,
        onProgress: false,
        errorMessage: msg
      })
    }
  }

  componentDidMount(){
    let path = this.props.match.path.split("/")
    let params = path[2];
    this.props.change("category", params)
    this.props.change("type", "M")
    this.props.change("no", false)
  }

  handleTabChange(newActiveTabIndex){
    if(newActiveTabIndex == 0){
      this.props.change("type", "M")
    } else {
      this.props.change("type", "A")
    }
  }

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='Nama'
          name='nama__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Nama Alias'
          name='namaAlias__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='NPWP'
          name='npwp__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Pasal'
          name='pasal__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Dari Bulan'
          name='masa__greaterOrEqualThan'
          className="md-cell md-cell--6"
          component={this.ig.Searchfield}
          options={[
            {id: 1, name: "1"},
            {id: 2, name: "2"},
            {id: 3, name: "3"},
            {id: 4, name: "4"},
            {id: 5, name: "5"},
            {id: 6, name: "6"},
            {id: 7, name: "7"},
            {id: 8, name: "8"},
            {id: 9, name: "9"},
            {id: 10, name: "10"},
            {id: 11, name: "11"},
            {id: 12, name: "12"}
          ]}
        />
        <this.ig.Field
          label='Sampai Bulan'
          name='masa__lessOrEqualThan'
          className="md-cell md-cell--6"
          component={this.ig.Searchfield}
          options={[
            {id: 1, name: "1"},
            {id: 2, name: "2"},
            {id: 3, name: "3"},
            {id: 4, name: "4"},
            {id: 5, name: "5"},
            {id: 6, name: "6"},
            {id: 7, name: "7"},
            {id: 8, name: "8"},
            {id: 9, name: "9"},
            {id: 10, name: "10"},
            {id: 11, name: "11"},
            {id: 12, name: "12"}
          ]}
        />

        <this.ig.Field
          label='Bulan'
          name='masa__equals'
          className="md-cell md-cell--12"
          component={this.ig.Searchfield}
          options={[
            {id: 1, name: "1"},
            {id: 2, name: "2"},
            {id: 3, name: "3"},
            {id: 4, name: "4"},
            {id: 5, name: "5"},
            {id: 6, name: "6"},
            {id: 7, name: "7"},
            {id: 8, name: "8"},
            {id: 9, name: "9"},
            {id: 10, name: "10"},
            {id: 11, name: "11"},
            {id: 12, name: "12"}
          ]}
        />

        <this.ig.Field
          label='Tahun'
          name='tahun__equals'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />

        <this.ig.Field
          label='WAITING'
          name='status__in_WAITING'
          className="md-cell md-cell--6"
          component={this.ig.Checkbox}
        />
        <this.ig.Field
          label='PROCESS'
          name='status__in_PROCESS'
          className="md-cell md-cell--6"
          component={this.ig.Checkbox}
        />
        <this.ig.Field
          label='FINISH'
          name='status__in_FINISH'
          className="md-cell md-cell--6"
          component={this.ig.Checkbox}
        />
        <this.ig.Field
          label='FAILED'
          name='status__in_FAILED'
          className="md-cell md-cell--6"
          component={this.ig.Checkbox}
        />

        <this.ig.Field
          label='UPLOAD'
          name='step__in_1'
          className="md-cell md-cell--12"
          component={this.ig.Checkbox}
        />
        <this.ig.Field
          label='CHECK'
          name='step__in_2'
          className="md-cell md-cell--12"
          component={this.ig.Checkbox}
        />
        <this.ig.Field
          label='REQ BPE'
          name='step__in_3'
          className="md-cell md-cell--12"
          component={this.ig.Checkbox}
        />
        <this.ig.Field
          label='DOWNLOAD BPE'
          name='step__in_4'
          className="md-cell md-cell--12"
          component={this.ig.Checkbox}
        />
      </this.SearchContainer>
    )
  }

}
