import ApiService from '../../../../services/api.service';

class MainFlowService extends ApiService {
  name= 'mainFlow';
  path= 'mainFlow';
  repoType='QueryDsl'

  api= {
    setDefault: async (data, dispatch=()=>{})=> {
      let res = await this.http.post(`${this.apiLocation}/${this.path}/setDefault`, data);
      return res;
    },
    pengajuan: async (data, dispatch=()=>{})=> {
      let res = await this.http.post(`${this.apiLocation}/${this.path}/pengajuan`, data);
      return res;
    },
    action: async (data, dispatch=()=>{})=> {
      let res = await this.http.post(`${this.apiLocation}/${this.path}/action`, data);
      return res;
    },
    pengajuanBulk: async (data, dispatch=()=>{})=> {
      let res = await this.http.post(`${this.apiLocation}/${this.path}/pengajuan/bulk`, data);
      return res;
    },
    actionBulk: async (data, dispatch=()=>{})=> {
      let res = await this.http.post(`${this.apiLocation}/${this.path}/action/bulk`, data);
      return res;
    },
    cancel: async (data, dispatch=()=>{}) => {
      let res = await this.http.post(`${this.apiLocation}/${this.path}/cancel`, data);
      return res;
    },
    cancelBulk: async (data, dispatch=()=>{}) => {
      let res = await this.http.post(`${this.apiLocation}/${this.path}/cancel/bulk`, data);
      return res;
    },
    history: async (data, dispatch=()=>{})=> {
      let res = await this.http.post(`${this.apiLocation}/${this.path}/history`, data);
      return res;
    },
    getState: async (data, dispatch=()=>{})=> {
      let res = await this.http.post(`${this.apiLocation}/${this.path}/state`, data);
      return res;
    },
  }

  constructor() {
    super();
    this.init();
    this.initApi()
  }


}


export default new MainFlowService();
