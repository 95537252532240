import React, { Component } from 'react';
import { connect } from 'react-redux';
import {TabsContainer, Tabs, Tab} from 'react-md';

import FormDialog from './Company.dialog.view';
import CompanyService from './Company.service';
import ListView from '../../../../components/entity/listView';
import authService from '../../../../services/auth.service';

@connect(CompanyService.stateConnectSetting(), CompanyService.actionConnectSetting())
export default class CompanyView extends ListView {
  service=CompanyService
  FormDialog=FormDialog
  apiPath='page'

  tableActions = (d) => {
    return [
    {
      label:"Open Company",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => {
        authService.api.changeCompany(item, this.props.dispatch)
        this.props.history.push(`/company/${item.id}/open`)
      }
    }
    ,{
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    },{
      label:"Delete",
      iconClassName:"mdi mdi-delete",
      onClick:(item, callback) => this.deleteItem(item, callback),
      confirmation:{
        title:"sentence.custom.deleteItem",
        message:"sentence.custom.deleteItemConfirmation"
      }
    }]
  }

  columns=[
    {isSortable:true, show:true, isSearchable:true, label: "word.name",  value: "nama", isDefaultSort:true},
    {isSortable:true, show:true, isSearchable:true, label: "word.email", value: "email", className: "mpk-font-size-S"},
    {isSortable:true, show:true, isSearchable:true, label: "word.phone", value: "telp", className: "mpk-font-size-S"},
    {isSortable:true, show:false, isSearchable:true, label: "word.address", value: "alamat", className: "mpk-font-size-S"}
  ]
}
