import ApiService from '../../../../../services/api.service';

class BP4a2TabunganDepositoService extends ApiService {
  name= 'BP4a2TabunganDeposito';
  path= 'spt4a2/daftar-bp-4a2';

  api= {
    findBySpt: async (SptId) => {
      return await this.http.get(`${this.apiLocation}/${this.path}/${SptId}`)
    },
    calculate: async (sptId) => {
      return await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/calculate`)
    },
    cetak: async (sptId) => {
      return await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/report`,  {
        responseType: 'blob'
      })
    }
  }

  // apiMockup= {
  //   find: [{
  //     id: 1,
  //     status: 'Process',
  //     npwp: '023945840000',
  //     name: 'PT. A',
  //     pasal: '23/26',
  //     year: '2018',
  //     organization: {name: 'PT. A', npwp: '123123'},
  //     month: 'Januari',
  //     pembetulan: '0'
  //   }],
  //   findOne: {data: {
  //     id: 1,
  //     status: 'Process',
  //     npwp: '023945840000',
  //     name: 'PT. A',
  //     pasal: '23/26',
  //     year: '2018',
  //     organization: {name: 'PT. A', npwp: '123123'},
  //     month: 'Januari',
  //     pembetulan: '0'
  //   }}
  // }

  constructor() {
    super()
    this.init()
    this.initApi()
  }
}


export default new BP4a2TabunganDepositoService();
