import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Button, SVGIcon } from 'react-md';
import AllBp21SatuTahunService from './ALL_BP_21_SATU_TAHUN.service';
import ListView from '../../../../../components/entity/listView';
import ColumnService from '../../../../../services/column.service';
import {Switch} from 'react-md';
import iziToast from 'izitoast';
import download from "downloadjs";
import BP21SatuTahunService from "../BP_21_SATU_TAHUN/BP_21_SATU_TAHUN.service";
import LogExportService from "modules/Main/Export/LogExport.service";
import {Checkbox} from "../../../../../components/form";
import {navService} from 'react-mpk';
import authService from "../../../../../services/auth.service";
import {initialize} from "redux-form";

@connect(AllBp21SatuTahunService.stateConnectSetting(), AllBp21SatuTahunService.actionConnectSetting())
export default class AllBp21SatuTahunView extends ListView {
  service=AllBp21SatuTahunService

  constructor(props){
    super(props);
    this.state = {
      showTableSetting:false,
      showDialogConfirmDeleteSelected:false,
      showForm:false,
      formData: {},
      data: []
    }

    authService.api.changeMenuType('main', this.props.dispatch);
  }

  componentDidMount() {
    var initParam = Object.keys(this.props.filterObj).reduce((pv, key) => {
      var newKey = key.replace('.', '__')
      pv[newKey] = this.props.filterObj[key]
      return pv;
    }, {})

    this.props.dispatch(initialize(this.service.name + "_search", initParam))
  }

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='Nama'
          name='nama__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='NPWP'
          name='npwp__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='NPWP Organisasi'
          name='orgNpwp__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Dari Bulan'
          name='masa__greaterOrEqualThan'
          className="md-cell md-cell--6"
          component={this.ig.Searchfield}
          options={[
            {id: 1, name: "1"},
            {id: 2, name: "2"},
            {id: 3, name: "3"},
            {id: 4, name: "4"},
            {id: 5, name: "5"},
            {id: 6, name: "6"},
            {id: 7, name: "7"},
            {id: 8, name: "8"},
            {id: 9, name: "9"},
            {id: 10, name: "10"},
            {id: 11, name: "11"},
            {id: 12, name: "12"}
          ]}
        />
        <this.ig.Field
          label='Sampai Bulan'
          name='masa__lessOrEqualThan'
          className="md-cell md-cell--6"
          component={this.ig.Searchfield}
          options={[
            {id: 1, name: "1"},
            {id: 2, name: "2"},
            {id: 3, name: "3"},
            {id: 4, name: "4"},
            {id: 5, name: "5"},
            {id: 6, name: "6"},
            {id: 7, name: "7"},
            {id: 8, name: "8"},
            {id: 9, name: "9"},
            {id: 10, name: "10"},
            {id: 11, name: "11"},
            {id: 12, name: "12"}
          ]}
        />

        <this.ig.Field
          label='Bulan'
          name='masa__equals'
          className="md-cell md-cell--12"
          component={this.ig.Searchfield}
          options={[
            {id: 1, name: "1"},
            {id: 2, name: "2"},
            {id: 3, name: "3"},
            {id: 4, name: "4"},
            {id: 5, name: "5"},
            {id: 6, name: "6"},
            {id: 7, name: "7"},
            {id: 8, name: "8"},
            {id: 9, name: "9"},
            {id: 10, name: "10"},
            {id: 11, name: "11"},
            {id: 12, name: "12"}
          ]}
        />

        <this.ig.Field
          label='Tahun'
          name='tahun__equals'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Pembetulan'
          name='pembetulan__equals'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Kode Pajak'
          name='code__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Pembuat'
          name='createdUserLogin__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          className="md-cell md-cell--12"
          label     = 'PROCESS'
          name      = 'statusProcess__equals'
          component = {Checkbox}
        />
        <this.ig.Field
          className="md-cell md-cell--12"
          label     = 'WAITING'
          name      = 'statusWaiting__equals'
          component = {Checkbox}
        />
        <this.ig.Field
          className="md-cell md-cell--12"
          label     = 'FINISH'
          name      = 'statusFinish__equals'
          component = {Checkbox}
        />
      </this.SearchContainer>
    )
  }

  editDialog = false;
  _barActions = [];

  editItem = (item) => {
    let sptId = item.spt21.id

    navService.redirectTo(`/SPT_21/${sptId}/open/BP_21_SATU_TAHUN`)
  }

  async handleSendEmail(){
    try {
      let category = "BP_21_SATU_TAHUN"
      var selected = this.props.table.selected
      var ids = [];
      selected.map(function (i){
        ids.push(i.id)
      })
      await this.service.api.email(category, ids)
      iziToast.info({
        title: "Kirim Email",
        message: "email sedang diproses silahkan cek log untuk lebih detil"
      })
    } catch (e){
      iziToast.warning({
        title: e.name,
        message: e.message
      })
    }
  }

  async handleCetak() {
    this.setState({cetak: true})
    if (this.props.table.selected.length === 0) {
      iziToast.warning({
        title: 'Pemberitahuan !',
        message: 'Pilih salah satu data yang akan dicetak',
      });
    }

    else if (this.props.table.selected.length > 1) {
      try {
        var selected = this.props.table.selected
        var ids = [];
        selected.map(function (i) {
          ids.push(i.spt21.id)
        })

        var report = await BP21SatuTahunService.api.cetakBulk(ids)
        var filename = report.headers.filename
        download(report.data, filename);
        this.setState({cetak: false})
      } catch (e) {
        const reader = new FileReader();

        /// This fires after the blob has been read/loaded.
        reader.addEventListener('loadend', (e) => {
          const text = e.srcElement.result
          let json = JSON.parse(text)

          if (json.detail)
            iziToast.error({
              title: 'Error',
              message: json.detail
            })
        });

        reader.readAsText(e.response.data)

        this.setState({cetak: false})
      }
    }

    else {
      try {
        var selection = this.props.table.selected[0]
        var sptId = selection.spt21.id
        var report = await BP21SatuTahunService.api.cetakDaftar(sptId)
        var filename = report.headers.filename
        download(report.data, filename);
        this.setState({cetak: false})
      } catch (e) {
        const reader = new FileReader();

        /// This fires after the blob has been read/loaded.
        reader.addEventListener('loadend', (e) => {
          const text = e.srcElement.result
          let json = JSON.parse(text)

          if (json.detail)
            iziToast.error({
              title: 'Error',
              message: json.detail
            })
        });

        reader.readAsText(e.response.data)

        this.setState({cetak: false})
      }
    }
  }

  columns=[
    {isSortable: true, label: "word.npwpProfile", value:"spt21.organization.npwp", show: true, isSearchable: true},
    {isSortable: true, label: "word.year", searchField: "spt21.year", value: (d)=> d.spt21.year, type: "func", show: true, isSearchable: true},
    {isSortable: true, label: "word.month", value: "spt21.month", type: "number", show: true, isSearchable: true},
    {isSortable: true, label: "word.pembetulan", value: "spt21.pembetulan", type: "number", show: true, isSearchable: true},
    {isSortable: true, label: "word.npwp", value: "npwp", show:true, isSearchable:true},
    {isSortable: true, label: "word.name", value: "nama", show:true, isSearchable:true},
    {isSortable: true, label: "word.taxCode", value: "code", show:true, isSearchable:true},
    {isSortable: true, label: "word.bruto", value: "bruto", show:true, isSearchable:true, type: 'number'},
    {isSortable: true, label: "word.pph", value: "pph", show:true, isSearchable:true, type: 'number'},
    {isSortable: true, label: "word.date",  value: "date", show:true, isSearchable:true},
  ]

  _tableActions = [
    {
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    }
  ]

}
