import ApiService from '../../../../services/api.service';
import http from "../../../../services/http.service";

class OrganizationKppService extends ApiService {
  name = 'OrganizationKpp';
  path = 'organization-kpp';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api = {

  }

}

export default new OrganizationKppService();
