import ApiService from '../../../../services/api.service';

class PaymentNotificationService extends ApiService {
  name= 'PaymentNotification';
  path= 'ebilling/invoices';

  api = {
    show: async (id) => {
      var res = await this.http.get(`${this.apiLocation + "/" + this.path}/${id}`)

      return res.data;
    },
    confirmOrder: async (id) => {
      var res = await this.http.get(`${this.apiLocation + "/" + this.path}/confirmOrder/${id}`)

      return res.data;
    },
    print: async (id) => {
      var res = await this.http.get(`${this.apiLocation + "/" + this.path}/print/${id}`, {
        responseType: 'blob'
      })

      return res;
    },
    printBulk: async (data) => {
      var res = await this.http.post(`${this.apiLocation + "/" + this.path}/print-bulk`, data)

      return res.data;
    },
    printBpn: async (data) => {
      var res = await this.http.post(`${this.apiLocation + "/" + this.path}/print-bpn`, data)

      return res.data;
    },
    printBpnGabung: async (data) => {
      var res = await this.http.post(`${this.apiLocation + "/" + this.path}/print-bpn-gabung`, data)

      return res.data;
    },
    printReport: async (data) => {
      var res = await this.http.post(`${this.apiLocation + "/" + this.path}/print-report`, data)

      return res.data;
    },
    deleteSelected: async (data) => {
      var res = await this.http.post(`${this.apiLocation + "/" + this.path}/delete/checked`, data)

      return res.data;
    },
  }

  constructor() {
    super()
    this.init()
    this.initApi()
  }
}


export default new PaymentNotificationService();
