import React, { Component } from 'react';
import { connect } from 'react-redux';

import FormDialog from './Master23Kap.dialog.view';
import ListView from '../../../../../../components/entity/listView';
import ColumnService from '../../../../../../services/column.service';
import Master23KapService from './Master23Kap.service';

@connect(Master23KapService.stateConnectSetting(), Master23KapService.actionConnectSetting())
export default class Master23KapView extends ListView {
  service=Master23KapService
  FormDialog=FormDialog

  columns=[
    {isSortable: true, label: "word.id",   searchField: "id",   value: function(d){ return d.id }, type: "func",  show:false, isSearchable:true},
    {isSortable: true, label: "word.code", searchField: "code", value: function(d){ return d.code }, type: "func",  show:true, isSearchable:true},
    {isSortable: true, label: "word.name", searchField: "name", value: function(d){ return d.name }, type: "func",  show:true, isSearchable:true},
  ]
}
