import React from 'react';
import {connect} from 'react-redux';
import {Field, getFormValues, reduxForm} from 'redux-form';
import {DataTable, TableBody, TableColumn, TableHeader, TableRow} from 'react-md';
import FormView from '../../../../../../components/entity/form.view';
import {Datepicker, Textfield, TextfieldMask, validation} from '../../../../../../components/form';
import Tarif15Service from './Tarif15.service';


@reduxForm({form: 'Tarif15Form', destroyOnUnmount: true})
@connect(state => ({
  company: state.auth.currentCompany,
  formData: getFormValues('Tarif15Form')(state)
}))
export default class Tarif15Form extends FormView {
  service = Tarif15Service
  viewType = 2

  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      activeTab: 0
    }
  }

  async initData() {
    if (this.props.match.params.id == 'new') {
      //let details = await Tarif15Service.api.getMaster();

      // let detailObjects = details.reduce((out, data) => {
      //   out[data.id] = data

      //   return out
      // }, {});

      let initialData = {
        company: this.props.company,
        tarifPbDn: 1.8,
        tarifPlDn: 1.2,
        tarifPlPbLn: 2.64
      }

      this.props.initialize(initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id);

      this.props.initialize(res.data);
    }
  }

  async handleSave(value) {
    try {
      this.setState({onProgress: true})

      if (value.id) {
        if(!value.company) value.company = this.props.company;

        await this.service.api.update(value)
      } else {
        await this.service.api.save(value)
      }

      this.setState({onProgress: false})

      let pathArray = this.props.location.pathname.split('/');

      let path = '';

      pathArray.forEach((d, i) => {
        if (pathArray.length - 1 != i) {
          path = path + d;

          if (i != pathArray.length - 2) path = path + '/'
        }
      })

      this.props.history.push(path)
    } catch (e) {
      let msg = e.message;

      if (e.response) msg = e.response.data.message;

      this.setState({
        isError: true,
        onProgress: false,
        errorMessage: msg
      })
    }
  }

  formView() {
    return (
      <div className="mpk-layout column fill mpk-tab-clear">
        <div className="flex">
          {this.pasal15Form()}
        </div>
      </div>
    )
  }

  pasal15Form() {
    return <div style={{marginBottom: 20}}>
      <div className='md-card md-paper md-paper md-paper--1 md-card md-background--card'>

        <div className="md-grid">
          <Field
            className="md-cell md-cell--6"
            label='Start Date'
            name='start'
            inline={true}
            component={Datepicker}
            validate={validation.required}
          />

          <Field
            className='md-cell md-cell--6'
            label='End Date'
            name='end'
            inline={true}
            component={Datepicker}
            validate={validation.required}
          />

          <Field
            className='md-cell md-cell--4'
            label='Penerbangan Dalam Negeri'
            name='tarifPbDn'
            component={TextfieldMask}
            money     = {','}
            // validate={validation.required}
          />

          <Field
            className='md-cell md-cell--4'
            label='Pelayaran Dalam Negeri'
            name='tarifPlDn'
            component={TextfieldMask}
            money     = {','}
            // validate={validation.required}
          />

          <Field
            className='md-cell md-cell--4'
            label='Pelayaran & Penerbangan Luar Negeri'
            name='tarifPlPbLn'
            component={TextfieldMask}
            money     = {','}
            // validate={validation.required}
          />
        </div>
      </div>
    </div>
  }
}
