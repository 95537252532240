import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Field, reduxForm } from 'redux-form';
import download from 'downloadjs';
import { Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab,
  Chip,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn} from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Switch as Switch2} from '../../../components/form';

import ListView from '../../../components/entity/listView';
import axios from 'axios';
import izitoast from 'izitoast';
import LogExportService from './LogExport.service';


@reduxForm({form: 'LogExport_Form', destroyOnUnmount: true})
@connect((state)=> ({
  ...LogExportService.stateConnectSetting()(state),
  user: state.auth.user,
  company: state.auth.currentCompany,
}), LogExportService.actionConnectSetting())
export default class LogExportView extends ListView {
  service=LogExportService
  translate=false

  titleHeader() {
    return `Log Export ${this.props.match.params.pasal}`;
  }

  FormDialog=()=> <div/>
  // viewType=2

  constructor(props){
    super(props);
    this.state = {
      showTableSetting:true,
      showDialogConfirmDeleteSelected:false,
      showForm:false,
      formData: {},
      data: [],
      onProgress: false,
      isError: false,
      errorMessage: ''
    }
  }

  beforeFetch(params) {
    // params["category.equals"] = "ORGANIZATION"
    params['type.equals'] = this.props.match.params.pasal;
  }

  fetchOption() {
    let companyId;
    if(this.props.company) companyId = this.props.company.id;
    if(this.props.match.params.companyId && this.props.match.params.companyId != '') companyId = this.props.match.params.companyId;
    // if(companyId) {
    //   return {
    //     path: this.service.path+'/'+companyId+'/company'
    //   }

    // } else {
    //   return {
    //     path: this.service.path+'/user'
    //   }
    // }

    return {
      path: 'log/'+this.props.match.params.pasal.toLowerCase()
    }

  }

  tableActions = (data) => {
    // let actions = [
    //   {
    //     label:"More Info",
    //     iconClassName:"mdi mdi-bell",
    //     onClick: (item) => this.showDetail(item)
    //   }
    // ]

    // let divider = {
    //   label:"divider",
    //     iconClassName:"-"
    // }

    // if(data.status == 'FINISH' || data.status == 'FAILED') {
    //   let deleteAction = {
    //     label:"Download",
    //     iconClassName:"mdi mdi-download",
    //     onClick:(item, callback) => this.download(item, callback),
    //     confirmation:{
    //       title:"sentence.custom.downloadItem",
    //       message:"sentence.custom.downloadItemConfirmation"
    //     }
    //   }

    //   actions.push(divider)
    //   actions.push(deleteAction)
    // }

    return [{}]
  }

  _barActions = [
    // {
    //   label:'Delete',
    //   iconClassName:'mdi mdi-delete',
    //   onClick:() => {
    //     this.setState({showDialogConfirmDeleteFinishFailedSelected:true})
    //   },
    //   disabledFunc:() => {
    //     let selected = this.props.table.selected
    //     let deleteable = []

    //     selected.forEach(data => {
    //       if(data.status == 'FINISH' || data.status == 'FAILED') {
    //         deleteable.push(data.id)
    //       }
    //     })

    //     return deleteable.length === 0
    //   }
    // },
    {
      label:'Refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => this.fetchData()
    },
    {
      label:'Download',
      iconClassName:'mdi mdi-download',
      disabledFunc:() => {
        let selected = this.props.table.selected

        return selected.length === 0
      },
      onClick:() => this.downloadBatch()
    },
    {
      label:'Download Foldering',
      iconClassName:'mdi mdi-folder',
      disabledFunc:() => {
        let selected = this.props.table.selected

        return selected.length === 0
      },
      onClick:() => this.downloadFoldering()
    },
    {
      label:'Delete',
      iconClassName:'mdi mdi-delete',
      disabledFunc:() => {
        let selected = this.props.table.selected

        return selected.length === 0
      },
      onClick:() => this.deleteSelected()
    },
  ]

  download = async (item, callback) => {
    try {
      var fileRes = await this.service.api.downloadPdf(item.id);
      var filename = fileRes.headers.filename
      download(fileRes.data, filename);

      callback()
      await this.fetchData()
    } catch(e) {
      callback(e, e)
    }
  }

  downloadBatch = async () => {
    try {
      let ids = []

      this.props.table.selected.map((d, i)=> {
        if (d.status === 'FINISH') {
          ids.push(d.id)
        }
      })

      if (ids.length === 0) {
        izitoast.warning({title: 'Export', message: 'Hanya log dengan status FINISH yang dapat di-download.'})

        return
      }

      this.service.api.downloadStream(ids, this.props.match.params.pasal.toLowerCase())
    } catch(e) {
      console.error(e)
    }
  }

  downloadFoldering = async () => {
    try {
      let ids = []

      this.props.table.selected.map((d, i)=> {
        if (d.status === 'FINISH') {
          ids.push(d.id)
        }
      })

      if (ids.length === 0) {
        izitoast.warning({title: 'Export', message: 'Hanya log dengan status FINISH yang dapat di-download.'})

        return
      }

      this.service.api.downloadFolderingStream(ids, this.props.match.params.pasal.toLowerCase())
    } catch(e) {
      console.error(e)
    }
  }

  convertFileSize(bytes) {
    let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

    if (bytes == 0) return '0 Byte';

    let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));

    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
  }

  deleteSelected = async () => {
    try {
      let selected = this.props.table.selected

      let ids = []

      selected.forEach(item => {
        if (item.status === 'FINISH' || item.status === 'FAILED') {
          ids.push(item.id)
        }
      })

      if (ids.length === 0) {
        izitoast.warning({title: 'Status', message: 'Hanya log dengan status FINISH/FAILED yang dapat dihapus.'})

        return
      }

      await this.service.api.deleteSelected(ids, this.props.match.params.pasal.toLowerCase())

      await this.fetchData()
    } catch(e) {
      console.error(e)
    }
  }

  async showDetail(d) {
    var d = await this.context.showDialog((props, res, rej) =>({
      title: 'Details',
      initialValue: {},
      width: 600,
      okText: 'Back',
      text: (
        <div>
        <DataTable plain>
          <TableHeader>
            <TableRow>
              <TableColumn>Name</TableColumn>
              <TableColumn>Count</TableColumn>
              <TableColumn>Total</TableColumn>
              <TableColumn>Message</TableColumn>
            </TableRow>
          </TableHeader>
          <TableBody>
            {d.details.map((c, i) => (
              <TableRow key={i}>
                <TableColumn>{c.name}</TableColumn>
                <TableColumn>{c.count}</TableColumn>
                <TableColumn>{c.total}</TableColumn>
                <TableColumn>{c.message}</TableColumn>
              </TableRow>
            ))}
          </TableBody>
        </DataTable>
        </div>
      )
    }));
  }

  columns=[
    {
      searchField: "status",
      label: "word.status",
      type: 'func',
      value: (d)=> {
        var style = {
          background: 'grey'
        }
        switch (d.status) {
          case 'PROCESS':
            style.background = '#2196F3'
            break;
          case 'FINISH':
            style.background = '#4CAF50'
            break;
          case 'FAILED':
            style.background = '#f44336'
            break;
          default:
            break;
        }
        return <Chip label={d.status} style={style} />
      },
      className: "mpk-font-size-S", show:true, isSearchable:true},
    {isSortable: true, label: "word.name",  value: "nama", isDefaultSort:false, show:true, isSearchable:true},
    {isSortable: true, label: "word.aliasName",  value: "namaAlias", isDefaultSort:false, show:true, isSearchable:true},
    {isSortable: true, label: "word.npwp",  value: "npwp", isDefaultSort:false, show:true, isSearchable:true},
    {isSortable: true, label: "word.pasal",  value: "pasal", isDefaultSort:false, show:true, isSearchable:false},
    {isSortable: true, label: "word.masa",  value: "masa", isDefaultSort:false, show:true, isSearchable:true,type: 'number'},
    {isSortable: true, label: "word.tahun", searchField: "tahun", value: (d)=> d.tahun, isDefaultSort:false, show:true, isSearchable:true, type: 'func'},
    {isSortable: true, label: "word.pembetulan",  value: "pembetulan", isDefaultSort:false, show:true, isSearchable:true,type: 'number'},
    {isSortable: true, label: "word.message",  value: "message", isDefaultSort:false, show:true, isSearchable:false},
    {isSortable: true, label: "word.fileSize",  value: (d) => this.convertFileSize(d.fileSize), isDefaultSort:false, show:true, isSearchable:false, type: 'func'},
  ]

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='Nama'
          name='nama__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Nama Alias'
          name='namaAlias__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='NPWP'
          name='npwp__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Pasal'
          name='pasal__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Dari Bulan'
          name='masa__greaterOrEqualThan'
          className="md-cell md-cell--6"
          component={this.ig.Searchfield}
          options={[
            {id: 1, name: "1"},
            {id: 2, name: "2"},
            {id: 3, name: "3"},
            {id: 4, name: "4"},
            {id: 5, name: "5"},
            {id: 6, name: "6"},
            {id: 7, name: "7"},
            {id: 8, name: "8"},
            {id: 9, name: "9"},
            {id: 10, name: "10"},
            {id: 11, name: "11"},
            {id: 12, name: "12"}
          ]}
        />
        <this.ig.Field
          label='Sampai Bulan'
          name='masa__lessOrEqualThan'
          className="md-cell md-cell--6"
          component={this.ig.Searchfield}
          options={[
            {id: 1, name: "1"},
            {id: 2, name: "2"},
            {id: 3, name: "3"},
            {id: 4, name: "4"},
            {id: 5, name: "5"},
            {id: 6, name: "6"},
            {id: 7, name: "7"},
            {id: 8, name: "8"},
            {id: 9, name: "9"},
            {id: 10, name: "10"},
            {id: 11, name: "11"},
            {id: 12, name: "12"}
          ]}
        />

        <this.ig.Field
          label='Bulan'
          name='masa__equals'
          className="md-cell md-cell--12"
          component={this.ig.Searchfield}
          options={[
            {id: 1, name: "1"},
            {id: 2, name: "2"},
            {id: 3, name: "3"},
            {id: 4, name: "4"},
            {id: 5, name: "5"},
            {id: 6, name: "6"},
            {id: 7, name: "7"},
            {id: 8, name: "8"},
            {id: 9, name: "9"},
            {id: 10, name: "10"},
            {id: 11, name: "11"},
            {id: 12, name: "12"}
          ]}
        />

        <this.ig.Field
          label='Tahun'
          name='tahun__equals'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />

        <this.ig.Field
          label='WAITING'
          name='status__in_WAITING'
          className="md-cell md-cell--6"
          component={this.ig.Checkbox}
        />
        <this.ig.Field
          label='PROCESS'
          name='status__in_PROCESS'
          className="md-cell md-cell--6"
          component={this.ig.Checkbox}
        />
        <this.ig.Field
          label='FINISH'
          name='status__in_FINISH'
          className="md-cell md-cell--6"
          component={this.ig.Checkbox}
        />
        <this.ig.Field
          label='FAILED'
          name='status__in_FAILED'
          className="md-cell md-cell--6"
          component={this.ig.Checkbox}
        />
      </this.SearchContainer>
    )
  }

}
