import ApiService from '../../../../services/api.service';

class OrganizationService extends ApiService {
  name= 'organization';
  path= 'organization';

  constructor() {
    super();
    this.init()
  }

  api= {
    cetakDaftar: async (id) => {
      var res = await this.http.get(`${this.apiLocation}/report/${id}`, {
        responseType: 'blob'
      })

      return res;
    },
    cetakBulk: async (ids) => {
      var res = await this.http.post(`${this.apiLocation}/spt21/report/bulk`, ids, {
        responseType: 'blob'
      })

      return res;
    },
    updateKey: async (data) => {
      var res = await this.http.post(`${this.apiLocation}/key/update`, data)

      return res;
    }
  }
}


export default new OrganizationService();
