import ApiService from '../../../../../services/api.service';

class SPT22SignerService extends ApiService {
  name= 'SPT22Signer';
  path= 'spt22/signer';
  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api= {
    getSigner: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/spt22/${sptId}`);
      return res.data;
    },
    deleteBulk: async (ids) => {
      let res = await this.http.post(`${this.apiLocation}/${this.path}/delete`, ids);
      return res;
    },
    deleteByParam: async (companyId, data) => {
      let res = await this.http.get(`${this.apiLocation}/${this.path}/${companyId}/delete/param`, {
        params: data,
      });
      return res;
    },
  }
}

export default new SPT22SignerService();
