import React, { Component } from 'react';
import {connect} from 'react-redux';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  CardActions,
  Button,
  LinearProgress
} from 'react-md';
import { Field, reduxForm, getFormValues} from 'redux-form';

import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Checkbox} from '../../../../../../components/form';
import terbilangService from '../../../../../../services/terbilang.service';

@connect((state) => ({
  formData: getFormValues('BP_4a2_Form')(state)
}))
export default class BP4a2BungaDiskontoForm extends Component {
  componentDidMount() {
    this.props.connectCmp(this.reCalculate.bind(this))
  }

  async reCalculate() {
    await this.calculateTotal()
  }

  async calculateBunga(e,v,pv,f, parent) {
    var form = this.props.formData;
    var d    = form.bungaDiskonto;

    var jumlahNilaiNominal = parseFloat(d.jumlahNilaiNominal);
    var tingkatBunga       = parseFloat(d.tingkatBunga);
    var holdingPeriod      = parseFloat(d.holdingPeriod);
    var timePeriod         = parseFloat(d.timePeriod);

    if(f === 'bungaDiskonto.jumlahNilaiNominal') {
      jumlahNilaiNominal = parseFloat(v);
    } else if(f === 'bungaDiskonto.tingkatBunga') {
      tingkatBunga = parseFloat(v);
    } else if(f === 'bungaDiskonto.holdingPeriod') {
      holdingPeriod = parseFloat(v);
    } else if(f === 'bungaDiskonto.timePeriod') {
      timePeriod = parseFloat(v);
    }

    if(timePeriod > 0) {
      await this.props.change('bungaDiskonto.bunga',
        jumlahNilaiNominal*(tingkatBunga/100)*holdingPeriod/timePeriod
      )
    } else {
      await this.props.change('bungaDiskonto.bunga', 0);
    }

    await this.calculateTotal();
  }

  async calculateDiskonto(e,v,pv,f, parent) {
    var form = this.props.formData;
    var d    = form.bungaDiskonto;

    var jumlahHargaPerolehanBersih = parseFloat(d.jumlahHargaPerolehanBersih);
    var jumlahHargaJualBersih      = parseFloat(d.jumlahHargaJualBersih);

    if(f === 'bungaDiskonto.jumlahHargaPerolehanBersih') {
      jumlahHargaPerolehanBersih = parseFloat(v);
    } else if(f === 'bungaDiskonto.jumlahHargaJualBersih') {
      jumlahHargaJualBersih = parseFloat(v);
    }

    await this.props.change('bungaDiskonto.diskonto', jumlahHargaJualBersih-jumlahHargaPerolehanBersih);

    await this.calculateTotal();
  }

  async calculateTotal(e,v,pv,f, parent) {
    var form = this.props.formData;
    var d    = form.bungaDiskonto;

    var bunga       = parseFloat(d.bunga);
    var diskonto    = parseFloat(d.diskonto);
    var tarif       = parseFloat(d.tarif);
    var jumlahBunga = bunga+diskonto;

    if(f === 'bungaDiskonto.tarif') {
      tarif = parseFloat(v);
    }

    await this.props.change('bungaDiskonto.jumlahBunga', jumlahBunga);
    var jmlPph = jumlahBunga*tarif/100;

    jumlahBunga = Math.floor(jumlahBunga)
    jmlPph = Math.floor(jmlPph)

    this.props.change('bruto', jumlahBunga)
    this.props.change('pph', jmlPph);

    this.props.change('terbilang', terbilangService(parseInt(jmlPph)));

  }

  render() {
    return <DataTable plain fixedScrollWrapperStyle={{overflow: 'inherit'}} style={{overflow: 'inherit'}}>
      <TableHeader>
        <TableRow>
          <TableColumn>No.</TableColumn>
          <TableColumn>Uraian</TableColumn>
          <TableColumn></TableColumn>
          <TableColumn>Bunga/Diskonto</TableColumn>
        </TableRow>
      </TableHeader>
      <TableBody>
        <TableRow>
          <TableColumn>A.</TableColumn>
          <TableColumn>Nama Obligasi</TableColumn>
          <TableColumn>
            <Field
              name      = 'bungaDiskonto.namaObligasi'
              component = {Textfield}
            />
          </TableColumn>
          <TableColumn style={{background: '#ddd'}}></TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn>B.</TableColumn>
          <TableColumn>Jumlah Nilai Nominal</TableColumn>
          <TableColumn>
            <Field
              name      = 'bungaDiskonto.jumlahNilaiNominal'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => {this.calculateBunga(e, v, vp, f)}}
              money     = {','}
            />
          </TableColumn>
          <TableColumn style={{background: '#ddd'}}></TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn>C.</TableColumn>
          <TableColumn>Nomor Seri</TableColumn>
          <TableColumn>
            <Field
              name      = 'bungaDiskonto.nomorSeri'
              component = {Textfield}
            />
          </TableColumn>
          <TableColumn style={{background: '#ddd'}}></TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn>D.</TableColumn>
          <TableColumn>Tingkat Bunga/Tahun (%)</TableColumn>
          <TableColumn>
            <Field
              name      = 'bungaDiskonto.tingkatBunga'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => {this.calculateBunga(e, v, vp, f)}}
              money     = {','}
            />
          </TableColumn>
          <TableColumn style={{background: '#ddd'}}></TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn>E.</TableColumn>
          <TableColumn>Tanggal Jatuh Tempo Bunga Terakhir</TableColumn>
          <TableColumn>
            <Field
              name      = 'bungaDiskonto.tanggalJatuhTempo'
              component = {Datepicker}
            />
          </TableColumn>
          <TableColumn style={{background: '#ddd'}}></TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn>F.</TableColumn>
          <TableColumn>Tanggal Perolehan</TableColumn>
          <TableColumn>
            <Field
              name      = 'bungaDiskonto.tanggalPerolehan'
              component = {Datepicker}
            />
          </TableColumn>
          <TableColumn style={{background: '#ddd'}}></TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn>G.</TableColumn>
          <TableColumn>Tanggal Penjualan</TableColumn>
          <TableColumn>
            <Field
              name      = 'bungaDiskonto.tanggalPenjualan'
              component = {Datepicker}
            />
          </TableColumn>
          <TableColumn style={{background: '#ddd'}}></TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn>H.</TableColumn>
          <TableColumn>Jumlah harga perolehan bersih (tanpa bunga)</TableColumn>
          <TableColumn>
            <Field
              name      = 'bungaDiskonto.jumlahHargaPerolehanBersih'
              component = {TextfieldMask}
              className = 'md-text-right'
              money     = {','}
              onChange  = {(e, v, vp, f) => {this.calculateDiskonto(e, v, vp, f)}}
            />
          </TableColumn>
          <TableColumn style={{background: '#ddd'}}></TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn>I.</TableColumn>
          <TableColumn>Jumlah harga jual bersih (tanpa bunga)</TableColumn>
          <TableColumn>
            <Field
              name      = 'bungaDiskonto.jumlahHargaJualBersih'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => {this.calculateDiskonto(e, v, vp, f)}}
              money     = {','}
            />
          </TableColumn>
          <TableColumn style={{background: '#ddd'}}></TableColumn>
        </TableRow>

        <TableRow>
          <TableColumn>J.</TableColumn>
          <TableColumn>Diskonto (I-H)</TableColumn>
          <TableColumn>
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'bungaDiskonto.diskonto'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => {this.calculateJumlahBunga(e, v, vp, f)}}
              disabled
              money = {','}
            />
          </TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn>K.</TableColumn>
          <TableColumn>Bunga</TableColumn>
          <TableColumn>
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'bungaDiskonto.bunga'
              component = {TextfieldMask}
              className = 'md-text-right'
              disabled
              money = {','}
            />
          </TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn></TableColumn>
          <TableColumn></TableColumn>
          <TableColumn>
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'bungaDiskonto.jumlahBunga'
              component = {TextfieldMask}
              className = 'md-text-right'
              disabled
              money = {','}
            />
          </TableColumn>
        </TableRow>
        <TableRow style={{borderTop: '3px solid black'}}>
          <TableColumn></TableColumn>
          <TableColumn>Tarif</TableColumn>
          <TableColumn>
            <Field
              name      = 'bungaDiskonto.tarif'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => {this.calculateTotal(e, v, vp, f)}}
              money     = {','}
            />
          </TableColumn>
          <TableColumn style={{background: '#ddd'}}></TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn></TableColumn>
          <TableColumn>Holding Periode	</TableColumn>
          <TableColumn>
            <Field
              name      = 'bungaDiskonto.holdingPeriod'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => {this.calculateBunga(e, v, vp, f)}}
              money     = {','}
            />
          </TableColumn>
          <TableColumn style={{background: '#ddd'}}></TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn></TableColumn>
          <TableColumn>Time Periode</TableColumn>
          <TableColumn>
            <Field
              name      = 'bungaDiskonto.timePeriod'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => {this.calculateBunga(e, v, vp, f)}}
              money     = {','}
            />
          </TableColumn>
          <TableColumn style={{background: '#ddd'}}></TableColumn>
        </TableRow>
        <TableRow style={{borderTop: '3px solid black'}}>
          <TableColumn></TableColumn>
          <TableColumn>PPh Final</TableColumn>
          <TableColumn>
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'pph'
              component = {TextfieldMask}
              className = 'md-text-right'
              money     = {','}
            />
          </TableColumn>
        </TableRow>
      </TableBody>

    </DataTable>
  }
}
