import React, { Component } from 'react';
import {
  Grid,
  Cell,
  Avatar,
  Divider,
  FontIcon,
  List,
  ListItem,
  Card
} from 'react-md';
import { connect } from 'react-redux';
import { Field, reduxForm , propTypes, formValueSelector, getFormValues } from 'redux-form';
import FormView from '../../../../components/entity/form.view';
import CompanyService from '../../Administrator/Company/Company.service';


@reduxForm({form: 'about_company', destroyOnUnmount: false, initialValues: {}})
@connect((state) => ({
  formData: getFormValues('about_company')(state),
  user: state.auth.user
}))
export default class AccessForm extends FormView {
  service=CompanyService
  viewType=2

  barItem() {}

  async initData() {
    var companyId = this.props.match.params.companyId;
    if(this.props.user.company && this.props.user.company.id) companyId = this.props.user.company.id;

    let res = await this.service.api.findOne(companyId);
    this.props.initialize(res.data);
  }

  formView() {
    return (
      <Card >
        <List>
          <ListItem
            primaryText="Nama"
            secondaryText={this.props.formData.nama}
          />
          <ListItem
            primaryText="Email"
            secondaryText={this.props.formData.email}
          />
          <ListItem
            primaryText="Telepon"
            secondaryText={this.props.formData.telp}
          />
          <ListItem
            primaryText="alamat"
            secondaryText={this.props.formData.alamat}
          />
        </List>
        <Divider/>
        <div className="md-fake-btn md-fake-btn--no-outline md-list-tile md-list-tile--two-lines md-text">
          <div className="md-tile-content">
            <div className="md-tile-text--primary md-text">Serial Number</div>
            <div className="md-tile-text--secondary md-text--secondary">
              <b>{this.props.formData.sn}</b>
            </div>
          </div>
        </div>
      </Card>
    )
  }
}
