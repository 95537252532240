import React, { Component } from 'react';
import { Field, reduxForm , propTypes, formValueSelector } from 'redux-form';
import { validation, Textfield, TextfieldMask, Datepicker, convert } from '../../../../../../components/form';
import Service from './ImportSSP23.service';
import ImportDialogTemplateView from '../../../../../../components/entity/Import.dialog.template.view';
ImportDialogTemplateView

@reduxForm({form: 'ImportSSP23-form', destroyOnUnmount: false})
export default class ImportSSP23DialogView extends ImportDialogTemplateView {
  service=Service
}
