import ApiService from '../../../../services/api.service';

class LogAppService extends ApiService {
  name= 'LogApp';
  path= 'log/app';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api={
    download: async (filename) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${filename}`)
      return res;
    },
  }
}

export default new LogAppService();
