import ApiService from '../../../../../../services/api.service';
import http from "../../../../../../services/http.service";

class BP15PPHPasal24Service extends ApiService {
  name= 'BP15PPHPasal24';
  path= 'spt15/bp-24';

  api= {
    calculate: async (param) => {
      var res = await this.http.post(this.apiLocation+'/calculation/pph15/f', param)

      return res.data;
    },
    total: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/total`)

      return res.data;

      return {};
    },
    cetak: async (id) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/report/${id}`, {
        responseType: 'blob'
      })

      return res;
    },
    cetakDaftar: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/report-daftar`, {
        responseType: 'blob'
      })

      return res;
    },
    downloadCsv: async (sptId)=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/csv/${sptId}`, {
        responseType: 'blob'
      })

      return res;
    },
    email: async (category, ids) => {
      return await this.http.post(`${this.apiLocation}/email?category=${category}`, ids)
    },
    deleteBulk: async (sptId, ids)=> {
      let res = await http.post(`${this.apiLocation}/${this.path}/${sptId}/delete`, ids);

      return res;
    },
  }

  constructor() {
    super()
    this.init()
    this.initApi()
  }
}


export default new BP15PPHPasal24Service();
