import ApiService from '../../../../services/api.service';

class BillingService extends ApiService {
  name= 'Billing';
  path= 'ebilling/billing';

  api = {
    generate: async (data) => {
      var res = await this.http.post(`${this.apiLocation + "/" + this.path}/generate`, data)

      return res.data;
    },
    save: async (data) => {
      var res = await this.http.post(`${this.apiLocation + "/" + this.path}/save`, data)

      return res.data;
    },
  }

  constructor() {
    super()
    this.init()
    this.initApi()
  }
}


export default new BillingService();
