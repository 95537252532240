import React, { Component } from 'react'; import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {TabsContainer, Tabs, Tab} from 'react-md';
import { Field } from 'redux-form';
import moment from 'moment'

import VillagesService from './Villages.service';
import ListView from '../../../../../components/entity/ListViewEbupot';
import AuthService from '../../../../../services/auth.service'
import ListViewEbupot from '../../../../../components/entity/ListViewEbupot';

@connect((state)=> ({
  ...VillagesService.stateConnectSetting()(state)
}), VillagesService.actionConnectSetting())
export default class VillagesView extends ListViewEbupot {
  service=VillagesService;
  editDialog = false;
  addDialog = false;

  beforeFetch(params) {
    delete params.startDate
    delete params.endDate
    delete params.column
    delete params.total
    delete params.keyword
    params.sortBy = "id"
    params.sort = "id,ASC"
    // params.sortBy = 'id'
    // params.sort = 1
}

  columns=VillagesService.columns

  _barActions = [
    {
      label:'word.create',
      iconClassName:'mdi mdi-plus',
      onClick:() => this.addItem()
    },
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => this.fetchData()
    },
  ]


}
