import React from 'react'
import { Commandbar } from 'react-mpk';
import { Button, Card, Grid, Cell, LinearProgress, FontIcon } from 'react-md';
import izitoast from 'izitoast'
import FormView from './form.view';
import counterpart from 'counterpart';

export default class FormViewSpt extends FormView {

    _barItem() {
        const { handleSubmit, submitting, valid, pristine } = this.props;

        var saveWord = counterpart.translate('word.save');
        if(this.props.match.params.id == 'new') saveWord = counterpart.translate('word.create')

        let statusSPT = "";
        let statusInSPT = false;
        if(this.props.spt){
            if(this.props.spt.data){
                if(this.props.spt.data.status){
                    statusSPT = this.props.spt.data.status;
                    statusInSPT = true
                }
            }
        }

        if(statusSPT == "FINISH" || statusSPT == "WAITING"){
            return (
                <div>
                    <Button secondary flat onClick={()=> {this.props.history.goBack()}} style={{marginRight: 16}}>{counterpart.translate('word.back')}</Button>
                </div>
            )
        } else {
            if(statusInSPT){
                return (
                    <div>
                        <Button secondary flat onClick={()=> {this.props.history.goBack()}} style={{marginRight: 16}}>{counterpart.translate('word.back')}</Button>

                        <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>{saveWord}</Button>
                    </div>
                )
            } else {
                return (
                    <div>
                        <Button secondary flat onClick={()=> {this.props.history.goBack()}} style={{marginRight: 16}}>Back</Button>
                    </div>
                )
            }
        }
    }

}
