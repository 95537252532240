/**
 * Created by dwiargo on 11/30/17.
 */

import React,{ Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Translate from 'react-translate-component';
import renderHTML from 'react-render-html';
import moment from 'moment';
import _ from 'lodash';
import numeral from 'numeral';
import counterpart from 'counterpart';
import Draggable from 'react-draggable';

import TableItemAction from 'react-mpk/components/Table/TableItemAction';
import {DialogConfirm, DialogAlert} from 'react-mpk/components/Dialog';
import NotFoundData from 'react-mpk/components/NotFoundData';

import * as tableActions from 'react-mpk/redux/actions/tableActions';
import * as toastActions from 'react-mpk/redux/actions/toastActions';

import {
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  TablePagination,
  LinearProgress,
  FontIcon,
} from 'react-md';

import 'react-mpk/components/Table/Table.scss';
import httpService from 'react-mpk/services/httpService';

let innerTableHeight = 0;
let innerTableWidth = 0;
const defaultError = () => ({
  isError:false,
  title:'',
  message:''
});

const resizeGap = 24;

class TableHeaderResizer extends React.Component{
  constructor(){
    super();
    this.state = {
      parent: null,
      defaultPosition:{},
      bounds:{}
    }
  }
  componentDidMount(){
    setTimeout(() => {
      let parent = document.getElementById(this.props.parentId);
      if(parent){
        this.setState({
          parent,
          defaultPosition:{x: parent.clientWidth - resizeGap, y: 0},
          bounds:{left:parent.clientWidth - resizeGap}
        })
      }
    })
  }

  render(){
    const { parent, defaultPosition, bounds } = this.state;
    return parent ? (
      <Draggable
        axis="x"
        defaultPosition={defaultPosition}
        bounds={bounds}
        onDrag={(e, ddata) => {
          this.props.onUpdate(e, ddata);
        }}
      >
        <div className="resizer mpk-layout align-center">
          <FontIcon iconClassName="mdi mdi-drag-vertical"/>
        </div>
      </Draggable>
    ) : null
  }
}

class Table extends Component {
  constructor(){
    super();
    this.state = {
      _actionHasAdded:false,
      innerTableHeight:72,
      innerTableWidth:72,
      dialogConfirm: {
        visible: false,
        title: 'confirmation',
        message: 'message',
        onSubmit: () => {
        }
      },
      columnStyles:{}
    }
  }

  componentWillMount() {
    // this.props.tableActions.resetAll();

    let {itemActions, columns} = this.props;
    if (itemActions.length > 0 && !this.state._actionsHasAdded) {
      columns.push({
        label: "word.actions", value: "_actions", type: "_actions", show:true, resizable: false
      });
      this.setState({_actionsHasAdded:true});
    }

    let defaultSortBy = _.find(this.props.columns, {isDefaultSort: true});
    let defaultSearchColumn = _.find(this.props.columns, {isDefaultSearchColumn: true});

    let params = {
      sortBy:defaultSortBy ? ( defaultSortBy.searchField || defaultSortBy.valueName || defaultSortBy.value ) : 'createdAt',
      column:defaultSearchColumn ? ( defaultSortBy.searchField || defaultSearchColumn.valueName || defaultSearchColumn.value ) : ''
    }

    this.props.tableActions.setProperties({
      data:[],
      columns: this.props.columns,
      itemActions: this.props.itemActions,
      isLoading: true,
      error:defaultError(),
      params: params,
      reload:this.fetchData
    }, true);
  }

  componentDidMount(){
    setTimeout(() => {
      let _innerTabl = document.getElementById('mpk-inner-tbl');
      innerTableHeight = _innerTabl.clientHeight;
      innerTableWidth = _innerTabl.clientWidth;
      this.setState({
        innerTableHeight:innerTableHeight - 56,
        innerTableWidth:innerTableWidth
      }, this.fetchData)
    }, 500)

    // setTimeout(() => {
    //   this.enableResize();
    // }, 5000)
    // setTimeout(this.fetchData, 50);
    // this.fetchData();
  }

  onFetchData = () => {
    this.props.tableActions.setProperties({
      isLoading: true,
      data:[],
      error:defaultError()
    });

    this.fetchData();
  };

  fetchData = () => {
    let _data = [];
    let { params } = this.props.table;
    let tReceive = 0;
    let { page, size } = params;
    // let tPerPage;
    // let { params } = this.props.table;
    // let size = params.size;
    this.props.fetchData(params, (data=[], total) => {
      total = Number(total);
      // let tP = Math.round(total/size);
      // tPerPage = params.page <= tP ? size : (total - (Math.floor(total/size)*size));
      //let tPage = total < size ? 1 : Math.round(total/size);
      //tReceive = page === tPage ? (total - ((tPage-1)*size)) : size;
      tReceive = httpService.utils.totalItemWillReceive(page, size, total);
      this.props.tableActions.setProperties({
        isLoading: false,
        data: data,
        params:{
          total:total
        }
      })
    }, err => {
      let response = typeof err === 'object' ? err.response : err;
      let message = response === 'object' ? (
        response.errorMessage[this.props.global.localeCode] ? response.errorMessage[this.props.global.localeCode] : response
      ) : response
      this.props.toastActions.izi(
        this.props.translate ? counterpart.translate('word.failed') : 'Failed',
        message,
        'warning'
      )
      this.props.tableActions.setProperties({
        isLoading: false,
      });
    }, item => {
      _data.push(item);
      if(_data.length === tReceive){
        this.props.tableActions.setProperties({
          data:_data,
          isLoading:false
        })
      }

      // this.props.tableActions.addDataItem(item, true);
    })
  }

  onPagination = (newStart, rowsPerPage, currentPage) => {
    let params = this.props.table.params;
    params.page = currentPage;
    params.size = rowsPerPage;

    this.props.tableActions.setProperties({
      params: params
    });

    this.onFetchData()
  }

  sort = (col) => {
    let params = this.props.table.params;
    if (params.sortBy === col.value || params.sortBy == col.searchField) {
      params.sort = params.sort === 'ASC' ? 'DESC' : 'ASC';
      if(col.searchField) params.sortBy = col.searchField;
    } else {
      params.sortBy = col.value;
      if(col.searchField) {
        params.sortBy = col.searchField;
      }
    }

    this.props.tableActions.setProperties({
      params: params
    });

    this.onFetchData();
  };

  onConfirmed = (callback) => {
    this.props.table.confirmation.action(this.props.table.selectedItem, (isError, errorMessage) => {
      if(isError){
        callback(isError, errorMessage);
      }else{
        callback();
      }
    })
  };

  onUnconfirmed = () => {
    this.props.tableActions.setProperties({
      confirmation:{visible:false},
      selectedItem:null
    })
  };

  colOnClick = (col, item, index) => {
    if(col.onClick){
      col.onClick(item, index, col);
    }
  };

  updateColumnStyle = (e, dd, i) => {
    let { columnStyles } = this.state;
    columnStyles[i] = columnStyles[i] ? _.clone(columnStyles[i]) : { minWidth: 92};
    const minWidth = dd.x + resizeGap;
    columnStyles[i].minWidth = minWidth;
    this.setState({columnStyles});
  }

  render() {
    const rowsPerPageLabel = this.props.mobile ? 'Rows' : 'Rows per page';
    let { translate, tableActions, isPaging, global, selectableRows, showIndex, baseId, fixedHeader } = this.props;
    let { columns, data, itemActions, params, isLoading, confirmation, error } = this.props.table;

    const isSorted = (col) => {
      if (params.sortBy === col.value || params.sortBy == col.searchField) {
        return params.sort === 'ASC' ? true : false;
      } else {
        return undefined
      }
    };

    const renderValue = (d, col, index) => {
      let _d = d;
      if(typeof col.value === 'string'){
        let keys = col.value.split('.');
        for(var i = 0 ; i < keys.length ; i++){
          if(_d) _d = _d[keys[i]]
        }
      } else {
        _d = d[col.value]
      }

      let val = col.isSelectValueByLocaleCode ? _d[global.localeCode] : _d;
      val = val === null || val === '' || val === undefined ? '-' : val;

      if (typeof val === 'string') {
        val = val.replace(/&/g, "&amp;")
          .replace(/</g, "&lt;")
          .replace(/>/g, "&gt;")
          .replace(/"/g, "&quot;")
          .replace(/'/g, "&#039;");
      }

      switch (col.type) {
        case '_actions':
          var itemActionData = itemActions;
          if(typeof itemActionData === 'function') {
            itemActionData = itemActions(d, index, col)
          }
          return (
            <TableItemAction
              item={d}
              itemActions={itemActionData}
              translate={translate}
              tableActions={this.props.tableActions}
            />
          );
        case 'boolean':
          let statusClassName = (d[col.value] ? 'mpk-status progress' : 'mpk-status warn outline') + ' ' + (col.onClick ? 'clickable' : '');
          return (
            <div
              className="status boolean"
              onClick={() => this.colOnClick(col, d, index)}
            >
              <div
                className={statusClassName}
              >
                { d[col.value] ? 'true' : 'false'}
              </div>
            </div>
          );
        case 'date':
          return (
            <div className="mpk-font-size-S" style={{whiteSpace:'nowrap'}}>{moment(val).format('lll')}</div>
          );
        case 'number':
          return (
            <div
              onClick={() => this.colOnClick(col, d, index)}
              style={{
                minWidth: 72
              }}
              className={`mpk-align-right ${col.onClick ? 'mpk-link' : ''} ${col.className}`}
            >
              {numeral(val).format('0,0')}
            </div>
          )
        case 'func':
          return col.value(d, index);
        case 'array':
          return val.toString()
        default:
          return (
            <div
              className={col.className+" "+(col.onClick ? "mpk-link" : "")}
              onClick={() => this.colOnClick(col, d, index)}
            >
              {renderHTML(val)}
            </div>
          )
      }
    };

    const renderHeaderRow = columns.map((col, i) => {
      let { columnStyles } = this.state;
      return col.show ? (
        <TableColumn
          style={columnStyles[i]}
          id={`${baseId}-header-${i}`}
          key={`${baseId}-header-${i}`} numeric={col.type==='number' ? true : false}
          sorted={isSorted(col)}
          className={col.isSortable ? "sortable" : ""}
        >
          {/* RESIZER */}
          {col.resizable === undefined || col.resizable === true ? (
            <TableHeaderResizer
              parentId={`${baseId}-header-${i}`}
              onUpdate={(e, dd) => {
                this.updateColumnStyle(e, dd, i)
              }}
            />
          ) : (null)}
          {/* END OF RESIZER */}

          <div
            onClick={() => col.isSortable ? this.sort(col) : null}
            style={{
              cursor: col.isSortable ? 'pointer' : 'default'
            }}
          >
            {translate ? (
              <Translate content={col.label}/>
            ) : (col.label)}
          </div>
        </TableColumn>
      ) : (null)
    });


    const renderDataRow = (d, i) => (
      <TableRow
        key={`${baseId}-row-${i}`}
      >
        {showIndex ? <TableColumn>{(i+1) + ((params.page - 1) * params.size)}</TableColumn> : null}
        {columns.map((col, ic) => {
            return col.show ? (
              <TableColumn key={ic} numeric={col.type === 'number' ? false : false} className="body-text">
                {renderValue(d, col, i)}
              </TableColumn>
            ) : (null)
          }
        )}
      </TableRow>
    );

    const notFoundData = () => {
      if(Number(params.total) === 0){
        return(
          <NotFoundData/>
        )
      } else {
        return (null)
      }
    }

    const tableInfo = () => (
      <div className="query-info">
        {
          isLoading ? (
            <div className="header-loader mpk-font-size-NS mpk-font-color-D3">
              <Translate content={isLoading ? "sentence.table.loadingData" : "sentence.table.dataNotFound"}/>
            </div>
          ) : (
            notFoundData()
          )
        }
      </div>
    )

    return (
      <div className="mpk-table mpk-layout-fill">
        { confirmation ? (
          <DialogConfirm
            title={confirmation.title || "confirmation"}
            visible={confirmation.visible || false}
            message={confirmation.message || "message"}
            translate={confirmation.translate === null || confirmation.translate === undefined ? this.props.translate :  confirmation.translate }
            onSubmit={this.onConfirmed}
            onCancel={this.onUnconfirmed}
            global={global}
          />
        ) : (null)}
        <DialogAlert
          visible={error.isError}
          title={error.title}
          message={error.message}
          global={global}
          onCancel={() => tableActions.setProperties({
            error:{
              isError:false,
              title:'',
              message:''
            }
          })}
        />

        <LinearProgress id="table-linear-progress" value={null} className={isLoading ? 'show' : 'hide'}/>
        { data.length === 0 ? (
          tableInfo()
        ) : (null)}

        <div className={"mpk-layout column fill"}>
          <div className="flex" id="mpk-inner-tbl">
            <div className="mpk-full height width">
              { data.length > 0 ? (
                <DataTable
                  id={baseId}
                  plain={this.props.plain}
                  baseId={baseId}
                  fixedHeader={fixedHeader}
                  fixedHeight={this.state.innerTableHeight}
                  selectableRows={selectableRows}
                  onRowToggle={(index, checked) => {
                    tableActions.selectDataItem(index, checked)
                  }}
                >
                  <TableHeader id={`${baseId}-header`}>
                    <TableRow>
                      {showIndex ? <TableColumn>#</TableColumn> : null }
                      {renderHeaderRow}
                    </TableRow>
                  </TableHeader>
                  <TableBody id={`${baseId}-body`}>
                    {data.map((d, i) => (
                      renderDataRow(d, i)
                    ))}
                  </TableBody>
                </DataTable>
              ) : (null)}
            </div>
          </div>
          { isPaging && data.length > 0 ? (
            <div className="flex-none mpk-border thin solid top dark">
              <div className="mpk-padding-N left right">
                <DataTable baseId={baseId}>
                  <TablePagination
                    rows={Number(params.total)}
                    page={params.page}
                    rowsPerPage={params.size}
                    rowsPerPageLabel={rowsPerPageLabel}
                    rowsPerPageItems={[10, 20, 30, 40, 50, 100, 1000]}
                    onPagination={this.onPagination}
                  />
                </DataTable>
              </div>
            </div>
          ):(null)}

          {this.props.footer}
        </div>
      </div>
    )
  }
}

Table.defaultProps = {
  itemActions:[],
  baseId:"mpk-table"
}

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
      className: PropTypes.string,
      type: PropTypes.string,
      onClick: PropTypes.func,
      isDefaultSort: PropTypes.bool,
      isSortable: PropTypes.bool
    })
  ).isRequired,
  itemActions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      iconClassName: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      confirmation:PropTypes.shape({
        title:PropTypes.string.isRequired,
        message:PropTypes.node,
        translate: PropTypes.bool
      })
    })
  ),
  isPaging:PropTypes.bool,
  connect: PropTypes.shape({
    properties: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired
  }),
  fetchData: PropTypes.func.isRequired,
  selectableRows:PropTypes.bool,
  showIndex:PropTypes.bool
};

Table.defaultProps = {
  showIndex:true,
  baseId:'mpk-table',
  fixedHeader: true
}

const mapStateToProps = (state, props) => {
  return {
    table: props.connect ? props.connect.properties : state.table,
    global: state.global,
    toast:state.toast
  }
};

const mapDispatchToProps = (dispatch, props) => ({
  tableActions: bindActionCreators((props.connect ? props.connect.actions : tableActions), dispatch),
  toastActions:bindActionCreators(toastActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Table);
