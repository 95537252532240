
import React, { Component } from 'react';
import { connect } from 'react-redux';
import routes from './routes';

import * as constant from '../../../config/constant';
import CompanyService from '../Administrator/Company/Company.service';
import AuthService from '../../../services/auth.service';

@connect(state => ({
  organization: state.auth.currentOrganization,
  user: state.auth.user,
  company: state.auth.currentCompany
}))
export default class CompanyMain extends Component{
  async componentDidMount() {
    let res = await CompanyService.api.findOne(this.props.match.params.companyId);
    AuthService.api.changeCompany(res.data, this.props.dispatch);

    var userAuth = AuthService.api.getAuth(this.props.user);
    if(userAuth === constant.ROLE_ADMIN) {
      AuthService.api.changeMenuType('company', this.props.dispatch)
    }
  }
  render() {
    if(this.props.company.id) {
      return(
        <div className="mpk-layout column fill">
          {routes(this.props)}
        </div>
      )
    } else {
      return <div/>
    }

  }
}
