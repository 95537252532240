import ApiService from '../../../../../../services/api.service';

class AllSsp22ImportBankDevisaService extends ApiService {
  name= 'AllSsp22ImportBankDevisa';
  path= 'spt22/ssp-oleh-bank-devisa/organization';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api= {
    email: async (category, ids) => {
      return await this.http.post(`${this.apiLocation}/email?category=${category}`, ids)
    }
  }

}

export default new AllSsp22ImportBankDevisaService();
