import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab } from 'react-md';

import OrganizationKppService from './OrganizationKpp.service';
import FormDialog from './OrganizationKpp.dialog';
import ListView from '../../../../components/entity/listView';
import iziToast from 'izitoast';
import download from "downloadjs";
import LogExportService from "../../Export/LogExport.service";
import ParamService from "../../../../services/param.service";

export class OrganizationKppView extends ListView{
  service=OrganizationKppService
  FormDialog=FormDialog

  fetchOption() {
    let companyId = this.props.match.params.companyId

    return {
      path: 'organization-kpp/company/'+companyId+'/page'
    }
  }

  async handleExportOrganizationKpp() {
    let selected = this.props.table.selected

    if (selected.length > 0) {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export CSV Organization Kpp',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: 'Apakah anda yakin akan meng-export CSV data yang diceklis?'
      }));

      if(dialog) {
        var ids = [];
        selected.map(function (i) {
          ids.push(i.id)
        })

        var res = await LogExportService.api.exportBulk(this.service.path, ids);
        var filename = res.headers.filename
        download(res.data, filename);
      }
    }
    else {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export CSV Organization Kpp',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: (
          <div>
            <this.ig.Field
              label='NPWP Organisasi'
              name='organizationNpwp__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Nama Organisasi'
              name='organizationName__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Nama Alias Organisasi'
              name='organizationAliasName__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Kode KPP'
              name='kppCode__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Nama KPP'
              name='kppName__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
          </div>
        )
      }));

      if (dialog === false)
        return

      if (dialog === undefined)
        dialog = {}

      if (dialog) {
        let param = ParamService.convert(dialog)

        let res = await LogExportService.api.exportByParamCsv(this.service.path, param);

        let filename = res.headers.filename;

        download(res.data, filename);
      }
    }
  }

  barActions() {
    return [
      ...this._barActions,
      {
        label:'word.exportCsv',
        iconClassName:'mdi mdi-file-excel',
        // disabledFunc:() => this.props.table.selected.length === 0,
        onClick:() => this.handleExportOrganizationKpp()
      },
    ]
  }

  columns=[
    {isSortable:true, label: "word.npwp",  value: "organization.npwp", show:true, isSearchable:true, searchAction: 'contains'},
    {isSortable:true, label: "word.name",  value: "organization.name", show:true, isSearchable:true, searchAction: 'contains'},
    {isSortable:true, label: "word.aliasName",  value: "organization.aliasName", show:true, isSearchable:true, searchAction: 'contains'},
    {isSortable:true, label: "word.kppCode",  value: "kpp.code", show:true, isSearchable:true, searchAction: 'contains'},
    {isSortable:true, label: "word.kppName",  value: "kpp.name", show:true, isSearchable:true, searchAction: 'contains'},
    {label: "entities.SPT21Signer.start",  value: "start", isSortable: true, show:true, isSearchable:true},
    {label: "entities.SPT21Signer.end",  value: "end", isSortable: true, show:true, isSearchable:true},
  ]

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='NPWP Organisasi'
          name='organizationNpwp__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Nama Organisasi'
          name='organizationName__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Nama Alias Organisasi'
          name='organizationAliasName__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Kode KPP'
          name='kppCode__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Nama KPP'
          name='kppName__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
      </this.SearchContainer>
    )
  }
}

export default connect(OrganizationKppService.stateConnectSetting(), OrganizationKppService.actionConnectSetting())(OrganizationKppView)
