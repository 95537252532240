import ApiService from '../../../../services/api.service';

class SettingImportGlobalService extends ApiService {
  name= 'SettingImportGlobal';
  path= 'setting/import-global/page';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api = {
    get: async ()=> {
      return await this.http.get(`${this.apiLocation}/setting/import-global`)
    },
    update: async (formData)=> {
      return await this.http.put(`${this.apiLocation}/setting/import-global`, formData)
    }
  }
}

export default new SettingImportGlobalService();
