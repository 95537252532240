import React, { Component } from 'react';
import { Grid, Cell } from 'react-md';
import { Field, reduxForm , propTypes, formValueSelector } from 'redux-form';
import {validation, Textfield, Searchfield, Switch} from '../../../../components/form';
import DialogView from '../../../../components/entity/dialog.view';
import MasterApplicationService from './MasterApplication.service';
import CompanyService from "../../Administrator/Company/Company.service";

@reduxForm({form: 'master-application-dialog', destroyOnUnmount: false})
export default class MasterApplicationDialogView extends DialogView {
  service=MasterApplicationService
  initialData = {
  }

  formView() {
    return (
      <Grid style={{padding:'none'}}>
        <Field
          label='Company'
          name='company'
          className="md-cell md-cell--12"
          apiPath="page"
          viewField="nama"
          valueField="parent"
          component={Searchfield}
          service={CompanyService}
          validate={validation.required}
        />
        <Field
          label='Name'
          name='name'
          id="master-application-name"
          className="md-cell md-cell--6"
          component={Textfield}
          validate={validation.required}
        />

        <Field
          label='Generate PDF Saat Selesai Impor'
          name='isGeneratePdf'
          id="master-application-is-generate-pdf"
          className="md-cell md-cell--6"
          component={Switch}
        />

        <Field
          label='Description'
          name='description'
          row={5}
          id="master-application-description"
          className="md-cell md-cell--12"
          component={Textfield}
        />

        <Field
          label='Client ID'
          name='oauthClientDetail.id'
          id="master-application-client-id"
          className="md-cell md-cell--6"
          component={Textfield}
          disabled
        />

        <Field
          label='Client Secret'
          name='oauthClientDetail.clientSecret'
          id="master-application-client-secret"
          className="md-cell md-cell--6"
          component={Textfield}
          disabled
        />
      </Grid>
    )
  }
}
