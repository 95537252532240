import ApiService from '../../../../../../services/api.service';

class ALLSSP23Service extends ApiService {
  name= 'ALLSSP23';
  path= 'spt23/ssp-23/organization';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api= {
    email: async (category, ids) => {
      return await this.http.post(`${this.apiLocation}/email?category=${category}`, ids)
    },
    cetak: async (id) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/report/${id}`, {
        responseType: 'blob'
      })

      return res;
    },
    cetakBulk: async (ids) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/report/bulk`, ids, {
        responseType: 'blob'
      })

      return res;
    },
  }

}

export default new ALLSSP23Service();
