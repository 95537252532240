import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm, getFormValues} from 'redux-form';
import moment from 'moment';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  CardActions,
  Button,
  LinearProgress
} from 'react-md';

import FormView from '../../../../../components/entity/form.view';
import BP23EbupotService from './BP_Ebupot_23.service'
// import NomorBPService from '../nomorBP.service';
import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Checkbox} from '../../../../../components/form';
import UtilService from '../../../../../services/utils.service'
// import SPT21SignerService from '../SPT21Signer/SPT21Signer.service';
import NpwpService from './../../NpwpService';
import FormViewSpt from '../../../../../components/entity/form.view.spt';
import terbilangService from '../../../../../services/terbilang.service';
import Master23TarifLainService from '../../23_26/master/Tarif23/Master23TarifLain.service';
import nomorBP23EbupotService from './../nomorBPEbupot23.service'
import Master23TarifService from '../../23_26/master/Tarif23/Master23Tarif.service';

import _ from 'lodash';
import LawanTransaksiDialog from '../../../../../components/entity/LawanTransaksi.dialog';
import MasterLawanTransaksi23Service from '../../../Master/MasterLawanTransaksi23/MasterLawanTransaksi23.service';
import BPDokumenForm from './BP_Ebupot_23.dokumen.form'

@reduxForm({form: 'BP_23_Form', destroyOnUnmount: true})
@connect((state) => ({
  access      : state.entity.access.api.find,
  organization: state.auth.currentOrganization,
  spt         : state.auth.currentSPT,
  formData    : getFormValues('BP_23_Form')(state)
}))
export default class BP23EbupotForm extends FormViewSpt {
  service  = BP23EbupotService
  viewType = 2;

  typingObj=null;
  typingTimeout=1000;


  initialData={
    bagA: {},
    bagB: {bruto: 0},
    bagC: {}
  }

  constructor(props) {
    super(props);

    this.state= {
      ...this.state,
      showCalculateProgress: false,
      npwpNotValid         : true,
      tarif23              : {
        "dividen"               : 15.0,
        "bunga"                 : 15.0,
        "royalti"               : 15.0,
        "hadiahDanPenghargaan"  : 15.0,
        "sewaDanPenghasilanLain": 2.0,
        "jasaTeknik"            : 2.0,
        "jasaManagemen"         : 2.0,
        "jasaKonsultan"         : 2.0,
        "jasaLain"              : 2.0,
      },

      lkDialog: false
    }
  }

  async npwpValidation(evt, value){

    if(this.typingObj) clearTimeout(this.typingObj);

    this.typingObj = setTimeout(async ()=> {
      if(value){
        var res = await NpwpService.api.validate(value)
        if(res.valid){
          this.setState({npwpNotValid: false})

          var lk = await MasterLawanTransaksi23Service.api.page({
            'npwp.equals': value,
            'organizationId.equals': this.props.spt.data.organization.id
          })

          if(lk.data) {
            var formData = {...this.props.formData};

            if(lk.data.length > 0) {
              var d = lk.data[0];
              formData.bagA = {
                "npwp"  : d.npwp,
                "nik"   : d.nik,
                "nama"  : d.nama,
                "alamat": d.alamat,
                "email" : d.email
              };
            } else {
              formData.bagA = {
                "npwp" : value
              }
              this.toast.info({title: "Info", message: `Lawan Transaksi dengan npwp ${value} tidak ditemukan`})
            }
            this.props.initialize(formData);
          }
        } else {
          this.setState({npwpNotValid: true})
          this.toast.warning({title: "Validasi Npwp", message: "npwp tidak valid"})
        }
      }
    }, this.typingTimeout);

    // if(value){
    //   var res = await NpwpService.api.validate(value)
    //   if(res.valid){
    //     this.setState({npwpNotValid: false})
    //   } else {
    //     this.setState({npwpNotValid: true})
    //     this.toast.warning({title: "Validasi Npwp", message: "npwp tidak valid"})
    //   }

      /*
      var nonNpwp = 0;
      var x2      = 1;
      if(value === '000000000000000' || value == '' || !value) {
        nonNpwp = 1;
        // x2 = 1;
      }


      var form = this.props.formData;
      var d    = _.cloneDeep(form.bagB);

      Object.keys(d).forEach((k, i) => {
        if(!(k == 'jmlBruto' || k == 'jmlPph' || k == 'terbilang')) {

          var x = d[k];

          // console.log(x)
          if(typeof x === 'object') {
            x.nonNpwp = nonNpwp

            if(k.search('jasaLain') >= 0) {
              x.tarif = this.state.tarif23.jasaLain*x2
            } else if(k.search('sewaDanPenghasilan')>= 0){
              x.tarif = this.state.tarif23.sewaDanPenghasilanLain*x2
            } else {
              x.tarif = this.state.tarif23[k]*x2
            }
          }
        }
      })


      await this.props.dispatch(this.props.change('bagB', d))
      */

      // await this.calculate()

    // }
  }

  async beforeSave(value) {
    if(value.bagB.bruto == 0 || value.bagB.bruto == '') {
      this.toast.warning({title:'Warning', message:'Isi bruto harus lebih besar dari 0'})
      return false;
    }

    var date;
    if(typeof value.bagC.tanggal === 'string') {
      if(value.bagC.tanggal.search('/') != -1) {
        date = moment(value.bagC.tanggal, 'DD/MM/YYYY');
      } else {
        date = moment(value.bagC.tanggal, 'YYYY-MM-DD');
      }
    } else if(typeof value.bagC.tanggal === 'object') {
      date = moment(value.bagC.tanggal)
    }

    if(!(date.year() == this.props.spt.data.year && date.month()+1 == this.props.spt.data.month)) {
      this.toast.warning({title:'Warning', message:'Tanggal bukti potong harus sesuai dengan masa SPT'})
      return false;
    }

    for (let i = 1; i <= 6; i++) {
      let nama = value.bagB[`jasaLain${i}`].nama
      let bruto = parseFloat(value.bagB[`jasaLain${i}`].bruto)

      if (bruto > 0) {
        if (!nama) {
          this.toast.warning({title:'Warning', message:`Jenis jasa lain ${i} harus diisi.`})
          return false;
        }
      }
    }

    return true;
  }

  async afterSave(res, val) {
    var formData = this.props.formData;

    var data = {
      ...formData.bagA,
      organization: this.props.spt.data.organization
    }
    if(data.id) delete data.id;
    await MasterLawanTransaksi23Service.api.findOrCreate(data)
  }

  async initData() {

    var tarif = {};

    try {
      tarif = await Master23TarifService.api.byCompany({
        compId: this.props.organization.company.id,
        tgl   : moment().format("YYYY-MM-DD")
      });
    } catch (error) {}

    if(tarif) {
      await this.setState({'tarif23': tarif});
    }


    if(this.props.match.params.id == 'new') {
      let nomorBPReq = await nomorBP23EbupotService.api.get(this.props.spt.data.organization.id)
      var nomorBP    = "";

      if(nomorBPReq.data.mode === 'PREF_SUF') {
        nomorBP = nomorBPReq.data.prefix+nomorBPReq.data.suffix;
      } else {
        nomorBP = nomorBPReq.data.suffix+nomorBPReq.data.prefix;
      }
      let signer = {};  //await SPT21SignerService.api.getSigner(this.props.spt.data.id);

      this.initialData = {
        ...this.initialData,
        no  : nomorBP,   //.data.no,
        bagC: {
          nama   : this.props.organization.name,
          npwp   : this.props.organization.npwp,
          tanggal: new Date(),
        },
        spt   : this.props.spt.data,
        "bagB": {
          "bunga": {
              "bruto"  : 0,
              "nama"   : "Bunga",
              "nonNpwp": 0,
              "pph"    : 0,
              "tarif"  : this.state.tarif23.bunga
          },
          "dividen": {
              "bruto"  : 0,
              "nama"   : "Dividen",
              "nonNpwp": 0,
              "pph"    : 0,
              "tarif"  : this.state.tarif23.bunga
          },
          "hadiahDanPenghargaan": {
              "bruto"  : 0,
              "nama"   : "Hadiah dan Penghargaan",
              "nonNpwp": 0,
              "pph"    : 0,
              "tarif"  : this.state.tarif23.hadiahDanPenghargaan
          },
          "jasaKonsultan": {
              "bruto"  : 0,
              "nama"   : "Jasa Konsultan",
              "nonNpwp": 0,
              "pph"    : 0,
              "tarif"  : this.state.tarif23.jasaKonsultan
          },
          "jasaLain1": {
              "bruto"  : 0,
              "nama"   : "",
              "nonNpwp": 0,
              "pph"    : 0,
              "tarif"  : this.state.tarif23.jasaLain
          },
          "jasaLain2": {
              "bruto"  : 0,
              "nama"   : "",
              "nonNpwp": 0,
              "pph"    : 0,
              "tarif"  : this.state.tarif23.jasaLain,
          },
          "jasaLain3": {
              "bruto"  : 0,
              "nama"   : "",
              "nonNpwp": 0,
              "pph"    : 0,
              "tarif"  : this.state.tarif23.jasaLain
          },
          "jasaLain4": {
              "bruto"  : 0,
              "nama"   : "",
              "nonNpwp": 0,
              "pph"    : 0,
              "tarif"  : this.state.tarif23.jasaLain
          },
          "jasaLain5": {
              "bruto"  : 0,
              "nama"   : "",
              "nonNpwp": 0,
              "pph"    : 0,
              "tarif"  : this.state.tarif23.jasaLain
          },
          "jasaLain6": {
              "bruto"  : 0,
              "nama"   : "",
              "nonNpwp": 0,
              "pph"    : 0,
              "tarif"  : this.state.tarif23.jasaLain
          },
          "jasaManagemen": {
              "bruto"  : 0,
              "nama"   : "Jasa Managemen",
              "nonNpwp": 0,
              "pph"    : 0,
              "tarif"  : this.state.tarif23.jasaManagemen
          },
          "jasaTeknik": {
              "bruto"  : 0,
              "nama"   : "Jasa Teknik",
              "nonNpwp": 0,
              "pph"    : 0,
              "tarif"  : this.state.tarif23.jasaTeknik
          },
          "jmlBruto": 0,
          "jmlPph"  : 0,
          "royalti" : {
              "bruto"  : 0,
              "nama"   : "Royalti",
              "nonNpwp": 0,
              "pph"    : 0,
              "tarif"  : this.state.tarif23.royalti
          },
          "sewaDanPenghasilan": {
              "bruto"  : 0,
              "nama"   : "Sewa dan Penghasilan",
              "nonNpwp": 0,
              "pph"    : 0,
              "tarif"  : this.state.tarif23.sewaDanPenghasilanLain
          },
          "terbilang": ""
        }
      }
      this.props.initialize(this.initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id);
      this.props.initialize(res.data);
      // this.npwpValidation(null, res.data.bagA.npwp)
      this.setState({npwpNotValid: false})
    }
  }

  async changeTarif(d, e) {
    var tarif = await Master23TarifService.api.byCompany({
      compId: this.props.organization.company.id,
      tgl   : moment(d).format("YYYY-MM-DD")
    })

    if(tarif) {
      await this.setState({'tarif23': tarif});
    }

    var form = {...this.props.formData};
    var bagB = form.bagB;

    if(bagB) {
      bagB.bunga.tarif                = this.state.tarif23.bunga;
      bagB.dividen.tarif              = this.state.tarif23.dividen;
      bagB.hadiahDanPenghargaan.tarif = this.state.tarif23.hadiahDanPenghargaan;
      bagB.jasaKonsultan.tarif        = this.state.tarif23.jasaKonsultan;
      bagB.jasaLain1.tarif            = this.state.tarif23.jasaLain;
      bagB.jasaLain2.tarif            = this.state.tarif23.jasaLain;
      bagB.jasaLain3.tarif            = this.state.tarif23.jasaLain;
      bagB.jasaLain4.tarif            = this.state.tarif23.jasaLain;
      bagB.jasaLain5.tarif            = this.state.tarif23.jasaLain;
      bagB.jasaLain6.tarif            = this.state.tarif23.jasaLain;
      bagB.jasaManagemen.tarif        = this.state.tarif23.jasaManagemen;
      bagB.jasaTeknik.tarif           = this.state.tarif23.jasaTeknik;
      bagB.royalti.tarif              = this.state.tarif23.royalti;
      bagB.sewaDanPenghasilan.tarif   = this.state.tarif23.sewaDanPenghasilanLain;

      await this.props.initialize(form);

      await this.reCalculate()
    }
  }

  async calculate(e,v,pv,f, parent) {
    var form = this.props.formData;
    var d    = form.bagB[parent];

    var bruto = parseFloat(d.bruto);
    var tarif = parseFloat(d.tarif);

    d.nonNpwp = 0;
    if(form.bagA.npwp === '000000000000000' || form.bagA.npwp == '' || !form.bagA.npwp) {
      d.nonNpwp = 1;
      // x2 = 1;
    }

    if(f.search('bruto') >= 0) {
      bruto = parseFloat(v)

      if(bruto == 0) d.nonNpwp = 0

    } else if(f.search('tarif') >= 0) {
      tarif = parseFloat(v)
    }


    await this.props.change(`bagB.${parent}.nonNpwp`, d.nonNpwp);


    if(!bruto) bruto = 0;
    if(!tarif) tarif = 0;

    var nonNpwp                  = 1;
    if  (d.nonNpwp == 1) nonNpwp = 2;

    await this.props.change(`bagB.${parent}.pph`, bruto*tarif*nonNpwp/100);
    this.calculateTotal()
  }

  async calculateTotal() {
    var form = this.props.formData;
    var d    = form.bagB;

    var jmlPph   = 0;
    var jmlBruto = 0;
    Object.keys(d).forEach((k, i) => {
      if(!(k == 'jmlBruto' || k == 'jmlPph' || k == 'terbilang' || k == 'lastModifiedBy' || k == 'lastModifiedDate' || k == 'id' || k == 'createdDate' || k == 'createdBy')) {
        var x = d[k];
        if(typeof x == 'object') {
          jmlPph   = jmlPph+ parseFloat(x.pph);
          jmlBruto = jmlBruto+ parseFloat(x.bruto)
        }


      }
    })

    this.props.change('bagB.jmlBruto', jmlBruto)
    this.props.change('bagB.jmlPph', jmlPph);

    // console.log(jmlPph)

    this.props.change('bagB.terbilang', terbilangService(parseInt(jmlPph)));

  }

  async reCalculate() {
    var form = {...this.props.formData};
    var d    = form.bagB;

    d.nonNpwp = 0;
    if(form.bagA.npwp === '000000000000000' || form.bagA.npwp == '' || !form.bagA.npwp) {
      d.nonNpwp = 1;
      // x2 = 1;
    }

    var nonNpwp                  = 1;
    if  (d.nonNpwp == 1) nonNpwp = 2;

    Object.keys(d).forEach((k, i) => {
      if(!(k == 'jmlBruto' || k == 'jmlPph' || k == 'terbilang' || k == 'lastModifiedBy' || k == 'lastModifiedDate' || k == 'id' || k == 'createdDate' || k == 'createdBy')) {
        var x = d[k];
        if(typeof x == 'object') {
          if(x.bruto) {
            x.pph = x.bruto*x.tarif*nonNpwp/100;
          }
        }
      }
    })



    await this.props.initialize(form);

    this.calculateTotal()

  }

  formView() {
    var formData                       = {bagB: {}};
    if  (this.props.formData) formData = this.props.formData

    var companyId                                                              = '';
    if  (this.props.organization && this.props.organization.company) companyId = this.props.organization.company.id;
    if  (formData.spt && formData.spt.organization) companyId                  = formData.spt.organization.company.id

    return (
      <div>

        <LawanTransaksiDialog
          spt      = {this.props.spt.data}
          onSelect = {(d)=> {
            var formData = {...this.props.formData};

            formData.bagA = {
              "npwp"  : d.npwp,
              "nik"   : d.nik,
              "nama"  : d.nama,
              "alamat": d.alamat,
              "email" : d.email
            };

            this.setState({npwpNotValid: false})

            this.props.initialize(formData);

          }}
          service = {MasterLawanTransaksi23Service}
          visible = {this.state.lkDialog}
          onHide  = {()=> this.setState({lkDialog: false})}
        />
        <Card>
          <div className="md-grid">
            <Field
              label     = 'No.Bukti Potong'
              name      = 'no'
              className = "md-cell md-cell--6"
              component = {Textfield}
              validate  = {validation.required}
            />
            <Field
              label        = 'Tanggal Bukti Potong'
              name         = 'bagC.tanggal'
              className    = "md-cell md-cell--6"
              onDateChange = {async (d, e) => this.changeTarif(d)}
              component    = {Datepicker}
              validate     = {validation.required}
            />
            <Field
              label     = 'Referensi'
              name      = 'referensi'
              id        = "BP21FForm-referensi"
              className = "md-cell md-cell--12"
              component = {Textfield}
            />
          </div>
        </Card>

        <br/>

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='A. IDENTITAS PENERIMA PENGHASILAN YANG DIPOTONG ' />
          <Divider/>
          <div className="md-grid">
            <div className='md-cell md-cell--6'>
              <div className='mpk-layout'>
                <Field
                  className  = 'flex'
                  label      = 'NPWP'
                  name       = 'bagA.npwp'
                  id         = "BP21FForm-npwp"
                  component  = {TextfieldMask}
                  maskFormat = "##.###.###.#-###-###"
                  mask       = "_"
                  length     = {15}
                  onChange   = {this.npwpValidation.bind(this)}
                  validate   = {validation.required}
                />
                <Button icon primary swapTheming style={{marginTop: 10}} onClick={()=> this.setState({lkDialog: true})}>search</Button>
               </div>
              <Field
                label = 'Nama'
                name  = 'bagA.nama'

                disabled  = {this.state.npwpNotValid}
                component = {Textfield}
                validate  = {validation.required}
              />
              {/* <Field
                label = 'NIK'
                name  = 'bagA.nik'

                disabled  = {this.state.npwpNotValid}
                component = {Textfield}
              /> */}
              {/* <Field
                label     = 'Telepon'
                name      = 'name'
                id        = "BP21FForm-name"
                component = {Textfield} */}
                <Field
                  label = 'Kelurahan/Desa'
                  name  = 'bagA.desa'

                  disabled  = {this.state.npwpNotValid}
                  component = {Textfield}
                  validate  = {validation.required}
                />
                <Field
                  label = 'Kota/Kabupaten'
                  name  = 'bagA.kota'

                  disabled  = {this.state.npwpNotValid}
                  component = {Textfield}
                  validate  = {validation.required}
                />
            </div>
            <div className='md-cell md-cell--6'>
              {/* <Field
                label     = 'Kode Poss'
                name      = 'npwp'
                id        = "BP21FForm-codePoss"
                component = {Textfield}
              /> */}
              <Field
                label = 'Alamat'
                name  = 'bagA.alamat'

                disabled  = {this.state.npwpNotValid}
                component = {Textfield}
                validate  = {validation.required}
              />
              <Field
                label = 'Kecamatan'
                name  = 'bagA.kecamatan'

                disabled  = {this.state.npwpNotValid}
                component = {Textfield}
                validate  = {validation.required}
              />
              <Field
                label = 'Propinsi'
                name  = 'bagA.propinsi'

                disabled  = {this.state.npwpNotValid}
                component = {Textfield}
                validate  = {validation.required}
              />
              <Field
                label = 'Kode Pos'
                name  = 'bagA.kodePos'

                disabled  = {this.state.npwpNotValid}
                component = {Textfield}
                validate  = {validation.required}
              />
              {/* <Field
                label = 'Email'
                name  = 'bagA.email'

                disabled  = {this.state.npwpNotValid}
                component = {Textfield}
              /> */}
            </div>
          </div>
        </Card>

        <br/>

        {this.state.showCalculateProgress && <LinearProgress id='lin_pro' style={{margin: 0}} />}
        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='B. DOKUMEN' />
          <Divider/>

          <BPDokumenForm />
        </Card>

        <br />

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='C. PAJAK PENGHASILAN YANG DIPOTONG' />
          <Divider/>

          <div className="md-grid">
            <div className="md-cell md-cell--6">
              <Field
                label='Kode Object Pajak'
                name='bagC.kodeObjekPajak'

                component={Searchfield}
                options={[
                  {id: "Kosong", name: 'Kosong'},
                ]}
              />
              <Field
                label     = 'Jumlah Penghasilan Bruto (Rp.)'
                name      = 'bagC.bruto'

                component = {TextfieldMask}
                className = 'md-text-right'
                money     = {','}
              />
            </div>
            <div className="md-cell md-cell--6">
              <Field
                label     = 'Tarif'
                name      = 'bagC.tarif'

                component = {TextfieldMask}
                className = 'md-text-right'
                money     = {','}
              />
              <Field
                label     = 'PPh Yang Dipotong / DTP (Rp.)'
                name      = 'bagC.pph'

                component = {TextfieldMask}
                className = 'md-text-right'
                money     = {','}
              />
            </div>
          </div>
        </Card>

        <br />

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='D. IDENTITAS PEMOTONG ' />
          <Divider/>

          <div className="md-grid">
          <Field
            label     = 'NPWP'
            name      = 'bagC.npwp'
            className = "md-cell md-cell--6"
            disabled
            component  = {TextfieldMask}
            maskFormat = "##.###.###.#-###-###"
            mask       = "_"
            validate   = {validation.required}
          />
          <Field
            label     = 'Nama'
            name      = 'bagC.nama'
            className = "md-cell md-cell--6"
            disabled
            component = {Textfield}
            validate  = {validation.required}
          />
          <Field
            label='Bertindak sebagai '
            name='bagC.nama2'
            className="md-cell md-cell--6"
            component={Searchfield}
            options={[
              {id: "-", name: '-'},
            ]}
          />
          <Field
            className="md-cell md-cell--12"
            label='Dengan menyadari sepenuhnya akan segala akibatnya termasuk sanksi-saksi sesuai dengan ketentuan perundang undangan yang berlaku,
            saya menyatakan bahwa apa yang telah saya beritahukan di atas beserta lampiran-lampirannya adalah benar, lengkap dan jelas'
            name='bagC.approve'
            component={Checkbox}
          />
          <br />
          <br />
          <br />
          <br />
          <br />
          </div>
        </Card>

      </div>
    )
  }

}
