import ApiService from '../../../../../../services/api.service';

class AllSsp15SetorSendiriService extends ApiService {
  name= 'AllSsp15SetorSendiri';
  path= 'spt15/bp-setor-sendiri-pelayaran-penerbangan-ln/organization';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api= {
    email: async (category, ids) => {
      return await this.http.post(`${this.apiLocation}/email?category=${category}`, ids)
    }
  }

}

export default new AllSsp15SetorSendiriService();
