import ApiService from '../../../services/api.service';

class EbillingInquiryService extends ApiService {
  name= 'EbillingInquiry';
  path= 'ebilling';

  constructor() {
    super();
    this.init();
    this.initApi()
  }

  api = {
    requestInquiry: async (data)=> {
      return await this.http.post(`${this.apiLocation}/ebilling/inquiry`, data)
    },
    requestInquiryGlobal: async (data)=> {
      return await this.http.post(`${this.apiLocation}/ebilling/inquiry/global`, data)
    },
    refreshInquiry: async (data)=> {
      return await this.http.post(`${this.apiLocation}/ebilling/inquiry/refresh`, data)
    },
    refreshInquiryGlobal: async (data)=> {
      return await this.http.post(`${this.apiLocation}/ebilling/inquiry/refresh/global`, data)
    },
    checkInquiry: async (data)=> {
      return await this.http.post(`${this.apiLocation}/ebilling/inquiry/check`, data)
    },
    checkInquiryGlobal: async (data)=> {
      return await this.http.post(`${this.apiLocation}/ebilling/inquiry/check/global`, data)
    },
  }
}

export default new EbillingInquiryService();
