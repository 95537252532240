import ApiService from '../../../../../services/api.service';

class SPT4a2Service extends ApiService {
  name= 'SPT_4a2';
  path= 'spt4a2';
  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api = {
    pembetulan: async(id, dispatch=()=>{})=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/pembetulan/${id}`)

      return res;
    },
    pembetulanBulk: async(ids, dispatch=()=>{})=> {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/pembetulan`, ids)

      return res;
    },

    cancelPembetulanBulk: async(ids, dispatch=()=>{})=> {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/pembetulan/cancel`, ids)

      return res;
    },

    downloadLapor: async(ids, dispatch=()=>{})=> {
      var res = await this.http.post(`${this.apiLocation}/upload/download`, ids,{
        responseType: 'blob'
      })

      return res;
    },

    laporAll: async(data) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/lapor`, data)

      return res;
    },

    laporAllWithSummary: async(data) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/lapor/summary`, data)

      return res;
    },

    laporAllWithSummarySkemaLama: async(data) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/lapor/summary/skema-lama`, data)

      return res;
    },

    laporAllWithSummaryParam: async(data) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/lapor/summary/param`, {params: data})

      return res;
    },

    laporAllWithSummarySkemaLamaParam: async(data) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/lapor/summary/skema-lama/param`, {params: data})

      return res;
    },

    pengajuanApprovalBulk: async(data, dispatch=()=>{})=> {
      var res = await this.http.post(`${this.apiLocation}/mainFlow/pengajuan/bulk`, data)

      return res;
    },
    getSummary: async(sptId)=> {
      return await this.http.get(`${this.apiLocation}/spt4a2/ssp-4a2/detail/${sptId}`)
    },

    sendEmailToProfile: async(data) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/email/company`, data)

      return res;
    },
  }
}


export default new SPT4a2Service();
