import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Chip,
  Switch,
  List,
  ListItem,
  ListItemControl,
  Subheader,
  FontIcon,
  Avatar,
  Divider,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
} from 'react-md';

import {validation, Textfield, Searchfield, TextfieldMask, Datepicker, Radio, Checkbox} from '../../../../components/form';
import Bluebird from 'bluebird';
import { Field, reduxForm, getFormValues, change  } from 'redux-form';
import EbillingAccountService from '../EbillingAccountService';
//import FormDialog from './Organization.dialog.view';
import UtilService from '../../../../services/utils.service';
import download from 'downloadjs';
import iziToast from 'izitoast';
import ListView from '../../../../components/entity/listView';
import PaymentOrderService from './PaymentOrder.service';
import SuratSetoranService from '../SuratSetoran/SuratSetoran.service';
import RetryService from '../Retry/Retry.service';
import utilsService from '../../../../services/utils.service';
import PaymentMethodService from '../PaymentMethod/PaymentMethod.service';
import Constants from '../Constants/Constants';
import moment from 'moment';
import _ from 'lodash';

@connect((state)=> ({
  ...PaymentOrderService.stateConnectSetting()(state),
}), PaymentOrderService.actionConnectSetting())
export default class PaymentOrderView extends ListView {
  service=PaymentOrderService
  suratSetoranService=SuratSetoranService
  retryService=RetryService
  ebillingAccountService=EbillingAccountService
  akunPembayaranService=PaymentMethodService

  FormDialog=() => <div/>

  constructor(props){
    super(props);
    this.state = {
      ...this.state
    }

    this.state.ebillingUser = {}
  }

  beforeFetch(params) {
    if(params["sortBy"] === "createdAt") {
      params["sortBy"] = "createdDate"
      params["sort"] = "DESC"
    }
  }

  addDialog=false
  editDialog=false

  _barActions = [
    {
      label:'word.delete',
      iconClassName:'mdi mdi-delete',
      onClick:() => {
        this.setState({showDialogConfirmDeleteSelected:true})
      },
      disabledFunc:() => this.props.table.selected.length === 0
    },
    {
      label:'word.removeChecked',
      iconClassName:'mdi mdi-minus-box',
      onClick:() => this.removeChecked(),
      disabledFunc:() => this.props.table.selected.length === 0
    },
    {
      label:'word.addChecked',
      iconClassName:'mdi mdi-plus-box',
      onClick:() => this.addChecked(),
      disabledFunc:() => this.props.table.selected.length === 0
    },
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => this.fetchData()
    },
  ]

  tableActions = (data) => {
    let actions = [
      {
        label:"More Info",
        iconClassName:"mdi mdi-bell",
        onClick: (item) => this.editItem(item)
      },
    ]

    let divider = {
      label:"divider",
      iconClassName:"-"
    }

    actions.push(divider)

    /*if(!data.suratsetoran.name) {
      let inquiryAction = {
        label:"Inquiry",
        iconClassName:"mdi mdi-clipboard-account",
        onClick:(data) => this.retryInquiry(data),
      }

      actions.push(inquiryAction)
    }*/

    /*if(!data.suratsetoran.idbilling) {
      let idBillingAction = {
        label:"ID Billing",
        iconClassName:"mdi mdi-play",
        onClick:(item) => this.retryBilling(item),
      }

      actions.push(idBillingAction)
    }*/

    if(data.suratsetoran.idbilling) {
      let printAction = {
        label:"Cetak",
        iconClassName:"mdi mdi-printer",
        onClick:(item, callback) => this.printItem(item, callback),
        confirmation:{
          title:"sentence.custom.print",
          message:"sentence.custom.printConfirmation"
        }
      }

      actions.push(printAction)
    }

    let deleteAction = {
      label:"Delete",
      iconClassName:"mdi mdi-delete",
      onClick:(item, callback) => this.deleteItem(item, callback),
      confirmation:{
        title:"sentence.custom.deleteItem",
        message:"sentence.custom.deleteItemConfirmation"
      }
    }

    actions.push(deleteAction)

    return actions
  }

  columns=[
    {isSortable: true, label: "word.kodeSSP", value: "suratsetoran.taxno", show: true, isSearchable: true},
    {isSortable: false, label: "word.idBilling", value: (data) => {
      if(data.suratsetoran.idbilling) {
        return data.suratsetoran.idbilling
      }

      if(data.suratsetoran.errorBilling) {
        return data.suratsetoran.messageBilling
      } else {
        return '-'
      }
    }, type: "func", show: true, isSearchable: true},
    {isSortable: true, label: "word.npwp", value: "suratsetoran.npwp", show: true, isSearchable: true},
    {isSortable: false, label: "word.name", value: (data) => {
      if(data.suratsetoran.name) {
        return data.suratsetoran.name
      }

      if(data.suratsetoran.errorInquiry) {
        return data.suratsetoran.messageInquiry
      } else {
        return '-'
      }
    }, type: "func", show: true, isSearchable: true},
    {isSortable: true, label: "word.jenisPajak", value: "suratsetoran.jenispajak.code", show: true, isSearchable: true},
    {isSortable: true, label: "word.jenisSetoran", value: "suratsetoran.jenissetoran.code", show: true, isSearchable: true},
    {isSortable: false, label: "word.masaPajak", value: (data) => {
      return data.suratsetoran.masadari.code + ' - ' + data.suratsetoran.masasampai.code + ' ' + data.suratsetoran.tahun;
    }, type: "func", show: true, isSearchable: false},
    {isSortable: true, label: "word.jumlahSetoran", value: "suratsetoran.jumlahsetor", type: "number", show: true, isSearchable: true},
    {isSortable: true, label: "word.aktifSd", value: "suratsetoran.activeUntil", show: true, isSearchable: true},
    {isSortable: true, label: "word.username", value: "additionalUsername", show: true, isSearchable: true},
  ]

  async checkConnection() {
    let res = await this.ebillingAccountService.api.me()

    this.setState({ebillingUser: res.data});
  }

  async componentDidMount() {
    this.rootPath = this.props.match.url;

    let paymentOrder = {
      type: 'selected',
      data: []
    }

    localStorage.setItem('paymentOrder', JSON.stringify(paymentOrder));

    try {
      this.checkConnection()
    } catch (e){
      this.toast.warning({
        title: e.name,
        message: e.message
      })
    }
  }

  retryInquiry = async (item) => {
    let nomorPajakList = []

    if(!item.suratsetoran.name) {
      let data = {
        nomorPajak: item.suratsetoran.taxno
      }

      nomorPajakList.push(data)
    }

    if(nomorPajakList.length > 0) {
      try {
        let res = await this.retryService.api.inquiry(nomorPajakList)

        iziToast.success({
          title: 'Sukses',
          message: 'Inquiry sedang diproses, silahkan klik tombol refresh.'
        })
      } catch (e) {
        iziToast.warning({
          title: e.name,
          message: e.message
        })
      }
    } else {
      iziToast.warning({
        title: 'Gagal',
        message: 'Data sudah terdapat nama.'
      })
    }
  }

  retryInquiryBulk = async () => {
    let selected = this.props.table.selected

    let nomorPajakList = []

    selected.forEach(item => {
      if(!item.suratsetoran.name) {
        let data = {
          nomorPajak: item.suratsetoran.taxno
        }

        nomorPajakList.push(data)
      }
    })

    if(nomorPajakList.length > 0) {
      try {
        let res = await this.retryService.api.inquiry(nomorPajakList)

        iziToast.success({
          title: 'Sukses',
          message: 'Inquiry sedang diproses, silahkan klik tombol refresh.'
        })
      } catch (e) {
        iziToast.warning({
          title: e.name,
          message: e.message
        })
      }
    } else {
      iziToast.warning({
        title: 'Gagal',
        message: 'Anda harus menceklis minimal satu data yang belum terdapat nama.'
      })
    }
  }

  retryBilling = async (item) => {
    let nomorPajakList = []

    if(!item.suratsetoran.idbilling) {
      let data = {
        nomorPajak: item.suratsetoran.taxno
      }

      nomorPajakList.push(data)
    }

    if(nomorPajakList.length > 0) {
      try {
        let res = await this.retryService.api.billing(nomorPajakList)

        iziToast.success({
          title: 'Sukses',
          message: 'Billing sedang diproses, silahkan klik tombol refresh.'
        })
      } catch (e) {
        iziToast.warning({
          title: e.name,
          message: e.message
        })
      }
    } else {
      iziToast.warning({
        title: 'Gagal',
        message: 'Data sudah terdapat billing.'
      })
    }
  }

  retryBillingBulk = async () => {
    let selected = this.props.table.selected

    let nomorPajakList = []

    selected.forEach(item => {
      if(!item.suratsetoran.idbilling) {
        let data = {
          nomorPajak: item.suratsetoran.taxno
        }

        nomorPajakList.push(data)
      }
    })

    if(nomorPajakList.length > 0) {
      try {
        let res = await this.retryService.api.billing(nomorPajakList)

        iziToast.success({
          title: 'Sukses',
          message: 'Billing sedang diproses, silahkan klik tombol refresh.'
        })
      } catch (e) {
        iziToast.warning({
          title: e.name,
          message: e.message
        })
      }
    } else {
      iziToast.warning({
        title: 'Gagal',
        message: 'Anda harus menceklis minimal satu data yang belum terdapat id billing.'
      })
    }
  }

  printItem = async (item, callback) => {
    try {
      if(item.suratsetoran.idbilling) {
        let report = await this.suratSetoranService.api.print(item.suratsetoran.id)
        let filename = report.headers.filename

        download(report.data, filename);
      } else {
        iziToast.warning({
          title: 'Gagal',
          message: 'Anda hanya bisa mencetak data yang terdapat ID billing.'
        })
      }

      callback()
    } catch(e) {
      callback(e, e)
    }
  }

  printBulk = async () => {
    let selected = this.props.table.selected

    let idList = []

    selected.forEach(item => {
      if(item.suratsetoran.idbilling) {
        idList.push(item.suratsetoran.id)
      }
    })

    if(idList.length > 0) {
      let data = {
        id: idList
      }

      try {
        let res = await this.suratSetoranService.api.printBulk(data)

        iziToast.success({
          title: 'Sukses',
          message: 'Silahkan cek di halaman log cetak payment order.'
        })
      } catch (e) {
        iziToast.warning({
          title: e.name,
          message: e.message
        })
      }
    } else {
      iziToast.warning({
        title: 'Gagal',
        message: 'Anda harus menceklis minimal satu data yang terdapat ID billing.'
      })
    }
  }

  deleteSelected = async (items, callback) => {
    try {
      let idList = []

      items.forEach(item => {
        if (item.id) {
          idList.push(item.id)
        }
      })

      let data = {
        id: idList
      }

      let res = await this.service.api.deleteChecked(data)

      callback()

      await this.fetchData()
    } catch (e) {
      callback(e, e)
    }
  }

  printLaporan = async ()=> {
    let dialog = await this.context.showDialog((props, res, rej) =>({
      title: 'Cetak Laporan',
      okText: 'Cetak',
      width: 600,
      text: (
        <div className='md-grid'>
          <Field
            label='Dari Tanggal'
            name='createdDateMulai'
            inline={true}
            className="md-cell md-cell--12"
            component={this.ig.Datepicker}
          />
          <Field
            label='Ke Tanggal'
            name='createdDateAkhir'
            inline={true}
            className="md-cell md-cell--12"
            component={this.ig.Datepicker}
          />

          <div style={{height: 300}}/>
        </div>
      ),
    }));

    if (dialog === undefined)
      dialog = {}

    if (dialog) {
      try {
        let createdDateMulai = dialog.createdDateMulai

        if(createdDateMulai) {
          createdDateMulai = moment(createdDateMulai).format('YYYY-MM-DD')

          dialog.createdDateMulai = createdDateMulai
        }

        let createdDateAkhir = dialog.createdDateAkhir

        if(createdDateAkhir) {
          createdDateAkhir = moment(createdDateAkhir).format('YYYY-MM-DD')

          dialog.createdDateAkhir = createdDateAkhir
        }

        let res = await this.suratSetoranService.api.printReport(dialog)

        if(res.success) {
          iziToast.success({
            title: 'Sukses',
            message: 'Silahkan cek di halaman log cetak laporan PO.'
          })
        } else {
          let message = 'Cetak laporan payment order gagal.'

          if(res.message) {
            message = res.message
          }

          iziToast.error({
            title: 'Gagal',
            message: message
          })
        }
      } catch (e) {
        iziToast.warning({
          title: e.name,
          message: e.message
        })
      }
    }
  }

  async addChecked() {
    let paymentOrder = localStorage.getItem('paymentOrder')

    if (paymentOrder) {
      paymentOrder = JSON.parse(paymentOrder)
    }
    else {
      paymentOrder = {
        type: 'selected',
        data: []
      }
    }

    let currentSelectedData = paymentOrder.data
    let selectedData = this.props.table.selected

    _.forEach(selectedData, (value, key) => {
      let existingData = _.find(currentSelectedData, (currentValue) => {
        return currentValue.id === value.id
      })

      // belum ada di ceklis
      if (!existingData) {
        paymentOrder.data.push(value)
      }
    })

    localStorage.setItem('paymentOrder', JSON.stringify(paymentOrder));
  }

  async removeChecked() {
    let paymentOrder = localStorage.getItem('paymentOrder')

    if (paymentOrder) {
      paymentOrder = JSON.parse(paymentOrder)
    }
    else {
      paymentOrder = {
        type: 'selected',
        data: []
      }
    }

    let currentSelectedData = paymentOrder.data
    let selectedData = this.props.table.selected

    _.forEach(selectedData, (value, key) => {
      let removedData = _.remove(currentSelectedData, (currentValue) => {
        return currentValue.id === value.id
      })

      paymentOrder.data = currentSelectedData
    })

    localStorage.setItem('paymentOrder', JSON.stringify(paymentOrder));
  }

  async order(type) {
    let selectedData = []

    var total = 0;

    if(type === 'selected') {
      await this.addChecked()

      let paymentOrder = localStorage.getItem('paymentOrder')

      if (paymentOrder) {
        paymentOrder = JSON.parse(paymentOrder)
      }
      else {
        paymentOrder = {
          type: 'selected',
          data: []
        }
      }

      let currentSelectedData = paymentOrder.data

      if (currentSelectedData.length > 0) {
        selectedData = currentSelectedData;
      }
      else {
        selectedData = this.props.table.selected;
      }

      if (selectedData.length === 0) {
        iziToast.warning({
          title: 'Warning!',
          message: 'Tidak data ada yang diceklis.'
        })

        return false
      }
    }

    localStorage.setItem('paymentOrder', JSON.stringify({type: type, data: selectedData}));

    let dialog = await this.context.showDialog((props, res, rej) =>({
      title: 'Order',
      initialValue:{},
      okText: 'Confirm',
      width: 600,
      text: (
        <div>
          <div className='md-grid'>
            <Field
              label='Metode Pembayaran'
              name='paymentMethod'
              className="md-cell md-cell--12"
              service={PaymentMethodService}
              valueField="parent"
              itemTemplate={function(d) {
                if(d.bank) {
                  let baseUrl = Constants.baseUrl()

                  let image = baseUrl + '/image/logo/bank/' + d.bank.id

                  return {
                    primaryText: d[this.props.viewField],
                    rightIcon: <img style={{height: 20, width: 'auto'}} src={image} />,
                    onClick: () => {
                      if(!this.props.disabled){
                        this.handleItemClick(d); this.hide()
                      }
                    }
                  }
                } else {
                  return {
                    primaryText: '',
                    onClick: () => {
                      if(!this.props.disabled){
                        this.handleItemClick(d); this.hide()
                      }
                    }
                  }
                }
              }}
              component={this.ig.Searchfield}
            />
          </div>
          {type === 'selected' &&
          <div>
            <DataTable plain>
              <TableHeader>
                <TableRow>
                  <TableColumn>No.</TableColumn>
                  <TableColumn>Kode SSP</TableColumn>
                  <TableColumn>ID Billing</TableColumn>
                  <TableColumn>NPWP</TableColumn>
                  <TableColumn>Nama</TableColumn>
                  <TableColumn>Jenis Pajak</TableColumn>
                  <TableColumn>Jenis Setoran</TableColumn>
                  <TableColumn>Masa Pajak</TableColumn>
                  <TableColumn>Jumlah Setoran</TableColumn>
                </TableRow>
              </TableHeader>
              <TableBody>
                {
                  selectedData.map((d, i)=> {
                    total += d.suratsetoran.jumlahsetor;

                    let idBilling = '-';

                    if (d.suratsetoran.idbilling) {
                      idBilling = d.suratsetoran.idbilling;
                    }
                    else {
                      if (d.suratsetoran.errorBilling) {
                        idBilling = d.suratsetoran.messageBilling
                      }
                    }

                    let name = '-';

                    if (d.suratsetoran.name) {
                      name = d.suratsetoran.name;
                    }
                    else {
                      if (d.suratsetoran.errorInquiry) {
                        name = d.suratsetoran.messageInquiry
                      }
                    }

                  return <TableRow key={i}>
                    <TableColumn>{i+1}</TableColumn>
                    <TableColumn>{d.suratsetoran.taxno}</TableColumn>
                    <TableColumn>{idBilling}</TableColumn>
                    <TableColumn>{d.suratsetoran.npwp}</TableColumn>
                    <TableColumn>{name}</TableColumn>
                    <TableColumn>{d.suratsetoran.jenispajak.code}</TableColumn>
                    <TableColumn>{d.suratsetoran.jenissetoran.code}</TableColumn>
                    <TableColumn>{ d.suratsetoran.masadari.code + ' - ' + d.suratsetoran.masasampai.code + ' ' + d.suratsetoran.tahun}</TableColumn>
                    <TableColumn style={{textAlign: 'right'}}>{utilsService.number.format(d.suratsetoran.jumlahsetor)}</TableColumn>
                  </TableRow>
                })}

                <TableRow style={{borderTop: '2px solid black'}}>
                  <TableColumn></TableColumn>
                  <TableColumn>Total</TableColumn>
                  <TableColumn></TableColumn>
                  <TableColumn></TableColumn>
                  <TableColumn></TableColumn>
                  <TableColumn></TableColumn>
                  <TableColumn></TableColumn>
                  <TableColumn></TableColumn>
                  <TableColumn style={{textAlign: 'right'}}>{utilsService.number.format(total)}</TableColumn>
                </TableRow>


              </TableBody>
            </DataTable>
          </div>
          }
        </div>
      )
    }));

    if (dialog === undefined)
      dialog = {}

    if(dialog) {
      let paymentOrder = JSON.parse(localStorage.getItem('paymentOrder'));

      paymentOrder.paymentMethod = dialog.paymentMethod;

      localStorage.setItem('paymentOrder', JSON.stringify(paymentOrder));

      // BNI Direct
      if(dialog.paymentMethod.name === 'BNIDirect') {
        this.props.history.push('/EBILLING/paymentOrder/bniDirect');
      } else if(dialog.paymentMethod.name === 'BRI Cash Management System') {
        this.props.history.push('/EBILLING/paymentOrder/paymentBriCms');
      } else {
      }
    }
  }


  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='Kode SSP'
          name='kodeSSP'
          className="md-cell md-cell--6"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='ID Billing'
          name='idBilling'
          className="md-cell md-cell--6"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='NPWP'
          name='npwp'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Nama'
          name='nama'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Jenis Pajak'
          name='jenisPajak'
          className="md-cell md-cell--6"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Jenis Setoran'
          name='jenisSetoran'
          className="md-cell md-cell--6"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Masa Awal'
          name='masaAwal'
          className="md-cell md-cell--6"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Masa Akhir'
          name='masaAkhir'
          className="md-cell md-cell--6"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Tahun Pajak'
          name='tahun'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Username'
          name='additionalUsername'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
      </this.SearchContainer>
    )
  }

  _barItem() {
    let selected = this.props.table.selected

    return (
      <div className='actions mpk-layout'>
        {/*<Button icon tooltipLabel="Generate" onClick={() => this.retryBillingBulk()} disabled={selected.length === 0}>play_arrow</Button>
        <Button icon tooltipLabel="Inquiry" onClick={() => this.retryInquiryBulk()} disabled={selected.length === 0}>assignment_ind</Button>*/}
        <Button icon tooltipLabel="Order" onClick={() => {this.order('selected')}}>shop</Button>
        <Button icon tooltipLabel="Order Semua" onClick={() => {this.order('all')}}>shop_two</Button>
        {/*<Button icon tooltipLabel="Cetak Bulk" onClick={() => this.printBulk()} disabled={selected.length === 0}>print</Button>*/}
        <Button icon tooltipLabel="Cetak Laporan" onClick={() => {this.printLaporan()}}>book</Button>
        <Switch
          style={{float: "right"}}
          id="switch-table-setting"
          name="Switch Table Setting"
          label=""
          checked={this.state.showTableSetting}
          onChange={() => {
            this.setState({
              showTableSetting:!this.state.showTableSetting
            })
          }}
        />
      </div>
    )
  }

  // right bar
  tabWidth = 280
  tabs= ["status", "search", "column"]

  tabComponents() {
    var obj = this;
    return {
      status: this.status.bind(obj)
    }
  }

  status() {
    const { handleSubmit, submitting, valid, pristine } = this.props;
    return (
      <div className='mpk-layout column fill'>
        <div className='flex'>
          <List className="">
            <Subheader primaryText="Status Koneksi" />

            { this.state.ebillingUser.login &&
              <ListItem
                leftAvatar={<Avatar  suffix="green" icon={<FontIcon>done</FontIcon>} />}
                primaryText="Terhubung"
                secondaryText={"Terhubung sebagai " + this.state.ebillingUser.login + "."}
              />
            }

            { !this.state.ebillingUser.login &&
              <ListItem
                leftAvatar={<Avatar suffix="red" icon={<FontIcon>clear</FontIcon>} />}
                primaryText="Tidak Terhubung"
                secondaryText="Status Koneksi"
              />
            }

            {/*<Subheader primaryText="Status Sistem" />

            <ListItem
              leftAvatar={<Avatar  suffix="green" icon={<FontIcon>done</FontIcon>} />}
              primaryText="Sistem Active"
              secondaryText="Status Sistem"
            />*/}
          </List>
        </div>
      </div>
    )
  }

}
