import React, { Component } from 'react';
import { Field, reduxForm , propTypes, formValueSelector } from 'redux-form';
import { validation, Textfield, TextfieldMask, Datepicker, convert } from '../../../../components/form';
import Service from './LogImportCsvByApp.service';
import ImportDialogTemplateView from './ImportCsvByApp.dialog.template.view';

@reduxForm({form: 'LogImportCsvByAppDialog-form', destroyOnUnmount: false})
export default class LogImportCsvByAppDialogView extends ImportDialogTemplateView {
  service=Service
}
