/**
 * Created by dwiargo on 11/30/17.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Commandbar, Table, TableSetting, Empty, DialogConfirm } from 'react-mpk';
import { Paper, Switch } from 'react-md';

import * as tableActions from 'react-mpk/redux/actions/tableActions';
import UserForm from './components/UserForm';

class ManageUser extends Component {
  constructor(){
    super();
    this.state = {
      showTableSetting:true,
      showDialogConfirmDeleteSelected:false,
      showUserForm:false
    }
  }

  fetchData = (params) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({data:[
          {name: "Jhony Mahendra", email: "jhon@mail.com", age: 21, active: false},
          {name: "Intan Rahayu", email: "intan@mail.com", age: 21, active: true}
        ]})
      }, 1000)
    })
  };

  deleteItem = (item, callback) => {
    setTimeout(() => {
      this.props.tableActions.deleteDataItem(item);
      callback();
    }, 1000)
  };

  render() {
    let { tableActions, table } = this.props;

    return (
      <div className="mpk-layout column fill">
        <Commandbar
          title="route.userManagement"
          translate={true}
          actions={[
            {
              label:'word.create',
              iconClassName:'mdi mdi-plus',
              onClick:() => this.setState({showUserForm:true})
            },
            {
              label:'word.delete',
              iconClassName:'mdi mdi-delete',
              onClick:() => {
                this.setState({showDialogConfirmDeleteSelected:true})
              },
              disabledFunc:() => table.selected.length === 0
            }
          ]}
          rightCorner={(
            <Switch
              id="switch-table-setting"
              name="Switch Table Setting"
              label=""
              checked={this.state.showTableSetting}
              onChange={() => {
                this.setState({
                  showTableSetting:!this.state.showTableSetting
                })
              }}
            />
          )}/>

        <div className="flex mpk-layout mpk-padding-N all">
          <Paper className="flex">
            <Table
              tableActions={tableActions}
              table={table}

              translate={true}
              columns={[
                {label: "word.name", value: "name", isDefaultSort:true, isSortable:true, show:true, isSearchable:true, isDefaultSearchColumn:true},
                {label: "word.email", value: "email", className: "mpk-font-size-S", show:true, isSearchable:true},
                {label: "word.age", value: "age", type: 'number', show:false, isSearchable:true},
                {label: "word.active", value: "active", type: 'boolean', onClick:() => {}, show:true, isSearchable:true}
              ]}
              itemActions={[
                {label:"More Info", iconClassName:"mdi mdi-bell"},
                {label:"divider", iconClassName:"-"},
                {
                  label:"Delete",
                  iconClassName:"mdi mdi-delete",
                  onClick:this.deleteItem,
                  confirmation:{
                    title:"sentence.custom.deleteItem",
                    message:"sentence.custom.deleteItemConfirmation"
                  }
                }
              ]}
              fetchData={this.fetchData}
            />
          </Paper>
          {this.state.showTableSetting ? (
            <div className="flex-none mpk-layout">
              <Empty width={16} className="flex-none"/>
              <Paper className="flex-none">
                <TableSetting
                  onSearch={this.fetchData}
                  translate={true}
                />
              </Paper>
            </div>
          ):(null)}
        </div>

        <UserForm
          visible={this.state.showUserForm}
          add={tableActions.addDataItem}
          onCancel={() => this.setState({showUserForm:false})}
        />

        <DialogConfirm
          title={'word.deletes'}
          message={'sentence.custom.deleteSelectedItems'}
          visible={this.state.showDialogConfirmDeleteSelected}
          onSubmit={(callback) => {
            tableActions.deleteSelectedDataItems();
            callback();
          }}
          onCancel={() => this.setState({showDialogConfirmDeleteSelected:false})}
          translate={true}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  table:state.table
})

const mapDispatchToProps = dispatch => ({
  tableActions:bindActionCreators(tableActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageUser);
