import React, { Component } from 'react';
import { Toolbar, List, ListItem, Avatar, DropdownMenu, FontIcon, TextField, Subheader, Divider} from 'react-md';
import OrganizationService from '../../modules/Main/Administrator/Organization/Organization.service';
import authService from '../../services/auth.service';
import {connect} from 'react-redux';

@connect()
export default class OrganizationSearch extends Component{

  constructor(props) {
    super(props);
    this.state = {
      list: []
    }
  }

  componentDidMount() {
    this.getOrganization()
  }

  async getOrganization(name='') {
    var res = await OrganizationService.api.find({
      'name.contains': name
    });

    this.setState({
      list: res.data
    })

  }

  handleSearch() {
    var input = this.refs.searchfield;
    var val = input.getField().value;

    if(val && val.length > 2) {
      this.getOrganization(val)
    }
    if(val && val.length == 0) {
      this.getOrganization(val)
    }
  }

  handleListItem(d) {
    authService.api.changeOrganization(d, this.props.dispatch)
  }

  render() {
    return (
      <div>
        <div style={{padding: '0 14px'}}>
          <TextField
            ref="searchfield"
            onKeyUp={()=> this.handleSearch()}
            id="main-organization-search-textfield"
            leftIcon={<FontIcon>search</FontIcon>}
          />
          </div>
          <div style={{height: 190, overflow: 'auto'}}>
          <Subheader primaryText="Organization" />
          {this.state.list.map((d) => {
            return (
              <ListItem
                key={d.id}
                onClick={()=> this.handleListItem(d)}
                leftAvatar={<Avatar suffix="blue">{d.name.charAt(0)}</Avatar>}
                rightIcon={<FontIcon>info</FontIcon>}
                primaryText={d.name}
                secondaryText={d.npwp}
              />
            )
          })}
        </div>
      </div>
    )
  }
}
