import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';
import {
  Divider,
  Subheader,
  CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  CardText,
  Tab,
  Tabs,
  SelectionControlGroup,
  Checkbox,
  Button,
  IconSeparator,
  FontIcon,
  List,
  ListItem,
  Avatar,
} from 'react-md';

import FormView from '../../../../../components/entity/form.view';
import {validation, Textfield, Radio, Switch} from '../../../../../components/form/index';
import FormViewSpt from '../../../../../components/entity/form.view.spt';
import { SearchField } from 'react-mpk';
import SettingEmailService from './SettingEmail.service';
import CompanyService from '../../../Administrator/Company/Company.service';
import iziToast from 'izitoast';

@reduxForm({form: 'SettingEmail_Form', destroyOnUnmount: true, initialValues: {}})
@connect((state) => ({
  access: state.entity.access.api.find,
  organization: state.auth.currentOrganization,
  spt: state.auth.currentSPT,
  formData: getFormValues('SettingEmail_Form')(state),
  user: state.auth.user
}))
export default class SettingEmailForm extends FormView {
  service=SettingEmailService
  viewType =2;

  constructor(props){
    super(props)
    this.state = {
      ...this.state
    }
  }

  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;
    var companyId = this.props.match.params.companyId

    if(companyId){
      return (
        <div>
          <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>Save</Button>
        </div>
      )
    } else {
      return (
        <div>
          <Button secondary flat onClick={()=> {this.props.history.goBack()}} style={{marginRight: 16}}>Back</Button>
          <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>Save</Button>
        </div>
      )
    }
  }

  async initData() {
    var companyId = this.props.match.params.companyId
    if(!companyId){
      // Try in url
      var paramInurl = this.props.location.pathname.split("/")
      if(paramInurl[2]){
        companyId = paramInurl[2]
      }
    }

    if(this.props.user.company && this.props.user.company.id) companyId = this.props.user.company.id;
    try {
      let res = await this.service.api.getByCompany(companyId);
      this.props.initialize(res.data);
    } catch (e){
      this.toast.warning({
        title: e.name,
        message: e.message
      })
    }
  }

  async handleSave(value) {
    if(value.port){
      value.port = parseInt(value.port)
    }
    try {
      let res = await this.service.api.updateByCompany(value)

      iziToast.success({
        title: "Setting Email",
        message: "Berhasil di simpan !"
      })
    } catch (e){
      this.toast.warning({
        title: e.name,
        message: e.message
      })
    }
  }

  formView() {
    return (
      <div>
        <Card>
          {/* <div className='md-grid'>
            <div className="md-cell md-cell--12">
              <Field
                label='Company'
                name='company'
                className="md-cell md-cell--12"
                apiPath="page"
                viewField="nama"
                valueField="parent"
                component={Searchfield}
                service={CompanyService}
                validate={validation.required}
              />
            </div>
          </div> */}
          <div className='md-grid'>
            <div className="md-cell md-cell--6">
              <Field
                label='Host'
                name='host'
                component={Textfield}
                validate={validation.required}
              />
              <Field
                label='Port'
                name='port'
                type="number"
                component={Textfield}
                validate={validation.required}
              />
              <Field
                label='Alamat Email'
                name='from'
                component={Textfield}
                validate={validation.required}
              />
              <Field
                label='Protokol'
                name='protocol'
                component={Textfield}
                validate={validation.required}
              />
            </div>

            <div className="md-cell md-cell--6">
              <Field
                label='Username'
                name='username'
                component={Textfield}
              />
              <Field
                label='Password'
                name='password'
                type='password'
                component={Textfield}
              />
              <Field
                label='Enable TLS'
                name='tls'
                component={Switch}
              />
              <Field
                label='Enable Auth'
                name='auth'
                component={Switch}
              />
            </div>

            <div className="md-cell md-cell--12">
              <Field
                label='Use Proxy'
                name='useProxy'
                component={Switch}
              />
            </div>

            {this.props.formData.useProxy &&
              <div className='md-grid' style={{padding: 0, margin: 0, width: '100%'}}>
                <div className="md-cell md-cell--6">
                  <Field
                    label='Proxy Host'
                    name='proxyHost'
                    component={Textfield}
                  />

                  <Field
                    label='Proxy Port'
                    name='proxyPort'
                    type="number"
                    component={Textfield}
                  />

                  <Field
                    component={Radio}
                    controlStyle={{display: 'inline-block'}}
                    name="proxyType"
                    type="radio"
                    key={this.props.formData.proxyType}
                    value={this.props.formData.proxyType}
                    controls={[{
                      label: 'HTTP',
                      value: 'HTTP',
                    }, {
                      label: 'SOCKS',
                      value: 'SOCKS',
                    }]}
                  />
                </div>
                <div className="md-cell md-cell--6">
                  <Field
                    label='Proxy Username'
                    name='proxyUsername'
                    component={Textfield}
                  />

                  <Field
                    label='Proxy Password'
                    name='proxyPassword'
                    type="password"
                    component={Textfield}
                  />
                </div>
              </div>
            }
          </div>
        </Card>
        <br/>
      </div>
    )
  }

}
