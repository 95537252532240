import ApiService from '../../../../../services/api.service';
import http from "../../../../../services/http.service";
class BP21SATUTAHUNService extends ApiService {
  name= 'BP_21_SATU_TAHUN';
  path= 'spt21/st/a';
  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api={
    calculate: async(sptId) => {
      return await this.http.get(`${this.apiLocation}/spt21/st/b/${sptId}/calculate`)
    },
    getBagB: async(sptId)=> {
      return await this.http.get(`${this.apiLocation}/spt21/st/b/${sptId}`)
    },
    updateBagA: async(d)=> {
      return await this.http.put(`${this.apiLocation}/spt21/st/b`, d);
    },
    cetakDaftar: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/spt21/st/a/report/${sptId}`, {
        responseType: 'blob'
      })

      return res;
    },
    cetakBulk: async (ids) => {
      var res = await this.http.post(`${this.apiLocation}/spt21/st/a/report/bulk`, ids, {
        responseType: 'blob'
      })

      return res;
    },
    deleteBulk: async (sptId, ids)=> {
      let res = await http.post(`${this.apiLocation}/${this.path}/${sptId}/delete`, ids);

      return res;
    },
  }
}


export default new BP21SATUTAHUNService();
