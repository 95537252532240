import http from '../../../../services/http.service';

export default {
  api: {
    async get(orgId, type) {
      return await http.get(`/api/spt15/nobupot/${orgId}/organization`);
    },
    async save(data) {
      return await http.post('/api/spt15/nobupot', data);
    },
    async update(data) {
      return await http.put('/api/spt15/nobupot', data);
    },
  }
}
