import React, { Component } from 'react'; import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {TabsContainer, Tabs, Tab} from 'react-md';
import { Field } from 'redux-form';

import ApplicationService from './Application.service';
import ListView from '../../../../../components/entity/ListViewEbupot';
import AuthService from '../../../../../services/auth.service'
import FormDialog from './Application.form.view'
import ListViewEbupot from '../../../../../components/entity/ListViewEbupot';

@connect((state)=> ({
  ...ApplicationService.stateConnectSetting()(state)
}), ApplicationService.actionConnectSetting())
export default class ApplicationView extends ListViewEbupot {
  service=ApplicationService;

  rootPath = this.props.match.url
  editDialog = false;
  addDialog = false;

  columns=ApplicationService.columns

  _tableActions = [
    {
      label:"Add Client",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.handleAddClient(item)
    },
    {
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    },
    {label:"divider", iconClassName:"-"},
    {
      label:"Delete",
      iconClassName:"mdi mdi-delete",
      onClick:(item, callback) => this.deleteItem(item, callback),
      confirmation:{
        title:"sentence.custom.deleteItem",
        message:"sentence.custom.deleteItemConfirmation"
      }
    }
  ]

  handleAddClient = (item) => {
    this.props.history.push('client/'+item.id);
  }

}
