import ApiService from '../../../../../services/api.service';
import iziToast from 'izitoast';

class SPT2126Service extends ApiService {
  name= 'SPT_21';
  path= 'spt21';
  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api = {
    pembetulan: async(id, dispatch=()=>{})=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/pembetulan/${id}`)

      return res;
    },

    pembetulanBulk: async(ids, dispatch=()=>{})=> {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/pembetulan`, ids)

      return res;
    },

    cancelPembetulanBulk: async(ids, dispatch=()=>{})=> {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/pembetulan/cancel`, ids)

      return res;
    },

    downloadLapor: async(ids, dispatch=()=>{})=> {
      var res = await this.http.post(`${this.apiLocation}/upload/download`, ids,{
        responseType: 'blob'
      })

      return res;
    },

    laporAll: async(data) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/lapor`, data)

      return res;
    },

    laporAllWithSummary: async(data) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/lapor/summary`, data)

      return res;
    },

    laporAllWithSummaryParam: async(data) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/lapor/summary/param`, {params: data})

      return res;
    },

    pengajuanApprovalBulk: async(data, dispatch=()=>{})=> {
      var res = await this.http.post(`${this.apiLocation}/mainFlow/pengajuan/bulk`, data)

      return res;
    },

    lapor: async(data) => {
      var res = await this.http.post(`${this.apiLocation}/efiling/upload/csv`, data)

      return res;
    },

    sendEmailToProfile: async(data) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/email/company`, data)

      return res;
    },
  }
}


export default new SPT2126Service();
