import React, { Component } from 'react'; import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {TabsContainer, Tabs, Tab} from 'react-md';
import { Field } from 'redux-form';

import Bupot26EmailService from './Bupot26Email.service';
import ListView from '../../../../../components/entity/ListViewEbupot';
import AuthService from '../../../../../services/auth.service'
import ListViewEbupot from '../../../../../components/entity/ListViewEbupot';

@connect((state)=> ({
  ...Bupot26EmailService.stateConnectSetting()(state)
}), Bupot26EmailService.actionConnectSetting())
export default class Bupot26EmailView extends ListViewEbupot {
  service=Bupot26EmailService;

  rootPath = this.props.match.url
  editDialog = false;
  addDialog = false;

  columns=Bupot26EmailService.columns

  fetchOption() {
    const historyId = this.props.match.params.id
    return {
      path: `${Bupot26EmailService.path}/${historyId}/log/email`
    }
  }

  _tableActions = [
    // {
    //   label:"More Info",
    //   iconClassName:"mdi mdi-bell",
    //   onClick: (item) => this.editItem(item)
    // },
    // {label:"divider", iconClassName:"-"},
    // {
    //   label:"Delete",
    //   iconClassName:"mdi mdi-delete",
    //   onClick:(item, callback) => this.deleteItem(item, callback),
    //   confirmation:{
    //     title:"sentence.custom.deleteItem",
    //     message:"sentence.custom.deleteItemConfirmation"
    //   }
    // }
  ]

  backHistory(){
    this.props.history.goBack()
  }

  _barActions = [
    {
      label:'word.back',
      iconClassName:'mdi mdi-arrow-left',
      onClick:() => this.backHistory()
    },
    // {
    //   label:'word.delete',
    //   iconClassName:'mdi mdi-delete',
    //   onClick:() => {
    //     this.setState({showDialogConfirmDeleteSelected:true})
    //   },
    //   disabledFunc:() => this.props.table.selected.length === 0
    // },
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => this.fetchData()
    },
  ]

}
