import React, { Component } from 'react';
import {Grid, Cell, TableHeader, TableRow, TableColumn, TableBody, DataTable} from 'react-md';
import { Field, reduxForm , propTypes, formValueSelector } from 'redux-form';
import {validation, Textfield, Searchfield, Switch, TextfieldMask} from '../../../../components/form';
import DialogView from '../../../../components/entity/dialog.view';
import MasterDefaultImportHeaderService from '../MasterDefaultImportHeader/MasterDefaultImportHeader.service';
import MasterCustomImportHeaderDetailService from '../MasterCustomImportHeaderDetail/MasterCustomImportHeaderDetail.service';
import MasterCustomImportService from './MasterCustomImport.service';
import MasterImportCategoryService from '../MasterImportCategory/MasterImportCategory.service';
import {connect} from "react-redux";

@connect(state => ({
  isLoginIn: state.auth.isLoginIn,
  user: state.auth.user,
  menuType: state.auth.menuType,
  company: state.auth.currentCompany
}))
@reduxForm({form: 'mastercustomimport', destroyOnUnmount: false})
export default class MasterCustomImportDialogView extends DialogView {
  service=MasterCustomImportService
  initialData = {
    header: {
      multiple: true
    },
    details: []
  }

  constructor(props) {
    super(props);

    this.state= {
      ...this.state,
      details: []
    }
  }

  initData(props) {
    let id = props.formData.id

    if(id) {
      let data = {
        header: props.formData
      }

      MasterCustomImportHeaderDetailService.api.findAllByHeaderId(id)
        .then((res) => {
          data.details = res.data

          this.props.initialize(data);

          this.setState({details: res.data})
        })
    } else {
      this.props.initialize(this.initialData);
    }
  }

  async handleSave(callback, value) {
    let stateDetails = this.state.details
    let valueDetails = value.details

    stateDetails.forEach((stateDetail, index) => {
      if (!valueDetails[index]) {
        valueDetails[index] = {}
      }

      stateDetails[index].urutan = valueDetails[index].urutan
      stateDetails[index].header = valueDetails[index].header
    })

    value.details = stateDetails

    var newVal = this.transform(value);

    try {
      if(value.header.id) {
        await this.service.api.update(newVal)
        this.props.change(this.props.form, value)
      } else {
        await this.service.api.save(newVal)
        this.props.reset(this.props.form)
      }

      callback()
      this.props.onSuccess()
      this.props.onClose()
    } catch(e) {
      var msg = e.message;
      if(e.response) msg = e.response.data.message
      callback(true, msg)
    }
  }

  formView() {
    return (
      <Grid style={{padding:'none'}}>
        <Field
          label     = "Kategori"
          name      = "header.masterImportCategory"
          id        = "kategori"
          className = "md-cell md-cell--12"
          valueField = 'parent'
          viewField = 'label'
          params     = {{
            size: 50
          }}
          remoteSearch = {true}
          service      = {MasterImportCategoryService}
          searchField  = {['label']}
          component    = {Searchfield}
          itemTemplate = {function(d) {
            return {
              primaryText  : d.label,
              secondaryText: 'Pasal ' + d.masterPasal.name,
              onClick      : () => {
                this.handleItemClick(d); this.hide()
              }
            }
          }}
          onChange={(e, v) => {
            if (v) {
              if (v.id) {
                MasterDefaultImportHeaderService.api.findAllByCategoryId(v.id)
                  .then(res => {
                    let masterDefaultImportHeaders = res.data
                    let details = []

                    this.setState({details})

                    masterDefaultImportHeaders.forEach(masterDefaultImportHeader => {
                      details.push({
                        urutan: null,
                        header: null,
                        masterDefaultImportHeader: masterDefaultImportHeader,
                        masterCustomImportHeader: null,
                      })
                    })

                    this.setState({details})
                  })

              }
            }

          }}
          validate={validation.required}
        />

        <Field
          label='Name'
          name='header.name'
          id="header-name"
          className="md-cell md-cell--12"
          component={Textfield}
          validate={validation.required}
        />

        <Field
          label='Multiple'
          name='header.multiple'
          component={Switch}
        />

        <DataTable plain >
          <TableHeader>
            <TableRow>
              <TableColumn>Urutan Default</TableColumn>
              <TableColumn>Header Default</TableColumn>
              <TableColumn>Urutan Custom</TableColumn>
              {/*<TableColumn>Header Custom</TableColumn>*/}
            </TableRow>
          </TableHeader>
          <TableBody>
            {
              this.state.details.map((detail, index) => {
                let nameUrutan = 'details[' + index + '].urutan'
                let nameHeader = 'details[' + index + '].header'

                return <TableRow>
                  <TableColumn>{detail.masterDefaultImportHeader.urutan}</TableColumn>
                  <TableColumn>{detail.masterDefaultImportHeader.header.replace(/_/g, ' ')}</TableColumn>
                  <TableColumn>
                    <Field
                      label=''
                      name={nameUrutan}
                      className="md-cell md-cell--12"
                      component={Textfield}
                    />
                  </TableColumn>
                  {/*<TableColumn>
                    <Field
                      label=''
                      name={nameHeader}
                      className="md-cell md-cell--12"
                      component={Textfield}
                    />
                  </TableColumn>*/}
                </TableRow>
              })
            }
          </TableBody>
        </DataTable>
      </Grid>
    )
  }
}
