import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Button, Chip, Switch} from 'react-md';

//import FormDialog from './Organization.dialog.view';
import ListView from '../../../../../../components/entity/listView';
import UtilService from '../../../../../../services/utils.service';
import download from 'downloadjs';
import uuid from 'uuid';
import iziToast from 'izitoast';
import Tarif15Service from './Tarif15.service';

@connect((state)=> ({
  ...Tarif15Service.stateConnectSetting()(state),
  spt: state.auth.currentSPT,
  company: state.auth.currentCompany
}), Tarif15Service.actionConnectSetting())
export default class Tarif15View extends ListView {
  service=Tarif15Service
  FormDialog=()=> <div/>
  rootPath = '/company/tarif-15'
  addDialog=false
  editDialog=false

  fetchOption() {
    return {
      path: Tarif15Service.path+'/'+this.props.company.id+'/company'
    }
  }

  columns=[
    // {isSortable: true, label: "word.company",  value: "company.nama", show:true, isSearchable:true},
    {isSortable: true, label: "word.startDate",  value: "start", show:true, isSearchable:true},
    {isSortable: true, label: "word.endDate",  value: "end", show:true, isSearchable:true},
  ]

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='Dimulai'
          name='start__equals'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Selesai'
          name='end__equals'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
      </this.SearchContainer>
    )
  }
}
