import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Divider,
  Chip,
  Card,
  CardTitle,
  CardActions,
  TextField as Textfield2,
  Switch
} from 'react-md';
import { Field, reduxForm } from 'redux-form';
import UtilService from '../../../../../services/utils.service';
import {validation, Textfield, Searchfield, TextfieldMask} from '../../../../../components/form';
import download from 'downloadjs';
import uuid from 'uuid';
import iziToast from 'izitoast';

//import FormDialog from './Organization.dialog.view';
import BP21SATUTAHUNService from './BP_21_SATU_TAHUN.service';
import ListView from '../../../../../components/entity/listView';

@reduxForm({form: 'BP_21_SATU_TAHUNN', destroyOnUnmount: false})
@connect((state)=> ({
  ...BP21SATUTAHUNService.stateConnectSetting()(state),
  spt: state.auth.currentSPT,
  formData: state.form.BP_21_SATU_TAHUNN
}), BP21SATUTAHUNService.actionConnectSetting())
export default class BP21SATUTAHUNView extends ListView {
  service=BP21SATUTAHUNService
  FormDialog=()=> <div/>

  constructor(props){
    super(props);
    this.state = {
      ...this.state,
      cetak: false,
    }
  }

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='Nama'
          name='nama__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='NPWP'
          name='npwp__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Kode Pajak'
          name='code__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Pembuat'
          name='createdUserLogin__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
      </this.SearchContainer>
    )
  }

  addDialog=false
  editDialog=false

  viewType=2

  fetchOption() {
    return {
      path: BP21SATUTAHUNService.path+'/'+this.props.spt.data.id+'/page'
    }
  }

  columns=[
    {isSortable: true, label: "word.npwp", value: "npwp", show:true, isSearchable:true},
    {isSortable: true, label: "word.name", value: "nama", show:true, isSearchable:true},
    {isSortable: true, label: "word.taxCode", value: "code", show:true, isSearchable:true},
    {isSortable: true, label: "word.bruto", value: "bruto", show:true, isSearchable:true, type: 'number'},
    {isSortable: true, label: "word.pph", value: "pph", show:true, isSearchable:true, type: 'number'},
    {isSortable: true, label: "word.date",  value: "date", show:true, isSearchable:true},
  ]

  componentDidMount() {
    this.rootPath = this.props.match.url;
    this.fetchB()
  }


  async fetchB() {
    if(this.props.spt.data.id) {
      let res = await BP21SATUTAHUNService.api.getBagB(this.props.spt.data.id);
      this.props.initialize(res.data);
    }
  }

  async handleSave(value) {
    await BP21SATUTAHUNService.api.updateBagA(value);
    iziToast.success({title: 'Data', message: 'berhasil di simpan'})
  }

  async handleCalculate() {
    var res = await BP21SATUTAHUNService.api.calculate(this.props.spt.data.id);
    if(res.data) {
      this.props.change('count', res.data.count)
      this.props.change('bruto', res.data.bruto)
    }

    iziToast.success({title: 'Data', message: 'berhasil di kalkulasi'})
  }

  _tableActions = []

  async handleCetakDaftar(){
    this.setState({ cetakDaftar: true })
    try {
      var sptId = this.props.spt.data.id
      var report = await this.service.api.cetakDaftar(sptId)
      var filename = report.headers.filename
      download(report.data, filename);
      this.setState({ cetakDaftar: false })
    } catch (e) {
      const reader = new FileReader();

      /// This fires after the blob has been read/loaded.
      reader.addEventListener('loadend', (e) => {
        const text = e.srcElement.result
        let json = JSON.parse(text)

        if (json.detail)
          iziToast.error({
            title: 'Error',
            message: json.detail
          })
      });

      reader.readAsText(e.response.data)

      this.setState({cetakDaftar: false})
    }
  }

  _barActions = []

  async deleteSelectedDataItems(){
    var selected = this.props.table.selected

    var ids = [];

    selected.map(function (i){
      ids.push(i.id)
    })

    const sptId = this.props.match.params.sptId

    var res = await this.service.api.deleteBulk(sptId, ids)

    this.fetchData()
  }

  _barItem() {
    return (
      <div>
        <Button disabled={this.state.cetakDaftar} primary raised style={{margin: 5}} onClick={this.handleCetakDaftar.bind(this)}>Cetak Daftar</Button>
        <Switch
          style={{float: "right"}}
          id="switch-table-setting"
          name="Switch Table Setting"
          label=""
          checked={this.state.showTableSetting}
          onChange={() => {
            this.setState({
              showTableSetting:!this.state.showTableSetting
            })
          }}
        />
      </div>
    )
  }

  header() {
    return (
      <div>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle='A. PEGAWAI TETAP DAN PENERIMA PENSIUN ATAU THT/JHT SERTA PNS, ANGGOTA TNI/POLRI, PEJABAT NEGARA DAN PENSIUNANNYA YANG PENGHASILANNYA MELEBIHI PENGHASILAN TIDAK KENA PAJAK (PTKP)
' />
        <Divider/>
      </div>
    )
  }

  totalBruto() {
    return this.state.data.reduce((d, v)=> (d+v.bruto), 0);
  }

  totalPph() {
    return this.state.data.reduce((d, v)=> (d+v.pph), 0);
  }

  footer() {
    return <div className="flex-none mpk-border thin solid top dark">
      <div className="mpk-padding-N left right">
        <table style={{width: '100%'}}>
          <tfoot className="md-table-footer">
            <tr className="md-table-column md-text-left md-table-column--data md-table-column--adjusted md-text ">
              <td>Total Jumlah Penghasilan Bruto</td>
              <td className=" md-text-right">{UtilService.number.format(this.totalBruto(), {precision: 0, thousand: '.', decimal: ','})}</td>
            </tr>
            <tr className="md-table-column md-text-left md-table-column--data md-table-column--adjusted md-text ">
              <td>Total Jumlah PPH Dipotong</td>
              <td className=" md-text-right">{UtilService.number.format(this.totalPph(), {precision: 0, thousand: '.', decimal: ','})}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  }

  additionalContent() {
    const { handleSubmit, submitting, valid, pristine } = this.props;
    var formData = {};
    if(this.props.formData && this.props.formData.values) formData = this.props.formData.values
    let FINISHED = false;
    if(this.props.spt.data.status === "FINISH"){
      FINISHED = true
    }

    return (
      <div>
        <br/>
        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='B. PEGAWAI TETAP DAN PENERIMA PENSIUN ATAU THT/JHT SERTA PNS, ANGGOTA TNI/POLRI, PEJABAT NEGARA
DAN PENSIUNANNYA YANG PENGHASILANNYA TIDAK MELEBIHI PTKP' />
          <Divider/>
          <div className='md-grid'>
            <div className="md-cell md-cell--6">
              <Field
                label='Jumlah Pegawai'
                name='count'
                className='md-text-right'
                component={TextfieldMask}
                money={true}
                disabled={true}
              />
            </div>

            <div className="md-cell md-cell--6">
              <Field
                label='Bruto Pegawai'
                name='bruto'
                className='md-text-right'
                component={TextfieldMask}
                money={true}
                disabled={true}
              />
            </div>
          </div>

        </Card>

        <br/>
        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='C. TOTAL (JUMLAH A + B)' />
          <Divider/>
          <div className='md-grid'>
            <div className="md-cell md-cell--6"></div>
            <div className="md-cell md-cell--6">
              <Textfield2
                label='Total Jumlah Penghasilan Bruto'
                name='totalJumlahPenghasilanBruto'
                value={UtilService.number.format(this.totalBruto()+parseInt(formData.bruto), {precision: 0, thousand: '.', decimal: ','})}
                // validate={validation.required}
              />
              <Textfield2
                label='Total Jumlah PPh Di Potong'
                name='totalJumlahPenghasilanDipotong'
                value={UtilService.number.format(this.totalPph(), {precision: 0, thousand: '.', decimal: ','})}
                // validate={validation.required}
              />
            </div>
          </div>
        </Card>
      </div>
    )

  }

}
