import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-md';
import SettingViewService from './SettingView.service';
import ListView from '../../../../components/entity/listView';
import ColumnService from '../../../../services/column.service';

@connect(SettingViewService.stateConnectSetting(), SettingViewService.actionConnectSetting())
export default class SettingViewView extends ListView {
  service=SettingViewService
  editDialog=false

  _barActions = [
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => this.fetchData()
    },
    {
      label:'word.delete',
      iconClassName:'mdi mdi-delete',
      onClick:() => {
        this.setState({showDialogConfirmDeleteSelected:true})
      },
      disabledFunc:() => this.props.table.selected.length === 0
    },
  ]

  editItem = (item) => {
    this.props.history.push("SETTING_VIEW"+"/"+item.company.id)
  }

  columns=[
    {isSortable: true, label: "word.id", searchField: "id", value: function(d){ return d.id }, type: "func",  show:false, isSearchable:true},
    {isSortable: true, label: "word.company", searchField: "company", value: function(d){ return d.company.nama }, type: "func",  show:true, isSearchable:true},
    {isSortable: true, label: "word.viewPerUser", type: "func", value: (d) => {
      if (d.viewPerUser) {
        return <Button flat swapTheming style={{backgroundColor: '#4caf50', color: 'white'}}>Ya</Button>
      }

      return <Button flat swapTheming style={{backgroundColor: '#B71C1C', color: 'white'}}>Tidak</Button>
    }, show: true, isSearchable: true},
  ]
}
