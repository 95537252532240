import React, { Component } from 'react';
import {
  Checkbox
} from 'react-md'
import izitoast from 'izitoast';
import { connect } from 'react-redux';
import ListView from '../../../../components/entity/listView';
import MainFlowService from './MainFlow.service';

@connect((state)=> ({
  ...MainFlowService.stateConnectSetting()(state),
  user: state.auth.user
}), MainFlowService.actionConnectSetting())
export default class MainFlowView extends ListView {
  service=MainFlowService
  FormDialog=()=>(<div/>)

  editDialog=false
  addDialog=false
  apiPath='page'

  beforeFetch(params) {
    if(this.props.user.company) {
      params.companyId = this.props.user.company.id
    }
  }

  async setDefault(d) {
    try {
      await MainFlowService.api.setDefault(d);
      await this.fetchData()
      izitoast.info({title: 'Success', message: `Flow dengan nama '${d.name}' telah di set default` });


    } catch (error) {

    }

  }

  columns=[
    {isSortable:true, label: "word.name",  value: "name", isDefaultSort:true, show:true, isSearchable:true},
    {isSortable:true, label: "word.company", value: "company.nama", className: "mpk-font-size-S", show:true, isSearchable:true},
    // {isSortable:true, label: "word.organization", value: "organization.name", className: "mpk-font-size-S", show:true, isSearchable:true},
    {isSortable:true, label: "word.default", value: (d)=> {
      // if(d.useDefault) {
        //
        return <Checkbox checked={d.useDefault} onClick={()=> {
          this.setDefault(d)
        }} value='asdad' />
      // }
    }, className: "mpk-font-size-S", show:true, isSearchable:true, type:'func'},
    {isSortable:true, label: "word.type", value: "formType", className: "mpk-font-size-S", show:false, isSearchable:true},
    {isSortable:true, label: "word.description", value: "description", className: "mpk-font-size-S", show:true, isSearchable:true}
  ]
}
