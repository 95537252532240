import ApiService from '../../../services/api.service';

class ValidationCsvService extends ApiService {
  name= 'validationCsv';
  path= 'validation-csv/page';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api={
    downloadCsv: async (uploadId)=> {
      var res = await this.http.get(`${this.apiLocation}/validation-csv/download/${uploadId}`, {
        responseType: 'blob'
      })

      return res;
    },
  }
}


export default new ValidationCsvService();
