import React, { Component } from 'react';
import { Field, reduxForm , propTypes, formValueSelector } from 'redux-form';
import { validation, Textfield, TextfieldMask, Datepicker, convert } from '../../../../../../components/form';
import ImportDialogTemplateView from '../../../../../../components/entity/Import.dialog.template.view';
import ImportSigner15Service from './ImportSigner15.service';
ImportDialogTemplateView


@reduxForm({form: 'ImportSigner15-form', destroyOnUnmount: false})
export default class ImportSigner15DialogView extends ImportDialogTemplateView {
  service=ImportSigner15Service
}
