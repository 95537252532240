import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux';
import { Field, reduxForm, getFormValues} from 'redux-form';
import moment from 'moment';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  CardActions,
  Button,
  LinearProgress
} from 'react-md';
import {validation, Textfield, Searchfield, Searchfieldv2, TextfieldMask, Switch, Datepicker, Checkbox} from '../../../../../../../components/form'
import JenisDokumenService from '../../../JenisDokumen/JenisDokumen.service';
import { Dialog, DialogContainer } from 'react-mpk';
import { resolve } from 'url';
import iziToast from 'izitoast';

@reduxForm({form: 'Bupot26InEdit_Form_Dokumen', destroyOnUnmount: true})
@connect((state) => ({
  //access      : state.entity.access.api.find,
  //organization: state.auth.currentOrganization,
  spt         : state.auth.currentSPT,
  formData    : getFormValues('Bupot26InEdit_Form_Dokumen')(state),
  formData26  : getFormValues('Bupot26InEdit_Form')(state)
}))
export default class Bupot26InEditInDokumenForm extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            data: [],
            open: false,
            editIndex: null
        }
    }

    async componentDidUpdate(prevProps, prevState){
        var formDataParentNew = this.props.formDataParent
        var formDataParentOld = prevProps.formDataParent
        if(formDataParentOld != formDataParentNew){
            if(formDataParentNew){
                var newData = formDataParentNew
                let newDataGet = Promise.all(newData.map((d)=> {
                    return new Promise(async (resolve, reject)=> {
                        try {
                            var getJenis = await JenisDokumenService.api.findOne(d.jenis)
                            d.jenisDokumen = getJenis.data
                        } catch(e){}
                        resolve(d)
                    })
                }))
                await newDataGet.then((values)=> {
                    this.setState({ data: newData })
                }).catch((error)=> {
                    this.setState({ data: newData })
                })
            }
        }
    }

    static contextTypes = {
        showDialog: PropTypes.func.isRequired,
    };

    async hide(){
        this.setState({ open: false, editIndex: null })
        this.props.initialize({
            jenis: null,
            noDok: null,
            tgl: null
        })
    }

    async handleForm(){
        this.setState({ open: true })
    }

    async handleHapus(index){
        var data = this.state.data
        data.splice(index, 1)
        this.props.parent.props.change('refs', data)
        this.setState({ data: data })
    }

    async handleSave(values){
        var valid = false
        try {
            var data = this.state.data
            var newData = Object.assign([], data)
            if(this.state.editIndex || this.state.editIndex == 0){
                newData[this.state.editIndex] = values
            } else {
                newData.push(values)
            }
            let newDataGet = Promise.all(newData.map((d)=> {
                return new Promise(async (resolve, reject)=> {
                    try {
                        var getJenis = await JenisDokumenService.api.findOne(d.jenis)
                        d.jenisDokumen = getJenis.data
                    } catch(e){}
                    resolve(d)
                })
            }))
            await newDataGet.then((values)=> {
                this.setState({ data: newData })
            }).catch((error)=> {
                this.setState({ data: newData })
            })
            this.props.parent.props.change('refs', newData)
            this.hide()
            // var split = values.tgl.split('-')
            // var tahun = split[0]
            // var bulan = split[1]
            // var tahunValidasi = this.props.spt.data.tahun + ""
            // var masaValidasi = this.props.spt.data.masa + ""
            // if(bulan.split('')[0] == "0"){
            //     bulan = bulan.split('')[1]
            // }
            // if(tahunValidasi != tahun){
            //     iziToast.info({
            //         title: "Validasi",
            //         message: "Tahun dokumen tidak sesuai dengan tahun SPT seharusnya tahun = " + tahunValidasi
            //     })
            // } else if(masaValidasi != bulan){
            //     iziToast.info({
            //         title: "Validasi",
            //         message: "Masa dokumen tidak sesuai dengan masa SPT seharusnya masa = " + masaValidasi
            //     })
            // } else {
            //     var data = this.state.data
            //     var newData = Object.assign([], data)
            //     if(this.state.editIndex || this.state.editIndex == 0){
            //         newData[this.state.editIndex] = values
            //     } else {
            //         newData.push(values)
            //     }
            //     let newDataGet = Promise.all(newData.map((d)=> {
            //         return new Promise(async (resolve, reject)=> {
            //             try {
            //                 var getJenis = await JenisDokumenService.api.findOne(d.jenis)
            //                 d.jenisDokumen = getJenis.data
            //             } catch(e){}
            //             resolve(d)
            //         })
            //     }))
            //     await newDataGet.then((values)=> {
            //         this.setState({ data: newData })
            //     }).catch((error)=> {
            //         this.setState({ data: newData })
            //     })
            //     this.props.parent.props.change('refs', newData)
            //     this.hide()
            // }
        } catch(e) {
        }
    }

    async handleEdit(d, i){
        var data = d
        await this.props.initialize(data)
        this.setState({ editIndex: i })
        this.handleForm()
    }

    render(){
        var datas = this.state.data
        return (
            <div className="md-grid">
                <DialogContainer title="Dokumen Referensi" height={600} width={800} onHide={this.hide.bind(this)} visible={this.state.open}>
                    <div className="md-grid">
                        <Field
                            label='Nama Dokumen'
                            name='jenis'
                            className="md-cell md-cell--12"
                            component={Searchfieldv2}
                            valueField='id'
                            viewField='name'
                            remoteSearch
                            service={JenisDokumenService}
                            validate={validation.required}
                            renderFirst={true}
                        />
                        <Field
                            label='No. Dokumen'
                            name='noDok'
                            className="md-cell md-cell--12"
                            component={Textfield}
                            validate={validation.required}
                        />
                        <Field
                            label='Tanggal'
                            name='tgl'
                            className="md-cell md-cell--12"
                            component={Datepicker}
                            inline={true}
                            validate={validation.required}
                            normalize={(e)=> moment(e).format('YYYY-MM-DD')}
                        />
                        <br />
                        <Button raised primary onClick={this.props.handleSubmit(this.handleSave.bind(this))}>Simpan</Button>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>
                </DialogContainer>
                <div className="md-cell--12">
                    <Button primary raised onClick={this.handleForm.bind(this)} >Tambah Data</Button>
                    <br />
                    <DataTable plain>
                        <TableHeader>
                            <TableRow>
                                <TableColumn>Nama Dokumen</TableColumn>
                                <TableColumn>No. Dokumen</TableColumn>
                                <TableColumn>Tanggal</TableColumn>
                                <TableColumn></TableColumn>
                                <TableColumn></TableColumn>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {datas.map((d, i)=> {
                                var jenisName = ""
                                if(d.jenisDokumen && d.jenisDokumen.name){
                                    jenisName = d.jenisDokumen.name
                                } else {
                                    jenisName = d.jenis
                                }
                                return (
                                    <TableRow key={i}>
                                        <TableColumn>{jenisName}</TableColumn>
                                        <TableColumn>{d.noDok}</TableColumn>
                                        <TableColumn>{moment(new Date(d.tgl)).format('DD-MM-YYYY')}</TableColumn>
                                        <TableColumn>
                                            <div className="md-grid" style={{ width: 120 }}>
                                                <div className="md-cell md-cell--6">
                                                    <Button icon secondary raised onClick={this.handleEdit.bind(this, d, i)}>edit</Button>
                                                </div>
                                                <div className="md-cell md-cell--6">
                                                    <Button icon primary raised onClick={this.handleHapus.bind(this, i)}>delete</Button>
                                                </div>
                                            </div>
                                        </TableColumn>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </DataTable>
                    <hr />
                    <p>Menampilkan {this.state.data.length}</p>
                </div>
            </div>
        )
    }
}
