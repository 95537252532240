import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Button, SVGIcon } from 'react-md';
import ALLBP21A1Service from './ALL_BP_21_A1.service';
import BP21A1Service from '../BP_21_A1/BP_21_A1.service';
import ListView from '../../../../../components/entity/listView';
import ColumnService from '../../../../../services/column.service';
import {Switch} from 'react-md';
import iziToast from 'izitoast';
import download from "downloadjs";
import LogExportService from "../../../Export/LogExport.service";
import ParamService from "../../../../../services/param.service";
import {Checkbox} from "../../../../../components/form";
import {navService} from 'react-mpk';
import authService from "../../../../../services/auth.service";
import {initialize} from "redux-form";

@connect(ALLBP21A1Service.stateConnectSetting(), ALLBP21A1Service.actionConnectSetting())
export default class ALLBP21A1View extends ListView {
  service=ALLBP21A1Service

  constructor(props){
    super(props);
    this.state = {
      showTableSetting:false,
      showDialogConfirmDeleteSelected:false,
      showForm:false,
      formData: {},
      data: []
    }

    authService.api.changeMenuType('main', this.props.dispatch);
  }

  componentDidMount() {
    var initParam = Object.keys(this.props.filterObj).reduce((pv, key) => {
      var newKey = key.replace('.', '__')
      pv[newKey] = this.props.filterObj[key]
      return pv;
    }, {})

    this.props.dispatch(initialize(this.service.name + "_search", initParam))
  }

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='Nama'
          name='ltNama__equals'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='NPWP'
          name='ltNpwp__equals'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Email'
          name='ltEmail__equals'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='NIK'
          name='ltNik__equals'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Nomor Bukti Potong'
          name='h01__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Kode Pajak'
          name='kodeObjekPajak__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='NPWP Organisasi'
          name='orgNpwp__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Dari Bulan'
          name='masa__greaterOrEqualThan'
          className="md-cell md-cell--6"
          component={this.ig.Searchfield}
          options={[
            {id: 1, name: "1"},
            {id: 2, name: "2"},
            {id: 3, name: "3"},
            {id: 4, name: "4"},
            {id: 5, name: "5"},
            {id: 6, name: "6"},
            {id: 7, name: "7"},
            {id: 8, name: "8"},
            {id: 9, name: "9"},
            {id: 10, name: "10"},
            {id: 11, name: "11"},
            {id: 12, name: "12"}
          ]}
        />
        <this.ig.Field
          label='Sampai Bulan'
          name='masa__lessOrEqualThan'
          className="md-cell md-cell--6"
          component={this.ig.Searchfield}
          options={[
            {id: 1, name: "1"},
            {id: 2, name: "2"},
            {id: 3, name: "3"},
            {id: 4, name: "4"},
            {id: 5, name: "5"},
            {id: 6, name: "6"},
            {id: 7, name: "7"},
            {id: 8, name: "8"},
            {id: 9, name: "9"},
            {id: 10, name: "10"},
            {id: 11, name: "11"},
            {id: 12, name: "12"}
          ]}
        />

        <this.ig.Field
          label='Bulan'
          name='masa__equals'
          className="md-cell md-cell--12"
          component={this.ig.Searchfield}
          options={[
            {id: 1, name: "1"},
            {id: 2, name: "2"},
            {id: 3, name: "3"},
            {id: 4, name: "4"},
            {id: 5, name: "5"},
            {id: 6, name: "6"},
            {id: 7, name: "7"},
            {id: 8, name: "8"},
            {id: 9, name: "9"},
            {id: 10, name: "10"},
            {id: 11, name: "11"},
            {id: 12, name: "12"}
          ]}
        />

        <this.ig.Field
          label='Tahun'
          name='tahun__equals'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Pembetulan'
          name='pembetulan__equals'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Pembuat'
          name='createdUserLogin__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          className="md-cell md-cell--12"
          label     = 'PROCESS'
          name      = 'statusProcess__equals'
          component = {Checkbox}
        />
        <this.ig.Field
          className="md-cell md-cell--12"
          label     = 'WAITING'
          name      = 'statusWaiting__equals'
          component = {Checkbox}
        />
        <this.ig.Field
          className="md-cell md-cell--12"
          label     = 'FINISH'
          name      = 'statusFinish__equals'
          component = {Checkbox}
        />
      </this.SearchContainer>
    )
  }

  editDialog = false;
  _barActions = [];

  editItem = (item) => {
    let sptId = item.spt21.id
    let id = item.id

    navService.redirectTo(`/SPT_21/${sptId}/open/BP_21_A1/${id}`)
  }

  async handleSendEmail(){
    try {
      let category = "BP_21_A1"
      var selected = this.props.table.selected
      var ids = [];
      selected.map(function (i){
          ids.push(i.id)
      })
      await this.service.api.email(category, ids)
      iziToast.info({
        title: "Kirim Email",
        message: "email sedang diproses silahkan cek log untuk lebih detil"
      })
    } catch (e){
      iziToast.warning({
        title: e.name,
        message: e.message
      })
    }
  }

  async handleCetak() {
    this.setState({cetak: true})

    let selected = this.props.table.selected

    if (selected.length > 0) {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export PDF',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: 'Apakah anda yakin akan meng-export PDF data yang diceklis?'
      }));

      if(dialog) {
        try {
          let ids = [];

          selected.map(function (i) {
            ids.push(i.id)
          });

          let res = await LogExportService.api.exportBulkReport('spt21/a1', ids);

          let filename = res.headers.filename;

          download(res.data, filename);

          this.setState({cetak: false})
        } catch (e) {
          const reader = new FileReader();

          /// This fires after the blob has been read/loaded.
          reader.addEventListener('loadend', (e) => {
            const text = e.srcElement.result
            let json = JSON.parse(text)

            if (json.detail)
              iziToast.error({
                title: 'Error',
                message: json.detail
              })
          });

          reader.readAsText(e.response.data)

          this.setState({cetak: false})
        }
      }
    }
    else {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export PDF',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: (
          <div>
            <this.ig.Field
              label='Nama'
              name='h04__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='NPWP'
              name='h03__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Nomor Bukti Potong'
              name='h01__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='NPWP Organisasi'
              name='orgNpwp__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Bulan'
              name='masa__equals'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Tahun'
              name='tahun__equals'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Pembetulan'
              name='pembetulan__equals'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Kode Pajak'
              name='kodeObjekPajak__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
          </div>
        )
      }));

      if (dialog === false)
        return

      if (dialog === undefined)
        dialog = {}

      if (dialog) {
        try {
          let param = ParamService.convert(dialog)

          let res = await LogExportService.api.exportByParamReport('spt21/a1', param);

          let filename = res.headers.filename;

          download(res.data, filename);

          this.setState({cetak: false})
        } catch (e) {
          const reader = new FileReader();

          /// This fires after the blob has been read/loaded.
          reader.addEventListener('loadend', (e) => {
            const text = e.srcElement.result
            let json = JSON.parse(text)

            if (json.detail)
              iziToast.error({
                title: 'Error',
                message: json.detail
              })
          });

          reader.readAsText(e.response.data)

          this.setState({cetak: false})
        }
      }
    }
  }

  async handleCetakMerge() {
    this.setState({cetak: true})

    let selected = this.props.table.selected

    if (selected.length > 0) {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export PDF Merge',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: 'Apakah anda yakin akan meng-export PDF data yang diceklis?'
      }));

      if(dialog) {
        try {
          let ids = [];

          selected.map(function (i) {
            ids.push(i.id)
          });

          let res = await LogExportService.api.exportBulkReportMerge('spt21/a1', ids);

          let filename = res.headers.filename;

          download(res.data, filename);

          this.setState({cetak: false})
        } catch (e) {
          const reader = new FileReader();

          /// This fires after the blob has been read/loaded.
          reader.addEventListener('loadend', (e) => {
            const text = e.srcElement.result
            let json = JSON.parse(text)

            if (json.detail)
              iziToast.error({
                title: 'Error',
                message: json.detail
              })
          });

          reader.readAsText(e.response.data)

          this.setState({cetak: false})
        }
      }
    }
    else {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export PDF Merge',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: (
          <div>
            <this.ig.Field
              label='Nama'
              name='h04__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='NPWP'
              name='h03__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Nomor Bukti Potong'
              name='h01__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='NPWP Organisasi'
              name='orgNpwp__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Bulan'
              name='masa__equals'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Tahun'
              name='tahun__equals'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Pembetulan'
              name='pembetulan__equals'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Kode Pajak'
              name='kodeObjekPajak__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
          </div>
        )
      }));

      if (dialog === false)
        return

      if (dialog === undefined)
        dialog = {}

      if (dialog) {
        try {
          let param = ParamService.convert(dialog)

          let res = await LogExportService.api.exportByParamReportMerge('spt21/a1', param);

          let filename = res.headers.filename;

          download(res.data, filename);

          this.setState({cetak: false})
        } catch (e) {
          const reader = new FileReader();

          /// This fires after the blob has been read/loaded.
          reader.addEventListener('loadend', (e) => {
            const text = e.srcElement.result
            let json = JSON.parse(text)

            if (json.detail)
              iziToast.error({
                title: 'Error',
                message: json.detail
              })
          });

          reader.readAsText(e.response.data)

          this.setState({cetak: false})
        }
      }
    }
  }

  async handleExportAll() {
    let selected = this.props.table.selected

    if (selected.length > 0) {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export CSV',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: 'Apakah anda yakin akan meng-export CSV data yang diceklis?'
      }));

      if(dialog) {
        let ids = [];

        selected.map(function (i) {
          ids.push(i.id)
        });

        let res = await LogExportService.api.exportBulkCsv('spt21/a1', ids);

        let filename = res.headers.filename;

        download(res.data, filename);
      }
    }
    else {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export CSV',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: (
          <div>
            <this.ig.Field
              label='Nama'
              name='h04__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='NPWP'
              name='h03__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Nomor Bukti Potong'
              name='h01__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='NPWP Organisasi'
              name='orgNpwp__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Bulan'
              name='masa__equals'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Tahun'
              name='tahun__equals'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Pembetulan'
              name='pembetulan__equals'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Kode Pajak'
              name='kodeObjekPajak__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
          </div>
        )
      }));

      if (dialog === false)
        return

      if (dialog === undefined)
        dialog = {}

      if (dialog) {
        let param = ParamService.convert(dialog)

        let res = await LogExportService.api.exportByParamCsv('spt21/a1', param);

        let filename = res.headers.filename;

        download(res.data, filename);
      }
    }
  }

  barActions() {
    return [
      ...this._barActions,
      {
        label:'word.exportCsv',
        iconClassName:'mdi mdi-file-excel',
        // disabledFunc:() => this.props.table.selected.length === 0,
        onClick:() => this.handleExportAll()
      },
      {
        label:'word.exportPdf',
        iconClassName:'mdi mdi-file-pdf',
        // disabledFunc:() => this.props.table.selected.length === 0,
        onClick:() => this.handleCetak()
      },
      {
        label:'word.exportPdfMerge',
        iconClassName:'mdi mdi-file-pdf-box',
        // disabledFunc:() => this.props.table.selected.length === 0,
        onClick:() => this.handleCetakMerge()
      },
      {
        label:'word.email',
        iconClassName:'mdi mdi-email',
        disabledFunc:() => this.props.table.selected.length === 0,
        onClick:() => this.handleSendEmail()
      }
    ]
  }

  columns=[
    {isSortable: true, label: "word.npwpProfile", value:"spt21.organization.npwp", show: true, isSearchable: true},
    {isSortable: true, label: "word.year", searchField: "spt21.year", value: (d)=> d.spt21.year, type: "func", show: true, isSearchable: true},
    {isSortable: true, label: "word.month", value: "spt21.month", type: "number", show: true, isSearchable: true},
    {isSortable: true, label: "word.pembetulan", value: "spt21.pembetulan", type: "number", show: true, isSearchable: true},
    {isSortable: true, label: "word.npwp", searchField: "bagA.a01", value: "bagA.npwp", show:true, isSearchable:true},
    {isSortable: true, label: "word.nik", searchField: "bagA.a02", value: "bagA.nik", show:true, isSearchable:true},
    {isSortable: true, label: "word.name", searchField: "bagA.a03", value: "bagA.nama", show:true, isSearchable:true},
    /*{isSortable: true, label: "word.email", searchField: "bagA.a04", value: "bagA.email", show:true, isSearchable:true},*/
    {isSortable: true, label: "word.email", searchField: "bagA.a04", type: "func",  value: (d) => {
        let email = d.bagA.email
        if (email) {
          email = email.replace('<', '(')
          email = email.replace('>', ')')
        }
        return email
    }, isDefaultSort:false, show:true, isSearchable:true},
    {isSortable: true, label: "word.noBp", searchField: "h01", value: "no", show:true, isSearchable:true},
    {isSortable: true, label: "word.masaAwal",  searchField: "h02Month1", value: "masaPerolehanAwal", show:true, isSearchable:true, type: 'number'},
    {isSortable: true, label: "word.masaAkhir",  searchField: "h02Month2", value: "masaPerolehanAkhir", show:true, isSearchable:true, type: 'number'},
    {isSortable: true, label: "word.date", searchField: "bagC.c03", value: "bagC.tanggal", show:true, isSearchable:true},
    {isSortable: true, label: "word.taxCode", searchField: "bagB.b00", value: "bagB.kodeObjekPajak", show:true, isSearchable:true},
    {isSortable: true, label: "word.bruto", searchField: "bagB.b08", value: "bagB.jmlBruto", show:true, isSearchable:true, type: 'number'},
    {isSortable: true, label: "word.pph", searchField: "bagB.b19", value: "bagB.pph21Terutang", show:true, isSearchable:true, type: 'number'},
    {isSortable: true, label: "word.reference", value: "referensi", show:false, isSearchable:true},
  ]

  _tableActions = [
    {
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    }
  ]

}
