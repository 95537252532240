import accounting from 'accounting';
import { parse } from 'path';

const money = function (v){
  if(!v) return 0;
  if(typeof v == 'string') v = parseFloat(v);
  if(v < 0) {
    return '('+accounting.formatMoney(Math.abs(v), "", 0, ",", ".") +')'
  } else {
    return accounting.formatMoney(v, "", 0, ",", ".") + "";
  }
}

const money2 = (v) => {
  if(!v) return 0;
  if(v < 0) {
    return '('+accounting.formatMoney(Math.abs(v), "", 0, ",", ".") +')'
  } else {
    return accounting.formatMoney(v, "", 0, ",", ".") + "";
  }

}

const int = function (val){
    return val+""
}

export default {
    money,
    int,
    money2
}

