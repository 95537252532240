import ApiService from '../../../../../../../services/api.service'
import http from '../../../../../../../services/http.service'

class EbupotKapService extends ApiService {
  name= 'EbupotKap';
  path= 'ebupot/kap';
  advancedSearch=true
  
  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api= {}
}


export default new EbupotKapService();
