import ApiService from '../../../../../services/api.service';

class Master21HonorPnsService extends ApiService {
  name= 'Master21HonorPns';
  path= 'master/tarif-21-honor-pns';
  constructor() {
    super();
    this.init()
  }
}


export default new Master21HonorPnsService();
