import ApiService from '../../../services/api.service';

class UnitService extends ApiService {
  name = 'Unit';
  path = 'unit';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api = {}
}

export default new UnitService();
