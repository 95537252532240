import ApiService from '../../../../services/api.service';

class MasterDefaultImportHeaderService extends ApiService {
  name= 'masterDefaultImportHeader';
  path= 'master/default-import-headers';
  constructor() {
    super();
    this.init()
  }

  api = {
    findAllByCategoryId: async (id) => {
      return await this.http.get(`${this.apiLocation}/${this.path}/byCategory/${id}`)
    }
  }
}

export default new MasterDefaultImportHeaderService();
