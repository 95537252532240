import React from 'react';
import _ from 'lodash';
import {connect} from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';
import {
  Divider,
  Subheader,
  CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  CardText,
  Tab,
  Tabs,
  SelectionControlGroup,
  Checkbox,
  Button,
  IconSeparator,
  FontIcon,
  List,
  ListItem,
  Avatar,
} from 'react-md';

import FormView from '../../../../components/entity/form.view';
import {validation, Textfield, Radio, Switch} from '../../../../components/form/index';
import SettingEbillingService from './SettingEbilling.service';
import EbillingAccountService from '../EbillingAccountService';
import EbillingInquiryService from '../EbillingInquiryService';
import iziToast from 'izitoast';

@reduxForm({form: 'SettingEbilling_Form', destroyOnUnmount: true, initialValues: {}})
@connect((state) => ({
  access: state.entity.access.api.find,
  organization: state.auth.currentOrganization,
  spt: state.auth.currentSPT,
  formData: getFormValues('SettingEbilling_Form')(state),
  user: state.auth.user
}))
export default class SettingEbillingForm extends FormView {
  service=SettingEbillingService
  ebillingAccountService=EbillingAccountService
  ebillingInquiryService=EbillingInquiryService
  viewType =2;

  constructor(props){
    super(props)
    this.state = {
      ...this.state
    }

    this.state.ebillingUser = {}
  }

  _barActions = [
    {
      label:'ebilling.inquiry',
      iconClassName:'mdi mdi-account-convert',
      onClick:() => {
        this.checkInquiry()
      },
      disabledFunc:() => {
        return !this.props.formData.npwp
      }
    },
  ]

  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;

    return (
      <div>
        <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>Save</Button>
      </div>
    )
  }

  async initData() {
    try {
      let res = await this.service.api.get();

      this.props.initialize(res.data);
    } catch (e){
      this.toast.warning({
        title: e.name,
        message: e.message
      })
    }

    try {
      this.checkConnection()
    } catch (e){
      this.toast.warning({
        title: e.name,
        message: e.message
      })
    }
  }

  async checkConnection() {
    let res = await this.ebillingAccountService.api.me()

    this.setState({ebillingUser: res.data});
  }

  async checkInquiry() {
    let formData = this.props.formData

    let data = {
      npwpList: [formData.npwp]
    }

    let res = await this.ebillingInquiryService.api.checkInquiry(data)

    formData.name = res.data[0].name
    formData.city = res.data[0].kota
    formData.address = res.data[0].alamat

    this.handleSave(formData)
  }

  async handleSave(value) {
    var doSave = true;

    if(value.useProxy) {
      if(value.proxyPort) value.proxyPort = parseInt(value.proxyPort)

      if(_.isEmpty(value.proxyHost) || value.proxyPort == 0) {
        iziToast.error({
          title: "Warning",
          message: "Host dan Port harus di isi jika proxy di centang"
        })
        doSave = false;
      }
    }

    if(value.expiresIn){
      value.expiresIn = parseInt(value.expiresIn)
    }

    if(doSave) {
      try {
        let res = await this.service.api.update(value)

        this.props.initialize(res.data);

        this.checkConnection();

        iziToast.success({
          title: "SettingEbilling E-Billing",
          message: "Berhasil di simpan !"
        })
      } catch (e){
        this.toast.warning({
          title: e.name,
          message: e.message
        })
      }
    }
  }

  formView() {
    return (
      <div>
        <Card>
          <div className='md-grid'>
            <div className="md-cell md-cell--8">
            </div>
            <div className="md-cell md-cell--4">
              <List className="md-paper md-paper--1">
                { this.state.ebillingUser.login &&
                  <ListItem
                    leftAvatar={<Avatar  suffix="green" icon={<FontIcon>done</FontIcon>} />}
                    primaryText="Terhubung"
                    secondaryText={"Terhubung sebagai " + this.state.ebillingUser.login + "."}
                  />
                }

                { !this.state.ebillingUser.login &&
                  <ListItem
                    leftAvatar={<Avatar suffix="red" icon={<FontIcon>clear</FontIcon>} />}
                    primaryText="Tidak Terhubung"
                    secondaryText="Status Koneksi"
                  />
                }
              </List>
            </div>
          </div>
          <div className='md-grid'>
            <div className="md-cell md-cell--6">
              <Field
                label='Username'
                name='username'
                component={Textfield}
              />
            </div>

            <div className="md-cell md-cell--6">
              <Field
                label='Password'
                name='password'
                type='password'
                component={Textfield}
              />
            </div>

            <div className="md-cell md-cell--12">
              <Field
                label='Use Proxy'
                name='useProxy'
                component={Switch}
              />
            </div>
            {this.props.formData.useProxy &&
              <div className='md-grid' style={{padding: 0, margin: 0, width: '100%'}}>
                <div className="md-cell md-cell--6">
                  <Field
                    label='Proxy Host'
                    name='proxyHost'
                    component={Textfield}
                  />

                  <Field
                    label='Proxy Port'
                    name='proxyPort'
                    type="number"
                    component={Textfield}
                  />

                  <Field
                    component={Radio}
                    controlStyle={{display: 'inline-block'}}
                    name="proxyType"
                    type="radio"
                    key={this.props.formData.proxyType}
                    value={this.props.formData.proxyType}
                    controls={[{
                      label: 'HTTP',
                      value: 'HTTP',
                    }, {
                      label: 'SOCKS',
                      value: 'SOCKS',
                    }]}
                  />
                </div>
                <div className="md-cell md-cell--6">
                  <Field
                    label='Proxy Username'
                    name='proxyUsername'
                    component={Textfield}
                  />

                  <Field
                    label='Proxy Password'
                    name='proxyPassword'
                    type="password"
                    component={Textfield}
                  />
                </div>
              </div>
            }

            <div className="md-cell md-cell--12">
              <div className='md-grid' style={{padding: 0, margin: 0, width: '100%'}}>
                <div className="md-cell md-cell--6">
                  <Field
                    label='NPWP'
                    name='npwp'
                    component={Textfield}
                    disabled
                  />

                  <Field
                    label='Name'
                    name='name'
                    component={Textfield}
                    disabled
                  />
                </div>
                <div className="md-cell md-cell--6">
                  <Field
                    label='City'
                    name='city'
                    component={Textfield}
                    disabled
                  />

                  <Field
                    label='Address'
                    name='address'
                    component={Textfield}
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
        </Card>
        <br/>
      </div>
    )
  }

}
