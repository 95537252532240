import ApiService from '../../../../services/api.service';

class MasterLawanTransaksi15Service extends ApiService {
  name= 'masterLawanTransaksi15';
  path= 'spt15/lt';

  constructor() {
    super();
    this.init()
  }

  api = {
    findOrCreate: async (data)=> {
      var res = await this.http.post(this.apiLocation+'/'+this.path+'/findOrCreate', data)
      return res.data;
    }
  }
}


export default new MasterLawanTransaksi15Service();
