import ApiService from '../../../../services/api.service';

class LogImportCsvByAppService extends ApiService {
  name= 'LogImportCsvByApp';
  path= 'log-by-app/csv';
  constructor() {
    super();
    this.init()
  }
}

export default new LogImportCsvByAppService();
