import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import {Button, Card, FontIcon, List, ListItem, Subheader} from 'react-md';
import _ from 'lodash';
import FormView from '../../../../components/entity/form.view';
import {Datepicker, Switch, Textfield} from '../../../../components/form';
import SettingImportGlobalService from './SettingImportGlobal.service';
import '../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

@reduxForm({
  form: 'SettingImportGlobal_Form',
  destroyOnUnmount: true,
  initialValues: {validasiPphTidakBolehNolImporSatuMasa: false}
})
@connect((state) => ({
  access: state.entity.access.api.find,
  organization: state.auth.currentOrganization,
  spt: state.auth.currentSPT,
  formData: state.form.SettingImportGlobal_Form,
  user: state.auth.user
}))
export default class SettingImportGlobalForm extends FormView {
  service = SettingImportGlobalService
  viewType = 2;

  constructor(props) {
    super(props)
    this.state = {
      ...this.state
    }
  }

  _barItem() {
    const {handleSubmit, submitting, valid, pristine} = this.props;

    return (
      <div>
        <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>Save</Button>
      </div>
    )
  }

  async initData() {
    try {
      let res = await this.service.api.get();

      let data = res.data

      this.props.initialize(data);
    } catch (e) {
      this.toast.warning({
        title: e.name,
        message: e.message
      })
    }
  }

  async handleSave(value) {
    try {
      let res = await this.service.api.update(value)
    } catch (e) {
      this.toast.warning({
        title: e.name,
        message: e.message
      })
    }
  }

  formView() {
    return (
      <div>
        <Card>
          <div className='md-grid'>
            <div className="md-cell md-cell--4">
              <Field
                label='Validasi PPh Tidak Boleh Nol Di Import Satu Masa Lebih'
                name='validasiPphTidakBolehNolImporSatuMasa'
                component={Switch}
              />
            </div>
          </div>
        </Card>
        <br/>
      </div>
    )
  }

}
