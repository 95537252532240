import React, { Component } from 'react';
import { connect } from 'react-redux';

import authService from '../../../../../services/auth.service';
import * as constant from '../../../../../config/constant';
import Spt15Service from './Spt15.service';
import ListViewSptRoot from '../../../../../components/entity/ListViewSptRoot';

@connect((state)=> ({
  ...Spt15Service.stateConnectSetting()(state),
  user: state.auth.user,
  orgAccess: state.auth.orgAccess,
  company: state.auth.currentCompany
}), Spt15Service.actionConnectSetting())
export default class SPT15View extends ListViewSptRoot {
  service=Spt15Service
  FormDialog=()=> <div/>

  addDialog=false
  editDialog=false

  permissionName="SPT_15"
  urlPath="SPT_15"
  formTypeName="PPH 15"
  shortPasalName="15"

  fetchOption() {
    var userAuth = authService.api.getAuth(this.props.user);
    var path = null;
    if(userAuth === constant.ROLE_OWNER) {
      path =  Spt15Service.path+'/company/page/'+this.props.company.id
    }

    if(this.props.match.params.companyId) path =  Spt15Service.path+'/company/page/'+this.props.match.params.companyId;
    // if(this.props.user.company && this.props.user.company.id) path =  SPT2126Service.path+'/company/page/'+this.props.user.company.id;

    return {
      path: path
    }
  }
}
