import download from 'downloadjs';
import React from 'react';
import { Button, Card, CardTitle, DataTable, Divider, TableBody, TableColumn, TableHeader, TableRow } from 'react-md';
import { DialogConfirm } from 'react-mpk';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import ListView from '../../../../../../components/entity/listView';
import ExportCsv15Service from './ExportCsv15.service';
import BP15Service from '../../BP15/BP15.service';
import BP24Service from '../../BP15/PPHPasal24/BP15PPhPasal24.service';
import SSP15SetorSendiriService from '../../SSPSetorSendiri/SSP15SetorSendiri.service';
import SSP15Service from '../../SSP/SSP15.service';
import PBK15Service from '../../PBK/PBK15.service';
import SPT15IndukService from "../../../15/Induk/Spt15Induk.service";

@reduxForm({form: 'EXPORT_CSV_15', destroyOnUnmount: true})
@connect((state)=> ({
  ...ExportCsv15Service.stateConnectSetting()(state),
  spt: state.auth.currentSPT
}), ExportCsv15Service.actionConnectSetting())
export default class FileLapor15View extends ListView {
  service=ExportCsv15Service

  constructor(props){
    super(props);
    this.state = {
      showTableSetting:false,
      showDialogConfirmDeleteSelected:false,
      showForm:false,
      formData: {},
      data: [],
      cetak: false,
      showDialog: false,
      category: null,

      totalbp: 0,
      total24: 0,
      sspSetorSendiri: 0,
      totalSSP: 0,
      totalPBK: 0,
      totalInduk: 1,
    }
  }

  columns=[]
  _barActions=[]
  _barItem() {}

  handleExport(category){
      this.setState({ showDialog: true })
      this.setState({ category: category })
  }

  async export(){
    var sptId = this.props.spt.data.id
    var category = this.state.category
    var csv = {};
    try {
      switch (category) {
        case 'BP_15':
          csv = await BP15Service.api.downloadCsv(sptId);
          break;
        case 'BP_24':
          csv = await BP24Service.api.downloadCsv(sptId);
          break;
        case 'SSP_15_SETOR_SENDIRI':
          csv = await SSP15SetorSendiriService.api.downloadCsv(sptId);
          break;
        case 'SSP_15':
          csv = await SSP15Service.api.downloadCsv(sptId);
          break;
        case 'PBK_15':
          csv = await PBK15Service.api.downloadCsv(sptId);
          break;
        case 'BP_15_INDUK':
          csv = await SPT15IndukService.api.downloadCsv(sptId);
          break;
      }
      var filename = csv.headers.filename
      download(csv.data, filename);
    } catch (error) {
    }
  }

  async componentDidMount(){
    var sptId = this.props.spt.data.id

    let info = await this.service.api.getInfo(sptId);

    // let total26 = await BP_26Service.api.find(null, ()=> {}, {
    //   path: BP_26Service.path+'/'+sptId+'/page'
    // })
    // let totalSSP = await SSP15Service.api.find(null, ()=> {}, {
    //   path: SSP15Service.path+sptId+'/page'
    // })
    // let totalPBK = await PBK15Service.api.find(null, ()=> {}, {
    //   path: PBK15Service.path+'/'+sptId+'/page'
    // })

    if(info.data) {
      this.setState({
        totalbp: info.data.bp,
        total24: info.data.bp24,
        sspSetorSendiri: info.data.sspSetorSendiri,
        totalSSP: info.data.ssp,
        totalPBK: info.data.pbk
      })
    }

  }

  _tableView(props) {
      const _this = this
      return (
        <Card className="flex mpk-layout">
            <DialogConfirm
                title={'word.export'}
                message={'sentence.custom.export'}
                visible={this.state.showDialog}
                onSubmit={(callback) => {
                    _this.export()
                    callback();
                }}
                onCancel={() => this.setState({showDialog:false})}
                translate={true}
            />
            <CardTitle title='' style={{padding: '10px 16px'}} subtitle='Informasi File Export'/>
            <Divider/>
            <DataTable plain fixedScrollWrapperStyle={{overflow: 'inherit'}} style={{overflow: 'inherit'}}>
              <TableHeader>
                <TableRow>
                    <TableColumn>Jumlah Data</TableColumn>
                    <TableColumn></TableColumn>
                </TableRow>
              </TableHeader>
              <TableBody>
                <TableRow>
                    <TableColumn>{this.state.totalbp} Unit</TableColumn>
                    <TableColumn>
                        <Button disabled={this.state.totalbp == 0} onClick={this.handleExport.bind(this, 'BP_15')} style={{width: '100%'}} primary raised>Export Data BP 15</Button>
                    </TableColumn>
                </TableRow>
                <TableRow>
                  <TableColumn>{this.state.total24} Unit</TableColumn>
                  <TableColumn>
                    <Button disabled={this.state.total24 == 0} onClick={this.handleExport.bind(this, 'BP_24')} style={{width: '100%'}} primary raised>Export Data Perhitungan Pasal 24</Button>
                  </TableColumn>
                </TableRow>
                <TableRow>
                  <TableColumn>{this.state.sspSetorSendiri} Unit</TableColumn>
                  <TableColumn>
                    <Button disabled={this.state.sspSetorSendiri == 0} onClick={this.handleExport.bind(this, 'SSP_15_SETOR_SENDIRI')} style={{width: '100%'}} primary raised>Export Data SSP Setor Sendiri</Button>
                  </TableColumn>
                </TableRow>
                <TableRow>
                    <TableColumn>{this.state.totalSSP} Unit</TableColumn>
                    <TableColumn>
                        <Button disabled={this.state.totalSSP == 0} onClick={this.handleExport.bind(this, 'SSP_15')} style={{width: '100%'}} primary raised>Export Data SSP</Button>
                    </TableColumn>
                </TableRow>
                <TableRow>
                    <TableColumn>{this.state.totalPBK} Unit</TableColumn>
                    <TableColumn>
                        <Button disabled={this.state.totalPBK == 0} onClick={this.handleExport.bind(this, 'PBK_15')} style={{width: '100%'}} primary raised>Export Data PBK</Button>
                    </TableColumn>
                </TableRow>
                <TableRow>
                  <TableColumn>{this.state.totalInduk} Unit</TableColumn>
                  <TableColumn>
                    <Button disabled={this.state.totalInduk == 0} onClick={this.handleExport.bind(this, 'BP_15_INDUK')} style={{width: '100%'}} primary raised>Export Data Induk</Button>
                  </TableColumn>
                </TableRow>
              </TableBody>
            </DataTable>
        </Card>
      )
  }
}
