
import React, { Component } from 'react';
import { connect } from 'react-redux';
import routes from './routes';

import AuthService from '../../../../services/auth.service';
import Spt4a2Service from './SPT/Spt4a2.service';

@connect(state => ({
  organization: state.auth.currentOrganization,
  user: state.auth.user,
  spt: state.auth.currentSPT
}))
export default class Spt4a2Main extends Component{
  async componentDidMount() {
    let spt = await Spt4a2Service.api.findOne(this.props.match.params.sptId);
    AuthService.api.changeSPT({
      type: '4a2',
      data: spt.data
    }, this.props.dispatch)
    AuthService.api.changeMenuType('SPT_4a2', this.props.dispatch)

    AuthService.api.changeOrganization(spt.data.organization, this.props.dispatch)
  }
  render() {
    if(this.props.spt.data) {
      return(
        <div className="mpk-layout column fill">
          {routes(this.props)}
        </div>
      )
    } else {
      return <div/>
    }

  }
}
