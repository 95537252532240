

import React from 'react';
import TextField from "react-md/lib/TextFields/TextField";
import moment from 'moment';
import { DatePicker } from 'react-md';
import { locale } from 'react-mpk';
import DayPickerInput from 'react-day-picker/DayPickerInput'
import {formatDate,parseDate,} from 'react-day-picker/moment';
import 'react-day-picker/lib/style.css';

export default class DatepickerCustom extends React.Component {
  constructor(props){
    super(props)
    this.state = {
        clicked  : false,
        value    : undefined,
        realValue: 0
    }
}

  handleChange(date){
    // var m = moment(date).add(5, 'h').toDate();
    if(!date) this.props.input.onChange(null)

    if(date) {
      if(this.props.onDateChange) this.props.onDateChange(date)
    }
  }
  render() {
    let {
      input,
      meta: { touched, error },

      label,
      className,
      labelClassName,
      inputClassName,
      style,
      labelStyle,
      inputStyle,
      autoComplete,
      ...others
    } = this.props;

    if (!autoComplete) autoComplete = "on"

    return <div style={style} className={"md-text-field-container md-full-width md-text-field-container--input " + className}>

      <label className={ 'md-floating-label md-floating-label--floating md-text--error'+labelClassName } style={labelStyle}>
        {label}
      </label>
      <DayPickerInput
          {...input}
          {...others}
          format      = {'YYYY-MM-DD'}
          placeholder = "YYYY-MM-DD"
          formatDate  = {formatDate}
          parseDate   = {parseDate}
          inputProps  = {{
            className: 'md-text-field md-text-field--floating-margin md-full-width md-text '+inputClassName,
            ...input,
            onChange: null,
            style   : inputStyle,
            autoComplete
          }}

          onDayChange = {this.handleChange.bind(this)}
          name        = {name}
      />
      <hr className={'md-divider md-divider--text-field md-divider--expand-from-left md-divider--text-field-expanded'} />

      {error && touched &&
        <div className="md-text-field-message-container md-full-width md-text--error" >
            <div className="md-text-field-message md-text-field-message--active">
                {this.props.meta.error}
            </div>
        </div>
      }

    </div>
  }
}
