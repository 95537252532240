import React, { Component } from 'react';
import { connect } from 'react-redux';

import {Field, getFormValues, reduxForm} from 'redux-form';
import { Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab } from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Switch as Switch2} from '../../../../../components/form';

import ImportLK21Service from './ImportLK21.service';
import ListView from '../../../../../components/entity/listView';
import ColumnService from '../../../../../services/column.service';
import ImportService from './../Import/Import.service';
import axios from 'axios';
import izitoast from 'izitoast';
import FormDialog from './ImportLK21.dialog.view';
import download from "downloadjs";
import ImportDialogResult from '../../../../../components/entity/import.dialog.result';
import ListViewImport from '../../../../../components/entity/ListViewImport';
import MasterLawanTransaksiPPService from '../../../Master/MasterLawanTransaksiPp/MasterLawanTransaksiPp.service'
import MasterLawanTransaksiA1Service from '../../../Master/MasterLawanTransaksiA1/MasterLawanTransaksiA1.service'
import MasterLawanTransaksiA2Service from '../../../Master/MasterLawanTransaksiA2/MasterLawanTransaksiA2.service'

@reduxForm({form: 'IMPORT_21_LK_Form', destroyOnUnmount: true})
@connect((state) => ({
  ...ImportLK21Service.stateConnectSetting()(state),
  formData: getFormValues('IMPORT_21_LK_Form')(state)
}), ImportLK21Service.actionConnectSetting())
export default class ImportLK21View extends ListViewImport {
  service=ImportLK21Service
  FormDialog=FormDialog

  category='LK_21'
  importPath='/api/import/spt/21'
  showAutomatisTab=false
  showPenomoranAuto=true
  showFormatEspt=true
  // viewType=2

  typeSendiri = [
    {
      name: 'LAWAN TRANSAKSI PP',
      id: 'LAWAN_TRANSAKSI_PP_21'
    },
    {
      name: 'LAWAN TRANSAKSI A1',
      id: 'LAWAN_TRANSAKSI_A1_21'
    },
    {
      name: 'LAWAN TRANSAKSI A2',
      id: 'LAWAN_TRANSAKSI_A2_21'
    }
  ]

  beforeFetch(params) {
    params["category.in"] = "LAWAN_TRANSAKSI_PP_21, LAWAN_TRANSAKSI_A1_21, LAWAN_TRANSAKSI_A2_21"
  }

  async handleDownloadTemplate() {
    let category = this.props.formData.category

    if (category ==='LK_21') {
      izitoast.warning({
        title: 'Pemberitahuan !',
        message: 'Pilih salah satu kategori template',
      });
    } else {
      // var report = await ImportLK21Service.api.downloadTemplate(category)
      let res = null

      switch (category) {
        case 'LAWAN_TRANSAKSI_PP_21':
          res = await MasterLawanTransaksiPPService.api.downloadTemplate()
          break
        case 'LAWAN_TRANSAKSI_A1_21':
          res = await MasterLawanTransaksiA1Service.api.downloadTemplate()
          break
        case 'LAWAN_TRANSAKSI_A2_21':
          res = await MasterLawanTransaksiA2Service.api.downloadTemplate()
          break
      }

      if (res)
        download(res.data, category+'.csv');
    }
  }

  async checkCategory() {
    let category = this.props.formData.category

    if (!category)
      return false

    if (category === 'LK_21')
      return false

    return true
  }

  import(){
    const { handleSubmit, submitting, valid, pristine } = this.props;

    let formatEspt = false;

    if (this.props.formData) {
      formatEspt = this.props.formData.formatEspt
    }

    return (
        <div className='mpk-layout column fill'>
        <ImportDialogResult dataResults={this.state.importResults} visible={this.state.showImportResult} onHide={()=> {this.setState({showImportResult: false})}} />
          <div className='flex'>
            <div className='md-grid'>
              <div className="md-cell md-cell--12">
                <Field
                  label='Kategori'
                  name='category'
                  twoLines
                  component={Searchfield}
                  options={this.typeSendiri}
                  // validate={validation.required}
                />
              </div>
              <div className="md-cell md-cell--12">
                <Field
                  label='Select a File'
                  name='file'
                  multiple
                  twoLines
                  component={Fileinput}
                  validate={validation.required}
                />
              </div>

              <div className="md-cell md-cell--12">
                <Field
                  label='Import Migrasi'
                  name='migration'
                  component={Switch2}
                  // validate={validation.required}
                />
              </div>


              <div className="md-cell md-cell--12">
                  <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>Import</Button>
              </div>
            </div>
          </div>
          {this.state.onProgress ? (
              <LinearProgress style={{margin: 0}} query={true} />
            ) : (null)}

        </div>
    )
  }

}
