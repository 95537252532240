import React, { Component } from 'react';
import { Grid, Cell } from 'react-md';
import { Field, reduxForm , propTypes, formValueSelector } from 'redux-form';
import { validation, Textfield, TextfieldMask, convert } from '../../../../components/form';
import DialogView from '../../../../components/entity/dialog.view';
import MasterPesangonService from './MasterPesangon.service';

@reduxForm({form: 'master-pesangon', destroyOnUnmount: false})
export default class MasterPesangonDialogView extends DialogView {
  service=MasterPesangonService
  initialData = {
  }

  formView() {
    return (
      <Grid style={{padding:'none'}}>
        <Field
          label='Code'
          name='code'
          id="master-pesangon-code"
          className="md-cell md-cell--12"
          component={Textfield}
          validate={validation.required}
        />
        <Field
          label='Start'
          name='start'
          id="master-pesangon-start"
          className="md-cell md-cell--6"
          component={TextfieldMask}
          money={true}
          normalize={convert.money}
          validate={validation.required}
        />
        <Field
          label='To'
          name='to'
          id="master-pesangon-to"
          className="md-cell md-cell--6"
          component={TextfieldMask}
          money={true}
          normalize={convert.money}
          validate={validation.required}
        />
        <Field
          label='(%)'
          name='percent'
          type="number"
          max="100"
          id="master-pesangon-persen"
          className="md-cell md-cell--6"
          component={Textfield}
          validate={validation.required}
        />
      </Grid>
    )
  }
}
