import React, { Component } from 'react'; import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {TabsContainer, Tabs, Tab} from 'react-md';
import { Field } from 'redux-form';
import moment from 'moment'

import ApplicationOrganizationService from './ApplicationOrganization.service';
import ListView from '../../../../../components/entity/ListViewEbupot';
import AuthService from '../../../../../services/auth.service'
import ListViewEbupot from '../../../../../components/entity/ListViewEbupot';
import ApplicationOrganizationDialog from './ApplicationOrganization.dialog';

@connect((state)=> ({
  ...ApplicationOrganizationService.stateConnectSetting()(state)
}), ApplicationOrganizationService.actionConnectSetting())
export default class ApplicationOrganizationView extends ListViewEbupot {
  service=ApplicationOrganizationService;
  FormDialog=ApplicationOrganizationDialog

  columns=ApplicationOrganizationService.columns

  fetchOption() {
    return {
      path: `application/${this.props.appId}/organization`
    }
  }


  header() {
    return this.commandBar()
  }

  render() {
    return (
      <this.viewContainer>

        <div className="flex mpk-layout">
          {this.tableView()}
          {this.tableSetting()}

          <this.FormDialog
            appId={this.props.appId}
            height={400}
            visible={this.state.showForm}
            add={this.props.tableActions.addDataItem}
            formData={this.state.formData}
            onSuccess={()=> this.fetchData()}
            onClose={() => this.setState({showForm:false})}
          />

          {this.dialogConfirm()}
        </div>
      </this.viewContainer>
    )
  }

}
