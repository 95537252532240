import ApiService from '../../../../services/api.service';

class SettingExportService extends ApiService {
  name= 'SettingExport';
  path= 'setting/export/page';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api = {
    getByUser: async (userId)=> {
      return await this.http.get(`${this.apiLocation}/setting/export/user/`+userId)
    },
    updateByUser: async (formData)=> {
      return await this.http.put(`${this.apiLocation}/setting/export`, formData)
    }
  }
}

export default new SettingExportService();
