import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Button, SVGIcon } from 'react-md';
import FormDialog from './Master21Pasal17.dialog.view';
import Master21Pasal17Service from './Master21Pasal17.service';
import ListView from '../../../../../components/entity/listView';
import ColumnService from '../../../../../services/column.service';

@connect(Master21Pasal17Service.stateConnectSetting(), Master21Pasal17Service.actionConnectSetting())
export default class Master21Pasal17View extends ListView {
  service=Master21Pasal17Service
  FormDialog=FormDialog

  goToDetail(item){
    let id = item.id
    this.props.history.push('/master/pasal-21-pasal-17-berlapis/'+id)
  }

  _tableActions = [
    {
      label:"Detail",
      iconClassName:"mdi mdi-open-in-new",
      onClick: (item) => this.goToDetail(item)
    },
    {
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    },
    {isSortable: true, label:"divider", iconClassName:"-"},
    {
      label:"Delete",
      iconClassName:"mdi mdi-delete",
      onClick:(item, callback) => this.deleteItem(item, callback),
      confirmation:{
        title:"sentence.custom.deleteItem",
        message:"sentence.custom.deleteItemConfirmation"
      }
    }
  ]

  columns=[
    {isSortable: true, label: "word.id",    searchField: "id", value: function(d){ return d.id }, type: "func",  show:false, isSearchable:true},
    {isSortable: true, label: "word.start", searchField: "start", value: function(d){ return d.start }, type: "func",  show:true, isSearchable:true},
    {isSortable: true, label: "word.end",   searchField: "end", value: function(d){ return d.end }, type: "func",  show:true, isSearchable:true},
  ]   
}
