import ApiService from "../../../../../../services/api.service";

class Tarif4a2ervice extends ApiService {
  name = 'Master4a2Tarif';
  path = 'spt4a2/tarif-4a2';

  constructor() {
    super();
    this.init()
  }

  api = {
    byCompany: async (data) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/company`, {
        params: data
      });
      return res.data;
    },
    getMaster: async () => {
      var res = await this.http.get(`${this.apiLocation}/spt4a2/master/tarif-4a2`);
      return res.data;
    }
  }
}


export default new Tarif4a2ervice();
