import ApiService from '../../../../../services/api.service';

class Master21UpahKumulatifService extends ApiService {
  name= 'Master21UpahKumulatif';
  path= 'master/tarif-21-upah-kumulatif';

  constructor() {
    super();
    this.init()
  }
}


export default new Master21UpahKumulatifService();
