import React from 'react';
import _ from 'lodash';
import TextField from "react-md/lib/TextFields/TextField";
import { FileInput, Snackbar, Button } from 'react-md';

export default class FileInputNext extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      value: '',
      fileName: '',
      files: []
    }
  }

  handleClear = ()=> {
    this.setState({ value: '', fileName: '' ,files: []});
    this.props.input.onChange([]);
  }

  handleChange = ({ name }, e) => {
    window.hahaha = e.target
    let files = [];
    for (let index = 0; index < e.target.files.length; index++) {
      files.push(e.target.files[index])
    }
    let file = e.target.files[0]
    const { value } = e.target;
    this.setState({ value, fileName: name ,files});
    try {
      this.props.parent.props.change('npwp', name.split('.')[0])
    } catch(e){}
    if(this.props.multiple) {
      this.props.input.onChange(e.target.files);
    } else {
      this.props.input.onChange(file);
    }

  };

  render() {
    let { input, meta: { touched, error }, ...others } = this.props;
    let idProps = Math.random().toString(36).substring(7)+this.props.name;
    const _this = this
    if(this.state.fileName){
      error = ""
    }

    if(this.props.twoLines) {
      if(this.props.multiple) {
        return (
          <div>
            <FileInput
              id={idProps+"FILE"}
              label="Choose file"
              multiple
              accept="*"
              onChange={_this.handleChange}
              primary
            />
            <Button flat style={{marginLeft: 6}} onClick={this.handleClear}>Clear</Button>
            {/* {window.filessss = this.state.files} */}
            {/* {this.state.files.map((d,i)=> {
              return <TextField
              key={i}
              id={'file_'+i}
              placeholder="No file chosen"
              value={(i+1)+" "+d.name}
              readOnly
              error={touched && !!error} errorText={error}/>
            })} */}

            <TextField
              id={'file_'}
              placeholder="No file chosen"
              value={this.state.files.length+" file telah terpilih"}
              readOnly
              error={touched && !!error} errorText={error}/>
          </div>
        )
      } else {
        return (
          <div>
            <FileInput
              id={idProps+"FILE"}
              label="Choose file"
              accept="*"
              onChange={_this.handleChange}
              primary
            />
            <TextField
            id={idProps}
            placeholder="No file chosen"
            value={this.state.fileName}
            readOnly
            error={touched && !!error} errorText={error}
          />
          </div>
        )
      }

    } else {
      return (
        <TextField
          id={idProps}
          placeholder="No file chosen"
          value={this.state.fileName}
          readOnly
          error={touched && !!error} errorText={error}
          leftIcon={
            <FileInput
              id={idProps+"FILE"}
              label="Choose file"
              accept="*"
              onChange={_this.handleChange}
              primary
            />
          }
        />
      )
    }

  }
}
