import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation} from '../../../../components/form';
import NpwpService from './../../../Main/pph/NpwpService';
import FormView from '../../../../components/entity/form.view';
import FileHeaderService from '../FileHeader/FileHeader.service';
import iziToast from 'izitoast';

@reduxForm({form: 'FileTransaksiForm', destroyOnUnmount: true, initialValues: {}})
@connect((state) => ({
  access: state.entity.access.api.find,
  formData: getFormValues('FileTransaksiForm')(state)
}))
export default class FileTransaksiForm extends FormView {
  translate = false
  service = FileHeaderService

  viewType=2

  constructor(props){
    super(props);
    this.state = {
      ...this.state
    }

    this.state.datas = []
  }

  titleHeader() {
    return `Detail File Transaksi`;
  }

  resend = async () => {
    let dialog = await this.context.showDialog((props, res, rej) =>({
      title: 'Kirim Ulang File Transaksi',
      okText: 'Kirim',
      width: 600,
      text: 'Apakah anda yakin ingin mengirim ulang transaksi?',
    }));

    if (dialog) {
      try {
        let datas = this.state.datas

        if(datas.length) {
          let data = datas[0]

          let res = await this.service.api.resendFileHeader(data)
        }
      } catch (e) {
        iziToast.warning({
          title: e.name,
          message: e.message
        })
      }
    }
  }

  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;

    return (
      <div>
        <Button secondary flat onClick={()=> {this.props.history.goBack()}} style={{marginRight: 16}}>Back</Button>

        <Button raised primary onClick={()=> this.resend()}>Resend</Button>
      </div>
    )
  }

  async initData() {
    let res = await this.service.api.findByInvoice(this.props.match.params.id);

    this.setState({datas: res});
  }

  formView() {
    let datas = this.state.datas

    if(datas.length) {
      let data = datas[0]

      let i18nStatusBilling = ''

      switch (data.statusBilling) {
        case 'FAILED':
          i18nStatusBilling = 'FAILED'
          break;
        case 'SEND':
          i18nStatusBilling = 'SEND'
          break;
        case 'ACK KOSONG':
          i18nStatusBilling = 'NACK'
          break;
        case 'PROCESSING ON BNI':
          i18nStatusBilling = 'ACK'
          break;
      }

      let i18nStatusSsp = ''

      switch (data.statusSsp) {
        case 'FAILED':
          i18nStatusSsp = 'FAILED'
          break;
        case 'SEND':
          i18nStatusSsp = 'SEND'
          break;
        case 'ACK KOSONG':
          i18nStatusSsp = 'NACK'
          break;
        case 'PROCESSING ON BNI':
          i18nStatusSsp = 'ACK'
          break;
      }

      return (
        <div>
          <Card>
            <CardTitle title='' style={{padding: '10px 16px'}} subtitle='Informasi Akun Pembayaran'/>

            <Divider/>

            <List>
              <ListItem
                // leftAvatar={<Avatar  suffix="green" icon={<FontIcon>done</FontIcon>} />}
                primaryText="ID Perusahaan"
                secondaryText={data.paymentAccount.companyid}
              />
              <ListItem
                primaryText="ID Pengguna"
                secondaryText={data.paymentAccount.name}
              />
              <ListItem
                primaryText="Nomor Account "
                secondaryText={data.paymentAccount.accountNumber}
              />
              <ListItem
                primaryText="Tanggal Instruksi Transaksi "
                secondaryText={data.transactionInstructionDate}
              />
              <ListItem
                primaryText="Persetujuan Transaksi "
                secondaryText={data.approvalTransaction}
              />
            </List>
          </Card>

          <br/>

          <Card>
            <CardTitle title='' style={{padding: '10px 16px'}} subtitle='File dan Status'/>

            <Divider/>

            <Grid noSpacing={true}>
              <Cell size={6}>
                <List>
                  { data.billing !== null &&
                    <ListItem
                      primaryText="File Billing"
                      secondaryText={data.billing}
                    />
                  }

                  { data.ssp !== null &&
                    <ListItem
                      primaryText="File SSP"
                      secondaryText={data.ssp}
                    />
                  }
                </List>
              </Cell>
              <Cell size={6} style={{textAlign: 'center'}}>
                {data.billing !== null &&
                  <div>
                    <p>
                      <Button raised={i18nStatusBilling === 'FAILED'} primary iconChildren="thumb_down">FAILED</Button>
                      &nbsp;
                      <Button raised={i18nStatusBilling === 'SEND'} primary iconChildren="thumb_up">SENT</Button>
                      &nbsp;
                      <Button raised={i18nStatusBilling === 'NACK'} primary iconChildren="close">NACK</Button>
                      &nbsp;
                      <Button raised={i18nStatusBilling === 'ACK'} primary iconChildren="check">ACK</Button>
                    </p>

                    <p>
                      {counterpart.translate('ebilling.paymentNotification.statusPengiriman.' + i18nStatusBilling)}
                    </p>
                  </div>
                }

                {data.ssp !== null &&
                  <div>
                    <p>
                      <Button raised={i18nStatusSsp === 'FAILED'} primary iconChildren="thumb_down">FAILED</Button>
                      &nbsp;
                      <Button raised={i18nStatusSsp === 'SEND'} primary iconChildren="thumb_up">SENT</Button>
                      &nbsp;
                      <Button raised={i18nStatusSsp === 'NACK'} primary iconChildren="close">NACK</Button>
                      &nbsp;
                      <Button raised={i18nStatusSsp === 'ACK'} primary iconChildren="check">ACK</Button>
                    </p>

                    <p>
                      {counterpart.translate('ebilling.paymentNotification.statusPengiriman.' + i18nStatusSsp)}
                    </p>
                  </div>
                }
              </Cell>
            </Grid>
          </Card>
        </div>
      )
    }
  }
}
