import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Button, Chip, Switch} from 'react-md';

//import FormDialog from './Organization.dialog.view';
import ListView from '../../../../../../components/entity/listView';
import UtilService from '../../../../../../services/utils.service';
import download from 'downloadjs';
import uuid from 'uuid';
import iziToast from 'izitoast';
import Master23TarifLainService from './Master23TarifLain.service';
import Master23TarifService from './Master23Tarif.service';

@connect((state)=> ({
  ...Master23TarifService.stateConnectSetting()(state),
  spt: state.auth.currentSPT
}), Master23TarifService.actionConnectSetting())
export default class Master23TarifAdminView extends ListView {
  service=Master23TarifService
  FormDialog=()=> <div/>

  rootPath = '/master/tarif-23-admin'
  apiPath = 'page';
  addDialog=false
  editDialog=false

  columns=[
    {isSortable: true, label: "word.company",  value: "company.nama", show:true, isSearchable:true},
    {isSortable: true, label: "word.startDate",  value: "start", show:true, isSearchable:true},
    {isSortable: true, label: "word.endDate",  value: "end", show:true, isSearchable:true},
  ]

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='Nama Perusahaan'
          name='companyNama__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Dimulai'
          name='start__equals'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Selesai'
          name='end__equals'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
      </this.SearchContainer>
    )
  }
}
