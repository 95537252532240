import React, { Component } from 'react';
import { Grid, Cell } from 'react-md';
import { Field, reduxForm , propTypes, formValueSelector } from 'redux-form';
import { validation, Textfield, TextfieldMask, Datepicker, convert, Multiselect } from '../../../../components/form';
import FormView from '../../../../components/entity/form.view';
import AccessService from './Access.service';
import PermissionsService from './../Permissions/Permissions.service';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  Tab,
  Tabs,
  SelectionControlGroup,
  Checkbox,
  Button,
  FontIcon
} from 'react-md';

import _ from 'lodash';

@reduxForm({form: 'access', destroyOnUnmount: false})
export default class AccessForm extends FormView {
  service=AccessService
  initialData = {
  }

  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      permissions: [],
      checkAll: false
    }
  }

  componentDidMount = async () => {
    this.initData()
    let permissions = await PermissionsService.api.find();
    let _permissions = _.cloneDeep(permissions.data)
    _permissions.map(function (i){
      i.checked = false
    })
    this.setState({permissions: _permissions})
  }

  handleCheck(index){
    let permissions = this.state.permissions
    let _permissions = _.cloneDeep(permissions)
    _permissions[index].checked = !_permissions[index].checked
    this.setState({permissions: _permissions})
  }

  handleCheckAll(){
    let checked = !this.state.checkAll
    let permissions = this.state.permissions
    let _permissions = _.cloneDeep(permissions)
    _permissions.map(function (i){ i.checked = checked })
    this.setState({permissions: _permissions, checkAll: checked})
  }

  async handleSave(value) {
    let permissions = this.state.permissions.filter(function (i){ return i.checked == true })
    value.permissions = permissions
    try {
      this.setState({onProgress: true})
      if(value.id) {
        await this.service.api.update(value)
      } else {
        await this.service.api.save(value)
      }
      this.setState({onProgress: false})

      var pathArray = this.props.location.pathname.split('/');
      var path = '';
      pathArray.forEach((d, i)=> {
        if(pathArray.length-1 != i) {
          path = path+d;
          if(i != pathArray.length-2) path = path+'/'
        }
      })

      this.props.history.push(path)
    } catch(e) {
      var msg = e.message;
      if(e.response) msg = e.response.data.message;
      this.setState({
        isError: true,
        onProgress: false,
        errorMessage: msg
      })
    }
  }

  formView() {
    const _this = this
    return (
      <div>
        <Grid style={{padding:'none'}}>
          <Field
            label='Nama'
            name='name'
            className="md-cell md-cell--12"
            component={Textfield}
            validate={validation.required}
          />
          <Field
            label='Keterangan'
            name='description'
            className="md-cell md-cell--12"
            component={Textfield}
            validate={validation.required}
          />
        </Grid>
        <div className="mpk-layout flex column fill">
          <DataTable plain>
            <TableHeader>
              <TableRow>
                <TableColumn style={{textAlign: "center", width: "100px"}}>
                  <Checkbox id="checkAll" name="checkAll" checked={this.state.checkAll} onChange={_this.handleCheckAll.bind(_this)} />
                </TableColumn>
                <TableColumn>PERMISSIONS</TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody>
              {this.state.permissions.map(function (i, index){
                return (
                  <TableRow>
                    <TableColumn style={{textAlign: "center", width: "100px"}}>
                      <Checkbox id={index} name={index} checked={i.checked} onChange={_this.handleCheck.bind(_this, index)} />
                    </TableColumn>
                    <TableColumn>{i.name}</TableColumn>
                  </TableRow>
                )
              })}
            </TableBody>
          </DataTable>
        </div>
      </div>
    )
  }
}
