import React, { Component } from 'react';
import { connect } from 'react-redux';

import FormDialog from './TemplateRole.dialog';
import TemplateRoleService from './TemplateRole.service';
import ListView from '../../../../components/entity/listView';
import authService from '../../../../services/auth.service';

@connect((state)=> ({
  ...TemplateRoleService.stateConnectSetting()(state),
  user: state.auth.user,
  company: state.auth.currentCompany,
}), TemplateRoleService.actionConnectSetting())
export default class TemplateRoleView extends ListView {
  service=TemplateRoleService
  FormDialog=FormDialog
  apiPath='page'

  beforeFetch(params) {
    if(this.props.match.params.companyId) {
      if(this.props.match.params.companyId != '') {
        params.companyId = this.props.match.params.companyId
      }
    }

    if(this.props.user.company) {
      params.companyId = this.props.user.company.id
    }
  }

  columns=[
    {label: "word.name",  value: "name", isDefaultSort:true,  isSortable:true, show:true, isSearchable:true},
    {label: "word.note", value: "note", className: "mpk-font-size-S", show:true, isSearchable:true},
    {label: "word.company", value: "company.nama", className: "mpk-font-size-S", show:true, isSearchable:true}
  ]
}
