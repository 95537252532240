import React, { Component } from 'react';
import { connect } from 'react-redux';

import FormDialog from './MasterCustomImport.dialog.view';
import MasterCustomImportService from './MasterCustomImport.service';
import ListView from '../../../../components/entity/listView';

@connect(MasterCustomImportService.stateConnectSetting(), MasterCustomImportService.actionConnectSetting())
export default class MasterCustomImportView extends ListView {
  service=MasterCustomImportService
  FormDialog=FormDialog

  defaultColumns = [
    {isSortable:true, label: "word.id",  value: "id", show:false, isSearchable:true, isDefaultSort: false},
  ]

  columns=[
    {label: "word.pasal",  value: "masterImportCategory.masterPasal.name", isDefaultSort:false, show:true, isSearchable:false},
    {label: "word.category",  value: "masterImportCategory.label", isDefaultSort:false, show:true, isSearchable:false},
    {label: "word.name",  value: "name", isDefaultSort:false, show:true, isSearchable:true},
    {label: "word.type", type: 'func',  value: (d) => {
      return (d.multiple) ? 'MULTIPLE' : 'SINGLE';
    }, isDefaultSort:false, show:true, isSearchable:true},
  ]

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='Nama'
          name='name__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        {/*<this.ig.Field
          label='MULTIPLE'
          name='multiple__in_1'
          className="md-cell md-cell--6"
          component={this.ig.Checkbox}
        />
        <this.ig.Field
          label='SINGLE'
          name='multiple__in_0'
          className="md-cell md-cell--6"
          component={this.ig.Checkbox}
        />*/}
      </this.SearchContainer>
    )
  }
}
