import ApiService from '../../../../../services/api.service';

class SettingEbupotService extends ApiService {
  name= 'SettingEbupot';
  path= 'setting/ebupot/page';

  constructor() {
    super();
    this.init();
    this.initApi()
  }

  api = {
    getByCompany: async (companyId)=> {
      return await this.http.get(`${this.apiLocation}/setting/ebupot/${companyId}/company`)
    },
    updateByCompany: async (formData)=> {
      return await this.http.put(`${this.apiLocation}/setting/ebupot`, formData)
    }
  }
}


export default new SettingEbupotService();
