

export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_USER = 'ROLE_USER';
export const ROLE_OWNER = 'ROLE_OWNER';

export const LEVEL = {
  ROLE_ADMIN: 1,
  ROLE_OWNER: 5,
  ROLE_USER: 10
}

