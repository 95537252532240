import React from 'react';
import FormView from '../../../../../components/entity/form.view';
import { Field, reduxForm, getFormValues} from 'redux-form';
import { CardTitle, Avatar, Button, Card, FontIcon, TabsContainer, Tab, Tabs, ExpansionList, ExpansionPanel, DataTable, TableHeader, TableBody, TableRow, TableColumn, Switch, Checkbox } from 'react-md';
import BP15PihakLainDipotongView from './PihakLainDipotong/BP15PihakLainDipotong.view';
import BP15DipotongPihakLainView from './DipotongPihakLain/BP15DipotongPihakLain.view';
import BP15PPHPasal24View from './PPHPasal24/BP15PPhPasal24.view';

@reduxForm({form: 'BP15MainForm', destroyOnUnmount: true})
export default class BP15MainForm extends FormView {
  translate = false;
  viewType=2

  titleHeader() {
    return `DAFTAR BUKTI POTONG PPH PASAL 15`;
  }

  barItem() {

  }

  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      activeTab: 0,
      activeTab2: 0
    }
  }

  initData() {

  }

  commandBar() {
    return (
      <div>
        {this._commandBar()}
        <div className='mpk-commandbar mpk-layout align-center justify-between mpk-border bottom solid dark' style={{height: 48, background: '#f8f8f8'}}>
        <Tabs tabId="organization-tab" inactiveTabClassName="md-text--secondary" onTabChange={(activeTabIndex)=> {
          this.setState({
            activeTab:activeTabIndex
          })
        }} >
          <Tab style={{maxWidth: 1000}} label="Pemotongan PPh Pasal 15/Penerima Atau Yang Memperoleh Imbalan" />
          <Tab label="Perhitungan PPh Pasal 24" />
        </Tabs>
        </div>

         {this.state.activeTab === 0 &&
          <div>
            <div className='mpk-commandbar mpk-layout align-center justify-between mpk-border top solid dark' style={{height: 48, background: '#f8f8f8'}}>
            <Tabs tabId="signer-tab" inactiveTabClassName="md-text--secondary" onTabChange={(activeTabIndex)=> {
              this.setState({
                activeTab2:activeTabIndex
              })
            }} >
              <Tab label="PPh Pihak Lain Yang Dipotong" />
              <Tab label="PPh Yang Dipotong Pihak Lain" />
            </Tabs>
            </div>
          </div>
        }
      </div>
    )
  }

  formView() {
    if(this.state.activeTab == 0) {
      if(this.state.activeTab2 == 0) {
        return <BP15PihakLainDipotongView history={this.props.history} match={this.props.match} />
      } else {
        return <BP15DipotongPihakLainView history={this.props.history} match={this.props.match} />
      }
    } else {
      return <BP15PPHPasal24View history={this.props.history} match={this.props.match} />
    }
  }
}
