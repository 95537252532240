import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Field, reduxForm } from 'redux-form';
import { Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab, Avatar, FontIcon, List, ListItem, Subheader } from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Switch as Switch2} from '../../../../../components/form';

import ListView from '../../../../../components/entity/listView';
import ColumnService from '../../../../../services/column.service';
import axios from 'axios';
import izitoast from 'izitoast';
import LogCetakBPNService from './LogCetakBPN.service';
import EbillingAccountService from '../../EbillingAccountService';
import download from 'downloadjs';

@connect((state)=> ({
  ...LogCetakBPNService.stateConnectSetting()(state),
  spt: state.auth.currentSPT,
  user: state.auth.user
}), LogCetakBPNService.actionConnectSetting())
export default class LogCetakBPNView extends ListView {
  service=LogCetakBPNService
  ebillingAccountService=EbillingAccountService
  // viewType=2

  constructor(props){
    super(props);
    this.state = {
      showTableSetting:true,
      showDialogConfirmDeleteSelected:false,
      showForm:false,
      formData: {},
      data: [],
      onProgress: false,
      isError: false,
      errorMessage: ''
    }

    this.state.ebillingUser = {}
  }

  beforeFetch(params) {
    if(params["sortBy"] === "createdAt") {
      params["sortBy"] = "createdDate"
      params["sort"] = "DESC"
    }
  }

  _barActions = [
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => this.fetchData()
    }
  ]

  tableActions = (data) => {
    let actions = [
      {
        label:"Download",
        iconClassName:"mdi mdi-download",
        onClick: (item) => this.download(item)
      }
    ]

    return actions
  }

  columns=[
    {isSortable: true, label: "word.countInvoice", type: "number", value: "jumlahInvoice", show: true, isSearchable: true},
    {isSortable: true, label: "word.countBpn", type: "number", value: "jumlahBpn", show: true, isSearchable: true},
    {isSortable: true, label: "word.status", type: "func", value: (d) => {
      switch (d.status) {
        case 'WAITING':
          return <Button flat swapTheming style={{backgroundColor: '#9e9e9e', color: 'white'}}>{d.status}</Button>
        case 'ON_PROCESS':
          return <Button flat swapTheming style={{backgroundColor: '#0D47A1', color: 'white'}}>{d.status}</Button>
        case 'COMPLETED':
          return <Button flat swapTheming style={{backgroundColor: '#4caf50', color: 'white'}}>{d.status}</Button>
        case 'FAILED':
          return <Button flat swapTheming style={{backgroundColor: '#f44336', color: 'white'}}>{d.status}</Button>
        default:
          break;
      }
    }, show: true, isSearchable: true},
    {isSortable: true, label: "word.pesan", value: "message", show: true, isSearchable: true},
  ]

  async checkConnection() {
    let res = await this.ebillingAccountService.api.me()

    this.setState({ebillingUser: res.data});
  }

  async componentDidMount() {
    this.rootPath = this.props.match.url;

    try {
      this.checkConnection()
    } catch (e){
      this.toast.warning({
        title: e.name,
        message: e.message
      })
    }
  }

  async download(item) {
    if (item.status === 'COMPLETED') {
      var report = await this.service.api.download(item.id)
      var filename = report.headers.filename

      download(report.data, filename);
    } else {
      izitoast.error({
        title: 'Error',
        message: 'Data yang bisa di-download hanya yang berstatus = COMPLETED'
      })
    }
  }

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='Status'
          name='status'
          className="md-cell md-cell--12"
          component={this.ig.Searchfield}
          options={[
            {id: null, name: 'ALL'},
            {id: 'WAITING', name: 'WAITING'},
            {id: 'ON_PROCESS', name: 'ON_PROCESS'},
            {id: 'COMPLETED', name: 'COMPLETED'},
            {id: 'FAILED', name: 'FAILED'}
          ]}
        />
      </this.SearchContainer>
    )
  }

  // right bar
  tabWidth = 280
  tabs= ["status", "search", "column"]

  tabComponents() {
    var obj = this;
    return {
      status: this.status.bind(obj)
    }
  }

  status() {
    const { handleSubmit, submitting, valid, pristine } = this.props;
    return (
      <div className='mpk-layout column fill'>
        <div className='flex'>
          <List className="">
            <Subheader primaryText="Status Koneksi" />

            { this.state.ebillingUser.login &&
              <ListItem
                leftAvatar={<Avatar  suffix="green" icon={<FontIcon>done</FontIcon>} />}
                primaryText="Terhubung"
                secondaryText={"Terhubung sebagai " + this.state.ebillingUser.login + "."}
              />
            }

            { !this.state.ebillingUser.login &&
              <ListItem
                leftAvatar={<Avatar suffix="red" icon={<FontIcon>clear</FontIcon>} />}
                primaryText="Tidak Terhubung"
                secondaryText="Status Koneksi"
              />
            }

            {/*<Subheader primaryText="Status Sistem" />

            <ListItem
              leftAvatar={<Avatar  suffix="green" icon={<FontIcon>done</FontIcon>} />}
              primaryText="Sistem Active"
              secondaryText="Status Sistem"
            />*/}
          </List>
        </div>
      </div>
    )
  }

}
