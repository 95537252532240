import ApiService from '../../../../services/api.service';

class CompanyService extends ApiService {
  name= 'company';
  path= 'company';
  constructor() {
    super();
    this.init()
  }
}


export default new CompanyService();
