import React, { Component } from 'react';
import { connect } from 'react-redux';
import Bluebird from 'bluebird';

import {Field, getFormValues, reduxForm} from 'redux-form';
import { Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab } from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Switch as Switch2} from '../../../../../../components/form';

import ImportBp25Service from './ImportBp25.service';
import ListView from '../../../../../../components/entity/listView';
import ColumnService from '../../../../../../services/column.service';
import ImportService from './../../../21_26/Import/Import.service';
import axios from 'axios';
import izitoast from 'izitoast';
import FormDialog from './ImportBp25.dialog';
import download from "downloadjs";
import ImportDialogResult from '../../../../../../components/entity/import.dialog.result';
import ListViewImport from '../../../../../../components/entity/ListViewImport';

@reduxForm({form: 'IMPORT_25_BP_Form', destroyOnUnmount: true})
@connect((state) => ({
  ...ImportBp25Service.stateConnectSetting()(state),
  formData: getFormValues('IMPORT_25_BP_Form')(state)
}), ImportBp25Service.actionConnectSetting())
export default class ImportBp25View extends ListViewImport {
  service=ImportBp25Service
  FormDialog=FormDialog

  category='BP_25'
  importPath='/api/import/spt/25'
  showAutomatisTab=false
  showPenomoranAuto=false
  showFormatEspt=false
  showPembetulan=true
  // viewType=2

  beforeFetch(params) {
    params["category.equals"] = "BP_25"
  }

  async handleDownloadTemplate() {
    var report = await ImportBp25Service.api.downloadTemplate()
    download(report.data, 'TEMPLATE_DAFTAR_ANGSURAN_25.csv');
  }

}
