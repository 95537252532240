import React from 'react';
import { Route, Switch } from 'react-router-dom';

export default (props) => (
  <Switch>
    {/* <Route path={props.match.path+'/BP_EBUPOT_23/:id'}  component={require('./BP_Ebupot_23/BP_Ebupot_23.form').default} />
    <Route path={props.match.path+'/BP_EBUPOT_23'}      component={require('./BP_Ebupot_23/BP_Ebupot_23.view').default} />

    <Route path={props.match.path+'/BP_EBUPOT_26/:id'}  component={require('./BP_Ebupot_26/BP_Ebupot_26.form').default} />*/}
    {/* <Route path={props.match.path+'/bp-23/:id'}      component={require('./bp23/bp23.form.view').default} /> 
    <Route path={props.match.path+'/bp-23'}      component={require('./bp23/bp23.view').default} /> 

    <Route path={props.match.path+'/bp-26/:id'}      component={require('./bp26/bp26.form.view').default} /> 
    <Route path={props.match.path+'/bp-26'}      component={require('./bp26/bp26.view').default} /> */}

    <Route path={props.match.path+'/bpe'} component={require('./../Bpe/Bpe.view').default} />
    <Route path={props.match.path+'/log-file'} component={require('./../../LogFile/LogFile.view').default} />

    <Route path={props.match.path+'/bp-26-edit/:id'}      component={require('./Bupot26InEdit/Bupot26InEdit.form.view').default} /> 
    <Route path={props.match.path+'/bp-26-edit'}      component={require('./Bupot26InEdit/Bupot26InEdit.view').default} /> 

    <Route path={props.match.path+'/bp-23-edit/:id'}      component={require('./Bupot23InEdit/Bupot23InEdit.form.view').default} /> 
    <Route path={props.match.path+'/bp-23-edit'}      component={require('./Bupot23InEdit/Bupot23InEdit.view').default} /> 

    <Route path={props.match.path+'/bp-26-pembetulan/:id'}      component={require('./Bupot26InPembetulan/Bupot26InPembetulan.form.view').default} /> 
    <Route path={props.match.path+'/bp-26-pembetulan'}      component={require('./Bupot26InPembetulan/Bupot26InPembetulan.view').default} /> 

    <Route path={props.match.path+'/bp-23-pembetulan/:id'}      component={require('./Bupot23InPembetulan/Bupot23InPembetulan.form.view').default} /> 
    <Route path={props.match.path+'/bp-23-pembetulan'}      component={require('./Bupot23InPembetulan/Bupot23InPembetulan.view').default} /> 

    <Route path={props.match.path+'/bp-23/:id'}      component={require('./Bupot23In/Bupot23In.form.view').default} /> 
    <Route path={props.match.path+'/bp-23'}      component={require('./Bupot23In/Bupot23In.view').default} /> 

    <Route path={props.match.path+'/bp-26/:id'}      component={require('./Bupot26In/Bupot26In.form.view').default} /> 
    <Route path={props.match.path+'/bp-26'}      component={require('./Bupot26In/Bupot26In.view').default} />

    <Route path={props.match.path+'/ssp/:id'}      component={require('./ssp/ssp.form.view').default} /> 
    <Route path={props.match.path+'/ssp'}      component={require('./ssp/ssp.view').default} />  

    <Route path={props.match.path+'/pbk/:id'}      component={require('./pbk/pbk.form.view').default} /> 
    <Route path={props.match.path+'/pbk'}      component={require('./pbk/pbk.view').default} />  

    <Route path={props.match.path+'/induk'}      component={require('./induk/induk.form.view').default} />  

    <Route path={props.match.path+'/'} component={function() {return <div></div>}} />
  </Switch>
)
