import ApiService from '../../../../services/api.service';

class MasterTarifPasal17BerlapisService extends ApiService {
  name= 'masterTarifPasal17Berlapis';
  path= 'master/tarif-pasal-17-berlapis';
  constructor() {
    super();
    this.init()
  }
}


export default new MasterTarifPasal17BerlapisService();
