import ApiService from '../../../../../services/api.service';

class Master21GolonganA2Service extends ApiService {
  name= 'Master21GolonganA2';
  path= 'master/spt21/status-golongan-a2';
  constructor() {
    super();
    this.init()
  }
}


export default new Master21GolonganA2Service();
