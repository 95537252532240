import ApiService from '../../../../../services/api.service';

class Master21Pasal17ProgService extends ApiService {
  name= 'Master21Pasal17Prog';
  path= 'master/tarif-21-pasal-17-prog';

  constructor() {
    super();
    this.init()
  }
}


export default new Master21Pasal17ProgService();

