import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import {Card} from 'react-md';
import DataInquiryService from './DataInquiry.service';
import {Textfield, TextfieldMask, validation} from '../../../../components/form';
import NpwpService from './../../../Main/pph/NpwpService';
import FormView from '../../../../components/entity/form.view';

@reduxForm({form: 'DataInquiry_Form', destroyOnUnmount: true})
@connect((state) => ({
  access: state.entity.access.api.find,
  formData: state.form.DataInquiry_Form
}))
export default class DataInquiryForm extends FormView {
  service = DataInquiryService
  viewType = 2;

  initialData = {}

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      npwpNotValid: true
    }
  }

  async npwpValidation(evt, value) {
    if (value) {
      var res = await NpwpService.api.validate(value)

      if (res.valid) {
        this.setState({npwpNotValid: false})
      } else {
        this.setState({npwpNotValid: true})

        this.toast.warning({title: "Validasi Npwp", message: "npwp tidak valid"})
      }
    }
  }

  async beforeSave(value) {
    return true;
  }

  async initData() {
    if (this.props.match.params.id == 'new') {
      this.props.initialize(this.initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id);
      this.props.initialize(res.data);
      this.npwpValidation(null, res.data.npwp)
    }
  }

  formView() {
    var formData = {};
    if (this.props.formData && this.props.formData.values) formData = this.props.formData.values
    return (
      <div>
        <Card>
          <div className="md-grid">
            <div className='md-cell md-cell--6'>
              <Field
                label='NPWP'
                name='npwp'
                id="DataInquiryForm-npwp"
                component={TextfieldMask}
                maskFormat="##.###.###.#-###-###"
                mask="_"
                length={15}
                onChange={this.npwpValidation.bind(this)}
                validate={validation.required}
              />
              <Field
                label='Nama'
                name='name'
                disabled={true}
                component={Textfield}
              />
            </div>
            <div className='md-cell md-cell--6'>
              <Field
                label='Kota'
                name='city'
                disabled={true}
                component={Textfield}
              />
              <Field
                label='Alamat'
                name='address'
                disabled={true}
                component={Textfield}
              />
            </div>
          </div>
        </Card>
      </div>
    )
  }

}
