import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Button, SVGIcon } from 'react-md';
import ListView from '../../../../../../components/entity/listView';
import {Switch} from 'react-md';
import iziToast from 'izitoast';
import ColumnService from '../../../../../../services/column.service';
import AllJasaKonstruksi4a2Service from '../AllJasaKonstruksi4a2/AllJasaKonstruksi4a2.service';
import LogExportService from "modules/Main/Export/LogExport.service";
import download from "downloadjs";
import ParamService from "../../../../../../services/param.service";
import {Checkbox} from "../../../../../../components/form";
import {navService} from 'react-mpk';
import authService from "../../../../../../services/auth.service";
import {initialize} from "redux-form";

@connect(AllJasaKonstruksi4a2Service.stateConnectSetting(), AllJasaKonstruksi4a2Service.actionConnectSetting())
export default class AllJasaKonstruksi4a2View extends ListView {
  service=AllJasaKonstruksi4a2Service

  constructor(props){
    super(props);
    this.state = {
      showTableSetting:false,
      showDialogConfirmDeleteSelected:false,
      showForm:false,
      formData: {},
      data: []
    }

    authService.api.changeMenuType('main', this.props.dispatch);
  }

  componentDidMount() {
    var initParam = Object.keys(this.props.filterObj).reduce((pv, key) => {
      var newKey = key.replace('.', '__')
      pv[newKey] = this.props.filterObj[key]
      return pv;
    }, {})

    this.props.dispatch(initialize(this.service.name + "_search", initParam))
  }

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='NPWP Organisasi'
          name='orgNpwp__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Dari Bulan'
          name='masa__greaterOrEqualThan'
          className="md-cell md-cell--6"
          component={this.ig.Searchfield}
          options={[
            {id: 1, name: "1"},
            {id: 2, name: "2"},
            {id: 3, name: "3"},
            {id: 4, name: "4"},
            {id: 5, name: "5"},
            {id: 6, name: "6"},
            {id: 7, name: "7"},
            {id: 8, name: "8"},
            {id: 9, name: "9"},
            {id: 10, name: "10"},
            {id: 11, name: "11"},
            {id: 12, name: "12"}
          ]}
        />
        <this.ig.Field
          label='Sampai Bulan'
          name='masa__lessOrEqualThan'
          className="md-cell md-cell--6"
          component={this.ig.Searchfield}
          options={[
            {id: 1, name: "1"},
            {id: 2, name: "2"},
            {id: 3, name: "3"},
            {id: 4, name: "4"},
            {id: 5, name: "5"},
            {id: 6, name: "6"},
            {id: 7, name: "7"},
            {id: 8, name: "8"},
            {id: 9, name: "9"},
            {id: 10, name: "10"},
            {id: 11, name: "11"},
            {id: 12, name: "12"}
          ]}
        />

        <this.ig.Field
          label='Bulan'
          name='masa__equals'
          className="md-cell md-cell--12"
          component={this.ig.Searchfield}
          options={[
            {id: 1, name: "1"},
            {id: 2, name: "2"},
            {id: 3, name: "3"},
            {id: 4, name: "4"},
            {id: 5, name: "5"},
            {id: 6, name: "6"},
            {id: 7, name: "7"},
            {id: 8, name: "8"},
            {id: 9, name: "9"},
            {id: 10, name: "10"},
            {id: 11, name: "11"},
            {id: 12, name: "12"}
          ]}
        />

        <this.ig.Field
          label='Tahun'
          name='tahun__equals'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Pembetulan'
          name='pembetulan__equals'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='NTPN'
          name='ntpn__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Pembuat'
          name='createdUserLogin__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          className="md-cell md-cell--12"
          label     = 'PROCESS'
          name      = 'statusProcess__equals'
          component = {Checkbox}
        />
        <this.ig.Field
          className="md-cell md-cell--12"
          label     = 'WAITING'
          name      = 'statusWaiting__equals'
          component = {Checkbox}
        />
        <this.ig.Field
          className="md-cell md-cell--12"
          label     = 'FINISH'
          name      = 'statusFinish__equals'
          component = {Checkbox}
        />
      </this.SearchContainer>
    )
  }

  editDialog = false;
  _barActions = [];

  editItem = (item) => {
    let sptId = item.spt.id
    let id = item.id

    navService.redirectTo(`/SPT_4a2/${sptId}/open/jasa_konstruksi/${id}`)
  }

  async handleExportAll() {
    let selected = this.props.table.selected

    if (selected.length > 0) {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export CSV',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: 'Apakah anda yakin akan meng-export CSV data yang diceklis?'
      }));

      if(dialog) {
        let ids = [];

        selected.map(function (i) {
          ids.push(i.id)
        });

        try {
          let res = await LogExportService.api.exportBulkCsv('spt4a2/sendiri-jasa-konstruksi', ids);

          let filename = res.headers.filename;

          download(res.data, filename);
        } catch (e) {
          iziToast.warning({
            title: e.name,
            message: e.message
          })
        }
      }
    }
    else {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export CSV',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: (
          <div>
            <this.ig.Field
              label='NPWP Organisasi'
              name='orgNpwp__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Bulan'
              name='masa__equals'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Tahun'
              name='tahun__equals'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Pembetulan'
              name='pembetulan__equals'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='NTPN'
              name='ntpn__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
          </div>
        )
      }));

      if (dialog === false)
        return

      if (dialog === undefined)
        dialog = {}

      if (dialog) {
        let param = ParamService.convert(dialog)

        try {
          let res = await LogExportService.api.exportByParamCsv('spt4a2/sendiri-jasa-konstruksi', param);

          let filename = res.headers.filename;

          download(res.data, filename);
        } catch (e) {
          iziToast.warning({
            title: e.name,
            message: e.message
          })
        }
      }
    }
  }

  barActions() {
    return [
      ...this._barActions,
      {
        label:'word.exportCsv',
        iconClassName:'mdi mdi-file-excel',
        // disabledFunc:() => this.props.table.selected.length === 0,
        onClick:() => this.handleExportAll()
      }
    ]
  }

  columns=[
    {isSortable: true, label: "word.npwpProfile", value:"spt.organization.npwp", show: true, isSearchable: true},
    {isSortable: true, label: "word.year", searchField: "spt.year", value: (d)=> d.spt.year, type: "func", show: true, isSearchable: true},
    {isSortable: true, label: "word.month", value: "spt.month", type: "number", show: true, isSearchable: true},
    {isSortable: true, label: "word.pembetulan", value: "spt.pembetulan", type: "number", show: true, isSearchable: true},
    {isSortable: true, label: "word.date",  value: "tanggal", show:true, isSearchable:true},
    {isSortable: true, label: "word.ntpp",  value: "ntpn", show:true, isSearchable:true},
    {isSortable: true, label: "word.jumlahPenghasilan",  value: "totalBruto", show:true, isSearchable:true, type: 'number'},
    {isSortable: true, label: "word.pph",  value: "totalPPh", show:true, isSearchable:true, type: 'number'},
  ]

  _tableActions = [
    {
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    }
  ]

}
