import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm, getFormValues} from 'redux-form';
import moment from 'moment';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  CardActions,
  Button,
  LinearProgress
} from 'react-md';

import FormView from '../../../../../components/entity/form.view';
// import NomorBPService from '../nomorBP.service';
import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Checkbox} from '../../../../../components/form';
import UtilService from '../../../../../services/utils.service'
// import SPT21SignerService from '../SPT21Signer/SPT21Signer.service';
import NpwpService from './../../NpwpService';
import FormViewSpt from '../../../../../components/entity/form.view.spt';
import terbilangService from '../../../../../services/terbilang.service';
import BP4a2Service from './BP4a2.service';

import BP4a2HadiahForm from './form/BP4a2Hadiah.form';
import BP4a2BungaDepositoForm from './form/BP4a2BungaDeposito.form';
import BP4a2BungaDiskontoForm from './form/BP4a2BungaDiskonto.form';
import BP4a2BungaSimpananKoperasiForm from './form/BP4a2BungaSimpananKoperasi.Form';
import BP4a2DividenForm from './form/BP4a2Dividen.form';
import BP4a2JasaKonstruksiForm from './form/BP4a2JasaKonstruksi.form';
import BP4a2TransaksiDeviratifForm from './form/BP4a2TransaksiDeviratif.form';
import BP4a2PenjualanSahamForm from './form/BP4a2PenjualanSaham.form';
import BP4a2PersewaanTanahForm from './form/BP4a2PersewaanTanah.form';
import BP4a2WpPeredaranTertentuForm from './form/BP4a2WpPeredaranTertentu.form';
import Tarif4a2Service from '../master/tarif/Tarif4a2.service';
import LawanTransaksiDialog from '../../../../../components/entity/LawanTransaksi.dialog';
import MasterLawanTransaksi4a2Service from '../../../Master/MasterLawanTransaksi4a2/MasterLawanTransaksi4a2.service';


@reduxForm({form: 'BP_4a2_Form', destroyOnUnmount: true, initialValues: {}})
@connect((state) => ({
  access      : state.entity.access.api.find,
  organization: state.auth.currentOrganization,
  spt         : state.auth.currentSPT,
  formData    : getFormValues('BP_4a2_Form')(state)
}))
export default class BP4a2Form extends FormViewSpt {
  service  = BP4a2Service
  viewType = 2;

  typingObj=null;
  typingTimeout=1000;


  initialData={
  }

  constructor(props) {
    super(props);

    this.state= {
      ...this.state,
      showCalculateProgress: false,
      npwpNotValid         : true,
      defaultNo: null,

      lkDialog: false,
      tarif   : {
        "hadiahUndian"                               : 25.0,
        "bungaDeposito"                              : 20.0,
        "penjualanSahamPendiri"                      : 0.5,
        "penjualanSahamBukanPendiri"                 : 0.1,
        "sewaTanahDanBangunan"                       : 10.0,
        "penyediaJasaKonstruksiKualifikasi"          : 2.0,
        "penyediaJasaKonstruksiTidakPunyaKualifikasi": 4.0,
        "penyediaJasaKonstruksiLain"                 : 3.0,
        "jasaPerancanganPunyaKualifikasi"            : 4.0,
        "jasaPerancanganTidakPunyaKualifikasi"       : 6.0,
        "bungaDanDiskontoObligasi"                   : 15.0,
        "bungaSimpanan"                              : 10.0,
        "penghasilanTransaksiDerivatif"              : 0.0,
        "dividenDiterima"                            : 10.0,
        "peredaranTertentu"                          : 0.5,
      }
    }
  }

  typeOptions= [
    {
      name   : 'Bunga Deposito/Tabungan, Diskonto SBI, Jasa Giro,',
      objName: 'bungaDeposito',
      id     : 'BUNGA_DEPOSITO'
    },
    {
      name   : 'Bunga dan/atau Diskonto Obligasi dan Surat Berharga Negara (SBN)',
      objName: 'bungaDiskonto',
      id     : 'BUNGA_DISKONTO'
    },
    {
      name   : 'Bunga Simpanan yang dibayarkan oleh Koperasi kepada Anggota Koperasi Orang Pribadi',
      objName: 'bungaSimpanan',
      id     : 'BUNGA_SIMPANAN'
    },
    {
      name   : 'Dividen yang Diterima atau Diperoleh Wajib Pajak Orang Pribadi Dalam Negri',
      objName: 'dividenDiterima',
      id     : 'DIVIDEN_DITERIMA'
    },
    {
      name   : 'Hadiah Undian',
      objName: 'hadiahUndian',
      id     : 'HADIAH_UNDIAN'
    },
    {
      name   : 'Penghasilan dari Usaha jasa Konstruksi',
      objName: 'jasaKonstruksi',
      id     : 'JASA_KONSTRUKSI'
    },
    {
      name   : 'Penghasilan dari Transaksi Derivatif berupa Kontrak Berjangka yang Diperdagangkan di Bursa',
      objName: 'transaksiDerivatif',
      id     : 'TRANSAKSI_DERIVATIF'
    },
    {
      name   : 'Penghasilan dari Transaksi Penjualan Saham yang Diperdagangkan di Bursa Efek',
      objName: 'penjualanSaham',
      id     : 'PENJUALAN_SAHAM'
    },
    {
      name   : 'Penghasilan dari persewaan Tanah dan/atau Bangunan',
      objName: 'persewaanTanah',
      id     : 'PERSEWAAN_TANAH'
    },
    {
      name   : 'Penghasilan dari Usaha yang Diterima Oleh Wajib Pajak yang Memiliki Peredaran Bruto Tertentu',
      objName: 'peredaranTertentu',
      id     : 'WP_PEREDARAN_TERTENTU'
    },
  ]

  async npwpValidation(evt, value){
    if(value){
      if(this.typingObj) clearTimeout(this.typingObj);

      this.typingObj = setTimeout(async ()=> {
        // if(value){
          var res = await NpwpService.api.validate(value)
          if(res.valid){
            this.setState({npwpNotValid: false})

            var lk = await MasterLawanTransaksi4a2Service.api.page({
              'npwp.equals': value,
              'organizationId.equals': this.props.spt.data.organization.id
            })

            if(lk.data) {
              var formData = {...this.props.formData};

              if(lk.data.length > 0) {
                var d = lk.data[0];
                formData.penerimaPenghasilan = {
                  "npwp"   : d.npwp,
                  "nik"    : d.nik,
                  "nama"   : d.nama,
                  "alamat" : d.alamat,
                  "telepon": d.telepon,
                  "kodePos": d.kode_pos,
                  "email"  : d.email
                };

              } else {
                formData.penerimaPenghasilan = {
                  "npwp" : value
                }
                this.toast.info({title: "Info", message: `Lawan Transaksi dengan npwp ${value} tidak ditemukan`})
              }
              this.props.initialize(formData);
            }
          } else {
            this.setState({npwpNotValid: true})
            this.toast.warning({title: "Validasi Npwp", message: "npwp tidak valid"})
          }
        // }
      }, this.typingTimeout);

      var nonNpwp = 0;
      if(value === '000000000000000' || value == '' || !value) {
        nonNpwp = 1;
      }

      var form = this.props.formData;
      var d    = form.bagB;
      if(d) {
        Object.keys(d).forEach((k, i) => {
          if(!(k == 'jmlBruto' || k == 'jmlPph' || k == 'terbilang')) {
            var x = d[k];

            x.nonNpwp = nonNpwp
          }
        })

        await this.props.change('bagB', d)
      }

    }
  }

  async beforeSave(value) {
    /*if (value.type === "WP_PEREDARAN_TERTENTU") {
      let defaultNo = this.state.defaultNo
      let ntpn = value.no

      if (ntpn.length !== 16) {
        this.toast.warning({title:'Warning', message:'NTPN harus 16 digit.'})
        return false
      }
      else {
        if (ntpn === defaultNo) {
          this.toast.warning({title:'Warning', message:'NTPN belum diubah.'})
          return false
        }
      }

      let letterNumber = /^[0-9A-Z]+$/;

      if (!ntpn.match(letterNumber)) {
        this.toast.warning({title:'Warning', message:'NTPN harus hanya berupa angka dan huruf.'})
        return false
      }
    }*/

    if(!value.type && value.type === '') {
      this.toast.warning({title:'Warning', message:'Jenis Bukti Potong harus diisi.'})
      return false;
    }
    if(value.bruto == 0 || value.bruto == '') {
      this.toast.warning({title:'Warning', message:'Isi bruto harus lebih besar dari 0'})
      return false;
    }

    if (value.type === 'HADIAH_UNDIAN') {
      let hadiahUndian = value.hadiahUndian

      if (hadiahUndian.b01.bruto > 0 && hadiahUndian.b01.jenisHadiahUndian.trim() === '') {
        this.toast.warning({title:'Warning', message:'Jenis hadiah undian 1 harus diisi jika bruto 1 diisi.'})
        return false;
      }

      if (hadiahUndian.b02.bruto > 0 && hadiahUndian.b02.jenisHadiahUndian.trim() === '') {
        this.toast.warning({title:'Warning', message:'Jenis hadiah undian 2 harus diisi jika bruto 2 diisi.'})
        return false;
      }

      if (hadiahUndian.b03.bruto > 0 && hadiahUndian.b03.jenisHadiahUndian.trim() === '') {
        this.toast.warning({title:'Warning', message:'Jenis hadiah undian 3 harus diisi jika bruto 3 diisi.'})
        return false;
      }

      if (hadiahUndian.b04.bruto > 0 && hadiahUndian.b04.jenisHadiahUndian.trim() === '') {
        this.toast.warning({title:'Warning', message:'Jenis hadiah undian 4 harus diisi jika bruto 4 diisi.'})
        return false;
      }

      if (hadiahUndian.b05.bruto > 0 && hadiahUndian.b05.jenisHadiahUndian.trim() === '') {
        this.toast.warning({title:'Warning', message:'Jenis hadiah undian 5 harus diisi jika bruto 5 diisi.'})
        return false;
      }

      if (hadiahUndian.b06.bruto > 0 && hadiahUndian.b06.jenisHadiahUndian.trim() === '') {
        this.toast.warning({title:'Warning', message:'Jenis hadiah undian 6 harus diisi jika bruto 6 diisi.'})
        return false;
      }
    }

    this.typeOptions.forEach((d, i)=> {
      if(value.type !== d.id) {
        delete value[d.objName];
      }
    })

    var date;
    if(typeof value.tanggal === 'string') {
      if(value.tanggal.search('/') != -1) {
        date = moment(value.tanggal, 'DD/MM/YYYY');
      } else {
        date = moment(value.tanggal, 'YYYY-MM-DD');
      }
    } else if(typeof value.tanggal === 'object') {
      date = moment(value.tanggal)
    }

    if (value.type !== 'WP_PEREDARAN_TERTENTU') {
      if(!(date.year() == this.props.spt.data.year && date.month()+1 == this.props.spt.data.month)) {
        this.toast.warning({title:'Warning', message:'Tanggal bukti potong harus sesuai dengan masa SPT'})
        return false;
      }
    }

    return true;
  }

  async afterSave(res, val) {
    var formData = this.props.formData;

    var data = {
      ...formData.penerimaPenghasilan,
      organization: this.props.spt.data.organization
    }
    if(data.id) delete data.id;
    await MasterLawanTransaksi4a2Service.api.findOrCreate(data)
  }

  async initData() {
    if(this.props.match.params.id == 'new') {
      let nomorBPReq = await this.service.api.getNumberBP(this.props.spt.data.organization.id)
      var nomorBP    = "";

      if(nomorBPReq.data.mode === 'PREF_SUF') {
        nomorBP = nomorBPReq.data.prefix+nomorBPReq.data.suffix;
      } else {
        nomorBP = nomorBPReq.data.suffix+nomorBPReq.data.prefix;
      }
      let signer = {};  //await SPT21SignerService.api.getSigner(this.props.spt.data.id);

      this.setState({'defaultNo': nomorBP})

      // tarif
      var tarif = {};
      try {
        tarif = await Tarif4a2Service.api.byCompany({
          compId: this.props.organization.company.id,
          tgl   : moment().format("YYYY-MM-DD")
        });
      } catch (error) {
        console.error({error})
      }

      if(tarif) {
        await this.setState({'tarif': tarif});
      }

      if(!tarif) {
        tarif = {
          "hadiahUndian"                               : 25.0,
          "bungaDeposito"                              : 20.0,
          "penjualanSahamPendiri"                      : 0.5,
          "penjualanSahamBukanPendiri"                 : 0.1,
          "sewaTanahDanBangunan"                       : 10.0,
          "penyediaJasaKonstruksiKualifikasi"          : 2.0,
          "penyediaJasaKonstruksiTidakPunyaKualifikasi": 4.0,
          "penyediaJasaKonstruksiLain"                 : 3.0,
          "jasaPerancanganPunyaKualifikasi"            : 4.0,
          "jasaPerancanganTidakPunyaKualifikasi"       : 6.0,
          "bungaDanDiskontoObligasi"                   : 15.0,
          "bungaSimpanan"                              : 10.0,
          "penghasilanTransaksiDerivatif"              : 0.0,
          "dividenDiterima"                            : 10.0,
          "peredaranTertentu"                          : 0.5,
        }
      }

      this.initialData = {
        ...this.initialData,
        no             : nomorBP,                        //.data.no,
        namaPemotong   : this.props.organization.name,
        npwpPemotong   : this.props.organization.npwp,
        spt            : this.props.spt.data,
        pph            : 0,
        tanggal        : new Date(),
        bruto          : 0,
        terbilang      : "",
        "bungaDeposito": {
          "depositoBerjangkaRupiah": {
            "jenisPenghasilan": "",
            "bruto"           : 0,
            "tarif"           : tarif.bungaDeposito,
            "pph"             : 0,
            "dnLn"            : "DN"
          },
          "depositoBerjangkaValutaAsingPremiForward": {
            "jenisPenghasilan": "",
            "bruto"           : 0,
            "tarif"           : tarif.bungaDeposito,
            "pph"             : 0,
            "dnLn"            : "DN"
          },
          "depositoBerjangkaValutaAsingTanpaPremiForward": {
            "jenisPenghasilan": "",
            "bruto"           : 0,
            "tarif"           : tarif.bungaDeposito,
            "pph"             : 0,
            "dnLn"            : "DN"
          },
          "sertifikatDeposito": {
            "jenisPenghasilan": "",
            "bruto"           : 0,
            "tarif"           : tarif.bungaDeposito,
            "pph"             : 0,
            "dnLn"            : "DN"
          },
          "tabungan": {
            "jenisPenghasilan": "",
            "bruto"           : 0,
            "tarif"           : tarif.bungaDeposito,
            "pph"             : 0,
            "dnLn"            : "DN"
          },
          "sertifikatBankIndonesia": {
            "jenisPenghasilan": "",
            "bruto"           : 0,
            "tarif"           : tarif.bungaDeposito,
            "pph"             : 0,
            "dnLn"            : "DN"
          },
          "jasaGiro": {
            "jenisPenghasilan": "",
            "bruto"           : 0,
            "tarif"           : tarif.bungaDeposito,
            "pph"             : 0,
            "dnLn"            : "DN"
          },
          "no6": {
            "jenisPenghasilan": "",
            "bruto"           : 0,
            "tarif"           : tarif.bungaDeposito,
            "pph"             : 0,
            "dnLn"            : "DN"
          }
        },

        "bungaDiskonto": {
          "namaObligasi"              : "",
          "jumlahNilaiNominal"        : 0,
          "nomorSeri"                 : "",
          "tingkatBunga"              : 0,
          "tanggalJatuhTempo"         : new Date(),
          "tanggalPerolehan"          : new Date(),
          "tanggalPenjualan"          : moment(this.props.spt.data.year+'-'+this.props.spt.data.month+'-01', "YYYY-MM-DD").toDate(),
          "jumlahHargaPerolehanBersih": 0,
          "jumlahHargaJualBersih"     : 0,
          "diskonto"                  : 0,
          "bunga"                     : 0,
          "tarif"                     : tarif.bungaDanDiskontoObligasi,
          "holdingPeriod"             : 0,
          "timePeriod"                : 0,
          "jumlahBunga"               : 0
        },

        "bungaSimpanan": {
          "bruto": 0,
          "tarif": tarif.bungaSimpanan,
          "pph"  : 0
        },

        "dividenDiterima": {
          "bruto": 0,
          "tarif": tarif.dividenDiterima,
          "pph"  : 0
        },

        "hadiahUndian": {
          "b01": {
            "jenisHadiahUndian": "",
            "bruto"            : 0,
            "tarif"            : tarif.hadiahUndian,
            "pph"              : 0
          },
          "b02": {
            "jenisHadiahUndian": "",
            "bruto"            : 0,
            "tarif"            : tarif.hadiahUndian,
            "pph"              : 0
          },
          "b03": {
            "jenisHadiahUndian": "",
            "bruto"            : 0,
            "tarif"            : tarif.hadiahUndian,
            "pph"              : 0
          },
          "b04": {
            "jenisHadiahUndian": "",
            "bruto"            : 0,
            "tarif"            : tarif.hadiahUndian,
            "pph"              : 0
          },
          "b05": {
            "jenisHadiahUndian": "",
            "bruto"            : 0,
            "tarif"            : tarif.hadiahUndian,
            "pph"              : 0
          },
          "b06": {
            "jenisHadiahUndian": "",
            "bruto"            : 0,
            "tarif"            : tarif.hadiahUndian,
            "pph"              : 0
          }
        },


        "jasaKonstruksi": {
          "jasaPelaksanaanDenganKualifikasi": {
            "bruto": 0,
            "tarif": tarif.penyediaJasaKonstruksiKualifikasi,
            "pph"  : 0
          },
          "jasaPelaksanaanTanpaKualifikasi": {
            "bruto": 0,
            "tarif": tarif.penyediaJasaKonstruksiTidakPunyaKualifikasi,
            "pph"  : 0
          },
          "jasaPelaksanaanSelainNo1Dan2": {
            "bruto": 0,
            "tarif": tarif.penyediaJasaKonstruksiLain,
            "pph"  : 0
          },
          "jasaPengawasanDenganKualifikasi": {
            "opsi" : 1,
            "bruto": 0,
            "tarif": tarif.jasaPerancanganPunyaKualifikasi,
            "pph"  : 0
          },
          "jasaPengawasanTanpaKualifikasi": {
            "opsi" : 1,
            "bruto": 0,
            "tarif": tarif.jasaPerancanganTidakPunyaKualifikasi,
            "pph"  : 0
          }
        },

        "transaksiDerivatif": {
          "nilaiMarginAwal": 0,
          "tarif"          : tarif.penghasilanTransaksiDerivatif,
          "pph"            : 0
        },

        "penjualanSaham": {
          "sahamPendiri": {
            "nilaiTransaksiPenjualan": 0,
            "tarif"                  : tarif.penjualanSahamPendiri,
            "pph"                    : 0
          },
          "bukanSahamPendiri": {
            "nilaiTransaksiPenjualan": 0,
            "tarif"                  : tarif.penjualanSahamBukanPendiri,
            "pph"                    : 0
          }
        },

        "persewaanTanah": {
          "bruto" : 0,
          "tarif" : tarif.sewaTanahDanBangunan,
          "pph"   : 0,
          "lokasi": ""
        },

        "peredaranTertentu": {
          "ntpn" : "",
          "tanggal" : "",
          "jmlBruto" : 0,
          "tarif" : tarif.peredaranTertentu,
          "pph"   : 0,
        },

      }
      this.props.initialize(this.initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id);
      this.props.initialize(res.data);
      // this.npwpValidation(null, res.data.bagA.npwp)
      this.setState({npwpNotValid: false})
    }
  }


  async changeTarif(d, e) {
    var tarif = await Tarif4a2Service.api.byCompany({
      compId: this.props.organization.company.id,
      tgl   : moment(d).format("YYYY-MM-DD")
    })

    if(tarif) {
      await this.setState({'tarif': tarif});
    }

    var form = {...this.props.formData};

    if(form.bungaDeposito) {
      form.bungaDeposito.depositoBerjangkaRupiah.tarif                       = this.state.tarif.bungaDeposito;
      form.bungaDeposito.depositoBerjangkaValutaAsingPremiForward.tarif      = this.state.tarif.bungaDeposito;
      form.bungaDeposito.depositoBerjangkaValutaAsingTanpaPremiForward.tarif = this.state.tarif.bungaDeposito;
      form.bungaDeposito.sertifikatDeposito.tarif                            = this.state.tarif.bungaDeposito;
      form.bungaDeposito.tabungan.tarif                                      = this.state.tarif.bungaDeposito;
      form.bungaDeposito.sertifikatBankIndonesia.tarif                       = this.state.tarif.bungaDeposito;
      form.bungaDeposito.jasaGiro.tarif                                      = this.state.tarif.bungaDeposito;
      form.bungaDeposito.no6.tarif                                           = this.state.tarif.bungaDeposito;
    }
    if(form.bungaDiskonto) {
      form.bungaDiskonto.tarif = this.state.tarif.bungaDanDiskontoObligasi;
    }
    if(form.bungaSimpanan) {
      form.bungaSimpanan.tarif = this.state.tarif.bungaSimpanan;
    }
    if(form.dividenDiterima) {
      form.dividenDiterima.tarif = this.state.tarif.dividenDiterima;
    }
    if(form.hadiahUndian) {
      form.hadiahUndian.b01.tarif = this.state.tarif.hadiahUndian;
      form.hadiahUndian.b02.tarif = this.state.tarif.hadiahUndian;
      form.hadiahUndian.b03.tarif = this.state.tarif.hadiahUndian;
      form.hadiahUndian.b04.tarif = this.state.tarif.hadiahUndian;
      form.hadiahUndian.b05.tarif = this.state.tarif.hadiahUndian;
      form.hadiahUndian.b06.tarif = this.state.tarif.hadiahUndian;
    }
    if(form.jasaKonstruksi) {
      form.jasaKonstruksi.jasaPelaksanaanDenganKualifikasi.tarif = this.state.tarif.penyediaJasaKonstruksiKualifikasi;
      form.jasaKonstruksi.jasaPelaksanaanTanpaKualifikasi.tarif  = this.state.tarif.penyediaJasaKonstruksiTidakPunyaKualifikasi;
      form.jasaKonstruksi.jasaPelaksanaanSelainNo1Dan2.tarif     = this.state.tarif.penyediaJasaKonstruksiLain;
      form.jasaKonstruksi.jasaPengawasanDenganKualifikasi.tarif  = this.state.tarif.jasaPerancanganPunyaKualifikasi;
      form.jasaKonstruksi.jasaPengawasanTanpaKualifikasi.tarif   = this.state.tarif.jasaPerancanganTidakPunyaKualifikasi;
    }
    if(form.transaksiDerivatif) {
      form.transaksiDerivatif.tarif = this.state.tarif.penghasilanTransaksiDerivatif
    }
    if(form.penjualanSaham) {
      form.penjualanSaham.sahamPendiri.tarif      = this.state.tarif.penjualanSahamPendiri;
      form.penjualanSaham.bukanSahamPendiri.tarif = this.state.tarif.penjualanSahamBukanPendiri;
    }
    if(form.persewaanTanah) {
      form.persewaanTanah.tarif = this.state.tarif.sewaTanahDanBangunan
    }
    if(form.peredaranTertentu) {
      form.peredaranTertentu.tarif = this.state.tarif.peredaranTertentu
    }

    await this.props.initialize(form);

    await this.reCalculate()
  }

  async calculate(e,v,pv,f, parent) {
    var form = this.props.formData;
    var d    = form.bagB[parent];

    var bruto = parseFloat(d.bruto);
    var tarif = parseFloat(d.tarif);

    if(f.search('bruto') >= 0) {
      bruto = parseFloat(v)
    } else if(f.search('tarif') >= 0) {
      tarif = parseFloat(v)
    }

    if(!bruto) bruto = 0;
    if(!tarif) tarif = 0;

    var nonNpwp                  = 1;
    if  (d.nonNpwp == 1) nonNpwp = 2;

    var pph = Math.floor(bruto * tarif * nonNpwp / 100)

    await this.props.change(`bagB.${parent}.pph`, pph);
    this.calculateTotal()
  }

  async calculateTotal() {
    var form = this.props.formData;
    var d    = form.bagB;

    var jmlPph   = 0;
    var jmlBruto = 0;
    Object.keys(d).forEach((k, i) => {
      if(!(k == 'jmlBruto' || k == 'jmlPph' || k == 'terbilang')) {
        var x = d[k];

        jmlPph   = jmlPph+ parseFloat(x.pph);
        jmlBruto = jmlBruto+ parseFloat(x.bruto)
      }
    })

    jmlBruto = Math.floor(jmlBruto)
    jmlPph = Math.floor(jmlPph)

    this.props.change('bagB.jmlBruto', jmlBruto)
    this.props.change('bagB.jmlPph', jmlPph);

    this.props.change('bagB.terbilang', terbilangService(parseInt(jmlPph)));

  }

  async reCalculate() {

  }

  jenisFormSwithcer() {
    switch (this.props.formData.type) {
      case 'HADIAH_UNDIAN':
        return <BP4a2HadiahForm change={this.props.change} initialize={this.props.initialize} connectCmp={(childFunc)=> {this.reCalculate = childFunc}} />
      case 'BUNGA_DEPOSITO':
        return <BP4a2BungaDepositoForm change={this.props.change} initialize={this.props.initialize} connectCmp={(childFunc)=> {this.reCalculate = childFunc}} />
      case 'BUNGA_DISKONTO':
        return <BP4a2BungaDiskontoForm change={this.props.change} initialize={this.props.initialize} connectCmp={(childFunc)=> {this.reCalculate = childFunc}} />
      case 'BUNGA_SIMPANAN':
        return <BP4a2BungaSimpananKoperasiForm change={this.props.change} initialize={this.props.initialize} connectCmp={(childFunc)=> {this.reCalculate = childFunc}} />;
      case 'DIVIDEN_DITERIMA':
        return <BP4a2DividenForm change={this.props.change} initialize={this.props.initialize} connectCmp={(childFunc)=> {this.reCalculate = childFunc}} />
      case 'JASA_KONSTRUKSI':
        return <BP4a2JasaKonstruksiForm change={this.props.change} initialize={this.props.initialize} connectCmp={(childFunc)=> {this.reCalculate = childFunc}} />
      case 'TRANSAKSI_DERIVATIF':
        return <BP4a2TransaksiDeviratifForm change={this.props.change} initialize={this.props.initialize} connectCmp={(childFunc)=> {this.reCalculate = childFunc}} />
      case 'PENJUALAN_SAHAM':
        return <BP4a2PenjualanSahamForm change={this.props.change} initialize={this.props.initialize} connectCmp={(childFunc)=> {this.reCalculate = childFunc}} />
      case 'PERSEWAAN_TANAH':
        return <BP4a2PersewaanTanahForm change={this.props.change} initialize={this.props.initialize} connectCmp={(childFunc)=> {this.reCalculate = childFunc}} />
      case 'WP_PEREDARAN_TERTENTU':
        return <BP4a2WpPeredaranTertentuForm change={this.props.change} initialize={this.props.initialize} connectCmp={(childFunc)=> {this.reCalculate = childFunc}} />
      default:
        break;
    }
  }

  formView() {
    var formData                                                     = {bagB: {}};
    if  (this.props.formData && this.props.formData.values) formData = this.props.formData.values
    return (
      <div>
        <LawanTransaksiDialog
          spt      = {this.props.spt.data}
          onSelect = {(d)=> {
            var formData = {...this.props.formData};

            formData.penerimaPenghasilan = {
              "npwp"   : d.npwp,
              "nik"    : d.nik,
              "nama"   : d.nama,
              "alamat" : d.alamat,
              "telepon": d.telepon,
              "kodePos": d.kodePos,
              "email"  : d.email
            };

            this.setState({npwpNotValid: false})

            this.props.initialize(formData);

          }}
          service = {MasterLawanTransaksi4a2Service}
          visible = {this.state.lkDialog}
          onHide  = {()=> this.setState({lkDialog: false})}
        />
        <Card>
          <div className="md-grid">
            <Field
              label     = 'No.Bukti Potong/NTPN/PBK'
              name      = 'no'
              className = "md-cell md-cell--6"
              component = {Textfield}
              validate  = {validation.required}
            />

            <Field
              label        = 'Tanggal Bukti Potong'
              name         = 'tanggal'
              className    = "md-cell md-cell--6"
              onDateChange = {async (d, e) => this.changeTarif(d)}
              component    = {Datepicker}
              validate     = {validation.required}
            />

            <Field
              label     = 'Referensi'
              name      = 'referensi'
              id        = "BP21FForm-referensi"
              className = "md-cell md-cell--12"
              component = {Textfield}
            />
          </div>
        </Card>

        <br/>

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='A. IDENTITAS WAJIB PAJAK YANG DIPOTONG ' />
          <Divider/>
          <div className="md-grid">
            <div className='md-cell md-cell--6'>
            <div className='mpk-layout'>
              <Field
                label      = 'NPWP'
                className  = 'flex'
                name       = 'penerimaPenghasilan.npwp'
                component  = {TextfieldMask}
                maskFormat = "##.###.###.#-###-###"
                mask       = "_"
                length     = {15}
                onChange   = {this.npwpValidation.bind(this)}
                validate   = {validation.required}
              />
              <Button icon primary swapTheming style={{marginTop: 10}} onClick={()=> this.setState({lkDialog: true})}>search</Button>
              </div>
              <Field
                label     = 'Nama'
                name      = 'penerimaPenghasilan.nama'
                disabled  = {this.state.npwpNotValid}
                component = {Textfield}
                validate  = {validation.required}
              />
              {/* <Field
                label     = 'NIK'
                name      = 'penerimaPenghasilan.nik'
                disabled  = {this.state.npwpNotValid}
                component = {Textfield}
              /> */}
              <Field
                label     = 'Telepon'
                name      = 'penerimaPenghasilan.telepon'
                id        = "BP21FForm-name"
                component = {Textfield}
                />

            </div>
            <div className='md-cell md-cell--6'>
              <Field
                label     = 'Kode Pos'
                name      = 'penerimaPenghasilan.kodePos'
                id        = "BP21FForm-codePos"
                component = {Textfield}
              />
              <Field
                label = 'Alamat'
                name  = 'penerimaPenghasilan.alamat'

                disabled  = {this.state.npwpNotValid}
                component = {Textfield}
                validate  = {validation.required}
              />
              <Field
                label = 'Email'
                name  = 'penerimaPenghasilan.email'

                disabled  = {this.state.npwpNotValid}
                component = {Textfield}
              />
            </div>
          </div>
        </Card>

        <br/>

        {this.state.showCalculateProgress && <LinearProgress id='lin_pro' style={{margin: 0}} />}
        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='B. DETAIL DIPOTONG' />
          <Divider/>
          <div>
           <Field
              label     = 'Jenis Bukti Potong'
              className = "md-cell md-cell--12"
              name      = 'type'
              component = {Searchfield}
              options   = {this.typeOptions}
              validate  = {validation.required}
            />

            <br/>
          </div>


          {this.jenisFormSwithcer()}
        </Card>

        <br/>

        <Card>
          <div className='md-grid'>
            <Field
              label     = 'Jumlah Bruto'
              name      = 'bruto'
              component = {TextfieldMask}
              className = 'md-text-right'
              disabled
              money = {','}
            />
            <Field
              label     = 'Jumlah PPh'
              name      = 'pph'
              component = {TextfieldMask}
              className = 'md-text-right'
              disabled
              money = {','}
            />
            <Field
              label     = 'Terbilang'
              name      = 'terbilang'
              component = {Textfield}
              disabled
            />
          </div>
        </Card>

        <br />

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='C. IDENTITAS PEMOTONG ' />
          <Divider/>

          <div className="md-grid">
          <Field
            label     = 'NPWP'
            name      = 'npwpPemotong'
            className = "md-cell md-cell--6"
            disabled
            component  = {TextfieldMask}
            maskFormat = "##.###.###.#-###-###"
            mask       = "_"
            validate   = {validation.required}
          />
          <Field
            label     = 'Nama'
            name      = 'namaPemotong'
            className = "md-cell md-cell--6"
            disabled
            component = {Textfield}
            validate  = {validation.required}
          />


          </div>
        </Card>

      </div>
    )
  }

}
