import React, { Component } from 'react';
import { connect } from 'react-redux';

import ListView from '../../../../../../components/entity/listView';
import TextField, { Button, Divider, Chip, Card, CardTitle, CardActions, DataTable, TableHeader, TableBody, TableRow, TableColumn, Switch, Paper } from 'react-md';
import { Field, reduxForm } from 'redux-form';
import download from 'downloadjs';
import uuid from 'uuid';
import iziToast from 'izitoast';
import { Commandbar, Table, TableSetting, Empty, DialogConfirm } from 'react-mpk';
import http from '../../../../../../services/http.service'
import ExportCsv22LogService from '../ExportCsv22Log/ExportCsv22Log.service';
import BP22Service from '../../BP22/BP22.service';
import SSP22DibayarSendiriService from '../../SSPDibayarSendiri/SSPDibayarSendiri.service';
import SSP22ImportBankDevisaService from '../../SSPImportBankDevisa/SSPImportBankDevisa.service';
import SSP22PembelianBarangService from '../../SSPPembelianBarang/SSPPembelianBarang.service';
import SSP22Service from '../../SSP/SSP22.service';
import PBK22Service from '../../PBK/PBK22.service';
import SPT22IndukService from "../../../22/Induk/SPT22.induk.service";

@reduxForm({form: 'EXPORT_CSV_22', destroyOnUnmount: true})
@connect((state)=> ({
  ...ExportCsv22LogService.stateConnectSetting()(state),
  spt: state.auth.currentSPT
}), ExportCsv22LogService.actionConnectSetting())
export default class FileLapor22View extends ListView {
  service=ExportCsv22LogService

  viewType=2;

  constructor(props){
    super(props);
    this.state = {
      showTableSetting:false,
      showDialogConfirmDeleteSelected:false,
      showForm:false,
      formData: {},
      data: [],
      cetak: false,
      showDialog: false,
      category: null,

      bpAtasImpor: 0,
      bpAtasPembelianBarangBadan: 0,
      bpIndustriEksportir: 0,
      bpIndustriImportir: 0,

      sspBankDevisa: 0,
      sspBendaharawan: 0,
      sspDibayarSendiri: 0,

      pbk: 0,
      ssp: 0,
      totalInduk: 1,
    }
  }

  columns=[]
  _barActions=[]
  _barItem() {}

  handleExport(category){
      this.setState({ showDialog: true })
      this.setState({ category: category })
  }

  async export(){
    var sptId = this.props.spt.data.id
    var category = this.state.category
    var csv = {};
    try {
      switch (category) {
        case 'SSP_DIBAYAR_SENDIRI':
          csv = await SSP22DibayarSendiriService.api.downloadCsv(sptId);
          break;
        case 'SSP_OLEH_BENDAHARAWAN':
          csv = await SSP22PembelianBarangService.api.downloadCsv(sptId);
          break;
        case 'SSP_OLEH_BANK_DEVISA':
          csv = await SSP22ImportBankDevisaService.api.downloadCsv(sptId);
          break;
        case 'SSP_22':
          csv = await SSP22Service.api.downloadCsv(sptId);
          break;
        case 'PBK_22':
          csv = await PBK22Service.api.downloadCsv(sptId);
          break;
        case 'BP_22_INDUK':
          csv = await SPT22IndukService.api.downloadCsv(sptId);
          break;
        default:
          csv = await BP22Service.api.downloadCsv(sptId, category);
          break;
      }
      var filename = csv.headers.filename
      download(csv.data, filename);
    } catch (error) {

    }
  }

  async componentDidMount(){
    var sptId = this.props.spt.data.id
    // let HADIAH_UNDIAN = await BP22Service.api.find({'type.equals': 'HADIAH_UNDIAN'}, ()=>{}, {
    //   path: BP22Service.path+'/'+this.props.spt.data.id+'/page'
    // })
    let info = await this.service.api.getInfo(sptId);

    // let HADIAH_UNDIAN = await BP22Service.api.find({'type.equals': 'HADIAH_UNDIAN'}, ()=>{}, {
    //   path: BP22Service.path+'/'+this.props.spt.data.id+'/page'
    // })
    // let BUNGA_DEPOSITO = await BP22Service.api.find({'type.equals': 'BUNGA_DEPOSITO'}, ()=>{}, {
    //   path: BP22Service.path+'/'+this.props.spt.data.id+'/page'
    // })
    // let BUNGA_SIMPANAN = await BP22Service.api.find({'type.equals': 'BUNGA_SIMPANAN'}, ()=>{}, {
    //   path: BP22Service.path+'/'+this.props.spt.data.id+'/page'
    // })
    // let BUNGA_DISKONTO = await BP22Service.api.find({'type.equals': 'BUNGA_DISKONTO'}, ()=>{}, {
    //   path: BP22Service.path+'/'+this.props.spt.data.id+'/page'
    // })
    // let DIVIDEN_DITERIMA = await BP22Service.api.find({'type.equals': 'DIVIDEN_DITERIMA'}, ()=>{}, {
    //   path: BP22Service.path+'/'+this.props.spt.data.id+'/page'
    // })
    // let JASA_KONSTRUKSI = await BP22Service.api.find({'type.equals': 'JASA_KONSTRUKSI'}, ()=>{}, {
    //   path: BP22Service.path+'/'+this.props.spt.data.id+'/page'
    // })
    // let TRANSAKSI_DERIVATIF = await BP22Service.api.find({'type.equals': 'TRANSAKSI_DERIVATIF'}, ()=>{}, {
    //   path: BP22Service.path+'/'+this.props.spt.data.id+'/page'
    // })
    // let PENJUALAN_SAHAM = await BP22Service.api.find({'type.equals': 'PENJUALAN_SAHAM'}, ()=>{}, {
    //   path: BP22Service.path+'/'+this.props.spt.data.id+'/page'
    // })
    // let PERSEWAAN_TANAH = await BP22Service.api.find({'type.equals': 'PERSEWAAN_TANAH'}, ()=>{}, {
    //   path: BP22Service.path+'/'+this.props.spt.data.id+'/page'
    // })

    // let JASA_KONSTRUKSI_S = await BP22DSJasaKonstruksiService.api.find({'type.equals': 'JASA_KONSTRUKSI_S'}, ()=>{}, {
    //   path: BP22DSJasaKonstruksiService.path+'/'+this.props.spt.data.id+'/page'
    // })
    // let PERSEWAAN_TANAH_S = await BP22DSSewaTanahService.api.find({'type.equals': 'PERSEWAAN_TANAH_S'}, ()=>{}, {
    //   path: BP22DSSewaTanahService.path+'/'+this.props.spt.data.id+'/page'
    // })
    // let PENGALIHAN_S = await BP22DSPengalihanTanahService.api.find({'type.equals': 'PENGALIHAN_S'}, ()=>{}, {
    //   path: BP22DSPengalihanTanahService.path+'/'+this.props.spt.data.id+'/page'
    // })

    // let SSP_22 = await SSP22Service.api.find({'type.equals': 'PENGALIHAN_S'}, ()=>{}, {
    //   path: SSP22Service.path+sptId+'/page'
    // })
    // let PBK_22 = await PBK22Service.api.find({'type.equals': 'PENGALIHAN_S'}, ()=>{}, {
    //   path: PBK22Service.path+'/'+this.props.spt.data.id+'/page'
    // })

    if(info.data) {
      this.setState({
        bpAtasImpor: info.data.bpAtasImpor,
        bpAtasPembelianBarangBadan: info.data.bpAtasPembelianBarangBadan,
        bpIndustriEksportir: info.data.bpIndustriEksportir,
        bpIndustriImportir: info.data.bpIndustriImportir,

        sspBankDevisa: info.data.sspBankDevisa,
        sspBendaharawan: info.data.sspBendaharawan,
        sspDibayarSendiri: info.data.sspDibayarSendiri,

        pbk: info.data.pbk,
        ssp: info.data.ssp
      })
    }
  }

  _tableView(props) {
      const _this = this
      return (
        <Card className="flex mpk-layout">
            <DialogConfirm
                title={'word.export'}
                message={'sentence.custom.export'}
                visible={this.state.showDialog}
                onSubmit={(callback) => {
                    _this.export()
                    callback();
                }}
                onCancel={() => this.setState({showDialog:false})}
                translate={true}
            />
            <CardTitle title='' style={{padding: '10px 16px'}} subtitle='Informasi File Export'/>
            <Divider/>
            <DataTable plain
            fixedHeader
            fixedFooter>
              <TableHeader>
                <TableRow>
                    <TableColumn>Jumlah Data</TableColumn>
                    <TableColumn></TableColumn>
                </TableRow>
              </TableHeader>
              <TableBody>
                <TableRow>
                    <TableColumn>BP</TableColumn>
                    <TableColumn></TableColumn>
                </TableRow>
                <TableRow>
                    <TableColumn>{this.state.bpAtasImpor} Unit</TableColumn>
                    <TableColumn>
                        <Button disabled={this.state.bpAtasImpor == 0} onClick={this.handleExport.bind(this, 'F113303')} style={{width: '100%'}} primary raised>BUKTI PUNGUT PPH 22 ATAS IMPORT</Button>
                    </TableColumn>
                </TableRow>
                <TableRow>
                    <TableColumn>{this.state.bpIndustriEksportir} Unit</TableColumn>
                    <TableColumn>
                        <Button disabled={this.state.bpIndustriEksportir == 0} onClick={this.handleExport.bind(this, 'F113304')} style={{width: '100%'}} primary raised>BUKTI PUNGUT PPH 22 INDUSTRI EKSPORTIR</Button>
                    </TableColumn>
                </TableRow>
                <TableRow>
                    <TableColumn>{this.state.bpIndustriImportir} Unit</TableColumn>
                    <TableColumn>
                        <Button disabled={this.state.bpIndustriImportir == 0} onClick={this.handleExport.bind(this, 'F113304S')} style={{width: '100%'}} primary raised>BUKTI PUNGUT PPH 22 INDUSTRI IMPORTIR</Button>
                    </TableColumn>
                </TableRow>
                <TableRow>
                    <TableColumn>{this.state.bpAtasPembelianBarangBadan} Unit</TableColumn>
                    <TableColumn>
                        <Button disabled={this.state.bpAtasPembelianBarangBadan == 0} onClick={this.handleExport.bind(this, 'F113304A')} style={{width: '100%'}} primary raised>BUKTI PUNGUT PPH 22 BADAN TERTENTU</Button>
                    </TableColumn>
                </TableRow>

                <TableRow>
                    <TableColumn>SSP Bayar Sendiri</TableColumn>
                    <TableColumn></TableColumn>
                </TableRow>
                <TableRow>
                    <TableColumn>{this.state.sspDibayarSendiri} Unit</TableColumn>
                    <TableColumn>
                        <Button disabled={this.state.sspDibayarSendiri == 0} onClick={this.handleExport.bind(this, 'SSP_DIBAYAR_SENDIRI')} style={{width: '100%'}} primary raised>SSP PPH 22 DIBAYAR SENDIRI</Button>
                    </TableColumn>
                </TableRow>
                <TableRow>
                    <TableColumn>{this.state.sspBendaharawan} Unit</TableColumn>
                    <TableColumn>
                        <Button disabled={this.state.sspBendaharawan == 0} onClick={this.handleExport.bind(this, 'SSP_OLEH_BENDAHARAWAN')} style={{width: '100%'}} primary raised>SSP PPH 22 BENDAHARAWAN</Button>
                    </TableColumn>
                </TableRow>
                <TableRow>
                    <TableColumn>{this.state.sspBankDevisa} Unit</TableColumn>
                    <TableColumn>
                        <Button disabled={this.state.sspBankDevisa == 0} onClick={this.handleExport.bind(this, 'SSP_OLEH_BANK_DEVISA')} style={{width: '100%'}} primary raised>SSP PPH 22 BANK DEVISA</Button>
                    </TableColumn>
                </TableRow>

                <TableRow>
                    <TableColumn>Lainnya</TableColumn>
                    <TableColumn></TableColumn>
                </TableRow>
                <TableRow>
                    <TableColumn>{this.state.ssp} Unit</TableColumn>
                    <TableColumn>
                        <Button disabled={this.state.ssp == 0} onClick={this.handleExport.bind(this, 'SSP_22')} style={{width: '100%'}} primary raised>Export Data SSP</Button>
                    </TableColumn>
                </TableRow>
                <TableRow>
                    <TableColumn>{this.state.pbk} Unit</TableColumn>
                    <TableColumn>
                        <Button disabled={this.state.pbk == 0} onClick={this.handleExport.bind(this, 'PBK_22')} style={{width: '100%'}} primary raised>Export Data PBK</Button>
                    </TableColumn>
                </TableRow>
                <TableRow>
                  <TableColumn>{this.state.totalInduk} Unit</TableColumn>
                  <TableColumn>
                    <Button disabled={this.state.totalInduk == 0} onClick={this.handleExport.bind(this, 'BP_22_INDUK')} style={{width: '100%'}} primary raised>Export Data Induk</Button>
                  </TableColumn>
                </TableRow>
              </TableBody>
            </DataTable>
        </Card>
      )
  }
}
