import React from 'react'
import { Paper, Switch } from 'react-md';
import { Commandbar, Table, TableSetting, Empty, DialogConfirm } from 'react-mpk';

import ListView from './listView';

export default class ListViewSpt extends ListView {

    constructor(props){
        super(props);
        this.state = {
          showTableSetting:false,
          showDialogConfirmDeleteSelected:false,
          showForm:false,
          formData: {},
          data: []
        }
    }

    async deleteSelectedDataItems(){
        var selected = this.props.table.selected
        var ids = [];
        selected.map(function (i){
            ids.push(i.id)
        })
        var res = await this.service.api.deleteAll(ids)
        this.fetchData()
    }

    _dialogConfirm() {
        var _this = this
        return <DialogConfirm
            title={'word.deletes'}
            message={'sentence.custom.deleteSelectedItems'}
            visible={this.state.showDialogConfirmDeleteSelected}
            onSubmit={(callback) => {
            _this.deleteSelectedDataItems()
            callback();
            }}
            onCancel={() => this.setState({showDialogConfirmDeleteSelected:false})}
            translate={true}
        />
    }

    barActions(){
        let statusSPT = this.props.spt.data.status;
        if(statusSPT == "FINISH"){
            return []
        } else {
            return [
                {
                    label:'word.create',
                    iconClassName:'mdi mdi-plus',
                    onClick:() => this.addItem()
                },
                {
                    label:'word.delete',
                    iconClassName:'mdi mdi-delete',
                    onClick:() => {
                    this.setState({showDialogConfirmDeleteSelected:true})
                    },
                    disabledFunc:() => this.props.table.selected.length === 0
                },
                {
                  label:'word.refresh',
                  iconClassName:'mdi mdi-refresh',
                  onClick:() => {

                    window.location.hash = window.location.hash.split('?')[0]
                    this.fetchData()
                  }
                },
            ]
        }
    }

    _tableActions = () => {
        let statusSPT = this.props.spt.data.status;
        if(statusSPT == "FINISH" || statusSPT == "WAITING"){
            return [
                {
                    label:"More Info",
                    iconClassName:"mdi mdi-bell",
                    onClick: (item) => this.editItem(item)
                }
            ]
        } else {
            return [
                {
                    label:"More Info",
                    iconClassName:"mdi mdi-bell",
                    onClick: (item) => this.editItem(item)
                }
                // {label:"divider", iconClassName:"-"},
                // {
                //     label:"Delete",
                //     iconClassName:"mdi mdi-delete",
                //     onClick:(item, callback) => this.deleteItem(item, callback),
                //     confirmation:{
                //       title:"sentence.custom.deleteItem",
                //       message:"sentence.custom.deleteItemConfirmation"
                //     }
                // }
            ]
        }
    }

    _tableView(props) {
        var columns = this._columns;
        var tableActions = this._tableActions();
        if(this.columns) columns = this.columns;
        if(this.tableActions) tableActions= this.tableActions;

        var _this = this;
        if(columns[0].addon){
        } else {
        // columns.unshift(
        //     {
        //     addon: true,
        //     label: "word.number",
        //     value: function(d, index){
        //         var current = 0;
        //         if(_this.props){
        //         if(_this.props.table){
        //             if(_this.props.table.params){
        //             current = _this.props.table.params.size * (_this.props.table.params.page-1)
        //             }
        //         }
        //         }
        //         if(index || index == 0){
        //         return (index+1) + current;
        //         } else {
        //         return "-"
        //         }
        //     },
        //     type: "func",
        //     show:true,
        //     isSearchable:true}
        //     )
        }

        return (
        <Paper className="flex mpk-layout column">
          {this.header()}

          <Table
            connect={{
              properties: this.props.table,
              actions: this.props.tableActions
            }}
            isPaging={true}
            translate={true}
            columns={columns}
            itemActions={tableActions}
            fetchData={this.fetchData}
            footer={this.footer()}
          />
        </Paper>
        )
    }

}
