import React from 'react'
import _ from 'lodash';
import bluebird from 'bluebird';
import  counterpart from 'counterpart';
import ListView from './listView';
import download from 'downloadjs';
import izitoast from 'izitoast';
import LogExportService from '../../modules/Main/Export/LogExport.service';
import MainFlowService from '../../modules/Main/Flow/MainFlow/MainFlow.service';
import FlowHistoryDialog from '../../modules/Main/Flow/MainFlow/FlowHistoryDialog';
import FlowActionDialog from '../../modules/Main/Flow/MainFlow/FlowAction.dialog';
import {Field, initialize} from 'redux-form';

import FlowActionBulkDialog from '../../modules/Main/Flow/MainFlow/FlowActionBulk.dialog';

import ParamService from '../../services/param.service';

import {
  Chip,
  Switch, Button, LinearProgress, TabsContainer, Tabs, Tab,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  ExpansionList,
  ExpansionPanel,
  Divider,
  Subheader,
  CardTitle,
  Card,
  DialogContainer,
  MenuButton,
  FileUpload,
  FileInput
} from 'react-md';
import websocketService from '../../services/websocket.service';
import LogEfillingService from '../../modules/Main/Efilling/LogEfilling.service';
import UserService from '../../modules/Main/Administrator/User/User.service';
import {Fileinput} from '../form'


export default class ListViewSptRoot extends ListView {

  permissionName="SPT_21"
  urlPath="SPT_21"
  formTypeName="PPH 21"
  shortPasalName="21"
  fileLaporTitle="File Lapor"
  skemaBaru=true
  emailProfileFeature=false

  subcribed=false

  codeFile = {
    SPT_21:  "F1132010413",
    SPT_23: "F1132031009",
    // SPT_4A2: "F1132041009",
    SPT_4A2: "F1132041218",
    SPT_15: "F1132051009",
    SPT_22: "F1132021009",
  }


  constructor(props){
    super(props);
    this.state = {
      ...this.state,
      flowDialog: false,
      flowType: "Pengajuan Approval",
      spt: {},
      flowHistory: false,

      exportType: 'CSV',
      exportDialog: false,
      exportDialogIsDetail: false,
      progress: {},
      logExport: [],
      logExportSelected: {details: []},


      approvalDialog: false,
      approvalType: 'Pengajuan',
      approvalList: [],

      stateMap: {

      },
      stateList: [],

      checkLaporDialog: false,
      checkLaporList: [],

      uploadLaporDialog: false,
      konfirmasiUploadLaporDialog: false,
      uploadLaporResMap: {},
      uploadLaporData: [],
      countNoPdf: 0,
    }

    let user = this.props.user

    this.emailProfileFeature = user.company.emailProfile
  }

  componentDidMount() {
    var initParam = Object.keys(this.props.filterObj).reduce((pv, key) => {
      var newKey = key.replace('.', '__')
      pv[newKey] = this.props.filterObj[key]
      return pv;
    }, {})

    this.props.dispatch(initialize(this.service.name + "_search", initParam))
  }

  async cancelSptFlow(spt) {
    var d = await this.context.showDialog((props, res, rej) =>({
      title: 'Cancel Flow',
      text: 'Apakah anda yakin akan membatalkan flow SPT ini! setelah di batalkan status SPT akan kembali berubah menjadi "PROCESS"',
      okText: 'Yes',
      cancelText: 'No'
    }))

    if(d) {
      try {
        await MainFlowService.api.cancel({
          formType: this.formTypeName,
          formId: spt.id,
          organizationId: spt.orgId
        });

        this.fetchData()
        izitoast.success({title: "Success", message: "Spt telah di berhasil di batalkan"})
      } catch(e) {
        // izitoast.warning({title: "Warning", message: "Approval telah di kirim"})
      }

    }
  }

  async onFlowAction(value, callback) {
    var data = {
      ...value,
      formType: this.formTypeName,
      formId: this.state.spt.id,
      organizationId: this.state.spt.orgId,
      companyId: this.props.user.company.id
    };
    if(this.state.flowType === 'Pengajuan Approval') {
      await MainFlowService.api.pengajuan(data);
    } else {
      data = {
        ...data,
        stateId: this.state.spt.flowState.id
      }
      await MainFlowService.api.action(data);
    }

    izitoast.success({title: "Success", message: "Approval telah di kirim"})

    this.fetchData()
    callback()
  }

  async onFlowActionBulk(value, callback) {

    var list = [];

    this.state.approvalList.forEach((d, i)=> {

      var v = value[d.id];
      if(!v) v = {};

      var data = {
        ...v,
        formType: this.formTypeName,
        formId: d.id,
        organizationId: d.orgId,
        companyId: this.props.user.company.id
      };

      if(d.flowState) data.stateId = d.flowState.id

      list.push(data);

    })

    try {
      if(this.state.approvalType === 'Pengajuan') {
        await MainFlowService.api.pengajuanBulk(list);
      } else {
        await MainFlowService.api.actionBulk(list);
      }
    } catch(e) {

    }

    izitoast.success({title: "Success", message: "Approval telah di kirim"})

    this.fetchData()
    this.props.dispatch({type: this.service.name+'/TABLE_SELECT_DATA_ITEM', index: 0, isSelect: false})
    callback()
  }

  deleteSelected = async () => {
    var items = this.props.table.selected;
    // .reduce((p, c)=> {
    //   if(c.status === 'PROCESS') {
    //     p.push(c);
    //   }

    //   return p;
    // }, []);

    // var dialog = await this.context.showDialog((props, res, rej) =>({
    //   title: 'Delete',
    //   initialValue: _.cloneDeep({}),
    //   width: 640,
    //   okText: counterpart.translate('word.yes'),
    //   text: (
    //     <div className='md-grid'>
    //       <p>{counterpart.translate('sentence.custom.deleteSelectedItems')}</p>

    //       <DataTable className='animated fadeInDown' plain fixedHeader fixedHeight={200}>
    //         <TableHeader>
    //           <TableRow>
    //             <TableColumn>NPWP</TableColumn>
    //             <TableColumn>Nama</TableColumn>
    //             {/* <TableColumn>Status</TableColumn> */}
    //             <TableColumn>Masa</TableColumn>
    //             <TableColumn>Tahun</TableColumn>
    //             <TableColumn>Pembetulan</TableColumn>
    //           </TableRow>
    //         </TableHeader>
    //         <TableBody>
    //             {items.map((el, i) => {
    //               return <TableRow>
    //                 <TableColumn>{el.npwp}</TableColumn>
    //                 <TableColumn>{el.nama}</TableColumn>
    //                 {/* <TableColumn>{el.status}</TableColumn> */}
    //                 <TableColumn>{el.masa}</TableColumn>
    //                 <TableColumn>{el.tahun}</TableColumn>
    //                 <TableColumn>{el.pembetulan}</TableColumn>
    //               </TableRow>
    //             })}
    //         </TableBody>
    //       </DataTable>

    //     </div>
    //   )
    // }));

    var dialog = await this.context.showDialog((props, res, rej) =>({
      title: 'Delete',
      initialValue: {},
      height: 'auto',
      width: 400,
      okText: 'Ya',
      text: 'Apakah anda yakin akan meng-hapus spt yang terpilih?'
    }));

    if(dialog) {
      try {
        let ids = []

        items.forEach(item => {
          ids.push(item.id)
        })

        var result = {data: []}

        if(this.service.api.deleteSelected) {
          result = await this.service.api.deleteSelected(ids)
        } else if(this.service.api.deleteAll) {
          result = await this.service.api.deleteAll(ids)
        }

        var dialog2 = await this.context.showDialog((props, res, rej) =>({
          initialValue: _.cloneDeep({}),
          width: 720,
          contentStyle: {padding:0},
          okText: counterpart.translate('word.yes'),
          text: (
            <div>
              <div className='mpk-commandbar mpk-layout align-center justify-between mpk-border bottom solid dark'>
                <div className='mpk-font-size-M flex mpk-layout align-center'>Delete</div>
              </div>

              <DataTable className='animated fadeInDown' plain fixedHeader fixedHeight={200}>
                <TableHeader>
                  <TableRow>
                    <TableColumn>No.</TableColumn>
                    <TableColumn>Success</TableColumn>
                    <TableColumn>NPWP</TableColumn>
                    <TableColumn>Nama</TableColumn>
                    {/* <TableColumn>Status</TableColumn> */}
                    <TableColumn>Masa</TableColumn>
                    <TableColumn>Tahun</TableColumn>
                    <TableColumn>Pembetulan</TableColumn>
                    <TableColumn>Message</TableColumn>
                  </TableRow>
                </TableHeader>
                <TableBody>
                    {result.data.map((el, i) => {
                      return <TableRow>
                        <TableColumn>{i+1}</TableColumn>
                        <TableColumn>
                          {el.success && 'Success'}
                          {!el.success && 'Failed'}
                        </TableColumn>
                        <TableColumn>{el.spt.organization.npwp}</TableColumn>
                        <TableColumn>{el.spt.organization.name}</TableColumn>
                        <TableColumn>{el.spt.month}</TableColumn>
                        <TableColumn>{el.spt.year}</TableColumn>
                        <TableColumn>{el.spt.pembetulan}</TableColumn>
                        <TableColumn>{el.message}</TableColumn>

                      </TableRow>
                    })}
                </TableBody>
              </DataTable>

            </div>
          )
        }))

        await this.fetchData()
      } catch(e) {
      }
    }
  }

  pembetulanSelected = async () => {
    var items = this.props.table.selected;

    // var dialog = await this.context.showDialog((props, res, rej) =>({
    //   title: 'Pembetulan',
    //   initialValue: _.cloneDeep({}),
    //   width: 640,
    //   okText: counterpart.translate('word.yes'),
    //   text: (
    //     <div className='md-grid'>
    //       <p>Apakah anda ingin membentuk pembetulan dari data-data terpilih?</p>

    //       <DataTable className='animated fadeInDown' plain fixedHeader fixedHeight={200}>
    //         <TableHeader>
    //           <TableRow>
    //             <TableColumn>NPWP</TableColumn>
    //             <TableColumn>Nama</TableColumn>
    //             {/* <TableColumn>Status</TableColumn> */}
    //             <TableColumn>Masa</TableColumn>
    //             <TableColumn>Tahun</TableColumn>
    //             <TableColumn>Pembetulan</TableColumn>
    //           </TableRow>
    //         </TableHeader>
    //         <TableBody>
    //             {items.map((el, i) => {
    //               return <TableRow>
    //                 <TableColumn>{el.npwp}</TableColumn>
    //                 <TableColumn>{el.nama}</TableColumn>
    //                 {/* <TableColumn>{el.status}</TableColumn> */}
    //                 <TableColumn>{el.masa}</TableColumn>
    //                 <TableColumn>{el.tahun}</TableColumn>
    //                 <TableColumn>{el.pembetulan}</TableColumn>
    //               </TableRow>
    //             })}
    //         </TableBody>
    //       </DataTable>

    //     </div>
    //   )
    // }));

    var dialog = await this.context.showDialog((props, res, rej) =>({
      title: 'Pembetulan',
      initialValue: {},
      height: 'auto',
      width: 400,
      okText: 'Ya',
      text: 'Apakah anda yakin akan membuat pembetulan spt yang terpilih?'
    }));


    if(dialog) {
      try {
        let ids = []

        items.forEach(item => {
          ids.push(item.id)
        })

        var result = await this.service.api.pembetulanBulk(ids);
        var dialog = await this.context.showDialog((props, res, rej) =>({
          // title: 'Pembetulan',
          initialValue: _.cloneDeep({}),
          contentStyle: {padding:0},
          width: 800,
          text: (
            <div>
              <div className='mpk-commandbar mpk-layout align-center justify-between mpk-border bottom solid dark'>
                <div className='mpk-font-size-M flex mpk-layout align-center'>Pembetulan</div>
              </div>

              <DataTable className='animated fadeInDown' plain fixedHeader fixedHeight={200}>
                <TableHeader>
                  <TableRow>
                    <TableColumn>No.</TableColumn>
                    <TableColumn>Success</TableColumn>
                    <TableColumn>NPWP</TableColumn>
                    <TableColumn>Nama</TableColumn>
                    {/* <TableColumn>Status</TableColumn> */}
                    <TableColumn>Masa</TableColumn>
                    <TableColumn>Tahun</TableColumn>
                    <TableColumn>Pembetulan</TableColumn>
                    <TableColumn>Message</TableColumn>
                  </TableRow>
                </TableHeader>
                <TableBody>
                    {result.data.map((el, i) => {
                      return <TableRow>
                        <TableColumn>{i+1}</TableColumn>
                        <TableColumn>
                          {el.success && 'Success'}
                          {!el.success && 'Failed'}
                        </TableColumn>
                        <TableColumn>{el.spt.organization.npwp}</TableColumn>
                        <TableColumn>{el.spt.organization.name}</TableColumn>
                        <TableColumn>{el.spt.month}</TableColumn>
                        <TableColumn>{el.spt.year}</TableColumn>
                        <TableColumn>{el.spt.pembetulan}</TableColumn>
                        <TableColumn>{el.message}</TableColumn>

                      </TableRow>
                    })}
                </TableBody>
              </DataTable>

            </div>
          )
        }));


        await this.fetchData()
      } catch(e) {
      }
    }
  }

  cancelPembetulanSelected = async () => {
    var items = this.props.table.selected;
    let ids = []

    items.forEach(item => {
      if (item.status === 'CLONING') {
        ids.push(item.id)
      }
    })

    if (ids.length > 0) {
      var result = await this.service.api.cancelPembetulanBulk(ids);

      izitoast.success({title: 'Batalkan Pembetulan', message: 'Pembatalan pembetulan telah berhasil dilakukan'})

      await this.fetchData()
    }
    else {
      izitoast.warning({title: 'Batalkan Pembetulan', message: 'Pembatalan pembetulan hanya untuk SPT dengan status CLONING.'})
    }
  }

  barActions() {
    let menus = [
      {
        label:'word.create',
        iconClassName:'mdi mdi-plus',
        onClick:() => this.addItem()
      },
      {
        label:'word.refresh',
        iconClassName:'mdi mdi-refresh',
        onClick:async () => {
          window.location.hash = window.location.hash.split('?')[0]
          setTimeout( async ()=> {
            await this.fetchData()
          }, 500)

        }
      },
      {
        label:'word.delete',
        iconClassName:'mdi mdi-delete',
        onClick: async () => {
          await this.deleteSelected()
        },
        disabledFunc:() => this.props.table.selected.length === 0
      },
      {
        label:'word.pembetulan',
        iconClassName:'mdi mdi-wrench',
        onClick: async () => {
          await this.pembetulanSelected()
        },
        disabledFunc:() => this.props.table.selected.length === 0
      },
      {
        label:'word.cancelPembetulan',
        iconClassName:'mdi mdi-cancel',
        onClick: async () => {
          await this.cancelPembetulanSelected()
        },
        disabledFunc:() => this.props.table.selected.length === 0
      },
    ]

    if (this.permissionName === 'SPT_4A2') {
      menus.push({
        label:'word.checkLaporSkemaBaru',
        iconClassName:'mdi mdi-download',
        onClick: async () => {
          await this.handleCheckLaporAll()
        },
        // disabledFunc:() => this.props.table.selected.length === 0
      })

      menus.push({
        label:'word.checkLaporSkemaLama',
        iconClassName:'mdi mdi-briefcase-download',
        onClick: async () => {
          await this.handleCheckLaporAll(false)
        },
        // disabledFunc:() => this.props.table.selected.length === 0
      })

      menus.push({
        label:'word.uploadLaporSkemaBaru',
        iconClassName:'mdi mdi-upload',
        onClick: async () => {
          await this.handleUploadLaporAll()
        },
        disabledFunc:() => this.props.table.selected.length === 0
      })

      menus.push({
        label:'word.uploadLaporSkemaLama',
        iconClassName:'mdi mdi-briefcase-upload',
        onClick: async () => {
          await this.handleUploadLaporAll(false)
        },
        disabledFunc:() => this.props.table.selected.length === 0
      })
    }
    else {
      menus.push({
        label:'word.checkLapor',
        iconClassName:'mdi mdi-download',
        onClick: async () => {
          await this.handleCheckLaporAll()
        },
        // disabledFunc:() => this.props.table.selected.length === 0
      })

      menus.push({
        label:'word.uploadLapor',
        iconClassName:'mdi mdi-upload',
        onClick: async () => {
          await this.handleUploadLaporAll()
        },
        disabledFunc:() => this.props.table.selected.length === 0
      })
    }

    if (this.emailProfileFeature) {
      menus.push({
        label:'word.sendEmailProfile',
        iconClassName:'mdi mdi-send',
        onClick:() => this.handleSendEmailProfile(),
        disabledFunc:() => this.props.table.selected.length === 0
      })
    }

    return [
      ...menus,

      // {
      //   label:'word.downloadBpe',
      //   iconClassName:'mdi mdi-download',
      //   onClick: async () => {
      //     await this.handleDownloadBpeAll()
      //   },
      //   disabledFunc:() => this.props.table.selected.length === 0
      // },
      {
        label:'word.exportCsv',
        iconClassName:'mdi mdi-file-excel',
        // disabledFunc:() => this.props.table.selected.length === 0,
        onClick:() => this.handleExportAll()
      },
      {
        label:'word.exportPdf',
        iconClassName:'mdi mdi-file-pdf',
        // disabledFunc:() => this.props.table.selected.length === 0,
        onClick:() => this.handleExportAll('PDF')
      },
      {
        label:'word.exportPdfInduk',
        iconClassName:'mdi mdi-file-pdf-box',
        // disabledFunc:() => this.props.table.selected.length === 0,
        onClick:() => this.handleExportIndukAll()
      },
      {
        label:'word.exportExcel',
        iconClassName:'mdi mdi-clipboard-text',
        // disabledFunc:() => this.props.table.selected.length === 0,
        onClick:() => this.handleExportExcel()
      },
      {
        label:'word.pengajuanApproval',
        iconClassName:'mdi mdi-clipboard-check-outline',
        disabledFunc:() => this.props.table.selected.length === 0,
        onClick:() => this.handleApproval()
      },
      {
        label:'word.approval',
        iconClassName:'mdi mdi-format-list-checks',
        disabledFunc:() => this.props.table.selected.length === 0,
        onClick:() => this.handleApproval('Approval')
      },
      {
        label:'word.cancelApproval',
        iconClassName:'mdi mdi-close',
        disabledFunc:() => this.props.table.selected.length === 0,
        onClick:() => this.handleCancelApproval()
      },
      /*{
        label:'word.generatePdfBpn',
        iconClassName:'mdi mdi-ticket-confirmation',
        onClick: async () => {
          await this.handlePdfBpn()
        },
      },
      {
        label:'word.downloadPdfBpn',
        iconClassName:'mdi mdi-download-network',
        onClick: async () => {
          await this.handleDownloadPdfBpn()
        },
      },
      {
        label:'word.deletePdfBpn',
        iconClassName:'mdi mdi-close-circle',
        disabledFunc:() => this.props.table.selected.length === 0,
        onClick: async () => {
          await this.handleDeletePdfBpn()
        },
      },*/
    ]
  }

  tableActions = (d) => {
    var items = [
      {
        label:"Buka SPT",
        iconClassName:"mdi mdi-book-open",
        onClick: (item) => this.props.history.push(`/${this.urlPath}/${item.id}/open`)
      },
      // {
      //   label:"More Info",
      //   iconClassName:"mdi mdi-bell",
      //   onClick: (item) => this.editItem(item)
      // }
    ]

    if(d.status === 'FINISH') {
      items.push({
        label:"Export CSV",
        iconClassName:"mdi mdi-file-excel",
        onClick: (item)=> this.handleExport(item, 'CSV')
      })

      items.push({
        label:"Export PDF",
        iconClassName:"mdi mdi-file-pdf",
        onClick: (item)=> this.handleExport(item, 'PDF')
      })

      items.push({
        label:"Export PDF Induk",
        iconClassName:"mdi mdi-file-pdf-box",
        onClick: (item)=> this.handleExportPdfInduk(item)
      })
    }

    /*
    if(d.status === 'FINISH') {
      items.push({
        label:"Pembetulan",
        iconClassName:"mdi mdi-table-edit",
        onClick: async (item) => {
          await this.service.api.pembetulan(item.id);
          this.fetchData();
        }
      })

      items.push({
        label:"Export Csv",
        iconClassName:"mdi mdi-table-edit",
        onClick: (item)=> this.handleExport(item)
      })

      items.push({
        label:"Export Pdf",
        iconClassName:"mdi mdi-table-edit",
        onClick: (item)=> this.handleExport(item, 'PDF')
      })

      items.push(
        {isSortable: true, label:"divider", iconClassName:"-"}
      )

      if(d.step == 1 || d.step == 2 || d.step == 3) {
        items.push({
          label:"Check Lapor",
          iconClassName:"mdi mdi-magnify",
          onClick: async (item) => {
            var checkRes = await LogEfillingService.api.checkStatus({
              sptId: item.id,
              pasal: this.shortPasalName
            });

            var dialog = await this.context.showDialog((props, res, rej) =>({
              title: 'Check Lapor',
              initialValue: _.cloneDeep({}),
              width: 400,
              okText: 'Ok',
              text: <div>
                <b>{checkRes.status}</b> - {checkRes.message}
              </div>
            }));

          }
        });
      } else if(d.step == 4) {
        items.push({
          label:"Download BPE",
          iconClassName:"mdi mdi-download",
          onClick: async (item) => {
            var file = await LogEfillingService.api.downloadBpe({
              sptId: item.id,
              pasal: this.shortPasalName
            });

            var filename = file.headers.filename
            download(file.data, filename);
          }
        });
      } else {
        items.push({
          label:"Lapor",
          iconClassName:"mdi mdi-table-edit",
          onClick: async (item) => {
            var dialog = await this.context.showDialog((props, res, rej) =>({
              title: 'Lapor',
              initialValue: _.cloneDeep({}),
              width: 400,
              okText: 'Send',
              text: (
                <div className='md-grid'>
                  <this.ig.Field
                    label='Select a PDF'
                    name='pdf'
                    className="md-cell md-cell--12"
                    twoLines
                    component={this.ig.Fileinput}
                    validate={this.ig.required}
                  />

              </div>
              )
            }));

            if(dialog) {

              let formData = new FormData();
              formData.append("pdf", dialog.pdf)
              formData.append("pasal", this.shortPasalName)
              formData.append("sptId", d.id)
              formData.append("isPdf", true)

              var res = await this.service.api.lapor(formData);

              izitoast.info({title: 'Lapor', message: 'sedang diproses cek detail lapor untuk memantau status lapor anda'})
            }
          }
        })
      }
    }


    items.push(
      {isSortable: true, label:"divider", iconClassName:"-"}
    )
    items.push({
      label:"Delete",
      iconClassName:"mdi mdi-delete",
      onClick:(item, callback) => this.deleteItem(item, callback),
      confirmation:{
        title:"sentence.custom.deleteItem",
        message:"sentence.custom.deleteItemConfirmation"
      }
    })
    items.push({isSortable: true, label:"divider", iconClassName:"-"})

    if((d.flowStatus === 'idle' || d.flowStatus === 'IDLE' || d.flowStatus === null) && d.status == 'PROCESS') {
      items.push( {
        label:"Pengajuan Approval",
        iconClassName:"mdi mdi-clipboard-check-outline",

        onClick: (item) => this.setState({flowDialog: true, spt: item, flowType: 'Pengajuan Approval'})
      })
    } else if(d.flowStatus === "WAITING") {
      var check = _.find(d.flowState.flowStateUsers, (f) => {
        return this.props.user.id === f.user.id
      })

      var check2 = false;
      if(d.flowState.access && d.flowState.access != '') {

        if(this.props.orgAccess[d.orgId+'.'+this.permissionName]) {
          if(this.props.orgAccess[d.orgId+'.'+this.permissionName][d.flowState.access]) check2 = true;
        }
      }

      if(check || check2) {
        var stateName = d.flowState.name+' (Flow)';
        if(stateName.toLowerCase().search('process') === -1 && stateName.toLowerCase().search('proses') === -1 ) {
          stateName = 'Proses '+d.flowState.name+' (Flow)';
        }
        items.push({
          label: stateName,
          iconClassName:"mdi mdi-comment-check",
          onClick: (item) => this.setState({flowDialog: true, spt: item, flowType: stateName})
        })
      }
    } else if(d.status === 'FINISH') {
      var check2 = false;
      if(this.props.orgAccess[d.orgId+'.'+this.permissionName]) {
        if(this.props.orgAccess[d.orgId+'.'+this.permissionName]['release']) check2 = true;
      }

      if(check2) {
        items.push({
          label: 'Cancel Flow',
          iconClassName:"mdi mdi-comment-check",
          onClick: (item) => this.cancelSptFlow(item)
        })
      }
    }

    **/

    items.push({
      label: 'Flow Histories',
      iconClassName:"mdi mdi-history",
      onClick: (item) => this.setState({flowHistory: true, spt: item})
    })

    return items;
  }

  async handleExportRebuild(item, rebuild=false) {
    if(this.state.exportType == 'CSV') {
      await LogExportService.api.export(this.service.path, item.id, rebuild);
    } else {
      await LogExportService.api.exportPdf(this.service.path, item.id, rebuild);
    }

    await this.handleExportRefresh()
  }

  async handleExportRefresh() {
    var ids = this.state.logExport.reduce((p, c)=> {
      p.push(c.id);

      return p;
    }, []);

    var res = await LogExportService.api.refresh(ids);

    this.setState({logExport: res.data})
  }

  async handleExport(item, type='CSV') {
    var dialog = await this.context.showDialog((props, res, rej) =>({
      title: `Export ${type}`,
      initialValue: {},
      height: 'auto',
      width: 400,
      okText: 'Ya',
      text: 'Apakah anda yakin akan meng-export spt ini?'
    }));

    if(dialog) {
      var exRes = {};
      if(type == 'CSV') {
        exRes = await LogExportService.api.exportCsvBySptId(this.service.path, item.id);
      } else {
        exRes = await LogExportService.api.exportPdfBySptId(this.service.path, item.id);
      }

      /*if(exRes.data) {
        await this.setState({logExport: [exRes.data]})
      } else {
        await this.setState({logExport: []});
      }

      this.setState({exportDialog: true, exportType: type, exportDialogIsDetail: false})*/

      izitoast.info({title: 'Export', message: 'sedang diproses cek Log Detail untuk memantau status export anda'})
    }
  }

  async handleExportPdfInduk(item) {
    var dialog = await this.context.showDialog((props, res, rej) =>({
      title: 'Export PDF Induk',
      initialValue: {},
      height: 'auto',
      width: 400,
      okText: 'Ya',
      text: 'Apakah anda yakin akan meng-export spt ini?'
    }));

    if(dialog) {
      var exRes = await LogExportService.api.exportPdfIndukBySptId(this.service.path, item.id);

      izitoast.info({title: 'Export', message: 'sedang diproses cek Log Detail untuk memantau status export anda'})
    }
  }

  async handleCancelApproval() {
    var items  = this.props.table.selected.reduce((res, d)=> {
      if(d.status === 'WAITING' || d.status === 'FINISH') {
        res.push(d);
      }
      return res;
    }, []);

    var dialog = await this.context.showDialog((props, res, rej) =>({
      initialValue: {},
      height: 'auto',
      width: 800,
      contentStyle: {padding:0, overflowY: 'hidden'},
      text: (
        <div>
          <div className='mpk-commandbar mpk-layout align-center justify-between mpk-border bottom solid dark'>
            <div className='mpk-font-size-M flex mpk-layout align-center'>Apakah anda yakin akan membatalkan approval terpilih?</div>
          </div>

          <DataTable className='animated fadeInDown' plain fixedHeader fixedHeight={240}>
            <TableHeader>
              <TableRow>
                <TableColumn>NPWP</TableColumn>
                <TableColumn>Nama</TableColumn>
                {/* <TableColumn>Status</TableColumn> */}
                <TableColumn>Masa</TableColumn>
                <TableColumn>Tahun</TableColumn>
                <TableColumn>Pembetulan</TableColumn>
                <TableColumn>Filename</TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody>
              {items.map((el, i) => {
                return <TableRow>
                  <TableColumn>{el.npwp}</TableColumn>
                  <TableColumn>{el.nama}</TableColumn>
                  {/* <TableColumn>{el.status}</TableColumn> */}
                  <TableColumn>{el.masa}</TableColumn>
                  <TableColumn>{el.tahun}</TableColumn>
                  <TableColumn>{el.pembetulan}</TableColumn>
                </TableRow>
              })}
            </TableBody>
          </DataTable>
        </div>
      )
    }));

    if(dialog) {
      let data = []

      for (let i = 0; i < items.length; i++) {
        let d = items[i]

        if (!d.isBpe) {
          data.push({
            formType: this.formTypeName,
            formId: d.id,
            organizationId: d.orgId
          })
        }
      }

      if (data.length) {
        await MainFlowService.api.cancelBulk(data);

        this.fetchData()
        izitoast.success({title: 'Cancel Approval', message: 'Cancel Approval telah berhasil dilakukan'})
      } else {
        izitoast.warning({title: 'Cancel Approval', message: 'Hanya SPT dengan status WAITING/FINISH & belum ada BPE yang dapat dicancel.'})
      }
    }

  }

  async handleApproval(type='Pengajuan') {
    var title = 'Pengajuan Approval';
    if(type == 'Approval') title = type;
    var dialog = await this.context.showDialog((props, res, rej) =>({
      title: title+' SPT',
      initialValue: {},
      height: 'auto',
      width: 400,
      okText: 'Ya',
      text: 'Apakah anda yakin akan mem-proses spt yang terpilih?'
    }));

    if(dialog) {

      if(type === 'Approval') {
        var accessCheck = {
        }
        if(this.props.orgAccess) accessCheck = this.props.orgAccess;

        await bluebird.each(this.props.table.selected, async (d, i) => {
          if(d.flowStateAccess) {
            if(!accessCheck[d.orgId+"."+this.permissionName]) accessCheck[d.orgId+"."+this.permissionName] = {};

            if(!accessCheck[d.orgId+"."+this.permissionName][d.flowStateAccess]) {
              var res = await UserService.api.access(d.orgId, this.permissionName, d.flowStateAccess);
              if(res) {
                var obj = accessCheck[d.orgId+"."+this.permissionName];
                obj[d.flowStateAccess] = true;
              }
            }
          }

          return true;
        })
        await this.props.dispatch({type: 'AUTH_CHANGE_ORG_ACCESS', data: accessCheck})
      }

      var data = this.props.table.selected.reduce((p, d)=> {
        if(type === 'Pengajuan' && d.status === 'PROCESS') {
          p.push(d)
        }

        if(type === 'Approval' && d.status === 'WAITING') {
          var check = _.find(d.flowState.flowStateUsers, (f) => {
            return this.props.user.id === f.user.id
          })


          var check2 = false;
          if(d.flowStateAccess && d.flowStateAccess !== '') {
            if(accessCheck[d.orgId+'.'+this.permissionName]) {
              if(accessCheck[d.orgId+'.'+this.permissionName][d.flowStateAccess]) {
                check2 = true;
              }
            }
          }
          if(check || check2) {
            p.push(d)
          }
        }
        return p;
      }, []);

      // getState
      var stateId = data.reduce((p, d) => {
        if(d.flowState) p.push(d.flowState);
        return p;
      }, [])

      var states = await MainFlowService.api.getState(stateId);
      var stateMap = states.data.reduce((p,  d)=> {
        p[d.id] = d;
        return p;
      }, {})


      this.setState({approvalDialog: true, approvalList:data, approvalType: type, stateMap, stateList: states.data})
    }
  }

  async handleExportAll(type='CSV') {
    let selected = this.props.table.selected

    if (selected.length > 0) {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export ' + type,
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: 'Apakah anda yakin akan meng-export spt yang terpilih?'
      }));

      if(dialog) {
        let res = {};

        let data = this.props.table.selected.reduce((p, c)=> {
          if(c.status === 'FINISH') {
            p.push({
              id: c.id,
              rebuild: true
            })
          }
          return p;
        }, []);

        if (data.length === 0) {
          izitoast.warning({title: 'Export', message: 'Hanya SPT dengan status FINISH yang dapat di-ekspor.'})

          return
        }

        if(type === 'CSV') {
          res = await LogExportService.api.exportBulk(this.service.path, data);
        } else {
          res = await LogExportService.api.exportBulkPdf(this.service.path, data);
        }

        if(res.data) {
          this.setState({logExport: res.data})
        } else {
          await this.setState({logExport: []});
        }

        // this.setState({exportDialog: true, exportType: type, exportDialogIsDetail: false})

        izitoast.info({title: 'Export', message: 'sedang diproses, silahkan cek Log ' + type + ' untuk memantau status export anda'})
      }
    }
    else {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export ' + type,
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: (
          <div>
            <div className='md-grid'>
              <this.ig.Field
                label='Name'
                name='name__contains'
                className="md-cell md-cell--12"
                component={this.ig.Textfield}
              />

              <this.ig.Field
                label='Npwp'
                name='npwp__contains'
                className="md-cell md-cell--12"
                component={this.ig.Textfield}
              />

              <this.ig.Field
                label='Month'
                name='masa__equals'
                className="md-cell md-cell--6"
                component={this.ig.Searchfield}
                options={[
                  {id: 1, name: "1"},
                  {id: 2, name: "2"},
                  {id: 3, name: "3"},
                  {id: 4, name: "4"},
                  {id: 5, name: "5"},
                  {id: 6, name: "6"},
                  {id: 7, name: "7"},
                  {id: 8, name: "8"},
                  {id: 9, name: "9"},
                  {id: 10, name: "10"},
                  {id: 11, name: "11"},
                  {id: 12, name: "12"}
                ]}
              />

              <this.ig.Field
                label='Year'
                name='tahun__equals'
                className="md-cell md-cell--6"
                component={this.ig.Textfield}
              />

              <this.ig.Field
                label='Status SPT'
                name='status__equals'
                className="md-cell md-cell--12"
                component={this.ig.Searchfield}
                options={[
                  {id: "FINISH", name: "FINISH"},
                  {id: "WAITING", name: "WAITING"},
                  {id: "PROCESS", name: "PROCESS"}
                ]}
              />

              <this.ig.Field
                label='Status Lapor'
                name='isLapor__equals'
                className="md-cell md-cell--12"
                component={this.ig.Searchfield}
                options={[
                  {id: true, name: "SUDAH"},
                  {id: 'false', name: "BELUM"}
                ]}
              />

              <this.ig.Field
                label='BPE'
                name='isBpe__equals'
                className="md-cell md-cell--12"
                component={this.ig.Searchfield}
                options={[
                  {id: true, name: "SUDAH"},
                  {id: false, name: "BELUM"}
                ]}
              />

              <this.ig.Field
                label='FINISH'
                name='status__in_FINISH'
                className="md-cell md-cell--6"
                component={this.ig.Checkbox}
              />

              <this.ig.Field
                label='WAITING'
                name='status__in_WAITING'
                className="md-cell md-cell--6"
                component={this.ig.Checkbox}
              />

              <this.ig.Field
                label='PROCESS'
                name='status__in_PROCESS'
                className="md-cell md-cell--6"
                component={this.ig.Checkbox}
              />
            </div>
          </div>
        )
      }));

      if (dialog === undefined)
        dialog = {}

      if (dialog) {
        let res = {};

        let param = ParamService.convert(dialog)

        if(type === 'CSV') {
          res = await LogExportService.api.exportByParamCsv(this.service.path, param);
        } else {
          res = await LogExportService.api.exportByParamPdf(this.service.path, param);
        }

        await this.setState({logExport: []});

        if (res.data.success) {
          izitoast.info({title: 'Export', message: 'sedang diproses, silahkan cek Log ' + type + ' untuk memantau status export anda'})
        } else {
          izitoast.error({title: 'Export', message: res.data.message})
        }
      }
    }
  }

  async handleExportIndukAll() {
    let selected = this.props.table.selected

    if (selected.length > 0) {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export PDF Induk',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: 'Apakah anda yakin akan meng-export spt yang terpilih?'
      }));

      if(dialog) {
        let data = this.props.table.selected.reduce((p, c)=> {
          if(c.status === 'FINISH') {
            p.push({
              id: c.id,
              rebuild: true,
              indukOnly: true
            })
          }
          return p;
        }, []);

        if (data.length === 0) {
          izitoast.warning({title: 'Export', message: 'Hanya SPT dengan status FINISH yang dapat di-ekspor.'})

          return
        }

        let res = await LogExportService.api.exportBulkPdf(this.service.path, data);

        if(res.data) {
          this.setState({logExport: res.data})
        } else {
          await this.setState({logExport: []});
        }

        // this.setState({exportDialog: true, exportType: type, exportDialogIsDetail: false})

        izitoast.info({title: 'Export', message: 'sedang diproses, silahkan cek Log PDF untuk memantau status export anda'})
      }
    }
    else {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export PDF Induk',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: (
          <div>
            <div className='md-grid'>
              <this.ig.Field
                label='Name'
                name='name__contains'
                className="md-cell md-cell--12"
                component={this.ig.Textfield}
              />

              <this.ig.Field
                label='Npwp'
                name='npwp__contains'
                className="md-cell md-cell--12"
                component={this.ig.Textfield}
              />

              <this.ig.Field
                label='Month'
                name='masa__equals'
                className="md-cell md-cell--6"
                component={this.ig.Searchfield}
                options={[
                  {id: 1, name: "1"},
                  {id: 2, name: "2"},
                  {id: 3, name: "3"},
                  {id: 4, name: "4"},
                  {id: 5, name: "5"},
                  {id: 6, name: "6"},
                  {id: 7, name: "7"},
                  {id: 8, name: "8"},
                  {id: 9, name: "9"},
                  {id: 10, name: "10"},
                  {id: 11, name: "11"},
                  {id: 12, name: "12"}
                ]}
              />

              <this.ig.Field
                label='Year'
                name='tahun__equals'
                className="md-cell md-cell--6"
                component={this.ig.Textfield}
              />

              <this.ig.Field
                label='Status SPT'
                name='status__equals'
                className="md-cell md-cell--12"
                component={this.ig.Searchfield}
                options={[
                  {id: "FINISH", name: "FINISH"},
                  {id: "WAITING", name: "WAITING"},
                  {id: "PROCESS", name: "PROCESS"}
                ]}
              />

              <this.ig.Field
                label='Status Lapor'
                name='isLapor__equals'
                className="md-cell md-cell--12"
                component={this.ig.Searchfield}
                options={[
                  {id: true, name: "SUDAH"},
                  {id: 'false', name: "BELUM"}
                ]}
              />

              <this.ig.Field
                label='BPE'
                name='isBpe__equals'
                className="md-cell md-cell--12"
                component={this.ig.Searchfield}
                options={[
                  {id: true, name: "SUDAH"},
                  {id: false, name: "BELUM"}
                ]}
              />

              <this.ig.Field
                label='FINISH'
                name='status__in_FINISH'
                className="md-cell md-cell--6"
                component={this.ig.Checkbox}
              />

              <this.ig.Field
                label='WAITING'
                name='status__in_WAITING'
                className="md-cell md-cell--6"
                component={this.ig.Checkbox}
              />

              <this.ig.Field
                label='PROCESS'
                name='status__in_PROCESS'
                className="md-cell md-cell--6"
                component={this.ig.Checkbox}
              />
            </div>
          </div>
        )
      }));

      if (dialog === undefined)
        dialog = {}

      if (dialog) {
        let param = ParamService.convert(dialog)

        let res = await LogExportService.api.exportByParamPdfInduk(this.service.path, param);

        await this.setState({logExport: []});

        if (res.data.success) {
          izitoast.info({title: 'Export', message: 'sedang diproses, silahkan cek Log PDF untuk memantau status export anda'})
        } else {
          izitoast.error({title: 'Export', message: res.data.message})
        }
      }
    }
  }

  async handleExportExcel() {
    let pasal = this.shortPasalName.toLowerCase()
    let selected = this.props.table.selected

    if (selected.length > 0) {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export Excel',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: 'Apakah anda yakin akan meng-export Excel data yang diceklis?'
      }));

      if(dialog) {
        let ids = [];

        selected.map(function (i) {
          ids.push(i.id)
        });

        let res = await LogExportService.api.exportBulkExcel(`spt${pasal}`, ids);

        let filename = res.headers.filename;

        download(res.data, filename);
      }
    }
    else {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export Excel',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: (
          <div>
            <this.ig.Field
              label='Nama'
              name='nama__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Nama Alias'
              name='namaAlias__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='NPWP'
              name='npwp__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Bulan'
              name='masa__equals'
              className="md-cell md-cell--12"
              component={this.ig.Searchfield}
              options={[
                {id: 1, name: "1"},
                {id: 2, name: "2"},
                {id: 3, name: "3"},
                {id: 4, name: "4"},
                {id: 5, name: "5"},
                {id: 6, name: "6"},
                {id: 7, name: "7"},
                {id: 8, name: "8"},
                {id: 9, name: "9"},
                {id: 10, name: "10"},
                {id: 11, name: "11"},
                {id: 12, name: "12"}
              ]}
            />
            <this.ig.Field
              label='Tahun'
              name='tahun__equals'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Status SPT'
              name='status__equals'
              className="md-cell md-cell--12"
              component={this.ig.Searchfield}
              options={[
                {id: "FINISH", name: "FINISH"},
                {id: "WAITING", name: "WAITING"},
                {id: "PROCESS", name: "PROCESS"}
              ]}
            />

            <this.ig.Field
              label='Status Lapor'
              name='isLapor__equals'
              className="md-cell md-cell--12"
              component={this.ig.Searchfield}
              options={[
                {id: true, name: "SUDAH"},
                {id: 'false', name: "BELUM"}
              ]}
            />

            <this.ig.Field
              label='BPE'
              name='isBpe__equals'
              className="md-cell md-cell--12"
              component={this.ig.Searchfield}
              options={[
                {id: true, name: "SUDAH"},
                {id: false, name: "BELUM"}
              ]}
            />

            <this.ig.Field
              label='FINISH'
              name='status__in_FINISH'
              className="md-cell md-cell--6"
              component={this.ig.Checkbox}
            />
            <this.ig.Field
              label='WAITING'
              name='status__in_WAITING'
              className="md-cell md-cell--6"
              component={this.ig.Checkbox}
            />
            <this.ig.Field
              label='PROCESS'
              name='status__in_PROCESS'
              className="md-cell md-cell--6"
              component={this.ig.Checkbox}
            />
          </div>
        )
      }));

      if (dialog === undefined)
        dialog = {}

      if (dialog) {
        let param = ParamService.convert(dialog)

        let res = await LogExportService.api.exportByParamExcel(`spt${pasal}`, param);

        let filename = res.headers.filename;

        download(res.data, filename);
      }
    }
  }

  async handlePdfBpn() {
    let pasal = this.shortPasalName.toLowerCase()
    let selected = this.props.table.selected

    if (selected.length > 0) {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Generate PDF BPN',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: 'Apakah anda yakin akan generate PDF BPN yang diceklis?'
      }));

      if(dialog) {
        let ids = [];

        selected.map(function (i) {
          if (i.status === 'FINISH') {
            ids.push(i.id)
          }
        });

        if (ids.length > 0) {
          let res = await LogExportService.api.exportBulkBpn(`spt${pasal}`, ids);

          izitoast.success({title: 'PDF BPN', message: res.data.message})
        }
        else {
          izitoast.error({title: 'PDF BPN', message: 'Anda harus menceklis SPT dengan status FINISH.'})
        }
      }
    }
    else {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Generate PDF BPN',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: (
          <div>
            <this.ig.Field
              label='Nama'
              name='nama__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Nama Alias'
              name='namaAlias__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='NPWP'
              name='npwp__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Bulan'
              name='masa__equals'
              className="md-cell md-cell--12"
              component={this.ig.Searchfield}
              options={[
                {id: 1, name: "1"},
                {id: 2, name: "2"},
                {id: 3, name: "3"},
                {id: 4, name: "4"},
                {id: 5, name: "5"},
                {id: 6, name: "6"},
                {id: 7, name: "7"},
                {id: 8, name: "8"},
                {id: 9, name: "9"},
                {id: 10, name: "10"},
                {id: 11, name: "11"},
                {id: 12, name: "12"}
              ]}
            />
            <this.ig.Field
              label='Tahun'
              name='tahun__equals'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
          </div>
        )
      }));

      if (dialog === undefined)
        dialog = {}

      if (dialog) {
        let param = ParamService.convert(dialog)

        let res = await LogExportService.api.exportByParamBpn(`spt${pasal}`, param);

        izitoast.success({title: 'PDF BPN', message: res.data.message})
      }
    }
  }

  async handleDownloadPdfBpn() {
    let pasal = this.shortPasalName.toLowerCase()
    let selected = this.props.table.selected

    if (selected.length > 0) {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Download PDF BPN',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: 'Apakah anda yakin akan download PDF BPN yang diceklis?'
      }));

      if(dialog) {
        let ids = [];

        selected.map(function (i) {
          if (i.statusBpn === 'FINISH') {
            ids.push(i.id)
          }
        });

        if (ids.length > 0) {
          let res = await LogExportService.api.downloadBulkBpn(`spt${pasal}`, ids);

          let filename = res.headers.filename;

          download(res.data, filename);
        }
        else {
          izitoast.error({title: 'Download BPN', message: 'Anda harus menceklis SPT dengan status BPN FINISH.'})
        }
      }
    }
    else {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Download PDF BPN',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: (
          <div>
            <this.ig.Field
              label='Nama'
              name='nama__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Nama Alias'
              name='namaAlias__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='NPWP'
              name='npwp__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Bulan'
              name='masa__equals'
              className="md-cell md-cell--12"
              component={this.ig.Searchfield}
              options={[
                {id: 1, name: "1"},
                {id: 2, name: "2"},
                {id: 3, name: "3"},
                {id: 4, name: "4"},
                {id: 5, name: "5"},
                {id: 6, name: "6"},
                {id: 7, name: "7"},
                {id: 8, name: "8"},
                {id: 9, name: "9"},
                {id: 10, name: "10"},
                {id: 11, name: "11"},
                {id: 12, name: "12"}
              ]}
            />
            <this.ig.Field
              label='Tahun'
              name='tahun__equals'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
          </div>
        )
      }));

      if (dialog === undefined)
        dialog = {}

      if (dialog) {
        let param = ParamService.convert(dialog)

        let res = await LogExportService.api.downloadByParamBpn(`spt${pasal}`, param);

        let filename = res.headers.filename;

        download(res.data, filename);
      }
    }
  }

  async handleDeletePdfBpn() {
    let pasal = this.shortPasalName.toLowerCase()
    let selected = this.props.table.selected

    if (selected.length > 0) {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Delete PDF BPN',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: 'Apakah anda yakin akan hapus PDF BPN yang diceklis?'
      }));

      if(dialog) {
        let ids = [];

        selected.map(function (i) {
          if (i.statusBpn === 'FINISH') {
            ids.push(i.id)
          }
        });

        if (ids.length > 0) {
          let res = await LogExportService.api.deleteBulkBpn(`spt${pasal}`, ids);

          izitoast.success({title: 'Delete BPN', message: 'File PDF BPN sukses dihapus'})

          await this.fetchData();
        }
        else {
          izitoast.error({title: 'Delete BPN', message: 'Anda harus menceklis SPT dengan status BPN FINISH.'})
        }
      }
    }
  }

  pad(v) {
    return ('0' + v).slice(-2)
  }

  async handleUploadLaporAll(skemaBaru = true) {
    if (this.permissionName === 'SPT_4A2') {
      let fileLaporTitle = 'File Lapor'

      if (skemaBaru) {
        this.codeFile.SPT_4A2 = 'F1132041218'

        fileLaporTitle = 'File Lapor Skema Baru'
      }
      else {
        this.codeFile.SPT_4A2 = 'F1132041009'

        fileLaporTitle = 'File Lapor Skema Lama'
      }

      this.fileLaporTitle = fileLaporTitle
      this.skemaBaru = skemaBaru

      this.forceUpdate()
    }

    let items = this.props.table.selected;
    let uploadLaporData = [];

    items.forEach(item => {
      if (item.status === 'FINISH') {
        uploadLaporData.push({
          spt: item,
          file: null
        })
      }
    })

    if (uploadLaporData.length > 0) {
      this.setState({uploadLaporDialog: true, uploadLaporResMap: {}, uploadLaporData})
    }
    else {
      izitoast.error({title: 'Upload File Lapor', message: 'Anda harus menceklis SPT dengan status FINISH.'})
    }
  }

  async handleUploadLaporAllSubmit() {
    let uploadLaporData = this.state.uploadLaporData

    if(uploadLaporData.length > 0) {
      let countNoPdf = 0;

      uploadLaporData.forEach(data => {
        if (!data.file && data.spt.statusBpn !== 'FINISH') {
          countNoPdf++;
        }
      })

      if (countNoPdf > 0) {
        this.setState({konfirmasiUploadLaporDialog: true, countNoPdf})
      }
      else {
        this.processUploadLaporAllSubmit()
      }
    } else {
      izitoast.error({title: 'Error', message: 'Mohon cek kembali file dan data anda'})
    }
  }

  async processUploadLaporAllSubmit() {
    let uploadLaporData = this.state.uploadLaporData

    await bluebird.mapSeries(uploadLaporData, async (d)=> {
      var data = new FormData();

      data.append("sptId",d.spt.id );
      data.append("pasal", this.shortPasalName);
      data.append("skemaBaru", this.skemaBaru);

      if(d.file) {
        data.append("pdf", d.file);
      }

      // TODO: ubah ke efiling core
      return await LogEfillingService.api.upload(data)
    })

    izitoast.success({title: 'Success', message: 'File Lapor telah selesai di upload'})

    this.setState({uploadLaporDialog: false, konfirmasiUploadLaporDialog: false})
  }

  async handleCheckLaporAll(skemaBaru = true) {
    let selected = this.props.table.selected

    if (selected.length > 0) {
      var dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Bulk Check Lapor',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: 'Apakah anda yakin akan men-check spt yang terpilih?'
      }));

      if(dialog) {
        var result = {data: []};

        let selected = this.props.table.selected;

        let allFinish = true;

        if (selected.length) {
          selected.forEach(function(x) {
            if (x.status !== 'FINISH') {
              allFinish = false;
            }
          })
        }

        if (allFinish) {
          var data = this.props.table.selected.map((d) => {
            return d.id;
          })

          // .reduce((p, c)=> {
          //   if(c.status == 'FINISH' && (c.step == 1 ||c.step == 2 ||c.step == 3)) {
          //     p.push({
          //       sptId: c.id,
          //       pasal: this.shortPasalName
          //     })
          //   }
          //   return p;
          // }, []);

          if (this.permissionName === 'SPT_4A2') {
            if (skemaBaru) {
              result = await this.service.api.laporAllWithSummary(data);
            }
            else {
              result = await this.service.api.laporAllWithSummarySkemaLama(data);
            }
          }
          else {
            result = await this.service.api.laporAllWithSummary(data);
          }


          // if(res.data) {
          //   this.setState({checkLaporList: res.data})
          // } else {
          //   await this.setState({checkLaporList: []});
          // }

          // this.setState({checkLaporDialog: true})

          var dialog2 = await this.context.showDialog((props, res, rej) => ({
            // title: 'Pembetulan',
            initialValue: _.cloneDeep({}),
            contentStyle: {padding: 0, overflowY: 'hidden'},
            okText: 'Download',
            cancelText: 'Close',
            width: 800,
            text: (
              <div>
                <div className='mpk-commandbar mpk-layout align-center justify-between mpk-border bottom solid dark'>
                  <div className='mpk-font-size-M flex mpk-layout align-center'>File Lapor</div>
                </div>

                <DataTable className='animated fadeInDown' plain fixedHeader fixedHeight={200}>
                  <TableHeader>
                    <TableRow>
                      <TableColumn>No.</TableColumn>
                      <TableColumn>Success</TableColumn>
                      <TableColumn>NPWP</TableColumn>
                      <TableColumn>Nama</TableColumn>
                      {/* <TableColumn>Status</TableColumn> */}
                      <TableColumn>Masa</TableColumn>
                      <TableColumn>Tahun</TableColumn>
                      <TableColumn>Pembetulan</TableColumn>
                      <TableColumn>Message</TableColumn>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {result.data.efilingInfos.map((el, i) => {
                      return <TableRow>
                        <TableColumn>{i + 1}</TableColumn>
                        <TableColumn>
                          {el.success && 'Success'}
                          {!el.success && 'Failed'}
                        </TableColumn>
                        <TableColumn>{el.spt.organization.npwp}</TableColumn>
                        <TableColumn>{el.spt.organization.name}</TableColumn>
                        <TableColumn>{el.spt.month}</TableColumn>
                        <TableColumn>{el.spt.year}</TableColumn>
                        <TableColumn>{el.spt.pembetulan}</TableColumn>
                        <TableColumn>{el.message}</TableColumn>

                      </TableRow>
                    })}
                  </TableBody>
                </DataTable>

              </div>
            )
          }));

          if (dialog2) {
            var ids = result.data.efilingInfos.reduce((p, d) => {
              if (d.success && d.id) {
                p.push(d.id);
              }

              return p;
            }, [])

            ids.push(result.data.idSummary);

            var file = await this.service.api.downloadLapor(ids);

            var filename = file.headers.filename
            download(file.data, filename);
          }

          // izitoast.info({title: 'Check Lapor', message: 'Check Lapor sedang diproses'})
        } else {
          izitoast.error({title: 'Check Lapor', message: 'Data yang diceklis harus berstatus "FINISH" semua.'})
        }
      }
    } else {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Bulk Check Lapor',
        initialValue: {},
        height: 'auto',
        width: 400,
        text: (
          <div>
            <this.ig.Field
              label='Nama'
              name='nama__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Nama Alias'
              name='namaAlias__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='NPWP'
              name='npwp__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Bulan'
              name='masa__equals'
              className="md-cell md-cell--12"
              component={this.ig.Searchfield}
              options={[
                {id: 1, name: "1"},
                {id: 2, name: "2"},
                {id: 3, name: "3"},
                {id: 4, name: "4"},
                {id: 5, name: "5"},
                {id: 6, name: "6"},
                {id: 7, name: "7"},
                {id: 8, name: "8"},
                {id: 9, name: "9"},
                {id: 10, name: "10"},
                {id: 11, name: "11"},
                {id: 12, name: "12"}
              ]}
            />
            <this.ig.Field
              label='Tahun'
              name='tahun__equals'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Status SPT'
              name='status__equals'
              className="md-cell md-cell--12"
              component={this.ig.Searchfield}
              options={[
                {id: "FINISH", name: "FINISH"},
                {id: "WAITING", name: "WAITING"},
                {id: "PROCESS", name: "PROCESS"}
              ]}
            />

            <this.ig.Field
              label='Status Lapor'
              name='isLapor__equals'
              className="md-cell md-cell--12"
              component={this.ig.Searchfield}
              options={[
                {id: true, name: "SUDAH"},
                {id: 'false', name: "BELUM"}
              ]}
            />

            <this.ig.Field
              label='BPE'
              name='isBpe__equals'
              className="md-cell md-cell--12"
              component={this.ig.Searchfield}
              options={[
                {id: true, name: "SUDAH"},
                {id: false, name: "BELUM"}
              ]}
            />

            <this.ig.Field
              label='FINISH'
              name='status__in_FINISH'
              className="md-cell md-cell--6"
              component={this.ig.Checkbox}
            />
            <this.ig.Field
              label='WAITING'
              name='status__in_WAITING'
              className="md-cell md-cell--6"
              component={this.ig.Checkbox}
            />
            <this.ig.Field
              label='PROCESS'
              name='status__in_PROCESS'
              className="md-cell md-cell--6"
              component={this.ig.Checkbox}
            />
          </div>
        )
      }));

      if (dialog === undefined)
        dialog = {}

      if (dialog) {
        var result = {data: []};

        let param = ParamService.convert(dialog)

        if (this.permissionName === 'SPT_4A2') {
          if (skemaBaru) {
            result = await this.service.api.laporAllWithSummaryParam(param);
          }
          else {
            result = await this.service.api.laporAllWithSummarySkemaLamaParam(param);
          }
        }
        else {
          result = await this.service.api.laporAllWithSummaryParam(param);
        }

        if (result.efilingInfos) {
          var dialog2 = await this.context.showDialog((props, res, rej) =>({
            // title: 'Pembetulan',
            initialValue: _.cloneDeep({}),
            contentStyle: {padding:0, overflowY: 'hidden'},
            okText: 'Download',
            cancelText: 'Close',
            width: 800,
            text: (
              <div>
                <div className='mpk-commandbar mpk-layout align-center justify-between mpk-border bottom solid dark'>
                  <div className='mpk-font-size-M flex mpk-layout align-center'>File Lapor</div>
                </div>

                <DataTable className='animated fadeInDown' plain fixedHeader fixedHeight={200}>
                  <TableHeader>
                    <TableRow>
                      <TableColumn>No.</TableColumn>
                      <TableColumn>Success</TableColumn>
                      <TableColumn>NPWP</TableColumn>
                      <TableColumn>Nama</TableColumn>
                      {/* <TableColumn>Status</TableColumn> */}
                      <TableColumn>Masa</TableColumn>
                      <TableColumn>Tahun</TableColumn>
                      <TableColumn>Pembetulan</TableColumn>
                      <TableColumn>Message</TableColumn>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {result.data.efilingInfos.map((el, i) => {
                      return <TableRow>
                        <TableColumn>{i+1}</TableColumn>
                        <TableColumn>
                          {el.success && 'Success'}
                          {!el.success && 'Failed'}
                        </TableColumn>
                        <TableColumn>{el.spt.organization.npwp}</TableColumn>
                        <TableColumn>{el.spt.organization.name}</TableColumn>
                        <TableColumn>{el.spt.month}</TableColumn>
                        <TableColumn>{el.spt.year}</TableColumn>
                        <TableColumn>{el.spt.pembetulan}</TableColumn>
                        <TableColumn>{el.message}</TableColumn>

                      </TableRow>
                    })}
                  </TableBody>
                </DataTable>
              </div>
            )
          }));

          if(dialog2) {
            var ids = result.data.efilingInfos.reduce((p, d)=> {
              if(d.success && d.id) {
                p.push(d.id);
              }

              return p;
            }, [])

            ids.push(result.data.idSummary);

            var file = await this.service.api.downloadLapor(ids);

            var filename = file.headers.filename
            download(file.data, filename);
          }
        } else {
          let message = 'Gagal generate file lapor.'

          if (result.data) {
            if (result.data.message) {
              message = result.data.message
            }
          }

          izitoast.warning({title: 'Gagal', message: message})
        }
      }
    }
  }

  async handleDownloadBpeAll() {
    var dialog = await this.context.showDialog((props, res, rej) =>({
      title: 'Bulk Download BPE',
      initialValue: {},
      height: 'auto',
      width: 400,
      okText: 'Ya',
      text: 'Apakah anda yakin akan men-download BPE spt yang terpilih?'
    }));

    if(dialog) {
      var res = {};

      var data = this.props.table.selected.reduce((p, c)=> {
        if(c.status === 'FINISH' && (c.step === 4)) {
          p.push({
            sptId: c.id,
            pasal: this.shortPasalName
          })
        }
        return p;
      }, []);

      res = {} //await LogEfillingService.api.checkStatus(data);


      if(res.data) {
        this.setState({checkLaporList: res.data})
      } else {
        await this.setState({checkLaporList: []});
      }

      this.setState({checkLaporDialog: true})

      izitoast.info({title: 'Download BPE', message: 'Download BPE sedang diproses'})
    }
  }

  async handleSendEmailProfile() {
    var items = this.props.table.selected;

    let ids = []

    items.forEach(item => {
      if (item.status === 'FINISH') {
        ids.push(item.id)
      }
    })

    if (!ids.length) {
      izitoast.warning({title: 'Gagal', message: 'Hanya SPT dengan status FINISH yang bisa dikirim ke email.'})
      return
    }

    try {
      let res = await this.service.api.sendEmailToProfile(ids)

      izitoast.success({title: 'Sukses', message: 'Silahkan cek halaman log email.'})
    } catch (e) {
      izitoast.error({title: 'Error', message: e.message})
    }

    /*var result = {data: []}

    if(this.service.api.deleteSelected) {
      result = await this.service.api.deleteSelected(ids)
    } else if(this.service.api.deleteAll) {
      result = await this.service.api.deleteAll(ids)
    }*/
  }



  viewContainer = (props) => {

    return <div className="mpk-layout column fill">
      <FlowActionDialog
        dialogType={this.state.flowType}
        spt={this.state.spt}
        formData={{}}
        visible={this.state.flowDialog}
        onClose={()=> this.setState({flowDialog: false})}
        onSubmit={(value, callback)=> this.onFlowAction(value, callback)}
      />
      <FlowHistoryDialog
        formType={this.formTypeName}
        formData={this.state.spt}
        visible={this.state.flowHistory}
        onClose={()=> this.setState({flowHistory: false})}
        onSubmit={(value, callback)=>{callback()}}

      />
      <FlowActionBulkDialog
        dialogType={this.state.approvalType}
        approvalList={this.state.approvalList}
        stateMap={this.state.stateMap}
        stateList={this.state.stateList}
        formData={{}}
        visible={this.state.approvalDialog}
        onClose={()=> this.setState({approvalDialog: false})}
        onSubmit={(value, callback)=> this.onFlowActionBulk(value, callback)}
      />

      {this.exportDialogView()}
      {this.checkLaporView()}
      {this.uploadLaporView()}
      {this.konfirmasiUploadLaporView()}

      {props.children}
    </div>
  }

  checkLaporView() {
    return <DialogContainer
      width={700}
      visible={this.state.checkLaporDialog}
      onHide={()=> {this.setState({checkLaporDialog: false})}}
      actions={[{
        secondary: true,
        children: 'Close',
        onClick: ()=> {this.setState({checkLaporDialog: false})}
      }]}
      title="Check Lapor"
      >
        <DataTable className='animated fadeInDown' plain fixedHeader fixedHeight={240}>
          <TableHeader>
            <TableRow>
              <TableColumn>NPWP</TableColumn>
              <TableColumn>Status</TableColumn>
              <TableColumn>Masa</TableColumn>
              <TableColumn>Tahun</TableColumn>
              <TableColumn>Pembetulan</TableColumn>
              <TableColumn>Message</TableColumn>
            </TableRow>
          </TableHeader>
          <TableBody>
              {this.state.checkLaporList.map((el, i) => {
                return <TableRow>
                  <TableColumn>{el.npwp}</TableColumn>
                  <TableColumn>{el.status}</TableColumn>
                  <TableColumn>{el.masa}</TableColumn>
                  <TableColumn>{el.tahun}</TableColumn>
                  <TableColumn>{el.pembetulan}</TableColumn>
                  <TableColumn>{el.message}</TableColumn>
                </TableRow>
              })}
          </TableBody>
        </DataTable>
      </DialogContainer>
  }

  uploadLaporView() {
    return <DialogContainer
      width={700}
      contentStyle={{padding:0, overflowY: 'hidden'}}
      visible={this.state.uploadLaporDialog}
      onHide={()=> {this.setState({uploadLaporDialog: false})}}
      actions={[
      <FileInput
        id="multiple-file-upload"
        multiple
        secondary
        flat
        name="multipart-file-upload"
        label="Browse Files"
        accept=".pdf"
        onChange={(files, e)=> {
          if(!files) files = []
            let selected = this.props.table.selected
            var items = [];
            var filteredFile = [];
            var sptMap = {};

            selected.forEach(select => {
              if (select.status === 'FINISH') {
                items.push(select)
              }
            })

            var mappedItem = items.reduce((pv, v)=> {
              let npwp = v.npwp;
              let masa = this.pad(v.masa);
              let tahun = v.tahun;
              let pembetulan = this.pad(v.pembetulan);
              let codeFile = this.codeFile[this.permissionName]
              let ext = ".pdf"
              let fileName = npwp +  masa + masa + tahun + pembetulan + codeFile + ext

              pv[fileName] = v;
              return pv;
            }, {});

            for (let index = 0; index < files.length; index++) {
              const file = files[index];
              var spt = mappedItem[file.name];
              if(spt) {
                filteredFile.push({
                  spt: spt,
                  file: file
                });
                sptMap[spt.id] = file.name;
              }
            }

            items.forEach((v, i) => {
              if(!sptMap[v.id]) {
                filteredFile.push({
                  spt: v,
                  file: null
                });
              }
            })
            this.setState({uploadLaporResMap: sptMap, uploadLaporData: filteredFile})
        }}
      />,
      {
        primary: true,
        children: 'Submit',
        onClick: async ()=> {
          await this.handleUploadLaporAllSubmit()
        }
      },
      {

        children: 'Close',
        onClick: ()=> {this.setState({uploadLaporDialog: false})}
      }
    ]}
      >
        <div className='mpk-commandbar mpk-layout align-center justify-between mpk-border bottom solid dark'>
          <div className='mpk-font-size-M flex mpk-layout align-center'>{ this.fileLaporTitle }</div>
        </div>

        {this.state.uploadLaporDialog &&
          <DataTable className='animated fadeInDown' plain fixedHeader fixedHeight={240}>
            <TableHeader>
              <TableRow>
                <TableColumn>NPWP</TableColumn>
                <TableColumn>Nama</TableColumn>
                {/* <TableColumn>Status</TableColumn> */}
                <TableColumn>Masa</TableColumn>
                <TableColumn>Tahun</TableColumn>
                <TableColumn>Pembetulan</TableColumn>
                <TableColumn>Filename</TableColumn>
                {/*<TableColumn>Status PDF BPN</TableColumn>
                <TableColumn>Ukuran PDF BPN</TableColumn>*/}
              </TableRow>
            </TableHeader>
            <TableBody>
              {this.props.table.selected.map((el, i) => {
                if (el.status === 'FINISH') {
                  let statusBpn = '-'
                  let sizeBpn = '-'

                  if (el.statusBpn) {
                    var style = {
                      background: 'grey'
                    }

                    switch (el.statusBpn) {
                      case 'PROCESS':
                        style.background = '#2196F3'
                        break;
                      case 'FINISH':
                        style.background = '#4CAF50'
                        sizeBpn = this.convertFileSize(el.sizeBpn)
                        break;
                      case 'FAILED':
                        style.background = '#f44336'
                        break;
                      default:
                        break;
                    }

                    statusBpn = <Chip label={el.statusBpn} style={style}/>
                  }

                  return <TableRow>
                    <TableColumn>{el.npwp}</TableColumn>
                    <TableColumn>{el.nama}</TableColumn>
                    {/* <TableColumn>{el.status}</TableColumn> */}
                    <TableColumn>{el.masa}</TableColumn>
                    <TableColumn>{el.tahun}</TableColumn>
                    <TableColumn>{el.pembetulan}</TableColumn>
                    <TableColumn>{this.state.uploadLaporResMap[el.id]}</TableColumn>
                    {/*<TableColumn>{statusBpn}</TableColumn>
                    <TableColumn>{sizeBpn}</TableColumn>*/}
                  </TableRow>
                }
              })}
            </TableBody>
          </DataTable>
        }

        <div className='md-grid'>

        </div>
      </DialogContainer>
  }

  konfirmasiUploadLaporView() {
    return <DialogContainer
      width={400}
      contentStyle={{padding:0, overflowY: 'hidden'}}
      visible={this.state.konfirmasiUploadLaporDialog}
      onHide={()=> {this.setState({konfirmasiUploadLaporDialog: false})}}
      actions={[
        {
          primary: true,
          children: 'Proses',
          onClick: async ()=> {
            await this.processUploadLaporAllSubmit()
          }
        },
        {
          children: 'Close',
          onClick: ()=> {this.setState({konfirmasiUploadLaporDialog: false})}
        }
      ]}
    >
      <div className='mpk-commandbar mpk-layout align-center justify-between mpk-border bottom solid dark'>
        <div className='mpk-font-size-M flex mpk-layout align-center'>Konfirmasi PDF File Lapor</div>
      </div>

      {this.state.konfirmasiUploadLaporDialog &&
        <div style={{margin: 20}}>Terdapat {this.state.countNoPdf} SPT yang belum ada file PDF, apakah anda akan tetap melanjutkan proses pelaporan?</div>
      }

      <div className='md-grid'>

      </div>
    </DialogContainer>
  }

  exportDialogView() {
    return <DialogContainer

        width={700}
        visible={this.state.exportDialog}
        onHide={()=> {this.setState({exportDialog: false})}}
        actions={[{
          secondary: true,
          children: 'Close',
          onClick: ()=> {this.setState({exportDialog: false})}
        }]}
        title={
          <div className='mpk-layout row'>
            {!this.state.exportDialogIsDetail && <span className='flex'>Log Export {this.state.exportType}</span> }
            {this.state.exportDialogIsDetail && <span className='flex'>Log Export {this.state.exportType} Detail</span> }

            {!this.state.exportDialogIsDetail && <Button secondary raised style={{float: 'right'}} onClick={()=> {this.handleExportRefresh()}}>Refresh</Button> }
            {this.state.exportDialogIsDetail &&<Button secondary raised style={{float: 'right'}} onClick={()=> {this.setState({exportDialogIsDetail: false})}}>Back</Button>}
          </div>
        }
        >

          {!this.state.exportDialogIsDetail &&
            <DataTable className='animated fadeInDown' plain fixedHeader fixedHeight={240}>
              <TableHeader>
                <TableRow>
                  <TableColumn>NPWP</TableColumn>
                  <TableColumn>Status</TableColumn>
                  <TableColumn>Masa</TableColumn>
                  <TableColumn>Tahun</TableColumn>
                  <TableColumn>Pembetulan</TableColumn>
                  <TableColumn></TableColumn>
                </TableRow>
              </TableHeader>
              <TableBody>
                  {this.state.logExport.map((el, i) => {
                    return <TableRow>
                      <TableColumn>{el.npwp}</TableColumn>
                      <TableColumn>{el.status}</TableColumn>
                      <TableColumn>{el.masa}</TableColumn>
                      <TableColumn>{el.tahun}</TableColumn>
                      <TableColumn>{el.pembetulan}</TableColumn>
                      <TableColumn>
                        {el.status === 'FINISH' &&
                          <MenuButton
                          icon
                          menuItems={[
                            {
                              primaryText: 'Details',
                              onClick: ()=> {
                                this.setState({exportDialogIsDetail: true, logExportSelected:el})
                              }
                            },
                            {
                              primaryText: 'Download',
                              onClick: async ()=> {
                                var file = await LogExportService.api.download(el.id);

                                var filename = file.headers.filename
                                download(file.data, filename);

                              }
                            },
                            {
                              primaryText: 'Rebuild',
                              onClick: ()=> {this.handleExportRebuild({id: el.sptId}, true)}
                            }
                          ]}
                          anchor={{
                            x: MenuButton.HorizontalAnchors.CENTER,
                            y: MenuButton.VerticalAnchors.CENTER,
                          }}
                        >
                          more_vert
                        </MenuButton>
                      }
                      </TableColumn>
                    </TableRow>
                  })}
              </TableBody>
            </DataTable>
          }

          {this.state.exportDialogIsDetail &&
            <DataTable className='animated fadeInDown' plain fixedHeader fixedHeight={180}>
              <TableHeader>
                <TableRow>
                  <TableColumn>Name</TableColumn>
                  <TableColumn>Count</TableColumn>
                  <TableColumn>Total</TableColumn>
                  <TableColumn>Message</TableColumn>
                </TableRow>
              </TableHeader>
              {this.state.logExportSelected.details &&
              <TableBody>

                  {this.state.logExportSelected.details.map((c, i) => (
                    <TableRow key={i}>
                      <TableColumn>{c.name}</TableColumn>
                      <TableColumn>{c.count}</TableColumn>
                      <TableColumn>{c.total}</TableColumn>
                      <TableColumn>{c.message}</TableColumn>
                    </TableRow>
                  ))}

              </TableBody>
              }
            </DataTable>
          }
        </DialogContainer>
  }

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='Nama'
          name='nama__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Nama Alias'
          name='namaAlias__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='NPWP'
          name='npwp__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Dari Bulan'
          name='masa__greaterOrEqualThan'
          className="md-cell md-cell--6"
          component={this.ig.Searchfield}
          options={[
            {id: 1, name: "1"},
            {id: 2, name: "2"},
            {id: 3, name: "3"},
            {id: 4, name: "4"},
            {id: 5, name: "5"},
            {id: 6, name: "6"},
            {id: 7, name: "7"},
            {id: 8, name: "8"},
            {id: 9, name: "9"},
            {id: 10, name: "10"},
            {id: 11, name: "11"},
            {id: 12, name: "12"}
          ]}
        />
        <this.ig.Field
          label='Sampai Bulan'
          name='masa__lessOrEqualThan'
          className="md-cell md-cell--6"
          component={this.ig.Searchfield}
          options={[
            {id: 1, name: "1"},
            {id: 2, name: "2"},
            {id: 3, name: "3"},
            {id: 4, name: "4"},
            {id: 5, name: "5"},
            {id: 6, name: "6"},
            {id: 7, name: "7"},
            {id: 8, name: "8"},
            {id: 9, name: "9"},
            {id: 10, name: "10"},
            {id: 11, name: "11"},
            {id: 12, name: "12"}
          ]}
        />

        <this.ig.Field
          label='Bulan'
          name='masa__equals'
          className="md-cell md-cell--12"
          component={this.ig.Searchfield}
          options={[
            {id: 1, name: "1"},
            {id: 2, name: "2"},
            {id: 3, name: "3"},
            {id: 4, name: "4"},
            {id: 5, name: "5"},
            {id: 6, name: "6"},
            {id: 7, name: "7"},
            {id: 8, name: "8"},
            {id: 9, name: "9"},
            {id: 10, name: "10"},
            {id: 11, name: "11"},
            {id: 12, name: "12"}
          ]}
        />

        <this.ig.Field
          label='Tahun'
          name='tahun__equals'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Status SPT'
          name='status__equals'
          className="md-cell md-cell--12"
          component={this.ig.Searchfield}
          options={[
            {id: "FINISH", name: "FINISH"},
            {id: "WAITING", name: "WAITING"},
            {id: "PROCESS", name: "PROCESS"}
          ]}
        />

        <this.ig.Field
          label='Status Lapor'
          name='isLapor__equals'
          className="md-cell md-cell--12"
          component={this.ig.Searchfield}
          options={[
            {id: true, name: "SUDAH"},
            {id: 'false', name: "BELUM"}
          ]}
        />

        <this.ig.Field
          label='BPE'
          name='isBpe__equals'
          className="md-cell md-cell--12"
          component={this.ig.Searchfield}
          options={[
            {id: true, name: "SUDAH"},
            {id: false, name: "BELUM"}
          ]}
        />

        <this.ig.Field
          label='WAITING'
          name='status__in_WAITING'
          className="md-cell md-cell--6"
          component={this.ig.Checkbox}
        />
        <this.ig.Field
          label='PROCESS'
          name='status__in_PROCESS'
          className="md-cell md-cell--6"
          component={this.ig.Checkbox}
        />
        <this.ig.Field
          label='FINISH'
          name='status__in_FINISH'
          className="md-cell md-cell--6"
          component={this.ig.Checkbox}
        />
        <this.ig.Field
          label='DELETING'
          name='status__in_DELETING'
          className="md-cell md-cell--6"
          component={this.ig.Checkbox}
        />
        <this.ig.Field
          label='CLONING'
          name='status__in_CLONING'
          className="md-cell md-cell--6"
          component={this.ig.Checkbox}
        />
      </this.SearchContainer>
    )
  }

  convertFileSize(bytes) {
    let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

    if (bytes == null || bytes == 0) return '0 Byte';

    let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));

    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
  }

  columns=[
    {isSortable: true, label: "word.name",  value: "nama", show:true, isSearchable:true},
    {isSortable: true, label: "word.aliasName",  value: "namaAlias", show:true, isSearchable:true},
    {isSortable: true, label: "word.npwp", value: "npwp", show:true, isSearchable:true},
    {isSortable: true, label: "word.month",  value: "masa", show:true, isSearchable:true, type: 'number'},
    {isSortable: true, label: "word.year",  searchField: "tahun", value: (d)=> d.tahun, show:true, isSearchable:true, type: 'func'},
    {isSortable: true, label: "word.pembetulan",  value: "pembetulan", show:true, isSearchable:true, type: 'number'},
    {
      searchField: "status",
      label: "word.statusSpt",
      type: 'func',
      value: (d)=> {
        var style = {
          background: 'grey'
        }
        var stateStyle = {
          background: 'transparent'
        }
        switch (d.status) {
          case 'PROCESS':
            style.background = '#2196F3'
            break;
          case 'FINISH':
            style.background = '#4CAF50'
            break;
          default:
            break;
        }

        if (d.currentState) {
          return (<div>
            <br/>
            <br/>
            <br/>
            <br/>
            <Chip label={d.status} style={style} />
            <br/>
            <br/>
            <Chip label={d.currentState} style={stateStyle} />
            <br/>
            <br/>
          </div>)
        }
        else {
          return <Chip label={d.status} style={style} />
        }
      },
      className: "mpk-font-size-S", align: 'center', show:true, isSearchable:true},

    {isSortable: true, label: "word.isLapor", searchField: 'isLapor', value: (d)=> {
      if(d.isLapor) {
        return <Chip label="SUDAH" style={{backgroundColor: '#4CAF50'}} />
      } else {
        return <Chip label="BELUM" style={{backgroundColor: '#FF9800'}} />
      }
    }, type:'func', show:true, isSearchable:true},
    {isSortable: true, label: "word.isBpe", searchField: 'isBpe', value: (d)=> {
      if(d.isBpe) {
        return <Chip label="SUDAH" style={{backgroundColor: '#4CAF50'}} />
      } else {
        return <Chip label="BELUM" style={{backgroundColor: '#FF9800'}} />
      }
    }, type:'func', show:true, isSearchable:true},

    /*{isSortable: true, label: "word.currentState",  value: "currentState", show:true, isSearchable:true},*/
    // {isSortable: true, label: "word.pasal",  value: , show:false, isSearchable:true},

    // {isSortable: true, label: "word.step",  show:true, searchField: "step", value: (d)=> d.step, show:true, isSearchable:true, type: 'func'},

    /*{
      searchField: "statusBpn",
      label: "word.statusBpn",
      type: 'func',
      value: (d)=> {
        if (!d.statusBpn)
          return '-'

        var style = {
          background: 'grey'
        }
        switch (d.statusBpn) {
          case 'PROCESS':
            style.background = '#2196F3'
            break;
          case 'FINISH':
            style.background = '#4CAF50'
            break;
          case 'FAILED':
            style.background = '#f44336'
            break;
          default:
            break;
        }
        return <Chip label={d.statusBpn} style={style} />
      },
      className: "mpk-font-size-S", show:true, isSearchable:true},
    {isSortable: false, label: "word.messageBpn",  value: "messageBpn", show:false, isSearchable:false},
    {isSortable: false, label: "word.pathBpn",  value: "pathBpn", show:false, isSearchable:false},
    {isSortable: true, label: "word.sizeBpn",  value: (d) => this.convertFileSize(d.sizeBpn), isDefaultSort:false, show:false, isSearchable:false, type: 'func'},*/
    {isSortable: true, label: "word.owner",  value: "createdBy", show:false, isSearchable:true},
    {isSortable: true, label: "word.createdAt",  value: "createdDate", show:false, isSearchable:true, isDefaultSort: true},
  ]


}
