import React, { Component } from 'react';
import { connect } from 'react-redux';

import {Field, getFormValues, reduxForm} from 'redux-form';
import { Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab } from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Switch as Switch2} from '../../../../../../components/form';

import ListView from '../../../../../../components/entity/listView';
import ColumnService from '../../../../../../services/column.service';
// import ImportService from './../Import/Import.service';
import axios from 'axios';
import izitoast from 'izitoast';
import FormDialog from './ImportBP4a2.dialog';
import ImportBP4a2Service from './ImportBP4a2.service';
import download from "downloadjs";
import ListViewImport from '../../../../../../components/entity/ListViewImport';
import ImportDialogResult from '../../../../../../components/entity/import.dialog.result';
import OrganizationService from "../../../../Administrator/Organization/Organization.service";
import MasterCustomImportService from "../../../../Master/MasterCustomImport/MasterCustomImport.service";

@reduxForm({form: 'ImportBP4a2Form', destroyOnUnmount: true})
@connect((state) => ({
  ...ImportBP4a2Service.stateConnectSetting()(state),
  formData    : getFormValues('ImportBP4a2Form')(state)
}), ImportBP4a2Service.actionConnectSetting())
export default class ImportBp4a2View extends ListViewImport {
  service=ImportBP4a2Service
  FormDialog=FormDialog

  category='BP_4A2'
  importPath='/api/import/spt/4a2'
  showAutomatisTab=false
  showPenomoranAuto=true
  showFormatEspt=true
  showPembetulan=true
  // viewType=2

  constructor(props){
    super(props);
    this.state = {
      ...this.state,
      showTableSetting:true,
      showDialogConfirmDeleteSelected:false,
      showForm:false,
      formData: {},
      data: [],
      onProgress: false,
      isError: false,
      errorMessage: '',
      type: 'M'
    }
  }

  typeSendiri = [
    {
      name: 'Sewa Tanah/Bangunan (PPh 4 (2) Disetor Sendiri)',
      id: 'SENDIRI_SEWA_TANAH'
    },
    {
      name: 'Usaha Jasa Konstruksi (PPh 4 (2) Disetor Sendiri)',
      id: 'SENDIRI_JASA_KONSTRUKSI'
    },
    {
      name: 'WP Melakukan Pengalihan Hak Atas Tanah/Bangunan (PPh 4 (2) Disetor Sendiri)',
      id: 'WP_HAK_ATAS_TANAH'
    }
  ]

  typeNormal = [
    {
      name: 'Bunga Deposito/Tabungan, Diskonto SBI, Jasa Giro,',
      objName: 'bungaDeposito',
      id: 'BUNGA_DEPOSITO'
    },
    {
      name: 'Bunga dan/atau Diskonto Obligasi dan Surat Berharga Negara (SBN)',
      objName: 'bungaDiskonto',
      id: 'BUNGA_DISKONTO'
    },
    {
      name:'Bunga Simpanan yang dibayarkan oleh Koperasi kepada Anggota Koperasi Orang Pribadi',
      objName: 'bungaSimpanan',
      id: 'BUNGA_SIMPANAN'
    },
    {
      name: 'Dividen yang Diterima atau Diperoleh Wajib Pajak Orang Pribadi Dalam Negri',
      objName: 'dividenDiterima',
      id: 'DIVIDEN_DITERIMA'
    },
    {
      name: 'Hadiah Undian',
      objName: 'hadiahUndian',
      id: 'HADIAH_UNDIAN'
    },
    {
      name: 'Penghasilan dari Usaha jasa Kontruksi',
      objName: 'jasaKonstruksi',
      id: 'JASA_KONSTRUKSI'
    },
    {
      name: 'Penghasilan dari Transaksi Derivatif berupa Kontrak Berjangka yang Diperdagangkan di Bursa',
      objName: 'transaksiDerivatif',
      id: 'TRANSAKSI_DERIVATIF'
    },
    {
      name: 'Penghasilan dari Transaksi Penjualan Saham yang Diperdagangkan di Bursa Efek',
      objName: 'penjualanSaham',
      id: 'PENJUALAN_SAHAM'
    },
    {
      name: 'Penghasilan dari persewaan Tanah dan/atau Bangunan',
      objName: 'persewaanTanah',
      id: 'PERSEWAAN_TANAH'
    },
    {
      // name: 'Penghasilan dari Usaha yang Diterima oleh Wajib Pajak yang Memiliki Peredaran Bruto Tertentu',
      name: 'Penghasilan Wajib Pajak yang Memiliki Peredaran Bruto Tertentu',
      objName: 'peredaranTertentu',
      id: 'WP_PEREDARAN_TERTENTU'
    }
  ]

  beforeFetch(params) {
    // params["type.equals"] = "SPT_4A2"
    params["category.in"] = "BP_4a2, SENDIRI_SEWA_TANAH, SENDIRI_JASA_KONSTRUKSI, WP_HAK_ATAS_TANAH, BUNGA_DEPOSITO, BUNGA_DISKONTO, BUNGA_SIMPANAN, DIVIDEN_DITERIMA, HADIAH_UNDIAN, JASA_KONSTRUKSI, TRANSAKSI_DERIVATIF, PENJUALAN_SAHAM, PERSEWAAN_TANAH, WP_PEREDARAN_TERTENTU"
  }

  async handleDownloadTemplate() {

    if (this.props.formData.category ==='BP_4a2') {
      izitoast.warning({
        title: 'Pemberitahuan !',
        message: 'Pilih salah satu kategori template',
      });
    } else {
      var report = await ImportBP4a2Service.api.downloadTemplate(this.props.formData.category)
      download(report.data, this.props.formData.category+'.csv');
    }
  }

  componentDidMount(){
    let path = this.props.match.path.split("/")
    let params = path[2];
    this.props.change("category", params)
    this.props.change("type", "M")
    this.props.change("no", false)
    this.props.change("migration", false)
    this.props.change("formatEspt", false)
    this.props.change("pembetulan", false)
    this.props.change("npwp", null)
  }

  handleTabChange(newActiveTabIndex){
    if(newActiveTabIndex == 0){
      this.props.change("type", "M");
      this.setState({type: 'M'})
    } else {
      this.props.change("type", "A")
      this.setState({type: 'A'})
    }
  }

  // yg ditambah
  tabWidth = 280
  tabs= ["import", "search", "column"]

  tabComponents() {
    var obj = this;
    return {
      import: this.import.bind(obj)
    }
  }
   // end yg ditambah

  async checkCategory() {
    let category = this.props.formData.category

    if (!category)
      return false

    if (category === 'BP_4a2')
      return false

    return true
  }

  import(){
    const { handleSubmit, submitting, valid, pristine } = this.props;

    let formatEspt = false;

    if (this.props.formData) {
      formatEspt = this.props.formData.formatEspt
    }

    return (
        <div className='mpk-layout column fill'>
        <ImportDialogResult dataResults={this.state.importResults} visible={this.state.showImportResult} onHide={()=> {this.setState({showImportResult: false})}} />
          <div className='flex'>
            <div className='md-grid'>
              <div className="md-cell md-cell--12">
                {this.state.type === 'M' &&<Field
                  label='Kategori'
                  name='category'
                  twoLines
                  component={Searchfield}
                  options={this.typeNormal}
                  // validate={validation.required}
                  onChange={(e,v,p) => {
                      this.findCustomImports(v)
                    }
                  }
                />
                }
                {this.state.type === 'A' &&<Field
                  label='Kategori'
                  name='category'
                  twoLines
                  component={Searchfield}
                  options={this.typeSendiri}
                  // validate={validation.required}
                  onChange={(e,v) => {
                      this.findCustomImports(v)
                    }
                  }
                />
                }
              </div>
              <div className="md-cell md-cell--12">
                <Field
                  label='Select a File'
                  name='file'
                  multiple
                  twoLines
                  component={Fileinput}
                  validate={validation.required}
                />
              </div>
              <div className="md-cell md-cell--12">
                <Field
                  label='Aktifkan Penomoran Bukti Potong Otomatis ?'
                  name='no'
                  component={Switch2}
                  // validate={validation.required}
                />
              </div>
              <div className="md-cell md-cell--12">
                <Field
                  label='Import Migrasi'
                  name='migration'
                  component={Switch2}
                  // validate={validation.required}
                />
              </div>

              {this.showPembetulan &&
                <div className="md-cell md-cell--12">
                  <Field
                    label='Pembetulan'
                    name='pembetulan'
                    component={Switch2}
                    // validate={validation.required}
                  />
                </div>
              }

              {this.showFormatEspt &&
                <div className="md-cell md-cell--12">
                  <Field
                    label='Format ESPT'
                    name='formatEspt'
                    component={Switch2}
                    // validate={validation.required}
                    onChange={(e,v) => {
                      this.showSingleNpwp = v
                    }}
                  />
                </div>
              }

              { this.customImports.length > 0 &&
                <Field
                  label     = "Custom Import"
                  name      = "customImport"
                  id        = "customImport"
                  className = "md-cell md-cell--12"
                  valueField = 'parent'
                  viewField = 'name'
                  params     = {{
                    size: 50
                  }}
                  component    = {Searchfield}
                  itemTemplate = {function(d) {
                    return {
                      primaryText  : d.name,
                      secondaryText: d.multiple ? 'MULTIPLE' : 'SINGLE',
                      onClick      : () => {
                        this.handleItemClick(d); this.hide()
                      }
                    }
                  }}
                  onChange={(e, v) => {
                    if (v) {
                      if (v.id) {
                        this.showSingleNpwp = !v.multiple
                      }
                    }
                  }}
                  options={this.customImports}
                />
              }

              { this.showSingleNpwp &&
                <div className="md-cell md-cell--12">
                  <Field
                    label='Organization'
                    name='npwp'
                    valueField='npwp'
                    params={{ size: 50 }}
                    remoteSearch={true}
                    service={OrganizationService}
                    searchField  = {['name', 'aliasName', 'npwp']}
                    component={Searchfield}
                    itemTemplate={function(d) {
                      let nameAndAlias = ""

                      if (d.name)
                        nameAndAlias = d.name

                      if (d.aliasName)
                        nameAndAlias += " - " + d.aliasName

                      return {
                        primaryText  : nameAndAlias,
                        secondaryText: d.npwp,
                        onClick      : () => {
                          if(!this.props.disabled){
                            this.handleItemClick(d); this.hide()
                          }
                        }
                      }
                    }}
                  />
                </div>
              }
              <div className="md-cell md-cell--12">
                  <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>Import</Button>
              </div>
            </div>
          </div>
          {this.state.onProgress ? (
              <LinearProgress style={{margin: 0}} query={true} />
            ) : (null)}

          <Tabs className='mpk-tab-white' onTabChange={this.handleTabChange.bind(this)} tabId="ImportTab">
            <Tab label="Pemotongan">

            </Tab>
            <Tab label="Setor Sendiri">

            </Tab>
          </Tabs>
        </div>
    )
  }

}
