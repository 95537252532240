import React, { Component } from 'react';
import { connect } from 'react-redux';

import SettingEfilingService from './SettingEfiling.service';
import ListView from '../../../../../components/entity/listView';
import ColumnService from '../../../../../services/column.service';

@connect(SettingEfilingService.stateConnectSetting(), SettingEfilingService.actionConnectSetting())
export default class SettingEfilingView extends ListView {
  service=SettingEfilingService
  editDialog=false

  _barActions = [
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => this.fetchData()
    }
  ]

  _tableActions = [
    {
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    }
  ]

  editItem = (item) => {
    this.props.history.push("SETTING_EFILING"+"/"+item.company.id)
  }

  columns=[
    {isSortable: true, label: "word.id", searchField: "id", value: function(d){ return d.id }, type: "func",  show:false, isSearchable:true},
    {isSortable: true, label: "word.company", searchField: "company", value: function(d){ return d.company.nama }, type: "func",  show:true, isSearchable:true},
    {isSortable: true, label: "word.host", searchField: "host", value: function(d){ return d.host }, type: "func", show:true, isSearchable:true},
    {isSortable: true, label: "word.port", searchField: "port", value: function(d){ return d.port }, type: "func", show:true, isSearchable:true},
    {isSortable: true, label: "word.clientId",searchField: "clientId",  value: 'clientId', type: "boolean",  show:true, isSearchable:true},
    {isSortable: true, label: "word.clientSecret",searchField: "clientSecret",  value: 'clientSecret', show:false, isSearchable:true},
    {isSortable: true, label: "word.apiId",searchField: "apiId",  value: 'apiId', show:false, isSearchable:true},
    {isSortable: true, label: "word.apiSecret",searchField: "apiSecret",  value: 'apiSecret', show:false, isSearchable:true},
    {isSortable: true, label: "word.accessToken",searchField: "accessToken",  value: 'accessToken', show:false, isSearchable:true},
    {isSortable: true, label: "word.refreshToken",searchField: "useProxy",  value: 'refreshToken', show:false, isSearchable:true},
    {isSortable: true, label: "word.expiresDate",searchField: "expiresDate",  value: 'expiresDate', type:"date", show:false, isSearchable:true},
    {isSortable: true, label: "word.expiresIn",searchField: "expiresIn",  value: function(d){ return d.expiresIn }, type:"func", show:false, isSearchable:true}
  ]
}
