import React from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  Tab,
  Tabs,
  SelectionControlGroup,
  Button
} from 'react-md';
import download from 'downloadjs';
import uuid from 'uuid';
import iziToast from 'izitoast';

import FormView from '../../../../../components/entity/form.view';
import SPT23IndukService from './Spt23Induk.service';
import {validation, Textfield, Searchfield, Switch, Checkbox, Datepicker, TextfieldMask} from '../../../../../components/form';
import columnService from '../../../../../services/column.service';
import terbilangService from '../../../../../services/terbilang.service';

@reduxForm({form: 'SPT_INDUK_23_Form', destroyOnUnmount: true, initialValues: {jasaLains: []}})
@connect((state) => ({
  access      : state.entity.access.api.find,
  organization: state.auth.currentOrganization,
  spt         : state.auth.currentSPT,
  formData    : getFormValues('SPT_INDUK_23_Form')(state)
}))
export default class Spt23IndukForm extends FormView {
  service  = SPT23IndukService
  viewType = 2;

  initialData={
    bagB1    : {},
    bagB2    : {},
    bagC     : {},
    bagD     : {},
    bagE     : {},
    jasaLains: []
  }

  constructor(props){
    super(props);
    this.state = {
      ...this.state,
      cetak    : false,
      calculate: false,

      signer: {}
    }
  }

  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      let value = this.props.formData
      value.spt = this.props.spt.data
      this.handleSave(value)
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  async handleSave(value) {
    try {
      this.setState({onProgress: true})
      var res = await this.service.api.update(value)

      if(res.data) {
        var sptId                                         = '';
        if  (this.props.spt && this.props.spt.data) sptId = this.props.spt.data.id;
        if  (this.props.match.params.id) sptId            = this.props.match.params.id;


        let jasaLains = [];
        if(res.data) {
          var jasaRes   = await this.service.api.getJasaLin(sptId, res.data.id);
              jasaLains = jasaRes.data;
        }

        res.data.jasaLains = jasaLains;

        if(!res.data.bagB26.jmlPph) res.data.bagB26.jmlPph = 0;
        if(!res.data.bagB23.jmlPph) res.data.bagB23.jmlPph = 0;

        res.data.bagB23.terbilang = terbilangService(parseInt(res.data.bagB23.jmlPph));
        res.data.bagB26.terbilang = terbilangService(parseInt(res.data.bagB26.jmlPph));
        this.props.initialize(res.data);
      }

      this.setState({onProgress: false})
      this.toast.success({title: 'Success', message: 'Saved'})
    } catch(e) {
      var msg              = e.message;
      if  (e.response) msg = e.response.data.message;
      this.setState({
        isError     : true,
        onProgress  : false,
        errorMessage: msg
      })
    }
  }

  async initData() {
    var sptId                                         = '';
    if  (this.props.spt && this.props.spt.data) sptId = this.props.spt.data.id;
    if  (this.props.match.params.id) sptId            = this.props.match.params.id;
    let res                                           = await this.service.api.getBySptId(sptId);

    let jasaLains = [];
    if(res.data) {
      var jasaRes   = await this.service.api.getJasaLin(sptId, res.data.id);
          jasaLains = jasaRes.data;
    }

    res.data.jasaLains = jasaLains;

    if(!res.data.bagB26.jmlPph) res.data.bagB26.jmlPph = 0;
    if(!res.data.bagB23.jmlPph) res.data.bagB23.jmlPph = 0;

    res.data.bagB23.terbilang = terbilangService(parseInt(res.data.bagB23.jmlPph));
    res.data.bagB26.terbilang = terbilangService(parseInt(res.data.bagB26.jmlPph));
    // res.data.tanggal          = new Date();


    // signer
    let signer = null;
    try {
      signer = await this.service.api.getSigner({
        orgId: this.props.spt.data.organization.id,
        tgl  : res.data.tanggal
      });
    } catch (error) {}

    if(signer) {
      this.setState({signer: signer})
      if(res.data.bagD.kuasa) {
        res.data.bagD.npwp = signer.npwpKuasa;
        res.data.bagD.nama = signer.namaKuasa;
      } else {
        res.data.bagD.npwp = signer.npwpPenandaTanganSpt;
        res.data.bagD.nama = signer.namaPenandaTanganSpt;
      }
    }
    this.props.initialize(res.data);
  }

  async changeSigner() {
    if(this.state.signer.id) {
      let formData                       = this.initialData
      if  (this.props.formData) formData = this.props.formData;
      if(formData.bagD.kuasa) {
        this.props.change('bagD.npwp', this.state.signer.npwpKuasa);
        this.props.change('bagD.nama', this.state.signer.namaKuasa);
      } else {
        this.props.change('bagD.npwp', this.state.signer.npwpPenandaTanganSpt);
        this.props.change('bagD.nama', this.state.signer.namaPenandaTanganSpt);
      }
    }
  }

  async handleCetak(){
    this.setState({ cetak: true })
    try {
      const sptId    = this.props.match.params.sptId
      var   report   = await this.service.api.cetak(sptId)
      var   filename = report.headers.filename;
      download(report.data, filename);
      this.setState({ cetak: false })
    } catch (e) {
      const reader = new FileReader();

      /// This fires after the blob has been read/loaded.
      reader.addEventListener('loadend', (e) => {
        const text = e.srcElement.result
        let json = JSON.parse(text)

        if (json.detail)
          iziToast.error({
            title: 'Error',
            message: json.detail
          })
      });

      reader.readAsText(e.response.data)

      this.setState({cetak: false})
    }
  }

  async handleCetakJasaLain(){
    this.setState({ cetak: true })
    try {
      const sptId    = this.props.match.params.sptId
      var   report   = await this.service.api.cetak(sptId)
      var   filename = report.headers.filename;
      download(report.data, filename);
      this.setState({ cetak: false })
    } catch (e) {
      const reader = new FileReader();

      /// This fires after the blob has been read/loaded.
      reader.addEventListener('loadend', (e) => {
        const text = e.srcElement.result
        let json = JSON.parse(text)

        if (json.detail)
          iziToast.error({
            title: 'Error',
            message: json.detail
          })
      });

      reader.readAsText(e.response.data)

      this.setState({cetak: false})
    }
  }

  async calculate() {
    await this.setState({calculate: true})
    await this.props.dispatch({
      type: 'TOGGLE_GLOBAL_PROGRESS',
      data: true
    });

    const sptId = this.props.match.params.sptId

    try {
      var res = await this.service.api.calculate(sptId)

      if(res.data) {
        let jasaLains = [];
        var jasaRes   = await this.service.api.getJasaLin(sptId, res.data.id);
            jasaLains = jasaRes.data;

        res.data.jasaLains = jasaLains;

        if(!res.data.bagB26.jmlPph) res.data.bagB26.jmlPph = 0;
        if(!res.data.bagB23.jmlPph) res.data.bagB23.jmlPph = 0;
           res.data.bagB23.terbilang                       = terbilangService(parseInt(res.data.bagB23.jmlPph));
           res.data.bagB26.terbilang                       = terbilangService(parseInt(res.data.bagB26.jmlPph));
        this.props.initialize(res.data);
      }
    } catch(e) {

    }


    this.setState({calculate: false})
    await this.props.dispatch({
      type: 'TOGGLE_GLOBAL_PROGRESS',
      data: false
    });
  }

  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;
    let   statusSPT                                     = "";
    let   statusInSPT                                   = false;
    if(this.props.spt){
        if(this.props.spt.data){
            if(this.props.spt.data.status){
                statusSPT   = this.props.spt.data.status;
                statusInSPT = true
            }
        }
    }

    if(statusSPT == "FINISH" || statusSPT == "WAITING"){
        return (
            <div>
              <Button disabled={this.state.cetak} primary raised style={{marginRight: 5}} onClick={this.handleCetak.bind(this)}>Cetak</Button>
            </div>
        )
    } else {
        if(statusInSPT){
            return (
              <div>
                <Button disabled={this.state.cetak} primary raised style={{marginRight: 5}} onClick={this.handleCetak.bind(this)}>Cetak</Button>

                {/* <Button disabled={this.state.calculate} primary raised style={{marginRight: 5}} onClick={this.calculate.bind(this)}>calculate</Button> */}

                <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>Save and Calculate</Button>
              </div>
            )
        } else {
            return (
                <div>
                    {/* <Button disabled={this.state.calculate} primary raised style={{marginRight: 5}} onClick={this.calculate.bind(this)}>calculate</Button> */}
                    <Button secondary flat onClick={()=> {this.props.history.goBack()}} style={{marginRight: 16}}>Back</Button>

                </div>
            )
        }
    }
  }

  async calculateTotal(e,v,pv,f) {
    await this.props.change(f, v);

    var form = this.props.formData;
    var d    = form.bagB23;

    var jmlPph   = 0;
    var jmlBruto = 0;
    Object.keys(d).forEach((k, i) => {
      if(!(k == 'jmlBruto' || k == 'jmlPph' || k == 'terbilang' || k == 'createdBy' || k == 'createdDate' || k == 'id' || k == 'lastModifiedBy' || k == 'lastModifiedDate')) {
        var x = d[k];

        var pph            = x.pph;
        var bruto          = x.bruto;
        if  (!bruto) bruto = 0;
        if  (!pph) pph     = 0;

        jmlPph   = jmlPph+ parseFloat(pph);
        jmlBruto = jmlBruto+ parseFloat(bruto)
      }
    })

    jmlBruto = Math.floor(jmlBruto)
    jmlPph = Math.floor(jmlPph)

    this.props.change('bagB23.jmlBruto', jmlBruto)
    this.props.change('bagB23.jmlPph', jmlPph);

    this.props.change('bagB23.terbilang', terbilangService(parseInt(jmlPph)));
  }

  formView() {
    let formData                                                     = this.initialData
    if  (this.props.formData && this.props.formData.values) formData = this.props.formData.values

    var masaOptions = [
      {id:1, name: 1}, {id:2, name: 2}, {id:3, name: 3}, {id:4, name: 4}, {id:5, name: 5}, {id:6, name: 6},
      {id:7, name: 7}, {id:8, name: 8}, {id:9, name: 9}, {id:10, name: 10}, {id:11, name: 11}, {id:12, name: 12}
    ]
    return (
      <div>
        <Card>
          {/* <CardTitle title='' style={{padding: '10px 16px'}} subtitle='A. IDENTITAS PEMOTONG' />
          <Divider/> */}

          <div className='md-grid'>
            <Field
              label        = 'Tanggal Lapor'
              className    = "md-cell md-cell--12"
              name         = 'tanggal'
              component    = {Datepicker}
              validate     = {validation.required}
              onDateChange = { async (d)=> {
                let signer = null;

                try {
                  signer = await this.service.api.getSigner({
                    orgId: this.props.spt.data.organization.id,
                    tgl  : moment(d).format("YYYY-MM-DD")
                  })

                  await this.setState({signer: signer})
                } catch (error) {
                }

                await this.changeSigner()
              }}
              />
          </div>
        </Card>
        <br/>

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='PPH PASAL 23 YANG TELAH DIPOTONG' />
          <Divider/>
          <DataTable plain fixedScrollWrapperStyle={{overflow: 'inherit'}} style={{overflow: 'inherit'}}>
            <TableHeader>
              <TableRow>
                <TableColumn>No.</TableColumn>
                <TableColumn>Jenis Penghasilan</TableColumn>
                <TableColumn>KAP/KJS</TableColumn>
                <TableColumn className='md-text-right'>Jumlah Penghasilan Bruto(Rp)</TableColumn>
                <TableColumn className='md-text-right'>PPH Di Potong(Rp)</TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableColumn>
                  1.
                </TableColumn>
                <TableColumn>Dividen</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name      = 'bagB23.dividen.kapKjs'
                    component = {Textfield}
                    disabled
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    className = 'md-text-right'
                    name      = 'bagB23.dividen.bruto'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    className = 'md-text-right'
                    name      = 'bagB23.dividen.pph'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  2.
                </TableColumn>
                <TableColumn>Bunga</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    component = {Textfield}
                    name      = 'bagB23.bunga.kapKjs'
                    disabled
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    className = 'md-text-right'
                    name      = 'bagB23.bunga.bruto'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB23.bunga.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  3.
                </TableColumn>
                <TableColumn>Royalti</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB23.royalti.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB23.royalti.bruto'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB23.royalti.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  4.
                </TableColumn>
                <TableColumn>Hadiah dan Penghargaan</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB23.hadiahDanPenghargaan.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB23.hadiahDanPenghargaan.bruto'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name = 'bagB23.hadiahDanPenghargaan.pph'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  5.
                </TableColumn>
                <TableColumn>Sewa dan Penghasilan Lain sehubungan dengan penggunaan harta **)</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB23.sewaDanPenghasilanLain.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB23.sewaDanPenghasilanLain.bruto'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name = 'bagB23.sewaDanPenghasilanLain.pph'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  6.
                </TableColumn>
                <TableColumn>Jasa Teknik, Jasa Manajemen , Jasa Konsultasi dan Jasa Lain sesuai PMK-244/PMK.03/2008 **) :</TableColumn>
                <TableColumn>

                </TableColumn>
                <TableColumn>

                </TableColumn>
                <TableColumn>

                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>

                </TableColumn>
                <TableColumn>a. Jasa Teknik</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB23.jasaTeknik.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB23.jasaTeknik.bruto'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name = 'bagB23.jasaTeknik.pph'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>

                </TableColumn>
                <TableColumn>b. Jasa Manajemen</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB23.jasaManagemen.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB23.jasaManagemen.bruto'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name = 'bagB23.jasaManagemen.pph'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>

                </TableColumn>
                <TableColumn>c. Jasa Konsultan</TableColumn>

                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB23.jasaKonsultan.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB23.jasaKonsultan.bruto'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name = 'bagB23.jasaKonsultan.pph'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>

                </TableColumn>
                <TableColumn>d. Jasa Lain: **)</TableColumn>
                <TableColumn>

                </TableColumn>
                <TableColumn>

                </TableColumn>
                <TableColumn>

                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>

                </TableColumn>
                <TableColumn>
                  <Field
                    label = 'Jasa'
                    name  = 'bagB23.jasaLain1.nama'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB23.jasaLain1.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB23.jasaLain1.bruto'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name = 'bagB23.jasaLain1.pph'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>

                </TableColumn>
                <TableColumn>
                  <Field
                    label = 'Jasa'
                    name  = 'bagB23.jasaLain2.nama'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB23.jasaLain2.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB23.jasaLain2.bruto'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name = 'bagB23.jasaLain2.pph'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>

                </TableColumn>
                <TableColumn>
                  <Field
                    label = 'Jasa'
                    name  = 'bagB23.jasaLain3.nama'
                    disabled
                    className = 'md-text-right'
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB23.jasaLain3.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB23.jasaLain3.bruto'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name = 'bagB23.jasaLain3.pph'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  7.
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name      = 'bagB23.uraian7.nama'
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB23.uraian7.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name      = 'bagB23.uraian7.bruto'
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    onChange  = {(e, v, vp, f) => this.calculateTotal(e, v, vp, f)}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB23.uraian7.pph'
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    onChange  = {(e, v, vp, f) => this.calculateTotal(e, v, vp, f)}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow style={{background: '#ddd'}}>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  Jumlah
                </TableColumn>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name      = 'bagB23.jmlBruto'
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    disabled
                    money = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB23.jmlPph'
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    disabled
                    money = {','}
                  />
                </TableColumn>
              </TableRow>



              <TableRow style={{background: '#ddd'}}>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  Terbilang
                </TableColumn>
                <TableColumn colSpan={4}>
                  <Field
                    // label='Bruto'
                    name      = 'bagB23.terbilang'
                    component = {Textfield}
                    disabled
                    money = {','}
                  />
                </TableColumn>
              </TableRow>

            </TableBody>

          </DataTable>
        </Card>

        <br/>


        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='DAFTAR JASA LAIN' />
          <Divider/>

          <DataTable plain fixedScrollWrapperStyle={{overflow: 'inherit'}} style={{overflow: 'inherit'}}>
            <TableHeader>
              <TableRow>
                <TableColumn>No.</TableColumn>
                <TableColumn>Uraian Jasa</TableColumn>
                <TableColumn className='md-text-right'>Jumlah Penghasilan Bruto</TableColumn>
                <TableColumn className='md-text-right'>Jumlah PPh Dipotong</TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody>
            {this.props.formData.jasaLains.map((d, i) => {
              return <TableRow>
                <TableColumn>{i+1}</TableColumn>
                <TableColumn>{d.uraian}</TableColumn>
                <TableColumn  className='md-text-right'>
                  {columnService.money(d.jmlBruto)}
                </TableColumn>
                <TableColumn  className='md-text-right'>
                  {columnService.money(d.jmlPph)}
                </TableColumn>
              </TableRow>
            })}

            </TableBody>
          </DataTable>
        </Card>

        <br/>

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='B. PPH PASAL 26 YANG TELAH DIPOTONG' />
          <Divider/>

          <DataTable plain fixedScrollWrapperStyle={{overflow: 'inherit'}} style={{overflow: 'inherit'}}>
            <TableHeader>
              <TableRow>
                <TableColumn>No.</TableColumn>
                <TableColumn>Jenis Penghasilan</TableColumn>
                <TableColumn>KAP/KJS</TableColumn>
                <TableColumn>Bruto(Rp)</TableColumn>
                <TableColumn>Perkiraan <br/> Penghasilan Neto (%)</TableColumn>
                <TableColumn>PPH Di Potong(Rp)</TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableColumn>
                  1.
                </TableColumn>
                <TableColumn>Dividen</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB26.dividen.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name      = 'bagB26.dividen.bruto'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB26.dividen.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  2.
                </TableColumn>
                <TableColumn>Bunga</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB26.bunga.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name      = 'bagB26.bunga.bruto'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB26.bunga.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  3.
                </TableColumn>
                <TableColumn>Royalti</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB26.royalti.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name      = 'bagB26.royalti.bruto'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB26.royalti.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  4.
                </TableColumn>
                <TableColumn>Sewa dan Penghasilan Lain sehubungan dengan penggunaan harta selain penghasilan atas pengalihan tanah dan atau bangunan</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB26.sewaDanPenghasilanLain.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name      = 'bagB26.sewaDanPenghasilanLain.bruto'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB26.sewaDanPenghasilanLain.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  5.
                </TableColumn>
                <TableColumn>Imbalan sehubungan dengan Jasa,Pekerjaan, dan Kegiatan</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB26.imbalanJasa.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name      = 'bagB26.imbalanJasa.bruto'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB26.imbalanJasa.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  6.
                </TableColumn>
                <TableColumn>Hadiah dan Penghargaan</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB26.hadiahDanPenghargaan.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name      = 'bagB26.hadiahDanPenghargaan.bruto'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB26.hadiahDanPenghargaan.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  7.
                </TableColumn>
                <TableColumn>Pensiun dan Pembayaran Berkala</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB26.pensiunDanPembayaranBerkala.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name      = 'bagB26.pensiunDanPembayaranBerkala.bruto'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB26.pensiunDanPembayaranBerkala.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  8.
                </TableColumn>
                <TableColumn>Premi swap dan transaksi lindung nilai</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB26.premiSwap.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name      = 'bagB26.premiSwap.bruto'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB26.premiSwap.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  9.
                </TableColumn>
                <TableColumn>Keuntungan karena pembebasan utang</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB26.keuntunganPembebasanUtang.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name      = 'bagB26.keuntunganPembebasanUtang.bruto'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB26.keuntunganPembebasanUtang.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  10.
                </TableColumn>
                <TableColumn>Penjualan harta di Indonesia</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB26.penjualanHarga.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name      = 'bagB26.penjualanHarga.bruto'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>

                <TableColumn>
                  <Field
                    // label='Bruto'
                    name      = 'bagB26.penjualanHarga.netto'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB26.penjualanHarga.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  11.
                </TableColumn>
                <TableColumn>Premi asuransi/reasuransi</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB26.reasuransi.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name      = 'bagB26.reasuransi.bruto'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name      = 'bagB26.reasuransi.netto'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB26.reasuransi.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  12.
                </TableColumn>
                <TableColumn>Penghasilan dari penjualan atau pengalihan saham</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB26.penghasilanPenjualan.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name      = 'bagB26.penghasilanPenjualan.bruto'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>

                <TableColumn>
                <Field
                    // label='Bruto'
                    name      = 'bagB26.penghasilanPenjualan.netto'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB26.penghasilanPenjualan.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  13.
                </TableColumn>
                <TableColumn>Penghasilan kena pajak BUT sesudah dikurangi pajak</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB26.penghasilanKenaBUT.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name      = 'bagB26.penghasilanKenaBUT.bruto'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB26.penghasilanKenaBUT.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow style={{background: '#ddd'}}>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  Jumlah
                </TableColumn>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name      = 'bagB26.jmlBruto'
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    disabled
                    money = {','}
                  />
                </TableColumn>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB26.jmlPph'
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    disabled
                    money = {','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow style={{background: '#ddd'}}>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  Terbilang
                </TableColumn>
                <TableColumn colSpan={4}>
                  <Field
                    // label='Bruto'
                    name      = 'bagB26.terbilang'
                    component = {Textfield}
                    disabled
                    money = {','}
                  />
                </TableColumn>
              </TableRow>
            </TableBody>

          </DataTable>
        </Card>

        <br/>

         <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='LAMPIRAN' />
          <Divider/>
          <DataTable plain fixedScrollWrapperStyle={{overflow: 'inherit'}} style={{overflow: 'inherit'}}>
            <TableHeader>
              <TableRow>
                <TableColumn>Cek</TableColumn>
                <TableColumn>Keterangan</TableColumn>
                <TableColumn>Lembar</TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableColumn>
                  <Field
                    name      = 'bagC.suratSetoranPajak.check'
                    component = {Checkbox}
                    disabled
                  />
                </TableColumn>

                <TableColumn>
                  Surat Setoran Pajak
                </TableColumn>
                <TableColumn>
                  <Field
                    name      = 'bagC.suratSetoranPajak.lembar'
                    component = {Textfield}
                    disabled
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  <Field
                    name      = 'bagC.daftarBuktiPotongan.check'
                    component = {Checkbox}
                    disabled
                  />
                </TableColumn>

                <TableColumn>
                  Daftar Bukti Pemotongan PPh Pasal 23 dan/atau Pasal 26
                </TableColumn>
                <TableColumn>
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>
                  <Field
                    name      = 'bagC.buktiPotongan.check'
                    component = {Checkbox}
                    disabled
                  />
                </TableColumn>

                <TableColumn>
                  Bukti Pemotongan PPh Pasal 23 dan/atau Pasal 26
                </TableColumn>
                <TableColumn>
                  <Field
                    name      = 'bagC.buktiPotongan.lembar'
                    component = {Textfield}
                    disabled
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  <Field
                    name      = 'bagC.suratKuasaKhusus.check'
                    component = {Checkbox}
                    onChange  = {async (ev, v)=> {
                      await this.props.change('bagD.kuasa', v);

                      await this.changeSigner()
                    }}
                  />
                </TableColumn>

                <TableColumn>
                Surat Kuasa Khusus
                </TableColumn>
                <TableColumn>
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  <Field
                   name      = 'bagC.suratKeteranganDomisili.check'
                   component = {Checkbox}
                  />
                </TableColumn>

                <TableColumn>
                Legalisasi fotocopy Surat Keterangan Domisili yang masih berlaku, dalam hal PPh Pasal 26 Dihitung berdasarkan Tarif Perjanjian Penghindaran Pajak Berganda (P3B)
                </TableColumn>
                <TableColumn>
                </TableColumn>
              </TableRow>
            </TableBody>
          </DataTable>
        </Card>

        <br />

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='TANDA TANGAN PEMOTONG' />
          <Divider/>
          {/* <div className='md-grid'>
            <div className="md-cell md-cell--12">
              <p style={{textAlign: 'center'}}>Dengan menyadari sepenuhnya atas segala akibatnya termasuk sanksi-sanksi sesuai dengan ketentuan yang berlaku, saya menyatakan bahwa apa yang telah saya beritahukan di atas beserta lampiran-lampirannya adalah benar, lengkap dan jelas.</p>
            </div>
          </div> */}
          <div className='md-grid'>
            <Field
              className = "md-cell md-cell--12"
              label     = 'Jenis Penandatangan'
              name      = 'bagD.kuasa'
              component = {Searchfield}
              options   = {[
                {id: false, name: 'Pemotong'},
                {id: true, name: 'Kuasa'}
              ]}
              onChange={async (e, v)=> {
                await this.props.change('bagD.kuasa', v);

                await this.changeSigner()
              }}
              //validate = {validation.required}
              disabled
              // disable karena saat cetak ambil dari DB signer
            />

            <div className="md-cell md-cell--6">
              <Field
                label      = 'NPWP Pemotong'
                name       = 'bagD.npwp'
                component  = {TextfieldMask}
                maskFormat = "##.###.###.#-###-###"
                validate   = {validation.required}
                disabled
                // disable karena saat cetak ambil dari DB signer
              />

            </div>
            <div className="md-cell md-cell--6">
              <Field
                label     = 'Nama Pemotong'
                name      = 'bagD.nama'
                component = {Textfield}
                validate  = {validation.required}
                disabled
                // disable karena saat cetak ambil dari DB signer
              />
            </div>
          </div>
        </Card>
      </div>
    )
  }
}
