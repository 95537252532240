import ApiService from '../../../../../../../services/api.service'
import http from '../../../../../../../services/http.service'

class PbkEbupotService extends ApiService {
  name= 'PbkEbupot';
  path= 'ebupot/pbk';
  advancedSearch=true

  columns=[
    {isSortable: true, searchField: 'date', label: "word.date",  value: "date", show:true, isSearchable:true},
    {isSortable: true, searchField: 'noPbk', label: "word.noPbk",  value: "noPbk", show:true, isSearchable:true},
    {isSortable: true, searchField: 'total', label: "word.total", value: "total", show:true, isSearchable:true, type: 'number'},
    {width: 150, isSortable: true, searchField: 'createdBy', label: "word.createdBy",  value:"createdBy", show:true, isSearchable:true},
    {isSortable: true, searchField: 'createdDate', label: "word.createdDate",  value:"createdDate", type:"date", show:true, isSearchable:true},
  ]

  api= {
    djp: async(esptId)=> {
      var res = await this.http.get(`${this.apiLocation}/ebupot/espt23/${esptId}/djp`)

      return res
    },
    summary: async (esptId) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${esptId}/espt23/summary`)

      return res.data;
    },
    total: async (sptId)=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/total`)

      return res;
    },
    downloadCsv: async (sptId)=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/csv/${sptId}`, {
        responseType: 'blob'
      })

      return res;
    },
    deleteBulk: async (sptId, ids)=> {
      let res = await http.post(`${this.apiLocation}/${this.path}/${sptId}/delete`, ids);

      return res;
    },
    getSummary: async(id)=> {
      let res = await http.get(`${this.apiLocation}/ebupot/espt23/${id}/info`);

      return res
    }
  }
  constructor() {
    super()
    this.init()
    this.initApi()
  }
}


export default new PbkEbupotService();
