import ApiService from '../../../../../../services/api.service';

class Master25KapService extends ApiService {
  name= 'Master25Kap';
  path= 'spt25/master/kap';

  constructor() {
    super();
    this.init()
  }
}


export default new Master25KapService();

