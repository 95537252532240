import React, { Component } from 'react';
import { Grid, Cell } from 'react-md';
import { Field, reduxForm , propTypes, formValueSelector } from 'redux-form';
import {validation, Textfield, Switch} from '../../../../components/form';
import DialogView from '../../../../components/entity/dialog.view';
import CompanyService from './Company.service';

@reduxForm({form: 'company-dialog', destroyOnUnmount: false})
export default class CompanyDialogView extends DialogView {
  service=CompanyService;
  initialData = {
    // activationKey: Math.random().toString(36).substring(7),
    active: false,
    emailProfile: false,
    kpp: {
      id: 1
    }
  }

  formView() {
    return (
      <Grid style={{padding:'none'}}>
        <Field
          label='Name'
          name='nama'
          className="md-cell md-cell--6"
          component={Textfield}
          validate={validation.required}
        />

        <Field
          label='Email'
          name='email'
          className="md-cell md-cell--6"
          component={Textfield}
          validate={validation.required}
        />
        <Field
          label='Phone'
          name='telp'
          className="md-cell md-cell--12"
          component={Textfield}
          validate={validation.required}
        />

        <Field
          label='Address'
          name='alamat'
          rows={4}
          className="md-cell md-cell--12"
          component={Textfield}
          validate={validation.required}
        />

        <Field
          label='Fitur Email Profil'
          name='emailProfile'
          component={Switch}
        />
      </Grid>
    )
  }
}
