import React, { Component } from 'react';
import { connect } from 'react-redux';
import Bluebird from 'bluebird';
import moment from 'moment';

import { Field, reduxForm } from 'redux-form';
import { Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab } from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Switch as Switch2} from '../../../../components/form';

import ListView from '../../../../components/entity/listView';
import axios from 'axios';
import izitoast from 'izitoast';
import download from "downloadjs";
import FileUploadService from './FileUpload.service';

@reduxForm({form: 'FileUpload_Form', destroyOnUnmount: true})
@connect((state)=> ({
  ...FileUploadService.stateConnectSetting()(state),
  user: state.auth.user,
  company: state.auth.currentCompany
}),FileUploadService.actionConnectSetting())
export default class FileUploadView extends ListView {
  service=FileUploadService
  translate=false
  accept="image/png, image/jpeg, image/jpg"
  FormDialog=()=> <div/>

  titleHeader() {
    return `File Management`;
  }

  defaultColumns = [
    {isSortable:true, label: "word.id",  value: "id", show:true, isSearchable:true, isDefaultSort: false},
    {isSortable:true, label: "word.createdBy",  value: "createdBy", show:true, isSearchable:true},
    {isSortable:true, label: "word.createdDate", value: "createdDate", show:true, isSearchable:true, type:"date", isDefaultSort: true},
    {isSortable:true, label: "word.lastModifiedBy",  value: "lastModifiedBy", show:true, isSearchable:true},
    {isSortable:true, label: "word.lastModifiedDate", value: (d)=> {
      if(d.lastModifiedDate) {
        return <div className='mpk-font-size-S'>{moment(d.lastModifiedDate).format('lll')}</div>
      } else {
        return null;
      }
    }, show:true, isSearchable:true, type:"func", searchField: 'lastModifiedDate', isDefaultSort: true}
  ]

  fetchOption() {
    return {
      path: `file/upload/${this.props.company.id}/company`
    }
  }

  beforeFetch(params) {
    // params["category.equals"] = "EFILING"
  }

  async uploadForm() {
    var dialog = await this.context.showDialog((props, res, rej) =>({
      title: 'Upload',
      initialValue: {},
      height: 'auto',
      width: 400,
      okText: 'Ya',
      text: (
        <div className="md-grid">
          <Field
            label='Select a File'
            name='files'
            multiple
            twoLines
            component={Fileinput}
            validate={validation.required}
            accept={this.accept}
          />
        </div>
      )
    }));

    if(dialog && dialog.files) {

      var files = []
      for (let index = 0; index < dialog.files.length; index++) {
        const element = dialog.files[index];
        files.push(element)
      }

      await Bluebird.mapSeries(files, async (d)=> {
        let formData = new FormData();
        formData.append("file", d);

        try {
          await this.service.api.upload(formData);
        } catch (e) {

        }
      })
      await this.fetchData()
    }
  }


  columns = [
    {label: "word.img",  value: (d)=> {
      var image  = new Image();
      // console.l
      // var encode = URL.createObjectURL(d.data);

      return <img src={'data:image/png;base64, '+d.data} style={{width: 'auto', height: 100}}/>

    }, show:true, isSearchable:true, type: 'func'},
    {label: "word.filename",  value: "fileName", show:true, isSearchable:true},
    {label: "word.fileType",  value: "fileType", show:true, isSearchable:true},
    {label: "word.company",  value: "company.nama", show:true, isSearchable:true},

  ]

  _barActions = [{
    label:'Upload',
    iconClassName:'mdi mdi-upload',
    onClick:() => {
      this.uploadForm()
    }
  },{
    label:'word.delete',
    iconClassName:'mdi mdi-delete',
    onClick:() => {
      this.setState({showDialogConfirmDeleteSelected:true})
    },
    disabledFunc:() => this.props.table.selected.length === 0
  },{
    label:'Refresh',
    iconClassName:'mdi mdi-refresh',
    onClick:() => {

      window.location.hash = window.location.hash.split('?')[0]
      this.fetchData()
    }
  },]

  tableActions= []
}
