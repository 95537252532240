import React, { Component } from 'react';
import { connect } from 'react-redux';

import FormDialog from './MasterLawanTransaksi15.dialog.view';
import MasterLawanTransaksi15Service from './MasterLawanTransaksi15.service';
import ListView from '../../../../components/entity/listView';
import ColumnService from '../../../../services/column.service';
import LogExportService from "../../../Main/Export/LogExport.service";
import ParamService from "../../../../services/param.service";
import download from "downloadjs";

@connect(MasterLawanTransaksi15Service.stateConnectSetting(), MasterLawanTransaksi15Service.actionConnectSetting())
export default class MasterLawanTransaksi15View extends ListView {
  apiPath = 'page';

  service=MasterLawanTransaksi15Service
  FormDialog=FormDialog

  columns=[
    {label: "word.npwp",  value: "npwp", isDefaultSort:false, show:true, isSearchable:true},
    {label: "word.nama",  value: "nama", isDefaultSort:false, show:true, isSearchable:true},
    {label: "word.alamat",  value: "alamat", isDefaultSort:false, show:true, isSearchable:true},
    {label: "word.email",  value: "email", isDefaultSort:false, show:true, isSearchable:true},
    {label: "word.organizationNpwp",  value: "organization.npwp", isDefaultSort:false, show:true, isSearchable:true},
    {label: "word.organizationName",  value: "organization.name", isDefaultSort:false, show:true, isSearchable:true},
  ]

  barActions() {
    return [
      ...this._barActions,
      {
        label:'word.exportCsv',
        iconClassName:'mdi mdi-file-excel',
        // disabledFunc:() => this.props.table.selected.length === 0,
        onClick:() => this.handleExportAll()
      },
    ]
  }

  async handleExportAll() {
    let selected = this.props.table.selected

    if (selected.length > 0) {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export CSV',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: 'Apakah anda yakin akan meng-export CSV data yang diceklis?'
      }));

      if(dialog) {
        let ids = [];

        selected.map(function (i) {
          ids.push(i.id)
        });

        let res = await LogExportService.api.exportBulkCsv('spt15/lt', ids);

        let filename = res.headers.filename;

        download(res.data, filename);
      }
    }
    else {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export CSV',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: (
          <div>
            <this.ig.Field
              label='Nama'
              name='nama__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='NPWP'
              name='npwp__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Email'
              name='email__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />

          </div>
        )
      }));

      if (dialog === false)
        return

      if (dialog === undefined)
        dialog = {}

      if (dialog) {
        let param = ParamService.convert(dialog)

        let res = await LogExportService.api.exportByParamCsv('spt15/lt', param);

        let filename = res.headers.filename;

        download(res.data, filename);
      }
    }
  }

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='NPWP'
          name='npwp__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Nama'
          name='nama__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Alamat'
          name='alamat__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='NPWP Organisasi'
          name='organizationNpwp__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Nama Organisasi'
          name='organizationName__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
      </this.SearchContainer>
    )
  }
}
