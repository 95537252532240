import React, { Component } from 'react';
import { connect } from 'react-redux';
import {TabsContainer, Tabs, Tab} from 'react-md';
import { Field } from 'redux-form';

import SPT23SignerService from '../../pph/23_26/Signer/SPT23Signer.service';
import ListView from '../../../../components/entity/listView';
import { Textfield } from '../../../../components/form'
import navService from "react-mpk/services/navService";
import ParamService from "../../../../services/param.service";

export class Signer23AllView extends ListView {
  service=SPT23SignerService;

  editDialog = false;

  fetchOption() {
    let companyId = this.props.match.params.companyId

    return {
      path: 'spt23/signer/all/'+companyId+'/page'
    }
  }

  columns=[
    {label: "word.npwp", value: "organization.npwp", isDefaultSort:true, isSortable:true, show:true, isSearchable:true, isDefaultSearchColumn:true},
    {label: "word.name", value: "organization.name", isSortable:true, show:true, isSearchable:true},
    {label: "word.aliasName", value: "organization.aliasName", isSortable:true, show:true, isSearchable:true},
    {label: "entities.SPT21Signer.namaPenandaTanganBp",  value: "namaPenandaTanganBp", isSortable: true, show:true, isSearchable:true, className: "mpk-font-size-S"},
    {label: "entities.SPT21Signer.jabatanPenandaTanganBp",  value: "jabatanPenandaTanganBp", isSortable: true, show:true, isSearchable:true},
    {label: "entities.SPT21Signer.npwpPenandaTanganSptInduk",  value: "npwpPenandaTanganSpt", isSortable: true, show:true, isSearchable:true},
    {label: "entities.SPT21Signer.namaPenandaTanganSptInduk",  value: "namaPenandaTanganSpt", isSortable: true, show:true, isSearchable:true},
    {label: "entities.SPT21Signer.start",  value: "start", isSortable: true, show:true, isSearchable:true},
    {label: "entities.SPT21Signer.end",  value: "end", isSortable: true, show:true, isSearchable:true},
    {label: "entities.SPT21Signer.imagePenandaTanganBp",  value: "imagePenandaTanganBp", isSortable: true, show:true, isSearchable:true},
    {label: "entities.SPT21Signer.imagePenandaTanganInduk",  value: "imagePenandaTanganInduk", isSortable: true, show:true, isSearchable:true},
  ]

  barActions() {
    return [
      {
        label:'word.delete',
        iconClassName:'mdi mdi-delete',
        onClick:() => this.handleDelete()
      },
      {
        label:'word.refresh',
        iconClassName:'mdi mdi-refresh',
        onClick:() => {

          window.location.hash = window.location.hash.split('?')[0]
          this.fetchData()
        }
      },
    ]
  }

  editItem = (item) => {
    let organizationId = item.organization.id

    navService.redirectTo(`/organization/${organizationId}`)
  }

  async handleDelete() {
    let selected = this.props.table.selected

    if (selected.length > 0) {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Hapus Signer',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: 'Apakah anda yakin akan menghapus data yang diceklis?'
      }));

      if(dialog) {
        var ids = [];
        selected.map(function (i) {
          ids.push(i.id)
        })

        var res = await this.service.api.deleteBulk(ids);

        this.fetchData()
      }
    }
    else {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Hapus Signer',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: (
          <div>
            <this.ig.Field
              label='NPWP Organisasi'
              name='organizationNpwp__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Nama Organisasi'
              name='organizationName__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Nama Alias Organisasi'
              name='organizationAliasName__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Nama TTD BP'
              name='namaPenandaTanganBp__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Jabatan TTD BP'
              name='jabatanPenandaTanganBp__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='NPWP TTD Induk'
              name='npwpPenandaTanganSpt__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Nama TTD Induk'
              name='namaPenandaTanganSpt__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Berlaku Mulai'
              name='start__equals'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Berlaku Sampai'
              name='end__equals'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
          </div>
        )
      }));

      if (dialog === false)
        return

      if (dialog === undefined)
        dialog = {}

      if (dialog) {
        let companyId = this.props.match.params.companyId
        let param = ParamService.convert(dialog)

        let res = await this.service.api.deleteByParam(companyId, param);

        this.fetchData()
      }
    }
  }

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='NPWP Organisasi'
          name='organizationNpwp__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Nama Organisasi'
          name='organizationName__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Nama Alias Organisasi'
          name='organizationAliasName__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Nama TTD BP'
          name='namaPenandaTanganBp__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Jabatan TTD BP'
          name='jabatanPenandaTanganBp__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='NPWP TTD Induk'
          name='npwpPenandaTanganSpt__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Nama TTD Induk'
          name='namaPenandaTanganSpt__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Berlaku Mulai'
          name='start__equals'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Berlaku Sampai'
          name='end__equals'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
      </this.SearchContainer>
    )
  }

  view1() {
    return (
      <this.viewContainer>
        {this.commandBar()}
        <div className="flex mpk-layout mpk-padding-N all">
          {this.tableView()}
          {this.tableSetting()}

          <this.FormDialog
            location={this.props.location}
            height={400}
            visible={this.state.showForm}
            add={this.props.tableActions.addDataItem}
            formData={this.state.formData}
            onSuccess={()=> this.fetchData()}
            onClose={() => this.setState({showForm:false})}
          />

          {this.dialogConfirm()}
        </div>
      </this.viewContainer>
    )
  }

  deleteItem = async (item, callback) => {
    try {
      await this.service.api.delete(item.id);
      callback()
      await this.fetchData()
    } catch(e) {
      callback(e, e)
    }
  }

}

export default connect(SPT23SignerService.stateConnectSetting(), SPT23SignerService.actionConnectSetting())(Signer23AllView)
