import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  Tab,
  Tabs,
  SelectionControlGroup,
  Checkbox
} from 'react-md';

import FormView from '../../../../../../../components/entity/form.view';
import PbkEbupotService from './pbk.service';
import {validation, Textfield, Searchfield, Switch, Datepicker, TextfieldMask, convert} from '../../../../../../../components/form';
import { SearchField } from 'react-mpk';
import FormViewSpt from '../../../../../../../components/entity/form.view.spt';
import ColumnService from '../../../../../../../services/column.service';

import Master23KapService from './../ssp/masterKap.service'
import Master23KjsService from './../ssp/masterKjs.service'
// import SPT2326Service from '../SPT/23_26.spt.service';


@reduxForm({form: 'PbkEbupotForm', destroyOnUnmount: true, initialValues: {}})
@connect((state) => ({
  //access: state.entity.access.api.find,
  //organization: state.auth.currentOrganization,
  spt: state.auth.currentSPT,
  formData: getFormValues('PbkEbupotForm')(state)
}))
export default class PbkEbupotForm extends FormViewSpt {
  service=PbkEbupotService
  viewType =2;

  constructor(props){
    super(props)
    this.state = {
      ...this.state,
      kjs: [],
      formData: {ssp23s:[], ssp26:[]}
    }
  }

  async initData() {
    if(this.props.match.params.id == 'new') {
      this.props.initialize(this.initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id);
      res.data.kap = res.data.kjs.kap
      this.props.initialize(res.data);
    }
  }

  async handleSave(value) {
    try {
      this.setState({onProgress: true})
      if(value.id) {
        await this.service.api.update(value)
      } else {
        value.spt = { id: this.props.spt.data.id }
        await this.service.api.save(value)
      }
      this.setState({onProgress: false})

      var pathArray = this.props.location.pathname.split('/');
      var path = '';
      pathArray.forEach((d, i)=> {
        if(pathArray.length-1 != i) {
          path = path+d;
          if(i != pathArray.length-2) path = path+'/'
        }
      })

      this.props.history.push(path)
    } catch(e) {
      var msg = e.message;
      if(e.response) msg = e.response.data.message;
      this.setState({
        isError: true,
        onProgress: false,
        errorMessage: msg
      })
    }
  }

  async findKjs(evt, value){
    var kapId = value.id;
    var kjs = await Master23KjsService.api.lookUp(kapId);
    this.setState({ kjs: kjs })
  }

  formView() {
    return (
      <div>

        <Card>

          <div className='md-grid'>
            <div className="md-cell md-cell--6">
              <Field
                label='Kode Jenis Pajak'
                name='kap'
                component={Searchfield}
                apiParam={{ pasal: '23' }}
                service={Master23KapService}
                validate={validation.required}
                onChange={this.findKjs.bind(this)}
                viewField="code"
                valueField="parent"

                itemTemplate={function(d) {
                  return {
                    primaryText: `${d.code}`,
                    onClick: () => {
                      if(!this.props.disabled){
                        this.handleItemClick(d); this.hide()
                      }
                    }
                  }
                }}
              />
              <Field
                label='Kode Jenis Setoran'
                name='kjs'
                component={Searchfield}
                apiParam={{ pasal: '23' }}
                validate={validation.required}
                rerender={true}
                options={this.state.kjs}
                viewField="code"
                valueField="parent"

                itemTemplate={function(d) {
                  return {
                    primaryText: `${d.code} - ${d.name}`,
                    onClick: () => {
                      if(!this.props.disabled){
                        this.handleItemClick(d); this.hide()
                      }
                    }
                  }
                }}
              />
              <Field
                label='Tanggal'
                name='date'
                component={Datepicker}
                validate={validation.required}
              />
            </div>

            <div className="md-cell md-cell--6">
                <Field
                    label='Nomor Buktipemindahbukuan'
                    name='noPbk'
                    component={Textfield}
                    validate={validation.required}
                />
                <Field
                    label='Jumlah Disetor'
                    name='total'
                    component={TextfieldMask}
                    money={true}
                    normalize={convert.money}
                    validate={validation.required}
                />
            </div>
          </div>
        </Card>
        <br/>
      </div>
    )
  }

}
