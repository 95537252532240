import ApiService from '../../../../../services/api.service';
import http from "../../../../../services/http.service";

class SSP15Service extends ApiService {
  name= 'SSP15';
  path= 'spt15/ssp';
  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api= {
    cetak: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/spt15/ssp/report/${sptId}`, {
        responseType: 'blob'
      })

      return res;
    },
    downloadCsv: async (sptId)=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/csv/${sptId}`, {
        responseType: 'blob'
      })

      return res;
    },
    deleteBulk: async (sptId, ids)=> {
      let res = await http.post(`${this.apiLocation}/${this.path}/${sptId}/delete`, ids);

      return res;
    },
  }
}


export default new SSP15Service();
