import ApiService from '../../../../../services/api.service';

class FileLapor15Service extends ApiService {
  name= 'FileLapor15';
  path= 'spt15';
  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api={
    getSummary: async(sptId)=> {
      return await this.http.get(`${this.apiLocation}/${this.path}/info/${sptId}/lapor`)
    },
    cetakTest: async (sptId) => {
      return await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/lapor`)
    },
    cetak: async (sptId) => {
      return await this.http.get(`${this.apiLocation}/download/${sptId}`, {
        responseType: 'blob'
      })
    },
  }
}

export default new FileLapor15Service();
