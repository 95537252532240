import ApiService from '../../../../../services/api.service';

class AllBp21Induk extends ApiService {
  name= 'ALL_BP_21_INDUK';
  path= 'spt21/induk/organization';
  constructor() {
    super();
    this.init()
  }
}

export default new AllBp21Induk();
