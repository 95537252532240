import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  Tab,
  Tabs,
  SelectionControlGroup,
  Checkbox,
  Button,
  FontIcon
} from 'react-md';

import _ from 'lodash';
import Bluebird, { Promise } from 'bluebird';

import FormView from '../../../../components/entity/form.view';
import AccessControlService from './AccessControl.service';
import AccessService from './../Access/Access.service';
import {validation, Textfield, Searchfield, Switch} from '../../../../components/form';
import { Commandbar, Table, TableSetting, Empty, DialogConfirm } from 'react-mpk';
import { callbackify } from 'util';

@reduxForm({form: 'AccessControl.Form', destroyOnUnmount: false})
@connect((state) => ({
  access: state.entity.access.api.find,
  organization: state.auth.currentOrganization,
  AccessControl: getFormValues('AccessControl.Form')(state)
}))
export default class AccessControlForm extends FormView {
  service=AccessControlService
  viewType =2;

  constructor(props){
    super(props)
    this.state = {
      ...this.state,
      showDialogConfirmDeleteSelected: false,
      deleteId: null
    }
  }

  async initData() {
    let accessQ = await this.service.api.find({size: 1000});
    let access = accessQ.data;
    let permissions = await this.service.api.permissions();
    var accessTemplate = _.cloneDeep(access);
    var permissionsTemplate = _.cloneDeep(permissions);
    permissionsTemplate.map(function (i){
      i.checked = false
    })
    accessTemplate.map(function (i){
      var permit = i.permissions
      i.all = false
      if(permit.length == permissionsTemplate.length){
        i.all = true
      }
      permit.map(function (i2){
        i2.checked = true
      })
      var cc = _.unionBy(permit, permissionsTemplate, "id")
      cc = _.sortBy(cc, function(o) { return o.id; })
      i.permissions = _.cloneDeep(cc)
    })
    this.props.initialize({
      access: accessTemplate,
      permissions: permissions
    })
  }

  handleCheckAll(accessChange){
    let accessX = _.cloneDeep(this.props.AccessControl.access)
    accessX[accessChange].all = !accessX[accessChange].all
    accessX[accessChange].permissions.map(function (i){
      i.checked = accessX[accessChange].all
    })
    this.props.change("access", accessX)
  }

  handleCheck(accessChange, permissionChange){
    let accessX = _.cloneDeep(this.props.AccessControl.access)
    accessX[accessChange].permissions[permissionChange].checked = !accessX[accessChange].permissions[permissionChange].checked
    this.props.change("access", accessX)
  }

  confirmDelete(access){
    this.setState({
      showDialogConfirmDeleteSelected: true,
      deleteId: access.id
    })
  }

  handleDelete = async () => {
    try {
      await AccessService.api.delete(this.state.deleteId)
      this.setState({deleteId: null})
      await this.initData()
      callback()
    } catch (e){
      callback(e, e)
    }
  }

  async handleSave(value) {
    var formData = _.cloneDeep(value.access)
    formData.map(function (i){
      var x = i.permissions.filter(function (c){
        return c.checked == true
      })
      i.permissions = x
    })
    try {
      this.setState({onProgress: true})
      var _this = this
      var exec = Promise.all(
        formData.map(function (value){
          return new Promise(function (resolve, reject){
            if(value.id) {
              _this.service.api.update(value)
              resolve(true)
            } else {
              _this.service.api.save(value)
              resolve(true)
            }
          })
        })
      )
      exec.then(function (){
        _this.initData()
        _this.setState({onProgress: false})
      })
    } catch(e) {
      var msg = e.message;
      if(e.response) msg = e.response.data.message;
      this.setState({
        isError: true,
        onProgress: false,
        errorMessage: msg
      })
    }
  }

  _barActions = [
    {
      label:'word.create',
      iconClassName:'mdi mdi-plus',
      onClick:() => this.addItem()
    }
  ]

  addItem = () => {
    this.props.history.push('/access-control/new');
  }

  formView() {
    let _this = this
    let access = []
    let permissions = []
    if(this.props.AccessControl){
      permissions = this.props.AccessControl.permissions
      access = this.props.AccessControl.access
    }
    return (
      <Card>
      <div className="mpk-layout flex column fill">
      <DialogConfirm
        title={'word.deletes'}
        message={'sentence.custom.deleteSelectedItems'}
        visible={this.state.showDialogConfirmDeleteSelected}
        onSubmit={(callback) => {
          this.handleDelete()
          callback();
        }}
        onCancel={() => this.setState({showDialogConfirmDeleteSelected:false})}
        translate={true}
      />
      <DataTable plain>
        <TableHeader>
          <TableRow>
            <TableColumn style={{textAlign: "center"}}>#</TableColumn>
            <TableColumn>ACCESS NAME</TableColumn>
            <TableColumn>ALL</TableColumn>
            {permissions.map(function (i, index){
              return <TableColumn key={index}>{i.name.toUpperCase()}</TableColumn>
            })}
          </TableRow>
        </TableHeader>
        <TableBody>
          {access.map(function (i, index){
            let permissions = i.permissions
            return (
              <TableRow key={index}>
                <TableColumn><Button secondary onClick={_this.confirmDelete.bind(_this, i)}><FontIcon>delete</FontIcon></Button></TableColumn>
                <TableColumn>{i.name}</TableColumn>
                <TableColumn><Checkbox id={i.name+index} name={i.name+index} checked={i.all} onChange={_this.handleCheckAll.bind(_this, index)} /></TableColumn>
                {permissions.map(function (i2, index2){
                  return <TableColumn key={index2+index}><Checkbox id={i.name+"_"+index2} name={i.name+"_"+index2} checked={i2.checked} onChange={_this.handleCheck.bind(_this, index, index2)} /></TableColumn>
                })}
              </TableRow>
            )
          })}
        </TableBody>
      </DataTable>
      </div>
      </Card>
    )
  }
}
