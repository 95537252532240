import ApiService from "../../../../../../services/api.service";

class Master26TarifService extends ApiService {
  name = 'Master26Tarif';
  path = 'spt23/tarif-26';

  constructor() {
    super();
    this.init()
  }

  api = {
    byCompany: async (data) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/company`, {
        params: data
      });
      return res.data;
    },
    getMaster: async () => {
      var res = await this.http.get(`${this.apiLocation}/spt23/master/tarif-26`);
      return res.data;
    }
  }
}


export default new Master26TarifService();
