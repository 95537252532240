import React, { Component } from 'react';

import _ from 'lodash';
import Bluebird from 'bluebird';
import { Avatar, Button, Card, Grid, Cell, LinearProgress, FontIcon, TabsContainer, Tab, Tabs, TableBody, TableHeader, TableColumn, TableRow, DataTable, Checkbox } from 'react-md';
import { Field, reduxForm , propTypes, formValueSelector, getFormValues } from 'redux-form';
import {validation, Textfield,Searchfield,Checkbox as Check} from '../../../../../components/form';
import { connect } from 'react-redux';

import DialogView from '../../../../../components/entity/dialog.view'
import UserService from '../../User/User.service';
import OrganizationUserService from './Organization.user.service';
import OrganizationService from '../Organization.service';
import AccessService from '../../Access/Access.service';
import OrganizationUserAccessService from '../Access/Organization.user.access.service';
import PermissionsService from '../../Permissions/Permissions.service';
import TemplateRoleService from '../../TemplateRole/TemplateRole.service';

@reduxForm({form: 'organization-user-dialog', destroyOnUnmount: false})
@connect((state) => ({
  access          : state.entity.access.api.find,
  organizationForm: getFormValues('organization_form')(state)
}))
export default class UserOrganizationDialogView extends DialogView {
  translate = false;
  service   = OrganizationUserService
  size      = 'big'
  formStyle = {
    padding: 0,
    //overflow: 'hidden'
  }

  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      userOrg: {
        accessMap  : {},
        permissions: []
      },
      defAccess: {},
      templateRoles: [],
      selectedTemplateRoleIndexes: [],
    }
  }

  titleHeader() {
    if(this.props.formData.id) {
      return 'Edit User'
    } else {
      return 'Add User'
    }
  }

  transform(val) {
    return {
      ...val,
      organization: {
        id: this.props.organizationId
      },
      company: {
        id: this.props.companyId
      }
    }
  }

  async componentDidMount() {
    if(this.props.access.length == 0) await this.initAccess()
    this.initData(this.props);
    var accessService = await AccessService.api.find({size: 100}, this.props.dispatch);
  }

  async initData(props) {
    let permissions  = await PermissionsService.api.find();
    let _permissions = _.cloneDeep(permissions.data)
    _permissions.map(function (i){ i.value = false })
    if(props.formData.id) {
      this.props.initialize(props.formData);
      var userOrg   = _.cloneDeep(props.formData);
      var accessMap = {}

      let access = [];
          access = _.sortBy(this.props.access, (d)=> d.name);

      access = _.sortBy(access, (d)=> {
        let pasal                            = '0';
        if (d.name.search('21') >= 0) pasal  = '1';
        if (d.name.search('23') >= 0) pasal  = '2';
        if (d.name.search('26') >= 0) pasal  = '2';
        if (d.name.search('4A2') >= 0) pasal = '3';
        if (d.name.search('22') >= 0) pasal  = '4';
        if (d.name.search('15') >= 0) pasal  = '5';

        return pasal;
      })

      access.forEach(e => {
        var defaultPermission = _.cloneDeep(_permissions)

        var view = e.permissions.reduce((obj, e)=> {
          obj[e.id] = true;
          return obj;
        }, {});

        accessMap[e.id] = {
          id         : e.id,
          name       : e.name,
          all        : false,
          permissions: defaultPermission,
          view
        }
      })

      this.setState({defAccess: _.cloneDeep(accessMap)})

      userOrg.accessMap = {...accessMap};

      let res = await OrganizationUserAccessService.api.getAccess(userOrg.id);

      res.data.forEach((e) => {
        e.userAccessPermission.forEach(f => {
          var permissionId      = f.permission.id;
          var accessPermissions = userOrg.accessMap[e.access.id].permissions;
          accessPermissions.map(function (c, cIndex){
            if(c.id == permissionId){
              c.value = true;
            }
          })
        })
      })

      userOrg.permissions = permissions.data

      this.setState({
        userOrg
      })

    } else {
      this.props.initialize(this.initialData);
    }

    let resTemplateRoles = await TemplateRoleService.api.find({ companyId: this.props.organizationForm.company.id })

    this.setState({ templateRoles: resTemplateRoles.data })
  }

  async initAccess() {
    let res = await AccessService.api.find({}, this.props.dispatch);
  }

  async handleSave(callback, value) {
    var newVal = this.transform(value);
    try {
      var user;
      if(value.id) {
        user = await this.handleUpdate();
      } else {
        user = await this.handleCreate(newVal)
      }

      // await Bluebird.map(this.props.access, async (d) => {
      //   let o = await OrganizationUserAccessService.api.save({
      //     access: d,
      //     organizationUserId: user.id
      //   })
      // })

      callback()
      this.props.onSuccess()
      this.props.onClose()
      this.props.reset()
    } catch(e) {
      var msg             = e.message;
      if (e.response) msg = e.response.data.message
      callback(true, msg)
    }
  }

  async handleCreate(value) {
    var user = await this.service.api.save(value);

    let templateRoles = this.state.templateRoles
    let selectedTemplateRoleIndexes = this.state.selectedTemplateRoleIndexes
    let selectedTemplateRoles = []

    selectedTemplateRoleIndexes.forEach(selectedTemplateRoleIndex => {
      selectedTemplateRoles.push(templateRoles[selectedTemplateRoleIndex])
    })

    /*if(value.role) {
      await Bluebird.mapSeries(value.role.accesses, async (d)=> {
        var userAccessPermission = d.permissions.map((dd)=> {
          return {permission: dd.permission}
        });

        let res = await OrganizationUserAccessService.api.save({
          access            : d.access,
          organizationUserId: user.data.id,
          userAccessPermission
        })
      })
    }*/

    for (let i = 0; i < selectedTemplateRoles.length; i++) {
      let selectedTemplateRole = selectedTemplateRoles[i]

      await Bluebird.mapSeries(selectedTemplateRole.accesses, async (d)=> {
        var userAccessPermission = d.permissions.map((dd)=> {
          return {permission: dd.permission}
        });

        let res = await OrganizationUserAccessService.api.save({
          access            : d.access,
          organizationUserId: user.data.id,
          userAccessPermission
        })
      })
    }

    this.setState({ selectedTemplateRoleIndexes: [] })
  }

  async handleUpdate() {
    var user   = this.state.userOrg;
    var access = Object.keys(user.accessMap).map((objectKey, index) => {
      return user.accessMap[objectKey];
    });

    var dataMapping = []
    access.forEach((e, i)=> {
      Object.keys(e.permissions).forEach((objectKey, index) => {
        var permission = e.permissions[objectKey];
        if(permission.value) {
          dataMapping.push({
            accessId: e.id,
            permissionId: permission.id
          })
        }
      })
    });

    let res = await OrganizationUserAccessService.api.updateBulk(user.id, dataMapping)



    // await Bluebird.mapSeries(access, async (e)=> {
    //   var userAccessPermission = [];
    //   Object.keys(e.permissions).forEach((objectKey, index) => {
    //     var permission = e.permissions[objectKey];
    //     if(permission.value) {
    //       userAccessPermission.push({
    //         permission: {
    //           id: permission.id
    //         }
    //       })
    //     }
    //   })

    //   var access = {...e}
    //   delete access.permissions;
    //   let res = await OrganizationUserAccessService.api.save({
    //     access            : access,
    //     organizationUserId: user.id,
    //     userAccessPermission
    //   })
    // })
  }

  handleCheckbox(i, accessId, permissionId) {
    this.setState((prev) => {
      var userOrg = {...prev.userOrg};

      userOrg.accessMap[accessId].permissions[permissionId].value = !userOrg.accessMap[accessId].permissions[permissionId].value;
      return {
        userOrg
      }
    })
  }

  handleCheckAll(accessId) {
    this.setState((prev) => {
      var userOrg = {...prev.userOrg};

      userOrg.accessMap[accessId].all = !userOrg.accessMap[accessId].all;
      userOrg.accessMap[accessId].permissions.forEach((d)=> {
        if(userOrg.accessMap[accessId].view[d.id]) {
          d.value = userOrg.accessMap[accessId].all;
        } else {
          d.value = false;
        }
      })

      return {
        userOrg
      }
    });
  }

  formView() {
    if(this.props.formData.id) {
      return this.editView()
    } else {
      return this.addView()
    }
  }

  changeSelectedTemplateRoleIndex(e, v, vp, f, indexValue) {
    let selectedTemplateRoleIndexes = this.state.selectedTemplateRoleIndexes

    let index = selectedTemplateRoleIndexes.indexOf(indexValue)

    // diceklis
    if (v) {
      // belum ada
      if (index === -1) {
        selectedTemplateRoleIndexes.push(indexValue)
      }
    }
    // tidak diceklis
    else {
      // sudah ada
      if (index !== -1) {
        selectedTemplateRoleIndexes.splice(index, 1)
      }
    }

    this.setState({ selectedTemplateRoleIndexes: selectedTemplateRoleIndexes })
  }

  addView() {
    let templateRoles = []

    if (this.state.templateRoles) {
      templateRoles = this.state.templateRoles
    }

    return (
      <div className="md-grid">
        <Field
          label        = 'User'
          name         = 'user'
          id           = "organization-user"
          className    = "md-cell md-cell--12"
          apiPath      = "searchByCompany"
          remoteSearch = {true}
          component    = {Searchfield}
          service      = {UserService}
          viewField    = 'login'
          searchField  = {['login']}
          valueField   = 'parent'
          apiParam       = {{
            companyId: this.props.organizationForm.company.id,
          }}
          itemTemplate={function(d) {
            var suff = '0';
            if(d.firstName) {
              suff = d.firstName[0].toUpperCase();
            } else {
              d.firstName = 'None';
              d.lastName  = '';
            }
            return {
              primaryText  : d.firstName+" "+d.lastName,
              secondaryText: d.login,
              leftAvatar   : <Avatar suffix="deep-purple">{suff}</Avatar>,
              onClick      : () => this.handleItemClick(d)
            }
          }}
          validate = {validation.required}
        />

        {/*<Field
          label      = 'Template Role'
          name       = 'role'
          id         = "organization-role"
          className  = "md-cell md-cell--12"
          valueField = "parent"
          apiPath    = "page"
          params     = {{
            companyId: this.props.organizationForm.company.id
          }}
          component = {Searchfield}
          service   = {TemplateRoleService}
        />*/}

        {
          templateRoles.map((templateRole, index) => {
            return <Field
              label={ `${templateRole.name}` }
              name={ `templateRoleIndexs${templateRole.id}` }
              className="md-cell md-cell--4"
              component={Check}
              onChange={(e, v, vp, f) => this.changeSelectedTemplateRoleIndex(e, v, vp, f, index)}
            />
          })
        }
      </div>
    )
  }

  editView() {
    var _this = this
    return (
      <div className="mpk-layout flex column fill" style={{overflow: 'hidden'}}>
        <div className='md-grid' style={{width: '100%'}}>

          <Field
              label      = 'Template Role'
              name       = 'role'
              id         = "organization-role"
              className  = "md-cell md-cell--6 md-cell--6-offset"
              valueField = "parent"
              apiPath    = "page"
              apiParam     = {{
                companyId: this.props.organizationForm.company.id
              }}
              component = {Searchfield}
              onChange  = {(v)=> {
                var accessMap = _.cloneDeep(this.state.defAccess)
                if(v.accesses) {
                  v.accesses.forEach((d, i) => {
                    d.permissions.forEach((dd) => {
                      if(accessMap[d.access.id]) {
                        var perm = _.find(accessMap[d.access.id].permissions, {id: dd.permission.id});
                        if(perm) {
                          perm.value = true;
                        }
                      }
                    })
                  })
                }

                this.setState({userOrg: {
                  ...this.state.userOrg,
                  accessMap
                }});

              }}
              service = {TemplateRoleService}
            />
          </div>

      <DataTable
        plain
        fixedHeader
        fixedFooter
        fixedHeight = {380}


      >
        <TableHeader>
          <TableRow>
            <TableColumn>ACCESS NAME</TableColumn>
            <TableColumn style={{background: '#ddd'}}>Check All</TableColumn>
            {this.state.userOrg.permissions.map(function (i, index){
              return <TableColumn key={index} style={{textAlign: 'center'}}>{i.name.toUpperCase()}</TableColumn>
            })}
          </TableRow>
        </TableHeader>
        <TableBody>
          {Object.keys(this.state.userOrg.accessMap).map((objectKey, index) => {
            var d           = this.state.userOrg;
            var access      = this.state.userOrg.accessMap[objectKey];
            var i           = 1;
            var permissions = access.permissions
            return (
            <TableRow key={access.id}>
              <TableColumn>{access.name}</TableColumn>
              <TableColumn style={{background: '#ddd'}}>
                <Checkbox  id={access.name+'_checkall'} checked={access.all} onChange={()=> {this.handleCheckAll(access.id)}} />

              </TableColumn>
              {permissions.map(function (i, iIndex){
                if(access.view[i.id]) {
                  return  <TableColumn><Checkbox disabled={!access.view[i.id]}  id={access.name+i.id} checked={i.value} onChange={()=> { _this.handleCheckbox(i.id, objectKey, iIndex)}} /></TableColumn>
                }

                return <TableColumn></TableColumn>;
              })}
            </TableRow>
            )
          })}
        </TableBody>
      </DataTable>
      </div>
    )
  }
}
