import ApiService from '../../../../services/api.service';

class MasterLawanTransaksi4a2Service extends ApiService {
  name= 'masterLawanTransaksi4a2';
  path= 'spt4a2/lt/4a2';

  constructor() {
    super();
    this.init()
  }

  api = {
    findOrCreate: async (data)=> {
      var res = await this.http.post(this.apiLocation+'/'+this.path+'/findOrCreate', data)
      return res.data;
    }
  }
}


export default new MasterLawanTransaksi4a2Service();
