import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Field, reduxForm } from 'redux-form';
import { Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab } from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Switch as Switch2} from '../../../../../components/form';

import ListView from '../../../../../components/entity/listView';
import ColumnService from '../../../../../services/column.service';
import ImportService from './../Import/Import.service';
import axios from 'axios';
import izitoast from 'izitoast';
import FormDialog from './ImportSigner21.dialog';
import ImportSigner21Service from './ImportSigner21.service';
import download from "downloadjs";
import ListViewImport from '../../../../../components/entity/ListViewImport';



@reduxForm({form: 'IMPORT_Signer21_Form', destroyOnUnmount: true})
@connect((state)=> ({
  ...ImportSigner21Service.stateConnectSetting()(state),
  user: state.auth.user,
  company: state.auth.currentCompany
}), ImportSigner21Service.actionConnectSetting())
export default class ImportSigner21View extends ListViewImport {
  service=ImportSigner21Service
  FormDialog=FormDialog

  category='SIGNER_21'
  importPath='/api/import/company/'

  importPathFunc() {
    return `/api/import/company/${this.props.company.id}`
  }

  showAutomatisTab=true
  showReplace=true
  // viewType=2

  beforeFetch(params) {
    params["category.equals"] = "SIGNER_21"
  }

  async handleDownloadTemplate() {
    var report = await ImportSigner21Service.api.downloadTemplate()
    download(report.data, 'Signer21.csv');
  }
}
