import ApiService from '../../../../../services/api.service';

class SettingEfilingService extends ApiService {
  name= 'SettingEfiling';
  path= 'setting/efiling/page';

  constructor() {
    super();
    this.init();
    this.initApi()
  }

  api = {
    getByCompany: async (companyId)=> {
      return await this.http.get(`${this.apiLocation}/setting/efiling/company/`+companyId)
    },
    updateByCompany: async (formData)=> {
      return await this.http.put(`${this.apiLocation}/setting/efiling`, formData)
    }
  }
}


export default new SettingEfilingService();
