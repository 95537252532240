import accounting from 'accounting-js';

export default {
  date: {
    MM(M) {
      return ('0' + M).slice(-2)
    },
    YY(YYYY) {
      return YYYY.toString().substr(2,2);
    }
  },
  number: {
    get(d) {
      if(d) {
        return parseFloat(d)
      } else {
        return 0;
      }
    },
    format(v, opt = {}) {
      return accounting.formatNumber(v, opt);
    }
  }
}
