import ApiService from '../../../../services/api.service';

class MasterImportCategoryService extends ApiService {
  name= 'masterImportCategory';
  path= 'master/import-categories';
  constructor() {
    super();
    this.init()
  }

  api = {
    findAllByPasalId: async (id) => {
      return await this.http.get(`${this.apiLocation}/${this.path}/byPasal/${id}`)
    }
  }
}

export default new MasterImportCategoryService();
