import React, { Component } from 'react';
import { Grid, Cell } from 'react-md';
import { connect } from 'react-redux';
import { Field, reduxForm , propTypes, formValueSelector } from 'redux-form';
import {validation, Textfield, Searchfield, TextfieldMask, Switch as SwitchField} from '../../../../components/form';
import DialogView from '../../../../components/entity/dialog.view';
import OrganizationService from './Organization.service';
import CompanyService from '../Company/Company.service';

@connect(state => ({
  isLoginIn: state.auth.isLoginIn,
  company: state.auth.currentCompany,
  user: state.auth.user
}))
@reduxForm({form: 'organization-dialog', destroyOnUnmount: false})
export default class OrganizationDialogView extends DialogView {
  service=OrganizationService;
  initialData = {
    // activationKey: Math.random().toString(36).substring(7),
    active: false,
    kpp: {
      id: 1
    }
  }

  initData(props) {
    if(props.formData.id) {
      this.props.initialize(props.formData);
    } else {
      this.initialData.company = this.props.company;
      this.props.initialize(this.initialData);
    }
  }

  formView() {
    return (
      <Grid style={{padding:'none'}}>
        {!(this.props.company && this.props.company.id) &&
          <Field
            label='Company'
            name='company'
            className="md-cell md-cell--12"
            apiPath="page"
            viewField="nama"
            valueField="parent"
            component={Searchfield}
            service={CompanyService}
            validate={validation.required}
          />
        }
        <Field
          label='NPWP'
          name='npwp'
          id="organization-npwp"
          className="md-cell md-cell--6"
          component={TextfieldMask}
          maskFormat="##.###.###.#-###-###"
          validate={validation.required}
        />
        <Field
          label='Name'
          name='name'
          id="organization-npwp"
          className="md-cell md-cell--6"
          component={Textfield}
          validate={validation.required}
        />

        <Field
          label='Alias Name'
          name='aliasName'
          id="organization-alias-name"
          className="md-cell md-cell--6"
          component={Textfield}
        />
        <Field
          label='City'
          name='city'
          id="organization-npwp"
          className="md-cell md-cell--6"
          component={Textfield}
          validate={validation.required}
        />

        <Field
          label='Postal Code'
          name='postalCode'
          id="organization-npwp"
          className="md-cell md-cell--6"
          component={Textfield}
        />
        <Field
          label='Email'
          name='email'
          id="organization-npwp"
          className="md-cell md-cell--6"
          component={Textfield}
        />

        <Field
          label='Phone'
          name='phone'
          id="organization-npwp"
          className="md-cell md-cell--6"
          component={Textfield}
        />
        <Field
          label='Pemungut'
          name='isPemungut'
          className="md-cell md-cell--6"
          // validate={validation.required}
          component={SwitchField}
        />

        <Field
          label='Address'
          name='address'
          rows={4}
          id="organization-npwp"
          className="md-cell md-cell--12"
          component={Textfield}
          validate={validation.required}
        />

        <Field
          label='Kode Aktivasi'
          name='activationKey'
          rows={4}
          className="md-cell md-cell--12"
          component={Textfield}
          validate={validation.required}
        />
      </Grid>
    )
  }
}
