import ApiService from '../../../../services/api.service';

class DataInquiryService extends ApiService {
  name = 'DataInquiry';
  path = 'ebilling/inquiry';

  api = {
    deleteSelected: async (ids) => {
      var res = await this.http.post(this.apiLocation+'/ebilling/inquiry/delete', ids)

      return res.data;
    },

    cetak: async (id) => {
      var res = await this.http.get(`${this.apiLocation}/ebilling/inquiry/report/${id}`, {
        responseType: 'blob'
      })

      return res;
    },

    cetakDaftar: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/ebilling/inquiry/daftar/report/${sptId}`, {
        responseType: 'blob'
      })

      return res;
    },

    csvBySelected: async (ids) => {
      var res = await this.http.post(this.apiLocation+'/ebilling/inquiry/csv-checked', ids)

      return res.data;
    },

    csvByFilter: async (data) => {
      var res = await this.http.get(this.apiLocation+'/ebilling/inquiry/csv-filter', {params: data})

      return res.data;
    },
  }

  constructor() {
    super()
    this.init()
    this.initApi()
  }
}

export default new DataInquiryService();
