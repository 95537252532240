import React, { Component } from 'react'; import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {TabsContainer, Tabs, Tab} from 'react-md';
import { Field } from 'redux-form';

import Bupot23HistoryService from './Bupot23History.service';
import ListView from '../../../../../components/entity/ListViewEbupot';
import AuthService from '../../../../../services/auth.service'
import ListViewEbupot from '../../../../../components/entity/ListViewEbupot';

@connect((state)=> ({
  ...Bupot23HistoryService.stateConnectSetting()(state)
}), Bupot23HistoryService.actionConnectSetting())
export default class Bupot23HistoryView extends ListViewEbupot {
  service=Bupot23HistoryService;

  rootPath = this.props.match.url
  editDialog = false;
  addDialog = false;

  columns = [
    {label: "word.id", searchField: "id", value: "entity.id", isDefaultSort:false, isSortable:true, show:false, isSearchable:true},
    {label: "word.revisionNumber", searchField: "metadata.revisionNumber", value: "metadata.revisionNumber", type: "number", isDefaultSort:true, isSortable:true, show:true, isSearchable:true},
    {label: "word.noBp", searchField: "no", value: "entity.no", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.rev", searchField: "rev", value: "entity.rev", type: 'number', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {
      searchField: "status",
      label: "word.status",
      type: 'func',
      value: (d)=> {
        var style = {
          background: 'grey'
        }
        switch (d.entity.status) {
          case 'CREATED':
            style.background = 'grey'
            break;
          case 'PROCESS':
            style.background = 'orange'
            break;
          case 'FAILED':
            style.background = 'orangered'
            break;
          case 'FINISH':
            style.background = 'limegreen'
            break;
          case 'COMPLETED':
            style.background = 'dodgerblue'
            break;
          case 'DELETED':
            style.background = 'darkred'
            break;
          case 'UPLOADING':
            style.background = 'yellow'
            break;
          default:
            break;
        }
        return <Chip label={d.entity.status} style={style} />
      },
      className: "mpk-font-size-S", show:true, isSearchable:true},
    {label: "word.report", searchField: "report", value: "entity.report", type: 'boolean', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.npwpProfiles", searchField: "spt.organization.npwp", value: "entity.spt.organization.npwp", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.masa", searchField: "spt.masa", value: "entity.spt.masa", type: 'number', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.tahun", searchField: "spt.tahun", value: (d)=> { return d.entity.spt.tahun + "" }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.pembetulan", searchField: "spt.pembetulan", value: "entity.spt.pembetulan", type: 'number', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.message", searchField: "message", value: "entity.message", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.npwp", searchField: "npwp", value: "entity.npwp", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.nik", searchField: "nik", value: "entity.nik", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.nama", searchField: "nama", value: "entity.nama", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.email", searchField: "email", value: "entity.email", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.code", searchField: "code", type:'func', value: (val)=> { return <div style={{width: 100}}>{val.entity.kode}</div> }, isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.bruto", searchField: "bruto", type: "number", value: "entity.bruto", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.pph", searchField: "pph", type: "number", value: "entity.pph", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.nSkbDtp", searchField: "nSkbDtp", type: "func", value: (d)=> {
      var nomor = ""
      if(d.entity.fasilitas == 0){
        nomor = "N"
      } else if(d.entity.fasilitas == 1){
        nomor = "SKB"
      } else if (d.entity.fasilitas == 2){
        nomor = "DTP"
      }
      return nomor
    }, isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.refXmlId", searchField: "refXmlId", value: "entity.refXmlId", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.tgl", searchField: "tgl", type:'func', value: (val)=> { return <div style={{width: 100}}>{val.entity.tgl}</div> },  isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.createdBy", searchField: "createdBy", value: "entity.createdBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.createdDate", searchField: "createdDate", value: (d)=> {
      if(moment(d.entity.createdDate).isValid()){
        return moment(d.entity.createdDate).format('DD/MM/YYYY HH:MM')
      } else {
        return "-"
      }
    }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.lastModifiedBy", searchField: "lastModifiedBy", value: "entity.lastModifiedBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.lastModifiedDate", searchField: "lastModifiedDate", value: (d)=> {
      if(moment(d.entity.createdDate).isValid()){
        return moment(d.entity.createdDate).format('DD/MM/YYYY HH:MM')
      } else {
        return "-"
      }
    }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
  ]

  fetchOption() {
    const historyId = this.props.match.params.id
    return {
      path: `${Bupot23HistoryService.path}/${historyId}/history`
    }
  }

  _tableActions = [
    {
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    },
    // {label:"divider", iconClassName:"-"},
    // {
    //   label:"Delete",
    //   iconClassName:"mdi mdi-delete",
    //   onClick:(item, callback) => this.deleteItem(item, callback),
    //   confirmation:{
    //     title:"sentence.custom.deleteItem",
    //     message:"sentence.custom.deleteItemConfirmation"
    //   }
    // }
  ]

  backHistory(){
    this.props.history.goBack()
  }

  _barActions = [
    {
      label:'word.back',
      iconClassName:'mdi mdi-arrow-left',
      onClick:() => this.backHistory()
    },
    // {
    //   label:'word.delete',
    //   iconClassName:'mdi mdi-delete',
    //   onClick:() => {
    //     this.setState({showDialogConfirmDeleteSelected:true})
    //   },
    //   disabledFunc:() => this.props.table.selected.length === 0
    // },
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => this.fetchData()
    },
  ]

}
