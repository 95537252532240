import ApiService from '../../../../services/api.service';

class MasterPesangonService extends ApiService {
  name= 'masterPesangon';
  path= 'master/pesangon';
  constructor() {
    super();
    this.init()
  }
}


export default new MasterPesangonService();
