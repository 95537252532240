import React from 'react';
import {connect} from 'react-redux';
import counterpart from 'counterpart';
import { Field, reduxForm, getFormValues} from 'redux-form';
import moment from 'moment';
import download from 'downloadjs';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  CardActions,
  Button,
  LinearProgress
} from 'react-md';
import izitoast from 'izitoast'

import FormView from '../../../../../components/entity/form.view';
// import NomorBPService from '../nomorBP.service';
import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Checkbox} from '../../../../../components/form';
import UtilService from '../../../../../services/utils.service'
// import SPT21SignerService from '../SPT21Signer/SPT21Signer.service';
import NpwpService from './../../NpwpService';
import FormViewSpt from '../../../../../components/entity/form.view.spt';
import terbilangService from '../../../../../services/terbilang.service';
import BP4a2TabunganDepositoService from './BP4a2TabunganDeposito.service';
import SettingImportService from '../../../Setting/SettingImport/SettingImport.service'


@reduxForm({form: 'BP4a2TabunganDepositoForm', destroyOnUnmount: true})
@connect((state) => ({
  access: state.entity.access.api.find,
  organization: state.auth.currentOrganization,
  spt: state.auth.currentSPT,
  formData:getFormValues('BP4a2TabunganDepositoForm')(state)
}))
export default class BP4a2TabunganDepositoForm extends FormViewSpt {
  service=BP4a2TabunganDepositoService
  settingImportService=SettingImportService
  viewType =2;

  initialData={
    bagA: {},
    bagB: {bruto: 0},
    bagC: {}
  }

  constructor(props) {
    super(props);

    this.state= {
      ...this.state,
      showCalculateProgress: false,
      npwpNotValid: true,
      cetak:false,
      disabled: true,
    }

    this.checkSettingImport()
  }

  async checkSettingImport() {
    let spt = this.props.spt

    if (spt) {
      let organization = spt.data.organization

      if (organization) {
        let company = organization.company

        if (company) {
          try {
            let settingImport = await this.settingImportService.api.getByCompany(company.id)

            this.setState({disabled: settingImport.data.autoCalculateDaftarPemotonganDepositoTabungan})
          } catch (e) {
            console.error(e)
          }
        }
      }
    }
  }

  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;

    var saveWord = counterpart.translate('word.save');
    if(this.props.match.params.id == 'new') saveWord = counterpart.translate('word.create')

    let statusSPT = "";
    let statusInSPT = false;
    if(this.props.spt){
        if(this.props.spt.data){
            if(this.props.spt.data.status){
                statusSPT = this.props.spt.data.status;
                statusInSPT = true
            }
        }
    }

    if(statusSPT == "FINISH" || statusSPT == "WAITING"){
        return (
            <div>
              <Button raised disabled={this.state.cetak} primary onClick={this.handleCetak.bind(this)} style={{marginRight: 5}}>Cetak</Button>
            </div>
        )
    } else {
        if(statusInSPT){
            return (
                <div>
                    <Button raised disabled={this.state.cetak} primary onClick={this.handleCetak.bind(this)} style={{marginRight: 5}}>Cetak</Button>
                    <Button raised primary onClick={this.handleCalculate.bind(this)} style={{marginRight: 5}}>Calculate</Button>
                    <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>{saveWord}</Button>
                </div>
            )
        } else {
            return (
                <div>
                </div>
            )
        }
    }
}

  async beforeSave(value) {
    return true;
  }

  async handleCetak() {
    this.setState({cetak: true})

    try {
      var report = await this.service.api.cetak(this.props.spt.data.id)
      var filename = report.headers.filename
      download(report.data, filename);
      this.setState({cetak: false})
    } catch (e) {
      const reader = new FileReader();

      /// This fires after the blob has been read/loaded.
      reader.addEventListener('loadend', (e) => {
        const text = e.srcElement.result
        let json = JSON.parse(text)

        if (json.detail)
          izitoast.error({
            title: 'Error',
            message: json.detail
          })
      });

      reader.readAsText(e.response.data)

      this.setState({cetak: false})
    }
  }

  async handleCalculate() {
    let res = await this.service.api.calculate(this.props.spt.data.id);
    if(res.data) {
      this.props.initialize(res.data);
    }
    izitoast.success({title: "Success", message: "Data telah terkalkulasi."})
  }

  async handleSave(value) {
    await this.service.api.update(value)

    izitoast.success({title: "Success", message: "Data telah tersimpan."})
  }

  async initData() {
      let res = await this.service.api.findBySpt(this.props.spt.data.id);
      this.props.initialize(res.data);
  }

  async calculate(e,v,pv,f, parent) {
    var form = this.props.formData;
    var d = form[parent];

    var jmlNasabah = parseFloat(d.jmlNasabah);
    var nilaiObjekPajak = parseFloat(d.nilaiObjekPajak);
    var pph = parseFloat(d.pph);

    if(f.search('jmlNasabah') >= 0) {
      jmlNasabah = parseFloat(v)
    } else if(f.search('nilaiObjekPajak') >= 0) {
      nilaiObjekPajak = parseFloat(v)
    }else if(f.search('pph') >= 0) {
      pph = parseFloat(v)
    }

    if(!jmlNasabah) jmlNasabah = 0;
    if(!nilaiObjekPajak) nilaiObjekPajak = 0;
    if(!pph) pph = 0;

    await this.props.change(`f`, parseFloat(v));
    this.calculateTotal()
  }

  async calculateTotal() {
    var form = this.props.formData;
    var d = form;

    var jmlNasabah = 0;
    var nilaiObjekPajak = 0;
    var pph = 0;

    Object.keys(d).forEach((k, i) => {
      if(k == 'depositoDalamNegeri' || k == 'depositoLuarNegeri' || k == 'diskontoBi' || k == 'jasaGiro') {
        var x = d[k];
        jmlNasabah = jmlNasabah+ parseFloat(x.jmlNasabah);
        nilaiObjekPajak = nilaiObjekPajak+ parseFloat(x.nilaiObjekPajak)
        pph = pph+ parseFloat(x.pph)
      }
    })

    nilaiObjekPajak = Math.floor(nilaiObjekPajak)
    pph = Math.floor(pph)

    this.props.change('total.jmlNasabah', jmlNasabah)
    this.props.change('total.nilaiObjekPajak', nilaiObjekPajak);
    this.props.change('total.pph', pph);

  }

  formView() {
    var formData = {bagB: {}};
    if(this.props.formData && this.props.formData.values) formData = this.props.formData.values

    let disabled = this.state.disabled

    return (
      <div>
        {this.state.showCalculateProgress && <LinearProgress id='lin_pro' style={{margin: 0}} />}
        <Card>

          <DataTable plain fixedScrollWrapperStyle={{overflow: 'inherit'}} style={{overflow: 'inherit'}}>
            <TableHeader>
              <TableRow>
                <TableColumn>No.</TableColumn>
                <TableColumn>Jenis Penghasilan</TableColumn>
                <TableColumn className='md-text-right'>Jumlah Nasabah</TableColumn>
                <TableColumn className='md-text-right'>Nilai Objek Pajak</TableColumn>
                <TableColumn className='md-text-right'>PPh Dipotong(Rp)</TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableColumn>1.</TableColumn>
                <TableColumn>Bunga Deposito / Tabungan</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>a.) Yang ditempatkan di dalam negeri</TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name='depositoDalamNegeri.jmlNasabah'
                    component={TextfieldMask}
                    className='md-text-right'
                    onChange={(e, v, vp, f) => this.calculate(e, v, vp, f, 'depositoDalamNegeri')}
                    money={','}
                    disabled={disabled}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    name='depositoDalamNegeri.nilaiObjekPajak'
                    component={TextfieldMask}
                    className='md-text-right'
                    onChange={(e, v, vp, f) => this.calculate(e, v, vp, f, 'depositoDalamNegeri')}
                    money={','}
                    disabled={disabled}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name='depositoDalamNegeri.pph'
                    component={TextfieldMask}
                    className='md-text-right'
                    onChange={(e, v, vp, f) => this.calculate(e, v, vp, f, 'depositoDalamNegeri')}
                    money={','}
                    disabled={disabled}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>b.) Yang ditempatkan di luar negeri</TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name='depositoLuarNegeri.jmlNasabah'
                    component={TextfieldMask}
                    className='md-text-right'
                    onChange={(e, v, vp, f) => this.calculate(e, v, vp, f, 'depositoLuarNegeri')}
                    money={','}
                    disabled={disabled}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name='depositoLuarNegeri.nilaiObjekPajak'
                    component={TextfieldMask}
                    className='md-text-right'
                    onChange={(e, v, vp, f) => this.calculate(e, v, vp, f, 'depositoLuarNegeri')}
                    money={','}
                    disabled={disabled}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name='depositoLuarNegeri.pph'
                    component={TextfieldMask}
                    className='md-text-right'
                    onChange={(e, v, vp, f) => this.calculate(e, v, vp, f, 'depositoLuarNegeri')}
                    money={','}
                    disabled={disabled}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>2.</TableColumn>
                <TableColumn>Diskonto Sertifikat Bank Indonesia</TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name='diskontoBi.jmlNasabah'
                    component={TextfieldMask}
                    className='md-text-right'
                    onChange={(e, v, vp, f) => this.calculate(e, v, vp, f, 'diskontoBi')}
                    money={','}
                    disabled={disabled}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name='diskontoBi.nilaiObjekPajak'
                    component={TextfieldMask}
                    className='md-text-right'
                    onChange={(e, v, vp, f) => this.calculate(e, v, vp, f, 'diskontoBi')}
                    money={','}
                    disabled={disabled}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name='diskontoBi.pph'
                    component={TextfieldMask}
                    className='md-text-right'
                    onChange={(e, v, vp, f) => this.calculate(e, v, vp, f, 'diskontoBi')}
                    money={','}
                    disabled={disabled}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>3.</TableColumn>
                <TableColumn>Jasa Giro</TableColumn>
                <TableColumn>
                  <Field
                   // label='Bruto'
                    name='jasaGiro.jmlNasabah'
                    component={TextfieldMask}
                    className='md-text-right'
                    onChange={(e, v, vp, f) => this.calculate(e, v, vp, f, 'jasaGiro')}
                    money={','}
                    disabled={disabled}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                   // label='Bruto'
                    name='jasaGiro.nilaiObjekPajak'
                    component={TextfieldMask}
                    className='md-text-right'
                    onChange={(e, v, vp, f) => this.calculate(e, v, vp, f, 'jasaGiro')}
                    money={','}
                    disabled={disabled}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name='jasaGiro.pph'
                    component={TextfieldMask}
                    className='md-text-right'
                    onChange={(e, v, vp, f) => this.calculate(e, v, vp, f, 'jasaGiro')}
                    money={','}
                    disabled={disabled}
                  />
                </TableColumn>
              </TableRow>
              <TableRow style={{borderTop: '3px solid black'}}>
                <TableColumn></TableColumn>
                <TableColumn>Jumlah</TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name='total.jmlNasabah'
                    component={TextfieldMask}
                    className='md-text-right'
                    onChange={(e, v, vp, f) => this.calculate(e, v, vp, f, 'total')}
                    money={','}
                    disabled={disabled}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name='total.nilaiObjekPajak'
                    component={TextfieldMask}
                    className='md-text-right'
                    onChange={(e, v, vp, f) => this.calculate(e, v, vp, f, 'total')}
                    money={','}
                    disabled={disabled}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name='total.pph'
                    component={TextfieldMask}
                    className='md-text-right'
                    onChange={(e, v, vp, f) => this.calculate(e, v, vp, f, 'total')}
                    money={','}
                    disabled={disabled}
                  />
                </TableColumn>
              </TableRow>
            </TableBody>
          </DataTable>
        </Card>

      </div>
    )
  }

}
