import React, { Component } from 'react'; import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {TabsContainer, Tabs, Tab} from 'react-md';
import { Field } from 'redux-form';

import KodeBuktiPotong26Service from './KodeBuktiPotong26.service';
import ListView from '../../../../../components/entity/ListViewEbupot';
import AuthService from '../../../../../services/auth.service'
import ListViewEbupot from '../../../../../components/entity/ListViewEbupot';
import KodeBuktiPotong26Dialog from './KodeBuktiPotong26.dialog.view';

@connect((state)=> ({
  ...KodeBuktiPotong26Service.stateConnectSetting()(state)
}), KodeBuktiPotong26Service.actionConnectSetting())
export default class KodeBuktiPotong26View extends ListViewEbupot {
  service=KodeBuktiPotong26Service;
  FormDialog=KodeBuktiPotong26Dialog

  beforeFetch(params) {
    delete params.startDate
    delete params.endDate
    delete params.column
    delete params.total
    delete params.keyword
    
    
    // params.sortBy = 'id'
    // params.sort = 1
}

  columns=[
    {label: "word.id", searchField: "id", value: "id", isDefaultSort:false, isSortable:true, show:false, isSearchable:true},
    {label: "word.name", searchField: "name", value: "name", isDefaultSort:false, isSortable:true, show:true, isSearchable:true}
  ]

  _tableActions = []

  _barActions = [
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => this.fetchData()
    },
  ]

}
