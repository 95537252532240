import React, { Component } from 'react';
import { connect } from 'react-redux';
import {} from 'react-md';

import OrganizaionUserService from './Organization.user.service';
import ListView from '../../../../../components/entity/listView';
import OrganizaionUserDialog from './Organization.user.dialog.view';

@connect(OrganizaionUserService.stateConnectSetting(), OrganizaionUserService.actionConnectSetting(), null, {withRef: true})
export default class OrganizationUserView extends ListView {
  service=OrganizaionUserService
  FormDialog=OrganizaionUserDialog

  columns=[
    {label: "word.login",  value: "user.login", isDefaultSort:true, show:true, isSearchable:true},
    {label: "word.firstName",  value: "user.firstName", isDefaultSort:true, show:true, isSearchable:true},
    {label: "word.lastName",  value: "user.lastName", isDefaultSort:true, show:true, isSearchable:true},
    {label: "word.email",  value: "user.email", isDefaultSort:true, show:true, isSearchable:true}
  ]

  fetchOption() {
    return {
      path: 'organization/'+this.props.organizationId+'/user/page'
    }
  }


  beforeFetch(param) {
    if(this.props.organizationId) {
      if(this.props.organizationId != 'new') {
        param['organizationId.equals'] = this.props.organizationId;
      }
    }
  }

  header() {
    return this.commandBar()
  }

  render() {
    return (
      <this.viewContainer>

        <div className="flex mpk-layout">
          {this.tableView()}
          {this.tableSetting()}

          <this.FormDialog
            organizationId={this.props.organizationId}
            height={400}
            visible={this.state.showForm}
            add={this.props.tableActions.addDataItem}
            formData={this.state.formData}
            onSuccess={()=> this.fetchData()}
            onClose={() => this.setState({showForm:false})}
          />

          {this.dialogConfirm()}
        </div>
      </this.viewContainer>
    )
  }

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='Nama Depan'
          name='userFirstName__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Nama Belakang'
          name='userLastName__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Sign In'
          name='userLogin__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Email'
          name='userEmail__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
      </this.SearchContainer>
    )
  }

}
