import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Avatar
} from 'react-md';
import {Textfield, TextfieldMask, validation, Searchfield} from '../../../../components/form';
import NpwpService from './../../../Main/pph/NpwpService';
import FormView from '../../../../components/entity/form.view';
import PaymentOrderService from './PaymentOrder.service';
import utilsService from '../../../../services/utils.service';
import TextField from 'react-md/lib/TextFields/TextField';
import AkunPembayaranService from "modules/Main/Ebilling/AkunPembayaran/AkunPembayaran.service";
import BankService from "modules/Main/Ebilling/Bank/Bank.service";
import PaymentNotificationService from "modules/Main/Ebilling/PaymentNotification/PaymentNotification.service";
import FileHeaderService from "modules/Main/Ebilling/FileHeader/FileHeader.service";
import Constants from '../Constants/Constants';
import iziToast from 'izitoast';

@reduxForm({form: 'PaymentBriCmsForm', destroyOnUnmount: true, initialValues: {}})
@connect((state) => ({
  access: state.entity.access.api.find,
  formData: getFormValues('PaymentBriCmsForm')(state)
}))
export default class PaymentBriCmsForm extends FormView {
  translate = false
  service = PaymentOrderService
  viewType=2
  akunPembayaranService=AkunPembayaranService
  bankService=BankService
  paymentNotificationService=PaymentNotificationService
  fileHeaderService=FileHeaderService

  titleHeader() {
    return `Proses Pembayaran BRI CMS`;
  }
  initialData = {}

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      order: {
        type: 'all',
        data: [],
        paymentMethod: {},
      },
      akun:[]
    }
  }

  async process() {
    let order = this.state.order;

    let {paymentAccount, companyid, userid, debitAccountNumber} = this.props.formData;

    if(companyid.trim() === '') {
      this.toast.warning({
        title: 'Peringatan',
        message: 'Corporate ID BRI CMS harus diisi.'
      })

      return
    }

    if(userid.trim() === '') {
      this.toast.warning({
        title: 'Peringatan',
        message: 'User BRI CMS harus diisi.'
      })

      return
    }

    if(debitAccountNumber.trim() === '') {
      this.toast.warning({
        title: 'Peringatan',
        message: 'Nomor Rekening BRI CMS harus diisi.'
      })

      return
    }

    let approvalTransaction = 'S'

    let fileheader = {
      paymentAccount,
      companyid,
      userid,
      debitAccountNumber,
      approvalTransaction,
    }

    let invoice = {
      paymentMethod: order.paymentMethod,
    }

    let data = {
      etaxs: order.data,
      invoice,
      fileheader
    };

    try {
      let res = null;

      if(this.state.order.type === 'selected') {
        let check = await this.fileHeaderService.api.checkTotalJumlahSetorByChecklist(data)

        let jumlah = check.jumlah
        let jumlahIdBilling = check.jumlahIdBilling
        let jumlahTanpaIdBilling = check.jumlahTanpaIdBilling
        let jumlahError = check.jumlahError
        let total = utilsService.number.format(check.total)

        // ada error
        if (jumlahError) {
          iziToast.error({
            title: 'Error',
            message: `Terdapat ${jumlahError} SSP error yang tidak dapat di-order.`
          })
        }
        // tidak ada error
        else {
          let confirmOrderChecked = this.context.showDialog((props, res, rej) =>({
            title: 'Konfirmasi',
            initialValue:{},
            okText: 'Order',
            width: 400,
            text: (
              <p>
                Jumlah SSP yang akan diorder adalah {jumlah} ({jumlahIdBilling} SSP dengan ID billing dan {jumlahTanpaIdBilling} SSP tanpa ID billing) dengan total jumlah setor  {total}. Apakah anda yakin melanjutkan proses order?
              </p>
            )
          }))

          confirmOrderChecked.then((ok) => {
            if(ok) {
              res = this.fileHeaderService.api.createFileHeaderByCorporate(data)

              iziToast.success({
                title: 'Sukses',
                message: 'Transaksi sedang diproses, silahkan cek di halaman payment notification.'
              })

              this.props.history.push('/EBILLING/paymentNotification');
            }
          })
        }
      } else {
        let check = await this.fileHeaderService.api.checkFileHeaderTotalJumlahSetor({})

        let jumlah = check.jumlah
        let jumlahIdBilling = check.jumlahIdBilling
        let jumlahTanpaIdBilling = check.jumlahTanpaIdBilling
        let jumlahError = check.jumlahError
        let total = utilsService.number.format(check.total)

        // ada error
        if (jumlahError) {
          iziToast.error({
            title: 'Error',
            message: `Terdapat ${jumlahError} SSP error yang tidak dapat di-order.`
          })
        }
        // tidak ada error
        else {
          let confirmOrderAll = this.context.showDialog((props, res, rej) => ({
            title: 'Konfirmasi',
            initialValue: {},
            okText: 'Order',
            width: 400,
            text: (
              <p>
                Jumlah SSP yang akan diorder adalah {jumlah} ({jumlahIdBilling} SSP dengan ID billing
                dan {jumlahTanpaIdBilling} SSP tanpa ID billing) dengan total jumlah setor {total}. Apakah anda yakin
                melanjutkan proses order?
              </p>
            )
          }))

          confirmOrderAll.then((ok) => {
            if (ok) {
              res = this.fileHeaderService.api.createFileHeaderByCorporateAll(data)

              iziToast.success({
                title: 'Sukses',
                message: 'Transaksi sedang diproses, silahkan cek di halaman payment notification.'
              })

              this.props.history.push('/EBILLING/paymentNotification');
            }
          })
        }
      }
    } catch (e) {
      iziToast.warning({
        title: e.name,
        message: e.message
      })
    }
  }

  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;

    let processDisabled = (!this.props.formData.companyid || !this.props.formData.userid || !this.props.formData.debitAccountNumber);

    return (
      <div>
        <Button secondary flat onClick={()=> {this.props.history.goBack()}} style={{marginRight: 16}}>Back</Button>

        <Button raised primary onClick={()=> this.process()} disabled={processDisabled}>Process</Button>
      </div>
    )
  }

  async refreshPaymentAccount() {
    let paymentOrder = localStorage.getItem('paymentOrder')

    let paymentAccount = []

    if (paymentOrder) {
      paymentOrder = JSON.parse(paymentOrder)

      paymentAccount = await AkunPembayaranService.api.getPersepsiByBankId(paymentOrder.paymentMethod.bank.id)
    }
    else {
      paymentAccount = await AkunPembayaranService.api.find()
    }

    this.setState({akun:paymentAccount.data})
  }

  async initData() {
    //let res = await this.service.api.getFileTransaksi(this.props.match.params.id);

    let paymentOrder = localStorage.getItem('paymentOrder');

    if(paymentOrder) this.setState({'order': JSON.parse(paymentOrder)});

    await this.refreshPaymentAccount();

    this.props.initialize({});
  }

  async onItemClick(d) {
    this.props.initialize(d)
  }

  async addPaymentAccount() {
    let dialog = await this.context.showDialog((props, res, rej) =>({
      title: 'Tambah',
      initialValue:{},
      okText: 'Save',
      width: 400,
      text: (
        <div className='md-grid'>
          <Field
            label='Bank'
            name='bank'
            className="md-cell md-cell--12"
            component={Searchfield}
            valueField="parent"
            viewField="name"
            service={BankService}
            validate={validation.required}
            itemTemplate={function(d) {
              if (d.id !== '') {
                let baseUrl = Constants.baseUrl()

                let image = baseUrl + '/image/logo/bank/' + d.id

                return {
                    primaryText: d[this.props.viewField],
                    rightIcon: <img style={{height: 20, width: 'auto'}} src={image} />,
                    onClick: () => {
                      if(!this.props.disabled){
                        this.handleItemClick(d); this.hide()
                      }
                    }
                  }
              } else {
                return {
                  primaryText: '',
                  onClick: () => {
                    if(!this.props.disabled){
                      this.handleItemClick(d); this.hide()
                    }
                  }
                }
              }
            }}
          />

          <Field
            label='Corporate ID BRI CMS'
            name='companyid'
            className="md-cell md-cell--12"
            component={Textfield}
            validate={validation.required}
          />

          <Field
            label='User BRI CMS'
            name='name'
            className="md-cell md-cell--12"
            component={Textfield}
            validate={validation.required}
          />

          <Field
            label='Nomor Rekening BRI CMS'
            name='accountNumber'
            className="md-cell md-cell--12"
            component={Textfield}
            validate={validation.required}
          />
        </div>
      )
    }));

    if(dialog) {
      try {
        let res = await this.akunPembayaranService.api.save(dialog)

        iziToast.success({
          title: 'Sukses',
          message: 'Akun pembayaran sukses dibuat.'
        })
      } catch (e) {
        iziToast.warning({
          title: e.name,
          message: e.message
        })
      }
    }
  }

  formView() {
    let formData = {};

    if(this.props.formData) formData = this.props.formData;

    let total = 0;

    return (
      <div>
        {this.state.order.type === 'selected' &&
        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='SSP' />
          <Divider/>

          <div>
            <DataTable plain>
              <TableHeader>
                <TableRow>
                  <TableColumn>No.</TableColumn>
                  <TableColumn>Kode SSP</TableColumn>
                  <TableColumn>ID Billing</TableColumn>
                  <TableColumn>NPWP</TableColumn>
                  <TableColumn>Nama</TableColumn>
                  <TableColumn>Jenis Pajak</TableColumn>
                  <TableColumn>Jenis Setoran</TableColumn>
                  <TableColumn>Masa Pajak</TableColumn>
                  <TableColumn>Jumlah Setoran</TableColumn>
                </TableRow>
              </TableHeader>
              <TableBody>
                {
                  this.state.order.data.map((d, i)=> {
                    total += d.suratsetoran.jumlahsetor;

                    let idBilling = '-';

                    if (d.suratsetoran.idbilling) {
                      idBilling = d.suratsetoran.idbilling;
                    }
                    else {
                      if (d.suratsetoran.errorBilling) {
                        idBilling = d.suratsetoran.messageBilling
                      }
                    }

                    let name = '-';

                    if (d.suratsetoran.name) {
                      name = d.suratsetoran.name;
                    }
                    else {
                      if (d.suratsetoran.errorInquiry) {
                        name = d.suratsetoran.messageInquiry
                      }
                    }

                  return <TableRow key={i}>
                    <TableColumn>{i+1}</TableColumn>
                    <TableColumn>{d.suratsetoran.taxno}</TableColumn>
                    <TableColumn>{idBilling}</TableColumn>
                    <TableColumn>{d.suratsetoran.npwp}</TableColumn>
                    <TableColumn>{name}</TableColumn>
                    <TableColumn>{d.suratsetoran.jenispajak.code}</TableColumn>
                    <TableColumn>{d.suratsetoran.jenissetoran.code}</TableColumn>
                    <TableColumn>{d.suratsetoran.masadari.code + ' - ' + d.suratsetoran.masasampai.code + ' ' + d.suratsetoran.tahun}</TableColumn>
                    <TableColumn style={{textAlign: 'right'}}>{utilsService.number.format(d.suratsetoran.jumlahsetor)}</TableColumn>
                  </TableRow>
                })}

                <TableRow style={{borderTop: '2px solid black'}}>
                  <TableColumn></TableColumn>
                  <TableColumn>Total</TableColumn>
                  <TableColumn></TableColumn>
                  <TableColumn></TableColumn>
                  <TableColumn></TableColumn>
                  <TableColumn></TableColumn>
                  <TableColumn></TableColumn>
                  <TableColumn></TableColumn>
                  <TableColumn style={{textAlign: 'right'}}>{utilsService.number.format(total)}</TableColumn>
                </TableRow>


              </TableBody>
            </DataTable>
          </div>
        </Card>

        }
        <br/>

        <Card>
          {/* <CardTitle title='' style={{padding: '10px 16px'}} subtitle='Informasi Akun Pembayaran' />
          <Divider/> */}
          <div className='mpk-commandbar mpk-layout align-center justify-between mpk-border bottom solid dark'>
            <div className='flex' style={{display: 'flex'}}>
              <Button icon tooltipLabel="Tambah" onClick={()=> {this.addPaymentAccount()}}>add</Button>
              <Button icon tooltipLabel="Refresh" onClick={() => {this.refreshPaymentAccount()}}>refresh</Button>
            </div>
          </div>
          <div className='mpk-layout'>
            <div className='flex'>
              <List>
                {
                  this.state.akun.map((d, i)=> {
                    let baseUrl = Constants.baseUrl()

                    let image = baseUrl + '/image/logo/bank/' + d.bank.id

                    return <ListItem
                      key={i}
                      leftAvatar={<Avatar src={image} />}
                      primaryText={(d.companyid) ?  d.companyid + ' - ' + d.name : d.name}
                      secondaryText={d.accountNumber}
                      onClick={()=> {this.onItemClick({
                        paymentAccount: d,
                        companyid: d.companyid,
                        userid: d.name,
                        debitAccountNumber: d.accountNumber,
                      })}}
                    />
                  })
                }
              </List>

            </div>
            <div className='flex '>
              <div className='md-grid'>
                <Field
                  label='Corporate ID BRI CMS'
                  name='companyid'
                  className="md-cell md-cell--12"
                  component={Textfield}
                  disabled
                />
                <Field
                  label='User BRI CMS'
                  name='userid'
                  className="md-cell md-cell--12"
                  component={Textfield}
                  disabled
                />
                <Field
                  label='Nomor Rekening BRI CMS'
                  name='debitAccountNumber'
                  className="md-cell md-cell--12"
                  component={Textfield}
                  disabled
                />
              </div>
            </div>
          </div>

        </Card>
        <br/>
      </div>
    )
  }

}
