import ApiService from '../../../../../services/api.service';

class Master21PensiunService extends ApiService {
  name= 'Master21Pensiun';
  path= 'master/tarif-21-pensiun';

  constructor() {
    super();
    this.init()
  }
}


export default new Master21PensiunService();
