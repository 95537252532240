import React, { Component } from 'react';
import { Field, reduxForm , propTypes, formValueSelector } from 'redux-form';
import { validation, Textfield, TextfieldMask, Datepicker, convert } from '../../../../../../components/form';
import Service from './ImportLK15.service';
import ImportDialogTemplateView from '../../../../../../components/entity/Import.dialog.template.view';
ImportDialogTemplateView

@reduxForm({form: 'ImportLK15-form', destroyOnUnmount: false})
export default class ImportLK15DialogView extends ImportDialogTemplateView {
  service=Service
}
