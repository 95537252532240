import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues} from 'redux-form';
import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Checkbox} from '../../../../../components/form';
import UtilService from '../../../../../services/utils.service'
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  CardActions,
  Button,
  LinearProgress
} from 'react-md';

import FormViewSpt from '../../../../../components/entity/form.view.spt';
import NpwpService from './../../NpwpService';
import BP22Service from './BP22.service';
import nomorBP22Service from '../nomorBP22.service';
import LawanTransaksiDialog from './../../../../../components/entity/LawanTransaksi.dialog';
import MasterLawanTransaksi22Service from '../../../Master/MasterLawanTransaksi22/MasterLawanTransaksi22.service';
import F113303Form  from './form/F113303.form';
import F113304Form from './form/F113304.form';
import F113304SForm from './form/F113304S.form';
import F113304AForm from './form/F113304A.form';
import Master22TarifService from '../master/tarif/Tarif22.service';


@reduxForm({form: 'BP_22_Form', destroyOnUnmount: true, initialValues: {}})
@connect((state) => ({
  access      : state.entity.access.api.find,
  organization: state.auth.currentOrganization,
  spt         : state.auth.currentSPT,
  formData    : getFormValues('BP_22_Form')(state)
}))
export default class BP22Form extends FormViewSpt {
  service  = BP22Service
  viewType = 2;

  typingObj     = null;
  typingTimeout = 1000;


  initialData={

  }

  typeOptions = [{
    name   : 'Bukti Pungut Atas Impor (Oleh Ditjen Bea dan Cukai) F113303',
    objName: 'F113303',
    id     : 'F113303'
  },
  {
    name   : 'Bukti Pungut PPh Pasal 22 (Untuk Industri/Eksportir Tertentu) F113304',
    objName: 'F113304',
    id     : 'F113304'
  },{
    name   : 'Bukti Pungut PPh Pasal 22 (Untuk Industri/Importir BBM, Gas dan Pelumas) F113304S',
    objName: 'F113304S',
    id     : 'F113304S'
  },{
    name   : 'Bukti Pungut PPh Pasal 22 (Atas Pembelian Barang Oleh Badan Tertentu Yang Ditunjuk) F113304A',
    objName: 'F113304A',
    id     : 'F113304A'
  },]

  constructor(props) {
    super(props);

    this.state= {
      ...this.state,
      showCalculateProgress: false,
      npwpNotValid         : true,
      lkDialog             : false
    }
  }

  async npwpValidation(evt, value){

    if(this.typingObj) clearTimeout(this.typingObj);

    this.typingObj = setTimeout(async ()=> {
      if(value){
        var res = await NpwpService.api.validate(value)
        if(res.valid){
          this.setState({npwpNotValid: false})
          var lk = await MasterLawanTransaksi22Service.api.page({
            'npwp.equals': value,
            'organizationId.equals': this.props.spt.data.organization.id
          })

          var formData = {...this.props.formData};

          if(lk.data) {
            if(lk.data.length > 0) {
              var d             = lk.data[0];
              formData = {
                ...formData,
                "npwp"  : d.npwp,
                "nik"   : d.nik,
                "nama"  : d.nama,
                "alamat": d.alamat,
                "email" : d.email
              };
            } else {
              formData.npwp = value
              this.toast.info({title: "Info", message: `Lawan Transaksi dengan npwp ${value} tidak ditemukan`})
            }
          }
          if(formData.npwp === '000000000000000' || formData.npwp === "") {
            formData.non = true;
          }
          else {
            formData.non = false;
          }

          this.props.initialize(formData);
        } else {
          this.setState({npwpNotValid: true})
          this.toast.warning({title: "Validasi Npwp", message: "npwp tidak valid"})
        }
      }
    }, this.typingTimeout);
  }

  async beforeSave(value) {
    if(value.jmlBruto == 0 || value.jmlBruto == '') {
      this.toast.warning({title:'Warning', message:'Isi bruto harus lebih besar dari 0'})
      return false;
    }

    var date;
    if(typeof value.tgl === 'string') {
      if(value.tgl.search('/') != -1) {
        date = moment(value.tgl, 'DD/MM/YYYY');
      } else {
        date = moment(value.tgl, 'YYYY-MM-DD');
      }
    } else if(typeof value.tgl === 'object') {
      date = moment(value.tgl)
    }

    if(!(date.year() == this.props.spt.data.year && date.month()+1 == this.props.spt.data.month)) {
      this.toast.warning({title:'Warning', message:'Tanggal bukti potong harus sesuai dengan masa SPT'})
      return false;
    }


    return true;
  }


  async handleSave(value) {

    var beforeSave = await this.beforeSave(value);
    var res = null;
    if(beforeSave) {
      if(typeof beforeSave === 'object') value = beforeSave;
      try {
        this.setState({onProgress: true})
        var bpLoc = '';
        switch (value.type) {
          case 'F113303':
            bpLoc = 'bp-atas-impor-bendaharawan'

            value = {
              nilaiImpor: 0,
              "brutoPajakPertambahanNilai": 0,
              "tarifPajakPertambahanNilai": 10,
              "pphPajakPertambahanNilai": 0,
              "brutoPajakPenjualanAtasBarangMewahA": 0,
              "tarifPajakPenjualanAtasBarangMewahA": 0,
              "pphPajakPenjualanAtasBarangMewahA": 0,
              "brutoPajakPenjualanAtasBarangMewahB": 0,
              "tarifPajakPenjualanAtasBarangMewahB": 0,
              "pphPajakPenjualanAtasBarangMewahB": 0,
              "brutoPajakPenjualanAtasBarangMewahC": 0,
              "tarifPajakPenjualanAtasBarangMewahC": 0,
              "pphPajakPenjualanAtasBarangMewahC": 0,
              "brutoPajakPenjualanAtasBarangMewahD": 0,
              "tarifPajakPenjualanAtasBarangMewahD": 0,
              "pphPajakPenjualanAtasBarangMewahD": 0,
              "brutoPajakPenjualanAtasBarangMewahE": 0,
              "tarifPajakPenjualanAtasBarangMewahE": 0,
              "pphPajakPenjualanAtasBarangMewahE": 0,
              "brutoPajakPenjualanAtasBarangMewahF": 0,
              "tarifPajakPenjualanAtasBarangMewahF": 0,
              "pphPajakPenjualanAtasBarangMewahF": 0,
              "brutoPajakPenjualanAtasBarangMewahG": 0,
              "tarifPajakPenjualanAtasBarangMewahG": 0,
              "pphPajakPenjualanAtasBarangMewahG": 0,
              "brutoPajakPenjualanAtasBarangMewahH": 0,
              "tarifPajakPenjualanAtasBarangMewahH": 0,
              "pphPajakPenjualanAtasBarangMewahH": 0,
              "brutoApi": 0,
              "tarifApi": 0,
              "pphApi": 0,
              "brutoNonApi": 0,
              "tarifNonApi": 0,
              "pphNonApi": 0,
              ...value
            }

            break;
            case 'F113304':
              bpLoc = 'bp-industri-eksportir'
              break;
            case 'F113304S':
              bpLoc = 'bp-industri-importir'
              break;
            case 'F113304A':
              bpLoc = 'bp-atas-pembelian-barang'
              break;
          default:
            break;
        }
        if(value.id) {
          res = await this.service.http.put(`${this.service.apiLocation}/spt22/${bpLoc}`, value)
        } else {
          res = await this.service.http.post(`${this.service.apiLocation}/spt22/${bpLoc}`, value)
        }
        this.setState({onProgress: false})
        this.afterSave(res, value)

        var pathArray = this.props.location.pathname.split('/');
        var path = '';
        pathArray.forEach((d, i)=> {
          if(pathArray.length-1 != i) {
            path = path+d;
            if(i != pathArray.length-2) path = path+'/'
          }
        })

        this.props.history.push(path)
      } catch(e) {
        var msg = e.message;
        if(e.response) msg = e.response.data.message;
        this.setState({
          isError: true,
          onProgress: false,
          errorMessage: msg
        })
      }
    }
  }

  jenisFormSwithcer() {
    switch (this.props.formData.type) {
      case 'F113303':
        return <F113303Form change={this.props.change} initialize={this.props.initialize} connectCmp={(childFunc)=> {this.reCalculate = childFunc}} />
      case 'F113304':
        return <F113304Form change={this.props.change} initialize={this.props.initialize} connectCmp={(childFunc)=> {this.reCalculate = childFunc}} />
      case 'F113304S':
        return <F113304SForm change={this.props.change} initialize={this.props.initialize} connectCmp={(childFunc)=> {this.reCalculate = childFunc}} />
      case 'F113304A':
        return <F113304AForm change={this.props.change} initialize={this.props.initialize} connectCmp={(childFunc)=> {this.reCalculate = childFunc}} />
      default:
        break;
    }
  }

  async initData() {
    var tarif = {};
    if(this.props.match.params.id == 'new') {

      var nomorBP    = "";

      try {
        let nomorBPReq = await nomorBP22Service.api.get(this.props.spt.data.organization.id)
        if(nomorBPReq.data.mode === 'PREF_SUF') {
          nomorBP = nomorBPReq.data.prefix+nomorBPReq.data.suffix;
        } else {
          nomorBP = nomorBPReq.data.suffix+nomorBPReq.data.prefix;
        }
      } catch (error) {

      }

      let organization = this.props.organization

      if (!organization) {
        organization = this.props.spt.data.organization
      } else {
        if (!organization.npwp) {
          organization = this.props.spt.data.organization
        }
      }

      this.initialData = {
        ...this.initialData,
        no             : nomorBP,                        //.data.no,
        namaPemotong   : organization.name,
        npwpPemotong   : organization.npwp,
        alamatPemotong : organization.address,
        spt            : this.props.spt.data,
        jmlPph         : 0,
        tgl            : new Date(),
        jmlBruto       : 0,
        terbilang      : "",
        auto           : true,
        non            : false,
        // F113303: {}
      }
      this.props.initialize(this.initialData);
    } else {

      var bpLoc = ''
      switch (this.props.match.params.type) {
        case "F113303":
          bpLoc = 'bp-atas-impor-bendaharawan'
          break;
        case 'F113304':
          bpLoc = 'bp-industri-eksportir'
          break;
        case 'F113304S':
          bpLoc = 'bp-industri-importir'
          break;
        case 'F113304A':
          bpLoc = 'bp-atas-pembelian-barang'
          break;
        default:
          break;
      }

      let res = await this.service.http.get(`${this.service.apiLocation}/spt22/${bpLoc}/${this.props.match.params.id}`);
      res.data.type = this.props.match.params.type;
      this.props.initialize(res.data);
      // this.npwpValidation(null, res.data.bagA.npwp)
      this.setState({npwpNotValid: false})
    }
  }

  async afterSave(res, val) {
    var data = {
      npwp: val.npwp,
      nama: val.nama,
      alamat: val.alamat,
      email: val.email,
      organization: this.props.spt.data.organization
    }
    if(data.id) delete data.id;
    await MasterLawanTransaksi22Service.api.findOrCreate(data)
  }

  formView() {
    var formData = {bagB: {}};
    if  (this.props.formData) formData = this.props.formData;

    return (
      <div>
        <LawanTransaksiDialog
          spt      = {this.props.spt.data}
          onSelect = {(d)=> {
            var formData = {
              ...this.props.formData,
              "npwp"   : d.npwp,
              "nik"    : d.nik,
              "nama"   : d.nama,
              "alamat" : d.alamat,
              "telepon": d.telepon,
              "kodePos": d.kodePos,
              "email"  : d.email
            };
            if(formData.npwp === '000000000000000' || formData.npwp === "") formData.non = true;
            this.props.initialize(formData);

          }}
          service = {MasterLawanTransaksi22Service}
          visible = {this.state.lkDialog}
          onHide  = {()=> this.setState({lkDialog: false})}
        />
        <Card>
          <div className="md-grid">
            <Field
              label     = 'No. Bukti Pungut'
              name      = 'no'
              className = "md-cell md-cell--6"
              component = {Textfield}
              validate  = {validation.required}
            />

            <Field
              label        = 'Tanggal'
              name         = 'tgl'
              className    = "md-cell md-cell--6"
              // onDateChange = {async (d, e) => this.changeTarif(d)}
              component    = {Datepicker}
              validate     = {validation.required}
            />

            <Field
              label     = 'Referensi'
              name      = 'referensi'
              id        = "BP21FForm-referensi"
              className = "md-cell md-cell--12"
              component = {Textfield}
            />
          </div>
        </Card>
        <br/>

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='A. LAWAN TRANSAKSI ' />
          <Divider/>
          <div className="md-grid">
            <div className='md-cell md-cell--6'>
            <div className='mpk-layout'>
              <Field
                label      = 'NPWP'
                className  = 'flex'
                name       = 'npwp'
                component  = {TextfieldMask}
                maskFormat = "##.###.###.#-###-###"
                mask       = "_"
                length     = {15}
                onChange   = {this.npwpValidation.bind(this)}
                validate   = {validation.required}
              />
              <Button icon primary swapTheming style={{marginTop: 10}} onClick={()=> this.setState({lkDialog: true})}>search</Button>
              </div>
              <Field
                label     = 'Nama'
                name      = 'nama'
                disabled  = {this.state.npwpNotValid}
                component = {Textfield}
                validate  = {validation.required}
              />
              {/* <Field
                label     = 'NIK'
                name      = 'penerimaPenghasilan.nik'
                disabled  = {this.state.npwpNotValid}
                component = {Textfield}
              />
              <Field
                label     = 'Telepon'
                name      = 'telepon'
                id        = "BP21FForm-name"
                component = {Textfield}
                />*/}

            </div>
            <div className='md-cell md-cell--6'>
              {/* <Field
                label     = 'Kode Poss'
                name      = 'kodePoss'
                id        = "BP21FForm-codePoss"
                component = {Textfield}
              />*/}
              <Field
                label = 'Alamat'
                name  = 'alamat'

                disabled  = {this.state.npwpNotValid}
                component = {Textfield}
                validate  = {validation.required}
              />
              <Field
                label = 'Email'
                name  = 'email'

                disabled  = {this.state.npwpNotValid}
                component = {Textfield}
              />
            </div>
          </div>
        </Card>

        <br/>

        {this.state.showCalculateProgress && <LinearProgress id='lin_pro' style={{margin: 0}} />}
        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='B. DETAIL DIPOTONG' />
          <Divider/>
          <div>
            {(this.props.match.params && this.props.match.params.id && this.props.match.params.id == 'new') &&

            <Field
              label     = 'Jenis Bukti Pungut'
              className = "md-cell md-cell--12"
              name      = 'type'
              onChange  = {async (e, v)=> {
                var tarif = await Master22TarifService.api.byCompany(
                  this.props.spt.data.organization.company.id,
                  {
                    compId: this.props.organization.company.id,
                    tgl   : moment(this.props.formData.tgl).format("YYYY-MM-DD")
                  }
                );
                var tarifMap = {};
                if(tarif) {
                  tarifMap = tarif.details.reduce((pv, v)=> {
                    pv[v.kode] = v;
                    return pv;
                  }, {});
                }


                var formData = {}

                switch (v) {
                  case 'F113303':
                    formData = {
                      ...this.props.formData,
                      "brutoPajakPertambahanNilai": 0,
                      "tarifPajakPertambahanNilai": 10,
                      "pphPajakPertambahanNilai": 0,
                      "brutoPajakPenjualanAtasBarangMewahA": 0,
                      "tarifPajakPenjualanAtasBarangMewahA": 10,
                      "pphPajakPenjualanAtasBarangMewahA": 0,
                      "brutoPajakPenjualanAtasBarangMewahB": 0,
                      "tarifPajakPenjualanAtasBarangMewahB": 20,
                      "pphPajakPenjualanAtasBarangMewahB": 0,
                      "brutoPajakPenjualanAtasBarangMewahC": 0,
                      "tarifPajakPenjualanAtasBarangMewahC": 30,
                      "pphPajakPenjualanAtasBarangMewahC": 0,
                      "brutoPajakPenjualanAtasBarangMewahD": 0,
                      "tarifPajakPenjualanAtasBarangMewahD": 40,
                      "pphPajakPenjualanAtasBarangMewahD": 0,
                      "brutoPajakPenjualanAtasBarangMewahE": 0,
                      "tarifPajakPenjualanAtasBarangMewahE": 50,
                      "pphPajakPenjualanAtasBarangMewahE": 0,
                      "brutoPajakPenjualanAtasBarangMewahF": 0,
                      "tarifPajakPenjualanAtasBarangMewahF": 60,
                      "pphPajakPenjualanAtasBarangMewahF": 0,
                      "brutoPajakPenjualanAtasBarangMewahG": 0,
                      "tarifPajakPenjualanAtasBarangMewahG": 75,
                      "pphPajakPenjualanAtasBarangMewahG": 0,
                      "brutoPajakPenjualanAtasBarangMewahH": 0,
                      "tarifPajakPenjualanAtasBarangMewahH": 0,
                      "pphPajakPenjualanAtasBarangMewahH": 0,
                      "brutoApi": 0,
                      "tarifApi": tarifMap['API'].tarif,
                      "pphApi": 0,
                      "brutoNonApi": 0,
                      "tarifNonApi": tarifMap['NON-API'].tarif,
                      "pphNonApi": 0,
                    }
                    break;
                  case 'F113304':
                    formData = {
                      ...this.props.formData,
                      "brutoSemen": 0,
                      "tarifSemen": tarifMap['SEMEN'].tarif,
                      "pphSemen": 0,
                      "brutoKertas": 0,
                      "tarifKertas": tarifMap['KERTAS'].tarif,
                      "pphKertas": 0,
                      "brutoBaja": 0,
                      "tarifBaja": tarifMap['BAJA'].tarif,
                      "pphBaja": 0,
                      "brutoOtomotif": 0,
                      "tarifOtomotif": tarifMap['OTOMOTIF'].tarif,
                      "pphOtomotif": 0,
                      "brutoFarmasi": 0,
                      "tarifFarmasi": tarifMap['FARMASI'].tarif,
                      "pphFarmasi": 0,
                      "uraian62": "",
                      "bruto63": 0,
                      "tarif65": 0,
                      "pph66": 0,
                      "uraian72": "",
                      "bruto73": 0,
                      "tarif75": 0,
                      "pph76": 0,
                      "uraian82": "",
                      "bruto83": 0,
                      "tarif85": 1.5,
                      "pph86": 0,
                      "uraian92": "",
                      "bruto93": 0,
                      "tarif95": 1.5,
                      "pph96": 0,
                      "uraian102": "",
                      "bruto103": 0,
                      "tarif105": 0,
                      "pph106": 0,
                      "uraian112": "",
                      "bruto113": 0,
                      "tarif115": 0,
                      "pph116": 0,
                    }
                    break;
                  case 'F113304S':
                    formData = {
                      ...this.props.formData,
                      "brutoSemen": 0,
                      "tarifSemen": tarifMap['SEMEN'].tarif,
                      "pphSemen": 0,
                      "brutoKertas": 0,
                      "tarifKertas": tarifMap['KERTAS'].tarif,
                      "pphKertas": 0,
                      "brutoBaja": 0,
                      "tarifBaja": tarifMap['BAJA'].tarif,
                      "pphBaja": 0,
                      "brutoOtomotif": 0,
                      "tarifOtomotif": tarifMap['OTOMOTIF'].tarif,
                      "pphOtomotif": 0,
                      "brutoFinal": 0,
                      "tarifFinal": tarifMap['FINAL'].tarif,
                      "pphFinal": 0,
                      "brutoTidakFinal": 0,
                      "tarifTidakFinal": tarifMap['TIDAK-FINAL'].tarif,
                      "pphTidakFinal": 0,
                      "uraianMewah": "",
                      "brutoMewah": 0,
                      "tarifMewah": 0,
                      "pphMewah": 0,
                      "uraianSektorA": "",
                      "brutoSektorA": 0,
                      "tarifSektorA": 1.5,
                      "pphSektorA": 0,
                      "uraianSektorB": "",
                      "brutoSektorB": 0,
                      "tarifSektorB": 1.5,
                      "pphSektorB": 0,
                      "uraianLainyaA": "",
                      "brutoLainyaA": 0,
                      "tarifLainyaA": 0,
                      "pphLainyaA": 0,
                      "uraianLainyaB": "",
                      "brutoLainyaB": 0,
                      "tarifLainyaB": 0,
                      "pphLainyaB": 0,
                    }
                    break;
                  case 'F113304A':
                    formData = {
                      ...this.props.formData,
                      "brutoSemen": 0,
                      "tarifSemen": tarifMap['SEMEN'].tarif,
                      "pphSemen": 0,
                      "brutoKertas": 0,
                      "tarifKertas": tarifMap['KERTAS'].tarif,
                      "pphKertas": 0,
                      "brutoBaja": 0,
                      "tarifBaja": tarifMap['BAJA'].tarif,
                      "pphBaja": 0,
                      "brutoOtomotif": 0,
                      "tarifOtomotif": tarifMap['OTOMOTIF'].tarif,
                      "pphOtomotif": 0,
                      "uraian52": "",
                      "bruto53": 0,
                      "tarif55": 0,
                      "pph56": 0,
                      "uraian62": "",
                      "bruto63": 0,
                      "tarif65": 0,
                      "pph66": 0,
                      "uraianMewah": "",
                      "brutoMewah": 0,
                      "tarifMewah": 0,
                      "pphMewah": 0,
                      "uraianSektorA": "",
                      "brutoSektorA": 0,
                      "tarifSektorA": 1.5,
                      "pphSektorA": 0,
                      "uraianSektorB": "",
                      "brutoSektorB": 0,
                      "tarifSektorB": 1.5,
                      "pphSektorB": 0,
                      "brutoBumnTertentu": 0,
                      "tarifBumnTertentu": 1.5,
                      "pphBumnTertentu": 0,
                      "uraianLainya": "",
                      "brutoLainya": 0,
                      "tarifLainya": 0,
                      "pphLainya": 0,
                    }
                    break;
                  default:
                    break;
                  }

                  this.props.initialize(formData)
              }}
              component = {Searchfield}
              options   = {this.typeOptions}
              validate  = {validation.required}
            />
            }

            <br/>
          </div>


          {this.jenisFormSwithcer()}
        </Card>

        <br/>

        <Card>
          <div className='md-grid'>
            <Field
              label     = 'Jumlah Bruto'
              name      = 'jmlBruto'
              component = {TextfieldMask}
              className = 'md-text-right'
              disabled
              money = {','}
            />
            <Field
              label     = 'Jumlah PPh'
              name      = 'jmlPph'
              component = {TextfieldMask}
              className = 'md-text-right'
              disabled
              money = {','}
            />
            <Field
              label     = 'Terbilang'
              name      = 'terbilang'
              component = {Textfield}
              disabled
            />
          </div>
        </Card>

        <br />

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='C. WAJIB PAJAK ' />
          <Divider/>

          <div className="md-grid">
          <Field
            label     = 'NPWP'
            name      = 'npwpPemotong'
            className = "md-cell md-cell--6"
            disabled
            component  = {TextfieldMask}
            maskFormat = "##.###.###.#-###-###"
            mask       = "_"
            validate   = {validation.required}
          />
          <Field
            label     = 'Nama'
            name      = 'namaPemotong'
            className = "md-cell md-cell--6"
            disabled
            component = {Textfield}
            validate  = {validation.required}
          />
          <Field
            label     = 'Alamat'
            name      = 'alamatPemotong'
            className = "md-cell md-cell--12"
            disabled
            component = {Textfield}
            validate  = {validation.required}
          />


          </div>
        </Card>
      </div>
    )
  }

}
