import React, { Component } from 'react';
import { connect } from 'react-redux';

import FormDialog from './Master21Ptkp.dialog.view';
import Master21PtkpService from './Master21Ptkp.service';
import ListView from '../../../../../components/entity/listView';
import ColumnService from '../../../../../services/column.service';

@connect(Master21PtkpService.stateConnectSetting(), Master21PtkpService.actionConnectSetting())
export default class Master21PtkpView extends ListView {
  service=Master21PtkpService
  FormDialog=FormDialog

  columns=[
    {isSortable: true, label: "word.id", searchField: "id", value: function(d){ return d.id }, type: "func",  show:false, isSearchable:true},
    {isSortable: true, label: "word.start", searchField: "start", value: function(d){ return d.start }, type: "func",  show:true, isSearchable:true},
    {isSortable: true, label: "word.end", searchField: "end", value: function(d){ return d.end }, type: "func",  show:true, isSearchable:true},
    {isSortable: true, label: "word.pribadi", searchField: "pribadi", value: function(d){ return ColumnService.money(d.pribadi) }, type: "func",  show:true, isSearchable:true},
    {isSortable: true, label: "word.tanggungan", searchField: "tanggungan", value: function(d){ return ColumnService.money(d.tanggungan) }, type: "func",  show:true, isSearchable:true}
  ]   
}
