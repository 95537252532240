import React from 'react';
import {reduxForm} from 'redux-form';
import ImportDialogTemplateView from '../ImportEbilling/ImportEbilling.dialog.template.view';

@reduxForm({form: 'ImportEsspWpPeredaranTertentu-form', destroyOnUnmount: false})
export default class ImportEsspWpPeredaranTertentuDialog extends ImportDialogTemplateView {

  titleHeader() {
    return `entities.ImportEsspWpPeredaranTertentu.title`;
  }

}
