import ApiService from '../../../../../services/api.service';

class ALLBP21SSPService extends ApiService {
  name= 'ALL_BP_21_SSP';
  path= 'spt21/ssp/organization';
  constructor() {
    super();
    this.init()
  }
}

export default new ALLBP21SSPService();
