import Application_C             from './../../modules/Main/Modules/EbupotModule/Application/Application.service';
import User_C                    from './../../modules/Main/Modules/EbupotModule/User/User.service';
import Client_C                  from './../../modules/Main/Modules/EbupotModule/Client/Client.service';
import Organization_C            from './../../modules/Main/Modules/EbupotModule/Organization/Organization.service';
import ApplicationOrganization_C from './../../modules/Main/Modules/EbupotModule/ApplicationOrganization/ApplicationOrganization.service';
import Espt23_C                  from './../../modules/Main/Modules/EbupotModule/PPh/SPT23/SPT23.service';
import LogFile_C                 from './../../modules/Main/Modules/EbupotModule/LogFile/LogFile.service';
import LogFileCsv_C              from './../../modules/Main/Modules/EbupotModule/LogFileCsv/LogFileCsv.service';
import KodeBuktiPotong_C         from './../../modules/Main/Modules/EbupotModule/KodeBuktiPotong/KodeBuktiPotong.service';
import JenisDokumen_C            from './../../modules/Main/Modules/EbupotModule/JenisDokumen/JenisDokumen.service';
import KodeNegara_C              from './../../modules/Main/Modules/EbupotModule/KodeNegara/KodeNegara.service';
import Provinces_C               from './../../modules/Main/Modules/EbupotModule/Provinces/Provinces.service';
import Regencies_C               from './../../modules/Main/Modules/EbupotModule/Regencies/Regencies.service';
import Districts_C               from './../../modules/Main/Modules/EbupotModule/Districts/Districts.service';
import Villages_C                from './../../modules/Main/Modules/EbupotModule/Villages/Villages.service';
import Signer_C                  from './../../modules/Main/Modules/EbupotModule/Signer/Signer.service';
import AllEbupot26_C             from './../../modules/Main/Modules/EbupotModule/AllEbupot26/AllEbupot26.service';
import BP26_C                    from './../../modules/Main/Modules/EbupotModule/PPh/SPT23In/bp26/bp26.service';
import Bupot26_C                 from './../../modules/Main/Modules/EbupotModule/Bupot26/Bupot26.service';
import Bupot26In_C               from './../../modules/Main/Modules/EbupotModule/PPh/SPT23In/Bupot26In/Bupot26In.service';
import AllEbupot23_C             from './../../modules/Main/Modules/EbupotModule/AllEbupot23/AllEbupot23.service';
import BP23_C                    from './../../modules/Main/Modules/EbupotModule/PPh/SPT23In/bp23/bp23.service';
import Bupot23_C                 from './../../modules/Main/Modules/EbupotModule/Bupot23/Bupot23.service';
import Bupot23In_C               from './../../modules/Main/Modules/EbupotModule/PPh/SPT23In/Bupot23In/Bupot23In.service';
import PbkEbupot_C               from './../../modules/Main/Modules/EbupotModule/PPh/SPT23In/pbk/pbk.service';
import SspEbupot_C               from './../../modules/Main/Modules/EbupotModule/PPh/SPT23In/ssp/ssp.service';
import LogFileValidasi_C         from './../../modules/Main/Modules/EbupotModule/LogFileValidasi/LogFileValidasi.service';
import LogCheck_C                from './../../modules/Main/Modules/EbupotModule/LogCheck/LogCheck.service';
import LogSubmit_C               from './../../modules/Main/Modules/EbupotModule/LogSubmit/LogSubmit.service';
import LawanTransaksi23_C        from './../../modules/Main/Modules/EbupotModule/LawanTransaksi23/LawanTransaksi23.service';
import LawanTransaksi26_C        from './../../modules/Main/Modules/EbupotModule/LawanTransaksi26/LawanTransaksi26.service';
import Bpe_C                     from './../../modules/Main/Modules/EbupotModule/PPh/Bpe/Bpe.service';
import Bupot23History_C           from './../../modules/Main/Modules/EbupotModule/Bupot23History/Bupot23History.service';
import Bupot26History_C           from './../../modules/Main/Modules/EbupotModule/Bupot26History/Bupot26History.service';
import Bupot23Email_C           from './../../modules/Main/Modules/EbupotModule/Bupot23Email/Bupot23Email.service';
import Bupot26Email_C           from './../../modules/Main/Modules/EbupotModule/Bupot26Email/Bupot26Email.service';

const Application              = Object.assign([], Application_C.columns)
const User                     = Object.assign([], User_C.columns)
const Client                   = Object.assign([], Client_C.columns)
const Organization             = Object.assign([], Organization_C.columns)
const ApplicationOrganization  = Object.assign([], ApplicationOrganization_C.columns)
const Espt23                   = Object.assign([], Espt23_C.columns)
const LogFile                  = Object.assign([], LogFile_C.columns)
const LogFileCsv               = Object.assign([], LogFileCsv_C.columns)
const KodeBuktiPotong          = Object.assign([], KodeBuktiPotong_C.columns)
const JenisDokumen             = Object.assign([], JenisDokumen_C.columns)
const KodeNegara               = Object.assign([], KodeNegara_C.columns)
const Provinces                = Object.assign([], Provinces_C.columns)
const Regencies                = Object.assign([], Regencies_C.columns)
const Districts                = Object.assign([], Districts_C.columns)
const Villages                 = Object.assign([], Villages_C.columns)
const Signer                   = Object.assign([], Signer_C.columns)
const AllEbupot26              = Object.assign([], AllEbupot26_C.columns)
const BP26                     = Object.assign([], BP26_C.columns)
const Bupot26                  = Object.assign([], Bupot26_C.columns)
const Bupot26In                = Object.assign([], Bupot26In_C.columns)
const AllEbupot23              = Object.assign([], AllEbupot23_C.columns)
const BP23                     = Object.assign([], BP23_C.columns)
const Bupot23                  = Object.assign([], Bupot23_C.columns)
const Bupot23In                = Object.assign([], Bupot23In_C.columns)
const PbkEbupot                = Object.assign([], PbkEbupot_C.columns)
const SspEbupot                = Object.assign([], SspEbupot_C.columns)
const LogFileValidasi          = Object.assign([], LogFileValidasi_C.columns)
const LogCheck                 = Object.assign([], LogCheck_C.columns)
const LogSubmit                = Object.assign([], LogSubmit_C.columns)
const LawanTransaksi23         = Object.assign([], LawanTransaksi23_C.columns)
const LawanTransaksi26         = Object.assign([], LawanTransaksi26_C.columns)
const Bpe                      = Object.assign([], BP23_C.columns)
const Bupot23History           = Object.assign([], Bupot23History_C.columns)
const Bupot26History           = Object.assign([], Bupot26History_C.columns)
const Bupot23Email           = Object.assign([], Bupot23Email_C.columns)
const Bupot26Email           = Object.assign([], Bupot26Email_C.columns)

export default {
  Application               : Application,
  User                      : User,
  Client                    : Client,
  Organization              : Organization,
  ApplicationOrganization   : ApplicationOrganization,
  Espt23                    : Espt23,
  LogFile                   : LogFile,
  LogFileCsv                : LogFileCsv,
  KodeBuktiPotong           : KodeBuktiPotong,
  JenisDokumen              : JenisDokumen,
  KodeNegara                : KodeNegara,
  Provinces                 : Provinces,
  Regencies                 : Regencies,
  Districts                 : Districts,
  Villages                  : Villages,
  Signer                    : Signer,
  AllEbupot26               : AllEbupot26,
  BP26                      : BP26,
  Bupot26                   : Bupot26,
  Bupot26In                 : Bupot26In,
  Bupot26InPembetulan       : Bupot26In,
  Bupot26InEdit             : Bupot26In,
  AllEbupot23               : AllEbupot23,
  BP23                      : BP23,
  Bupot23                   : Bupot23,
  Bupot23In                 : Bupot23In,
  Bupot23InPembetulan       : Bupot23In,
  Bupot23InEdit             : Bupot23In,
  PbkEbupot                 : PbkEbupot,
  SspEbupot                 : SspEbupot,
  LogFileValidasi           : LogFileValidasi,
  LogCheck                  : LogCheck,
  LogSubmit                 : LogSubmit,
  LawanTransaksi23          : LawanTransaksi23,
  LawanTransaksi26          : LawanTransaksi26,
  Bpe                       : Bpe,
  Bupot23History            : Bupot23History,
  Bupot26History            : Bupot26History,
  Bupot23Email              : Bupot23Email,
  Bupot26Email              : Bupot26Email
}

// export default {
//     Application: [
//       {label: "word.id", searchField: "id", value: "id", isDefaultSort:false, isSortable:true, show:false, isSearchable:true},
//       {label: "word.name", searchField: "name", value: "name", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.description", searchField: "description", value: "description", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.createdBy", searchField: "createdBy", value: "createdBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.createdDate", searchField: "createdDate", value: (d)=> {
//         if(d){
//           return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
//         } else {
//           return "-"
//         }
//       }, type: 'func', isDefaultSort:true, isSortable:true, show:true, isSearchable:true},
//       {label: "word.lastModifiedBy", searchField: "lastModifiedBy", value: "lastModifiedBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.lastModifiedDate", searchField: "lastModifiedDate", value: (d)=> {
//         if(d){
//           return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
//         } else {
//           return "-"
//         }
//       }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//     ],
//     User: [
//         {label: "word.id", searchField: "id", value: "id", isDefaultSort:false, isSortable:true, show:false, isSearchable:true},
//         {label: "word.login", searchField: "login", value: "login", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.firstName", searchField: "firstName", value: "firstName", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.lastName", searchField: "lastName", value: "lastName", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.email", searchField: "email", value: "email", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.activated", searchField: "activated", value: "activated", type: 'boolean', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.langKey", searchField: "langKey", value: "langKey", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.createdBy", searchField: "createdBy", value: "createdBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.companyId", searchField: "companyId", value: "companyId", isDefaultSort:false, isSortable:true, show:false, isSearchable:true},
//         {label: "word.authority", searchField: "authority", value: "authority", isDefaultSort:false, isSortable:true, show:false, isSearchable:true},
//     ],
//     Client: [
//         {label: "word.id", searchField: "id", value: "id", isDefaultSort:false, isSortable:true, show:false, isSearchable:true},
//         {label: "word.clientId", searchField: "clientId", value: "clientId", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.clientSecret", searchField: "clientSecret", value: "clientSecret", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.createdBy", searchField: "createdBy", value: "createdBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.createdDate", searchField: "createdDate", value: "createdDate", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.lastModifiedBy", searchField: "lastModifiedBy", value: "lastModifiedBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.lastModifiedDate", searchField: "lastModifiedDate", value: "lastModifiedDate", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//     ],
//     Organization: [
//       {label: "word.id", searchField: "id", value: "id", isDefaultSort:false, isSortable:true, show:false, isSearchable:true},
//       {label: "word.name", searchField: "nama", value: "nama", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       // {isSortable:true, label: "word.active", value: "active", type: 'boolean', onClick:() => {}, show:true, isSearchable:true},
//       {label: "word.npwp", searchField: "npwp", value: "npwp", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.email", searchField: "email", value: "email", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.phone", searchField: "noTelp", value: "noTelp", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.city", searchField: "kota", value: "kota", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.postalCode", searchField: "kodePos", value: "kodePos", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.address", searchField: "alamat", value: "alamat", isDefaultSort:false, isSortable:true, show:true, isSearchable:false},
//       {label: "word.createdBy", searchField: "createdBy", value: "createdBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:false},
//       {label: "word.createdDate", searchField: "createdDate", type: 'date', value: "createdDate", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.lastModifiedBy", searchField: "lastModifiedBy", value: "lastModifiedBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.lastModifiedDate", searchField: "lastModifiedDate", type: 'date', value: "lastModifiedDate", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//     ],
//     ApplicationOrganization: [
//         {label: "word.id", searchField: "id", value: "id", isDefaultSort:false, isSortable:true, show:false, isSearchable:true},
//         {label: "word.name", searchField: "nama", value: "organization.nama", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {isSortable:true, searchField: "active", label: "word.active", value: "organization.active", type: 'boolean', onClick:() => {}, show:true, isSearchable:true},
//         {label: "word.npwp", searchField: "npwp", value: "organization.npwp", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.email", searchField: "email", value: "organization.email", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.phone", searchField: "noTelp", value: "organization.noTelp", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.city", searchField: "kota", value: "organization.kota", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.postalCode", searchField: "kodePos", value: "organization.kodePos", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.address", searchField: "alamat", value: "organization.alamat", isDefaultSort:false, isSortable:true, show:true, isSearchable:false},
//         {label: "word.createdBy", searchField: "createdBy", value: "createdBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.createdDate", searchField: "createdDate", value: (d)=> {
//           if(d){
//             return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
//           } else {
//             return "-"
//           }
//         }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.lastModifiedBy", searchField: "lastModifiedBy", value: "lastModifiedBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.lastModifiedDate", searchField: "lastModifiedDate", value: (d)=> {
//           if(d){
//             return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
//           } else {
//             return "-"
//           }
//         }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//     ],
//     Espt23: [
//         {isSortable: true, label: "word.name", searchField: "nama", value: "nama", show:true, isSearchable:true},
//         {isSortable: true, label: "word.aliasName", searchField: "namaAlias", value: "namaAlias", show:true, isSearchable:true},
//         {isSortable: true, label: "word.npwp", searchField: "npwp", value: "npwp", show:true, isSearchable:true},
//         {isSortable: true, label: "word.month", searchField: "masa", value: "masa", show:true, isSearchable:true, type: 'number'},
//         {isSortable: true, label: "word.year",  searchField: "tahun", value: (d)=> d.tahun, show:true, isSearchable:true, type: 'func'},
//         {isSortable: true, label: "word.pembetulan", searchField: "pembetulan", value: "pembetulan", show:true, isSearchable:true, type: 'number'},
//         {
//           searchField: "status",
//           label: "word.statusSpt",
//           type: 'func',
//           value: (d)=> {
//             var style = {
//               background: 'grey'
//             }
//             switch (d.status) {
//               case 'PROCESS':
//                 style.background = '#2196F3'
//                 break;
//               case 'FINISH':
//                 style.background = '#4CAF50'
//                 break;
//               default:
//                 break;
//             }
//             return <Chip label={d.status} style={style} />
//           },
//           className: "mpk-font-size-S", show:true, isSearchable:true},
//         {isSortable: true, label: "word.isLapor", searchField: 'isLapor', value: (d)=> {
//           if(d.isLapor) {
//             return <Chip label="SUDAH" style={{backgroundColor: '#4CAF50'}} />
//           } else {
//             return <Chip label="BELUM" style={{backgroundColor: '#FF9800'}} />
//           }
//         }, type:'func', show:true, isSearchable:true},
//         {isSortable: true, label: "word.isBpe", searchField: 'isBpe', value: (d)=> {
//           if(d.isBpe) {
//             return <Chip label="SUDAH" style={{backgroundColor: '#4CAF50'}} />
//           } else {
//             return <Chip label="BELUM" style={{backgroundColor: '#FF9800'}} />
//           }
//         }, type:'func', show:true, isSearchable:true},
//         {isSortable: true, label: "word.currentState", searchField: "currentState",  value: "currentState", show:true, isSearchable:true},
//         {label: "word.createdBy", searchField: "createdBy", value: "createdBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.createdDate", searchField: "createdDate", value: (d)=> {
//           if(d){
//             return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
//           } else {
//             return "-"
//           }
//         }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.lastModifiedBy", searchField: "lastModifiedBy", value: "lastModifiedBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.lastModifiedDate", searchField: "lastModifiedDate", value: (d)=> {
//           if(d){
//             return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
//           } else {
//             return "-"
//           }
//         }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//     ],
//     LogFile: [
//         {label: "word.id", searchField: "id", value: "id", isDefaultSort:false, isSortable:true, show:false, isSearchable:true},
//         {label: "word.originalFilename", searchField: "originalFilename", value: "originalFilename", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.contentType", searchField: "contentType", value: "contentType", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.md5", searchField: "md5", value: "md5", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.fileSize", searchField: "fileSize", value: "fileSize", type: 'number', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.success", searchField: "success", value: "success", type: 'boolean', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.status", searchField: "status", value: "status", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.message", searchField: "message", value: "message", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.createdBy", searchField: "createdBy", value: "createdBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.createdDate", searchField: "createdDate", value: (d)=> {
//         if(d){
//             return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
//         } else {
//             return "-"
//         }
//         }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.lastModifiedBy", searchField: "lastModifiedBy", value: "lastModifiedBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.lastModifiedDate", searchField: "lastModifiedDate", value: (d)=> {
//         if(d){
//             return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
//         } else {
//             return "-"
//         }
//         }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//     ],
//     LogFileCsv: [
//         {label: "word.id", searchField: "id", value: "id", isDefaultSort:false, isSortable:true, show:false, isSearchable:true},
//         {label: "word.originalFilename", searchField: "originalFilename", value: "originalFilename", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.contentType", searchField: "contentType", value: "contentType", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.md5", searchField: "md5", value: "md5", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.fileSize", searchField: "fileSize", value: "fileSize", type: 'number', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.success", searchField: "success", value: "success", type: 'boolean', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.status", searchField: "status", value: "status", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.message", searchField: "message", value: "message", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.createdBy", searchField: "createdBy", value: "createdBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.createdDate", searchField: "createdDate", value: (d)=> {
//           if(d){
//             return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
//           } else {
//             return "-"
//           }
//         }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.lastModifiedBy", searchField: "lastModifiedBy", value: "lastModifiedBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.lastModifiedDate", searchField: "lastModifiedDate", value: (d)=> {
//           if(d){
//             return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
//           } else {
//             return "-"
//           }
//         }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//     ],
//     KodeBuktiPotong: [
//         {label: "word.id", searchField: "id", value: "id", isDefaultSort:false, isSortable:true, show:false, isSearchable:true},
//         {label: "word.kode", searchField: "kode", value: "kode", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.name", searchField: "name", value: "name", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//     ],
//     JenisDokumen: [
//         {label: "word.id", searchField: "id", value: "id", isDefaultSort:false, isSortable:true, show:false, isSearchable:true},
//         {label: "word.kode", searchField: "kode", value: "kode", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.name", searchField: "name", value: "name", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//     ],
//     KodeNegara: [
//         {label: "word.id", searchField: "id", value: "id", isDefaultSort:false, isSortable:true, show:false, isSearchable:true},
//         {label: "word.kode", searchField: "kode", value: "kode", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.name", searchField: "name", value: "name", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//     ],
//     Provinces: [
//         {label: "word.id", searchField: "id", value: "id", isDefaultSort:false, isSortable:true, show:false, isSearchable:true},
//         {label: "word.name", searchField: "name", value: "name", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//     ],
//     Regencies: [
//         {label: "word.id", searchField: "id", value: "id", isDefaultSort:false, isSortable:true, show:false, isSearchable:true},
//         {label: "word.provinceId", searchField: "provinceId", value: "provinceId", isDefaultSort:false, isSortable:true, show:false, isSearchable:true},
//         {label: "word.name", searchField: "name", value: "name", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//     ],
//     Districts: [
//         {label: "word.id", searchField: "id", value: "id", isDefaultSort:false, isSortable:true, show:false, isSearchable:true},
//         {label: "word.regencyId", searchField: "regencyId", value: "regencyId", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.name", searchField: "name", value: "name", isDefaultSort:false, isSortable:true, show:true, isSearchable:true}
//     ],
//     Villages: [
//         {label: "word.id", searchField: "id", value: "id", isDefaultSort:false, isSortable:true, show:false, isSearchable:true},
//         {label: "word.districtId", searchField: "districtId", value: "districtId", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.name", searchField: "name", value: "name", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//     ],
//     Signer: [
//         {label: "word.id", searchField: "id", value: "id", isDefaultSort:false, isSortable:true, show:false, isSearchable:true},
//         {label: "word.npwpPenandaTangan", searchField: "npwpPenandaTangan", value: "npwpPenandaTangan", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.namaPenandaTangan", searchField: "namaPenandaTangan", value: "namaPenandaTangan", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.signAs", searchField: "signAs", value: "signAs", type: 'boolean', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.active", searchField: "active", value: "active", type: 'boolean', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.createdBy", searchField: "createdBy", value: "createdBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.createdDate", searchField: "createdDate", value: (d)=> {
//           if(d){
//             return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
//           } else {
//             return "-"
//           }
//         }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.lastModifiedBy", searchField: "lastModifiedBy", value: "lastModifiedBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.lastModifiedDate", searchField: "lastModifiedDate", value: (d)=> {
//           if(d){
//             return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
//           } else {
//             return "-"
//           }
//         }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//     ],
//     AllEbupot26: [
//         {label: "word.id", searchField: "id", value: "id", isDefaultSort:false, isSortable:true, show:false, isSearchable:true},
//         {label: "word.tin", searchField: "tinDipotong", value: "bagA.tin", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.nama", searchField: "namaDipotong", value: "bagA.nama", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.email", searchField: "emailDipotong", value: "bagA.email", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.bruto", searchField: "bruto", type: "number", value: "bagB.bruto", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.pph", searchField: "pph", type: "number", value: "bagB.pph", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.createdBy", searchField: "createdBy", value: "createdBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.createdDate", searchField: "createdDate", value: (d)=> {
//           if(d){
//             return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
//           } else {
//             return "-"
//           }
//         }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.lastModifiedBy", searchField: "lastModifiedBy", value: "lastModifiedBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.lastModifiedDate", searchField: "lastModifiedDate", value: (d)=> {
//           if(d){
//             return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
//           } else {
//             return "-"
//           }
//         }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//     ],
//     BP26: [
//         {label: "word.id", searchField: "id", value: "id", isDefaultSort:false, isSortable:true, show:false, isSearchable:true},
//         {label: "word.tin", searchField: "tinDipotong", value: "bagA.tin", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.nama", searchField: "namaDipotong", value: "bagA.nama", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.email", searchField: "emailDipotong", value: "bagA.email", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.bruto", searchField: "bruto", type: "number", value: "bagB.bruto", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.pph", searchField: "pph", type: "number", value: "bagB.pph", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.createdBy", searchField: "createdBy", value: "createdBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.createdDate", searchField: "createdDate", value: (d)=> {
//           if(d){
//             return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
//           } else {
//             return "-"
//           }
//         }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.lastModifiedBy", searchField: "lastModifiedBy", value: "lastModifiedBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.lastModifiedDate", searchField: "lastModifiedDate", value: (d)=> {
//           if(d){
//             return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
//           } else {
//             return "-"
//           }
//         }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//     ],
//     Bupot26: [
//       {label: "word.id", searchField: "id", value: "id", isDefaultSort:false, isSortable:true, show:false, isSearchable:true},
//       {label: "word.tin", searchField: "tin", value: "bagA.tin", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.nama", searchField: "nama", value: "bagA.nama", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.email", searchField: "email", value: "bagA.email", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.bruto", searchField: "bruto", type: "number", value: "bagB.bruto", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.pph", searchField: "pph", type: "number", value: "bagB.pph", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.createdBy", searchField: "createdBy", value: "createdBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.createdDate", searchField: "createdDate", value: (d)=> {
//         if(d){
//           return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
//         } else {
//           return "-"
//         }
//       }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.lastModifiedBy", searchField: "lastModifiedBy", value: "lastModifiedBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.lastModifiedDate", searchField: "lastModifiedDate", value: (d)=> {
//         if(d){
//           return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
//         } else {
//           return "-"
//         }
//       }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//     ],
//     Bupot26In: [
//       {label: "word.id", searchField: "id", value: "id", isDefaultSort:false, isSortable:true, show:false, isSearchable:true},
//       {label: "word.tin", searchField: "tin", value: "bagA.tin", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.nama", searchField: "nama", value: "bagA.nama", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.email", searchField: "email", value: "bagA.email", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.bruto", searchField: "bruto", type: "number", value: "bagB.bruto", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.pph", searchField: "pph", type: "number", value: "bagB.pph", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.createdBy", searchField: "createdBy", value: "createdBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.createdDate", searchField: "createdDate", value: (d)=> {
//         if(d){
//           return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
//         } else {
//           return "-"
//         }
//       }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.lastModifiedBy", searchField: "lastModifiedBy", value: "lastModifiedBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.lastModifiedDate", searchField: "lastModifiedDate", value: (d)=> {
//         if(d){
//           return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
//         } else {
//           return "-"
//         }
//       }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//     ],
//     AllEbupot23: [
//         {label: "word.id", searchField: "id", value: "id", isDefaultSort:false, isSortable:true, show:false, isSearchable:true},
//         {label: "word.npwp", searchField: "npwpDipotong", value: "bagA.npwp", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.nik", searchField: "nikDipotong", value: "bagA.nik", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.nama", searchField: "namaDipotong", value: "bagA.nama", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.email", searchField: "emailDipotong", value: "bagA.email", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.createdBy", searchField: "createdBy", value: "createdBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.createdDate", searchField: "createdDate", value: (d)=> {
//           if(d){
//             return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
//           } else {
//             return "-"
//           }
//         }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.lastModifiedBy", searchField: "lastModifiedBy", value: "lastModifiedBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.lastModifiedDate", searchField: "lastModifiedDate", value: (d)=> {
//           if(d){
//             return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
//           } else {
//             return "-"
//           }
//         }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//     ],
//     BP23: [
//         {label: "word.id", searchField: "id", value: "id", isDefaultSort:false, isSortable:true, show:false, isSearchable:true},
//         {label: "word.npwp", searchField: "npwpDipotong", value: "bagA.npwp", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.nik", searchField: "nikDipotong", value: "bagA.nik", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.nama", searchField: "namaDipotong", value: "bagA.nama", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.email", searchField: "emailDipotong", value: "bagA.email", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.createdBy", searchField: "createdBy", value: "createdBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.createdDate", searchField: "createdDate", value: (d)=> {
//           if(d){
//             return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
//           } else {
//             return "-"
//           }
//         }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.lastModifiedBy", searchField: "lastModifiedBy", value: "lastModifiedBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//         {label: "word.lastModifiedDate", searchField: "lastModifiedDate", value: (d)=> {
//           if(d){
//             return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
//           } else {
//             return "-"
//           }
//         }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//     ],
//     Bupot23: [
//       {label: "word.id", searchField: "id", value: "id", isDefaultSort:false, isSortable:true, show:false, isSearchable:true},
//       {label: "word.npwp", searchField: "npwp", value: "bagA.npwp", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.nik", searchField: "nik", value: "bagA.nik", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.nama", searchField: "nama", value: "bagA.nama", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.email", searchField: "email", value: "bagA.email", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.createdBy", searchField: "createdBy", value: "createdBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.createdDate", searchField: "createdDate", value: (d)=> {
//         if(d){
//           return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
//         } else {
//           return "-"
//         }
//       }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.lastModifiedBy", searchField: "lastModifiedBy", value: "lastModifiedBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.lastModifiedDate", searchField: "lastModifiedDate", value: (d)=> {
//         if(d){
//           return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
//         } else {
//           return "-"
//         }
//       }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//     ],
//     Bupot23In: [
//       {label: "word.id", searchField: "id", value: "id", isDefaultSort:false, isSortable:true, show:false, isSearchable:true},
//       {label: "word.npwp", searchField: "npwp", value: "bagA.npwp", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.nik", searchField: "nik", value: "bagA.nik", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.nama", searchField: "nama", value: "bagA.nama", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.email", searchField: "email", value: "bagA.email", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.createdBy", searchField: "createdBy", value: "createdBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.createdDate", searchField: "createdDate", value: (d)=> {
//         if(d){
//           return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
//         } else {
//           return "-"
//         }
//       }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.lastModifiedBy", searchField: "lastModifiedBy", value: "lastModifiedBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.lastModifiedDate", searchField: "lastModifiedDate", value: (d)=> {
//         if(d){
//           return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
//         } else {
//           return "-"
//         }
//       }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//     ],
//     PbkEbupot: [
//       {isSortable: true, searchField: 'date', label: "word.date",  value: "date", show:true, isSearchable:true},
//       {isSortable: true, searchField: 'noPbk', label: "word.noPbk",  value: "noPbk", show:true, isSearchable:true},
//       {isSortable: true, searchField: 'total', label: "word.total", value: "total", show:true, isSearchable:true, type: 'number'},
//       {width: 150, isSortable: true, searchField: 'createdBy', label: "word.createdBy",  value:"createdBy", show:true, isSearchable:true},
//       {isSortable: true, searchField: 'createdDate', label: "word.createdDate",  value:"createdDate", type:"date", show:true, isSearchable:true},
//     ],
//     SspEbupot: [
//       // {isSortable: true, label: "word.jenisSetoran", value: "kjs.code", show:true, isSearchable:true},
//       {isSortable: true, searchField: 'date', label: "word.date",  value: "date", show:true, isSearchable:true},
//       {isSortable: true, searchField: 'ntpn', label: "word.ntpn",  value: "ntpn", show:true, isSearchable:true},
//       {isSortable: true, searchField: 'total', label: "word.total", value: "total", show:true, isSearchable:true, type: 'number'},
//       {isSortable: true, searchField: 'createdBy', label: "word.createdBy",  value:"createdBy", show:true, isSearchable:true},
//       {isSortable: true, searchField: 'createdDate', label: "word.createdDate",  value:"createdDate", type:"date", show:true, isSearchable:true},
//     ],
//     LogFileValidasi: [
//       {isSortable: true, searchField: 'line', label: "word.line", show:true, isSearchable:true},
//       {isSortable: true, searchField: 'message', label: "word.message", show:true, isSearchable:true},
//       {isSortable: true, searchField: 'sheet', label: "word.sheet", show:true, isSearchable:true}
//     ],
//     LogCheck: [
//       {label: "word.success", searchField: "success", value: "success", type: 'boolean', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.status", searchField: "status", value: "status", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.originalFilename", searchField: "originalFilename", value: "originalFilename", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.contentType", searchField: "contentType", value: "contentType", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.md5", searchField: "md5", value: "md5", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.fileSize", searchField: "fileSize", value: "fileSize", type: 'number', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.message", searchField: "message", value: "message", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.createdBy", searchField: "createdBy", value: "createdBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.createdDate", searchField: "createdDate", value: (d)=> {
//         if(d){
//           return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
//         } else {
//           return "-"
//         }
//       }, type: 'func', isDefaultSort:true, isSortable:true, show:true, isSearchable:true},
//       {label: "word.lastModifiedBy", searchField: "lastModifiedBy", value: "lastModifiedBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.lastModifiedDate", searchField: "lastModifiedDate", value: (d)=> {
//         if(d){
//           return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
//         } else {
//           return "-"
//         }
//       }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//     ],
//     LogSubmit: [
//       {label: "word.success", searchField: "success", value: "success", type: 'boolean', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.status", searchField: "status", value: "status", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.originalFilename", searchField: "originalFilename", value: "originalFilename", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.contentType", searchField: "contentType", value: "contentType", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.md5", searchField: "md5", value: "md5", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.fileSize", searchField: "fileSize", value: "fileSize", type: 'number', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.message", searchField: "message", value: "message", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.createdBy", searchField: "createdBy", value: "createdBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.createdDate", searchField: "createdDate", value: (d)=> {
//         if(d){
//           return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
//         } else {
//           return "-"
//         }
//       }, type: 'func', isDefaultSort:true, isSortable:true, show:true, isSearchable:true},
//       {label: "word.lastModifiedBy", searchField: "lastModifiedBy", value: "lastModifiedBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.lastModifiedDate", searchField: "lastModifiedDate", value: (d)=> {
//         if(d){
//           return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
//         } else {
//           return "-"
//         }
//       }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//     ],
//     LawanTransaksi23: [
//       {label: "word.id", searchField: "id", value: "id", isDefaultSort:false, isSortable:true, show:false, isSearchable:true},
//       {label: "word.npwpProfile", searchField: "npwpProfile", value: "npwpProfile", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.nama", searchField: "nama", value: "nama", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.nik", searchField: "nik", value: "nik", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.npwp", searchField: "npwp", value: "npwp", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.email", searchField: "email", value: "email", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.alamat", searchField: "alamat", value: "alamat", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.provinsi", searchField: "provinsi", value: "provinsi", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.kabupaten", searchField: "kabupaten", value: "kabupaten", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.kecamatan", searchField: "kecamatan", value: "kecamatan", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.kelurahan", searchField: "kelurahan", value: "kelurahan", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.kodePos", searchField: "kodePos", value: "kodePos", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.noTelp", searchField: "noTelp", value: "noTelp", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {width: 150, label: "word.createdBy", searchField: "createdBy", value: "createdBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.createdDate", searchField: "createdDate", value: (d)=> {
//         if(d){
//           return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
//         } else {
//           return "-"
//         }
//       }, type: 'func', isDefaultSort:true, isSortable:true, show:true, isSearchable:true},
//       {width: 150, label: "word.lastModifiedBy", searchField: "lastModifiedBy", value: "lastModifiedBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.lastModifiedDate", searchField: "lastModifiedDate", value: (d)=> {
//         if(d){
//           return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
//         } else {
//           return "-"
//         }
//       }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//     ],
//     LawanTransaksi26: [
//       {label: "word.id", searchField: "id", value: "id", isDefaultSort:false, isSortable:true, show:false, isSearchable:true},
//       {label: "word.npwpProfile", searchField: "npwpProfile", value: "npwpProfile", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.nama", searchField: "nama", value: "nama", type: "boolean", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.dob", searchField: "dob", value: "dob", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.email", searchField: "email", value: "email", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.tin", searchField: "tin", value: "tin", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.noKitas", searchField: "noKitas", value: "noKitas", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.noPassport", searchField: "noPassport", value: "noPassport", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.negara", searchField: "negara", value: "negara", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.alamat", searchField: "alamat", value: "alamat", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {width: 150, label: "word.createdBy", searchField: "createdBy", value: "createdBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.createdDate", searchField: "createdDate", value: (d)=> {
//         if(d){
//           return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
//         } else {
//           return "-"
//         }
//       }, type: 'func', isDefaultSort:true, isSortable:true, show:true, isSearchable:true},
//       {width: 150, label: "word.lastModifiedBy", searchField: "lastModifiedBy", value: "lastModifiedBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//       {label: "word.lastModifiedDate", searchField: "lastModifiedDate", value: (d)=> {
//         if(d){
//           return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
//         } else {
//           return "-"
//         }
//       }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
//     ]
// }
