import ApiService from '../../../../services/api.service';

class SignInLogService extends ApiService {
  name= 'SignInLog';
  path= 'log/sign-in';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api={
  }
}

export default new SignInLogService();
