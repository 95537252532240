import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm, getFormValues} from 'redux-form';
import moment from 'moment';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  CardActions,
  Button,
  LinearProgress
} from 'react-md';

import FormView from '../../../../../../components/entity/form.view';
import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Checkbox} from '../../../../../../components/form';
import UtilService from '../../../../../../services/utils.service'
import NpwpService from '../../../NpwpService';
import FormViewSpt from '../../../../../../components/entity/form.view.spt';
import terbilangService, {terbilangAsync} from '../../../../../../services/terbilang.service';
import BP15PPHPasal24Service from './BP15PPhPasal24.service';
import LawanTransaksiDialog from '../../../../../../components/entity/LawanTransaksi.dialog';
import MasterLawanTransaksi15Service from '../../../../Master/MasterLawanTransaksi15/MasterLawanTransaksi15.service';


@reduxForm({form: 'BP15PPHPasal24Form', destroyOnUnmount: true})
@connect((state) => ({
  access: state.entity.access.api.find,
  organization: state.auth.currentOrganization,
  spt: state.auth.currentSPT,
  formData:getFormValues('BP15PPHPasal24Form')(state)
}))
export default class BP15PPHPasal24Form extends FormViewSpt {
  service=BP15PPHPasal24Service
  viewType =2;

  initialData={
    bagA: {},
    bagB: {bruto: 0},
    bagC: {}
  }

  typingObj=null;
  typingTimeout=1000;

  constructor(props) {
    super(props);

    this.state= {
      ...this.state,
      showCalculateProgress: false,
      lkDialog: false
    }
  }

  async beforeSave(value) {

    return true;
  }

  async initData() {
    let organization = this.props.organization

    if (!organization) {
      organization = this.props.spt.data.organization
    } else {
      if (!organization.npwp) {
        organization = this.props.spt.data.organization
      }
    }

    if(this.props.match.params.id == 'new') {
      let signer = {}; //await SPT21SignerService.api.getSigner(this.props.spt.data.id);

      this.initialData = {
        ...this.initialData,
        namaPemotong   : organization.name,
        npwpPemotong   : organization.npwp,
        alamatPemotong : organization.address,
        tanggal: new Date(),
        spt: this.props.spt.data,
      }
      this.props.initialize(this.initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id);

      res.data.namaPemotong = organization.name
      res.data.npwpPemotong = organization.npwp
      res.data.alamatPemotong = organization.address
      res.data.spt = this.props.spt.data

      this.props.initialize(res.data);
    }
  }

  async calculate(e, v) {
    if(this.typingObj) clearTimeout(this.typingObj);

    this.typingObj = setTimeout(async ()=> {
      if(v) {
        var terbilangRes = '';
        try {
          terbilangRes = await terbilangAsync(v);
        } catch (error) {}

        this.props.change('terbilang', terbilangRes);
      }
    }, this.typingTimeout);
  }

  formView() {
    var formData = {bagA: {}};
    if(this.props.formData && this.props.formData.values) formData = this.props.formData.values
    return (
      <div>
        <Card>
          <div className="md-grid">
            <Field
              label='Negara Sumber Penghasilan'
              name='negaraSumberPenghasilan'
              className="md-cell md-cell--6"
              component={Textfield}
              validate={validation.required}
            />
            <Field
              label='Jumlah Bruto'
              name='bruto'
              className="md-cell md-cell--6"
              component={TextfieldMask}
              money={','}
            />
            <Field
              label='Jumlah Pajak Terutang Yang Dibayar di LN (Rp)'
              name='pphDibayarLuarNegeri'
              className="md-cell md-cell--6"
              component={TextfieldMask}
              money={','}
            />
            <Field
              label='PPh Pasal 24 Yang Dapat Diperhitungkan (Rp)'
              name='pphDiperhitungkan'
              className="md-cell md-cell--6"
              component={TextfieldMask}
              money={','}
              onChange  = {(e, v) => this.calculate(e, v)}
            />
            <Field
              label='Terbilang'
              name='terbilang'
              className="md-cell md-cell--12"
              component={Textfield}
              disabled
              validate={validation.required}
            />
            <Field
              label='Keterangan'
              name='keterangan'
              className="md-cell md-cell--12"
              component={Textfield}
            />
            {/* <Field
              label='Referensi'
              name='referensi'
              id="BP21FForm-referensi"
              className="md-cell md-cell--12"
              component={Textfield}
            /> */}
          </div>
        </Card>
      </div>
    )
  }

}
