import React, { Component } from 'react';
import { Grid, Cell } from 'react-md';
import { Field, reduxForm , propTypes, formValueSelector } from 'redux-form';
import { validation, Textfield, TextfieldMask, Datepicker, convert, Switch, Searchfield } from '../../../../components/form';
import DialogView from '../../../../components/entity/dialog.view';
import PengaturanNoBPService from './PengaturanNoBP.service';

@reduxForm({form: 'PengaturanNoBP', destroyOnUnmount: false})
export default class PengaturanNoBpDialog extends DialogView {
  service=PengaturanNoBPService
  initialData = {
  }

  async handleSave(callback, value) {
    var newVal = this.transform(value);
    try {
      var url = `/spt${this.props.match.params.pasal.toLowerCase()}/nobupot`;

      await this.service.http.put(this.service.apiLocation+url, newVal);
      callback()
      this.props.onSuccess()
      this.props.onClose()
    } catch(e) {
      var msg = e.message;
      if(e.response) msg = e.response.data.message
      callback(true, msg)
    }
  }

  formView() {
    if(this.props.match.params.pasal === '21') {
      return (
        <Grid style={{padding:'none'}}>
          <Field
            label='BP A1'
            name='bpA1'
            className="md-cell md-cell--12"
            component={Textfield}
            validate={validation.required}
          />
          <Field
            label='BP A2'
            name='bpA2'
            className="md-cell md-cell--12"
            component={Textfield}
            validate={validation.required}
          />
          <Field
            label='BP Final'
            name='bpFinal'
            className="md-cell md-cell--12"
            component={Textfield}
            validate={validation.required}
          />
          <Field
            label='BP Tidak Final'
            name='bpTidakFinal'
            className="md-cell md-cell--12"
            component={Textfield}
            validate={validation.required}
          />
        </Grid>
      )
    } else {
      return (
        <Grid style={{padding:'none'}}>
          <Field
            label='Prefix'
            name='prefix'
            className="md-cell md-cell--12"
            component={Textfield}
            validate={validation.required}
          />
          <Field
            label='Suffix'
            name='suffix'
            className="md-cell md-cell--12"
            component={Textfield}
            validate={validation.required}
          />
          <Field
            label='Inc'
            name='inc'
            className="md-cell md-cell--12"
            component={Switch}
            // validate={validation.required}
          />
          <Field
            label='Mode'
            name='mode'
            className="md-cell md-cell--12"
            component={Searchfield}
            options={[
              {id: 'PREF_SUF', name: 'PREF SUF'},
              {id: 'SUF_PREF', name: 'SUF_PREF'}
            ]}
            validate={validation.required}
          />
        </Grid>
      )
    }

  }
}
