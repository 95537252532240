import ApiService from '../../../../../services/api.service';

class Master21BiayaJabatanService extends ApiService {
  name= 'Master21BiayaJabatan';
  path= 'master/tarif-21-biaya-jabatan';
  constructor() {
    super();
    this.init()
  }
}


export default new Master21BiayaJabatanService();
