import ApiService from '../../../../services/api.service';

class MasterPtkpService extends ApiService {
  name= 'masterPtkp';
  path= 'master/ptkp';

  constructor() {
    super();
    this.init()
  }
}


export default new MasterPtkpService();
