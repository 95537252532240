import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  Tab,
  Tabs,
  SelectionControlGroup,
  Checkbox,
  Button
} from 'react-md';

import FormView from '../../../../components/entity/form.view';
import FormViewSpt from '../../../../components/entity/form.view.spt';
import {validation, Textfield, Searchfield, Switch, Datepicker, TextfieldMask, convert} from '../../../../components/form';
import { SearchField } from 'react-mpk';
import SettingEmailContentService from './SettingEmailContent.service';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import iziToast from 'izitoast';

@reduxForm({form: 'SettingEmailContent_Form', destroyOnUnmount: true})
@connect((state) => ({
  access: state.entity.access.api.find,
  organization: state.auth.currentOrganization,
  spt: state.auth.currentSPT,
  formData: state.form.SettingEmailContent_Form,
  user: state.auth.user
}))
export default class SettingEmailContentForm extends FormView {
  service=SettingEmailContentService
  viewType =2;

  constructor(props){
    super(props)
    this.state = {
      ...this.state
    }

    this.state.editorState = EditorState.createEmpty()
  }

  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;
    var companyId = this.props.match.params.companyId

    if(companyId){
      return (
        <div>
          <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>Save</Button>
        </div>
      )
    } else {
      return (
        <div>
          <Button secondary flat onClick={()=> {this.props.history.goBack()}} style={{marginRight: 16}}>Back</Button>
          <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>Save</Button>
        </div>
      )
    }
  }

  async initData() {
    var companyId = this.props.match.params.companyId
    if(!companyId){
      // Try in url
      var paramInurl = this.props.location.pathname.split("/")
      if(paramInurl[2]){
        companyId = paramInurl[2]
      }
    }

    if(this.props.user.company && this.props.user.company.id) companyId = this.props.user.company.id;
    try {
      let res = await this.service.api.getByCompany(companyId);

      const contentBlock = htmlToDraft(res.data.body);

      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);

        const editorState = EditorState.createWithContent(contentState);

        this.state = {
          editorState,
        };
      }

      this.props.initialize(res.data);
    } catch (e){
      this.toast.warning({
        title: e.name,
        message: e.message
      })
    }
  }

  async onEditorStateChange(editorState) {
    let body = draftToHtml(convertToRaw(editorState.getCurrentContent()))

    this.props.formData.values.body = body

    this.setState({
      editorState,
    });
  }

  async handleSave(value) {
    try {
      let res = await this.service.api.updateByCompany(value)
    } catch (e){
      this.toast.warning({
        title: e.name,
        message: e.message
      })
    }
  }

  formView() {
    const { editorState } = this.state;

    return (
      <div>
        <Card>
          <div className='md-grid'>
            <div className="md-cell md-cell--12">
              <Field
                label='Subject'
                name='subject'
                component={Textfield}
                validate={validation.required}
              />
              <Editor
                label='Body'
                name='body'
                editorState={editorState}
                onEditorStateChange={this.onEditorStateChange.bind(this)}
              />
              <strong>*Available variable : #{'{'}pasal{'}'}, #{'{'}jenis{'}'}, #{'{'}jumlah{'}'}, #{'{'}npwp{'}'} & #{'{'}tanggal{'}'}.</strong>
              <Field
                label='Batas Per Hari'
                name='limitPerDay'
                type='number'
                component={Textfield}
              />
            </div>
          </div>
          <div className='md-grid'>
            <div className="md-cell md-cell--12">

            </div>
          </div>
        </Card>
        <br/>
      </div>
    )
  }

}
