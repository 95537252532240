import ApiService from '../../../../../../services/api.service';
import { splitDecimal } from 'react-number-format/lib/utils';

class ExportCsv15Service extends ApiService {
  name= 'ExportCsv15';
  path= 'spt15';
  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api={
    getInfo: async (sptId) => {
      // GET /api/spt15/spt15/info/{spt15Id}/export
      return await this.http.get(`${this.apiLocation}/${this.path}/info/${sptId}/export`)
    },
    getSummary: async(sptId)=> {
      return await this.http.get(`${this.apiLocation}/spt15/info/${sptId}`)
    },
    getTFTotal: async(sptId)=> {
      return await this.http.get(`${this.apiLocation}/spt15/tf/${sptId}/page`, {
        params: {
          page: 0,
        }
      })
    },
    getFTotal: async(sptId)=> {
      return await this.http.get(`${this.apiLocation}/spt15/f/${sptId}/page`, {
        params: {
          page: 0,
        }
      })
    },
    getA1Total: async(sptId)=> {
      return await this.http.get(`${this.apiLocation}/spt15/a1/${sptId}/page`, {
        params: {
          page: 0,
        }
      })
    },
    getA2Total: async(sptId)=> {
      return await this.http.get(`${this.apiLocation}/spt15/a2/${sptId}/page`, {
        params: {
          page: 0,
        }
      })
    }
  }
}

export default new ExportCsv15Service();
