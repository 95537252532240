import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Chip,
  Switch,
  List,
  ListItem,
  Subheader,
  FontIcon,
  Avatar,
  Divider,
  MenuButton
} from 'react-md';

import {validation, Textfield, Searchfield, TextfieldMask, Datepicker, Radio, Checkbox} from '../../../../components/form';
import Bluebird from 'bluebird';
import { Field, reduxForm, getFormValues, change  } from 'redux-form';
import EbillingAccountService from '../EbillingAccountService';
//import FormDialog from './Organization.dialog.view';
import UtilService from '../../../../services/utils.service';
import download from 'downloadjs';
import iziToast from 'izitoast';
import ListView from '../../../../components/entity/listView';
import PaymentNotificationService from './PaymentNotification.service';
import BuktiPenerimaanService from '../BuktiPenerimaan/BuktiPenerimaan.service';
import moment from 'moment';

@connect((state)=> ({
  ...PaymentNotificationService.stateConnectSetting()(state),
}), PaymentNotificationService.actionConnectSetting())
export default class PaymentNotificationView extends ListView {
  service=PaymentNotificationService
  ebillingAccountService=EbillingAccountService
  buktiPenerimaanService=BuktiPenerimaanService
  FormDialog=()=> <div/>

  constructor(props){
    super(props);
    this.state = {
      ...this.state
    }

    this.state.ebillingUser = {}
  }

  beforeFetch(params) {
    if(params["sortBy"] === "createdAt") {
      params["sortBy"] = "createdDate"
      params["sort"] = "DESC"
    }
  }

  addDialog=false
  editDialog=false

  _barActions = [
    {
      label:'word.delete',
      iconClassName:'mdi mdi-delete',
      onClick:() => {
        this.setState({showDialogConfirmDeleteSelected:true})
      },
      disabledFunc:() => this.props.table.selected.length === 0
    },
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => this.fetchData()
    },
  ]

  columns=[
    {isSortable: true, label: "word.noTransaksi", value: "invoice", show: true, isSearchable: true},
    {isSortable: true, label: "word.total", type: "number", value: "total", show: true, isSearchable: true},
    {isSortable: true, label: "word.bank", value: "paymentMethod.bank.name", show: true, isSearchable: true},
    {isSortable: true, label: "word.status", type: "func", value: (d) => {
      switch (d.status) {
        case 'WAITING':
          return <Chip label={d.status} style={{backgroundColor: '#9e9e9e'}}/>
        case 'WAITING_CONFIRMATION':
          return <Chip label={d.status} style={{backgroundColor: '#424242'}}/>
        case 'WAITING_VERIFICATION':
          return <Chip label={d.status} style={{backgroundColor: '#757575'}}/>
        case 'DUPLICATE_SSP':
          return <Chip label={d.status} style={{backgroundColor: '#FF9800'}}/>
        case 'RE_INQUIRY':
          return <Chip label={d.status} style={{backgroundColor: '#E65100'}}/>
        case 'RUNNING':
          return <Chip label={d.status} style={{backgroundColor: '#03A9F4'}}/>
        case 'SPECIAL_CASE':
          return <Chip label={d.status} style={{backgroundColor: '#FB8C00'}}/>
        case 'VERIFIED':
          return <Chip label={d.status} style={{backgroundColor: '#03A9F4'}}/>
        case 'REJECTED':
          return <Chip label={d.status} style={{backgroundColor: '#F44336'}}/>
        case 'ON_PROCESS':
          return <Chip label={d.status} style={{backgroundColor: '#2196F3'}}/>
        case 'COMPLETED':
          return <Chip label={d.status} style={{backgroundColor: '#4CAF50'}}/>
        case 'EXPIRED':
          return <Chip label={d.status} style={{backgroundColor: '#B71C1C'}}/>
        case 'FAILED':
          return <Chip label={d.status} style={{backgroundColor: '#D50000'}}/>
        default:
          break;
      }

      // if(d.status === 'Kadaluarsa' || d.status === 'Gagal') return <Chip label=d.status style={{backgroundColor: '#f44336'}/}
      //
      // if(d.status === 'Sedang Diproses') return <Chip label=d.status style={{backgroundColor: '#9e9e9e'}/}
      //
      // if(d.status === 'Selesai') return <Chip label=d.status style={{backgroundColor: '#4caf50'}/}
    }, show: true, isSearchable: true},
    {isSortable: true, label: "word.aktifSd", type: "date", value: "active", show: true, isSearchable: true},
    {isSortable: true, label: "word.username", value: "additionalUsername", show: true, isSearchable: true},
  ]

  _tableActions = [
    {
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    },
    {label:"divider", iconClassName:"-"},
    {
      label:"Cetak",
      iconClassName:"mdi mdi-printer",
      onClick:(item, callback) => this.printItem(item, callback),
      confirmation:{
        title:"sentence.custom.print",
        message:"sentence.custom.printConfirmation"
      }
    },
    {
      label:"Delete",
      iconClassName:"mdi mdi-delete",
      onClick:(item, callback) => this.deleteItem(item, callback),
      confirmation:{
        title:"sentence.custom.deleteItem",
        message:"sentence.custom.deleteItemConfirmation"
      }
    }
  ]

  async checkConnection() {
    let res = await this.ebillingAccountService.api.me()

    this.setState({ebillingUser: res.data});
  }

  async componentDidMount() {
    this.rootPath = this.props.match.url;

    try {
      this.checkConnection()
    } catch (e){
      iziToast.warning({
        title: e.name,
        message: e.message
      })
    }
  }

  tarikNtpn = async () => {
    let dialog = await this.context.showDialog((props, res, rej) =>({
      title: 'Tarik NTPN',
      okText: 'Tarik',
      width: 600,
      text: 'Apakah anda yakin akan menarik semua NTPN?',
    }));

    if (dialog) {
      try {
        let selected = this.props.table.selected

        let idList = []

        selected.forEach(item => {
          if (item.status === 'COMPLETED') {
            if(item.id) {
              idList.push(item.id)
            }
          }
        })

        if(idList.length > 0) {
          let data = {
            id: idList
          }

          try {
            let res = await this.buktiPenerimaanService.api.pullByInvoiceIds(data)

            if(res.success) {
              let message = 'Semua NTPN sukses ditarik.'

              if(res.message) {
                message = res.message
              }

              iziToast.success({
                title: 'Sukses',
                message: message
              })
            } else {
              let message = 'Tarik NTPN gagal.'

              if(res.message) {
                message = res.message
              }

              iziToast.error({
                title: 'Gagal',
                message: message
              })
            }
          } catch (e) {
            iziToast.warning({
              title: e.name,
              message: e.message
            })
          }
        } else {
          iziToast.warning({
            title: 'Gagal',
            message: 'Anda harus menceklis minimal satu data berstatus COMPLETED.'
          })
        }
      } catch (e) {
        iziToast.warning({
          title: e.name,
          message: e.message
        })
      }
    }
  }

  printItem = async (item, callback) => {
    try {
      var report = await this.service.api.print(item.id)
      var filename = report.headers.filename

      download(report.data, filename);

      callback()
    } catch(e) {
      callback(e, e)
    }
  }

  printBulk = async () => {
    let selected = this.props.table.selected

    let idList = []

    selected.forEach(item => {
      if(item.id) {
        idList.push(item.id)
      }
    })

    if(idList.length > 0) {
      let data = {
        id: idList
      }

      try {
        let res = await this.service.api.printBulk(data)

        iziToast.success({
          title: 'Sukses',
          message: 'Silahkan cek di halaman log cetak payment notification.'
        })
      } catch (e) {
        iziToast.warning({
          title: e.name,
          message: e.message
        })
      }
    } else {
      iziToast.warning({
        title: 'Gagal',
        message: 'Anda harus menceklis minimal satu data.'
      })
    }
  }

  printBpn = async () => {
    let selected = this.props.table.selected

    let idList = []

    selected.forEach(item => {
      if(item.id && item.status === 'COMPLETED') {
        idList.push(item.id)
      }
    })

    if(idList.length > 0) {
      let data = {
        id: idList
      }

      try {
        let res = await this.service.api.printBpn(data)

        iziToast.success({
          title: 'Sukses',
          message: 'Silahkan cek di halaman log cetak BPN'
        })
      } catch (e) {
        iziToast.warning({
          title: e.name,
          message: e.message
        })
      }
    } else {
      iziToast.warning({
        title: 'Gagal',
        message: 'Anda harus menceklis minimal satu data dengan status = "COMPLETED".'
      })
    }
  }

  printBpnGabung = async (type) => {
    let selected = this.props.table.selected

    let idList = []

    selected.forEach(item => {
      if(item.id && item.status === 'COMPLETED') {
        idList.push(item.id)
      }
    })

    if(idList.length > 0) {
      let data = {
        id: idList,
      }

      if (type === 'npwp') {
        data.gabungByNpwp = true
      }

      if (type === 'invoice') {
        data.gabungByInvoice = true
      }

      try {
        let res = await this.service.api.printBpnGabung(data)

        iziToast.success({
          title: 'Sukses',
          message: 'Silahkan cek di halaman log cetak BPN'
        })
      } catch (e) {
        iziToast.warning({
          title: e.name,
          message: e.message
        })
      }
    } else {
      iziToast.warning({
        title: 'Gagal',
        message: 'Anda harus menceklis minimal satu data dengan status = "COMPLETED".'
      })
    }
  }

  deleteSelected = async (items, callback) => {
    try {
      let idList = []

      items.forEach(item => {
        if (item.id) {
          idList.push(item.id)
        }
      })

      let data = {
        id: idList
      }

      let res = await this.service.api.deleteSelected(data)

      callback()

      await this.fetchData()
    } catch (e) {
      callback(e, e)
    }
  }

  printLaporan = async ()=> {
    let dialog = await this.context.showDialog((props, res, rej) =>({
      title: 'Cetak Laporan',
      okText: 'Cetak',
      width: 600,
      text: (
        <div className='md-grid'>
          <Field
            label='Dari Tanggal'
            name='createdDateMulai'
            inline={true}
            className="md-cell md-cell--12"
            component={this.ig.Datepicker}
          />
          <Field
            label='Ke Tanggal'
            name='createdDateAkhir'
            inline={true}
            className="md-cell md-cell--12"
            component={this.ig.Datepicker}
          />

          <div style={{height: 300}}/>
        </div>
      ),
    }));

    if (dialog === undefined)
      dialog = {}

    if (dialog) {
      try {
        let createdDateMulai = dialog.createdDateMulai

        if(createdDateMulai) {
          createdDateMulai = moment(createdDateMulai).format('YYYY-MM-DD')

          dialog.createdDateMulai = createdDateMulai
        }

        let createdDateAkhir = dialog.createdDateAkhir

        if(createdDateAkhir) {
          createdDateAkhir = moment(createdDateAkhir).format('YYYY-MM-DD')

          dialog.createdDateAkhir = createdDateAkhir
        }

        let res = await this.service.api.printReport(dialog)

        if(res.success) {
          iziToast.success({
            title: 'Sukses',
            message: 'Silahkan cek di halaman log cetak laporan PN.'
          })
        } else {
          let message = 'Cetak laporan payment notification gagal.'

          if(res.message) {
            message = res.message
          }

          iziToast.error({
            title: 'Gagal',
            message: message
          })
        }
      } catch (e) {
        iziToast.warning({
          title: e.name,
          message: e.message
        })
      }
    }
  }

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='Status'
          name='status'
          className="md-cell md-cell--12"
          component={this.ig.Searchfield}
          options={[
            {id: null, name: 'ALL'},
            {id: 'WAITING', name: 'WAITING'},
            {id: 'WAITING_CONFIRMATION', name: 'WAITING_CONFIRMATION'},
            {id: 'WAITING_VERIFICATION', name: 'WAITING_VERIFICATION'},
            {id: 'DUPLICATE_SSP', name: 'DUPLICATE_SSP'},
            {id: 'RE_INQUIRY', name: 'RE_INQUIRY'},
            {id: 'RUNNING', name: 'RUNNING'},
            {id: 'SPECIAL_CASE', name: 'SPECIAL_CASE'},
            {id: 'VERIFIED', name: 'VERIFIED'},
            {id: 'REJECTED', name: 'REJECTED'},
            {id: 'ON_PROCESS', name: 'ON_PROCESS'},
            {id: 'COMPLETED', name: 'COMPLETED'},
            {id: 'EXPIRED', name: 'EXPIRED'},
            {id: 'FAILED', name: 'FAILED'}
          ]}
        />
        <this.ig.Field
          label='Mulai Dibuat'
          name='createdDateMulai'
          className="md-cell md-cell--12"
          component={this.ig.Datepicker}
        />
        <this.ig.Field
          label='Sampai Dibuat'
          name='createdDateAkhir'
          className="md-cell md-cell--12"
          component={this.ig.Datepicker}
        />
        <this.ig.Field
          label='Cari'
          name='query'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Username'
          name='additionalUsername'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
      </this.SearchContainer>
    )
  }

  _barItem() {
    let selected = this.props.table.selected

    return (
      <div className='actions mpk-layout'>
        <Button icon tooltipLabel="Tarik NTPN" onClick={()=> this.tarikNtpn()} disabled={selected.length === 0}>chrome_reader_mode</Button>
        <Button icon tooltipLabel="Cetak Bulk" onClick={() => this.printBulk()} disabled={selected.length === 0}>print</Button>
        <Button icon tooltipLabel="Cetak BPN" onClick={() => this.printBpn()} disabled={selected.length === 0}>assignment</Button>
        <Button icon tooltipLabel="Cetak BPN Gabung NPWP" onClick={() => this.printBpnGabung('npwp')} disabled={selected.length === 0}>description</Button>
        <Button icon tooltipLabel="Cetak BPN Gabung Invoice" onClick={() => this.printBpnGabung('invoice')} disabled={selected.length === 0}>description</Button>
        <Button icon tooltipLabel="Cetak Laporan" onClick={this.printLaporan}>book</Button>

        {/*<MenuButton
          id="menu-button-2"
          icon
          menuItems={[
            <ListItem leftIcon={<FontIcon>print</FontIcon>} key={1} onClick={() => this.printBulk()} primaryText="Cetak Bulk" />,
            <ListItem leftIcon={<FontIcon>book</FontIcon>} key={2} primaryText="Cetak Laporan" />,
            <ListItem leftIcon={<FontIcon>print</FontIcon>} key={3} onClick={() => this.printBpn()} primaryText="Cetak BPN" />,
            <ListItem leftIcon={<FontIcon>print</FontIcon>} key={4} onClick={() => this.printBpnGabung()} primaryText="Cetak BPN Gabung" />,
          ]}
          centered
          anchor={{
            x: MenuButton.HorizontalAnchors.CENTER,
            y: MenuButton.VerticalAnchors.CENTER,
          }}
        >
          more_vert
        </MenuButton>*/}

        <Switch
          style={{float: "right"}}
          id="switch-table-setting"
          name="Switch Table Setting"
          label=""
          checked={this.state.showTableSetting}
          onChange={() => {
            this.setState({
              showTableSetting:!this.state.showTableSetting
            })
          }}
        />
      </div>
    )
  }

  // right bar
  tabWidth = 280
  tabs= ["status", "search", "column"]

  tabComponents() {
    var obj = this;
    return {
      status: this.status.bind(obj)
    }
  }

  status() {
    const { handleSubmit, submitting, valid, pristine } = this.props;
    return (
      <div className='mpk-layout column fill'>
        <div className='flex'>
          <List className="">
            <Subheader primaryText="Status Koneksi" />

            { this.state.ebillingUser.login &&
              <ListItem
                leftAvatar={<Avatar  suffix="green" icon={<FontIcon>done</FontIcon>} />}
                primaryText="Terhubung"
                secondaryText={"Terhubung sebagai " + this.state.ebillingUser.login + "."}
              />
            }

            { !this.state.ebillingUser.login &&
              <ListItem
                leftAvatar={<Avatar suffix="red" icon={<FontIcon>clear</FontIcon>} />}
                primaryText="Tidak Terhubung"
                secondaryText="Status Koneksi"
              />
            }

            {/*<Subheader primaryText="Status Sistem" />

            <ListItem
              leftAvatar={<Avatar  suffix="green" icon={<FontIcon>done</FontIcon>} />}
              primaryText="Sistem Active"
              secondaryText="Status Sistem"
            />*/}
          </List>
        </div>
      </div>
    )
  }

}
