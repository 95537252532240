import React, { Component } from 'react';
import { connect } from 'react-redux';

import FormDialog from './MasterKpp.dialog.view';
import MasterKppService from './MasterKpp.service';
import ListView from '../../../../components/entity/listView';

@connect(MasterKppService.stateConnectSetting(), MasterKppService.actionConnectSetting())
export default class MasterKppView extends ListView {
  service=MasterKppService
  FormDialog=FormDialog

  defaultColumns = [
    {isSortable:true, label: "word.id",  value: "id", show:false, isSearchable:true, isDefaultSort: false},
  ]

  columns=[
    {label: "word.code",  value: "code", isDefaultSort:true, show:true, isSearchable:true},
    {label: "word.name",  value: "name", isDefaultSort:true, show:true, isSearchable:true},
    {label: "word.address",  value: "address", isDefaultSort:true, show:false, isSearchable:true},
  ]
}
