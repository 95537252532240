import ApiService from '../../../../services/api.service';

class ImportEsspService extends ApiService {
  name = 'ImportEssp';
  path = 'ebilling/log-import/page';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api = {
    downloadTemplate: async ()=> {
      var res = await this.http.get(this.apiLocation + '/ebilling/log-import/download-template/EBILLING_IMPORT_ESSP')
      return res;
    },

    delete: async (id) => {
      var res = await this.http.delete(this.apiLocation + '/ebilling/log-import/delete/' + id)

      return res.data;
    },

    deleteSelected: async (ids) => {
      var res = await this.http.post(this.apiLocation + '/ebilling/log-import/delete', ids)

      return res.data;
    },
  }
}


export default new ImportEsspService();
