import React, { Component } from 'react';
import { connect } from 'react-redux';

import ListView from '../../../../../../components/entity/listView';
import TextField, { Button, Divider, Chip, Card, CardTitle, CardActions, DataTable, TableHeader, TableBody, TableRow, TableColumn, Switch, Paper } from 'react-md';
import { Field, reduxForm } from 'redux-form';
import download from 'downloadjs';
import uuid from 'uuid';
import iziToast from 'izitoast';
import { Commandbar, Table, TableSetting, Empty, DialogConfirm } from 'react-mpk';
import http from '../../../../../../services/http.service'
import ExportCsv4a2LogService from '../ExportCsv4a2Log/ExportCsv4a2Log.service';
import BP4a2Service from '../../BP4a2/BP4a2.service';
import BP4a2DSJasaKonstruksiService from '../../DSJasaKonstruksi/BP4a2DSJasaKonstruksi.service';
import BP4a2DSPengalihanTanahService from '../../DSPengalihanTanah/BP4a2DSPengalihanTanah.service';
import BP4a2DSSewaTanahService from '../../DSSewaTanah/BP4a2DSSewaTanah.service';
import SSP4a2Service from '../../SSP/SSP4a2.service';
import PBK4a2Service from '../../PBK/PBK4a2.service';
import SPT4a2IndukService from '../../Induk/Spt4a2Induk.service'

@reduxForm({form: 'EXPORT_CSV_4a2', destroyOnUnmount: true})
@connect((state)=> ({
  ...ExportCsv4a2LogService.stateConnectSetting()(state),
  spt: state.auth.currentSPT
}), ExportCsv4a2LogService.actionConnectSetting())
export default class FileLapor4a2View extends ListView {
  service=ExportCsv4a2LogService

  viewType=2;

  constructor(props){
    super(props);
    this.state = {
      showTableSetting:false,
      showDialogConfirmDeleteSelected:false,
      showForm:false,
      formData: {},
      data: [],
      cetak: false,
      showDialog: false,
      category: null,
      HADIAH_UNDIAN: 0,
      BUNGA_DEPOSITO: 0,
      BUNGA_DISKONTO: 0,
      BUNGA_SIMPANAN: 0,
      DIVIDEN_DITERIMA: 0,
      JASA_KONSTRUKSI: 0,
      TRANSAKSI_DERIVATIF: 0,
      PENJUALAN_SAHAM: 0,
      PERSEWAAN_TANAH: 0,
      WP_PEREDARAN_TERTENTU: 0,

      JASA_KONSTRUKSI_S: 0,
      PERSEWAAN_TANAH_S: 0,
      PENGALIHAN_S: 0,

      SSP_4A2: 0,
      PBK_4A2: 0,
      totalInduk: 1,
    }
  }

  columns=[]
  _barActions=[]
  _barItem() {}

  handleExport(category){
      this.setState({ showDialog: true })
      this.setState({ category: category })
  }

  async export(){
    var sptId = this.props.spt.data.id
    var category = this.state.category
    var csv = {};
    try {
      switch (category) {
        case 'HADIAH_UNDIAN':
          csv = await BP4a2Service.api.downloadCsv(sptId, category);
          break;
        case 'BUNGA_DEPOSITO':
          csv = await BP4a2Service.api.downloadCsv(sptId, category);
          break;
        case 'BUNGA_DISKONTO':
          csv = await BP4a2Service.api.downloadCsv(sptId, category);
          break;
        case 'BUNGA_SIMPANAN':
          csv = await BP4a2Service.api.downloadCsv(sptId, category);
          break;
        case 'DIVIDEN_DITERIMA':
          csv = await BP4a2Service.api.downloadCsv(sptId, category);
          break;
        case 'JASA_KONSTRUKSI':
          csv = await BP4a2Service.api.downloadCsv(sptId, category);
          break;
        case 'TRANSAKSI_DERIVATIF':
          csv = await BP4a2Service.api.downloadCsv(sptId, category);
          break;
        case 'PENJUALAN_SAHAM':
          csv = await BP4a2Service.api.downloadCsv(sptId, category);
          break;
        case 'PERSEWAAN_TANAH':
          csv = await BP4a2Service.api.downloadCsv(sptId, category);
          break;
        case 'WP_PEREDARAN_TERTENTU':
          csv = await BP4a2Service.api.downloadCsv(sptId, category);
          break;
        case 'PERSEWAAN_TANAH_S':
          csv = await BP4a2Service.api.downloadCsv(sptId, 'SENDIRI_SEWA_TANAH');
          break;
        case 'JASA_KONSTRUKSI_S':
          csv = await BP4a2Service.api.downloadCsv(sptId, 'SENDIRI_JASA_KONSTRUKSI');
          break;
        case 'PENGALIHAN_S':
          csv = await BP4a2Service.api.downloadCsv(sptId, 'WP_HAK_ATAS_TANAH');
          break;
        case 'SSP_4A2':
          csv = await SSP4a2Service.api.downloadCsv(sptId);
          break;
        case 'PBK_4A2':
          csv = await PBK4a2Service.api.downloadCsv(sptId);
          break;
        case 'BP_4A2_INDUK':
          csv = await SPT4a2IndukService.api.downloadCsv(sptId);
          break;
        default:
          break;
      }
      var filename = csv.headers.filename
      download(csv.data, filename);
    } catch (error) {

    }
  }

  async componentDidMount(){
    var sptId = this.props.spt.data.id
    // let HADIAH_UNDIAN = await BP4a2Service.api.find({'type.equals': 'HADIAH_UNDIAN'}, ()=>{}, {
    //   path: BP4a2Service.path+'/'+this.props.spt.data.id+'/page'
    // })
    let info = await this.service.api.getInfo(sptId);

    // let HADIAH_UNDIAN = await BP4a2Service.api.find({'type.equals': 'HADIAH_UNDIAN'}, ()=>{}, {
    //   path: BP4a2Service.path+'/'+this.props.spt.data.id+'/page'
    // })
    // let BUNGA_DEPOSITO = await BP4a2Service.api.find({'type.equals': 'BUNGA_DEPOSITO'}, ()=>{}, {
    //   path: BP4a2Service.path+'/'+this.props.spt.data.id+'/page'
    // })
    // let BUNGA_SIMPANAN = await BP4a2Service.api.find({'type.equals': 'BUNGA_SIMPANAN'}, ()=>{}, {
    //   path: BP4a2Service.path+'/'+this.props.spt.data.id+'/page'
    // })
    // let BUNGA_DISKONTO = await BP4a2Service.api.find({'type.equals': 'BUNGA_DISKONTO'}, ()=>{}, {
    //   path: BP4a2Service.path+'/'+this.props.spt.data.id+'/page'
    // })
    // let DIVIDEN_DITERIMA = await BP4a2Service.api.find({'type.equals': 'DIVIDEN_DITERIMA'}, ()=>{}, {
    //   path: BP4a2Service.path+'/'+this.props.spt.data.id+'/page'
    // })
    // let JASA_KONSTRUKSI = await BP4a2Service.api.find({'type.equals': 'JASA_KONSTRUKSI'}, ()=>{}, {
    //   path: BP4a2Service.path+'/'+this.props.spt.data.id+'/page'
    // })
    // let TRANSAKSI_DERIVATIF = await BP4a2Service.api.find({'type.equals': 'TRANSAKSI_DERIVATIF'}, ()=>{}, {
    //   path: BP4a2Service.path+'/'+this.props.spt.data.id+'/page'
    // })
    // let PENJUALAN_SAHAM = await BP4a2Service.api.find({'type.equals': 'PENJUALAN_SAHAM'}, ()=>{}, {
    //   path: BP4a2Service.path+'/'+this.props.spt.data.id+'/page'
    // })
    // let PERSEWAAN_TANAH = await BP4a2Service.api.find({'type.equals': 'PERSEWAAN_TANAH'}, ()=>{}, {
    //   path: BP4a2Service.path+'/'+this.props.spt.data.id+'/page'
    // })

    // let JASA_KONSTRUKSI_S = await BP4a2DSJasaKonstruksiService.api.find({'type.equals': 'JASA_KONSTRUKSI_S'}, ()=>{}, {
    //   path: BP4a2DSJasaKonstruksiService.path+'/'+this.props.spt.data.id+'/page'
    // })
    // let PERSEWAAN_TANAH_S = await BP4a2DSSewaTanahService.api.find({'type.equals': 'PERSEWAAN_TANAH_S'}, ()=>{}, {
    //   path: BP4a2DSSewaTanahService.path+'/'+this.props.spt.data.id+'/page'
    // })
    // let PENGALIHAN_S = await BP4a2DSPengalihanTanahService.api.find({'type.equals': 'PENGALIHAN_S'}, ()=>{}, {
    //   path: BP4a2DSPengalihanTanahService.path+'/'+this.props.spt.data.id+'/page'
    // })

    // let SSP_4A2 = await SSP4a2Service.api.find({'type.equals': 'PENGALIHAN_S'}, ()=>{}, {
    //   path: SSP4a2Service.path+sptId+'/page'
    // })
    // let PBK_4A2 = await PBK4a2Service.api.find({'type.equals': 'PENGALIHAN_S'}, ()=>{}, {
    //   path: PBK4a2Service.path+'/'+this.props.spt.data.id+'/page'
    // })

    if(info.data) {
      this.setState({
        HADIAH_UNDIAN: info.data.hadiahUndian,
        BUNGA_DEPOSITO: info.data.bungaDeposito,
        BUNGA_DISKONTO: info.data.bungaDiskonto,
        BUNGA_SIMPANAN: info.data.bungaSimpanan,
        DIVIDEN_DITERIMA: info.data.dividenDiterima,
        JASA_KONSTRUKSI: info.data.jasaKonstruksi,
        TRANSAKSI_DERIVATIF: info.data.transaksiDerivatif,
        PENJUALAN_SAHAM: info.data.penjualanSaham,
        PERSEWAAN_TANAH: info.data.persewaanTanah,
        WP_PEREDARAN_TERTENTU: info.data.peredaranTertentu,

        JASA_KONSTRUKSI_S: info.data.sendiriJasaKonstruksi,
        PERSEWAAN_TANAH_S: info.data.sendiriSewaTanah,
        PENGALIHAN_S: info.data.sendiriWpHakAtasTanah,

        SSP_4A2: info.data.ssp,
        PBK_4A2: info.data.pbk,
      })
    }
  }

  _tableView(props) {
      const _this = this
      return (
        <Card className="flex mpk-layout">
            <DialogConfirm
                title={'word.export'}
                message={'sentence.custom.export'}
                visible={this.state.showDialog}
                onSubmit={(callback) => {
                    _this.export()
                    callback();
                }}
                onCancel={() => this.setState({showDialog:false})}
                translate={true}
            />
            <CardTitle title='' style={{padding: '10px 16px'}} subtitle='Informasi File Export'/>
            <Divider/>
            <DataTable plain
            fixedHeader
            fixedFooter>
              <TableHeader>
                <TableRow>
                    <TableColumn>Jumlah Data</TableColumn>
                    <TableColumn></TableColumn>
                </TableRow>
              </TableHeader>
              <TableBody>
                <TableRow>
                    <TableColumn>BP</TableColumn>
                    <TableColumn></TableColumn>
                </TableRow>
                <TableRow>
                    <TableColumn>{this.state.HADIAH_UNDIAN} Unit</TableColumn>
                    <TableColumn>
                        <Button disabled={this.state.HADIAH_UNDIAN == 0} onClick={this.handleExport.bind(this, 'HADIAH_UNDIAN')} style={{width: '100%'}} primary raised>EXPORT DATA HADIAH UNDIAN</Button>
                    </TableColumn>
                </TableRow>
                <TableRow>
                    <TableColumn>{this.state.BUNGA_DEPOSITO} Unit</TableColumn>
                    <TableColumn>
                        <Button disabled={this.state.BUNGA_DEPOSITO == 0} onClick={this.handleExport.bind(this, 'BUNGA_DEPOSITO')} style={{width: '100%'}} primary raised>EXPORT DATA BUNGA DEPOSITO</Button>
                    </TableColumn>
                </TableRow>
                <TableRow>
                    <TableColumn>{this.state.BUNGA_DISKONTO} Unit</TableColumn>
                    <TableColumn>
                        <Button disabled={this.state.BUNGA_DISKONTO == 0} onClick={this.handleExport.bind(this, 'BUNGA_DISKONTO')} style={{width: '100%'}} primary raised>EXPORT DATA BUNGA DISKONTO</Button>
                    </TableColumn>
                </TableRow>
                <TableRow>
                    <TableColumn>{this.state.BUNGA_SIMPANAN} Unit</TableColumn>
                    <TableColumn>
                        <Button disabled={this.state.BUNGA_SIMPANAN == 0} onClick={this.handleExport.bind(this, 'BUNGA_SIMPANAN')} style={{width: '100%'}} primary raised>EXPORT DATA BUNGA SIMPANAN</Button>
                    </TableColumn>
                </TableRow>
                <TableRow>
                    <TableColumn>{this.state.DIVIDEN_DITERIMA} Unit</TableColumn>
                    <TableColumn>
                        <Button disabled={this.state.DIVIDEN_DITERIMA == 0} onClick={this.handleExport.bind(this, 'DIVIDEN_DITERIMA')} style={{width: '100%'}} primary raised>EXPORT DATA DIVIDEN DITERIMA</Button>
                    </TableColumn>
                </TableRow>
                <TableRow>
                    <TableColumn>{this.state.JASA_KONSTRUKSI} Unit</TableColumn>
                    <TableColumn>
                        <Button disabled={this.state.JASA_KONSTRUKSI == 0} onClick={this.handleExport.bind(this, 'JASA_KONSTRUKSI')} style={{width: '100%'}} primary raised>EXPORT DATA JASA KONSTRUKSI</Button>
                    </TableColumn>
                </TableRow>
                <TableRow>
                    <TableColumn>{this.state.TRANSAKSI_DERIVATIF} Unit</TableColumn>
                    <TableColumn>
                        <Button disabled={this.state.TRANSAKSI_DERIVATIF == 0} onClick={this.handleExport.bind(this, 'TRANSAKSI_DERIVATIF')} style={{width: '100%'}} primary raised>EXPORT DATA TRANSAKSI DERIVATIF</Button>
                    </TableColumn>
                </TableRow>
                <TableRow>
                    <TableColumn>{this.state.PENJUALAN_SAHAM} Unit</TableColumn>
                    <TableColumn>
                        <Button disabled={this.state.PENJUALAN_SAHAM == 0} onClick={this.handleExport.bind(this, 'PENJUALAN_SAHAM')} style={{width: '100%'}} primary raised>EXPORT DATA PENJUALAN SAHAM</Button>
                    </TableColumn>
                </TableRow>
                <TableRow>
                    <TableColumn>{this.state.PERSEWAAN_TANAH} Unit</TableColumn>
                    <TableColumn>
                        <Button disabled={this.state.PERSEWAAN_TANAH == 0} onClick={this.handleExport.bind(this, 'PERSEWAAN_TANAH')} style={{width: '100%'}} primary raised>EXPORT DATA PERSEWAAN TANAH</Button>
                    </TableColumn>
                </TableRow>
                <TableRow>
                  <TableColumn>{this.state.WP_PEREDARAN_TERTENTU} Unit</TableColumn>
                  <TableColumn>
                    <Button disabled={this.state.WP_PEREDARAN_TERTENTU == 0} onClick={this.handleExport.bind(this, 'WP_PEREDARAN_TERTENTU')} style={{width: '100%'}} primary raised>EXPORT DATA WP PEREDARAN TERTENTU</Button>
                  </TableColumn>
                </TableRow>
                <TableRow>
                    <TableColumn>BP Bayar Sendiri</TableColumn>
                    <TableColumn></TableColumn>
                </TableRow>
                <TableRow>
                    <TableColumn>{this.state.PERSEWAAN_TANAH_S} Unit</TableColumn>
                    <TableColumn>
                        <Button disabled={this.state.PERSEWAAN_TANAH_S == 0} onClick={this.handleExport.bind(this, 'PERSEWAAN_TANAH_S')} style={{width: '100%'}} primary raised>EXPORT DATA PERSEWAAN TANAH (S)</Button>
                    </TableColumn>
                </TableRow>
                <TableRow>
                    <TableColumn>{this.state.JASA_KONSTRUKSI_S} Unit</TableColumn>
                    <TableColumn>
                        <Button disabled={this.state.JASA_KONSTRUKSI_S == 0} onClick={this.handleExport.bind(this, 'JASA_KONSTRUKSI_S')} style={{width: '100%'}} primary raised>EXPORT DATA JASA KONSTRUKSI (S)</Button>
                    </TableColumn>
                </TableRow>
                <TableRow>
                    <TableColumn>{this.state.PENGALIHAN_S} Unit</TableColumn>
                    <TableColumn>
                        <Button disabled={this.state.PENGALIHAN_S == 0} onClick={this.handleExport.bind(this, 'PENGALIHAN_S')} style={{width: '100%'}} primary raised>EXPORT DATA PENGALIHAN (S)</Button>
                    </TableColumn>
                </TableRow>
                <TableRow>
                    <TableColumn>Lainnya</TableColumn>
                    <TableColumn></TableColumn>
                </TableRow>
                <TableRow>
                    <TableColumn>{this.state.SSP_4A2} Unit</TableColumn>
                    <TableColumn>
                        <Button disabled={this.state.SSP_4A2 == 0} onClick={this.handleExport.bind(this, 'SSP_4A2')} style={{width: '100%'}} primary raised>EXPORT DATA SSP</Button>
                    </TableColumn>
                </TableRow>
                <TableRow>
                    <TableColumn>{this.state.PBK_4A2} Unit</TableColumn>
                    <TableColumn>
                        <Button disabled={this.state.PBK_4A2 == 0} onClick={this.handleExport.bind(this, 'PBK_4A2')} style={{width: '100%'}} primary raised>EXPORT DATA PBK</Button>
                    </TableColumn>
                </TableRow>
                <TableRow>
                  <TableColumn>{this.state.totalInduk} Unit</TableColumn>
                  <TableColumn>
                    <Button disabled={this.state.totalInduk == 0} onClick={this.handleExport.bind(this, 'BP_4A2_INDUK')} style={{width: '100%'}} primary raised>Export Data Induk</Button>
                  </TableColumn>
                </TableRow>
              </TableBody>
            </DataTable>
        </Card>
      )
  }
}
