import React, { Component } from 'react';
import { connect } from 'react-redux';

import {Field, getFormValues, reduxForm} from 'redux-form';
import { Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab } from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Switch as Switch2} from '../../../../../../components/form';

import ListView from '../../../../../../components/entity/listView';
import ColumnService from '../../../../../../services/column.service';
// import ImportService from './../Import/Import.service';
import axios from 'axios';
import izitoast from 'izitoast';
import FormDialog from './ImportBP22IndustriImportir.dialog';
import ImportBP22IndustriImportirService from './ImportBP22IndustriImportir.service';
import download from "downloadjs";
import ListViewImport from '../../../../../../components/entity/ListViewImport';
import ImportDialogResult from '../../../../../../components/entity/import.dialog.result';
import OrganizationService from "../../../../Administrator/Organization/Organization.service";

@reduxForm({form: 'ImportBP22IndustriImportirForm', destroyOnUnmount: true})
@connect((state) => ({
  ...ImportBP22IndustriImportirService.stateConnectSetting()(state),
  formData    : getFormValues('ImportBP22IndustriImportirForm')(state)
}), ImportBP22IndustriImportirService.actionConnectSetting())
@connect(ImportBP22IndustriImportirService.stateConnectSetting(), ImportBP22IndustriImportirService.actionConnectSetting())
export default class ImportBP22IndustriImportirView extends ListViewImport {
  service=ImportBP22IndustriImportirService
  FormDialog=FormDialog

  category='BP_INDUSTRI_IMPORTIR'
  importPath='/api/import/spt/22'
  showAutomatisTab=false
  showPenomoranAuto=true
  showPembetulan=true
  // showFormatEspt=true
  // viewType=2

  constructor(props){
    super(props);
    this.state = {
      ...this.state,
      showTableSetting:true,
      showDialogConfirmDeleteSelected:false,
      showForm:false,
      formData: {},
      data: [],
      onProgress: false,
      isError: false,
      errorMessage: '',
      type: 'M'
    }
  }

  beforeFetch(params) {
    params["category.equals"] = "BP_INDUSTRI_IMPORTIR"
  }

  async handleDownloadTemplate() {

    if (this.props.formData.category ==='BP_22') {
      izitoast.warning({
        title: 'Pemberitahuan !',
        message: 'Pilih salah satu kategori template',
      });
    } else {
      var report = await ImportBP22IndustriImportirService.api.downloadTemplate()
      download(report.data, this.props.formData.category+'.csv');
    }
  }

  componentDidMount(){
    let path = this.props.match.path.split("/")
    let params = path[2];
    this.props.change("category", params)
    this.props.change("type", "M")
    this.props.change("no", false)
    this.props.change("migration", false)
    this.props.change("formatEspt", false)
    this.props.change("pembetulan", false)
    this.props.change("npwp", null)
  }

  handleTabChange(newActiveTabIndex){
    if(newActiveTabIndex == 0){
      this.props.change("type", "M");
      this.setState({type: 'M'})
    } else {
      this.props.change("type", "A")
      this.setState({type: 'A'})
    }
  }

  // yg ditambah
  tabWidth = 280
  tabs= ["import", "search", "column"]

  tabComponents() {
    var obj = this;
    return {
      import: this.import.bind(obj)
    }
  }
   // end yg ditambah

  import(){
    const { handleSubmit, submitting, valid, pristine } = this.props;

    let formatEspt = false;

    if (this.props.formData) {
      formatEspt = this.props.formData.formatEspt
    }

    return (
        <div className='mpk-layout column fill'>
        <ImportDialogResult dataResults={this.state.importResults} visible={this.state.showImportResult} onHide={()=> {this.setState({showImportResult: false})}} />
          <div className='flex'>
            <div className='md-grid'>
             <div className="md-cell md-cell--12">
                <Field
                  label='Select a File'
                  name='file'
                  multiple
                  twoLines
                  component={Fileinput}
                  validate={validation.required}
                />
              </div>
              <div className="md-cell md-cell--12">
                <Field
                  label='Aktifkan Penomoran Bukti Potong Otomatis ?'
                  name='no'
                  component={Switch2}
                  // validate={validation.required}
                />
              </div>
              <div className="md-cell md-cell--12">
                <Field
                  label='Import Migrasi'
                  name='migration'
                  component={Switch2}
                  // validate={validation.required}
                />
              </div>

              {this.showPembetulan &&
                <div className="md-cell md-cell--12">
                  <Field
                    label='Pembetulan'
                    name='pembetulan'
                    component={Switch2}
                    // validate={validation.required}
                  />
                </div>
              }

              {this.showFormatEspt &&
              <div className="md-cell md-cell--12">
                <Field
                  label='Format ESPT'
                  name='formatEspt'
                  component={Switch2}
                  // validate={validation.required}
                />
              </div>
              }

              { formatEspt &&
              <div className="md-cell md-cell--12">
                <Field
                  label='Organization'
                  name='npwp'
                  valueField='npwp'
                  params={{ size: 50 }}
                  remoteSearch={true}
                  service={OrganizationService}
                  searchField  = {['name', 'aliasName', 'npwp']}
                  component={Searchfield}
                  itemTemplate={function(d) {
                    let nameAndAlias = ""

                    if (d.name)
                      nameAndAlias = d.name

                    if (d.aliasName)
                      nameAndAlias += " - " + d.aliasName

                    return {
                      primaryText  : nameAndAlias,
                      secondaryText: d.npwp,
                      onClick      : () => {
                        if(!this.props.disabled){
                          this.handleItemClick(d); this.hide()
                        }
                      }
                    }
                  }}
                />
              </div>
              }
              <div className="md-cell md-cell--12">
                  <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>Import</Button>
              </div>
            </div>
          </div>
          {this.state.onProgress ? (
              <LinearProgress style={{margin: 0}} query={true} />
            ) : (null)}
        </div>
    )
  }

}
