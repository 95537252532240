/**
 * Created by dwiargo on 10/17/17.
 */
var origin = window.location.origin

const env = {
  clientId:'abbeb89a-2c00-4c17-b276-008800ed2be7',
  clientSecret:'43d42ac1-975a-4a88-b3d4-175f01d9a633',
  clientAuth: 'Basic YWJiZWI4OWEtMmMwMC00YzE3LWIyNzYtMDA4ODAwZWQyYmU3OjQzZDQyYWMxLTk3NWEtNGE4OC1iM2Q0LTE3NWYwMWQ5YTYzMw==',
  grantType:'password',
  enableCaptcha: false,
  enableEbupot: true,
  enableEfilingPpn: true,
  enableEfilingBadan: false,
  enableAksesProfile: true,
  host:{
    profile: origin,
    // default: "http://localhost:8081",
    // ws: 'localhost:8081',
    default: "",
    ws: '',
    captcha: ''
  }
};

export default env;
