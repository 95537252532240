import ApiService from '../../../../services/api.service';

class KeyService extends ApiService {
  name= 'KeyService';
  path= 'key';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api = {
    topUp: async (loc, data) => {
      var res = await this.http.post(loc, data)

      return res;
    }
  }

  // apiMockup= {
  //   find: [{
  //     id: 1,
  //     status: 'Process',
  //     npwp: '023945840000',
  //     name: 'PT. A',
  //     pasal: '21/26',
  //     year: '2018',
  //     month: 'Januari',
  //     pembetulan: '0'
  //   }],
  //   findOne: {
  //     id: 1,
  //     status: 'Process',
  //     npwp: '023945840000',
  //     name: 'PT. A',
  //     pasal: '21/26',
  //     year: '2018',
  //     month: 'Januari',
  //     pembetulan: '0'
  //   }
  // }
}

export default new KeyService();
