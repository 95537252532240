import ApiService from '../../../../services/api.service';

class LogginInService extends ApiService {
  name= 'LogginIn';
  path= 'logged-in';

  constructor() {
    super()
    this.init()
    this.initApi()
  }
}

export default new LogginInService();
