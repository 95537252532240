import ApiService from '../../../../services/api.service';

class MasterApplicationService extends ApiService {
  name= 'masterApplication';
  path= 'master/applications';
  constructor() {
    super();
    this.init()
  }
}

export default new MasterApplicationService();
