import React from 'react';
import { Route, Switch } from 'react-router-dom';
import LogExportView from "../../Export/LogExport.view";
import LogEfillingView from "../../Efilling/LogEfilling.view";

export default (props) => (
  <Switch>
    <Route path={props.match.path+'/BP_21_F/:id'} component={require('./BP_21_F/BP_21_F.form').default} />
    <Route path={props.match.path+'/BP_21_F'} component={require('./BP_21_F/BP_21_F.view').default} />
    <Route path={props.match.path+'/BP_21_TF/:id'} component={require('./BP_21_TF/BP_21_TF.form').default} />
    <Route path={props.match.path+'/BP_21_TF'} component={require('./BP_21_TF/BP_21_TF.view').default} />
    <Route path={props.match.path+'/BP_21_A1/:id'} component={require('./BP_21_A1/BP_21_A1.form').default} />
    <Route path={props.match.path+'/BP_21_A1'} component={require('./BP_21_A1/BP_21_A1.view').default} />
    <Route path={props.match.path+'/BP_21_A2/:id'} component={require('./BP_21_A2/BP_21_A2.form').default} />
    <Route path={props.match.path+'/BP_21_A2'} component={require('./BP_21_A2/BP_21_A2.view').default} />
    <Route path={props.match.path+'/BP_21_SATU_MASA/:id'} component={require('./BP_21_SATU_MASA/BP_21_SATU_MASA.form').default} />
    <Route path={props.match.path+'/BP_21_SATU_MASA'} component={require('./BP_21_SATU_MASA/BP_21_SATU_MASA.view').default} />
    <Route path={props.match.path+'/DAFTAR_BIAYA_21'} component={require('./BP_21_DAFTAR_BIAYA/BP_21_DAFTAR_BIAYA.form').default} />
    <Route path={props.match.path+'/DAFTAR_SSP_21/:id'} component={require('./BP_21_SSP/BP_21_SSP.form').default} />
    <Route path={props.match.path+'/DAFTAR_SSP_21'} component={require('./BP_21_SSP/BP_21_SSP.view').default} />
    <Route path={props.match.path+'/BP_21_SATU_TAHUN'} component={require('./BP_21_SATU_TAHUN/BP_21_SATU_TAHUN.view').default} />
    <Route path={props.match.path+'/SPT_INDUK_21'} component={require('./SPT_INDUK_21/SPT_INDUK_21.form').default} />
    <Route path={props.match.path+'/FILE_LAPOR_21'} component={require('./FileLapor21/FileLapor21.view').default} />

    {/* IMPORT_21 */}
    <Route path={props.match.path+"/IMPORT_21"} component={require('./Import/Import.form').default} />
    <Route path={props.match.path+"/IMPORT/BP_21_TF"} component={require('./ImportBp21TF/ImportBp21TF.view').default} />
    <Route path={props.match.path+"/IMPORT/BP_21_F"} component={require('./ImportBp21F/ImportBp21F.view').default} />
    <Route path={props.match.path+"/IMPORT/BP_21_A1"} component={require('./ImportBp21A1/ImportBp21A1.view').default} />
    <Route path={props.match.path+"/IMPORT/BP_21_A2"} component={require('./ImportBp21A2/ImportBp21A2.view').default} />
    <Route path={props.match.path+"/IMPORT/DAFTAR_BIAYA_21"} component={require('./ImportBp21DaftarBiaya/ImportBp21DaftarBiaya.view').default} />
    <Route path={props.match.path+"/IMPORT/BP_21_SATU_MASA"} component={require('./ImportBP21SatuMasa/ImportBP21SatuMasa.view').default} />
    <Route path={props.match.path+"/IMPORT/BP_21_SATU_MASA_TIDAK_LEBIH"} component={require('./ImportBP21SatuMasaTL/ImportBP21SatuMasaTL.view').default} />
    <Route path={props.match.path+"/IMPORT/SSP_21"} component={require('./ImportBp21Ssp/ImportBp21Ssp.view').default} />
    <Route path={props.match.path+"/IMPORT/INDUK_21"} component={require('./ImportBp21Induk/ImportBp21Induk.view').default} />
    {/* END IMPORT_21 */}

    {/* EXPORT_21 */}
    <Route path={props.match.path+"/EXPORT_CSV_21"} component={require('./ExportCsv21/ExportCsv21.view').default} />
    {/* END EXPORT_21 */}

    {/* log export */}
    <Route path={props.match.path+"/logExport/:pasal"} render={(props) => (
      <LogExportView key={props.match.params.pasal} {...props} />
    )} />

    <Route path={props.match.path+"/eFiling"} render={(props) => (
      <LogEfillingView {...props} />
    )} />
    {/* END log export */}

    {/* LOG 21 */}
    <Route path={props.match.path+"/LOG_EXPORT_CSV_21"} component={require('./ExportCsv21Log/ExportCsv21Log.view').default} />
    <Route path={props.match.path+"/LOG_EMAIL"} component={require('./Email21Log/Email21Log.view').default} />
    <Route path={props.match.path+'/LOG_FILE_LAPOR_21'} component={require('./FileLapor21Log/FileLapor21Log.view').default} />
    {/* END LOG 21 */}

    {/* SETTING 21 */}
    <Route path={props.match.path+"/SETTING_NO_BUPOT_21"} component={require('./SettingNoBupot21/SettingNoBupot21.form').default} />
    {/* END SETTING 21 */}

    <Route path={props.match.path+'/'} component={function() {return <div/>}} />
  </Switch>
)
