import React from 'react'
import {
  DialogContainer,
  Button
} from 'react-md'

import { connect } from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import { Textfield, Searchfield, Datepicker, Multiselect, Switch as Switch2, Radio, TextfieldMask, Checkbox, validation } from './../form'
import moment from 'moment'
import TableColumns from './TableColumns'
import { deepStrictEqual } from 'assert';

@reduxForm({form: 'SearchForm', destroyOnUnmount: true})
@connect((state)=> ({
  wp: state.auth.currentWp,
  formData: state.form.SearchForm
}))
export default class TableSearchCustomDialog extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      dsl: [
        {
          field: '',
          value: '',
          valueType: '',
          operator: '',
        }
      ]
    }
  }

  formatDate(e){
    if(e){
      return moment(e, "DD/MM/YYYY").toDate()
    } else {
      return new Date()
      }
  }

  handleHide(){
    this.props.reset()
    this.props.parent.setState({ showModal: false })
    this.setState({
      dsl: [
        {
          field: '',
          value: '',
          valueType: '',
          operator: '',
        }
      ]
    })
  }

  componentDidMount(){
    this.props.initialize({})
  }

  handleSearch(){
    var serviceName = ""
    if(this.props.service && this.props.service.name) serviceName = this.props.service.name
    var params = {}
    if(this.props.formData && this.props.formData.values){
      params = this.props.formData.values
    }

    // ADVANCE SEARCH
    var type = null
    if(this.props.tableFilter2 && this.props.tableFilter2.type){
      type = this.props.tableFilter2.type
    }
    if(type == "AdvancedSearch" || this.props.service.advancedSearch){
      var formData = {}
      if(this.props.formData && this.props.formData.values){
        formData = this.props.formData.values
      }
      params.type = "AdvancedSearch"
      params.query = ""
      var dsl = this.state.dsl
      var i = 0
      while(i < dsl.length){
        var formName = 'search' + i
        if(formData[formName]){
          var getValue = formData[formName]
          if(getValue.field){
            params.query += getValue.field
          }
          if(getValue.value){
            var jumlah = getValue.field.indexOf("JUMLAH")
            if(jumlah >= 0){
              if(getValue.compare == "LIKE"){
                params.query += '.' + getValue.compare + "'%" + getValue.value + "%'"
              } else {
                params.query += '.' + getValue.compare + "=" + getValue.value
                params[getValue.field+"."+getValue.compare] = getValue.value
              }
            } else {
              if(getValue.compare == "LIKE"){
                params.query += '.' + getValue.compare + "'%" + getValue.value + "%'"
              } else {
                params.query += '.' + getValue.compare + "=" + getValue.value
                params[getValue.field+"."+getValue.compare] = getValue.value
              }
            }
          }
          // MultiVal
          if(getValue.compare && getValue.compare == 'IN' && getValue.value){
            params.query = ""
            if(getValue.field){
              params.query += getValue.field
            }
            var jumlah = getValue.field.indexOf("JUMLAH")
            var arrayValue = getValue.value.split('|')
            if(getValue.compare == "IN"){
              params.query += " " + getValue.compare + " ("
              arrayValue.map((i, index)=> {
                if((arrayValue.length - 1) == index){
                  if(jumlah > 0){
                    params.query += i
                  } else {
                    params.query += "'" + i + "'"
                  }
                } else {
                  if(jumlah > 0){
                    params.query += i + ","
                  } else {
                    params.query += "'" + i + "'" + ","
                  }
                }
              })
              params.query += ")"
            }
          }
          // Multi Val
          // if(getValue.op){
          //   params.query += " " + getValue.op + " "
          // }
        }
        i++
      }
      this.setState({
        dsl: [
          {
            field: '',
            value: '',
            valueType: '',
            operator: '',
          }
        ]
      })
    }
    // ADVANCE SEARCH

    // --- UPDATE PARAM
    params.onSearch = true
    try {
      this.props.parent.props.history.push({
        pathname: this.props.parent.props.history.location.pathname,
        search: '?'+params.query
      })
    } catch (e){}

    // var queryDsl = params.query
    // var paramQ = {}
    // var q = queryDsl.split('=')
    // // if(q.length > 1){
    // //   paramQ[q[0]] = q[1]
    // // }

    delete params.type
    delete params.query
    var cutP = 0
    while(cutP < this.state.dsl.length){
      delete params['search'+ cutP]
      cutP++;
    }

    this.props.parent.props.onSearch(params)
    this.handleHide()
  }

  render(){
    return (
      <DialogContainer
        footer={true}
        id="custom-search"
        visible={this.props.show}
        title="Query Data"
        width={1000}
        height={500}
        onHide={this.handleHide.bind(this)}
        actions={[
          {
            onClick: this.props.handleSubmit(this.handleSearch.bind(this)),
            primary: true,
            children: 'Cari',
          }, {
            onClick: this.handleHide.bind(this),
            primary: true,
            children: 'Batal',
          }
        ]}
      >
        {this.searchForm(this.props)}
      </DialogContainer>
    )
  }

  searchForm(){
    var type = null
    if(this.props.tableFilter2 && this.props.tableFilter2.type){
      type = this.props.tableFilter2.type
    }

    if(type){
        if(type == "AdvancedSearch"){
            return this.formAdvancedSearch()
        }
    } else {
      if(this.props.service.advancedSearch){
        return this.formAdvancedSearch()
      }
    }
  }

  tambahDSL(){
    var dsl = this.state.dsl
    dsl.unshift(
      {
        field: '',
        value: '',
        valueType: '',
        operator: '',
      }
    )
    this.setState({ dsl : dsl })
  }

  deleteDSL(index){
    var dsl = this.state.dsl
    dsl = dsl.filter((d, i)=>{
      return i != index
    })
    this.setState({ dsl : dsl })
  }

  formAdvancedSearch(){
    var dsl = this.state.dsl
    var serviceName = this.props.service.name
    var options = []
    TableColumns[serviceName].map((col)=> {
      var colName  = col.searchField.replace(/([A-Z])/g, " $1")
      colName = colName[0].toUpperCase() + colName.slice(1)
      var colValue = ""
      if(typeof col.value == "function"){
        colValue = col.searchField
      } else {
        colValue = col.value.split('.')
        if(colValue.length > 1){
          colValue = col.searchField
        }
      }

      if(col.isSearchable){
        options.push({ id: colValue, name: colName })
      }
    })
    return (
      <div className="md-grid" style={{height: '100%', width: '100%'}}>
        {
          dsl.map((d, index)=> {
            var formData = {}
            if(this.props.formData && this.props.formData.values && this.props.formData.values['search'+index]) formData = this.props.formData.values['search'+index]
            var valueField = 'normalValue'
            if(formData.field){
              if(formData.field.indexOf("JUMLAH") >= 0){
                valueField = 'jumlahValue'
              } else if(formData.field.indexOf("TANGGAL") >= 0){
                valueField = 'tanggalValue'
              } else if(formData.compare == "IN"){
                valueField = "multipleValue"
              }
            }
            return <div className="md-grid" style={{height: '100%', width: '100%'}}>
              <Field
                label='Field'
                name={'search'+index+'.field'}
                className="md-cell md-cell--3"
                component={Searchfield}
                options={options}
                validate={validation.required}
              />
              <Field
                label='Compare'
                name={'search'+index+'.compare'}
                className="md-cell md-cell--2"
                component={Searchfield}
                options={[
                  {id: 'equals', name: '(=) Equal to '},
                  {id: 'contains', name: '(=) Contains '}
                  // {id: '>', name: '(>) Greater than'},
                  // {id: '<', name: '(<) Less than '},
                  // {id: '>=', name: '(>=) Greater than equal to'},
                  // {id: '<=', name: '(<=) Less than equal to'},
                  // {id: '<>', name: '(<>) Not equal to'},
                  // {id: 'LIKE', name: '(LIKE) Like'},
                  // {id: "IN", name: '(IN) Multiple Value'}
                ]}
                validate={validation.required}
              />
              {valueField == 'multipleValue' &&
                <Field
                  label='Multiple Value with "|" = value1|value2'
                  name={'search'+index+'.value'}
                  className="md-cell md-cell--3"
                  component={Textfield}
                  validate={validation.required}
                />
              }
              {valueField == 'normalValue' &&
                <Field
                  label='Value'
                  name={'search'+index+'.value'}
                  className="md-cell md-cell--3"
                  component={Textfield}
                  validate={validation.required}
                />
              }
              {valueField == 'jumlahValue' &&
                <Field
                  label="Value"
                  name={'search'+index+'.value'}
                  className="md-cell md-cell--3"
                  component={TextfieldMask}
                  money={true}
                  normalize={(e)=> parseFloat(e)}
                />
              }
              {valueField == 'tanggalValue' &&
                <Field
                  label='Value (YYYY-MM-DD)'
                  name={'search'+index+'.value'}
                  className="md-cell md-cell--3"
                  component={Textfield}
                  validate={validation.required}
                />
              }
              {/* {index != (dsl.length - 1) &&
              <Field
                label='Operator'
                name={'search'+index+'.op'}
                className="md-cell md-cell--2"
                component={Searchfield}
                validate={validation.required}
                options={[
                  {id: 'AND', name: 'AND'},
                  {id: 'OR', name: 'OR'}
                ]}
              />
              } */}
              <div  className="md-cell md-cell--2">
                <Button style={{ width: '100%', marginTop: 20 }} raised onClick={this.deleteDSL.bind(this, index)}>Hapus</Button>
              </div>
            </div>
          })
        }
        <div className="md-cell md-cell--12">
          <Button style={{ width: '100%', marginBottom: 375 }} raised onClick={this.tambahDSL.bind(this)}>Tambah Kolom</Button>
        </div>
      </div>
    )
  }

}
