import React, { Component } from 'react';
import { Grid, Cell,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Button,
  FontIcon,
  Checkbox} from 'react-md';
import { connect } from 'react-redux';
import { Field, reduxForm , propTypes, formValueSelector } from 'redux-form';
import _ from 'lodash';
import {validation, Textfield, Searchfield} from '../../../../components/form';
import DialogView from '../../../../components/entity/dialog.view';
import TemplateRoleService from './TemplateRole.service';
import CompanyService from '../Company/Company.service';
import AccessService from '../Access/Access.service';
import PermissionsService from '../Permissions/Permissions.service';


@reduxForm({form: 'template_role_dialog', destroyOnUnmount: true})
@connect((state) => ({
  company: state.auth.currentCompany,
  access: state.entity.access.api.find,
  permission: state.entity.Permissions.api.find
}))
export default class TemplateRoleView extends DialogView {
  service=TemplateRoleService;
  initialData = {
  }

  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      accessRole: {},
      defaultAccess: {}
    }
  }

  async initDefaultAccess() {
    await AccessService.api.find({size: 100, sort: 'id,ASC'}, this.props.dispatch);
    await PermissionsService.api.find({sort: 'id,ASC'}, this.props.dispatch);
  }

  async componentDidMount() {
    await this.initDefaultAccess();

    var permission = _.cloneDeep(this.props.permission);
    var access = _.cloneDeep(this.props.access);

    var accessRole = {}

    access.forEach((d, i) => {

      var p = d.permissions.reduce((prev, e)=> {
        prev[e.name] = e;
        return prev;
      }, {})

      accessRole[d.name] = {
        id: d.id,
        name: d.name,
        permissions: {}
      }

      permission.forEach((e) => {
        accessRole[d.name].permissions[e.name] = {
          id: e.id,
          name: e.name,
          checked: false,
          disabled: true
        };

        if(p[e.name]) accessRole[d.name].permissions[e.name].disabled = false;

      })
    })

    this.setState({defaultAccess:accessRole})

    this.initData(this.props);
  }

  initData(props) {
    if(props.formData.id) {
      var accessRole = _.cloneDeep(this.state.defaultAccess);

      props.formData.accesses.forEach((d, i) => {
        d.permissions.forEach((dd, ii) => {
          accessRole[d.access.name].permissions[dd.permission.name].checked = true;
        })
      })

      this.setState({accessRole: accessRole})
      this.props.initialize(props.formData);
    } else {
      this.setState({accessRole: _.cloneDeep(this.state.defaultAccess)})
      this.props.initialize({
        company: this.props.company
      });
    }
  }

  transform(value) {
    value = _.cloneDeep(value);
    value.accesses = []

    Object.keys(_.cloneDeep(this.state.accessRole)).map((objectKey, i) =>{
      var d = this.state.accessRole[objectKey];

      var access = {
        access: {
          id: d.id,
          name: d.name
        },
        permissions: []
      }

      Object.keys(d.permissions).map((key) => {
        var p = d.permissions[key];
        if(p.checked) {
         access.permissions.push({
          permission: {
            id: p.id,
            name: p.name
          }
         })
        }
      })

      if(access.permissions.length > 0) {
        value.accesses.push(access)
      }

    })

    return value;
  }

  checkboxAllChange(value) {
    Object.keys(this.state.accessRole).map((objectKey, i) => {
      let access = this.state.accessRole[objectKey];
      let permissions = access.permissions;

      this.props.permission.map((e, h) => {
        if (!permissions[e.name].disabled) {
          this.checkboxChange(value, access, permissions[e.name])
        }
      })
    })
  }

  checkboxChange(v, access, permission) {
    var accessRole = this.state.accessRole;
    accessRole[access.name].permissions[permission.name].checked = v;
    this.setState({accessRole})
  }

  companyField() {
    if(this.props.company && !this.props.company.id) {
      return <Field
      label='Company'
      name='company'
      className="md-cell md-cell--12"
      valueField='parent'
      apiPath='page'
      viewField='nama'
      disabled={this.props.company.id}
      service={CompanyService}
      component={Searchfield}
      />
    }
  }

  handleCheckAll(v, access) {
    let accessRole = this.state.accessRole;
    let permissions = accessRole[access.name].permissions
    let keys = Object.keys(permissions)

    keys.forEach((key) => {
      let permission = permissions[key]

      if (!permission.disabled) {
        this.checkboxChange(v, access, permission)
      }
    })
  }

  formView() {
    return (
      <div>
        <Grid style={{padding:'none'}}>
          {this.companyField()}
          <Field
            label='Name'
            name='name'
            className="md-cell md-cell--12"
            component={Textfield}
            validate={validation.required}
          />

          <Field
            label='Note'
            name='note'
            rows={4}
            className="md-cell md-cell--12"
            component={Textfield}
          />

          <Checkbox
            label="Check All"
            id="check-all"
            name="checkAll"
            onChange={ (value) => this.checkboxAllChange(value) }
          />
        </Grid>

        <DataTable plain>
        <TableHeader>
          <TableRow>
            <TableColumn>ACCESS NAME</TableColumn>
            <TableColumn style={{background: '#ddd', textAlign: 'center'}}>Check All</TableColumn>
            {this.props.permission.map(function (i, index){
              return <TableColumn key={index} style={{textAlign: 'center'}}>{i.name.toUpperCase()}</TableColumn>
            })}
          </TableRow>
        </TableHeader>
        <TableBody>
          {Object.keys(this.state.accessRole).map((objectKey, i) =>{
            var d = this.state.accessRole[objectKey];
            let permissions = d.permissions;
            return (
              <TableRow key={i}>
                {/* <TableColumn><Button secondary onClick={()=> {}}><FontIcon>delete</FontIcon></Button></TableColumn> */}
                <TableColumn>{d.name}</TableColumn>
                <TableColumn style={{background: '#ddd', textAlign: 'center'}}>
                  <Checkbox  id={d.name+'_checkall'} checked={d.all} onChange={(v)=> {this.handleCheckAll(v, d)}} />
                </TableColumn>
                {/* <TableColumn><Checkbox id={d.id} name={i.name+index} checked={i.all} onChange={_this.handleCheckAll.bind(_this, index)} /></TableColumn> */}
                {this.props.permission.map((e, h) => {
                  return <TableColumn label={e.name} key={h} style={{textAlign: 'center'}}><Checkbox disabled={permissions[e.name].disabled} id={e.id+d.name} name={e.id+d.name} checked={permissions[e.name].checked} onChange={(v)=> {this.checkboxChange(v,d, permissions[e.name])}} /></TableColumn>
                })}
              </TableRow>
            )
          })}
        </TableBody>
      </DataTable>
      </div>
    )
  }
}
