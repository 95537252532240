import ApiService from '../../../../../services/api.service';
import http from "../../../../../services/http.service";

class BP21SATUMASAService extends ApiService {
  name= 'BP_21_SATU_MASA';
  path= 'spt21/sml';
  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api= {
    total: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/spt21/sml/total/${sptId}`)

      return res.data;
    },
    getPegawai: async (sptId)=> {
      return await this.http.get(`${this.apiLocation}/spt21/pegawai/sml/${sptId}`, sptId)
    },
    getPensiun: async (sptId)=> {
      return await this.http.get(`${this.apiLocation}/spt21/pensiun/sml/${sptId}`, sptId)
    },
    calculatePegawai: async (sptId)=> {
      return this.http.post(`${this.apiLocation}/spt21/pegawai/sml/calculate/${sptId}`);
    },
    calculatePensiun: async (sptId)=> {
      return this.http.post(`${this.apiLocation}/spt21/pensiun/sml/calculate/${sptId}`);
    },
    updatePegawai: async (data)=> {
      return this.http.put(`${this.apiLocation}/spt21/pegawai/sml`, data);
    },
    updatePensiun: async (data)=> {
      return this.http.put(`${this.apiLocation}/spt21/pensiun/sml`, data);
    },
    downloadCsv: async (sptId)=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/csv/${sptId}`, {
        responseType: 'blob'
      })

      return res;
    },
    cetakDaftar: async (id) => {
      var res = await this.http.get(`${this.apiLocation}/spt21/sml/report/${id}`, {
        responseType: 'blob'
      })

      return res;
    },
    cetakBulk: async (ids) => {
      var res = await this.http.post(`${this.apiLocation}/spt21/sml/report/bulk`, ids, {
        responseType: 'blob'
      })

      return res;
    },
    cetakBulkMerge: async (ids) => {
      var res = await this.http.post(`${this.apiLocation}/spt21/sml/report/bulk/merge`, ids, {
        responseType: 'blob'
      })

      return res;
    },
    deleteBulk: async (sptId, ids)=> {
      let res = await http.post(`${this.apiLocation}/${this.path}/${sptId}/delete`, ids);

      return res;
    },
    downloadCsvTidakLebih: async (sptId)=> {
      var res = await this.http.get(`${this.apiLocation}/spt21/smtl/csv/${sptId}`, {
        responseType: 'blob'
      })

      return res;
    },
  }
}


export default new BP21SATUMASAService();
