import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Button, Chip, Switch} from 'react-md';

//import FormDialog from './Organization.dialog.view';
import ListView from '../../../../../../components/entity/listView';
import UtilService from '../../../../../../services/utils.service';
import download from 'downloadjs';
import uuid from 'uuid';
import iziToast from 'izitoast';
import Master26TarifService from './Master26Tarif.service';



@connect((state)=> ({
  ...Master26TarifService.stateConnectSetting()(state),
  spt: state.auth.currentSPT
}), Master26TarifService.actionConnectSetting())
export default class Master26TarifView extends ListView {
  service=Master26TarifService
  FormDialog=()=> <div/>
  rootPath = '/master/tarif-26'
  apiPath = 'page';
  addDialog=false
  editDialog=false

  columns=[
    // {isSortable: true, label: "word.company",  value: "company.nama", show:true, isSearchable:true},
    {isSortable: true, label: "word.startDate",  value: "start", show:true, isSearchable:true},
    {isSortable: true, label: "word.endDate",  value: "end", show:true, isSearchable:true},
  ]
}
