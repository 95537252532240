import React, { Component } from 'react';
import { Grid, Cell } from 'react-md';
import { Field, reduxForm , propTypes, formValueSelector } from 'redux-form';
import { validation, Textfield, TextfieldMask, convert, Searchfield } from '../../../../components/form';
import DialogView from '../../../../components/entity/dialog.view';
import AkunPembayaranService from './AkunPembayaran.service';
import BankService from '../Bank/Bank.service';
import iziToast from "izitoast";

@reduxForm({form: 'akunPembayaranDialog', destroyOnUnmount: false})
export default class AkunPembayaranDialog extends DialogView {
  service=AkunPembayaranService
  bankService=BankService

  beforeSave(value) {
    let regExAlphaNumericUnderscore = new RegExp("^[a-zA-Z0-9_-]*$")
    let regExNumericUnderscore = new RegExp("^[0-9]*$")

    let {companyid, name, accountNumber} = value

    if (!regExAlphaNumericUnderscore.test(name)) {
      iziToast.error({
        title: 'Error!',
        message: 'ID Pengguna tidak valid (abjad, nomor, strip & garis bawah).'
      })

      return false
    }

    if (!regExAlphaNumericUnderscore.test(companyid)) {
      iziToast.error({
        title: 'Error!',
        message: 'ID Perusahaan tidak valid (alpha, numeric & underscore).'
      })

      return false
    }

    if (!regExNumericUnderscore.test(accountNumber)) {
      iziToast.error({
        title: 'Error!',
        message: 'Nomor akun tidak valid (numeric).'
      })

      return false
    }

    return true
  }

  formView() {
    return (
      <Grid style={{padding:'none'}}>
        <Field
          label='Bank'
          name='bank'
          className="md-cell md-cell--12"
          component={Searchfield}
          valueField="parent"
          viewField="name"
          service={BankService}
          validate={validation.required}
        />

        <Field
          label='ID Pengguna'
          name='name'
          className="md-cell md-cell--12"
          component={Textfield}
          validate={validation.required}
        />

        <Field
          label='ID Perusahaan'
          name='companyid'
          className="md-cell md-cell--12"
          component={Textfield}
          validate={validation.required}
        />

        <Field
          label='Nomor Akun'
          name='accountNumber'
          className="md-cell md-cell--12"
          component={Textfield}
          validate={validation.required}
        />
      </Grid>
    )
  }
}
