import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  Tab,
  Tabs,
  SelectionControlGroup,
  Checkbox
} from 'react-md';

import FormView from '../../../../../components/entity/form.view';
import {validation, Textfield, Searchfield, Switch, Datepicker, TextfieldMask, convert} from '../../../../../components/form';
import { SearchField } from 'react-mpk';
import FormViewSpt from '../../../../../components/entity/form.view.spt';
import ColumnService from '../../../../../services/column.service';

import Master23KapService from '../../23_26/master/Kap/Master23Kap.service';
import Master23KjsService from '../../23_26/master/Kjs/Master23Kjs.service';

import SSP4a2Service from './SSP4a2.service';
import Spt4a2Service from '../SPT/Spt4a2.service';
import Master4a2KapService from '../master/Kap/Master4a2Kap.service';
import Master4a2KjsService from '../master/Kjs/Master4a2Kjs.service';


@reduxForm({form: 'SSP4a2Form', destroyOnUnmount: true, initialValues: {}})
@connect((state) => ({
  access      : state.entity.access.api.find,
  organization: state.auth.currentOrganization,
  spt         : state.auth.currentSPT,
  formData    : getFormValues('SSP4a2Form')(state)
}))
export default class SSP4a2Form extends FormViewSpt {
  service  = SSP4a2Service
  viewType = 2;

  constructor(props){
    super(props)
    this.state = {
      ...this.state,
      kjs     : [],
      formData: {ssp4A2s:[]}
    }
  }

  async initData() {
    if(this.props.match.params.id == 'new') {
      await this.getSummary()
      this.props.initialize(this.initialData);
    } else {
      let res          = await this.service.api.findOne(this.props.match.params.id);
          res.data.kap = res.data.kjs.kap
      await this.getSummary()
      this.props.initialize(res.data);
    }
  }

  async getSummary() {
    const sptId = this.props.match.params.sptId;
    var   res   = await Spt4a2Service.api.getSummary(sptId);
    if(res.data) {
      //
      this.setState({formData: res.data})
    }
  }

  async handleSave(value) {
    try {
      this.setState({onProgress: true})
      if(value.id) {
        await this.service.api.update(value)
      } else {
        value.spt = { id: this.props.spt.data.id }
        await this.service.api.save(value)
      }
      this.setState({onProgress: false})

      var pathArray = this.props.location.pathname.split('/');
      var path      = '';
      pathArray.forEach((d, i)=> {
        if(pathArray.length-1 != i) {
          path = path+d;

          if(i != pathArray.length-2) path = path+'/'
        }
      })

      this.props.history.push(path)
    } catch(e) {
      var msg              = e.message;
      if  (e.response) msg = e.response.data.message;
      this.setState({
        isError     : true,
        onProgress  : false,
        errorMessage: msg
      })
    }
  }

  async findKjs(evt, value){
    var kapId     = value.id;
    var kjs       = await Master4a2KjsService.api.find();
    var _kjs      = _.cloneDeep(kjs.data);
    var _kjsKapId = _kjs.filter((i) => {
      if (i.kap.code === '411128' && i.code === '402') {
        return false
      }

      return i.kap.id === kapId
    })
    this.setState({ kjs: _kjsKapId })
  }

  formView() {
    return (
      <div>
        <div className='mpk-layout row'>
          <br/>
          <Card className='flex' style={{marginBottom: 32, marginRight: 10}}>
            <CardTitle title='' style={{padding: '10px 16px'}} subtitle='DAFTAR SSP PASAL 4 (2)'/>
            <Divider/>
              <DataTable plain baseId="simple-selectable-table" indeterminate fixedHeader
                // fixedFooter
                fixedHeight={300}>
                  <TableHeader>
                  <TableRow>
                      <TableColumn>Uraian</TableColumn>
                      <TableColumn>PPH Terutang</TableColumn>
                      <TableColumn>SSP yang disetor</TableColumn>
                      {/*<TableColumn>Request ID Biling</TableColumn>*/}
                  </TableRow>
                  </TableHeader>
                  <TableBody>
                    {this.state.formData.ssp4A2s.map((d) => {
                      return <TableRow>
                        <TableColumn>{d.kap}</TableColumn>
                        <TableColumn className='md-text-right'>
                          { ColumnService.money(d.jumlah)  }
                        </TableColumn>
                        <TableColumn className='md-text-right'>
                          { ColumnService.money(d.terbayar)  }
                        </TableColumn>
                        {/*<TableColumn>-</TableColumn>*/}
                      </TableRow>
                    })}

                    <TableRow>
                      <TableColumn><strong>TOTAL</strong></TableColumn>
                      <TableColumn className='md-text-right'>
                        <strong>{ ColumnService.money(this.state.formData.jumlah)  }</strong>
                      </TableColumn>
                      <TableColumn className='md-text-right'>
                        <strong>{ ColumnService.money(this.state.formData.jumlahTerbayar)  }</strong>
                      </TableColumn>
                      {/*<TableColumn>-</TableColumn>*/}
                    </TableRow>
                  </TableBody>
              </DataTable>
          </Card>
        </div>

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='PPH TERUTNG YANG TELAH DIBAYAR' />
          <Divider/>

          <div className='md-grid'>
            <div className="md-cell md-cell--6">
              <Field
                label      = 'Kode Jenis Pajak'
                name       = 'kap'
                component  = {Searchfield}
                service    = {Master4a2KapService}
                validate   = {validation.required}
                onChange   = {this.findKjs.bind(this)}
                viewField  = "code"
                valueField = "parent"

                itemTemplate={function(d) {
                  return {
                    primaryText: `${d.code}`,
                    onClick    : () => {
                      if(!this.props.disabled){
                        this.handleItemClick(d); this.hide()
                      }
                    }
                  }
                }}
              />
              <Field
                label      = 'Kode Jenis Setoran'
                name       = 'kjs'
                component  = {Searchfield}
                validate   = {validation.required}
                rerender   = {true}
                options    = {this.state.kjs}
                viewField  = "code"
                valueField = "parent"

                itemTemplate={function(d) {
                  return {
                    primaryText  : `${d.code}`,
                    secondaryText: d.description,
                    onClick      : () => {
                      if(!this.props.disabled){
                        this.handleItemClick(d); this.hide()
                      }
                    }
                  }
                }}
              />
              <Field
                label     = 'Tanggal'
                name      = 'date'
                component = {Datepicker}
                validate  = {validation.required}
              />
            </div>

            <div className="md-cell md-cell--6">
                <Field
                    label      = 'NTPN'
                    name       = 'ntpn'
                    component  = {Textfield}
                    mask       = "_"
                    length     = {16}
                    maxLength  = {16}
                    maskFormat = "################"
                    validate   = {validation.required}
                />
                <Field
                    label     = 'Jumlah Disetor'
                    name      = 'total'
                    component = {TextfieldMask}
                    money     = {true}
                    normalize = {convert.money}
                    validate  = {validation.required}
                />
            </div>

            {/* <div className="md-cell md-cell--12">
                <ul>
                  <li>0: Untuk SSP</li>
                  <li>1: Untuk SSP PPh Pasal 21 Ditanggung Pemerintah</li>
                  <li>2: Untuk PBK</li>
                </ul>
            </div> */}
          </div>
        </Card>
        <br/>
      </div>
    )
  }

}
