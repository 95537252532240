import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm, getFormValues} from 'redux-form';
import moment from 'moment';
import { ExpansionList, ExpansionPanel, Slider } from 'react-md';

import FormView from '../../../../../components/entity/form.view'
import {validation, Textfield, Searchfield, Searchfieldv2, TextfieldMask, Switch, Datepicker, Radio, Checkbox, CheckboxSelection} from '../../../../../components/form'
import Service from './LawanTransaksi26.service'
import KodeNegara from '../KodeNegara/KodeNegara.service'
import OrganizationService from '../Organization/Organization.service';

@reduxForm({form: 'LawanTransaksi26Form', destroyOnUnmount: true})
@connect((state) => ({
  //access      : state.entity.access.api.find,
  //organization: state.auth.currentOrganization,
  spt         : state.auth.currentSPT,
  formData    : getFormValues('LawanTransaksi26Form')(state)
}))
export default class LawanTransaksi26Form extends FormView {
    service=Service
    viewType=2;

    constructor(props){
        super(props)
        this.state = {
            ...this.state,
            edit: false
        }
    }

    initialData={
        identity: false
    }
    
    async initData() {
        if(this.props.match.params.id == 'new') {
          var initData = this.initialData
          this.props.initialize(initData);
        } else {
          this.setState({
            edit: true
          })
          let res = await this.service.api.findOne(this.props.match.params.id);
          this.props.initialize(res.data);
        }
    }
  
    formView(){
        var disableOnEdit = false
        if(this.props.match.params.id != "new") disableOnEdit = true
        return (
            <ExpansionList>
                <ExpansionPanel label="Identitas Wajib Pajak yang Dipotong" defaultExpanded={true} footer={false}>
                    <div className="md-grid">
                        <Field
                            label     = 'Organization'
                            name      = 'npwpProfile'
                            className = "md-cell md-cell--12"
                            component={Searchfieldv2}
                            viewField="nama"
                            valueField='npwp'
                            service={OrganizationService}
                            remoteSearch
                            validate={validation.required}
                            disabled={disableOnEdit}
                        />
                        <Field
                            label     = 'Tax Id Number'
                            name      = 'tin'
                            className = "md-cell md-cell--12"
                            component = {Textfield}
                            validate={validation.required}
                            disabled={disableOnEdit}
                        />
                        <Field
                            label     = 'Nama'
                            name      = 'nama'
                            className = "md-cell md-cell--12"
                            component = {Textfield}
                            validate={validation.required}
                            disabled={disableOnEdit}
                        />
                        <Field
                            label     = 'Alamat'
                            name      = 'alamat'
                            className = "md-cell md-cell--12"
                            component = {Textfield}
                            validate={validation.required}
                            disabled={disableOnEdit}
                        />
                        <Field
                            label     = 'Email'
                            name      = 'email'
                            className = "md-cell md-cell--6"
                            component = {Textfield}
                            validate={validation.email}
                        />
                        <Field
                            label='Negara'
                            name='negara'
                            className="md-cell md-cell--6"
                            component={Searchfieldv2}
                            valueField='name'
                            service={KodeNegara}
                            remoteSearch
                            validate={validation.required}
                            disabled={disableOnEdit}
                        />
                        <Field
                            label     = 'Tanggal'
                            name      = 'dob'
                            className = "md-cell md-cell--6"
                            component={Datepicker}
                            validate={validation.required}
                            disabled={disableOnEdit}
                        />
                        <Field
                            label     = 'Nomor Passport'
                            name      = 'noPassport'
                            className = "md-cell md-cell--6"
                            component = {Textfield}
                            validate={validation.required}
                            disabled={disableOnEdit}
                        />
                        <Field
                            label     = 'No.KITAS/KITAP'
                            name      = 'noKitas'
                            className = "md-cell md-cell--6"
                            component = {Textfield}
                            validate={validation.required}
                            disabled={disableOnEdit}
                        />
                    </div>
                </ExpansionPanel>
            </ExpansionList>
        )
    }

}