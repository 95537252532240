import React from 'react';
import { Route, Switch } from 'react-router-dom';
import LogExportView from "../../Export/LogExport.view";
import LogEfillingView from "../../Efilling/LogEfilling.view";

export default (props) => (
  <Switch>
    <Route path={props.match.path+'/BP_23/:id'} component={require('./BP_23/BP_23.form').default} />
    <Route path={props.match.path+'/BP_23'} component={require('./BP_23/BP_23.view').default} />

    <Route path={props.match.path+'/BP_26/:id'} component={require('./BP_26/BP_26.form').default} />
    <Route path={props.match.path+'/BP_26'} component={require('./BP_26/BP_26.view').default} />

    <Route path={props.match.path+'/PBK23/:id'} component={require('./PBK/PBK23.form').default} />
    <Route path={props.match.path+'/PBK23'} component={require('./PBK/PBK23.view').default} />

    <Route path={props.match.path+'/SSP23/:id'} component={require('./SSP/SSP23.form').default} />
    <Route path={props.match.path+'/SSP23'} component={require('./SSP/SSP23.view').default} />
    <Route path={props.match.path+'/SPT23INDUK'} component={require('./Induk/Spt23Induk.form').default} />
    <Route path={props.match.path+'/FILE_LAPOR_23'} component={require('./FileLapor/FileLapor23.view').default} />

    <Route path={props.match.path+'/EXPORT_CSV_23'} component={require('./Export/ExportCsv23/ExportCsv23.view').default} />
    <Route path={props.match.path+'/LOG_EXPORT_CSV_23'} component={require('./Export/ExportCsv23Log/ExportCsv23Log.view').default} />

    {/* IMPORT */}
    <Route path={props.match.path+"/IMPORT/BP_23"} component={require('./Import/ImportBP23/ImportBP23.view').default} />
    <Route path={props.match.path+"/IMPORT/BP_26"} component={require('./Import/ImportBP26/ImportBP26.view').default} />
    <Route path={props.match.path+"/IMPORT/BP_2326"} component={require('./Import/ImportBP2326/ImportBP2326.view').default} />
    <Route path={props.match.path+"/IMPORT/IMPORT_PBK"} component={require('./Import/ImportPBK/ImportPBK23.view').default} />
    <Route path={props.match.path+"/IMPORT/SSP_23"} component={require('./Import/ImportSSP/ImportSSP23.view').default} />
    <Route path={props.match.path+"/IMPORT/INDUK_23"} component={require('./ImportBp23Induk/ImportBp23Induk.view').default} />

    {/* log export */}
    <Route path={props.match.path+"/logExport/:pasal"} render={(props) => (
      <LogExportView key={props.match.params.pasal} {...props} />
    )} />

    <Route path={props.match.path+"/eFiling"} render={(props) => (
      <LogEfillingView {...props} />
    )} />
    {/* END log export */}

    {/* LOG 21 */}
    <Route path={props.match.path+"/LOG_EMAIL"} component={require('../21_26/Email21Log/Email21Log.view').default} />
    {/* END LOG 21 */}

    {/* SETTING 2326 */}
    <Route path={props.match.path+"/SETTING_NO_BUPOT_2326"} component={require('./SettingNoBupot2326/SettingNoBupot2326.form').default} />
    {/* END SETTING 2326 */}

    <Route path={props.match.path+'/'} component={function() {return <div/>}} />
  </Switch>
)
