import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm, getFormValues} from 'redux-form';
import moment from 'moment';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  CardActions,
  Button,
  LinearProgress,
} from 'react-md';
import { ExpansionList, ExpansionPanel, Slider } from 'react-md';

import FormView from '../../../../../../../components/entity/form.view'
import {validation, Textfield, Searchfield, Searchfieldv2, TextfieldMask, Switch, Datepicker, Radio, Checkbox, CheckboxSelection} from '../../../../../../../components/form'
import UtilService from '../../../../../../../services/utils.service'
import Service from './Bupot26InPembetulan.service'
import DokumenForm from './Bupot26InPembetulan.dokumen.form.view'
import KodeBuktiPotongService from '../../../KodeBuktiPotong/KodeBuktiPotong.service'
import KodeNegara from '../../../KodeNegara/KodeNegara.service'
import Signer from '../../../Signer/Signer.service'
import izitoast from 'izitoast';
import iziToast from 'izitoast';
import ESPT23Service from '../../SPT23/SPT23.service'
import SignerService from '../../../Signer/Signer.service';
import OrganizationService from '../../../Organization/Organization.service';
import LawanTransaksi26Service from '../../../LawanTransaksi26/LawanTransaksi26.service'
import LawanTransaksi26View from '../../../LawanTransaksi26/LawanTransaksi26.view'

@reduxForm({form: 'Bupot26InPembetulan_Form', destroyOnUnmount: true})
@connect((state) => ({
  //access      : state.entity.access.api.find,
  //organization: state.auth.currentOrganization,
  spt         : state.auth.currentSPT,
  formData    : getFormValues('Bupot26InPembetulan_Form')(state)
}))
export default class Bupot26InPembetulanForm extends FormView {
    service=Service
    viewType=2;

    constructor(props){
        super(props)
        this.state = {
            ...this.state,
            tahun: [],
            masa: [],
            kodes: [],
            isNik: false,
            note: "",
            organizationId: "",
            formSpt: false,
            edit: false
        }
    }

    masa = [
        { id: 1, name: '1' },
        { id: 2, name: '2' },
        { id: 3, name: '3' },
        { id: 4, name: '4' },
        { id: 5, name: '5' },
        { id: 6, name: '6' },
        { id: 7, name: '7' },
        { id: 8, name: '8' },
        { id: 9, name: '9' },
        { id: 10, name: '10' },
        { id: 11, name: '11' },
        { id: 12, name: '12' }
    ]

    initialData={
        identity: false,
        spt: {
            pembetulan: 0
        },
        fasilitas: 0
    }

    async beforeSave(val) {
        var newValue = Object.assign({}, val)
        var split = newValue.tgl.split('-')
        var tahun = split[0]
        var bulan = split[1]
        var tahunValidasi = newValue.spt.tahun + ""
        var masaValidasi = newValue.spt.masa + ""
        if(bulan.split('')[0] == "0"){
            bulan = bulan.split('')[1]
        }
        if(tahunValidasi != tahun){
            iziToast.info({
                title: "Validasi",
                message: "Tahun pemotong tidak sesuai dengan tahun SPT seharusnya tahun = " + tahunValidasi
            })
            return false
        } else if(masaValidasi != bulan){
            iziToast.info({
                title: "Validasi",
                message: "Masa pemotong tidak sesuai dengan masa SPT seharusnya masa = " + masaValidasi
            })
            return false
        } else {
            // newValue.tgl = moment(newValue.tgl).format("YYYY-DD-MM")
            if(newValue.tarif == null){
                newValue.tarif = 0
            }
            if(newValue.netto == null){
                newValue.netto = 0
            }
            if(newValue.fasilitas == 0){
                newValue.noDtp = " "
                newValue.ntpn = " "
                newValue.noSkd = " "
                newValue.tarifSkd = 0
            } else if(newValue.fasilitas == 1) {
                newValue.noDtp = " "
                newValue.ntpn = " "
                newValue.tarif = newValue.tarifSkd
            } else if(newValue.fasilitas == 2) {
                newValue.noSkd = " "
                newValue.tarifSkd = 0
            }
            if(newValue.id){

            } else {
                newValue.seq = null
                newValue.rev = " "
                newValue.no = null
                newValue.referensi = null
                newValue.status = "CREATED"
            }
            if(newValue.refs && newValue.refs.length == 0){
                izitoast.info({
                    title: "Validasi",
                    message: "dokumen referensi wajib diisi."
                })
                return false
            } else {
                return newValue;
            }
        }
    }

    componentDidMount() {
        this.initData()
        // var tahun = new Date().getFullYear()
        // var tahuns = [
        //     { id: tahun, name: tahun + '' },
        //     { id: tahun - 1, name: tahun - 1 + '' },
        //     { id: tahun - 2, name: tahun - 2 + '' }
        // ]
        // var masa = this.masa
        // var kodes = []
        // // kodeObjectPajak.map((d, i)=> {
        // //     kodes.push({ id: d.id, name: d.name })
        // // })
        // this.setState({
        //     tahun: tahuns,
        //     masa: masa,
        //     kodes: kodes
        // })
        // this.props.change('spt.masa', this.props.spt.data.masa)
        // this.props.change('spt.tahun', this.props.spt.data.tahun)
    }

    async initData() {
        var spt = {}
        try {
            spt = await ESPT23Service.api.findOne(this.props.match.params.sptId);
            this.setState({ organizationId: spt.data.organization.id })
        } catch(e){}
        if(this.props.match.params.id == 'new') {
          var initData = this.initialData
          try {
            let getSigner = await Signer.api.getByOrganization(spt.data.organization.id)
            initData.npwpPenandatangan = getSigner.npwpPenandaTangan
            initData.namaPenandatangan = getSigner.namaPenandaTangan
            initData.signAs = getSigner.signAs
          } catch(e){}
          initData.spt = this.props.spt.data
          this.props.initialize(initData);
        } else {
          this.setState({
            formSpt: false,
            edit: true
          })
          let res = await this.service.api.findOne(this.props.match.params.id);
          res.data.agree = true
          res.data.spt = this.props.spt.data
          this.props.initialize(res.data);
        }
        this.props.change('spt', spt.data)
    }

    handleTahun(e, value){
        if(value == new Date().getFullYear()){
            this.setState({
                masa: [
                    { id: 1, name: '1' },
                    { id: 2, name: '2' },
                ]
            })
        } else {
            this.setState({ masa: this.masa })
        }
    }

    handleNik(e, value){
        if(value == false){
            this.setState({ isNik: false })
        } else {
            this.setState({ isNik: true })
        }
    }

    async handleKode(e, value){
        try {
            let getKode = await KodeBuktiPotongService.api.getByKode('kode.equals='+value)
            this.props.change('tarif', getKode.tarif)
            this.props.change('netto', getKode.netto)
            this.props.change('kode', getKode.kode)
            this.setState({note: getKode.name})
        } catch(e){
        }
    }

    async handlePenandatanganan(e, value){
        try {
            let ttd = await Signer.api.getByOrganizationQuery({
                active: true,
                'namaPenandaTangan.contains': value,
                organizationId: this.state.organizationId
            })
            this.props.change('npwpPenandatangan', ttd.data[0].npwpPenandaTangan)
            this.props.change('signAs', ttd.data[0].signAs)
        } catch(e) {

        }
    }

    handleBruto(e, value){
        var tarif = 0
        var fasilitas = 0
        var netto = 0
        var tarifSkd = 0
        var bruto = 0
        if(this.props.formData && this.props.formData && this.props.formData && this.props.formData.tarif){
            tarif = this.props.formData.tarif
        }
        if(this.props.formData && this.props.formData && this.props.formData && this.props.formData.fasilitas){
            fasilitas = this.props.formData.fasilitas
        }
        if(this.props.formData && this.props.formData && this.props.formData && this.props.formData.netto){
            netto = this.props.formData.netto
        }
        if(this.props.formData && this.props.formData && this.props.formData && this.props.formData.tarifSkd){
            tarifSkd = this.props.formData.tarifSkd
        }
        if(this.props.formData && this.props.formData && this.props.formData && this.props.formData.bruto){
            bruto = this.props.formData.bruto
        }
        if(fasilitas == 1){
            var pph = ((value * netto) / 100) * (tarifSkd/100)
            this.props.change('pph', Math.floor(pph))
        } else {
            var pph = ((value * netto) / 100) * (tarif/100)
            this.props.change('pph', Math.floor(pph))
        }
    }

    handleTarif(e, value){
        var tarif = 0
        var fasilitas = 0
        var netto = 0
        var tarifSkd = 0
        var bruto = 0
        if(this.props.formData && this.props.formData && this.props.formData && this.props.formData.tarif){
            tarif = this.props.formData.tarif
        }
        if(this.props.formData && this.props.formData && this.props.formData && this.props.formData.fasilitas){
            fasilitas = this.props.formData.fasilitas
        }
        if(this.props.formData && this.props.formData && this.props.formData && this.props.formData.netto){
            netto = this.props.formData.netto
        }
        if(this.props.formData && this.props.formData && this.props.formData && this.props.formData.tarifSkd){
            tarifSkd = this.props.formData.tarifSkd
        }
        if(this.props.formData && this.props.formData && this.props.formData && this.props.formData.bruto){
            bruto = this.props.formData.bruto
        }
        if(fasilitas == 1){
            var pph = ((bruto * netto) / 100) * (tarifSkd/100)
            this.props.change('pph', Math.floor(pph))
        } else {
            var pph = ((bruto * netto) / 100) * (value/100)
            this.props.change('pph', Math.floor(pph))
        }
    }

    handleTarifSkd(e, value){
        this.props.change('tarif', value)
        var tarif = 0
        var fasilitas = 0
        var netto = 0
        var tarifSkd = 0
        var bruto = 0
        if(this.props.formData && this.props.formData && this.props.formData && this.props.formData.tarif){
            tarif = this.props.formData.tarif
        }
        if(this.props.formData && this.props.formData && this.props.formData && this.props.formData.fasilitas){
            fasilitas = this.props.formData.fasilitas
        }
        if(this.props.formData && this.props.formData && this.props.formData && this.props.formData.netto){
            netto = this.props.formData.netto
        }
        if(this.props.formData && this.props.formData && this.props.formData && this.props.formData.tarifSkd){
            tarifSkd = this.props.formData.tarifSkd
        }
        if(this.props.formData && this.props.formData && this.props.formData && this.props.formData.bruto){
            bruto = this.props.formData.bruto
        }
        if(fasilitas == 1){
            var pph = ((bruto * netto) / 100) * (value/100)
            this.props.change('pph', Math.floor(pph))
        } else {
            var pph = ((bruto * netto) / 100) * (tarif/100)
            this.props.change('pph', Math.floor(pph))
        }
    }

    handleNetto(e, value){
        var tarif = 0
        var fasilitas = 0
        var netto = 0
        var tarifSkd = 0
        var bruto = 0
        if(this.props.formData && this.props.formData && this.props.formData && this.props.formData.tarif){
            tarif = this.props.formData.tarif
        }
        if(this.props.formData && this.props.formData && this.props.formData && this.props.formData.fasilitas){
            fasilitas = this.props.formData.fasilitas
        }
        if(this.props.formData && this.props.formData && this.props.formData && this.props.formData.netto){
            netto = this.props.formData.netto
        }
        if(this.props.formData && this.props.formData && this.props.formData && this.props.formData.tarifSkd){
            tarifSkd = this.props.formData.tarifSkd
        }
        if(this.props.formData && this.props.formData && this.props.formData && this.props.formData.bruto){
            bruto = this.props.formData.bruto
        }
        if(fasilitas == 1){
            var pph = ((bruto * value) / 100) * (tarifSkd/100)
            this.props.change('pph', Math.floor(pph))
        } else {
            var pph = ((bruto * value) / 100) * (tarif/100)
            this.props.change('pph', Math.floor(pph))
        }
    }

    handleIsNik(e, value){
        this.props.change('npwp', " ")
        this.props.change('nik', value)
    }

    handleIsNpwp(e, value){
        this.props.change('npwp', value)
        this.props.change('nik', " ")
    }

    handleFasilitas(e, value){
        // if(value == 0){
        //     this.props.change('noDtp', " ")
        //     this.props.change('ntpn', " ")
        //     this.props.change('noSkd', " ")
        //     this.props.change('tgl', "2019-02-28")
        // } else if(value == 1) {
        //     this.props.change('noDtp', " ")
        //     this.props.change('ntpn', " ")
        // } else if(value == 2) {
        //     this.props.change('noSkd', " ")
        //     this.props.change('tgl', "2019-02-28")
        // }
        if(value == 1){
            this.props.change('tarif', 0)
        } else {
            this.props.change('tarifSkd', 0)
        }
    }

    _barItem() {
        const { handleSubmit, submitting, valid, pristine } = this.props;
        var edit = false
        if(this.props.match.params.id != 'new') edit = true
        if(this.props.formData && this.props.formData.status && this.props.formData.status == 'FAILED') edit = false
        return (
          <div>
            <Button secondary flat onClick={()=> {this.props.history.goBack()}} style={{marginRight: 16}}>Back</Button>

            <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>Save</Button>
          </div>
        )
    }

    async handleSave(value) {
        var redirectTo = this.redirectTo
        var askPermission = await this.askPermission(value)
        if(askPermission){
          var beforeSave = await this.beforeSave(value);
          if(beforeSave) {
            if(typeof beforeSave === 'object') value = beforeSave;
            try {
              this.setState({onProgress: true})
              if(value.id) {
                if(this.props.match.params.id == 'new'){
                //   await this.service.api.save(value)
                } else {
                    var id = this.props.match.params.id
                    var data = JSON.parse(localStorage.getItem("pembetulanBp26"))
                    data.map((d, index)=> {
                        if(d.id == id){
                            data[index] = Object.assign({}, value)
                        }
                    })
                    localStorage.setItem("pembetulanBp26", JSON.stringify(data))
                //   await this.service.api.update(value)
                }
              } else {
                // await this.service.api.save(value)
              }
              this.setState({onProgress: false})
              // redirect
              if(redirectTo){
                var pathArray = this.props.location.pathname.split('/');
                var path = '';
                pathArray.forEach((d, i)=> {
                  if(pathArray.length-1 != i) {
                    path = path+d;
                    if(i != pathArray.length-2) path = path+'/'
                  }
                })
                if(this.props.match.path.indexOf('ebupot-23') == -1){
                    path = "/" + path.split('/')[1]
                }
                this.props.history.push(path)
              } else {
                izitoast.success({
                  title: 'Success',
                  message: 'Data telah tersimpan'
                })
              }
            } catch(e) {
              var msg = e.message;
              if(e.response) msg = e.response.data.message;
              this.setState({
                isError: true,
                onProgress: false,
                errorMessage: msg
              })
            }
          }
        } else {
          if(value.id){
            izitoast.error({
              title: "Update",
              message: "Forbidden, Permission Denied"
            })
          } else {
            izitoast.error({
              title: "Create",
              message: "Forbidden, Permission Denied"
            })
          }
        }
    }

    async cariSpt(){
        try {
            var organizationId = this.props.formData.organization.id
            // let getOrganization = await OrganizationService.api.getOrgByNpwp(this.props.formData.spt.npwp)
            // organizationId = getOrganization.data[0].id
            try {
                let cekSpt = await ESPT23Service.api.querySpt23({
                    organizationId: organizationId,
                    masa: this.props.formData.spt.masa,
                    tahun: this.props.formData.spt.tahun,
                    pembetulan: this.props.formData.spt.pembetulan
                })
                var data = cekSpt.data
                this.props.change('spt', data)
                this.setState({
                    formSpt: false
                })
                let getSigner = await Signer.api.getByOrganization(data.organization.id)
                this.props.change('npwpPenandatangan', getSigner.npwpPenandaTangan)
                this.props.change('namaPenandaTangan', getSigner.namaPenandaTangan)
                this.props.change('signAs', getSigner.signAs)
            } catch(e){
                iziToast.info({
                    title: "Info",
                    message: "Spt tidak ditemukan, " + e.message
                })
            }
        } catch(e){
            iziToast.info({
                title: "Info",
                message: "Organisasi tidak ditemukan, " + e.message
            })
        }
    }

    async lawanTransaksiDialog(){
        var res = await this.context.showDialog((props, resolve, reject)=> ({
            title: null,
            initialValue: {},
            okText: ' ',
            cancelText: 'Tutup',
            width: 800,
            height: 600,
            text: (
              <div style={{height: 498}}>
                <LawanTransaksi26View parent={this} dialogProps={props} dialogMode={true} match={this.props.match} service={LawanTransaksi26Service} />
              </div>
            )
        }))
    }

    formView(){
        var nik = this.state.isNik
        var fasilitas = 0
        if(this.props.formData && this.props.formData.fasilitas) fasilitas = this.props.formData.fasilitas
        var refs = []
        if(this.props.formData && this.props.formData.refs) refs = this.props.formData.refs
        var date = new Date();
        let yearOptions = [];
        for(var i=0; i<11; i++) {
            yearOptions.push({
                id: date.getFullYear()-i,
                name: date.getFullYear()-i
            })
        }
        // disabledAll
        var formSptExpanded = this.state.formSpt
        var formOther = false
        if(formSptExpanded){
            formOther = false
        } else {
            formOther = true
        }
        // disabledAll
        var _this = this
        return (
            <ExpansionList>
                {/* <ExpansionPanel label="Form SPT" defaultExpanded={formSptExpanded} footer={false}>
                    <div className="md-grid">
                        <Field
                            label='Organization'
                            name='organization'
                            id="spt2126-organization"
                            className="md-cell md-cell--12"
                            // disabled={this.props.organization.id != null}
                            params     = {{
                                size: 50
                            }}
                            remoteSearch = {true}
                            valueField='parent'
                            service={OrganizationService}
                            searchField  = {['nama']}
                            component={Searchfield}
                            itemTemplate={function(d) {
                                let nameAndAlias = ""
                                if (d.nama)
                                nameAndAlias = d.nama
                                if (d.aliasNama)
                                nameAndAlias += " - " + d.aliasNama
                                return {
                                    primaryText  : nameAndAlias,
                                    secondaryText: d.npwp,
                                    onClick: async () => {
                                        let nameAndAlias = ""
                                        if (d.nama)
                                        nameAndAlias = d.nama
                                        if (d.aliasNama)
                                        nameAndAlias += " - " + d.aliasNama
                                        _this.props.change('spt.organization.npwp', d.npwp)
                                        _this.props.change('spt.organization.nama', nameAndAlias)
                                        _this.setState({ organizationId: d.id })
                                        if(!this.props.disabled){
                                            this.handleItemClick(d); this.hide()
                                        }
                                    }
                                }
                            }}
                        />
                        <Field
                            label='Npwp'
                            name='spt.organization.npwp'
                            className="md-cell md-cell--6"
                            component={TextfieldMask}
                            maskFormat="##.###.###.#-###-###"
                            mask="_"
                            length="15"
                            validate={validation.required}
                            disabled={formOther}
                        />
                        <Field
                            label='Nama'
                            name='spt.organization.nama'
                            className="md-cell md-cell--6"
                            component={Textfield}
                            validate={validation.required}
                            disabled={formOther}
                        />
                        <Field
                            label='Pembetulan'
                            name='spt.pembetulan'
                            className="md-cell md-cell--4"
                            component={Textfield}
                            validate={validation.required}
                            disabled={true}
                        />
                        <Field
                            label='Masa'
                            name='spt.masa'
                            className="md-cell md-cell--4"
                            component={Searchfield}
                            validate={validation.required}
                            disabled={formOther}
                            options={[
                                {id: 1, name: 'Januari'},
                                {id: 2, name: 'Februari'},
                                {id: 3, name: 'Maret'},
                                {id: 4, name: 'April'},
                                {id: 5, name: 'Mei'},
                                {id: 6, name: 'Juni'},
                                {id: 7, name: 'Juli'},
                                {id: 8, name: 'Agustus'},
                                {id: 9, name: 'September'},
                                {id: 10, name: 'Oktober'},
                                {id: 11, name: 'November'},
                                {id: 12, name: 'Desember'}
                            ]}
                        />
                        <Field
                            label='Tahun'
                            name='spt.tahun'
                            className="md-cell md-cell--4"
                            component={Searchfield}
                            validate={validation.required}
                            options={yearOptions}
                            disabled={formOther}
                        />
                        {!this.state.edit &&
                            <div className="md-cell md-cell--12">
                                <Button raised primary onClick={this.props.handleSubmit(this.cariSpt.bind(this))}>Cari SPT</Button>
                                <Button raised style={{ marginLeft: '10px' }} onClick={()=> {
                                    this.props.initialize(this.initialData)
                                    this.setState({
                                        formSpt: true
                                    })
                                }}>Hapus</Button>
                            </div>
                        }
                    </div>
                </ExpansionPanel>
                <br /> */}
                <ExpansionPanel label="Detail Bupot" expanded={formOther} footer={false}>
                    <div className="md-grid">
                        <Field
                            label     = 'Nomor Bupot'
                            name      = 'no'
                            className = "md-cell md-cell--6"
                            component = {Textfield}
                            disabled  = {true}
                        />
                        <Field
                            label     = 'Rev'
                            name      = 'rev'
                            className = "md-cell md-cell--6"
                            component = {Textfield}
                            type      = 'number'
                            disabled  = {true}
                        />
                    </div>
                </ExpansionPanel>
                <br />
                <ExpansionPanel label="Identitas Wajib Pajak yang Dipotong" expanded={formOther} footer={false}>
                    <div className="md-grid">
                        <Field
                            label     = 'Pilih Lawan Transaksi'
                            name      = 'lt26'
                            className = "md-cell md-cell--10"
                            component={Searchfield}
                            searchField  = {['nama']}
                            viewField="nama"
                            valueField='tin'
                            service={LawanTransaksi26Service}
                            parent={_this}
                            itemTemplate={function(d) {
                                if(!d.tin) d.tin = " "
                                return {
                                  primaryText  : d.nama,
                                  secondaryText: d.tin,
                                  onClick: () => {
                                      try { this.props.parent.props.change("nama", d.nama)               } catch(e){  }
                                      try { this.props.parent.props.change("alamat", d.alamat)           } catch(e){  }
                                      try { this.props.parent.props.change("tin", d.tin)                 } catch(e){  }
                                      try { this.props.parent.props.change("email", d.email)             } catch(e){  }
                                      try { this.props.parent.props.change("dob", d.dob)                 } catch(e){  }
                                      try { this.props.parent.props.change("negara", d.negara)           } catch(e){  }
                                      try { this.props.parent.props.change("noPassport", d.noPassport)   } catch(e){  }
                                      try { this.props.parent.props.change("noKitas", d.noKitas)         } catch(e){  }
                                      this.handleItemClick(d); this.hide()
                                  }
                              }
                            }}
                            remoteSearch
                        />
                        <div className="md-cell md-cell--2">
                            <Button style={{ marginTop: 20 }} onClick={this.lawanTransaksiDialog.bind(this)} fullWidth raised primary label="Search" iconChildren="search"></Button>
                        </div>
                        {/* <Field
                            label     = 'Tahun'
                            name      = 'spt.tahun'
                            className = "md-cell md-cell--6"
                            component = {Searchfield}
                            options   = {this.state.tahun}
                            rerender
                            validate  = {validation.required}
                            onChange  = {this.handleTahun.bind(this)}
                        />
                        <Field
                            label     = 'Masa'
                            name      = 'spt.masa'
                            className = "md-cell md-cell--6"
                            component = {Searchfield}
                            options   = {this.state.masa}
                            rerender
                            validate  = {validation.required}
                        /> */}
                        <Field
                            label     = 'Tax Id Number'
                            name      = 'tin'
                            className = "md-cell md-cell--12"
                            component = {Textfield}
                            validate={validation.required}
                        />
                        <Field
                            label     = 'Nama'
                            name      = 'nama'
                            className = "md-cell md-cell--12"
                            component = {Textfield}
                        />
                        <Field
                            label     = 'Alamat'
                            name      = 'alamat'
                            className = "md-cell md-cell--12"
                            component = {Textfield}
                        />
                        <Field
                            label     = 'Email'
                            name      = 'email'
                            className = "md-cell md-cell--6"
                            component = {Textfield}
                        />
                        <Field
                            label='Negara'
                            name='negara'
                            className="md-cell md-cell--6"
                            component={Searchfieldv2}
                            valueField='name'
                            service={KodeNegara}
                            remoteSearch
                        />
                        <Field
                            label     = 'Tanggal'
                            name      = 'dob'
                            className = "md-cell md-cell--6"
                            component={Datepicker}
                        />
                        <Field
                            label     = 'Nomor Passport'
                            name      = 'noPassport'
                            className = "md-cell md-cell--6"
                            component = {Textfield}
                        />
                        <Field
                            label     = 'No.KITAS/KITAP'
                            name      = 'noKitas'
                            className = "md-cell md-cell--6"
                            component = {Textfield}
                        />
                    </div>
                </ExpansionPanel>
                <br />
                <ExpansionPanel label="Dokumen Referensi" expanded={formOther} footer={false}>
                    <DokumenForm parent={this} formDataParent={refs} />
                    <div className="md-grid">
                        <Field
                            label     = 'Fasilitas'
                            name      = 'fasilitas'
                            className = "md-cell md-cell--12"
                            component = {CheckboxSelection}
                            options  = {[
                                {value: 0, label: "Tanpa Fasilitas"},
                                {value: 1, label: "PPh Pasal 26 dikenakan tarif sesuai Perjajian Perdagangan Pajak Berganda (P3B)"},
                                {value: 2, label: "PPh Pasal 26 Ditanggung Oleh Pemerintah (DTP) berdasarkan"}
                            ]}
                            validate={validation.required2}
                            onChange={this.handleFasilitas.bind(this)}
                            formData={this.props.formData}
                            skipValues= {true}
                        />
                        {fasilitas == 1 &&
                            <Field
                                label     = 'Nomor SKD'
                                name      = 'noSkd'
                                className = "md-cell md-cell--6"
                                component = {Textfield}
                                validate={validation.required}
                            />
                        }
                        {fasilitas == 1 &&
                            <Field
                                label     = 'Tarif Skd'
                                name      = 'tarifSkd'
                                className = "md-cell md-cell--6"
                                component = {TextfieldMask}
                                money
                                onChange={this.handleTarifSkd.bind(this)}
                                normalize = {(e)=> parseFloat(e)}
                                validate={validation.required}
                            />
                        }
                        {/* {fasilitas == 1 &&
                            <Field
                                label     = 'Tanggal'
                                name      = 'tglSkd'
                                className = "md-cell md-cell--6"
                                component={Datepicker}
                                inline={true}
                                validate={validation.required}
                            />
                        } */}
                        {fasilitas == 2 &&
                            <Field
                                label     = 'Dokumen PPh DTP'
                                name      = 'noDtp'
                                className = "md-cell md-cell--6"
                                component={Textfield}
                                validate={validation.required}
                            />
                        }
                        {fasilitas == 2 &&
                            <Field
                                label     = 'Nomor NTPN'
                                name      = 'ntpn'
                                className = "md-cell md-cell--6"
                                component={Textfield}
                                validate={validation.required}
                            />
                        }
                    </div>
                </ExpansionPanel>
                <br />
                <ExpansionPanel label="Pajak Penghasilan yang dipotong" expanded={formOther} footer={false}>
                    <div className="md-grid">
                        <Field
                            label='Kode Object Pajak'
                            name='kode'
                            className="md-cell md-cell--12"
                            component={Searchfieldv2}
                            searchField={['name']}
                            valueField='kode'
                            viewField='kode'
                            viewField2='name'
                            service={KodeBuktiPotongService}
                            remoteSearch
                            params={{ 'jenis.equals': '26' }}
                            validate={validation.required}
                            onChange={this.handleKode.bind(this)}
                        />
                        <div className="md-cell md-cell--12">
                            <p>{this.state.note}</p>
                        </div>
                        <Field
                            label='Jumlah Penghasilan Bruto'
                            name='bruto'
                            className="md-cell md-cell--12"
                            component={TextfieldMask}
                            money
                            validate={validation.required}
                            onChange={this.handleBruto.bind(this)}
                            normalize={(e)=> parseFloat(e)}
                        />
                        <Field
                            label='Netto ( % )'
                            name='netto'
                            className="md-cell md-cell--6"
                            component={TextfieldMask}
                            money
                            onChange={this.handleNetto.bind(this)}
                            normalize={(e)=> parseFloat(e)}
                        />
                        <Field
                            label='Tarif ( % )'
                            name='tarif'
                            className="md-cell md-cell--6"
                            component={TextfieldMask}
                            money
                            onChange={this.handleTarif.bind(this)}
                            normalize={(e)=> parseFloat(e)}
                            disabled={fasilitas == 1}
                        />
                        <Field
                            label='PPh yang Dipotong / DTP'
                            name='pph'
                            className="md-cell md-cell--6"
                            component={TextfieldMask}
                            money
                            disabled
                            normalize={(e)=> parseFloat(e)}
                        />
                    </div>
                </ExpansionPanel>
                <br />
                <ExpansionPanel label="Identitas Pemotong Pajak" expanded={formOther} footer={false}>
                    <div className="md-grid">
                    <Field
                            label='Nama Penandatanganan'
                            name='namaPenandatangan'
                            className="md-cell md-cell--6"
                            component={Searchfieldv2}
                            valueField='namaPenandaTangan'
                            viewField='namaPenandaTangan'
                            viewField2='npwpPenandaTangan'
                            service={Signer}
                            remoteSearch
                            params={{ 'active': true, organizationId: this.state.organizationId  }}
                            validate={validation.required}
                            apiPath="getByOrganizationQuery"
                            onChange={this.handlePenandatanganan.bind(this)}
                        />
                        <div className="md-cell md-cell--6">
                            <p>Penandatanganan</p>
                            <hr />
                            <Field
                                label='Bertindak Sebagai'
                                name='signAs'
                                component={Searchfield}
                                options={[
                                    {id: true, name: 'Wajib Pajak / Wakil Wajib Pajak'},
                                    {id: false, name: 'Kuasa'}
                                ]}
                            />
                        </div>
                        <Field
                            label='Npwp Penandatanganan'
                            name='npwpPenandatangan'
                            className="md-cell md-cell--6"
                            component={TextfieldMask}
                            maskFormat="##.###.###.#-###-###"
                            validate={validation.required}
                        />
                        {/* <Field
                            label='Nama'
                            name='rev'
                            className="md-cell md-cell--6"
                            component={Searchfield}
                            options={[
                                {id: '0', name: 'Kosong'}
                            ]}
                        /> */}
                        <Field
                            label='Tanggal Pemotong'
                            name='tgl'
                            className="md-cell md-cell--6"
                            component={Datepicker}
                            inline={true}
                            validate={validation.required}
                        />
                        <Field
                            className="md-cell md-cell--12"
                            label='Dengan menyadari sepenuhnya akan segala akibatnya termasuk sanksi-saksi sesuai dengan ketentuan perundang undangan yang berlaku,
                            saya menyatakan bahwa apa yang telah saya beritahukan di atas beserta lampiran-lampirannya adalah benar, lengkap dan jelas'
                            name='agree'
                            component={Checkbox}
                            validate={validation.required}
                        />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>
                </ExpansionPanel>
            </ExpansionList>
        )
    }

}

// {
//     "bagA": {
//       "alamat": "string",
//       "dob": "2019-03-04",
//       "email": "string",
//       "id": "string",
//       "nama": "string",
//       "negara": "string",
//       "noKitas": "string",
//       "noPassport": "string",
//       "tin": "string"
//     },
//     "bagB": {
//       "bruto": 0,
//       "kode": "string",
//       "netto": 0,
//       "pph": 0,
//       "tarif": 0
//     },
//     "fasilitas": 0,
//     "namaPenandatangan": "string",
//     "no": "string",
//     "noDtp": "string",
//     "noSkd": 0,
//     "npwpPenandatangan": "string",
//     "ntpn": "string",
//     "referensi": "string",
//     "refs": [
//       {
//         "Bupot26InPembetulan": {},
//         "jenis": "string",
//         "noDok": "string",
//         "tgl": "2019-03-04"
//       }
//     ],
//     "rev": 0,
//     "seq": 0,
//     "signAs": true,
//     "spt": {
//       "masa": 0,
//       "nama": "string",
//       "npwp": "string",
//       "organizationId": "string",
//       "pembetulan": 0,
//       "sebelumnya": "string",
//       "status": "string",
//       "tahun": 0
//     },
//     "status": "CREATED",
//     "tgl": "2019-03-04"
//   }
