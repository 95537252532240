import React, { Component } from 'react';
import { connect } from 'react-redux';

import ListView from '../../../../../../components/entity/listView';
import ColumnService from '../../../../../../services/column.service';
import TextField, { Button, Divider, Chip, Card, CardTitle, CardActions, DataTable, TableHeader, TableBody, TableRow, TableColumn, Switch, Paper } from 'react-md';
import {validation, Searchfield, TextfieldMask, Datepicker} from '../../../../../../components/form';
import { Field, reduxForm } from 'redux-form';
import download from 'downloadjs';
import uuid from 'uuid';
import iziToast from 'izitoast';
import { Commandbar, Table, TableSetting, Empty, DialogConfirm } from 'react-mpk';
import http from '../../../../../../services/http.service'
import ExportCsv23Service from './ExportCsv23.service';
import BP_23Service from '../../BP_23/BP_23.service';
import BP_26Service from '../../BP_26/BP_26.service';
import SSP23Service from '../../SSP/SSP23.service';
import PBK23Service from '../../PBK/PBK23.service';
import SPT23IndukService from '../../Induk/Spt23Induk.service'

@reduxForm({form: 'EXPORT_CSV_23', destroyOnUnmount: true})
@connect((state)=> ({
  ...ExportCsv23Service.stateConnectSetting()(state),
  spt: state.auth.currentSPT
}), ExportCsv23Service.actionConnectSetting())
export default class FileLapor23View extends ListView {
  service=ExportCsv23Service

  constructor(props){
    super(props);
    this.state = {
      showTableSetting:false,
      showDialogConfirmDeleteSelected:false,
      showForm:false,
      formData: {},
      data: [],
      cetak: false,
      showDialog: false,
      category: null,
      total23: 0,
      total26: 0,
      totalSSP: 0,
      totalPBK: 0,
      totalInduk: 1,
    }
  }

  columns=[]
  _barActions=[]
  _barItem() {}

  handleExport(category){
      this.setState({ showDialog: true })
      this.setState({ category: category })
  }

  async export(){
    var sptId = this.props.spt.data.id
    var category = this.state.category
    var csv = {};
    try {
      switch (category) {
        case 'BP_23':
          csv = await BP_23Service.api.downloadCsv(sptId);
          break;
        case 'BP_26':
          csv = await BP_26Service.api.downloadCsv(sptId);
          break;
        case 'BP_23_SSP':
          csv = await SSP23Service.api.downloadCsv(sptId);
          break;
        case 'BP_23_PBK':
          csv = await PBK23Service.api.downloadCsv(sptId);
          break;
        case 'BP_23_INDUK':
          csv = await SPT23IndukService.api.downloadCsv(sptId);
          break;
        default:
          break;
      }
      var filename = csv.headers.filename
      download(csv.data, filename);
    } catch (error) {

    }
  }

  async componentDidMount(){
    var sptId = this.props.spt.data.id

    let info = await this.service.api.getInfo(sptId);

    // let total26 = await BP_26Service.api.find(null, ()=> {}, {
    //   path: BP_26Service.path+'/'+sptId+'/page'
    // })
    // let totalSSP = await SSP23Service.api.find(null, ()=> {}, {
    //   path: SSP23Service.path+sptId+'/page'
    // })
    // let totalPBK = await PBK23Service.api.find(null, ()=> {}, {
    //   path: PBK23Service.path+'/'+sptId+'/page'
    // })

    if(info.data) {
      this.setState({
        total23: info.data['23'],
        total26: info.data['26'],
        totalSSP: info.data.ssp,
        totalPBK: info.data.pbk
      })
    }

  }

  _tableView(props) {
      const _this = this
      return (
        <Card className="flex mpk-layout">
            <DialogConfirm
                title={'word.export'}
                message={'sentence.custom.export'}
                visible={this.state.showDialog}
                onSubmit={(callback) => {
                    _this.export()
                    callback();
                }}
                onCancel={() => this.setState({showDialog:false})}
                translate={true}
            />
            <CardTitle title='' style={{padding: '10px 16px'}} subtitle='Informasi File Export'/>
            <Divider/>
            <DataTable plain fixedScrollWrapperStyle={{overflow: 'inherit'}} style={{overflow: 'inherit'}}>
              <TableHeader>
                <TableRow>
                    <TableColumn>Jumlah Data</TableColumn>
                    <TableColumn></TableColumn>
                </TableRow>
              </TableHeader>
              <TableBody>
                <TableRow>
                    <TableColumn>{this.state.total23} Unit</TableColumn>
                    <TableColumn>
                        <Button disabled={this.state.total23 == 0} onClick={this.handleExport.bind(this, 'BP_23')} style={{width: '100%'}} primary raised>Export Data BP 23</Button>
                    </TableColumn>
                </TableRow>
                <TableRow>
                    <TableColumn>{this.state.total26} Unit</TableColumn>
                    <TableColumn>
                        <Button disabled={this.state.total26 == 0} onClick={this.handleExport.bind(this, 'BP_26')} style={{width: '100%'}} primary raised>Export Data BP 26</Button>
                    </TableColumn>
                </TableRow>
                <TableRow>
                    <TableColumn>{this.state.totalSSP} Unit</TableColumn>
                    <TableColumn>
                        <Button disabled={this.state.totalSSP == 0} onClick={this.handleExport.bind(this, 'BP_23_SSP')} style={{width: '100%'}} primary raised>Export Data SSP</Button>
                    </TableColumn>
                </TableRow>
                <TableRow>
                    <TableColumn>{this.state.totalPBK} Unit</TableColumn>
                    <TableColumn>
                        <Button disabled={this.state.totalPBK == 0} onClick={this.handleExport.bind(this, 'BP_23_PBK')} style={{width: '100%'}} primary raised>Export Data PBK</Button>
                    </TableColumn>
                </TableRow>
                <TableRow>
                  <TableColumn>{this.state.totalInduk} Unit</TableColumn>
                  <TableColumn>
                    <Button disabled={this.state.totalInduk == 0} onClick={this.handleExport.bind(this, 'BP_23_INDUK')} style={{width: '100%'}} primary raised>Export Data Induk</Button>
                  </TableColumn>
                </TableRow>
              </TableBody>
            </DataTable>
        </Card>
      )
  }
}
