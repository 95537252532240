import React, { Component } from 'react'; import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  Divider,
  Chip,
  Card,
  CardTitle,
  CardActions,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Switch
} from 'react-md';
import { Field, reduxForm, getFormValues, initialize } from 'redux-form';
import {validation, FileInputNext, Searchfieldv2, Textfield, Searchfield, TextfieldMask, Datepicker, Radio, convert, CheckboxSelection, Multiselect} from '../../../../../../../components/form';
import ColumnService from '../../../../../../../services/column.service';
import download from 'downloadjs';
import uuid from 'uuid';
import iziToast from 'izitoast';
import UtilService from '../../../../../../../services/utils.service'

//import FormDialog from './Organization.dialog.view';
import SspEbupotService from './ssp.service';
import ListView from '../../../../../../../components/entity/ListViewEbupot'
// import SPT2326Service from '../SPT/23_26.spt.service';
// import FileLapor21Service from '../FileLapor21/FileLapor21.service';

@reduxForm({form: 'SspEbupot', destroyOnUnmount: false})
@connect((state)=> ({
  ...SspEbupotService.stateConnectSetting()(state),
  spt: state.auth.currentSPT,
  formData: getFormValues('SspEbupot')(state)
}), SspEbupotService.actionConnectSetting())
export default class SspEbupotView extends ListView {
  service=SspEbupotService
  FormDialog=()=> <div/>

  addDialog=false
  editDialog=false

  constructor(props){
    super(props);
    this.state = {
      ...this.state,
      cetak: false,
      total: {},
      djp: {},
      formData: {
        "jumlah23": 0,
        "jumlah26": 0,
        "jumlahTerbayar23": 0,
        "jumlahTerbayar26": 0,
        "ssp23s": [
          // {
          //   "jumlah": 0,
          //   "map": "string",
          //   "terbayar": 0
          // }
        ],
        "ssp26": [
          // {
          //   "jumlah": 0,
          //   "map": "string",
          //   "terbayar": 0
          // }
        ]
      }
    }
  }

  viewType=2

  header() {
    return (
      <div>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle='DATA SSP TELAH DIBAYAR' />
        <Divider/>
      </div>
    )
  }

  fetchOption() {
    const sptId = this.props.match.params.sptId
    return {
        path: SspEbupotService.path+'/'+sptId+'/espt23'
    }
  }

  columns=SspEbupotService.columns

  async beforeFetch(params) {
    delete params.startDate
    delete params.endDate
    delete params.column
    delete params.total
    delete params.keyword
    // delete params.sortBy
    // params.sortBy = 'id'
    // params.sort = 1
    try {
      const sptId = this.props.match.params.sptId
      var res = await this.service.api.getSummary(sptId);
      if(res.data) {
        this.setState({formData: res.data})
      }
    } catch(e){
      iziToast.error({
        title: "Error",
        message: "Fetch data summary failed, " + e.message
      })
    }
  }

  async afterFetch(params){
    const sptId = this.props.match.params.sptId
    var total = await SspEbupotService.api.summary(sptId)
    this.setState({
      total: total
    })
    var djp = await SspEbupotService.api.djp(sptId)
    this.setState({
      djp: djp.data
    })
  }

  async componentDidMount() {
    this.rootPath = this.props.match.url;
    const sptId = this.props.match.params.sptId
    var res = await this.service.api.getSummary(sptId);
    if(res.data) {
      this.setState({formData: res.data})
    }
    // var total = await SspEbupotService.api.summary(sptId)
    // this.setState({
    //   total: total
    // })
    // var djp = await SspEbupotService.api.djp(sptId)
    // this.setState({
    //   djp: djp.data
    // })
  }

  async handleCetakDaftar(){
    this.setState({ cetak: true })
    try {
      const sptId = this.props.match.params.sptId
      var report = await this.service.api.cetak(sptId)
      var filename = report.headers.filename
      download(report.data, filename);
      this.setState({ cetak: false })
    } catch (e){
      this.setState({ cetak: false })
    }
  }

  async deleteSelectedDataItems(){
    var selected = this.props.table.selected

    var ids = [];

    selected.map(function (i){
      ids.push(i.id)
    })

    const sptId = this.props.match.params.sptId

    var res = await this.service.api.deleteBulk(sptId, ids)

    this.fetchData()
  }

  _barItem() {
    return (
      <div>

        {/* <Button disabled={this.state.cetak} primary raised style={{margin: 5}} onClick={this.handleCetakDaftar.bind(this)}>Cetak Daftar</Button> */}

        <Switch
          style={{float: "right"}}
          id="switch-table-setting"
          name="Switch Table Setting"
          label=""
          checked={this.state.showTableSetting}
          onChange={() => {
            this.setState({
              showTableSetting:!this.state.showTableSetting
            })
          }}
        />
      </div>
    )
  }

  totalBruto() {
    var total = 0;
    if(this.state.total.total){
      total = this.state.total.total;
    }
    return total;
  }

  totalPph() {
    var total = 0;
    if(this.state.total.pph){
      total = this.state.total.pph;
    }
    return total;
  }

  footer() {
    return <div className="flex-none mpk-border thin solid top dark">
      <div className="mpk-padding-N left right">
        <table style={{width: '100%'}}>
          <tfoot className="md-table-footer">
            <tr className="md-table-column md-text-left md-table-column--data md-table-column--adjusted md-text ">
              <td>Total</td>
              <td className=" md-text-right">{UtilService.number.format(this.totalBruto(), {precision: 0, thousand: '.', decimal: ','})}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  }

  additionalTopContent() {
    var djpLoading = true
    var daftarSetoran = []
    var jumlah23 = 0
    var jumlah26 = 0
    var message = ""
    if(this.state.djp && this.state.djp.status == true) djpLoading = false
    if(this.state.djp && this.state.djp.message) message = this.state.djp.message
    if(this.state.djp && this.state.djp.detail && this.state.djp.detail && this.state.djp.detail.lamp2){
      if(this.state.djp.detail.lamp2.daftarSetor && this.state.djp.detail.lamp2.daftarSetor.setoran) daftarSetoran = this.state.djp.detail.lamp2.daftarSetor.setoran
      if(this.state.djp.detail.lamp2.jmlSetorPsl23) jumlah23 = this.state.djp.detail.lamp2.jmlSetorPsl23
      if(this.state.djp.detail.lamp2.jmlSetorPsl26) jumlah26 = this.state.djp.detail.lamp2.jmlSetorPsl26
    }
    var daftarTeutang = [];
    var jmlBrutoPsl23 = 0;
    var jmlBrutoPsl26 = 0;
    var jmlPPhDipotongPsl23 = 0;
    var jmlPPhDipotongPsl26 = 0;
    var jmlPsl23KsMinDtp = 0;
    var jmlPsl23KsPbtl = 0;
    var jmlPsl26KsMinDtp = 0;
    var jmlPsl26KsPbtl = 0;
    if(this.state.djp && this.state.djp.detail && this.state.djp.detail && this.state.djp.detail.bagianB){
      if(this.state.djp.detail.bagianB.daftarPotPut && this.state.djp.detail.bagianB.daftarPotPut.potPut) daftarTeutang = this.state.djp.detail.bagianB.daftarPotPut.potPut
      if(this.state.djp.detail.bagianB.jmlBrutoPsl23){
        jmlBrutoPsl23 = this.state.djp.detail.bagianB.jmlBrutoPsl23
      }
      if(this.state.djp.detail.bagianB.jmlBrutoPsl26){
        jmlBrutoPsl26 = this.state.djp.detail.bagianB.jmlBrutoPsl26
      }
      if(this.state.djp.detail.bagianB.jmlPPhDipotongPsl23){
        jmlPPhDipotongPsl23 = this.state.djp.detail.bagianB.jmlPPhDipotongPsl23
      }
      if(this.state.djp.detail.bagianB.jmlPPhDipotongPsl26){
        jmlPPhDipotongPsl26 = this.state.djp.detail.bagianB.jmlPPhDipotongPsl26
      }
      if(this.state.djp.detail.bagianB.jmlPsl23KsMinDtp){
        jmlPsl23KsMinDtp = this.state.djp.detail.bagianB.jmlPsl23KsMinDtp
      }
      if(this.state.djp.detail.bagianB.jmlPsl23KsPbtl){
        jmlPsl23KsPbtl = this.state.djp.detail.bagianB.jmlPsl23KsPbtl
      }
      if(this.state.djp.detail.bagianB.jmlPsl26KsMinDtp){
        jmlPsl26KsMinDtp = this.state.djp.detail.bagianB.jmlPsl26KsMinDtp
      }
      if(this.state.djp.detail.bagianB.jmlPsl26KsPbtl){
        jmlPsl26KsPbtl = this.state.djp.detail.bagianB.jmlPsl26KsPbtl
      }
    }
    return (
      <div>
        <div className='mpk-layout row'>
          <br/>
          <Card className='flex' style={{marginBottom: 32, marginRight: 10}}>
            <CardTitle title='' style={{padding: '10px 16px'}} subtitle='DAFTAR SSP PASAL 23'/>
            <Divider/>
              <DataTable plain baseId="simple-selectable-table" indeterminate>
                  <TableHeader>
                    <TableRow>
                        <TableColumn>Uraian</TableColumn>
                        <TableColumn>PPH Terutang</TableColumn>
                        <TableColumn>SSP yang disetor</TableColumn>
                        {/*<TableColumn>Request ID Biling</TableColumn>*/}
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {this.state.formData.ssp23s.map((d) => {
                      return <TableRow>
                        <TableColumn>{d.map}</TableColumn>
                        <TableColumn className='md-text-right'>
                          { ColumnService.money(d.jumlah)  }
                        </TableColumn>
                        <TableColumn className='md-text-right'>
                          { ColumnService.money(d.terbayar)  }
                        </TableColumn>
                        {/*<TableColumn>-</TableColumn>*/}
                      </TableRow>
                    })}

                    <TableRow>
                      <TableColumn><strong>TOTAL</strong></TableColumn>
                      <TableColumn className='md-text-right'>
                        <strong>{ ColumnService.money(this.state.formData.jumlah23)  }</strong>
                      </TableColumn>
                      <TableColumn className='md-text-right'>
                        <strong>{ ColumnService.money(this.state.formData.jumlahTerbayar23)  }</strong>
                      </TableColumn>
                      {/*<TableColumn>-</TableColumn>*/}
                    </TableRow>
                  </TableBody>
              </DataTable>
          </Card>
          <Card className='flex' style={{marginBottom: 32, marginLeft: 10}}>
            <CardTitle title='' style={{padding: '10px 16px'}} subtitle='DAFTAR SSP PASAL 26'/>
            <Divider/>
              <DataTable plain baseId="simple-selectable-table" indeterminate>
                  <TableHeader>
                  <TableRow>
                      <TableColumn>Uraian</TableColumn>
                      <TableColumn>PPH Terutang</TableColumn>
                      <TableColumn>SSP yang disetor</TableColumn>
                      {/*<TableColumn>Request ID Biling</TableColumn>*/}
                  </TableRow>
                  </TableHeader>
                  <TableBody>
                    {this.state.formData.ssp26.map((d) => {
                      return <TableRow>
                        <TableColumn>{d.map}</TableColumn>
                        <TableColumn className='md-text-right'>
                          { ColumnService.money(d.jumlah)  }
                        </TableColumn>
                        <TableColumn className='md-text-right'>
                          { ColumnService.money(d.terbayar)  }
                        </TableColumn>
                        {/*<TableColumn>-</TableColumn>*/}
                      </TableRow>
                    })}

                    <TableRow>
                      <TableColumn><strong>TOTAL</strong></TableColumn>
                      <TableColumn className='md-text-right'>
                        <strong>{ ColumnService.money(this.state.formData.jumlah26)  }</strong>
                      </TableColumn>
                      <TableColumn className='md-text-right'>
                        <strong>{ ColumnService.money(this.state.formData.jumlahTerbayar26)  }</strong>
                      </TableColumn>
                      {/*<TableColumn>-</TableColumn>*/}
                    </TableRow>
                  </TableBody>
              </DataTable>
          </Card>
        </div>
        <div className="mpk-layout row">
          <br/>
          <Card className='flex' style={{marginBottom: 32, marginRight: 10}}>
              <CardTitle title='' style={{padding: '10px 16px'}} subtitle='Terutang'/>
              <Divider/>
              <DataTable plain baseId="simple-selectable-table" indeterminate>
                {(djpLoading && !message) &&
                  <TableHeader>
                    <TableRow>
                        <TableColumn colSpan={3}>Waiting response from DJP ...</TableColumn>
                    </TableRow>
                  </TableHeader>
                }
                {(djpLoading && message) &&
                  <TableHeader>
                    <TableRow>
                        <TableColumn colSpan={3}>{message}</TableColumn>
                    </TableRow>
                  </TableHeader>
                }
                {!djpLoading &&
                  <TableHeader>
                    <TableRow>
                        <TableColumn>Kode Akun Pajak</TableColumn>
                        <TableColumn>Kode Jenis Setor</TableColumn>
                        <TableColumn>Jumlah Setor</TableColumn>
                    </TableRow>
                  </TableHeader>
                }
                {!djpLoading &&
                  <TableBody>
                    {daftarTeutang.map((d,i)=> {
                      return (
                        <TableRow key={i}>
                          <TableColumn>{d.kdAkunPajak}</TableColumn>
                          <TableColumn>{d.kdJnsSetor}</TableColumn>
                          <TableColumn className='md-text-right'>{ ColumnService.money(parseFloat(d.jmlDipotong)) }</TableColumn>
                      </TableRow>
                      )
                    })}
                    <TableRow>
                      <TableColumn><strong>TOTAL BRUTO BP 23</strong></TableColumn>
                      <TableColumn className='md-text-right'></TableColumn>
                      <TableColumn className='md-text-right'>
                        <strong>{ ColumnService.money(jmlBrutoPsl23) }</strong>
                      </TableColumn>
                    </TableRow>
                    <TableRow>
                      <TableColumn><strong>TOTAL BRUTO BP 26</strong></TableColumn>
                      <TableColumn className='md-text-right'></TableColumn>
                      <TableColumn className='md-text-right'>
                        <strong>{ ColumnService.money(jmlBrutoPsl26) }</strong>
                      </TableColumn>
                    </TableRow>
                    <TableRow>
                      <TableColumn><strong>TOTAL PPH DIPOTONG BP 23</strong></TableColumn>
                      <TableColumn className='md-text-right'></TableColumn>
                      <TableColumn className='md-text-right'>
                        <strong>{ ColumnService.money(jmlPPhDipotongPsl23) }</strong>
                      </TableColumn>
                    </TableRow>
                    <TableRow>
                      <TableColumn><strong>TOTAL PPH DIPOTONG BP 26</strong></TableColumn>
                      <TableColumn className='md-text-right'></TableColumn>
                      <TableColumn className='md-text-right'>
                        <strong>{ ColumnService.money(jmlPPhDipotongPsl26) }</strong>
                      </TableColumn>
                    </TableRow>
                    <TableRow>
                      <TableColumn><strong>TOTAL MIN DTP BP 23</strong></TableColumn>
                      <TableColumn className='md-text-right'></TableColumn>
                      <TableColumn className='md-text-right'>
                        <strong>{ ColumnService.money(jmlPsl23KsMinDtp) }</strong>
                      </TableColumn>
                    </TableRow>
                    <TableRow>
                      <TableColumn><strong>TOTAL MIN DTP BP 26</strong></TableColumn>
                      <TableColumn className='md-text-right'></TableColumn>
                      <TableColumn className='md-text-right'>
                        <strong>{ ColumnService.money(jmlPsl26KsMinDtp) }</strong>
                      </TableColumn>
                    </TableRow>
                    <TableRow>
                      <TableColumn><strong>TOTAL KURANG SETOR BP 23</strong></TableColumn>
                      <TableColumn className='md-text-right'></TableColumn>
                      <TableColumn className='md-text-right'>
                        <strong>{ ColumnService.money(jmlPsl23KsPbtl) }</strong>
                      </TableColumn>
                    </TableRow>
                    <TableRow>
                      <TableColumn><strong>TOTAL KURANG SETOR BP 26</strong></TableColumn>
                      <TableColumn className='md-text-right'></TableColumn>
                      <TableColumn className='md-text-right'>
                        <strong>{ ColumnService.money(jmlPsl26KsPbtl) }</strong>
                      </TableColumn>
                    </TableRow>
                  </TableBody>
                }
              </DataTable>
          </Card>
          <Card className='flex' style={{marginBottom: 32, marginLeft: 10}}>
              <CardTitle title='' style={{padding: '10px 16px'}} subtitle='Setor'/>
              <Divider/>
              <DataTable plain baseId="simple-selectable-table" indeterminate>
                {(djpLoading && !message) &&
                  <TableHeader>
                    <TableRow>
                        <TableColumn colSpan={3}>Waiting response from DJP ...</TableColumn>
                    </TableRow>
                  </TableHeader>
                }
                {(djpLoading && message) &&
                  <TableHeader>
                    <TableRow>
                        <TableColumn colSpan={3}>{message}</TableColumn>
                    </TableRow>
                  </TableHeader>
                }
                {!djpLoading &&
                  <TableHeader>
                    <TableRow>
                        <TableColumn>Kode Akun Pajak</TableColumn>
                        <TableColumn>Kode Jenis Setor</TableColumn>
                        <TableColumn>Jumlah Setor</TableColumn>
                    </TableRow>
                  </TableHeader>
                }
                {!djpLoading &&
                  <TableBody>
                    {daftarSetoran.map((d,i)=> {
                      return (
                        <TableRow key={i}>
                          <TableColumn>{d.kdAkunPajak}</TableColumn>
                          <TableColumn>{d.kdJnsSetor}</TableColumn>
                          <TableColumn className='md-text-right'>{ ColumnService.money(parseFloat(d.jmlSetor)) }</TableColumn>
                      </TableRow>
                      )
                    })}
                    <TableRow>
                      <TableColumn><strong>TOTAL BP 23</strong></TableColumn>
                      <TableColumn className='md-text-right'></TableColumn>
                      <TableColumn className='md-text-right'>
                        <strong>{ ColumnService.money(jumlah23) }</strong>
                      </TableColumn>
                    </TableRow>
                    <TableRow>
                      <TableColumn><strong>TOTAL BP 26</strong></TableColumn>
                      <TableColumn className='md-text-right'></TableColumn>
                      <TableColumn className='md-text-right'>
                        <strong>{ ColumnService.money(jumlah26) }</strong>
                      </TableColumn>
                    </TableRow>
                  </TableBody>
                }
              </DataTable>
          </Card>
        </div>
      </div>
    )

  }

  // NEW TAB MENU
  async hapusPencarian(){
    try {
      var params = Object.assign({}, params=this.props.table.params)
      this.fetchData(params)
      await this.props.dispatch(initialize(this.service.name+'_search', {}));
    } catch(e){
    }
  }

  async handleSearching(values){
    try {
      var formData = this.props.tableFilter2
      var params = Object.assign({}, params=this.props.table.params)

      if(formData.kjsCode){
        params['kjsCode.contains'] = formData.kjsCode
      } else { delete params['kjsCode.contains'] }

      if(formData.ntpn){
        params['ntpn.contains'] = formData.ntpn
      } else { delete params['ntpn.contains'] }

      if(formData.total){
        params['total.equals'] = formData.total
      } else { delete params['total.equals'] }

      if(formData.createdBy){
        params['createdBy.contains'] = formData.createdBy
      } else { delete params['createdBy.contains'] }

      if(formData.date1){
        params['date.greaterOrEqualThan'] = moment(formData.date1).format()
      } else { delete params['date.greaterOrEqualThan'] }

      if(formData.date2){
        params['date.lessOrEqualThan'] = moment(formData.date2).format()
      } else { delete params['date.lessOrEqualThan'] }

      if(formData.createdDate1){
        params['createdDate.greaterOrEqualThan'] = moment(formData.createdDate1).format()
      } else { delete params['createdDate.greaterOrEqualThan'] }

      if(formData.createdDate2){
        params['createdDate.lessOrEqualThan'] = moment(formData.createdDate2).format()
      } else { delete params['createdDate.lessOrEqualThan'] }

      this.fetchData(params)
    } catch(e){
      iziToast.info({
        title: "Pencarian",
        message: "Data tidak ditemukan, " + e.message
      })
    }
  }

  defaultSearchForm(){
    const { handleSubmit, submitting, valid, pristine } = this.props;
    return (
      <div className="md-grid" style={{ overflow: 'auto' }}>
        <Field
          label     = 'Kode KJS'
          name      = 'kjsCode'
          className = "md-cell md-cell--12"
          component = {Textfield}
        />
        <Field
          label     = 'NTPN'
          name      = 'ntpn'
          className = "md-cell md-cell--12"
          component = {Textfield}
        />
        <Field
          label     = 'Total'
          name      = 'total'
          className = "md-cell md-cell--12"
          component = {Textfield}
        />
        <Field
          label     = 'Tanggal dari'
          name      = 'date1'
          className = "md-cell md-cell--12"
          component = {Datepicker}
        />
        <Field
          label     = 'Tanggal ke'
          name      = 'date2'
          className = "md-cell md-cell--12"
          component = {Datepicker}
        />
        <Field
          label     = 'Dibuat Oleh'
          name      = 'createdBy'
          className = "md-cell md-cell--6"
          component = {Textfield}
        />
        <Field
          label     = 'Dibuat dari'
          name      = 'createdDate1'
          className = "md-cell md-cell--12"
          component = {Datepicker}
        />
        <Field
          label     = 'Dibuat ke'
          name      = 'createdDate2'
          className = "md-cell md-cell--12"
          component = {Datepicker}
        />
        <Button raised primary className="md-cell md-cell--12" onClick={handleSubmit(this.handleSearching.bind(this))}>Cari</Button>
        <hr />
        <Button raised primary className="md-cell md-cell--12" onClick={handleSubmit(this.hapusPencarian.bind(this))}>Hapus</Button>
      </div>
    )
  }
  // yg ditambah
  // NEW TAB MENU

}
