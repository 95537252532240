import ApiService from '../../../../../../services/api.service';
import { splitDecimal } from 'react-number-format/lib/utils';

class ExportCsv25Service extends ApiService {
  name= 'ExportCsv25';
  path= 'spt25';
  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api={
    getInfo: async (sptId) => {
      return await this.http.get(`${this.apiLocation}/${this.path}/info/${sptId}/export`)
    },
  }

}

export default new ExportCsv25Service();
