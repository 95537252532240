import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Button, Chip, Switch} from 'react-md';

//import FormDialog from './Organization.dialog.view';
import ListView from '../../../../../../components/entity/listView';
import UtilService from '../../../../../../services/utils.service';
import download from 'downloadjs';
import uuid from 'uuid';
import iziToast from 'izitoast';
import Master23TarifLainService from './Master23TarifLain.service';
import FormDialog from './Master23TarifLain.dialog';
import ParamService from "../../../../../../services/param.service";


@connect((state)=> ({
  ...Master23TarifLainService.stateConnectSetting()(state),
  spt: state.auth.currentSPT
}), Master23TarifLainService.actionConnectSetting())
export default class Master23LainView extends ListView {
  service=Master23TarifLainService
  FormDialog=FormDialog

  rootPath = '/master/tarif-23-lain'
  apiPath = 'page';

  async handleExportCsv() {
    let selected = this.props.table.selected

    if (selected.length > 0) {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export CSV',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: 'Apakah anda yakin akan meng-export CSV data yang diceklis?'
      }));

      if(dialog) {
        let ids = [];

        selected.map(function (i) {
          ids.push(i.id)
        });

        let res = await this.service.api.exportBulkCsv(ids);

        let filename = res.headers.filename;

        download(res.data, filename);
      }
    }
    else {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export CSV',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: (
          <div>
            <this.ig.Field
              label='Kode'
              name='kode__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Nama'
              name='nama__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
          </div>
        ),
      }));

      if (dialog === false)
        return

      if (dialog === undefined)
        dialog = {}

      if (dialog) {
        let param = ParamService.convert(dialog)

        let res = await this.service.api.exportByParamCsv(param);

        let filename = res.headers.filename;

        download(res.data, filename);
      }
    }
  }

  barActions() {
    return [
      ...this._barActions,
      {
        label:'word.exportCsv',
        iconClassName:'mdi mdi-file-excel',
        // disabledFunc:() => this.props.table.selected.length === 0,
        onClick:() => this.handleExportCsv()
      },
    ]
  }

  defaultColumns = [
    {isSortable:true, label: "word.id",  value: "id", show:false, isSearchable:true, isDefaultSort: false},
  ]

  columns=[
    {isSortable: true, label: "word.code",  value: "kode", show:true, isSearchable:true, isDefaultSort: true},
    {isSortable: true, label: "word.name",  value: "nama", show:true, isSearchable:true},
    {isSortable: true, label: "word.tarif",  type: "func", value: (d) => {
      return d.tarif
    }, show:true, isSearchable:true},
  ]
}
