import React, { Component } from 'react';
import { Field, reduxForm , propTypes, formValueSelector } from 'redux-form';
import { validation, Textfield, TextfieldMask, Datepicker, convert } from '../../../../../../components/form';
import Service from './ImportSsp25.service';
import ImportDialogTemplateView from '../../../../../../components/entity/Import.dialog.template.view';

@reduxForm({form: 'ImportSSP25-form', destroyOnUnmount: false})
export default class ImportSSP25DialogView extends ImportDialogTemplateView {
  service=Service
}
