import ApiService from "../../../../../../services/api.service";

class Master22KomoditiService extends ApiService {
  name= 'Master22Komoditi';
  path= 'spt22/master/komoditi';

  constructor() {
    super();
    this.init()
  }

  api = {
    byCompany: async (data) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/company`, {
        params: data
      });
      return res.data;
    },

    getMaster: async () => {
      var res = await this.http.get(`${this.apiLocation}/spt22/master/komoditi`);

      return res.data;
    }
  }
}


export default new Master22KomoditiService();
