import React, { Component } from 'react'; import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {TabsContainer, Tabs, Tab} from 'react-md';
import { Field } from 'redux-form';

import UserService from './User.service';
import ListView from '../../../../../components/entity/ListViewEbupot';
import AuthService from '../../../../../services/auth.service'
import FormDialog from './User.form.view'

@connect((state)=> ({
  ...UserService.stateConnectSetting()(state)
}), UserService.actionConnectSetting())
export default class UserView extends ListView {
  service=UserService;

  rootPath = this.props.match.url
  editDialog = false;
  addDialog = false;

  columns=UserService.columns

}