import ApiService from '../../../../../services/api.service';

class SettingEmailService extends ApiService {
  name = 'SettingEmail';
  path = 'setting/email/page';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api = {
    getByCompany: async (companyId) => {
      return await this.http.get(`${this.apiLocation}/setting/email/company/` + companyId)
    },
    updateByCompany: async (formData) => {
      return await this.http.put(`${this.apiLocation}/setting/email`, formData)
    },
    delete: async (id) => {
      return await this.http.delete(`${this.apiLocation}/setting/email/${id}`)
    },
    deleteAll: async (ids) => {
      return await this.http.post(`${this.apiLocation}/setting/email/delete`, ids)
    },
  }
}


export default new SettingEmailService();

