import ApiService from '../../../../../services/api.service';

class ExportCsv21LogService extends ApiService {
  name= 'ExportCsv21Log';
  path= 'log/csv';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api= {
    validasi: async (fileId, page) => {
      if(page){
        page = page
      } else {
        page = 0
      }
      var res = await this.http.get(this.apiLocation+'/validation-csv', {
        params: {
          fileId : fileId,
          page: page,
          size: 100
        }
      })

      return res.data;
    }
  }
}


export default new ExportCsv21LogService();
