import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm, getFormValues  } from 'redux-form';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  Tab,
  Tabs,
  SelectionControlGroup,
  Checkbox,
  Button
} from 'react-md';

import FormView from '../../../../../components/entity/form.view';
import BP21DAFTARBIAYAService from './BP_21_DAFTAR_BIAYA.service';
import {validation, Textfield, Searchfield, Switch, Radio, convert, TextfieldMask} from '../../../../../components/form';
import download from 'downloadjs';
import uuid from 'uuid';
import izitoast from 'izitoast';

@reduxForm({form: 'BP_21_DAFTAR_BIAYA.Form', destroyOnUnmount: true})
@connect((state) => ({
  access: state.entity.access.api.find,
  organization: state.auth.currentOrganization,
  spt: state.auth.currentSPT,
  formData: getFormValues('BP_21_DAFTAR_BIAYA.Form')(state)
}))
export default class BP21DAFTARBIAYAForm extends FormView {
    service=BP21DAFTARBIAYAService
    viewType =2;

    async initData() {
        var sptId = this.props.match.params.sptId
        let res = await this.service.api.findOne(sptId);
        this.props.initialize(res.data);
    }

    componentWillReceiveProps(nextProps){
        if( JSON.stringify(this.props.formData) !== JSON.stringify(nextProps.formData) ){
            // sum
            var f = nextProps.formData;
            var total = f.no1 + f.no2 + f.no3 + f.no4 + f.no5 + f.no6 + f.no7 + f.no8 + f.no9 + f.no10;
            nextProps.change("total", total)
            // end sum
        }
    }

    async handleCetakDaftar(){
        this.setState({ cetakDaftar: true })
        try {
          var sptId = this.props.match.params.sptId
          var report = await this.service.api.cetakDaftar(sptId)
          var filename = report.headers.filename
          download(report.data, filename);
          this.setState({ cetakDaftar: false })
        } catch (e) {
          const reader = new FileReader();

          /// This fires after the blob has been read/loaded.
          reader.addEventListener('loadend', (e) => {
            const text = e.srcElement.result
            let json = JSON.parse(text)

            if (json.detail)
              iziToast.error({
                title: 'Error',
                message: json.detail
              })
          });

          reader.readAsText(e.response.data)

          this.setState({cetakDaftar: false})
        }
      }

    _barItem() {
        const { handleSubmit, submitting, valid, pristine } = this.props;
        let statusSPT = "";
        let statusInSPT = false;
        if(this.props.spt){
            if(this.props.spt.data){
                if(this.props.spt.data.status){
                    statusSPT = this.props.spt.data.status;
                    statusInSPT = true
                }
            }
        }
        
        if(statusSPT == "FINISH" || statusSPT == "WAITING"){
            return (
                <div>
                  <Button disabled={this.state.cetakDaftar} primary raised style={{marginRight: 5}} onClick={this.handleCetakDaftar.bind(this)}>Cetak</Button>
                </div>
            )
        } else {
            if(statusSPT == "FINISH" || statusSPT == "WAITING"){
                return (
                    <div>
                        <Button secondary flat onClick={()=> {this.props.history.goBack()}} style={{marginRight: 16}}>Back</Button>
                    </div>
                )
            } else {
                if(statusInSPT){
                    return (
                        <div>
                            <Button disabled={this.state.cetakDaftar} primary raised style={{marginRight: 5}} onClick={this.handleCetakDaftar.bind(this)}>Cetak</Button>
                            <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>Save</Button>
                        </div>
                    )
                } else {
                    return (
                        <div>
                            <Button secondary flat onClick={()=> {this.props.history.goBack()}} style={{marginRight: 16}}>Back</Button>
                        </div>
                    )
                }
            }    
        }
      }

    async handleSave(value) {
        // sum
        var f = value
        var total = f.no1 + f.no2 + f.no3 + f.no4 + f.no5 + f.no6 + f.no7 + f.no8 + f.no9 + f.no10;
        value.total = total
        // end sum
        try {
          this.setState({onProgress: true})
          if(value.id) {
            await this.service.api.update(value)
          } else {
            await this.service.api.save(value)
          }
          this.setState({onProgress: false})
          izitoast.success({title: 'success', message: 'Saved'})

          var pathArray = this.props.location.pathname.split('/');
          var path = '';
          pathArray.forEach((d, i)=> {
            if(pathArray.length-1 != i) {
              path = path+d;
              if(i != pathArray.length-2) path = path+'/'
            }
          })
          this.initData()
        } catch(e) {
          var msg = e.message;
          if(e.response) msg = e.response.data.message;
          this.setState({
            isError: true,
            onProgress: false,
            errorMessage: msg
          })
        }
      }

    formView(){
        return (
            <div>
                <Card>
                    <Divider/>
                    <DataTable plain>
                        <TableBody>
                        <TableRow>
                            <TableColumn>1.</TableColumn>
                            <TableColumn>Gaji Upah</TableColumn>
                            <TableColumn></TableColumn>
                            <TableColumn>
                            <Field
                                name='no1'
                                component={TextfieldMask} money={true} normalize={convert.money}
                            />
                            </TableColumn>
                        </TableRow>
                        <TableRow>
                            <TableColumn>2.</TableColumn>
                            <TableColumn>Biaya Transportasi</TableColumn>
                            <TableColumn></TableColumn>
                            <TableColumn>
                            <Field
                                name='no2'
                                component={TextfieldMask} money={true} normalize={convert.money}
                            />
                            </TableColumn>
                        </TableRow>
                        <TableRow>
                            <TableColumn>3.</TableColumn>
                            <TableColumn>Biaya Penyusutan Amortisasi</TableColumn>
                            <TableColumn></TableColumn>
                            <TableColumn>
                            <Field
                                name='no3'
                                component={TextfieldMask} money={true} normalize={convert.money}
                            />
                            </TableColumn>
                        </TableRow>
                        <TableRow>
                            <TableColumn>4.</TableColumn>
                            <TableColumn>Biaya Sewa</TableColumn>
                            <TableColumn></TableColumn>
                            <TableColumn>
                            <Field
                                name='no4'
                                component={TextfieldMask} money={true} normalize={convert.money}
                            />
                            </TableColumn>
                        </TableRow>
                        <TableRow>
                            <TableColumn>5.</TableColumn>
                            <TableColumn>Biaya Bunga Pinjaman</TableColumn>
                            <TableColumn></TableColumn>
                            <TableColumn>
                            <Field
                                name='no5'
                                component={TextfieldMask} money={true} normalize={convert.money}
                            />
                            </TableColumn>
                        </TableRow>
                        <TableRow>
                            <TableColumn>6.</TableColumn>
                            <TableColumn>Biaya Sehubungan dengan Jasa</TableColumn>
                            <TableColumn></TableColumn>
                            <TableColumn>
                            <Field
                                name='no6'
                                component={TextfieldMask} money={true} normalize={convert.money}
                            />
                            </TableColumn>
                        </TableRow>
                        <TableRow>
                            <TableColumn>7.</TableColumn>
                            <TableColumn>Biaya Piutang Tak Tertagih</TableColumn>
                            <TableColumn></TableColumn>
                            <TableColumn>
                            <Field
                                name='no7'
                                component={TextfieldMask} money={true} normalize={convert.money}
                            />
                            </TableColumn>
                        </TableRow>
                        <TableRow>
                            <TableColumn>8.</TableColumn>
                            <TableColumn>Biaya Royalti</TableColumn>
                            <TableColumn></TableColumn>
                            <TableColumn>
                            <Field
                                name='no8'
                                component={TextfieldMask} money={true} normalize={convert.money}
                            />
                            </TableColumn>
                        </TableRow>
                        <TableRow>
                            <TableColumn>9.</TableColumn>
                            <TableColumn>Biaya Pemasaran</TableColumn>
                            <TableColumn></TableColumn>
                            <TableColumn>
                            <Field
                                name='no9'
                                component={TextfieldMask} money={true} normalize={convert.money}
                            />
                            </TableColumn>
                        </TableRow>
                        <TableRow>
                            <TableColumn>10.</TableColumn>
                            <TableColumn>Biaya Lainnya</TableColumn>
                            <TableColumn></TableColumn>
                            <TableColumn>
                            <Field
                                name='no10'
                                component={TextfieldMask} money={true} normalize={convert.money}
                            />
                            </TableColumn>
                        </TableRow>
                        <TableRow>
                            <TableColumn>11.</TableColumn>
                            <TableColumn>Total</TableColumn>
                            <TableColumn></TableColumn>
                            <TableColumn>
                            <Field
                                name='total'
                                component={TextfieldMask} money={true} normalize={convert.money} disabled={true}
                            />
                            </TableColumn>
                        </TableRow>
                        </TableBody>
                    </DataTable>
                </Card>
                <br/>
            </div>
        )
    }
}
