import React from 'react';
import { Route, Switch } from 'react-router-dom';
import LogExportView from "../../Export/LogExport.view";
import LogEfillingView from "../../Efilling/LogEfilling.view";

export default (props) => (
  <Switch>
    <Route path={props.match.path+'/BP_4a2/:id'} component={require('./BP4a2/BP4a2.form').default} />
    <Route path={props.match.path+'/BP_4a2'} component={require('./BP4a2/BP4a2.view').default} />

    <Route path={props.match.path+'/daftar_pemotong'} component={require('./TabunganDeposito/BP4a2TabunganDeposito.form').default} />

    <Route path={props.match.path+'/sewa_tanah/:id'} component={require('./DSSewaTanah/BP4a2DSSewaTanah.form').default} />
    <Route path={props.match.path+'/sewa_tanah'} component={require('./DSSewaTanah/BP4a2DSSewaTanah.view').default} />

    <Route path={props.match.path+'/jasa_konstruksi/:id'} component={require('./DSJasaKonstruksi/BP4a2DSJasaKonstruksi.form').default} />
    <Route path={props.match.path+'/jasa_konstruksi'} component={require('./DSJasaKonstruksi/BP4a2DSJasaKonstruksi.view').default} />

    <Route path={props.match.path+'/hak_atas_tanah/:id'} component={require('./DSPengalihanTanah/BP4a2DSPengalihanTanah.form').default} />
    <Route path={props.match.path+'/hak_atas_tanah'} component={require('./DSPengalihanTanah/BP4a2DSPengalihanTanah.view').default} />

    <Route path={props.match.path+'/SSP4a2/:id'} component={require('./SSP/SSP24a2.form').default} />
    <Route path={props.match.path+'/SSP4a2'} component={require('./SSP/SSP4a2.view').default} />

    <Route path={props.match.path+'/PBK4a2/:id'} component={require('./PBK/PBK4a2.form').default} />
    <Route path={props.match.path+'/PBK4a2'} component={require('./PBK/PBK4a2.view').default} />

    <Route path={props.match.path+'/SPT4a2INDUK'} component={require('./Induk/Spt4a2Induk.form').default} />
    <Route path={props.match.path+'/FILE_LAPOR_4a2'} component={require('./FileLapor/FileLapor4a2.view').default} />

    <Route path={props.match.path+'/EXPORT_CSV_4a2'} component={require('./Export/ExportCsv4a2/ExportCsv4a2.view').default} />
    <Route path={props.match.path+'/LOG_EXPORT_CSV_4a2'} component={require('./Export/ExportCsv4a2Log/ExportCsv4a2Log.view').default} />

    <Route path={props.match.path+"/IMPORT/BP_4a2"} component={require('./Import/ImportBP4a2/ImportBP4a2.view').default} />
    <Route path={props.match.path+"/IMPORT/BP_4a2_GABUNG"} component={require('./Import/ImportBP4a2Gabung/ImportBP4a2Gabung.view').default} />
    <Route path={props.match.path+"/IMPORT/DAFTAR_PEMOTONGAN_4a2"} component={require('./Import/ImportDaftarPemotongan4a2/ImportDaftarPemotongan4a2.view').default} />
    <Route path={props.match.path+"/IMPORT/PBK_4a2"} component={require('./Import/ImportPBK/ImportPBK4a2.view').default} />
    <Route path={props.match.path+"/IMPORT/INDUK_PT_4a2"} component={require('./Import/ImportInduk4a2PenghasilanTertentu/ImportInduk4a2PenghasilanTertentu.view').default} />
    <Route path={props.match.path+"/IMPORT/SSP_4a2"} component={require('./Import/ImportSSP/ImportSSP4a2.view').default} />
    <Route path={props.match.path+"/IMPORT/INDUK_4a2"} component={require('./ImportBp4a2Induk/ImportBp4a2Induk.view').default} />

    {/* log export */}
    <Route path={props.match.path+"/logExport/:pasal"} render={(props) => (
      <LogExportView key={props.match.params.pasal} {...props} />
    )} />

    <Route path={props.match.path+"/eFiling"} render={(props) => (
      <LogEfillingView {...props} />
    )} />
    {/* END log export */}

    {/* LOG 21 */}
    <Route path={props.match.path+"/LOG_EMAIL"} component={require('../21_26/Email21Log/Email21Log.view').default} />
    {/* END LOG 21 */}

    {/* SETTING 4a2 */}
    <Route path={props.match.path+"/SETTING_NO_BUPOT_4A2"} component={require('./SettingNoBupot4a2/SettingNoBupot4a2.form').default} />
    {/* END SETTING 4a2 */}

    <Route path={props.match.path+'/'} component={function() {return <div/>}} />
  </Switch>
)
