import React, { Component } from 'react'; import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {TabsContainer, Tabs, Tab} from 'react-md';
import { Field } from 'redux-form';

import OrganizationService from '../../Modules/EbupotModule/Organization/Organization.service';
import ListView from '../../../../components/entity/ListViewEbupot';
import AuthService from '../../../../services/auth.service'
import ListViewEbupot from '../../../../components/entity/ListViewEbupot';
import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Radio, convert, FileInput5} from '../../../../components/form';
import http from './../../../../services/http.service'
import counterpart from 'counterpart'
import izitoast from 'izitoast'
import FileInputNext from '../../../../components/form/FileInputNext';
import moment from "moment";

@connect((state)=> ({
  ...OrganizationService.stateConnectSetting()(state)
}), OrganizationService.actionConnectSetting())
export default class OrganizationEbupotDialogView extends ListViewEbupot {
  service=OrganizationService;
  addDialog=false
  editDialog=false

  static contextTypes = {
    showDialog: PropTypes.func.isRequired,
  };

  columns=[
    {label: "word.id", searchField: "id", value: "id", isDefaultSort:false, isSortable:true, show:false, isSearchable:true},
    {label: "word.name", searchField: "nama", value: "nama", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    // {isSortable:true, label: "word.active", value: "active", type: 'boolean', onClick:() => {}, show:true, isSearchable:true},
    {label: "word.npwp", searchField: "npwp", value: "npwp", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.email", searchField: "email", value: "email", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.phone", searchField: "noTelp", value: "noTelp", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.city", searchField: "kota", value: "kota", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.createdBy", searchField: "createdBy", value: "createdBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.createdDate", searchField: "createdDate", value: (d)=> {
        if(d){
          return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
        } else {
          return "-"
        }
      }, type: 'func', isDefaultSort:true, isSortable:true, show:true, isSearchable:true},
    {label: "word.lastModifiedBy", searchField: "lastModifiedBy", value: "lastModifiedBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.lastModifiedDate", searchField: "lastModifiedDate", value: (d)=> {
        if(d){
          return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
        } else {
          return "-"
        }
      }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
  ]

  _tableActions = []
  _barActions = []

}
