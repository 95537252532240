import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm, getFormValues} from 'redux-form';
import moment from 'moment';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  CardActions,
  Button,
  LinearProgress
} from 'react-md';

import FormView from '../../../../../components/entity/form.view';
import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Checkbox} from '../../../../../components/form';
import UtilService from '../../../../../services/utils.service'
import NpwpService from './../../NpwpService';
import FormViewSpt from '../../../../../components/entity/form.view.spt';
import terbilangService from '../../../../../services/terbilang.service';
import Bp25Service from './Bp25.service';

@reduxForm({form: 'BP25Form', destroyOnUnmount: true})
@connect((state) => ({
  access: state.entity.access.api.find,
  organization: state.auth.currentOrganization,
  spt: state.auth.currentSPT,
  formData:getFormValues('BP25Form')(state)
}))
export default class BP25Form extends FormViewSpt {
  service=Bp25Service
  viewType =2;

  initialData={
    bagA: {},
  }

  constructor(props) {
    super(props);

    this.state= {
      ...this.state,
      showCalculateProgress: false,
      npwpNotValid: true
    }
  }

  async npwpValidation(evt, value){
    if(value){
      var res = await NpwpService.api.validate(value)
      if(res.valid){
        this.setState({npwpNotValid: false})
      } else {
        this.setState({npwpNotValid: true})
        this.toast.warning({title: "Validasi Npwp", message: "npwp tidak valid"})
      }

      var nonNpwp = 0;
      if(value === '000000000000000' || value == '' || !value) {
        nonNpwp = 1;
      }

      var form = this.props.formData;
      var d = form.bagB;

      Object.keys(d).forEach((k, i) => {
        if(!(k == 'jmlBruto' || k == 'jmlPph' || k == 'terbilang')) {
          var x = d[k];

          x.nonNpwp = nonNpwp
        }
      })

      await this.props.change('bagB', d)
    }
  }

  async beforeSave(value) {
    return true;
  }

  async initData() {
    if(this.props.match.params.id == 'new') {
      let nomorBPReq = 0; //await nomorBP23Service.api.get(this.props.spt.data.organization.id)
      var nomorBP = "";

      // if(nomorBPReq.data.mode === 'PREF_SUF') {
      //   nomorBP = nomorBPReq.data.prefix+nomorBPReq.data.suffix;
      // } else {
      //   nomorBP = nomorBPReq.data.suffix+nomorBPReq.data.prefix;
      // }
      let signer = {}; //await SPT21SignerService.api.getSigner(this.props.spt.data.id);

      this.initialData = {
        ...this.initialData,
        bagA:{
          nama: this.props.organization.name,
          npwp: this.props.organization.npwp,
          alamat: this.props.organization.address
        },
        berdasarkan: 'SPT',
        spt: this.props.spt.data,
      }
      this.props.initialize(this.initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id);
      this.props.initialize(res.data);
      // this.npwpValidation(null, res.data.bagA.npwp)
    }
  }

  typeOptions= [
    {
      name   : 'SPT',
      id     : 'SPT'
    },
    {
      name   : 'STP',
      id     : 'STP'
    },
    {
      name   : 'SKPKB',
      id     : 'SKPKB'
    },
    {
      name   : 'SKPKBT',
      id     : 'SKPKBT'
    },
  ]

  formView() {
    var formData = {};
    if(this.props.formData) formData = this.props.formData
    return (
      <div>
        <Card>
          <div className="md-grid">
            <Field
              label     = 'Berdasarkan'
              className = "md-cell md-cell--12"
              name      = 'berdasarkan'
              component = {Searchfield}
              options   = {this.typeOptions}
              validate  = {validation.required}
            />

            { (formData.berdasarkan == 'STP' || formData.berdasarkan == 'SKPKB' || formData.berdasarkan == 'SKPKBT') &&
              <Field
                label='Nomor SK'
                className = "md-cell md-cell--12"
                name='noSk'
                component={Textfield}
              />
            }
          </div>
        </Card>

        <br/>

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='A. WAJIB PAJAK' />
          <Divider/>
          <div className="md-grid">
            <div className='md-cell md-cell--6'>
              <Field
                label='NPWP'
                name='bagA.npwp'
                id="BP21FForm-npwp"
                disabled
                component={TextfieldMask}
                maskFormat="##.###.###.#-###-###"
                mask="_"
                length={15}
                onChange={this.npwpValidation.bind(this)}
                validate={validation.required}
              />
              <Field
                label='Nama'
                name='bagA.nama'

                disabled={this.state.npwpNotValid}
                component={Textfield}
                validate={validation.required}
              />
            </div>
            <div className='md-cell md-cell--6'>
              <Field
                label='Alamat'
                name='bagA.alamat'

                disabled={this.state.npwpNotValid}
                component={Textfield}
                validate={validation.required}
              />
            </div>
          </div>
        </Card>

        <br/>

        {this.state.showCalculateProgress && <LinearProgress id='lin_pro' style={{margin: 0}} />}
        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='B. DETAIL' />
          <Divider/>
          <DataTable plain fixedScrollWrapperStyle={{overflow: 'inherit'}} style={{overflow: 'inherit'}}>
            <TableHeader>
              <TableRow>
                <TableColumn>No.</TableColumn>
                <TableColumn className='md-text-right'>PPH(Rp)</TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableColumn>1.</TableColumn>
                <TableColumn>
                  <Field
                    name='pph'
                    component={TextfieldMask}
                    className='md-text-right'
                    money={','}
                    onChange={async (e, v)=> {
                      this.props.change('terbilang', terbilangService(parseInt(v)));
                    }}
                  />
                </TableColumn>
              </TableRow>

               <TableRow>
                <TableColumn>Terbilang</TableColumn>
                <TableColumn colSpan={2}>
                  <Field
                    name='terbilang'
                    component={Textfield}
                    disabled
                  />
                </TableColumn>
              </TableRow>
            </TableBody>
          </DataTable>
        </Card>

        <br />
      </div>
    )
  }

}
