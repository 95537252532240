import ApiService from '../../../services/api.service';
import http from "../../../services/http.service";

class LogEfillingPpnService extends ApiService {
  name= 'LogEfillingPpn';
  path= 'log/efiling-ppn';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api={
    downloadBpe: async (params)=> {
      var res = await this.http.get(`${this.apiLocation}/efiling-ppn/download/bpe`, {
        responseType: 'blob',
        params
      })

      return res;
    },
    checkStatus: async (params)=> {
      var res = await this.http.post(`${this.apiLocation}/efiling-ppn/upload/check`, {}, {
        params
      })

      return res.data;
    },
    reqNoBpeChecked: async (datas)=> {
      var res = await this.http.post(`${this.apiLocation}/efiling-ppn/req-no/bpe/bulk`, datas)

      return res.data;
    },
    downloadBpeChecked: async (datas)=> {
      var res = await this.http.post(`${this.apiLocation}/efiling-ppn/download/bpe`, datas, {
        responseType: 'blob',
      })

      return res;
    },
    checkStatusChecked: async (datas)=> {
      var res = await this.http.post(`${this.apiLocation}/efiling-ppn/upload/check/bulk`, datas)

      return res.data;
    },
    getNtpaChecked: async (datas)=> {
      var res = await this.http.post(`${this.apiLocation}/efiling-ppn/ntpa/check/bulk`, datas)

      return res.data;
    },
    getNtteChecked: async (datas)=> {
      var res = await this.http.post(`${this.apiLocation}/efiling-ppn/ntte/check/bulk`, datas)

      return res.data;
    },
    forceUploadChecked: async (datas)=> {
      var res = await this.http.post(`${this.apiLocation}/efiling-ppn/upload/force/bulk`, datas)

      return res.data;
    },
    setFailedChecked: async (datas)=> {
      var res = await this.http.post(`${this.apiLocation}/efiling-ppn/failed/bulk`, datas)

      return res.data;
    },
    upload: async(data) => {
      var res = await this.http.post(`${this.apiLocation}/efiling-ppn/upload/csv`, data)

      return res.data;
    },

    exportBulkExcel: async (data) => {
      var res = await this.http.post(`${this.apiLocation}/efiling-ppn/excel/bulk`, data, {
        responseType: 'blob'
      })
      return res;
    },

    exportByParamExcel: async (data) => {
      let res = await this.http.get(`${this.apiLocation}/efiling-ppn/excel/param`, {
        params: data,
        responseType: 'blob'
      });
      return res;
    },

    downloadAttachmentBulk: async (ids) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/download/bulk`, ids, {
        responseType: 'blob'
      })
      return res;
    },

    deleteSelected: async (ids)=> {
      let res = await http.post(`${this.apiLocation}/${this.path}/delete`, ids);

      return res;
    },
  }
}


export default new LogEfillingPpnService();
