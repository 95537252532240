import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {connect} from 'react-redux';

import SignInLogService from './SignInLog.service';
import ListView from '../../../../components/entity/listView';
import {DataTable, TableBody, TableColumn, TableHeader, TableRow} from "react-md";
import download from "downloadjs";
import izitoast from "izitoast";
import LogEfillingService from "../../Efilling/LogEfilling.service";

@reduxForm({form: 'SIGN_IN_LOG', destroyOnUnmount: true})
@connect((state) => ({
  ...SignInLogService.stateConnectSetting()(state)
}), SignInLogService.actionConnectSetting())
export default class SignInLog extends ListView {
  service = SignInLogService
  FormDialog=()=> <div/>
  // viewType=2

  constructor(props) {
    super(props);

    this.state = {
      showTableSetting: true,
      showDialogConfirmDeleteSelected: false,
      showForm: false,
      formData: {},
      data: [],
      onProgress: false,
      isError: false,
      errorMessage: ''
    }
  }

  _barActions = [
  ]

  _tableActions = []

  columns = [
    {isSortable: true, label: "word.status",  value: "status", type:'number', isDefaultSort:false, show:true, isSearchable:false},
    {isSortable: true, label: "word.username",  value: "username", isDefaultSort:false, show:true, isSearchable:false},
    // {isSortable: true, label: "word.password",  value: "password", isDefaultSort:false, show:false, isSearchable:false},
    {isSortable: true, label: "word.grantType",  value: "grantType", isDefaultSort:false, show:true, isSearchable:false},
    {isSortable: true, label: "word.scope",  value: "scope", isDefaultSort:false, show:true, isSearchable:false},
    {isSortable: true, label: "word.ip",  value: "ip", isDefaultSort:false, show:true, isSearchable:false},
    {isSortable:true, label: "word.createdDate", value: "createdDate", show:true, isSearchable:true, type:"date", isDefaultSort: true},
  ]

  defaultColumns = [
  ]

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='Status'
          name='status__equals'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Username'
          name='username__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Grant Type'
          name='grantType__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='IP'
          name='ip__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
      </this.SearchContainer>
    )
  }

}
