import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Field, reduxForm } from 'redux-form';
import {
  Card,
  Switch,
  Button,
  LinearProgress,
  TabsContainer,
  Tabs,
  Tab,
  Chip,
  DataTable,
  TableHeader,
  TableRow, TableColumn, TableBody, DialogContainer, FileInput
} from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Switch as Switch2} from '../../../components/form';

import ListView from '../../../components/entity/listView';
import axios from 'axios';
import izitoast from 'izitoast';
import LogEfillingBadanService from './LogEfillingBadan.service';
import download from 'downloadjs';
import ParamService from "src/services/param.service";
import _ from "lodash";
import counterpart from "counterpart";
import bluebird from "bluebird";
import LogEfillingService from "./LogEfilling.service";
import FlowActionDialog from "../Flow/MainFlow/FlowAction.dialog";
import FlowHistoryDialog from "../Flow/MainFlow/FlowHistoryDialog";
import FlowActionBulkDialog from "../Flow/MainFlow/FlowActionBulk.dialog";


@reduxForm({form: 'LogEfillingBadan_Form', destroyOnUnmount: true})
@connect((state)=> ({
  ...LogEfillingBadanService.stateConnectSetting()(state),
  user: state.auth.user,
  company: state.auth.currentCompany,
}), LogEfillingBadanService.actionConnectSetting())
export default class LogEfillingBadanView extends ListView {
  service=LogEfillingBadanService
  FormDialog=()=> <div/>
  // viewType=2

  constructor(props){
    super(props);
    this.state = {
      showTableSetting:true,
      showDialogConfirmDeleteSelected:false,
      showForm:false,
      formData: {},
      data: [],
      onProgress: false,
      isError: false,
      errorMessage: '',
      uploadLaporData: {},
    }
  }

  beforeFetch(params) {
    // params["category.equals"] = "ORGANIZATION"
    // params['pasal.equals'] = this.props.match.params.pasal;
  }

  fetchOption() {
    let companyId;
    if(this.props.company) companyId = this.props.company.id;
    if(this.props.match.params.companyId && this.props.match.params.companyId != '') companyId = this.props.match.params.companyId;
    // if(companyId) {
    //   return {
    //     path: this.service.path+'/'+companyId+'/company'
    //   }

    // } else {
    //   return {
    //     path: this.service.path+'/'+this.props.match.params.pasal+'/organization'
    //   }
    // }

  }

  tableActions = (data) => {
    let actions = [
      {
        label:'Cek Status',
        iconClassName:'mdi mdi-shuffle',
        onClick: (item) => this.handleCheckStatus(item)
      },
    ]

    let divider = {
      label:"divider",
        iconClassName:"-"
    }

    if(data.status === 'FAILED') {
      let deleteAction = {
        label:"Delete",
        iconClassName:"mdi mdi-delete",
        onClick:(item, callback) => this.deleteItem(item, callback),
        confirmation:{
          title:"sentence.custom.deleteItem",
          message:"sentence.custom.deleteItemConfirmation"
        }
      }

      actions.push(divider)
      actions.push(deleteAction)
    }

    /*actions = [
      {
        label:'word.checkStatus',
        iconClassName:'mdi mdi-shuffle',
        onClick: (item) => this.handleCheckStatus(item)
      },
    ]*/

    return actions
  }

  async handleUploadLaporAll() {
    let uploadLaporData = [];

    this.setState({uploadLaporDialog: true, uploadLaporResponse: {}})
  }

  async handleUploadLaporAllSubmit() {
    let uploadLaporData = Object.values(this.state.uploadLaporData)

    if(uploadLaporData.length > 0) {
      let countNoCsv = 0;
      let countNoPdfLk = 0;

      uploadLaporData.forEach(data => {
        if (!data.fileCsv) {
          countNoCsv++;
        }

        if (!data.filePdfLk) {
          countNoPdfLk++;
        }
      })

      if (countNoCsv > 0) {
        izitoast.error({title: 'Error', message: `Terdapat ${countNoCsv} SPT yang belum terdapat CSV`})
        return
      }

      if (countNoPdfLk > 0) {
        izitoast.error({title: 'Error', message: 'Terdapat pelaporan yang belum ada PDF laporan keuangan.'})
        // this.setState({konfirmasiUploadLaporDialog: true, countNoPdfLk})
      }
      else {
        this.processUploadLaporAllSubmit()
      }
    } else {
      izitoast.error({title: 'Error', message: 'Mohon cek kembali file dan data anda'})
    }
  }

  async processUploadLaporAllSubmit() {
    let uploadLaporData = Object.values(this.state.uploadLaporData)

    await bluebird.mapSeries(uploadLaporData, async (d)=> {
      var data = new FormData();

      data.append("csv", d.fileCsv);

      if(d.filePdfLk) {
        data.append("pdfLk", d.filePdfLk);
      }

      if(d.filePdfRk) {
        data.append("pdfRk", d.filePdfRk);
      }

      if(d.filePdfDn) {
        data.append("pdfDn", d.filePdfDn);
      }

      if(d.filePdfBut) {
        data.append("pdfBut", d.filePdfBut);
      }

      if(d.filePdfMgs) {
        data.append("pdfMgs", d.filePdfMgs);
      }

      if(d.filePdfLu) {
        data.append("pdfLu", d.filePdfLu);
      }

      if(d.filePdfDl) {
        data.append("pdfDl", d.filePdfDl);
      }

      return await LogEfillingBadanService.api.upload(data)
    })

    izitoast.success({title: 'Success', message: 'File Lapor telah selesai di upload'})

    this.setState({uploadLaporDialog: false, konfirmasiUploadLaporDialog: false})
  }

  async handleDownloadAttachment() {
    if (this.props.table.selected.length > 0) {
      let selected = this.props.table.selected

      let ids = [];

      selected.map(function (i) {
        if (i.hasAttachment) {
          ids.push(i.id)
        }
      })

      if (ids.length === 0) {
        izitoast.warning({title: 'Download Lampiran', message: 'Anda harus memilih minimal 1 log yang mempunyai lampiran.'})

        return
      }

      let fileRes = await this.service.api.downloadAttachmentBulk(ids);

      let filename = fileRes.headers.filename

      download(fileRes.data, filename);
    }
  }

  async handleReqNoBpeChecked() {
    if (this.props.table.selected.length > 0) {
      var selected = this.props.table.selected

      var datas = [];

      selected.map(function (i) {
        let step = i.step

        if (step >= 3) {
          datas.push(i.id)
        }
      })

      if (datas.length) {
        var res = await this.service.api.reqNoBpeChecked(datas);

        izitoast.success({title: 'Req No BPE', message: 'Request no BPE akan segara di proses.'})

        this.fetchData()
      }
      else {
        izitoast.warning({title: 'Req No BPE', message: 'Request no BPE hanya bisa untuk status minimal PROCESS REQ NO BPE.'})
      }
    }
  }

  async handleDownloadChecked() {

    if (this.props.table.selected.length > 0) {
      let selected = this.props.table.selected

      let datas = [];

      selected.map(function (i) {
        datas.push(i.id)
      })

      let fileRes = await this.service.api.downloadBpeChecked(datas);

      let filename = fileRes.headers.filename

      download(fileRes.data, filename);
    }
  }

  async handleCheckStatus(item) {
    let datas = [];

    datas.push(item.id)

    var res = await this.service.api.checkStatusChecked(datas);

    izitoast.success({title: 'Cek Status', message: 'Cek status berhasil.'})

    this.fetchData()
  }

  async handleCheckStatusChecked() {

    if (this.props.table.selected.length > 0) {
      var selected = this.props.table.selected

      var datas = [];

      selected.map(function (i) {
        datas.push(i.id)
      })

      var res = await this.service.api.checkStatusChecked(datas);

      izitoast.success({title: 'Cek Status', message: 'Cek status berhasil.'})

      this.fetchData()
    }
  }

  async handleGetNtpaChecked() {
    if (this.props.table.selected.length > 0) {
      var selected = this.props.table.selected

      var ids = [];

      selected.map(function (i) {
        ids.push(i.id)
      })

      let data = {
        idList: ids
      }

      var res = await this.service.api.getNtpaChecked(data);

      izitoast.success({title: 'Get NTPA', message: res.message})

      this.fetchData()
    }
  }

  async handleGetNtteChecked() {
    if (this.props.table.selected.length > 0) {
      var selected = this.props.table.selected

      var ids = [];

      selected.map(function (i) {
        if (i.ntte && !i.ntteCode) {
          ids.push(i.id)
        }
      })

      if (ids.length === 0) {
        izitoast.error({title: 'Get NTTE', message: 'Anda harus menceklis minimal 1 data yang sudah dapat tarik NTTE.'})

        return
      }

      let data = {
        idList: ids
      }

      var res = await this.service.api.getNtteChecked(data);

      izitoast.success({title: 'Get NTTE', message: res.message})

      this.fetchData()
    }
  }

  async handleForceUploadChecked() {

    if (this.props.table.selected.length > 0) {
      var selected = this.props.table.selected

      var datas = [];

      selected.map(function (i) {
        datas.push(i.id)
      })

      var res = await this.service.api.forceUploadChecked(datas);

      izitoast.success({title: 'Force Upload', message: 'File lapor akan segera diupload, silahkan tunggu.'})

      this.fetchData()
    }
  }

  async handleSetFailed(item) {
    let datas = [];

    datas.push(item.id)

    var res = await this.service.api.setFailedChecked(datas);

    izitoast.success({title: 'Set Failed', message: 'Set failed berhasil.'})

    this.fetchData()
  }

  async handleSetFailedChecked() {
    if (this.props.table.selected.length > 0) {
      var selected = this.props.table.selected

      var datas = [];

      selected.map(function (i) {
        datas.push(i.id)
      })

      var res = await this.service.api.setFailedChecked(datas);

      izitoast.success({title: 'Set Failed', message: 'Set failed berhasil.'})

      this.fetchData()
    }
  }

  async handleSetSkemaBaruChecked() {
    if (this.props.table.selected.length > 0) {
      var selected = this.props.table.selected

      var datas = [];

      selected.map(function (i) {
        datas.push(i.id)
      })

      var res = await this.service.api.setSkemaBaruChecked(datas);

      izitoast.success({title: 'Set Skema Baru', message: 'Set skema baru berhasil.'})

      this.fetchData()
    }
  }

  async handleSetSkemaLamaChecked() {
    if (this.props.table.selected.length > 0) {
      var selected = this.props.table.selected

      var datas = [];

      selected.map(function (i) {
        datas.push(i.id)
      })

      var res = await this.service.api.setSkemaLamaChecked(datas);

      izitoast.success({title: 'Set Skema Lama', message: 'Set skema lama berhasil.'})

      this.fetchData()
    }
  }

  async handleExportExcel() {
    let selected = this.props.table.selected

    if (selected.length > 0) {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export Excel',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: 'Apakah anda yakin akan meng-export Excel data yang diceklis?'
      }));

      if(dialog) {
        let ids = [];

        selected.map(function (i) {
          ids.push(i.id)
        });

        let res = await LogEfillingBadanService.api.exportBulkExcel(ids);

        let filename = res.headers.filename;

        download(res.data, filename);
      }
    }
    else {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export Excel',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: (
          <div>
            <this.ig.Field
              label='Nama'
              name='nama__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Nama Alias'
              name='namaAlias__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='NPWP'
              name='npwp__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            {/*<this.ig.Field
              label='Dari Bulan'
              name='masa__greaterOrEqualThan'
              className="md-cell md-cell--12"
              component={this.ig.Searchfield}
              options={[
                {id: 1, name: "1"},
                {id: 2, name: "2"},
                {id: 3, name: "3"},
                {id: 4, name: "4"},
                {id: 5, name: "5"},
                {id: 6, name: "6"},
                {id: 7, name: "7"},
                {id: 8, name: "8"},
                {id: 9, name: "9"},
                {id: 10, name: "10"},
                {id: 11, name: "11"},
                {id: 12, name: "12"}
              ]}
            />
            <this.ig.Field
              label='Sampai Bulan'
              name='masa__lessOrEqualThan'
              className="md-cell md-cell--12"
              component={this.ig.Searchfield}
              options={[
                {id: 1, name: "1"},
                {id: 2, name: "2"},
                {id: 3, name: "3"},
                {id: 4, name: "4"},
                {id: 5, name: "5"},
                {id: 6, name: "6"},
                {id: 7, name: "7"},
                {id: 8, name: "8"},
                {id: 9, name: "9"},
                {id: 10, name: "10"},
                {id: 11, name: "11"},
                {id: 12, name: "12"}
              ]}
            />

            <this.ig.Field
              label='Bulan'
              name='masa__equals'
              className="md-cell md-cell--12"
              component={this.ig.Searchfield}
              options={[
                {id: 1, name: "1"},
                {id: 2, name: "2"},
                {id: 3, name: "3"},
                {id: 4, name: "4"},
                {id: 5, name: "5"},
                {id: 6, name: "6"},
                {id: 7, name: "7"},
                {id: 8, name: "8"},
                {id: 9, name: "9"},
                {id: 10, name: "10"},
                {id: 11, name: "11"},
                {id: 12, name: "12"}
              ]}
            />*/}

            <this.ig.Field
              label='Tahun'
              name='tahun__equals'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />

            <this.ig.Field
              label='WAITING'
              name='status__in_WAITING'
              className="md-cell md-cell--12"
              component={this.ig.Checkbox}
            />
            <this.ig.Field
              label='PROCESS'
              name='status__in_PROCESS'
              className="md-cell md-cell--12"
              component={this.ig.Checkbox}
            />
            <this.ig.Field
              label='FINISH'
              name='status__in_FINISH'
              className="md-cell md-cell--12"
              component={this.ig.Checkbox}
            />
            <this.ig.Field
              label='FAILED'
              name='status__in_FAILED'
              className="md-cell md-cell--12"
              component={this.ig.Checkbox}
            />

            <this.ig.Field
              label='UPLOAD'
              name='step__in_1'
              className="md-cell md-cell--12"
              component={this.ig.Checkbox}
            />
            <this.ig.Field
              label='CHECK'
              name='step__in_2'
              className="md-cell md-cell--12"
              component={this.ig.Checkbox}
            />
            <this.ig.Field
              label='REQ BPE'
              name='step__in_3'
              className="md-cell md-cell--12"
              component={this.ig.Checkbox}
            />
            <this.ig.Field
              label='DOWNLOAD BPE'
              name='step__in_4'
              className="md-cell md-cell--12"
              component={this.ig.Checkbox}
            />
          </div>
        )
      }));

      if (dialog === false)
        return

      if (dialog === undefined)
        dialog = {}

      if (dialog) {
        let param = ParamService.convert(dialog)

        let res = await LogEfillingBadanService.api.exportByParamExcel(param);

        let filename = res.headers.filename;

        download(res.data, filename);
      }
    }
  }

  async deleteSelectedDataItems() {
    var items = this.props.table.selected

    var dialog = await this.context.showDialog((props, res, rej) =>({
      title: 'Delete',
      initialValue: {},
      height: 'auto',
      width: 400,
      okText: 'Ya',
      text: 'Apakah anda yakin akan meng-hapus data yang terpilih?'
    }));

    if(dialog) {
      try {
        let ids = []

        items.forEach(item => {
          if (item.status === 'FAILED') {
            ids.push(item.id)
          }
        })

        if (ids.length) {
          await this.service.api.deleteSelected(ids)
          await this.fetchData()
        }
        else {
          izitoast.error({title: 'Gagal', message: 'Yang bisa dihapus hanya yang statusnya FAILED.'})
        }

      } catch(e) {
      }
    }
  }

  pad(v) {
    return ('0' + v).slice(-2)
  }

  _barActions = [
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => this.fetchData()
    },
    {
      label:'word.uploadLapor',
      iconClassName:'mdi mdi-upload',
      onClick: async () => {
        await this.handleUploadLaporAll()
      },
    },
    {
      label:'word.downloadAttachment',
      iconClassName:'mdi mdi-briefcase-download',
      disabledFunc:() => this.props.table.selected.length === 0,
      onClick:() => this.handleDownloadAttachment()
    },
    {
      label:'word.requestNoBpe',
      iconClassName:'mdi mdi-autorenew',
      disabledFunc:() => this.props.table.selected.length === 0,
      onClick:() => this.handleReqNoBpeChecked()
    },
    {
      label:'word.downloadBpe',
      iconClassName:'mdi mdi-download',
      disabledFunc:() => this.props.table.selected.length === 0,
      onClick:() => this.handleDownloadChecked()
    },
    {
      label:'word.checkStatus',
      iconClassName:'mdi mdi-shuffle',
      disabledFunc:() => this.props.table.selected.length === 0,
      onClick:() => this.handleCheckStatusChecked()
    },
    {
      label:'word.getNtpa',
      iconClassName:'mdi mdi-shuffle-variant',
      disabledFunc:() => this.props.table.selected.length === 0,
      onClick:() => this.handleGetNtpaChecked()
    },
    {
      label:'word.getNtte',
      iconClassName:'mdi mdi-shuffle-disabled',
      disabledFunc:() => this.props.table.selected.length === 0,
      onClick:() => this.handleGetNtteChecked()
    },
    {
      label:'word.forceUpload',
      iconClassName:'mdi mdi-alert-octagon',
      disabledFunc:() => this.props.table.selected.length === 0,
      onClick:() => this.handleForceUploadChecked()
    },
    {
      label:'word.setFailed',
      iconClassName:'mdi mdi-minus-circle',
      disabledFunc:() => this.props.table.selected.length === 0,
      onClick:() => this.handleSetFailedChecked()
    },
    {
      label:'word.exportExcel',
      iconClassName:'mdi mdi-clipboard-text',
      // disabledFunc:() => this.props.table.selected.length === 0,
      onClick:() => this.handleExportExcel()
    },
    {
      label:'word.delete',
      iconClassName:'mdi mdi-delete',
      onClick: async () => {
        await this.deleteSelectedDataItems()
      },
      disabledFunc:() => {
        let selected = this.props.table.selected
        let deleteable = []

        selected.forEach(data => {
          if(data.status === 'FAILED') {
            deleteable.push(data.id)
          }
        })

        return deleteable.length === 0
      }
    },
  ]

  async showDetail(d) {
    var d = await this.context.showDialog((props, res, rej) =>({
      title: 'Details',
      initialValue: {},

      okText: 'Back',
      text: (
        <div>
        {d.isJoin && <span className='mpk-badge md-green-500--background'>JOIN</span> }
        {!d.isJoin && <span className='mpk-badge md-red-500--background'>JOIN</span> }

        {d.isEncrypt && <span className='mpk-badge md-green-500--background'>ENCRYPT</span> }
        {!d.isEncrypt && <span className='mpk-badge md-red-500--background'>ENCRYPT</span> }

        {d.isWrap && <span className='mpk-badge md-green-500--background'>WRAP</span> }
        {!d.isWrap && <span className='mpk-badge md-red-500--background'>WRAP</span> }

        <br/>

        {d.isComplete && <span className='mpk-badge md-green-500--background'>COMPLETE</span> }
        {!d.isComplete && <span className='mpk-badge md-red-500--background'>COMPLETE</span> }

        {d.isWrap && <span className='mpk-badge md-green-500--background'>WRAP</span> }
        {!d.isWrap && <span className='mpk-badge md-red-500--background'>WRAP</span> }

        {d.isBpe && <span className='mpk-badge md-green-500--background'>BPE</span> }
        {!d.isBpe && <span className='mpk-badge md-red-500--background'>BPE</span> }

        {d.isSendingToASP && <span className='mpk-badge md-green-500--background'>SENDING TO ASP</span> }
        {!d.isSendingToASP && <span className='mpk-badge md-red-500--background'>SENDING TO ASP</span> }

        {d.isReceivedByASP && <span className='mpk-badge md-green-500--background'>RECEIVED BY ASP</span> }
        {!d.isReceivedByASP && <span className='mpk-badge md-red-500--background'>RECEIVED BY ASP</span> }

        <br/>

        {d.isSendingToDJP && <span className='mpk-badge md-green-500--background'>SENDING TO DJP</span> }
        {!d.isSendingToDJP && <span className='mpk-badge md-red-500--background'>SENDING TO DJP</span> }

        {d.isReceivedByDJP && <span className='mpk-badge md-green-500--background'>RECEIVED BY DJP</span> }
        {!d.isReceivedByDJP && <span className='mpk-badge md-red-500--background'>RECEIVED BY DJP</span> }

        {d.isReceivedByDJP && <span className='mpk-badge md-green-500--background'>RECEIVED BY DJP</span> }
        {!d.isReceivedByDJP && <span className='mpk-badge md-red-500--background'>RECEIVED BY DJP</span> }
        </div>
      )
    }));
  }

  columns=[
    {isSortable: true, label: "word.name",  value: "organization.name", isDefaultSort:false, show:true, isSearchable:true},
    {isSortable: true, label: "word.aliasName",  value: "organization.aliasName", isDefaultSort:false, show:true, isSearchable:true},
    {isSortable: true, label: "word.npwp",  value: "organization.npwp", isDefaultSort:false, show:true, isSearchable:true},
    /*{isSortable: true, label: "word.month",  value: "masa", isDefaultSort:false, show:true, isSearchable:true, type: 'number'},*/
    {isSortable: true, label: "word.tahun",  searchField: "tahun", value: (d)=> d.tahun, show:true, isSearchable:true, type: 'func'},
    {isSortable: true, label: "word.pembetulan",  value: "pembetulan", isDefaultSort:false, show:true, isSearchable:true, type: 'number'},
    {isSortable: true, label: "word.kodeForm",  value: "kodeForm", isDefaultSort:false, show:true, isSearchable:true},
    {isSortable: false, label: "word.fileName",  value: (d)=> {
      let npwp = d.organization.npwp;
      let masaFiskal = this.pad(d.masaFiskal);
      let akhirMasaFiskal = this.pad(d.akhirMasaFiskal);
      let tahun = d.tahun;
      let kodeForm = d.kodeForm;
      let pembetulan = d.pembetulan;

      let fileName = npwp +  masaFiskal + akhirMasaFiskal + tahun + pembetulan + kodeForm

      return fileName
    }, isDefaultSort:false, show:true, isSearchable:false, type: 'func'},

    {isSortable: true, label: "word.attachment",  value: "hasAttachment", isDefaultSort:false, show:true, isSearchable:false, type: "boolean", searchField: "hasAttachment"},

    {
      searchField: "status",
      label: "word.status",
      type: 'func',
      value: (d)=> {
        var style = {
          background: 'grey'
        }
        switch (d.status) {
          case 'PROCESS':
            style.background = '#2196F3'
            break;
          case 'FINISH':
            style.background = '#4CAF50'
            break;
          case 'FAILED':
            style.background = '#f44336'
            break;
          default:
            break;
        }

        var step = '';
        switch (d.step) {
          case 1:
            step = 'UPLOAD'
            break;
          case 2:
            step = 'CHECK'
            break;
          case 3:
            step = 'REQ BPE'
            break;
          case 4:
            step = 'DOWNLOAD BPE'
            break;

          default:
            break;
        }
        return <div style={{width: 200}}>
          <Chip label={d.status} style={style} />

          {d.step &&
            <Chip label={step} />
          }
          </div>
      },
      className: "mpk-font-size-S", show:true, isSearchable:true},
      {isSortable: true, label: "word.message",  value: "errorReason", isDefaultSort:false, show:true, isSearchable:false},
      {isSortable: true, label: "word.ntpa",  value: "ntpa", isDefaultSort:false, show:true, isSearchable:false},
      // {isSortable: true, label: "word.ntte",  value: "isNtte", isDefaultSort:false, show:true, isSearchable:false, type: "boolean"},
      {isSortable: true, label: "word.ntte",  value: "ntte", isDefaultSort:false, show:true, isSearchable:false},
      {isSortable: true, label: "word.bpe",  value: "isBpe", isDefaultSort:false, show:true, isSearchable:false, type: "boolean"},
      {isSortable: true, label: "word.taxType",  value: "taxType", isDefaultSort:false, show:false, isSearchable:false},
      {isSortable: true, label: "word.amount",  value: "amount", isDefaultSort:false, show:false, isSearchable:false},

  ]

  viewContainer = (props) => {

    return <div className="mpk-layout column fill">
      {this.uploadLaporView()}
      {this.konfirmasiUploadLaporView()}

      {props.children}
    </div>
  }

  uploadLaporView() {
    return <DialogContainer
      width={700}
      contentStyle={{padding:0, overflowY: 'hidden'}}
      visible={this.state.uploadLaporDialog}
      onHide={()=> {this.setState({uploadLaporDialog: false})}}
      actions={[
        <FileInput
          id="multiple-file-upload"
          multiple
          secondary
          flat
          name="multipart-file-upload"
          label="Browse Files"
          onChange={(files, e)=> {
            if(!files) files = []

            let uploadLaporData = {}

            files.forEach(file => {
              let type = ''
              let filename = file.name
              let fileCsv = null
              let filePdfLk = null
              let filePdfRk = null
              let filePdfDn = null
              let filePdfBut = null
              let filePdfMgs = null
              let filePdfLu = null
              let filePdfDl = null

              if (filename.endsWith('.csv')) {
                type = 'csv'
                filename = filename.replace('.csv', '')
                fileCsv = file
              }

              if (filename.endsWith('LK.pdf')) {
                type = 'pdflk'
                filename = filename.replace('.pdf', '')
                filePdfLk = file
              }

              if (filename.endsWith('RK.pdf')) {
                type = 'pdfrk'
                filename = filename.replace('.pdf', '')
                filePdfRk = file
              }

              if (filename.endsWith('DN.pdf')) {
                type = 'pdfdn'
                filename = filename.replace('.pdf', '')
                filePdfDn = file
              }

              if (filename.endsWith('BUT.pdf')) {
                type = 'pdfbut'
                filename = filename.replace('.pdf', '')
                filePdfBut = file
              }

              if (filename.endsWith('MGS.pdf')) {
                type = 'pdfmgs'
                filename = filename.replace('.pdf', '')
                filePdfMgs = file
              }

              if (filename.endsWith('LU.pdf')) {
                type = 'pdflu'
                filename = filename.replace('.pdf', '')
                filePdfLu = file
              }

              if (filename.endsWith('DL.pdf')) {
                type = 'pdfdl'
                filename = filename.replace('.pdf', '')
                filePdfDl = file
              }

              let npwp = filename.substring(0, 15)
              let masaFiskal = parseInt(filename.substring(15, 17))
              let akhirMasaFiskal = parseInt(filename.substring(17, 19))
              let tahun = parseInt(filename.substring(19, 23))
              let pembetulan = parseInt(filename.substring(23, 25))
              let kodeForm = filename.substring(25, 36)

              if (kodeForm === "F1132140110" || kodeForm === "F1132150110" || kodeForm === "F1132140111" || kodeForm === "F1132150111") {
                let key = npwp + masaFiskal + akhirMasaFiskal + tahun + pembetulan + kodeForm

                let object = {
                  npwp,
                  masaFiskal,
                  akhirMasaFiskal,
                  tahun,
                  pembetulan,
                  kodeForm,
                  fileCsv,
                  filePdfLk,
                  filePdfRk,
                  filePdfDn,
                  filePdfBut,
                  filePdfMgs,
                  filePdfLu,
                  filePdfDl,
                  key,
                }

                if (uploadLaporData[key]) {
                  if (type === 'csv') {
                    uploadLaporData[key].fileCsv = fileCsv
                  }

                  if (type === 'pdflk') {
                    uploadLaporData[key].filePdfLk = filePdfLk
                  }

                  if (type === 'pdfrk') {
                    uploadLaporData[key].filePdfRk = filePdfRk
                  }

                  if (type === 'pdfdn') {
                    uploadLaporData[key].filePdfDn = filePdfDn
                  }

                  if (type === 'pdfbut') {
                    uploadLaporData[key].filePdfBut = filePdfBut
                  }

                  if (type === 'pdfmgs') {
                    uploadLaporData[key].filePdfMgs = filePdfMgs
                  }

                  if (type === 'pdflu') {
                    uploadLaporData[key].filePdfLu = filePdfLu
                  }

                  if (type === 'pdfdl') {
                    uploadLaporData[key].filePdfDl = filePdfDl
                  }
                }
                else {
                  uploadLaporData[key] = object
                }

                this.setState({uploadLaporData})
              }
            })
          }}
        />,
        {
          primary: true,
          children: 'Submit',
          onClick: async ()=> {
            await this.handleUploadLaporAllSubmit()
          }
        },
        {

          children: 'Close',
          onClick: ()=> {this.setState({uploadLaporDialog: false})}
        }
      ]}
    >
      <div className='mpk-commandbar mpk-layout align-center justify-between mpk-border bottom solid dark'>
        <div className='mpk-font-size-M flex mpk-layout align-center'>{ this.fileLaporTitle }</div>
      </div>

      {this.state.uploadLaporDialog &&
        <DataTable className='animated fadeInDown' plain fixedHeader fixedHeight={240}>
          <TableHeader>
            <TableRow>
              <TableColumn>NPWP</TableColumn>
              {/*<TableColumn>Masa</TableColumn>*/}
              <TableColumn>Tahun</TableColumn>
              <TableColumn>Pembetulan</TableColumn>
              <TableColumn>Filename CSV</TableColumn>
              <TableColumn>Filename PDF LK</TableColumn>
              <TableColumn>Filename PDF RK</TableColumn>
              <TableColumn>Filename PDF DN</TableColumn>
              <TableColumn>Filename PDF BUT</TableColumn>
              <TableColumn>Filename PDF MGS</TableColumn>
              <TableColumn>Filename PDF LU</TableColumn>
              <TableColumn>Filename PDF DL</TableColumn>
            </TableRow>
          </TableHeader>
          <TableBody>
            {Object.values(this.state.uploadLaporData).map((el, i) => {
              let filenameCsv = '-'
              let filenamePdfLk = '-'
              let filenamePdfRk = '-'
              let filenamePdfDn = '-'
              let filenamePdfBut = '-'
              let filenamePdfMgs = '-'
              let filenamePdfLu = '-'
              let filenamePdfDl = '-'

              if (el.fileCsv) {
                filenameCsv = el.fileCsv.name
              }

              if (el.filePdfLk) {
                filenamePdfLk = el.filePdfLk.name
              }

              if (el.filePdfRk) {
                filenamePdfRk = el.filePdfRk.name
              }

              if (el.filePdfDn) {
                filenamePdfDn = el.filePdfDn.name
              }

              if (el.filePdfBut) {
                filenamePdfBut = el.filePdfBut.name
              }

              if (el.filePdfMgs) {
                filenamePdfMgs = el.filePdfMgs.name
              }

              if (el.filePdfLu) {
                filenamePdfLu = el.filePdfLu.name
              }

              if (el.filePdfDl) {
                filenamePdfDl = el.filePdfDl.name
              }

              return <TableRow>
                <TableColumn>{el.npwp}</TableColumn>
                {/*<TableColumn>{el.masa}</TableColumn>*/}
                <TableColumn>{el.tahun}</TableColumn>
                <TableColumn>{el.pembetulan}</TableColumn>
                <TableColumn>{filenameCsv}</TableColumn>
                <TableColumn>{filenamePdfLk}</TableColumn>
                <TableColumn>{filenamePdfRk}</TableColumn>
                <TableColumn>{filenamePdfDn}</TableColumn>
                <TableColumn>{filenamePdfBut}</TableColumn>
                <TableColumn>{filenamePdfMgs}</TableColumn>
                <TableColumn>{filenamePdfLu}</TableColumn>
                <TableColumn>{filenamePdfDl}</TableColumn>
              </TableRow>
            })}
          </TableBody>
        </DataTable>
      }

      <div className='md-grid'>

      </div>
    </DialogContainer>
  }

  konfirmasiUploadLaporView() {
    return <DialogContainer
      width={400}
      contentStyle={{padding:0, overflowY: 'hidden'}}
      visible={this.state.konfirmasiUploadLaporDialog}
      onHide={()=> {this.setState({konfirmasiUploadLaporDialog: false})}}
      actions={[
        {
          primary: true,
          children: 'Proses',
          onClick: async ()=> {
            await this.processUploadLaporAllSubmit()
          }
        },
        {
          children: 'Close',
          onClick: ()=> {this.setState({konfirmasiUploadLaporDialog: false})}
        }
      ]}
    >
      <div className='mpk-commandbar mpk-layout align-center justify-between mpk-border bottom solid dark'>
        <div className='mpk-font-size-M flex mpk-layout align-center'>Konfirmasi PDF File Lapor</div>
      </div>

      {this.state.konfirmasiUploadLaporDialog &&
      <div style={{margin: 20}}>Terdapat {this.state.countNoPdf} SPT yang belum ada file PDF, apakah anda akan tetap melanjutkan proses pelaporan?</div>
      }

      <div className='md-grid'>

      </div>
    </DialogContainer>
  }

  async handleSave(value) {
    var _this = this
    try {
      this.setState({onProgress: true})
      let formData = new FormData();
      formData.append("type", value.type)
      formData.append("category", "ORGANIZATION")
      formData.append("file", value.file)
      formData.append("no", value.no)
      axios.post(`/api/${ImportService.pathImportOrganization}`, formData).then(function (res){
        izitoast.info({title: 'Import File', message: 'sedang diproses cek log untuk lebih detil'})
        _this.setState({onProgress: false})
        _this.fetchData()
      }).catch(function (e){
        _this.fetchData()
        var msg = e.message;
        if(e.response) msg = e.response.data.message;
        _this.setState({
          isError: true,
          onProgress: false,
          errorMessage: msg
        })
      })
    } catch(e) {
      _this.fetchData()
      var msg = e.message;
      if(e.response) msg = e.response.data.message;
      _this.setState({
        isError: true,
        onProgress: false,
        errorMessage: msg
      })
    }
  }

  componentDidMount(){
    let path = this.props.match.path.split("/")
    let params = path[2];
    this.props.change("category", params)
    this.props.change("type", "M")
    this.props.change("no", false)
  }

  handleTabChange(newActiveTabIndex){
    if(newActiveTabIndex == 0){
      this.props.change("type", "M")
    } else {
      this.props.change("type", "A")
    }
  }

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='Nama'
          name='nama__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Nama Alias'
          name='namaAlias__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='NPWP'
          name='npwp__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        {/*<this.ig.Field
          label='Dari Bulan'
          name='masa__greaterOrEqualThan'
          className="md-cell md-cell--6"
          component={this.ig.Searchfield}
          options={[
            {id: 1, name: "1"},
            {id: 2, name: "2"},
            {id: 3, name: "3"},
            {id: 4, name: "4"},
            {id: 5, name: "5"},
            {id: 6, name: "6"},
            {id: 7, name: "7"},
            {id: 8, name: "8"},
            {id: 9, name: "9"},
            {id: 10, name: "10"},
            {id: 11, name: "11"},
            {id: 12, name: "12"}
          ]}
        />
        <this.ig.Field
          label='Sampai Bulan'
          name='masa__lessOrEqualThan'
          className="md-cell md-cell--6"
          component={this.ig.Searchfield}
          options={[
            {id: 1, name: "1"},
            {id: 2, name: "2"},
            {id: 3, name: "3"},
            {id: 4, name: "4"},
            {id: 5, name: "5"},
            {id: 6, name: "6"},
            {id: 7, name: "7"},
            {id: 8, name: "8"},
            {id: 9, name: "9"},
            {id: 10, name: "10"},
            {id: 11, name: "11"},
            {id: 12, name: "12"}
          ]}
        />

        <this.ig.Field
          label='Bulan'
          name='masa__equals'
          className="md-cell md-cell--12"
          component={this.ig.Searchfield}
          options={[
            {id: 1, name: "1"},
            {id: 2, name: "2"},
            {id: 3, name: "3"},
            {id: 4, name: "4"},
            {id: 5, name: "5"},
            {id: 6, name: "6"},
            {id: 7, name: "7"},
            {id: 8, name: "8"},
            {id: 9, name: "9"},
            {id: 10, name: "10"},
            {id: 11, name: "11"},
            {id: 12, name: "12"}
          ]}
        />*/}

        <this.ig.Field
          label='Tahun'
          name='tahun__equals'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />

        <this.ig.Field
          label='WAITING'
          name='status__in_WAITING'
          className="md-cell md-cell--6"
          component={this.ig.Checkbox}
        />
        <this.ig.Field
          label='PROCESS'
          name='status__in_PROCESS'
          className="md-cell md-cell--6"
          component={this.ig.Checkbox}
        />
        <this.ig.Field
          label='FINISH'
          name='status__in_FINISH'
          className="md-cell md-cell--6"
          component={this.ig.Checkbox}
        />
        <this.ig.Field
          label='FAILED'
          name='status__in_FAILED'
          className="md-cell md-cell--6"
          component={this.ig.Checkbox}
        />

        <this.ig.Field
          label='UPLOAD'
          name='step__in_1'
          className="md-cell md-cell--12"
          component={this.ig.Checkbox}
        />
        <this.ig.Field
          label='CHECK'
          name='step__in_2'
          className="md-cell md-cell--12"
          component={this.ig.Checkbox}
        />
        <this.ig.Field
          label='REQ BPE'
          name='step__in_3'
          className="md-cell md-cell--12"
          component={this.ig.Checkbox}
        />
        <this.ig.Field
          label='DOWNLOAD BPE'
          name='step__in_4'
          className="md-cell md-cell--12"
          component={this.ig.Checkbox}
        />
      </this.SearchContainer>
    )
  }

}
