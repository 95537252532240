import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Field, reduxForm } from 'redux-form';
import { Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab } from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Switch as Switch2} from '../../../../../../components/form';

import ListView from '../../../../../../components/entity/listView';
import ColumnService from '../../../../../../services/column.service';
// import ImportService from './../Import/Import.service';
import axios from 'axios';
import izitoast from 'izitoast';
import FormDialog from './ImportSSP22.dialog';
import ImportSSP22service from './ImportSSP22.service';
import download from "downloadjs";
import ListViewImport from '../../../../../../components/entity/ListViewImport';

@reduxForm({form: 'ImportSSP22Form', destroyOnUnmount: true})
@connect(ImportSSP22service.stateConnectSetting(), ImportSSP22service.actionConnectSetting())
export default class ImportSSP22View extends ListViewImport {
  service=ImportSSP22service
  FormDialog=FormDialog

  category='SSP_22'
  importPath='/api/import/spt/22'
  showAutomatisTab=false
  showPembetulan=true
// viewType=2

  beforeFetch(params) {
    params["category.equals"] = "SSP_22"
  }

  async handleDownloadTemplate() {
    var report = await ImportSSP22service.api.downloadTemplate()
    download(report.data, 'TEMPLATE_SSP_22.csv');
  }

}
