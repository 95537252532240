import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  Tab,
  Tabs,
  SelectionControlGroup,
  Checkbox,
  Avatar,
  FontIcon,
  List,
  ListItem,
  Paper
} from 'react-md';

import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Radio, convert} from '../../../../../../components/form';
import UtilService from '../../../../../../services/utils.service'
import FormView from '../../../../../../components/entity/form.view';
import DialogView from '../../../../../../components/entity/dialog.view';
import Service from './SPT23.service'
import OrganizationService from './../../Organization/Organization.service'
import moment from 'moment'
import counterpart from 'counterpart'

@reduxForm({form: 'Espt23Form', destroyOnUnmount: true})
@connect((state) => ({
  formData:state.form.Espt23Form,
  user: state.auth.user
}))
export default class Espt23FormView extends FormView {
  service=Service
  viewType=2;

  initialData={
    status: 'CREATED',
    pembetulan: 0,
    organization: {}
  }

  constructor(props) {
    super(props);

    var date = new Date();
    let yearOptions = [];
    for(var i=0; i<11; i++) {
      yearOptions.push({
        id: date.getFullYear()-i,
        name: date.getFullYear()-i
      })
    }

    this.state = {
      ...this.state,
      bulanOptions: [
        {id: 1, name: 'Januari'},
        {id: 2, name: 'Februari'},
        {id: 3, name: 'Maret'},
        {id: 4, name: 'April'},
        {id: 5, name: 'Mei'},
        {id: 6, name: 'Juni'},
        {id: 7, name: 'Juli'},
        {id: 8, name: 'Agustus'},
        {id: 9, name: 'September'},
        {id: 10, name: 'Oktober'},
        {id: 11, name: 'November'},
        {id: 12, name: 'Desember'}
      ],
      yearOptions
    }
  }

  componentDidMount() {
    this.initData()
  }

  async beforeSave(val) {
    val.organization.id = val.organization.id
    val.organization.nama = val.organization.nama
    val.organization.npwp = val.organization.npwp
    val.sebelumnya = " "
    return true;
  }

  async initData() {
    if(this.props.match.params.sptId == 'new') {
      this.initialData.organization = this.props.organization;
      this.props.initialize(this.initialData);
    } else {
      try {
        let res = await this.service.api.findOne(this.props.match.params.sptId);
        var initialData = {}
        initialData = res.data
        let organization = await OrganizationService.api.findOne(res.data.organization.id)
        initialData.organization = organization.data
        this.props.initialize(initialData);
      } catch (e) {
      }
    }
  }

  formView() {
    var formData = {}
    if(this.props.formData) formData = this.props.formData.values;
    var organization = {};
    if(formData && formData.organization) organization = formData.organization;

    return (
        <Card>
        <div className="md-grid">
            <Field
              label='Organization' viewField='nama'
              name='organization'
              id="spt2126-organization"
              className="md-cell md-cell--12"
              // disabled={this.props.organization.id != null}
              params     = {{
                  size: 50
              }}
              remoteSearch = {true}
              viewField="nama"
              valueField='parent'
              service={OrganizationService}
              searchField  = {['nama']}
              searchFieldInteger  = {['npwp']}
              component={Searchfield}
              itemTemplate={function(d) {
                  let nameAndAlias = ""
                  if (d.nama)
                  nameAndAlias = d.nama
                  if (d.aliasNama)
                  nameAndAlias += " - " + d.aliasNama
                  return {
                    primaryText  : nameAndAlias,
                    secondaryText: d.npwp,
                    onClick: () => {
                        if(!this.props.disabled){
                        this.handleItemClick(d); this.hide()
                        }
                    }
                }
              }}
            />
            <div className="md-cell md-cell--6">
            <Field
                label='Bulan'
                name='masa'
                id="spt2126-pasal"
                component={Searchfield}
                options={this.state.bulanOptions}
                validate={validation.required}
            />
            <Field
                label='Tahun'
                name='tahun'
                id="spt2126-pasal"
                component={Searchfield}
                options={this.state.yearOptions}
                validate={validation.required}
            />
            </div>

            <div className="md-cell md-cell--6">

            <List className="md-paper md-paper--1" >
                <Subheader primaryText="ORGANISASI" />
                <ListItem
                primaryText   = {organization.npwp}
                secondaryText = "NPWP"
                />
                <ListItem
                primaryText   = {organization.nama}
                secondaryText = "NAME"
                />
                <ListItem
                primaryText   = {organization.aliasNama}
                secondaryText = "ALIAS NAME"
                />
                <ListItem
                primaryText   = {organization.kota}
                secondaryText = "CITY"
                />
                <ListItem
                primaryText   = {organization.kodePos}
                secondaryText = "POSTAL CODE"
                />
                <ListItem
                primaryText   = {organization.email}
                secondaryText = "EMAIL"
                />
                <ListItem
                primaryText   = {organization.noTelp}
                secondaryText = "PHONE"
                />
                <ListItem
                primaryText   = {organization.alamat}
                secondaryText = "ADDRESS"
                />
                <ListItem
                primaryText   = {(organization.active) ? 'YES' : 'NO'}
                secondaryText = "ACTIVE"
                />
            </List>
            </div>
        </div>
      </Card>
    )
  }

}
