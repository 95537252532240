import ApiService from '../../../../services/api.service';

class LoggingEventService extends ApiService {
  name= 'LoggingEvent';
  path= 'log/loggingEvent';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api={
    delete: async (id) => {
      var res = await this.http.delete(`${this.apiLocation}/${this.path}/delete/${id}`)

      return res.data;
    },

    deleteSelected: async (ids) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/delete`, ids)

      return res.data;
    },
  }
}

export default new LoggingEventService();
