import ApiService from '../../../../../../services/api.service';
import http from "../../../../../../services/http.service";

class BP15DipotongPihakLainService extends ApiService {
  name= 'BP15DipotongPihakLain';
  path= 'spt15/bp-dipotong-pihak-lain';

  api= {
    calculate: async (param) => {
      var res = await this.http.post(this.apiLocation+'/calculation/pph15/f', param)

      return res.data;
    },
    save: async (param) => {
      let {type} = param

      let uri = ''

      switch (type) {
        case 'DN23':
          uri = 'bp-pelayaran-dn-potong-pihak-lain';
          break;
        case 'LN4':
          uri = 'bp-pelayaran-penerbangan-ln-potong-pihak-lain';
          break;
      }

      let res = await http.post(`${this.apiLocation}/spt15/${uri}`, param);

      return res.data;
    },
    update: async (param) => {
      let {type} = param

      let uri = ''

      switch (type) {
        case 'DN23':
          uri = 'bp-pelayaran-dn-potong-pihak-lain';
          break;
        case 'LN4':
          uri = 'bp-pelayaran-penerbangan-ln-potong-pihak-lain';
          break;
      }

      let res = await http.put(`${this.apiLocation}/spt15/${uri}`, param);

      return res.data;
    },
    total: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/total`)

      return res.data;

      return {};
    },
    cetak: async (id) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/report/${id}`, {
        responseType: 'blob'
      })

      return res;
    },
    cetakBulk: async (sptId, ids) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/${sptId}/report/bulk`, ids, {
        responseType: 'blob'
      })

      return res;
    },
    cetakDaftar: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/report-daftar`, {
        responseType: 'blob'
      })

      return res;
    },
    downloadCsv: async (sptId)=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/csv/${sptId}`, {
        responseType: 'blob'
      })

      return res;
    },
    email: async (category, ids) => {
      return await this.http.post(`${this.apiLocation}/email?category=${category}`, ids)
    },
    emailSpt: async (category, sptId, ids) => {
      return await this.http.post(`${this.apiLocation}/email?category=${category}&sptId=${sptId}`, ids)
    },
    deleteBulk: async (sptId, ids)=> {
      let res = await http.post(`${this.apiLocation}/${this.path}/${sptId}/delete`, ids);

      return res;
    },
  }

  constructor() {
    super()
    this.init()
    this.initApi()
  }
}


export default new BP15DipotongPihakLainService();
