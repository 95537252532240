import React, { Component } from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm , propTypes, formnameSelector } from 'redux-form';
import {
  Avatar,
  Divider,
  FontIcon,
  List,
  ListItem,
  Subheader,
} from 'react-md';

import FormView from '../../../../../components/entity/form.view';
import {validation, Textfield, Searchfield} from '../../../../../components/form';
import OrganizationService from '../../../Administrator/Organization/Organization.service';
import Spt15Service from './Spt15.service';

@reduxForm({form: '15-Form', destroyOnUnmount: false})
@connect((state) => ({
  access: state.entity.access.api.find,
  organization: state.auth.currentOrganization,
  formData: state.form['15-Form']
}))
export default class SPT15Form extends FormView {
  service=Spt15Service
  initialData={
    status: 'PROCESS',
    pembetulan: 0,
    organization: {}
  }

  constructor(props) {
    super(props);

    var date = new Date();
    let yearOptions = [];
    for(var i=0; i<11; i++) {
      yearOptions.push({
        id: date.getFullYear()-i,
        name: date.getFullYear()-i
      })
    }

    this.state = {
      ...this.state,
      bulanOptions: [
        {id: 1, name: 'Januari'},
        {id: 2, name: 'Februari'},
        {id: 3, name: 'Maret'},
        {id: 4, name: 'April'},
        {id: 5, name: 'Mei'},
        {id: 6, name: 'Juni'},
        {id: 7, name: 'Juli'},
        {id: 8, name: 'Agustus'},
        {id: 9, name: 'September'},
        {id: 10, name: 'Oktober'},
        {id: 11, name: 'November'},
        {id: 12, name: 'Desember'}
      ],
      yearOptions
    }
  }

  componentDidMount() {
    this.initData()
  }

  async initData() {
    if(this.props.match.params.sptId == 'new') {
      this.initialData.organization = this.props.organization;
      this.initialData.pasal = '15'
      this.props.initialize(this.initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.sptId);
      this.props.initialize(res.data);
    }
  }

  formView() {
    var formData = {}
    if(this.props.formData) formData = this.props.formData.values;
    var organization = {};
    if(formData.organization) organization = formData.organization;

    return (
      <div className="md-grid">
        <Field

          label='Organization'
          name='organization'
          id="spt2126-organization"
          className="md-cell md-cell--12"
          // disabled={this.props.organization.id != null}
          valueField='parent'
          params     = {{
            size: 50
          }}
          remoteSearch = {true}
          service={OrganizationService}
          searchField  = {['name', 'aliasName', 'npwp']}
          component={Searchfield}
          itemTemplate={function(d) {
            let nameAndAlias = ""

            if (d.name)
              nameAndAlias = d.name

            if (d.aliasName)
              nameAndAlias += " - " + d.aliasName

            return {
              primaryText  : nameAndAlias,
              secondaryText: d.npwp,
              onClick: () => {
                if(!this.props.disabled){
                  this.handleItemClick(d); this.hide()
                }
              }
            }
          }}
        />
        <div className="md-cell md-cell--6">
          {/* <Field
            label='Pasal'
            name='pasal'
            id="spt2126-pasal"
            disabled
            component={Textfield}
            validate={validation.required}
          /> */}
          <Field
            label='Bulan'
            name='month'
            id="spt2126-pasal"
            component={Searchfield}
            options={this.state.bulanOptions}
            validate={validation.required}
          />
          <Field
            label='Tahun'
            name='year'
            id="spt2126-pasal"
            component={Searchfield}
            options={this.state.yearOptions}
            validate={validation.required}
          />
        </div>

        <div className="md-cell md-cell--6">
          {/*<List className="md-paper md-paper--1" >
            <Subheader primaryText="PENANDA TANGAN" />
            <ListItem
              // leftAvatar={<Avatar icon={<FontIcon>folder</FontIcon>} />}
              primaryText   = {organization.npwp}
              secondaryText = "NPWP"
            />
            <ListItem
              // leftAvatar={<Avatar icon={<FontIcon>folder</FontIcon>} />}
              primaryText   = {organization.name}
              secondaryText = "NAME"
            />
            <Divider />
            <Subheader primaryText="Kuasa" />
            <ListItem
              // leftAvatar={<Avatar icon={<FontIcon>folder</FontIcon>} />}
              primaryText   = {organization.npwp}
              secondaryText = "NPWP"
            />
            <ListItem
              // leftAvatar={<Avatar icon={<FontIcon>folder</FontIcon>} />}
              primaryText   = {organization.name}
              secondaryText = "NAME"
            />

            <Divider />
            <Subheader primaryText="BENDAHARA PEMERINTAH" />
            <ListItem

              // leftAvatar={<Avatar icon={<FontIcon>folder</FontIcon>} />}
              primaryText   = {organization.npwp}
              secondaryText = "NPWP"
            />
            <ListItem
              // leftAvatar={<Avatar icon={<FontIcon>folder</FontIcon>} />}
              primaryText   = {organization.name}
              secondaryText = "Nama Intansi"
            />
          </List>*/}

          <List className="md-paper md-paper--1" >
            <Subheader primaryText="ORGANISASI" />
            <ListItem
              primaryText   = {organization.npwp}
              secondaryText = "NPWP"
            />
            <ListItem
              primaryText   = {organization.name}
              secondaryText = "NAME"
            />
            <ListItem
              primaryText   = {organization.aliasName}
              secondaryText = "ALIAS NAME"
            />
            <ListItem
              primaryText   = {organization.city}
              secondaryText = "CITY"
            />
            <ListItem
              primaryText   = {organization.postalCode}
              secondaryText = "POSTAL CODE"
            />
            <ListItem
              primaryText   = {organization.email}
              secondaryText = "EMAIL"
            />
            <ListItem
              primaryText   = {organization.phone}
              secondaryText = "PHONE"
            />
            <ListItem
              primaryText   = {organization.address}
              secondaryText = "ADDRESS"
            />
            <ListItem
              primaryText   = {(organization.isPemungut) ? 'YES' : 'NO'}
              secondaryText = "PEMUNGUT"
            />
          </List>
        </div>
      </div>
    )
  }

}
