import React, { Component } from 'react';
import { Grid, Cell } from 'react-md';
import { connect } from 'react-redux';
import { Field, reduxForm , propTypes, formValueSelector } from 'redux-form';
import { validation, Textfield, TextfieldMask, Searchfield, Switch, Multiselect, convert } from '../../../components/form';
import DialogView from '../../../components/entity/dialog.view';
import UnitService from './Unit.service';

@connect(state => ({
  isLoginIn: state.auth.isLoginIn,
  user: state.auth.user,
  menuType: state.auth.menuType,
  company: state.auth.currentCompany
}))
@reduxForm({form: 'unit', destroyOnUnmount: false})
export default class UnitDialogView extends DialogView {
  service=UnitService
  initialData = {
  }

  initData(props) {
    if(props.formData.id) {
      this.props.initialize(props.formData);
    } else {
      this.props.initialize(this.initialData);
    }
  }

  formView() {
    return (
      <Grid style={{padding:'none'}}>
        <Field
          label='Kode Perusahaan'
          name='kodePerusahaan'
          className="md-cell md-cell--6"
          component={Textfield}
          validate={validation.required}
        />

        <Field
          label='Nama Unit'
          name='namaUnit'
          className="md-cell md-cell--6"
          component={Textfield}
          validate={validation.required}
        />

        <Field
          label='Kode Wilayah Bisnis'
          name='kodeWilayahBisnis'
          className="md-cell md-cell--6"
          component={Textfield}
          validate={validation.required}
        />

        <Field
          label='Nama Wilayah Bisnis'
          name='namaWilayahBisnis'
          className="md-cell md-cell--6"
          component={Textfield}
          validate={validation.required}
        />

        <Field
          label='NPWP'
          name='npwp'
          className="md-cell md-cell--6"
          component={Textfield}
          validate={validation.required}
        />

        <Field
          label='Nama Sesuai NPWP'
          name='namaSesuaiNpwp'
          className="md-cell md-cell--6"
          component={Textfield}
          validate={validation.required}
        />
        <Field
          label='Report'
          name='status'
          className="md-cell md-cell--6"
          component={Switch}
        />
      </Grid>
    )
  }
}
