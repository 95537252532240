/**
 * Created by dwiargo on 11/29/17.
 */

import {Connect, connect} from 'react-redux';
import React, { Component} from 'react';
import ContainerPaper from '../components/ContainerPaper'
import counterpart from 'counterpart';
import { TextField, Button, FontIcon } from 'react-md';
import Empty from 'react-mpk/components/Empty';
import './Login.scss';
import AuthService from '../../../services/auth.service';
import { Avatar, Tooltipped } from 'react-md';
import izitoast from 'izitoast';
import env from 'env';

@connect()
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showForgotPasswordView: false,
      signature:{},
      formData:{},
      validation:{},
      error:{},
      status:'wait',
      version: '',
      serverStatus: {
        status: 'UP',
        class: 'md-avatar--green'
      },
      usernameReadOnly: true,
      passwordReadOnly: true,
    }
    if(env.enableCaptcha){
      this.state = {
        ...this.state,
        captcha: null,
        captchaText: null,
      }
    }
  }

  async componentDidMount() {
    if(env.enableCaptcha){
      let fetchCaptcha = await fetch(env.host.captcha)
      const getCaptcha = await fetchCaptcha.json();
      if(getCaptcha && getCaptcha.data){
        this.setState({ captcha: getCaptcha })
      }
    }

    let token = localStorage.getItem('token')

    if (token) {
      let objToken = JSON.parse(token)

      if (objToken.access_token) {
        this.props.router.history.push('/');

        return
      }
    }

    try {
      var version = await AuthService.http.get('/management/info');
      if(version.data && version.data.build) {
        this.setState({version: version.data.build.version})
      }
    } catch (e) {
      console.log({e})
    }

    // status
    await this.checkStatus();
    setInterval(async ()=> {
      await this.checkStatus();
    }, 300000)
  }

  async checkStatus() {
    try {
      var status = await AuthService.http.get('/management/health');
      var className = 'md-avatar--red';
      if(status.data.status == 'UP') className = 'md-avatar--green';
      this.setState({serverStatus: {
        status: status.data.status,
        class: className
      }})
    } catch(ex) {
      this.setState({serverStatus: {
        status: 'Down',
        class: 'md-avatar--red'
      }})
    }
  }

  async login() {
    var username = this.refs.username.value;
    var password = this.refs.password.value;
    let res = await AuthService.api.login(username, password, this.props.dispatch, this.props.dispatch);
    this.props.router.history.push('/');
  }

  async forgotPassword() {
    var email = this.refs.email.value;
    let res = await AuthService.api.forgotPasswordInit({email});

    izitoast.info({title: 'Forgot Password', message: res.data.message})
  }

  async loginCaptcha() {
    // Validasi Captcha
    try {
      let validasiCaptcha = await fetch(`${AuthService.captcha}/${this.state.captcha.id}/${this.state.captchaText}`)
      let validasiCaptchaJson = await validasiCaptcha.json()
      if(validasiCaptchaJson == true){
        // Validasi Captcha
        try {
          var username = this.refs.username.value;
          var password = this.refs.password.value;
          let res = await AuthService.api.login(username, password, this.props.dispatch, this.props.dispatch);
          this.props.router.history.push('/');
        } catch (e){
          // izitoast.error({
          //   title: counterpart("custom.fail"),
          //   message: e.message
          // })
        }
      } else {
        izitoast.error({
          title: "Verifikasi",
          message: "Kode Verifikasi Salah",
        })
        let fetchCaptcha = await fetch(AuthService.captcha)
        const getCaptcha = await fetchCaptcha.json();
        if(getCaptcha && getCaptcha.data){
          this.setState({ captcha: getCaptcha })
        }
      }
    } catch(e){
      izitoast.error({
        title: "Verifikasi",
        message: "Kode Verifikasi Salah",
      })
      let fetchCaptcha = await fetch(AuthService.captcha)
      const getCaptcha = await fetchCaptcha.json();
      if(getCaptcha && getCaptcha.data){
        this.setState({ captcha: getCaptcha })
      }
    }
  }

  enter(e) {
    if(e.key === 'Enter') {
      if(env.enableCaptcha){
        this.loginCaptcha()
      } else {
        this.login()
      }
    }
  }

  toggleShowPassword() {
    this.setState((p) => {
      return {
        showForgotPasswordView: !p.showForgotPasswordView
      }
    })
  }

  render() {
    let  {validation, error, status, formData} = this.state;

    if(this.state.showForgotPasswordView) {
      return this.forgotPasswordView();
    }

    return (
        <ContainerPaper
          status={status}
          validation={validation}
          headerText={<div>
            {this.state.version}
          </div>}
          deck="PayJak"
          footer={
            <div className="mpk-margin-N top mpk-layout justify-between mpk-font-weight-M">
              <span
                className="mpk-link mpk-font-weight-M"
                onClick={() => this.toggleShowPassword()}
                >
                {counterpart.translate('word.forgotPassword')}
              </span>
              {/* <span
                className="mpk-link mpk-font-weight-M"
                onClick={() => navService.redirectTo('/account/register')}
                >
                {counterpart.translate('word.createAccount')}
              </span> */}
            </div>
          }
          >
          <div className="body" onSubmit={(e)=> {
            e.preventDefault();
            this.login()
          }
          }>
            <Tooltipped label="Empty element with tooltip">
            <div className={'md-inline-block md-avatar '+this.state.serverStatus.class} style={{
              position: 'fixed',
              top: 14.5,
              right: 40,
              height: 18,
              width: 18
            }}>

              <div className="md-avatar-content"></div>

            </div>
            </Tooltipped>

            <div className="mpk-flex">
              <TextField
                ref="username"
                id="user-username"
                label="Username"
                placeholder="Type your username"
                lineDirection="center"
                type="text"
                required={true}
                onKeyPress={(e)=> this.enter(e)}
                readOnly={this.state.usernameReadOnly}
                onFocus={() => {
                  this.setState({usernameReadOnly: false})
                }}
                autoComplete={"off"}
                />
              <TextField
                ref="password"
                id="user-password"
                label="Password"
                placeholder="Enter your password"
                lineDirection="center"
                type="password"
                onKeyPress={(e)=> this.enter(e)}
                passwordIcon={(
                    <FontIcon
                      iconClassName="mdi mdi-eye"
                      style={{fontSize:16}}
                    />
                  )}
                required={true}
                readOnly={this.state.passwordReadOnly}
                onFocus={() => {
                  this.setState({passwordReadOnly: false})
                }}
                autoComplete={"off"}
                />
              {env.enableCaptcha &&
                <div>
                  <Empty height={16}/>
                  { (this.state.captcha && this.state.captcha.data) &&
                    <div style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: this.state.captcha.data }}></div>
                  }
                  <TextField
                    ref="captchaText"
                    id="user-captcha"
                    label="Captcha"
                    placeholder="Enter your captcha"
                    lineDirection="center"
                    type="text"
                    onChange={(value)=> {
                      this.setState({ captchaText: value })
                    }}
                    onKeyPress={(e)=> this.enter(e)}
                    required={true}
                    />
                  <Empty height={16}/>
                </div>
              }
            </div>

          </div>
          <div onClick={()=> {
            if(env.enableCaptcha){
              this.loginCaptcha()
            } else {
              this.login()
            }
          }} className="action mpk-flex-none">
              <Button raised secondary type="submit">Sign In</Button>
          </div>
        </ContainerPaper>

    )
  }

  loginView() {

    if(this.state.showForgotPasswordView) {
      return this.forgotPasswordView();
    }

    return (
      <form>
        <div className="title">Login</div>
        <TextField
          ref="username"
          id="user-username"
          label="Username"
          placeholder="Type your Username"
          lineDirection="center"
          type="text"
          required={true}
          onKeyPress={(e)=> this.enter(e)}
          />
        <TextField
          ref="password"
          id="user-password"
          label="Password"
          placeholder="Enter your Password"
          lineDirection="center"
          type="password"
          onKeyPress={(e)=> this.enter(e)}
          passwordIcon={(
              <FontIcon
                iconClassName="mdi mdi-eye"
                style={{fontSize:16}}
              />
            )}
          required={true}
          />
        <Empty height={16}/>
        <Button
          onClick={()=> this.login()}
          style={{width:"100%"}}
          flat primary swapTheming
          >
          Submit
        </Button>
        <div className="opt">
          <span className="mpk-link" onClick={()=> this.toggleShowPassword()}>Forgot Password?</span>
        </div>
      </form>
    )
  }

  forgotPasswordView() {
    /*return (
      <form>
        <div className="title">Forgot Password</div>
        <TextField
          ref="email"
          id="user-email"
          label="email"
          placeholder="Type your email"
          lineDirection="center"
          type="text"
          required={true}
          onKeyPress={(e)=> this.enter(e)}
          />
        <Button
          onClick={()=> {}}
          style={{width:"100%"}}
          flat primary swapTheming
          >
          Submit
        </Button>
        <div className="opt">
          <span className="mpk-link" onClick={()=> this.toggleShowPassword()}>Login?</span>
        </div>
      </form>
    )*/

    let  {validation, error, status, formData} = this.state;

    return (
      <ContainerPaper
        status={status}
        validation={validation}
        headerText={<div>
          {this.state.version}
        </div>}
        deck="PayJak"
        footer={
          <div className="mpk-margin-N top mpk-layout justify-between mpk-font-weight-M">
              <span
                className="mpk-link mpk-font-weight-M"
                onClick={() => this.toggleShowPassword()}
              >
                {counterpart.translate('word.login')}
              </span>
            {/* <span
              className="mpk-link mpk-font-weight-M"
              onClick={() => navService.redirectTo('/account/register')}
            >
                {counterpart.translate('word.createAccount')}
            </span> */}
          </div>
        }
      >
        <div className="body" onSubmit={(e)=> {
          e.preventDefault();
          this.forgotPassword()
        }
        }>
          <Tooltipped label="Empty element with tooltip">
            <div className={'md-inline-block md-avatar '+this.state.serverStatus.class} style={{
              position: 'fixed',
              top: 14.5,
              right: 40,
              height: 18,
              width: 18
            }}>
              <div className="md-avatar-content"></div>
            </div>
          </Tooltipped>

          <div className="mpk-flex">
            <TextField
              ref="email"
              id="user-email"
              label="Email"
              placeholder="Enter your Email"
              lineDirection="center"
              type="text"
              required={true}
              onKeyPress={(e)=> this.enter(e)}
            />
          </div>

        </div>
        <div onClick={()=> this.forgotPassword()} className="action mpk-flex-none">
          <Button raised secondary type="submit">Send</Button>
        </div>
      </ContainerPaper>
    );
  }
}



export default Login;
