import React from 'react';
import { Route, Switch } from 'react-router-dom';
import OrganizationCompanyView from './Organization/Organization.company.view';
import OrganizationUserAllView from './OrganizationUserAll/OrganizationUserAll.view';
import Signer21AllView from './Signer21All/Signer21All.view';
import Signer23AllView from './Signer23All/Signer23All.view';
import Signer4a2AllView from './Signer4a2All/Signer4a2All.view';
import Signer22AllView from './Signer22All/Signer22All.view';
import Signer15AllView from './Signer15All/Signer15All.view';
import UserCompanyView from './User/User.company.view';
import OwnerCompanyView from './Owner/Owner.company.view';
import SettingEmailView from './../../Main/pph/21_26/SettingEmail/SettingEmail.view'
import SettingEmailContentView from './../../Main/Setting/SettingEmailContent/SettingEmailContent.view'
import SettingEmailScheduleView from './../../Main/Setting/SettingEmailSchedule/SettingEmailSchedule.view'
import SettingEfilingView from './../../Main/pph/21_26/SettingEfiling/SettingEfiling.view'
import AboutCompanyView from './About/About.company.view';
import TemplateRoleView from '../Administrator/TemplateRole/TemplateRole.view';
import ImportUserView from '../Import/ImportUser/ImportUser.view';
import ExportCsv21LogView from '../pph/21_26/ExportCsv21Log/ExportCsv21Log.view';
import Email21LogView from '../pph/21_26/Email21Log/Email21Log.view';
import SPT2126View from '../pph/21_26/spt/21_26.spt.view';
import ImportSigner23View from '../pph/23_26/Import/ImportSigner/ImportSigner23.view';
import ImportSigner21View from '../pph/21_26/ImportSigner/ImportSigner21.view';
import LogEfillingView from '../Efilling/LogEfilling.view';
import LogExportView from '../Export/LogExport.view';
import ImportSigner4a2View from '../pph/4a2/Import/ImportSigner/ImportSigner4a2.view';
import MasterLawanTransaksiA1 from '../Master/MasterLawanTransaksiA1/MasterLawanTransaksiA1.view';
import MasterLawanTransaksiA2 from '../Master/MasterLawanTransaksiA2/MasterLawanTransaksiA2.view';
import MasterLawanTransaksiPp from '../Master/MasterLawanTransaksiPp/MasterLawanTransaksiPp.view';
import MasterLawanTransaksi23 from '../Master/MasterLawanTransaksi23/MasterLawanTransaksi23.view';
import MasterLawanTransaksi4a2 from '../Master/MasterLawanTransaksi4a2/MasterLawanTransaksi4a2.view';
import OrganizationKppView from '../Company/OrganizationKpp/OrganizationKpp.view';

export default (props) => (
  <Switch>
    <Route path={props.match.path+'/organization'} component={OrganizationCompanyView} />
    <Route path={props.match.path+'/organizationUser'} component={OrganizationUserAllView} />
    <Route path={props.match.path+'/signer21'} component={Signer21AllView} />
    <Route path={props.match.path+'/signer23'} component={Signer23AllView} />
    <Route path={props.match.path+'/signer4a2'} component={Signer4a2AllView} />
    <Route path={props.match.path+'/signer22'} component={Signer22AllView} />
    <Route path={props.match.path+'/signer15'} component={Signer15AllView} />
    <Route path={props.match.path+'/user'} component={UserCompanyView} />
    <Route path={props.match.path+'/owner'} component={OwnerCompanyView} />
    <Route path={props.match.path+"/aboutCompany"} component={AboutCompanyView} />
    <Route path={props.match.path+"/templateRole"} component={TemplateRoleView} />
    <Route path={props.match.path+"/IMPORT/USER"} component={ImportUserView} />
    <Route path={props.match.path+"/IMPORT/SIGNER23"} component={ImportSigner23View} />
    <Route path={props.match.path+"/IMPORT/SIGNER21"} component={ImportSigner21View} />
    <Route path={props.match.path+"/IMPORT/SIGNER4A2"} component={ImportSigner4a2View} />
    <Route path={props.match.path+"/lawanTransaksi/pph21/a1"} component={MasterLawanTransaksiA1} />
    <Route path={props.match.path+"/lawanTransaksi/pph21/a2"} component={MasterLawanTransaksiA2} />
    <Route path={props.match.path+"/lawanTransaksi/pph21/pp"} component={MasterLawanTransaksiPp} />
    <Route path={props.match.path+"/lawanTransaksi/pph23"} component={MasterLawanTransaksi23} />
    <Route path={props.match.path+"/lawanTransaksi/4a2"} component={MasterLawanTransaksi4a2} />
    <Route path={props.match.path+'/organizationKpp'} component={OrganizationKppView} />

    {/* PPH21 */}
    <Route path={props.match.path+"/SPT_21"} component={SPT2126View} />
    {/* END PPH21 */}

    {/* LOG */}
    <Route path={props.match.path+"/LOG_EXPORT_CSV_21"} component={ExportCsv21LogView} />
    <Route path={props.match.path+"/LOG_EMAIL_21"} component={Email21LogView} />
    {/* END LOG */}

    <Route path={props.match.path+"/SETTING_EMAIL"} component={SettingEmailView} />
    <Route path={props.match.path+"/SETTING_EMAIL_CONTENT"} component={SettingEmailContentView} />
    <Route path={props.match.path+"/SETTING_EMAIL_SCHEDULE"} component={SettingEmailScheduleView} />

    {/* Efilling */}
    <Route path={props.match.path+"/eFiling/:pasal"} render={(props) => (
      <LogEfillingView key={props.match.params.pasal} {...props} />
    )} />
    {/* END Efilling */}

    {/* log export */}
    <Route path={props.match.path+"/logExport/:pasal"} render={(props) => (
      <LogExportView key={props.match.params.pasal} {...props} />
    )} />
    {/* END log export */}


    {/* SETTING */}
    <Route path={props.match.path+"/SETTING_EMAIL"} component={SettingEmailView} />
    <Route path={props.match.path+"/SETTING_EMAIL_CONTENT"} component={SettingEmailContentView} />
    <Route path={props.match.path+"/SETTING_EMAIL_SCHEDULE"} component={SettingEmailScheduleView} />
    <Route path={props.match.path+"/SETTING_EFILING"} component={SettingEfilingView} />
    {/* END SETTING */}

    <Route path={props.match.path+"/master/tarif-23"} exact component={require('../pph/23_26/master/Tarif23/Master23Tarif.form').default} />

    <Route path={props.match.path+'/'} component={function() {return <div/>}} />
  </Switch>
)
