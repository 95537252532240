import ApiService from '../../../../services/api.service';

class PermissionsService extends ApiService {
  name= 'Permissions';
  path= 'permission';

  constructor() {
    super();
    this.init()
  }
}


export default new PermissionsService();
