import React, { Component } from 'react';
import { connect } from 'react-redux';

import FormDialog from './MasterTarifPasal17Berlapis.dialog.view';
import MasterTarifPasal17BerlapisService from './MasterTarifPasal17Berlapis.service';
import ListView from '../../../../components/entity/listView';
import ColumnService from '../../../../services/column.service';

@connect(MasterTarifPasal17BerlapisService.stateConnectSetting(), MasterTarifPasal17BerlapisService.actionConnectSetting())
export default class MasterTarifPasal17BerlapisView extends ListView {
  service=MasterTarifPasal17BerlapisService
  FormDialog=FormDialog

  columns=[
    {label: "word.code",  value: "code", isDefaultSort:true, show:true, isSearchable:true},
    {label: "word.start",  value: function(d){ return ColumnService.money(d.start) }, type: "func", isDefaultSort:true, show:true, isSearchable:true},
    {label: "word.to",  value: function(d){ return ColumnService.money(d.to) }, type: "func", isDefaultSort:true, show:true, isSearchable:true},
    {label: "word.percent",  value: function(d){ return ColumnService.money(d.percent) }, type: "func", isDefaultSort:true, show:true, isSearchable:true},
  ]
}
