import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import {Button, Card, FontIcon, List, ListItem, Subheader} from 'react-md';
import _ from 'lodash';
import FormView from '../../../../components/entity/form.view';
import {Datepicker, Switch, Textfield} from '../../../../components/form';
import SettingImportService from './SettingImport.service';
import '../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

@reduxForm({
  form: 'SettingImport_Form',
  destroyOnUnmount: true,
  initialValues: {autoCalculateDaftarPemotonganDepositoTabungan: false}
})
@connect((state) => ({
  access: state.entity.access.api.find,
  organization: state.auth.currentOrganization,
  spt: state.auth.currentSPT,
  formData: state.form.SettingImport_Form,
  user: state.auth.user
}))
export default class SettingImportForm extends FormView {
  service = SettingImportService
  viewType = 2;

  constructor(props) {
    super(props)
    this.state = {
      ...this.state
    }
  }

  _barItem() {
    const {handleSubmit, submitting, valid, pristine} = this.props;
    var companyId = this.props.match.params.companyId

    if (companyId) {
      return (
        <div>
          <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>Save</Button>
        </div>
      )
    } else {
      return (
        <div>
          <Button secondary flat onClick={() => {
            this.props.history.goBack()
          }} style={{marginRight: 16}}>Back</Button>
          <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>Save</Button>
        </div>
      )
    }
  }

  async initData() {
    var companyId = this.props.match.params.companyId

    if (!companyId) {
      // Try in url
      var paramInurl = this.props.location.pathname.split("/")

      if (paramInurl[2]) {
        companyId = paramInurl[2]
      }
    }

    if (this.props.user.company && this.props.user.company.id) companyId = this.props.user.company.id;

    try {
      let res = await this.service.api.getByCompany(companyId);

      let data = res.data

      this.props.initialize(data);
    } catch (e) {
      this.toast.warning({
        title: e.name,
        message: e.message
      })
    }
  }

  async handleSave(value) {
    try {
      let res = await this.service.api.updateByCompany(value)
    } catch (e) {
      this.toast.warning({
        title: e.name,
        message: e.message
      })
    }
  }

  formView() {
    return (
      <div>
        <Card>
          <div className='md-grid'>
            <div className="md-cell md-cell--4">
              <Field
                label='Hitung Otomatis Daftar Pemotongan Deposito/Tabungan'
                name='autoCalculateDaftarPemotonganDepositoTabungan'
                component={Switch}
              />
            </div>
          </div>
        </Card>
        <br/>
      </div>
    )
  }

}
