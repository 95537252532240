import ApiService from '../../../../../../services/api.service';

class AllBP15Pasal24Service extends ApiService {
  name= 'AllBP15Pasal24';
  path= 'spt15/bp-24/organization';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api= {
    email: async (category, ids) => {
      return await this.http.post(`${this.apiLocation}/email?category=${category}`, ids)
    }
  }

}

export default new AllBP15Pasal24Service();
