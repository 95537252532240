import React, { Component } from 'react';
import { Grid } from 'react-md';
import { Field, reduxForm , propTypes, formValueSelector } from 'redux-form';
import { validation, Textfield, TextfieldMask, convert } from '../../../../components/form';
import DialogView from '../../../../components/entity/dialog.view';
import MasterLawanTransaksi23Service from './MasterLawanTransaksi23.service';
import OrganizationService from "modules/Main/Administrator/Organization/Organization.service";
import {Searchfield} from "src/components/form";

@reduxForm({form: 'master-lawan-transaksi-23-dialog', destroyOnUnmount: false})
export default class MasterLawanTransaksi23DialogView extends DialogView {
  service=MasterLawanTransaksi23Service
  initialData = {
  }

  formView() {
    return (
      <Grid style={{padding:'none'}}>
        <Field

          label='Organization'
          name='organization'
          id="spt2126-organization"
          className="md-cell md-cell--12"
          valueField='parent'
          service={OrganizationService}
          searchField  = {['name', 'aliasName', 'npwp']}
          component={Searchfield}
          itemTemplate={function(d) {
            let nameAndAlias = ""

            if (d.name)
              nameAndAlias = d.name

            if (d.aliasName)
              nameAndAlias += " - " + d.aliasName

            return {
              primaryText  : nameAndAlias,
              secondaryText: d.npwp,
              onClick: () => {
                if(!this.props.disabled){
                  this.handleItemClick(d); this.hide()
                }
              }
            }
          }}
          validate={validation.required}
        />
        <Field
          label='NPWP'
          name='npwp'
          className="md-cell md-cell--6"
          component={TextfieldMask}
          maskFormat="##.###.###.#-###-###"
          mask="_"
          length={15}
          validate={validation.required}
        />
        <Field
          label='Name'
          name='nama'
          className="md-cell md-cell--6"
          component={Textfield}
          validate={validation.required}
        />
        <Field
          label='Alamat'
          name='alamat'
          className="md-cell md-cell--6"
          component={Textfield}
        />
        <Field
          label='Postal Code'
          name='kodePos'
          className="md-cell md-cell--6"
          component={Textfield}
        />
        <Field
          label='Phone'
          name='telepon'
          className="md-cell md-cell--6"
          component={Textfield}
        />
        <Field
          label='Email'
          name='email'
          className="md-cell md-cell--6"
          component={Textfield}
        />
      </Grid>
    )
  }
}
