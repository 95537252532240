import ApiService from '../../../../services/api.service';

class ImportUserService extends ApiService {
  name= 'ImportUser';
  path= 'log-import-csv/page';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api= {
    validasi: async (fileId, page) => {
      if(page){
        page = page
      } else {
        page = 0
      }
      var res = await this.http.get(this.apiLocation+'/validation-csv', {
        params: {
          fileId : fileId,
          page: page,
          size: 100
        }
      })

      return res.data;
    },

    delete: async (id) => {
      var res = await this.http.delete(this.apiLocation+'/log-import-csv/delete/'+id)

      return res.data;
    },

    deleteSelected: async (ids) => {
      var res = await this.http.post(this.apiLocation+'/log-import-csv/delete', ids)

      return res.data;
    },

    downloadTemplate: async (id) => {
      var res = await this.http.get(`${this.apiLocation}/log-import-csv/download-template/${id}`)
      return res;
    },
  }
}


export default new ImportUserService();
