import React, { Component } from 'react';
import { connect } from 'react-redux';

import FormDialog from './MasterApplication.dialog.view';
import MasterApplicationService from './MasterApplication.service';
import ListView from '../../../../components/entity/listView';
import {Chip} from "react-md";

@connect(MasterApplicationService.stateConnectSetting(), MasterApplicationService.actionConnectSetting())
export default class MasterApplicationView extends ListView {
  service=MasterApplicationService
  FormDialog=FormDialog

  defaultColumns = [
    {isSortable:true, label: "word.id",  value: "id", show:false, isSearchable:true, isDefaultSort: false},
  ]

  columns=[
    {label: "word.company",  value: "company.nama", isDefaultSort:false, show:true, isSearchable:true},
    {label: "word.name",  value: "name", isDefaultSort:false, show:true, isSearchable:true},
    {label: "word.description",  value: "description", isDefaultSort:false, show:true, isSearchable:true},
    {label: "word.generatePdf", searchField: 'isGeneratePdf', value: (d)=> {
        if(d.isGeneratePdf) {
          return <Chip label="YA" style={{backgroundColor: '#4CAF50'}} />
        } else {
          return <Chip label="TIDAK" style={{backgroundColor: '#FF9800'}} />
        }
      }, type:'func', show:true, isSearchable:true},
    {label: "word.clientId",  value: "oauthClientDetail.id", isDefaultSort:false, show:true, isSearchable:true},
    {label: "word.clientSecret",  value: "oauthClientDetail.clientSecret", isDefaultSort:false, show:true, isSearchable:true},
  ]
}
