/**
 * Created by dwiargo on 11/29/17.
 */

import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ManageUser from './ManageUser';

export default actions => (
  <Switch>
    <Route path="/entities/manageUser"
           exact render={(props) => (
      <ManageUser router={props} actions={actions} />
    )}/>
  </Switch>
)
