import ApiService from '../../../../services/api.service';

class MasterBiayaJabatanService extends ApiService {
  name= 'masterBiayaJabatan';
  path= 'master/biaya-jabatan';

  constructor() {
    super();
    this.init()
  }
}


export default new MasterBiayaJabatanService();
