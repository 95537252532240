import React from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  Tab,
  Tabs,
  SelectionControlGroup,
  Button
} from 'react-md';
import download from 'downloadjs';
import uuid from 'uuid';
import iziToast from 'izitoast';

import FormView from '../../../../../components/entity/form.view';
import SPTINDUK21Service from './SPT_INDUK_21.service';
import {validation, Textfield, Searchfield, Switch, Checkbox, Datepicker, TextfieldMask} from '../../../../../components/form';
import columnService from '../../../../../services/column.service';
import SPT21SignerService from '../SPT21Signer/SPT21Signer.service';

@reduxForm({form: 'SPT_INDUK_21_Form', destroyOnUnmount: true})
@connect((state) => ({
  access      : state.entity.access.api.find,
  organization: state.auth.currentOrganization,
  spt         : state.auth.currentSPT,
  formData    : state.form.SPT_INDUK_21_Form
}))
export default class SPTINDUK21Form extends FormView {
  service  = SPTINDUK21Service
  viewType = 2;

  initialData={
    bagB1: {},
    bagB2: {},
    bagC : {},
    bagD : {},
    bagE : {}
  }

  constructor(props){
    super(props);
    this.state = {
      ...this.state,
      cetak    : false,
      calculate: false,
      signer   : {

      }
    }
  }

  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      let value = this.props.formData.values
      this.handleSave(value)
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  async handleSave(value) {
    try {
      this.setState({onProgress: true})
      if(value.id) {
        var res = await this.service.api.update(value)

        if(res.data) {
          this.props.initialize(res.data);
        }
      } else {
        await this.service.api.save(value)
      }
      this.setState({onProgress: false})
      this.toast.success({title: 'Success', message: 'Saved'})
    } catch(e) {
      var msg              = e.message;
      if  (e.response) msg = e.response.data.message;
      this.setState({
        isError     : true,
        onProgress  : false,
        errorMessage: msg
      })
    }
  }

  async initData() {
      let res = await this.service.api.findOne(this.props.match.params.sptId);

      let signer = null;

      if(res.data) {
        try {
          signer = await this.service.api.getSigner({
            orgId: this.props.spt.data.organization.id,
            tgl  : res.data.bagE.e05
          })
        } catch(e) {}

        if(signer) {
          this.setState({signer: signer})

          if(res.data.bagE.e0102) {
            res.data.bagE.e03 = signer.npwpPenandaTanganSptInduk;
            res.data.bagE.e04 = signer.namaPenandaTanganSptInduk;
          } else {
            res.data.bagE.e03 = signer.npwpKuasa;
            res.data.bagE.e04 = signer.namaKuasa;
          }

        }
      }
      this.props.initialize(res.data);
  }

  async calculate() {
    await this.setState({calculate: true})
    await this.props.dispatch({
      type: 'TOGGLE_GLOBAL_PROGRESS',
      data: true
    });

    const sptId = this.props.match.params.sptId

    try {
      var res = await this.service.api.calculate(sptId)

      if(res.data) {
        this.props.initialize(res.data);
      }
    } catch(e) {

    }


    this.setState({calculate: false})
    await this.props.dispatch({
      type: 'TOGGLE_GLOBAL_PROGRESS',
      data: false
    });
  }

  async changeSigner() {
    if(this.state.signer.id) {
      let formData                                                     = this.initialData
      if  (this.props.formData && this.props.formData.values) formData = this.props.formData.values
      if(formData.bagE.e0102) {
        this.props.change('bagE.e03', this.state.signer.npwpPenandaTanganSptInduk);
        this.props.change('bagE.e04', this.state.signer.namaPenandaTanganSptInduk);
      } else {
        this.props.change('bagE.e03', this.state.signer.npwpKuasa);
        this.props.change('bagE.e04', this.state.signer.namaKuasa);
      }
    }
  }


  async handleCetak(){
    this.setState({ cetak: true })
    try {
      const sptId    = this.props.match.params.sptId
      var   report   = await this.service.api.cetak(sptId)
      var   filename = report.headers.filename;
      download(report.data, filename);
      this.setState({ cetak: false })
    } catch (e) {
      const reader = new FileReader();

      /// This fires after the blob has been read/loaded.
      reader.addEventListener('loadend', (e) => {
        const text = e.srcElement.result
        let json = JSON.parse(text)

        if (json.detail)
          iziToast.error({
            title: 'Error',
            message: json.detail
          })
      });

      reader.readAsText(e.response.data)

      this.setState({cetak: false})
    }
  }

  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;
    let   statusSPT                                     = "";
    let   statusInSPT                                   = false;
    if(this.props.spt){
        if(this.props.spt.data){
            if(this.props.spt.data.status){
                statusSPT   = this.props.spt.data.status;
                statusInSPT = true
            }
        }
    }

    if(statusSPT == "FINISH" || statusSPT == "WAITING"){
        return (
            <div>
              <Button disabled={this.state.cetak} primary raised style={{marginRight: 5}} onClick={this.handleCetak.bind(this)}>Cetak</Button>
            </div>
        )
    } else {
        if(statusInSPT){
            return (
              <div>
                <Button disabled={this.state.cetak} primary raised style={{marginRight: 5}} onClick={this.handleCetak.bind(this)}>Cetak</Button>
                {/* <Button disabled={this.state.calculate} primary raised style={{marginRight: 5}} onClick={this.calculate.bind(this)}>calculate</Button> */}

                <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>Save and Calculate</Button>
              </div>
            )
        } else {
            return (
                <div>
                    <Button disabled={this.state.calculate} primary raised style={{marginRight: 5}} onClick={this.calculate.bind(this)}>calculate</Button>

                    <Button secondary flat onClick={()=> {this.props.history.goBack()}} style={{marginRight: 16}}>Back</Button>
                </div>
            )
        }
    }
  }

  formView() {
    let formData                                                     = this.initialData
    let spt = this.props.spt.data
    if  (this.props.formData && this.props.formData.values) formData = this.props.formData.values

    var masaOptions = [
      {id:1, name: 1}, {id:2, name: 2}, {id:3, name: 3}, {id:4, name: 4}, {id:5, name: 5}, {id:6, name: 6},
      {id:7, name: 7}, {id:8, name: 8}, {id:9, name: 9}, {id:10, name: 10}, {id:11, name: 11}, {id:12, name: 12}
    ]

    let no18Disabled = true

    if (formData && spt) {
      if (spt.pembetulan === 0 && formData.bagB2.b15 < 0) {
        no18Disabled = false
      }

      if (spt.pembetulan > 0 && formData.bagB2.b17 < 0) {
        no18Disabled = false
      }
    }

    return (
      <div>
        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='A. IDENTITAS PEMOTONG' />
          <Divider/>

          <div className='md-grid'>
            <div className="md-cell md-cell--6">
              <Field
                label      = 'NPWP'
                name       = 'bagA.a01'
                id         = "BP21A1Form-npwp"
                component  = {TextfieldMask}
                maskFormat = "##.###.###.#-###-###"
                validate   = {validation.required}
                disabled
              />
              <Field
                label     = 'Nama'
                name      = 'bagA.a02'
                id        = "BP21A1Form-nama"
                component = {Textfield}
                validate  = {validation.required}
                disabled
              />
            </div>

            <div className="md-cell md-cell--6">
              <Field
                label     = 'No. Telp'
                name      = 'bagA.a04'
                component = {Textfield}
                validate  = {validation.required}
                disabled
              />
              <Field
                label     = 'Email'
                name      = 'bagA.a05'
                component = {Textfield}
                validate  = {validation.required}
                disabled
              />
            </div>

            <div className="md-cell md-cell--12">
              <Field
                label     = 'Alamat'
                name      = 'bagA.a03'
                id        = "BP21A1Form-alamat"
                component = {Textfield}
                validate  = {validation.required}
                disabled
              />
            </div>
          </div>
        </Card>
        <br/>

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='B. OBJEK PAJAK' />
          <Divider/>
          <DataTable plain tableStyle={{minWidth: 1000}}>
            <TableHeader>
              <TableRow>
                <TableColumn className='md-text-center' style={{width: 5}}>NO</TableColumn>
                <TableColumn className='md-text-center'>PENERIMA PENGHASILAN</TableColumn>
                <TableColumn className='md-text-center' style={{width: 130}}>KODE</TableColumn>
                <TableColumn className='md-text-center'>JUMLAH PENERIMA</TableColumn>
                <TableColumn className='md-text-center'>JUMLAH PENGHASILAN BRUTO</TableColumn>
                <TableColumn className='md-text-center'>JUMLAH PAJAK DIPOTONG</TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableColumn className='md-text-center' style={{height: 30}}>(1)</TableColumn>
                <TableColumn className='md-text-center' style={{height: 30}}>(2)</TableColumn>
                <TableColumn className='md-text-center' style={{height: 30}}>(3)</TableColumn>
                <TableColumn className='md-text-center' style={{height: 30}}>(4)</TableColumn>
                <TableColumn className='md-text-center' style={{height: 30}}>(5)</TableColumn>
                <TableColumn className='md-text-center' style={{height: 30}}>(6)</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>1.</TableColumn>
                <TableColumn>PEGAWAI TETAP</TableColumn>
                <TableColumn className='md-text-center'>21-100-01</TableColumn>
                <TableColumn className='md-text-right'>{formData.bagB1.b14}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b15)}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b16)}</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>2.</TableColumn>
                <TableColumn>PENERIMA PENSIUN BERKALA</TableColumn>
                <TableColumn className='md-text-center'>21-100-02</TableColumn>
                <TableColumn className='md-text-right'>{formData.bagB1.b24}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b25)}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b26)}</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>3.</TableColumn>
                <TableColumn>PEGAWAI TIDAK TETAP ATAU TENAGA KERJA LEPAS</TableColumn>
                <TableColumn className='md-text-center'>21-100-03</TableColumn>
                <TableColumn className='md-text-right'>{formData.bagB1.b34}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b35)}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b36)}</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>4.</TableColumn>
                <TableColumn>BUKAN PEGAWAI: </TableColumn>
                <TableColumn style={{background: '#DDD'}}></TableColumn>
                <TableColumn style={{background: '#DDD'}}></TableColumn>
                <TableColumn style={{background: '#DDD'}}></TableColumn>
                <TableColumn style={{background: '#DDD'}}></TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>4a. DISTRIBUTOR MULTILEVEL MARKETING (MLM)</TableColumn>
                <TableColumn className='md-text-center'>21-100-04</TableColumn>
                <TableColumn className='md-text-right'>{formData.bagB1.b4a4}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b4a5)}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b4a6)}</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>4b. PETUGAS DINAS LUAR ASURANSI</TableColumn>
                <TableColumn className='md-text-center'>21-100-05</TableColumn>
                <TableColumn className='md-text-right'>{formData.bagB1.b4b4}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b4b5)}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b4b6)}</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>4c. PENJAJA BARANG DAGANGAN</TableColumn>
                <TableColumn className='md-text-center'>21-100-06</TableColumn>
                <TableColumn className='md-text-right'>{formData.bagB1.b4c4}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b4c5)}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b4c6)}</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>4d. TENAGA AHLI</TableColumn>
                <TableColumn className='md-text-center'>21-100-07</TableColumn>
                <TableColumn className='md-text-right'>{formData.bagB1.b4d4}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b4d5)}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b4d6)}</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>4e. BUKAN PEGAWAI YANG MENERIMA IMBALAN YANG BERSIFAT BERKESINAMBUNGAN</TableColumn>
                <TableColumn className='md-text-center'>21-100-08</TableColumn>
                <TableColumn className='md-text-right'>{formData.bagB1.b4e4}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b4e5)}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b4e6)}</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>4f. BUKAN PEGAWAI YANG MENERIMA IMBALAN YANG TIDAK BERSIFAT BERKESINAMBUNGAN</TableColumn>
                <TableColumn className='md-text-center'>21-100-09</TableColumn>
                <TableColumn className='md-text-right'>{formData.bagB1.b4f4}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b4f5)}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b4f6)}</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>5.</TableColumn>
                <TableColumn>ANGGOTA DEWAN KOMISARIS ATAU DEWAN PENGAWAS YANG TIDAK MERANGKAP SEBAGAI PEGAWAI TETAP</TableColumn>
                <TableColumn className='md-text-center'>21-100-10</TableColumn>
                <TableColumn className='md-text-right'>{formData.bagB1.b54}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b55)}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b56)}</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>6.</TableColumn>
                <TableColumn>MANTAN PEGAWAI YANG MENERIMA JASA PRODUKSI,TANTIEM, BONUS ATAU IMBALAN LAIN</TableColumn>
                <TableColumn className='md-text-center'>21-100-11</TableColumn>
                <TableColumn className='md-text-right'>{formData.bagB1.b64}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b65)}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b66)}</TableColumn>
                </TableRow>
              <TableRow>
                <TableColumn>7.</TableColumn>
                <TableColumn>PEGAWAI YANG MELAKUKAN PENARIKAN DANA PENSIUN</TableColumn>
                <TableColumn className='md-text-center'>21-100-12</TableColumn>
                <TableColumn className='md-text-right'>{formData.bagB1.b74}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b75)}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b76)}</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>8.</TableColumn>
                <TableColumn>PESERTA KEGIATAN</TableColumn>
                <TableColumn className='md-text-center'>21-100-13</TableColumn>
                <TableColumn className='md-text-right'>{formData.bagB1.b84}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b85)}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b86)}</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>9.</TableColumn>
                <TableColumn>PENERIMA PENGHASILAN YANG DIPOTONG PPh PASAL 21 TIDAK FINAL LAINNYA</TableColumn>
                <TableColumn className='md-text-center'>21-100-99</TableColumn>
                <TableColumn className='md-text-right'>{formData.bagB1.b94}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b95)}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b96)}</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>10.</TableColumn>
                <TableColumn>PEGAWAI/PEMBERI JASA/PESERTA KEGIATAN/PENERIMA PENSIUN BERKALA SEBAGAI WAJIB PAJAK LUAR NEGERI</TableColumn>
                <TableColumn className='md-text-center'>27-100-99</TableColumn>
                <TableColumn className='md-text-right'>{formData.bagB1.b104}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b105)}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b106)}</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>11.</TableColumn>
                <TableColumn>JUMLAH (PENJUMLAHAN ANGKA 1 S.D. 10)</TableColumn>
                <TableColumn style={{background: '#DDD'}}></TableColumn>
                <TableColumn className='md-text-right'>{formData.bagB1.b114}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b115)}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b116)}</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn style={{background: '#DDD'}}></TableColumn>
                <TableColumn style={{background: '#DDD'}} colSpan={4}>PENGHITUNGAN PPh PASAL 21 DAN/ATAU PASAL 26 YANG KURANG (LEBIH) DISETOR</TableColumn>
                <TableColumn style={{background: '#DDD'}}>JUMLAH (Rp)</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>12.</TableColumn>
                <TableColumn colSpan={4}>STP PPh PASAL 21 DAN/ATAU PASAL 26 (HANYA POKOK PAJAK)</TableColumn>
                <TableColumn className='md-text-right'>
                  <Field
                    // label='Bruto'
                    name      = 'bagB2.b12'
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    money     = {','}
                    onChange  = {async (e, v)=> {
                      var bag13 = formData.bagB2.b13;
                      if(!bag13) bag13 = 0;
                      if(!v) v=0;
                      await this.props.change('bagB2.b14', parseFloat(v)+parseFloat(bag13));

                      var b14 = parseFloat(v)+parseFloat(bag13);
                      var b15 = parseFloat(formData.bagB1.b116) - b14;

                      await this.props.change('bagB2.b15', b15)

                      if (spt.pembetulan > 0) {
                        await this.props.change('bagB2.b17', b15 - formData.bagB2.b16)
                      }
                    }}
                    />
                    {/* {formData.bagB2.b12} */}
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>13.</TableColumn>
                <TableColumn colSpan={4}>
                  <p>KELEBIHAN PENYETORAN PPh PASAL 21 DAN/ATAU PASAL 26 DARI: </p>
                  <div>
                    <Field
                      label     = '01'
                      name      = 'bagB2.b13Month1'
                      style     = {{float: "left"}}
                      component = {Checkbox}
                    />
                    <Field
                      label     = '02'
                      name      = 'bagB2.b13Month2'
                      style     = {{float: "left"}}
                      component = {Checkbox}
                    />
                    <Field
                      label     = '03'
                      name      = 'bagB2.b13Month3'
                      style     = {{float: "left"}}
                      component = {Checkbox}
                    />
                    <Field
                      label     = '04'
                      name      = 'bagB2.b13Month4'
                      style     = {{float: "left"}}
                      component = {Checkbox}
                    />
                    <Field
                      label     = '05'
                      name      = 'bagB2.b13Month5'
                      style     = {{float: "left"}}
                      component = {Checkbox}
                    />
                    <Field
                      label     = '06'
                      name      = 'bagB2.b13Month6'
                      style     = {{float: "left"}}
                      component = {Checkbox}
                    />
                    <Field
                      label     = '07'
                      name      = 'bagB2.b13Month7'
                      style     = {{float: "left"}}
                      component = {Checkbox}
                    />
                    <Field
                      label     = '08'
                      name      = 'bagB2.b13Month8'
                      style     = {{float: "left"}}
                      component = {Checkbox}
                    />
                    <Field
                      label     = '09'
                      name      = 'bagB2.b13Month9'
                      style     = {{float: "left"}}
                      component = {Checkbox}
                    />
                    <Field
                      label     = '10'
                      name      = 'bagB2.b13Month10'
                      style     = {{float: "left"}}
                      component = {Checkbox}
                    />
                    <Field
                      label     = '11'
                      name      = 'bagB2.b13Month11'
                      style     = {{float: "left"}}
                      component = {Checkbox}
                    />
                    <Field
                      label     = '12'
                      name      = 'bagB2.b13Month12'
                      style     = {{float: "left"}}
                      component = {Checkbox}
                    />

                    <Field
                      label      = 'Tahun'
                      name       = 'bagB2.b13Year'
                      style      = {{float: "left"}}
                      component  = {TextfieldMask}
                      maskFormat = "####"
                      mask       = "Y"
                    />
                  </div>
                </TableColumn>
                <TableColumn className='md-text-right'>
                  <Field
                    // label='Bruto'
                    name      = 'bagB2.b13'
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                    onChange  = {async (e, v)=> {
                      var bag12 = formData.bagB2.b12;
                      if(!bag12) bag12 = 0;
                      if(!v) v=0;
                      await this.props.change('bagB2.b14', parseFloat(v)+parseFloat(bag12))

                      var b14 = parseFloat(v)+parseFloat(bag12);
                      var b15 = parseFloat(formData.bagB1.b116) - b14;
                      await this.props.change('bagB2.b15', b15)

                      await this.props.change('bagB2.b17', b15 - formData.bagB2.b16)
                    }}
                    />
                  {/* {formData.bagB2.b13} */}
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>14.</TableColumn>
                <TableColumn colSpan={4}>JUMLAH (ANGKA 12 + ANGKA 13)</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB2.b14)}</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>15.</TableColumn>
                <TableColumn colSpan={4}>PPh PASAL 21 DAN/ATAU PASAL 26 YANG KURANG (LEBIH) DISETOR (ANGKA 11 KOLOM 6 - ANGKA 14)</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB2.b15)}</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn colSpan={4}>LANJUTKAN PENGISIAN PADA ANGKA 16 & 17 APABILA SPT PEMBETULAN DAN/ATAU PADA ANGKA 18 APABILA LEBIH DISETOR PPh</TableColumn>
                <TableColumn style={{background: '#DDD'}}></TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>16.</TableColumn>
                <TableColumn colSpan={4}>PPh PASAL 21 DAN/ATAU PASAL 26 YANG KURANG (LEBIH) DISETOR PADA SPT YANG DIBETULKAN (PINDAHAN DARI BAGIAN B ANGKA 15 DARI SPT YANG DIBETULKAN)</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB2.b16)}</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>17.</TableColumn>
                <TableColumn colSpan={4}>PPh PASAL 21 DAN/ATAU PASAL 26 YANG KURANG (LEBIH) DISETOR KARENA PEMBETULAN (ANGKA 15 - ANGKA 16)</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB2.b17)}</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>18.</TableColumn>
                <TableColumn colSpan={4}>KELEBIHAN SETOR PADA ANGKA 15 ATAU ANGKA 17 AKAN DIKOMPENSASIKAN KE MASA PAJAK (mm - yyyy)</TableColumn>
                <TableColumn className='md-text-right'>
                  <Field
                    label     ='Bulan'
                    disabled  = {no18Disabled}
                    name      = 'bagB2.b18Month'

                    component = {Textfield}
                    options   = {[
                      {id: 1, name: 1},
                      {id: 2, name: 2},
                      {id: 3, name: 3},
                      {id: 4, name: 4},
                      {id: 5, name: 5},
                      {id: 6, name: 6},
                      {id: 7, name: 7},
                      {id: 8, name: 8},
                      {id: 9, name: 9},
                      {id: 10, name: 10},
                      {id: 11, name: 11},
                      {id: 12, name: 12},
                    ]}
                  />

                  <Field
                    label     ='Tahun'
                    disabled  = {no18Disabled}
                    name      = 'bagB2.b18Year'

                    component = {TextfieldMask}
                  />
                </TableColumn>
              </TableRow>
            </TableBody>
          </DataTable>
        </Card>

        <br/>

        {/* <Card>
          <div className='md-grid'>
            <Field
              label      = 'NPWP Pemotong'
              name       = 'referensi'
              id         = "BP21A1Form-referensi"
              className  = "md-cell md-cell--12"
              component  = {TextfieldMask}
              maskFormat = "##.###.###.#-###-###"
              validate   = {validation.required}
            />
          </div>
        </Card> */}

        <br />

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='C.  OBJEK PAJAK FINAL' />
          <Divider/>
          <DataTable plain tableStyle={{minWidth: 1000}}>
            <TableHeader>
              <TableRow>
                <TableColumn className='md-text-center' style={{width: 5}}>NO</TableColumn>
                <TableColumn className='md-text-center'>PENERIMA PENGHASILAN</TableColumn>
                <TableColumn className='md-text-center' style={{width: 130}}>KODE</TableColumn>
                <TableColumn className='md-text-center'>JUMLAH PENERIMA</TableColumn>
                <TableColumn className='md-text-center'>JUMLAH PENGHASILAN BRUTO</TableColumn>
                <TableColumn className='md-text-center'>JUMLAH PAJAK DIPOTONG</TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableColumn>1.</TableColumn>
                <TableColumn>PENERIMA UANG PESANGON YANG DIBAYARKAN SEKALIGUS</TableColumn>
                <TableColumn className='md-text-center'>21-401-01</TableColumn>
                <TableColumn className='md-text-right'>{formData.bagC.c14}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagC.c15)}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagC.c16)}</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>2.</TableColumn>
                <TableColumn>PENERIMA UANG MANFAAT PENSIUN, TUNJANGAN HARI TUA ATAU JAMINAN HARI TUA DAN PEMBAYARAN SEJENIS YANG DIBAYARKAN SEKALIGUS</TableColumn>
                <TableColumn className='md-text-center'>21-401-02</TableColumn>
                <TableColumn className='md-text-right'>{formData.bagC.c24}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagC.c25)}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagC.c26)}</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>3.</TableColumn>
                <TableColumn>PEJABAT NEGARA, PEGAWAI NEGERI SIPIL, ANGGOTA TNI/POLRI DAN PENSIUNAN YANG MENERIMA HONORARIUM DAN IMBALAN LAIN YANG DIBEBANKAN KEPADA KEUANGAN NEGARA/DAERAH</TableColumn>
                <TableColumn className='md-text-center'>21-402-01</TableColumn>
                <TableColumn className='md-text-right'>{formData.bagC.c34}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagC.c35)}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagC.c36)}</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>4.</TableColumn>
                <TableColumn>PENERIMA PENGHASILAN YANG DIPOTONG PPh PASAL 21 FINAL LAINNYA</TableColumn>
                <TableColumn className='md-text-center'>21-499-99</TableColumn>
                <TableColumn className='md-text-right'>{formData.bagC.c44}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagC.c45)}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagC.c46)}</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>5.</TableColumn>
                <TableColumn><b>JUMLAH BAGIAN C</b> (PENJUMLAHAN ANGKA 1 S.D. 5)</TableColumn>
                <TableColumn style={{background: '#DDD'}}></TableColumn>
                <TableColumn className='md-text-right'>{formData.bagC.c54}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagC.c55)}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagC.c56)}</TableColumn>
              </TableRow>
            </TableBody>
          </DataTable>
        </Card>

        <br />

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='D.  LAMPIRAN' />
          <Divider/>
          <DataTable plain tableStyle={{minWidth: 1000}}>
            <TableBody>
              <TableRow>
                <TableColumn>
                  <Field
                    label     = ''
                    name      = 'bagD.d01'
                    component = {Checkbox}
                    disabled
                  />
                </TableColumn>
                <TableColumn>1. FORMULIR 1721 - I (untuk Satu Masa Pajak)</TableColumn>
                <TableColumn>
                  <Field
                    label     = ''
                    name      = 'bagD.d01Lembar'
                    component = {Textfield}
                    disabled
                  />
                </TableColumn>
                <TableColumn>LEMBAR</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>
                  <Field
                    label     = ''
                    name      = 'bagD.d02'
                    component = {Checkbox}
                    disabled
                  />
                </TableColumn>
                <TableColumn>2. FORMULIR - I (untuk Satu Tahun Pajak)</TableColumn>
                <TableColumn>
                  <Field
                    label     = ''
                    name      = 'bagD.d02Lembar'
                    component = {Textfield}
                    disabled
                  />
                </TableColumn>
                <TableColumn>LEMBAR</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>
                  <Field
                    label     = ''
                    name      = 'bagD.d03'
                    component = {Checkbox}
                    disabled
                  />
                </TableColumn>
                <TableColumn>3. FORMULIR 1721 - II</TableColumn>
                <TableColumn>
                  <Field
                    label     = ''
                    name      = 'bagD.d03Lembar'
                    component = {Textfield}
                    disabled
                  />
                </TableColumn>
                <TableColumn>LEMBAR</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>
                  <Field
                    label     = ''
                    name      = 'bagD.d04'
                    component = {Checkbox}
                    disabled
                  />
                </TableColumn>
                <TableColumn>4. FORMULIR 1721 - III</TableColumn>
                <TableColumn>
                  <Field
                    label     = ''
                    name      = 'bagD.d04Lembar'
                    component = {Textfield}
                    disabled
                  />
                </TableColumn>
                <TableColumn>LEMBAR</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>
                  <Field
                    label     = ''
                    name      = 'bagD.d05'
                    component = {Checkbox}
                    disabled
                  />
                </TableColumn>
                <TableColumn>5. FORMULIR 1721 - IV</TableColumn>
                <TableColumn>
                  <Field
                    label     = ''
                    name      = 'bagD.d05Lembar'
                    component = {Textfield}
                    disabled
                  />
                </TableColumn>
                <TableColumn>LEMBAR</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>
                  <Field
                    label     = ''
                    name      = 'bagD.d06'
                    component = {Checkbox}
                    disabled
                  />
                </TableColumn>
                <TableColumn>6. FORMULIR 1721 - V</TableColumn>
                <TableColumn>
                  {/*<Field
                      label     = ''
                      name      = 'bagD.d06Lembar'
                      component = {Textfield}
                    />*/}
                </TableColumn>
                <TableColumn></TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>
                  <Field
                    label     = ''
                    name      = 'bagD.d07'
                    component = {Checkbox}
                    disabled
                  />
                </TableColumn>
                <TableColumn>7. SURAT SETORAN PAJAK (SSP) DAN/ATAU BUKTI PEMINDAHBUKUAN (Pbk)</TableColumn>
                <TableColumn>
                  <Field
                    label     = ''
                    name      = 'bagD.d07Lembar'
                    component = {Textfield}
                    disabled
                  />
                </TableColumn>
                <TableColumn>LEMBAR</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>
                  <Field
                    label     = ''
                    name      = 'bagD.d08'
                    component = {Checkbox}
                    onChange  = {async (ev, v)=> {
                      await this.props.change('bagE.e0102', !v);

                      await this.changeSigner()
                    }}
                  />
                </TableColumn>
                <TableColumn>8. SURAT KUASA KHUSUS</TableColumn>
                <TableColumn>
                  {/* <Field
                    label     = ''
                    name      = 'bagD.d08'
                    component = {Textfield}
                  /> */}
                  </TableColumn>
                <TableColumn></TableColumn>
              </TableRow>
            </TableBody>
          </DataTable>
        </Card>

        <br />

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='E.  PERNYATAAN DAN TANDA TANGAN PEMOTONG' />
          <Divider/>
          <div className='md-grid'>
            <div className="md-cell md-cell--12">
              <p style={{textAlign: 'center'}}>Dengan menyadari sepenuhnya atas segala akibatnya termasuk sanksi-sanksi sesuai dengan ketentuan yang berlaku, saya menyatakan bahwa apa yang telah saya beritahukan di atas beserta lampiran-lampirannya adalah benar, lengkap dan jelas.</p>
            </div>
          </div>
          <div className='md-grid'>
            <Field
              className    = "md-cell md-cell--12"
              label        = 'Tanggal Pernyataan Pemotong'
              name         = 'bagE.e05'
              component    = {Datepicker}
              validate     = {validation.required}
              onDateChange = { async (d)=> {
                  let signer = null;

                  try {
                    signer = await this.service.api.getSigner({
                      orgId: this.props.spt.data.organization.id,
                      tgl  : moment(d).format("YYYY-MM-DD")
                    })

                    await this.setState({signer: signer})
                  } catch (error) {
                  }

                  await this.changeSigner()
                }}
              />
              <Field
                className = "md-cell md-cell--12"
                label     = 'Jenis Penandatangan'
                name      = 'bagE.e0102'
                component = {Searchfield}
                options   = {[
                  {id: true, name: 'Pemotong'},
                  {id: false, name: 'Kuasa'}
                ]}
                onChange={async (e, v)=> {
                  await this.props.change('bagE.e0102', v);

                  await this.changeSigner()
                }}
                //validate = {validation.required}
                disabled
                // disable karena saat cetak ambil dari DB signer
              />

            <div className="md-cell md-cell--6">
              <Field
                label      = 'NPWP Pernyataan Pemotong'
                name       = 'bagE.e03'
                component  = {TextfieldMask}
                maskFormat = "##.###.###.#-###-###"
                validate   = {validation.required}
                disabled
                // disable karena saat cetak ambil dari DB signer
              />
              <Field
                label     = 'Nama Pernyataan Pemotong'
                name      = 'bagE.e04'
                component = {Textfield}
                validate  = {validation.required}
                disabled
                // disable karena saat cetak ambil dari DB signer
              />
            </div>
            <div className="md-cell md-cell--6">
              <Field
                label     = 'Tempat Pernyataan Pemotong'
                name      = 'bagE.e06'
                component = {Textfield}
                validate  = {validation.required}
                // disabled
              />
            </div>
          </div>
        </Card>
        <br />
      </div>
    )
  }
}
