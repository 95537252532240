import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Field, reduxForm } from 'redux-form';
import { Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab } from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Switch as Switch2} from '../../../../../../components/form';

import ListView from '../../../../../../components/entity/listView';
import ColumnService from '../../../../../../services/column.service';
// import ImportService from './../Import/Import.service';
import axios from 'axios';
import izitoast from 'izitoast';
import FormDialog from './ImportLK22.dialog';
import ImportLK22Service from './ImportLK22.service';
import download from "downloadjs";
import ListViewImport from '../../../../../../components/entity/ListViewImport';

@reduxForm({form: 'ImportLK22Form', destroyOnUnmount: true})
@connect(ImportLK22Service.stateConnectSetting(), ImportLK22Service.actionConnectSetting())
export default class ImportLK22View extends ListViewImport {
  service=ImportLK22Service
  FormDialog=FormDialog

  category='LAWAN_TRANSAKSI_22'
  importPath='/api/import/spt/22'
  showAutomatisTab=false
  // viewType=2

  beforeFetch(params) {
    params["category.equals"] = "LAWAN_TRANSAKSI_22"
  }

  async handleDownloadTemplate() {
    var report = await ImportLK22Service.api.downloadTemplate()
    download(report.data, 'TEMPLATE_LT_22.csv');
  }

}
