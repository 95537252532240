import React, { Component } from 'react';
import { connect } from 'react-redux';

import FormDialog from './Access.dialog.view';
import AccessService from './Access.service';
import ListView from '../../../../components/entity/listView';
import ColumnService from '../../../../services/column.service';

@connect(AccessService.stateConnectSetting(), AccessService.actionConnectSetting())
export default class AccessView extends ListView {
  service=AccessService
  FormDialog=FormDialog

  columns=[
    {label: "word.id",  value: function(d){ return d.id }, type: "func", isDefaultSort:true, show:false, isSearchable:true},
    {label: "word.name",  value: function(d){ return d.name }, type: "func", isDefaultSort:true, show:true, isSearchable:true},
    {label: "word.description",  value: function(d){ return d.description }, type: "func", isDefaultSort:true, show:true, isSearchable:true},
  ]   
}
