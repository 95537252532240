import React, { Component } from 'react';
import { Grid, Cell } from 'react-md';
import { connect } from 'react-redux';
import { Field, reduxForm , propTypes, formValueSelector } from 'redux-form';
import {
  validation,
  Textfield,
  TextfieldMask,
  Searchfield,
  Switch,
  Multiselect,
  convert,
  Datepicker
} from '../../../../components/form';
import DialogView from '../../../../components/entity/dialog.view';
import OrganizationService from '../../Administrator/Organization/Organization.service';
import MasterKppService from '../../Master/MasterKpp/MasterKpp.service';
import OrganizationKppService from './OrganizationKpp.service';

@connect(state => ({
  isLoginIn: state.auth.isLoginIn,
  user: state.auth.user,
  menuType: state.auth.menuType,
  company: state.auth.currentCompany
}))
@reduxForm({form: 'organizationKpp', destroyOnUnmount: false})
export default class OrganizationKppDialogView extends DialogView {
  service=OrganizationKppService
  initialData = {
  }

  initData(props) {
    if(props.formData.id) {
      this.props.initialize(props.formData);
    } else {
      this.props.initialize(this.initialData);
    }
  }

  formView() {
    return (
      <Grid style={{padding:'none'}}>
        <Field
          label='Organization'
          name='organization'
          id="organization"
          className="md-cell md-cell--12"
          valueField='parent'
          params     = {{
            size: 50
          }}
          remoteSearch = {true}
          service={OrganizationService}
          searchField  = {['name', 'aliasName', 'npwp']}
          component={Searchfield}
          itemTemplate={function(d) {
            let nameAndAlias = ""

            if (d.name)
              nameAndAlias = d.name

            if (d.aliasName)
              nameAndAlias += " - " + d.aliasName

            return {
              primaryText  : nameAndAlias,
              secondaryText: d.npwp,
              onClick: () => {
                if(!this.props.disabled){
                  this.handleItemClick(d); this.hide()
                }
              }
            }
          }}
        />

        <Field
          label='KPP'
          name='kpp'
          id="kpp"
          className="md-cell md-cell--12"
          valueField='parent'
          params     = {{
            size: 50
          }}
          remoteSearch = {true}
          service={MasterKppService}
          searchField  = {['code', 'name']}
          component={Searchfield}
          itemTemplate={function(d) {
            return {
              primaryText  : d.code,
              secondaryText: d.name,
              onClick: () => {
                if(!this.props.disabled){
                  this.handleItemClick(d); this.hide()
                }
              }
            }
          }}
        />

        <div className="md-cell md-cell--6">
          <Field
            label='Start Date'
            name='start'
            inline={true}
            component={Datepicker}
            validate={validation.required}
          />
        </div>

        <div className="md-cell md-cell--6">
          <Field
            label='End Date'
            name='end'
            inline={true}
            component={Datepicker}
            validate={validation.required}
          />
        </div>

      </Grid>
    )
  }
}
