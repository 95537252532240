import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Button, SVGIcon } from 'react-md';
import ListView from '../../../../../../components/entity/listView';
import {Switch} from 'react-md';
import iziToast from 'izitoast';
import ALLSPT23IndukService from './ALLSPT23Induk.service';
import SPT23IndukService from '../../Induk/Spt23Induk.service';
import download from "downloadjs";

@connect(ALLSPT23IndukService.stateConnectSetting(), ALLSPT23IndukService.actionConnectSetting())
export default class ALLSPT23IndukView extends ListView {
  service=ALLSPT23IndukService

  constructor(props){
    super(props);
    this.state = {
      showTableSetting:false,
      showDialogConfirmDeleteSelected:false,
      showForm:false,
      formData: {},
      data: []
    }
  }

  editDialog = false;
  _barActions = [];

  editItem = (item) => {
    if(this.editDialog) {
      this.showFormDialog(item);
    } else {
      if(this.rootPath) {
        this.props.history.push(this.rootPath+"/"+item.id)
      } else {
        this.props.history.push('/'+this.service.name+"/"+item.spt.id);
      }
    }
  }

  async handleSendEmail(){
    try {
      let category = "BP_23"
      var selected = this.props.table.selected
      var ids = [];
      selected.map(function (i){
          ids.push(i.id)
      })
      await this.service.api.email(category, ids)
      iziToast.info({
        title: "Kirim Email",
        message: "email sedang diproses silahkan cek log untuk lebih detil"
      })
    } catch (e){
      iziToast.warning({
        title: e.name,
        message: e.message
      })
    }
  }

  async handleCetak() {
    this.setState({cetak: true})
    if (this.props.table.selected.length === 0) {
      iziToast.warning({
        title: 'Pemberitahuan !',
        message: 'Pilih salah satu data yang akan dicetak',
      });
    }

    else if (this.props.table.selected.length > 1) {
      try {
        var selected = this.props.table.selected
        var ids = [];
        selected.map(function (i) {
          ids.push(i.spt.id)
        })

        var report = await SPT23IndukService.api.cetakBulk(ids)
        var filename = report.headers.filename
        download(report.data, filename);
        this.setState({cetak: false})
      } catch (e) {
        const reader = new FileReader();

        /// This fires after the blob has been read/loaded.
        reader.addEventListener('loadend', (e) => {
          const text = e.srcElement.result
          let json = JSON.parse(text)

          if (json.detail)
            iziToast.error({
              title: 'Error',
              message: json.detail
            })
        });

        reader.readAsText(e.response.data)

        this.setState({cetak: false})
      }
    }

    else {
      try {
        var selection = this.props.table.selected[0]
        var sptId = selection.spt.id
        var report = await SPT23IndukService.api.cetak(sptId)
        var filename = report.headers.filename
        download(report.data, filename);
        this.setState({cetak: false})
      } catch (e) {
        const reader = new FileReader();

        /// This fires after the blob has been read/loaded.
        reader.addEventListener('loadend', (e) => {
          const text = e.srcElement.result
          let json = JSON.parse(text)

          if (json.detail)
            iziToast.error({
              title: 'Error',
              message: json.detail
            })
        });

        reader.readAsText(e.response.data)

        this.setState({cetak: false})
      }
    }
  }

  _barItem() {
    return (
      <div>
        <Button primary raised style={{margin: 5}} onClick={this.handleCetak.bind(this)}>Cetak</Button>
        <Button secondary raised style={{margin: 5}} onClick={this.handleSendEmail.bind(this)}>Kirim</Button>
        <Switch
          style={{float: "right"}}
          id="switch-table-setting"
          name="Switch Table Setting"
          label=""
          checked={this.state.showTableSetting}
          onChange={() => {
            this.setState({
              showTableSetting:!this.state.showTableSetting
            })
          }}
        />
      </div>
    )
  }

  columns=[
    /*{isSortable: true, label: "word.reference",  value: "referensi", show:false, isSearchable:true},*/
    {isSortable: true, label: "word.year",  searchField: "year", value: (d)=> d.spt.year, type: "func", show:true, isSearchable:true},
    {isSortable: true, label: "word.month", searchField:'masa',  value: "spt.month", type: "number", show:true, isSearchable:true},
    {isSortable: true, label: "word.pembetulan",  value: "spt.pembetulan", type: "number", show:true, isSearchable:true},
    {isSortable: true, label: "word.npwp",  value: "bagA.npwp", show:true, isSearchable:true},
    {isSortable: true, label: "word.nik",  value: "bagA.nik", show:false, isSearchable:true},
    {isSortable: true, label: "word.name",  value: "bagA.nama", show:true, isSearchable:true},

  ]

  _tableActions = [
    {
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    }
  ]

}
