import ApiService from '../../../../services/api.service';

class MasterBatasUpahHarianService extends ApiService {
  name= 'masterBatasUpahHarian';
  path= 'master/batas-upah-harian';
  constructor() {
    super();
    this.init()
  }
}


export default new MasterBatasUpahHarianService();
