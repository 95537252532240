import ApiService from '../../../services/api.service';

class SummaryAdminService extends ApiService {
  name= 'summary';
  path= 'summary-admin';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api={
    generate: async (data) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/generate`, data, {
        responseType: 'blob'
      })
      return res;
    },
  }
}


export default new SummaryAdminService();
