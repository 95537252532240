import React, { Component } from 'react';
import { connect } from 'react-redux';
import {OrganizationView} from '../../Administrator/Organization/Organization.view';
import OrganizationService from '../../Administrator/Organization/Organization.service';

@connect(OrganizationService.stateConnectSetting(), OrganizationService.actionConnectSetting())
export default class OrganizationCompanyView extends OrganizationView {

  fetchOption() {
    return {
      path: OrganizationService.path+'/company/'+this.props.match.params.companyId
    }
  }

}
