import React from 'react';
import {connect} from 'react-redux';
import Bluebird from 'bluebird';
import { Field, reduxForm, getFormValues} from 'redux-form';
import moment from 'moment';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  CardActions,
  Button,
  LinearProgress,
  Tab,
  Tabs
} from 'react-md';
import FormView from '../../../../../../components/entity/form.view';
import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Checkbox} from '../../../../../../components/form';
import iziToast from 'izitoast';
import Tarif4a2Service from './Tarif4a2.service';


@reduxForm({form: 'Tarif4a2Form', destroyOnUnmount: true})
@connect(state => ({
  company: state.auth.currentCompany,
  formData:getFormValues('Tarif4a2Form')(state)
}))
export default class Tarif4a2Form extends FormView {
  service=Tarif4a2Service
  viewType=2

  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      activeTab: 0
    }
  }

  async initData() {
    if(this.props.match.params.id == 'new') {

      var master = await Tarif4a2Service.api.getMaster();
      if(master) {
        delete master.id;
      }

      var initialData = {
        company: this.props.company,
        ...master
      }

      this.props.initialize(initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id);
      this.props.initialize(res.data);
    }
  }

  formView() {
    return (
      <div className="mpk-layout column fill mpk-tab-clear">
        <div className="flex">
        {this.pasal4a2Form()}
        </div>
      </div>
    )
  }

  pasal4a2Form() {
    return <div style={{marginBottom: 20}}>
      <div className='md-card md-paper md-paper md-paper--1 md-card md-background--card'>

        <div className="md-grid">
          <Field
            className="md-cell md-cell--6"
            label='Start Date'
            name='start'
            inline={true}
            component={Datepicker}
            />
          <Field
            className='md-cell md-cell--6'
            label='End Date'
            name='end'
            inline={true}
            component={Datepicker} />
        </div>
        <DataTable plain fixedScrollWrapperStyle={{overflow: 'inherit'}} style={{overflow: 'inherit'}}>
          <TableHeader>
            <TableRow>
              <TableColumn>No.</TableColumn>
              <TableColumn>Jenis Penghasilan</TableColumn>
              <TableColumn>Tarif(%)</TableColumn>
            </TableRow>
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableColumn>
                1.
              </TableColumn>
              <TableColumn>Hadiah Undian</TableColumn>
              <TableColumn>
                <Field
                  // label='Tarif'
                  name='hadiahUndian'
                  component={TextfieldMask}
                  className='md-text-right'
                />
              </TableColumn>
            </TableRow>
            <TableRow>
              <TableColumn>
                2.
              </TableColumn>
              <TableColumn>Bunga Deposito</TableColumn>
              <TableColumn>
                <Field
                  // label='Tarif'
                  name='bungaDeposito'
                  component={TextfieldMask}
                  className='md-text-right'
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>
                3.
              </TableColumn>
              <TableColumn>Penjualan Saham Pendiri</TableColumn>
              <TableColumn>
                <Field
                  // label='Tarif'
                  name='penjualanSahamPendiri'
                  component={TextfieldMask}
                  className='md-text-right'
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>
                4.
              </TableColumn>
              <TableColumn>Penjualan Saham Bukan Sendiri</TableColumn>
              <TableColumn>
                <Field
                  // label='Tarif'
                  name='penjualanSahamBukanPendiri'
                  component={TextfieldMask}
                  className='md-text-right'
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>
                5.
              </TableColumn>
              <TableColumn>Sewa Tanah dan Bangunan</TableColumn>
              <TableColumn>
                <Field
                  // label='Tarif'
                  name='sewaTanahDanBangunan'
                  component={TextfieldMask}
                  className='md-text-right'
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>
                6.
              </TableColumn>
              <TableColumn>Penyedia Jasa Konstruksi Kualifikasi</TableColumn>
              <TableColumn>
                <Field
                  // label='Tarif'
                  name='penyediaJasaKonstruksiKualifikasi'
                  component={TextfieldMask}
                  className='md-text-right'
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>
                7.
              </TableColumn>
              <TableColumn>Penyedia Jasa Konstruksi Tidak Punya Kualifikasi</TableColumn>
              <TableColumn>
                <Field
                  // label='Tarif'
                  name='penyediaJasaKonstruksiTidakPunyaKualifikasi'
                  component={TextfieldMask}
                  className='md-text-right'
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>
                8.
              </TableColumn>
              <TableColumn>Penyedia Jasa Konstruksi Lain</TableColumn>
              <TableColumn>
                <Field
                  // label='Tarif'
                  name='penyediaJasaKonstruksiLain'
                  component={TextfieldMask}
                  className='md-text-right'
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>
                9.
              </TableColumn>
              <TableColumn>Jasa Perancangan Punya Kualifikasi</TableColumn>
              <TableColumn>
                <Field
                  // label='Tarif'
                  name='jasaPerancanganPunyaKualifikasi'
                  component={TextfieldMask}
                  className='md-text-right'
                />
              </TableColumn>
            </TableRow>


            <TableRow>
              <TableColumn>
                10.
              </TableColumn>
              <TableColumn>Jasa Perancangan Tidak Punya Kualifikasi</TableColumn>
              <TableColumn>
                <Field
                  // label='Tarif'
                  name='jasaPerancanganTidakPunyaKualifikasi'
                  component={TextfieldMask}
                  className='md-text-right'
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>
                11.
              </TableColumn>
              <TableColumn>Bunga dan Diskonto Obligasi</TableColumn>
              <TableColumn>
                <Field
                  // label='Tarif'
                  name='bungaDanDiskontoObligasi'
                  component={TextfieldMask}
                  className='md-text-right'
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>
                12.
              </TableColumn>
              <TableColumn>Bunga Simpanan</TableColumn>
              <TableColumn>
                <Field
                  // label='Tarif'
                  name='bungaSimpanan'
                  component={TextfieldMask}
                  className='md-text-right'
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>
                13.
              </TableColumn>
              <TableColumn>Penghasilan Transaksi Deviatif</TableColumn>
              <TableColumn>
                <Field
                  // label='Tarif'
                  name='penghasilanTransaksiDerivatif'
                  component={TextfieldMask}
                  className='md-text-right'
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>
                14.
              </TableColumn>
              <TableColumn>Dividen Diterima</TableColumn>
              <TableColumn>
                <Field
                  // label='Tarif'
                  name='dividenDiterima'
                  component={TextfieldMask}
                  className='md-text-right'
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>
                15.
              </TableColumn>
              <TableColumn>WP Peredaran Tertentu</TableColumn>
              <TableColumn>
                <Field
                  // label='Tarif'
                  name='peredaranTertentu'
                  component={TextfieldMask}
                  className='md-text-right'
                />
              </TableColumn>
            </TableRow>

          </TableBody>
        </DataTable>
      </div>
      <br/>
      {/* <div className='md-card md-paper md-paper md-paper--1 md-card md-background--card'>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle='Tarif Jasa Lain ex Peraturan Menteri Keuangan No.244/PMK/03/2008
                    ' />
        <Divider/>

        <DataTable plain fixedScrollWrapperStyle={{overflow: 'inherit'}} style={{overflow: 'inherit'}}>
          <TableHeader>
            <TableRow>
              <TableColumn>Kode Jasa.</TableColumn>
              <TableColumn>Nama Jasa</TableColumn>
              <TableColumn>Tarif(%)</TableColumn>
            </TableRow>
          </TableHeader>
          <TableBody>
            {this.props.formData.pasal23Lain.map((d, i)=> {
              return <TableRow>
                <TableColumn>
                  {i+1}.
                </TableColumn>
                <TableColumn>{d.nama}</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name={'lain.'+d.id+'.tarif'}
                    component={TextfieldMask}
                    className='md-text-right'
                  />
                </TableColumn>
              </TableRow>
            })}

          </TableBody>
        </DataTable>
      </div> */}
    </div>
  }
}
