import ApiService from '../../../../services/api.service';

class OrganizationService extends ApiService {
  name= 'access';
  path= 'access';

  constructor() {
    super();
    this.init()
  }
}


export default new OrganizationService();
