import ApiService from '../../../../../services/api.service';
import http from "../../../../../services/http.service";

class PBK4a2Service extends ApiService {
  name= 'PBK4a2';
  path= 'spt4a2/pbk-4a2';

  // apiMockup= {
  //   find: [{
  //     id: 1,
  //     status: 'Process',
  //     npwp: '023945840000',
  //     name: 'PT. A',
  //     pasal: '23/26',
  //     year: '2018',
  //     organization: {name: 'PT. A', npwp: '123123'},
  //     month: 'Januari',
  //     pembetulan: '0'
  //   }],
  //   findOne: {data: {
  //     id: 1,
  //     status: 'Process',
  //     npwp: '023945840000',
  //     name: 'PT. A',
  //     pasal: '23/26',
  //     year: '2018',
  //     organization: {name: 'PT. A', npwp: '123123'},
  //     month: 'Januari',
  //     pembetulan: '0'
  //   }}
  // }

  api = {
    total: async (sptId)=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/total`)

      return res;
    },
    downloadCsv: async (sptId)=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/csv/${sptId}`, {
        responseType: 'blob'
      })

      return res;
    },
    deleteBulk: async (sptId, ids)=> {
      let res = await http.post(`${this.apiLocation}/${this.path}/${sptId}/delete`, ids);

      return res;
    },
  }

  constructor() {
    super()
    this.init()
    this.initApi()
  }
}


export default new PBK4a2Service();
