/**
 * Created by dwiargo on 11/29/17.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Router from 'react-router-dom/Router';
import _ from 'lodash';
import {
  Avatar,
  Divider,
  FontIcon,
  List,
  ListItem,
  Subheader,
  Button,
  Card,
  CardTitle,
  DialogContainer,
  CircularProgress
} from 'react-md';
import {Field, reduxForm, getFormValues} from 'redux-form';
import {Appbar, Notification, Sidebar} from 'react-mpk';
import * as globalActions from 'react-mpk/redux/actions/globalActions';
import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Radio} from '../../components/form';
import routes from './routes';
import AuthService from '../../services/auth.service';
import OrganizationSwitcher from '../../components/OrganizationSwitcher/OrganizationSwitcher';
import defaultMenu from './defaultMenu';
import modularMenu from './modularMenu';
import * as constant from '../../config/constant';
import UserBar from '../../components/UserBar/UserBar';
import ModuleMenu from '../../components/ModuleMenu/ModuleMenu';
import authService from '../../services/auth.service';
import websocketService from '../../services/websocket.service';
import env from 'env';

@connect(state => ({
  global: state.global,
  isLoginIn: state.auth.isLoginIn,
  user: state.auth.user,
  showGlobalProgress: state.auth.showGlobalProgress,
  organization: state.auth.currentOrganization,
  access: state.auth.access,
  spt: state.auth.currentSPT,
  currentModule: state.auth.currentModule,
  company: state.auth.currentCompany,
  setting: state.auth.setting,
  menuType: state.auth.menuType
}), dispatch => ({
  dispatch: dispatch,
  globalActions: bindActionCreators(globalActions, dispatch)
}))
export default class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      version: '',
      menus: []
    }
  }

  static contextTypes = {
    showDialog: React.PropTypes.func,
  };

  async logout() {
    await AuthService.api.logout(this.props.dispatch);
    this.props.history.push('/account/login')
  }

  async componentWillMount() {
    try {
      // init user
      var res = await AuthService.api.getCurrentUser(this.props.dispatch);
      AuthService.api.changeMenuType('main', this.props.dispatch)
      var userAuth = AuthService.api.getAuth(this.props.user);
      if (userAuth === constant.ROLE_OWNER) {
        if (this.props.user.company) {
          AuthService.api.changeCompany(this.props.user.company, this.props.dispatch);
        }
      } else {
        AuthService.api.changeCompany({}, this.props.dispatch)
      }

      // get access
      AuthService.api.getOrgAccess(this.props.dispatch)

      // connect to websocket
      /*websocketService.connect();*/

      /*websocketService.connected.promise.then(()=> {
        websocketService.subscribe('/user/notification', (d)=> {
          try {

          } catch (error) {

          }

        }, true)
      })*/

      var version = await AuthService.http.get('/management/info');
      if (version.data && version.data.build) {
        this.setState({version: version.data.build.version})
      }
    } catch (e) {
      await this.logout(this.props.dispatch)
    }
  }

  componentWillReceiveProps(nextProps, nextState) {
    // change menu from spt -> main
    if (nextProps.location.pathname != this.props.location.pathname && nextProps.location.pathname.search('/open') == -1) {
      AuthService.api.changeSPT({}, this.props.dispatch)
    }
    if(JSON.stringify(this.props) != JSON.stringify(nextProps)){
      // console.log("props change")
      this.buildMenus()
    }
    if(JSON.stringify(this.state) != JSON.stringify(nextState)){
      // console.log("state change")
      this.buildMenus()
    }
    if(this.props.menuType != nextProps.menuType){
      // console.log("menu type change")
      this.buildMenus()
    }
  }

  async buildMainMenus() {
    if(env.enableAksesProfile){
      var defMenus = []
      if(this.props.user && this.props.user.defaultMenu && this.props.user.defaultMenu.mainMenus){
        defMenus = this.props.user.defaultMenu.mainMenus;
      }
      return this.checkAndCreateMenus(defMenus);
    } else {
      let defMenus = modularMenu[this.props.currentModule];
      if (!defMenus) defMenus = defaultMenu['none']
      if (this.props.setting.menuType === 'Default') {
        defMenus = defaultMenu.mainMenus;
      }
      return this.checkAndCreateMenus(defMenus);
    }
  }

  async buildSPTMenus() {
    if(env.enableAksesProfile){
      var defMenus = [];
      if (this.props.menuType === 'SPT_21') {
        if(this.props.user && this.props.user.defaultMenu && this.props.user.defaultMenu.sptPph21Menus){
          defMenus = this.props.user.defaultMenu.sptPph21Menus;
        }
        return this.checkAndCreateMenus(defMenus, '/SPT_21');
      } else if (this.props.menuType === 'SPT_23') {
        if(this.props.user && this.props.user.defaultMenu && this.props.user.defaultMenu.sptPph23Menus){
          defMenus = this.props.user.defaultMenu.sptPph23Menus;
        }
        return this.checkAndCreateMenus(defMenus, '/SPT_23');
      } else if(this.props.menuType === 'ebupot') {
        if(this.props.user && this.props.user.defaultMenu && this.props.user.defaultMenu.sptEbupot23Menus){
          defMenus = this.props.user.defaultMenu.sptEbupot23Menus;
        }
        return this.checkAndCreateMenus(defMenus, '/ebupot-23');
      } else if(this.props.menuType === 'SPT_4a2') {
        if(this.props.user && this.props.user.defaultMenu && this.props.user.defaultMenu.sptPph4a2Menus){
          defMenus = this.props.user.defaultMenu.sptPph4a2Menus;
        }
        return this.checkAndCreateMenus(defMenus, '/SPT_4a2');
      } else if(this.props.menuType === 'SPT_15') {
        if(this.props.user && this.props.user.defaultMenu && this.props.user.defaultMenu.sptPph15Menus){
          defMenus = this.props.user.defaultMenu.sptPph15Menus;
        }
        return this.checkAndCreateMenus(defMenus, '/SPT_15');
      } else if (this.props.menuType === 'SPT_22') {
        if(this.props.user && this.props.user.defaultMenu && this.props.user.defaultMenu.sptPph22Menus){
          defMenus = this.props.user.defaultMenu.sptPph22Menus;
        }
        return this.checkAndCreateMenus(defMenus, '/SPT_22');
      } else if (this.props.menuType === 'SPT_25') {
        if(this.props.user && this.props.user.defaultMenu && this.props.user.defaultMenu.sptPph25Menus){
          defMenus = this.props.user.defaultMenu.sptPph25Menus;
        }
        return this.checkAndCreateMenus(defMenus, '/SPT_25');
      }
    } else {
      if (this.props.menuType === 'SPT_21') {
        return this.checkAndCreateMenus(defaultMenu.sptPph21Menus, '/SPT_21');
      } else if (this.props.menuType === 'SPT_23') {
        return this.checkAndCreateMenus(defaultMenu.sptPph23Menus, '/SPT_23');
      } else if(this.props.menuType === 'ebupot') {
        return this.checkAndCreateMenus(defaultMenu.sptEbupot23Menus, '/ebupot-23');
      } else if(this.props.menuType === 'SPT_4a2') {
        return this.checkAndCreateMenus(defaultMenu.sptPph4a2Menus, '/SPT_4a2');
      } else if(this.props.menuType === 'SPT_15') {
        return this.checkAndCreateMenus(defaultMenu.sptPph15Menus, '/SPT_15');
      } else if (this.props.menuType === 'SPT_22') {
        return this.checkAndCreateMenus(defaultMenu.sptPph22Menus, '/SPT_22');
      } else if (this.props.menuType === 'SPT_25') {
        return this.checkAndCreateMenus(defaultMenu.sptPph25Menus, '/SPT_25');
      }
    }
  }

  async buildCompanyMenus() {
    if(env.enableAksesProfile){
      var defMenus = []
      if(this.props.user && this.props.user.defaultMenu && this.props.user.defaultMenu.companyMenu){
        defMenus = this.props.user.defaultMenu.companyMenu;
      }
      return this.checkAndCreateMenus(defMenus, '/company');
    } else {
      return this.checkAndCreateMenus(defaultMenu.companyMenu, '/company');
    }
  }

  async buildMenus() {
    // switch between spt menu and main menu
    if (this.props.menuType.search('SPT') >= 0) {
      // console.log(this.buildSPTMenus(), "A")
      // return this.buildSPTMenus()
      this.setState({
        menus: await this.buildSPTMenus()
      })
    } else if(this.props.menuType.search('ebupot') >= 0) {
      // console.log(this.buildSPTMenus(), "B")
      // return this.buildSPTMenus()
      this.setState({
        menus: await this.buildSPTMenus()
      })
    } else if (this.props.menuType === 'company') {
      // return this.buildCompanyMenus()
      this.setState({
        menus: await this.buildCompanyMenus()
      })
    } else {
      // return this.buildMainMenus()
      this.setState({
        menus: await this.buildMainMenus()
      })
    }
  }

  checkAndCreateMenus(defMenu = [], sptPath) {
    var userAuth = constant.ROLE_USER;
    if (this.props.user.authorities) {
      this.props.user.authorities.forEach((d, i) => {
        if (d.name === constant.ROLE_ADMIN) {
          userAuth = constant.ROLE_ADMIN;
        }

        if (userAuth !== constant.ROLE_ADMIN) {
          if (d.name === constant.ROLE_OWNER) {
            userAuth = constant.ROLE_OWNER;
          }
        }
      })
    }

    var isDecember = null
    if (this.props.spt.data) {
      if (this.props.spt.data.month == 12) {
        isDecember = true
      }
    }

    var mainMenus = [];
    defMenu.forEach((d, i) => {
      var hm = {
        label: d.label,
        children: []
      };

      d.children.forEach((c, j) => {
        var shouldPush = false;
        if(sptPath) {
          c.onClick = ()=> {
            if(this.props.menuType.search('SPT') >= 0) {
              this.props.history.push(sptPath+'/'+this.props.spt.data.id+'/open'+c.path)
            } else if(this.props.menuType.search('ebupot') >= 0) {
              this.props.history.push(sptPath+'/'+this.props.spt.data.id+'/open'+c.path)
            } else if(this.props.menuType === 'company') {
              this.props.history.push(sptPath+'/'+this.props.company.id+'/open'+c.path)
            }
          }
        }

        if (c.rootPath) {
          c.onClick = () => {
            if (userAuth === constant.ROLE_OWNER) {
              this.props.history.push(c.rootPath + '/' + this.props.company.id + '/open' + c.path)
            }
          }
        }

        if (userAuth === constant.ROLE_ADMIN) {
          if (!c.owner && !c.user) {
            if (c.decemberOnly) {
              if (isDecember) {
                shouldPush = true;
              }
            } else {
              shouldPush = true;
            }
          }
        } else if (userAuth === constant.ROLE_OWNER) {
          if (!c.admin && !c.user) {
            if (c.decemberOnly) {
              if (isDecember) {
                shouldPush = true;
              }
            } else {
              shouldPush = true;
            }
          }
        } else {
          if (!c.admin && !c.owner) {
            if (c.permission) {
              // check  permission
              // if(this.props.access[c.permission]) {
              //   hm.children.push(c);
              // }
              if (c.decemberOnly) {
                if (isDecember) {
                  shouldPush = true;
                }
              } else {
                shouldPush = true;
              }
            } else {
              if (c.decemberOnly) {
                if (isDecember) {
                  shouldPush = true;
                }
              } else {
                shouldPush = true;
              }
            }
          }
        }

        if (sptPath === '/SPT_22') {
          var organization = this.props.organization;
          if (c.pemungut && !organization.isPemungut) {
            shouldPush = false;
          }
        }

        if (c.show !== undefined) {
          if (!c.admin && !c.owner) {
            shouldPush = c.show
          }
        }

        if (shouldPush) {
          hm.children.push(c)
        }
      })
      if (hm.children.length > 0) mainMenus.push(hm);
    })

    return mainMenus;
  }

  editProfile = () => {
    this.props.history.push("/EDIT_PROFILE")
  }

  editPassword = () => {
    this.props.history.push("/EDIT_PASSWORD")
  }

  render() {
    return (
      <div className="mpk-layout column fill">
        {this.props.showGlobalProgress && <div className='animated fadeIn' style={{
          height: '100%', width: '100%',
          background: 'rgba(0,0,0,.7)', position: 'absolute',
          zIndex: 1000, display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <CircularProgress id='global_progress'/>
        </div>
        }

        <Appbar
          hideCompanyInfo={true}
          appName="payjak"
          version={this.state.version}
          fixedVersion={true}
          showVersion
          onLogout={() => this.logout()}
          notification={<Notification newFeeds={0}/>}
          profileMenu={[
            {
              label: "Profile",
              materialIcon: 'perm_identity',
              onClick: () => this.editProfile()
            },
            {
              label: "Password",
              materialIcon: 'lock',
              onClick: () => this.editPassword()
            },
            {
              label: "Setting",
              materialIcon: 'settings',
              onClick: async () => {
                var d = await this.context.showDialog((props, res, rej) => ({
                  title: 'App Settings',
                  initialValue: _.cloneDeep(this.props.setting),
                  okText: 'Save',
                  text: (
                    <div className='md-grid'>
                      <Field
                        label='Screen size'
                        name='size'
                        className="md-cell md-cell--12"
                        component={Textfield}
                      />
                      <Field
                        label='Menu Type'
                        name='menuType'
                        className="md-cell md-cell--12"
                        options={[{
                          id: 'Default', name: 'Default'
                        }, {
                          id: 'Modular', name: 'Modular'
                        }]}
                        component={Searchfield}
                      />
                      <Field
                        label='Menu Collapsible'
                        name='collapsible'
                        className="md-cell md-cell--12"
                        component={Switch}
                      />
                    </div>

                  )
                }))

                if (d) {
                  localStorage.setItem('settings', JSON.stringify(d));
                  location.reload()
                }
              }
            }
          ]}
          translate={true}
        />
        <div className="mpk-layout fill mpk-relative-position mpk-content">
          <Sidebar
            accordionMode={true}
            menuClass='animated fadeInLeft'
            collapsible={this.props.setting.collapsible}
            headerElements={this.topSideBar()}
            menus={this.state.menus}
            hideFooter={true}
            getValueByLocaleCode={true}
          />
          {this.props.user.id &&
          routes()
          }
        </div>
      </div>
    )
  }

  topSideBar() {
    if (this.props.menuType.search('SPT') >= 0 && this.props.spt.data) {
      return (
        <div>
          <OrganizationSwitcher/>
          <List style={{background: '#F44336'}}>
            <ListItem

              primaryTextStyle={{paddingLeft: 58, color: 'rgba(255, 255, 255, 0.87)'}}
              secondaryTextStyle={{paddingLeft: 58, color: 'rgba(255, 255, 255, 0.47)'}}
              tileStyle={{height: 'auto', margin: '10px 0px'}}
              rightIcon={<FontIcon tooltipLabel="Tutup" className='close-menu' onClick={() => {
                authService.api.changeMenuType('main', this.props.dispatch);
                this.props.history.push('/' + this.props.menuType)
              }}>close</FontIcon>}
              primaryText={this.props.spt.data.year + ' - ' + this.props.spt.data.month}
              secondaryText={'Pembetulan ' + this.props.spt.data.pembetulan}
            />
          </List>
        </div>
      )
    } else if(this.props.menuType.search('ebupot') >= 0 && this.props.spt.data) {
      return (
        <div>
          <OrganizationSwitcher/>
          <List style={{background: '#F44336'}}>
            <ListItem

              primaryTextStyle={{paddingLeft: 58, color: 'rgba(255, 255, 255, 0.87)'}}
              secondaryTextStyle={{paddingLeft: 58, color: 'rgba(255, 255, 255, 0.47)'}}
              tileStyle={{height: 'auto', margin: '10px 0px'}}
              rightIcon={<FontIcon tooltipLabel="Tutup"   className='close-menu' onClick={() => {
                authService.api.changeMenuType('main', this.props.dispatch);
                let menuType = this.props.menuType

                if (menuType === 'ebupot') {
                  menuType = 'ebupot-23'
                }

                this.props.history.push('/'+menuType)
              }}>close</FontIcon>}
              primaryText={this.props.spt.data.tahun+' - '+this.props.spt.data.masa}
              secondaryText={'Pembetulan '+this.props.spt.data.pembetulan}
              />
          </List>
        </div>
      )
    } else if (this.props.menuType === 'company' && this.props.company) {
      var companyName = "A";
      if (this.props.company && this.props.company.nama) companyName = this.props.company.nama;
      return <div>
        <List className="md-list md-dark-theme" style={{background: 'none'}}>
          <ListItem
            leftAvatar={<Avatar suffix="green">{companyName.charAt(0)}</Avatar>}
            tileStyle={{height: 'auto', margin: '10px 0px'}}
            rightIcon={<FontIcon tooltipLabel="Tutup" className='close-menu' onClick={() => {
              authService.api.changeMenuType('main', this.props.dispatch);
              this.props.history.push('/company')
            }}>close</FontIcon>}
            primaryText={this.props.company.nama}
            secondaryText={this.props.company.email}
          />
        </List>
      </div>
    } else {
      return (
        <div>
          <UserBar/>
          {this.props.setting.menuType !== 'Default' &&
          <ModuleMenu/>
          }

        </div>

      )
    }
  }
}
