import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm, getFormValues} from 'redux-form';
import moment from 'moment';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  CardActions,
  Button,
  LinearProgress
} from 'react-md';

import FormView from '../../../../../../components/entity/form.view';
// import NomorBPService from '../nomorBP.service';
import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Checkbox} from '../../../../../../components/form';
import UtilService from '../../../../../../services/utils.service'
// import SPT21SignerService from '../SPT21Signer/SPT21Signer.service';
import NpwpService from '../../../NpwpService';
import FormViewSpt from '../../../../../../components/entity/form.view.spt';
import terbilangService, {terbilangAsync} from '../../../../../../services/terbilang.service';
import BP15PihakLainDipotongService from './BP15PihakLainDipotong.service';
import LawanTransaksiDialog from '../../../../../../components/entity/LawanTransaksi.dialog';
import MasterLawanTransaksi15Service from '../../../../Master/MasterLawanTransaksi15/MasterLawanTransaksi15.service';
import nomorBP15Service from "../../nomorBP15.service";
import Tarif15Service from "../../master/tarif/Tarif15.service"

@reduxForm({form: 'BP15PihakLainDipotongForm', destroyOnUnmount: true})
@connect((state) => ({
  access: state.entity.access.api.find,
  organization: state.auth.currentOrganization,
  spt: state.auth.currentSPT,
  formData:getFormValues('BP15PihakLainDipotongForm')(state)
}))
export default class BP15PihakLainDipotongForm extends FormViewSpt {
  service=BP15PihakLainDipotongService
  viewType =2;

  initialData={
    numbering: false
  }

  typingObj=null;
  typingTimeout=1000;

  constructor(props) {
    super(props);

    this.state= {
      ...this.state,
      showCalculateProgress: false,
      npwpNotValid: true,
      lkDialog: false,
      masterTarif: {},

      prevData: {}
    }
  }

  async npwpValidation(evt, value){
    if(value){
      var res = await NpwpService.api.validate(value)
      if(res.valid){
        this.setState({npwpNotValid: false})
      } else {
        this.setState({npwpNotValid: true})
        this.toast.warning({title: "Validasi Npwp", message: "npwp tidak valid"})
      }

      /*var nonNpwp = 0;
      if(value === '000000000000000' || value == '' || !value) {
        nonNpwp = 1;
      }

      var form = this.props.formData;
      var d = form.bagB;

      Object.keys(d).forEach((k, i) => {
        if(!(k == 'bruto' || k == 'pph' || k == 'terbilang')) {
          var x = d[k];

          x.nonNpwp = nonNpwp
        }
      })

      await this.props.change('bagB', d)*/
    }
  }


  async initData() {
    let organization = this.props.organization

    if (!organization) {
      organization = this.props.spt.data.organization
    } else {
      if (!organization.npwp) {
        organization = this.props.spt.data.organization
      }
    }

    if(this.props.match.params.id == 'new') {
      var nomorBP    = "";

      try {
        let nomorBPReq = await nomorBP15Service.api.get(organization.id)

        if(nomorBPReq.data.mode === 'PREF_SUF') {
          nomorBP = nomorBPReq.data.prefix+nomorBPReq.data.suffix;
        } else {
          nomorBP = nomorBPReq.data.suffix+nomorBPReq.data.prefix;
        }
      } catch (error) {

      }

      let signer = {}; //await SPT21SignerService.api.getSigner(this.props.spt.data.id);

      this.initialData = {
        ...this.initialData,
        no             : nomorBP,
        namaPemotong   : organization.name,
        npwpPemotong   : organization.npwp,
        alamatPemotong : organization.address,
        tgl: new Date(),
        spt: this.props.spt.data,
      }
      this.props.initialize(this.initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id);

      res.data.namaPemotong = organization.name
      res.data.npwpPemotong = organization.npwp
      res.data.alamatPemotong = organization.address
      res.data.spt = this.props.spt.data

      this.setState({prevData:res.data});
      this.props.initialize(res.data);
      // this.npwpValidation(null, res.data.npwpOrganization)
    }

    try {
      let param = {
        tgl: moment(this.props.formData.tgl).format("YYYY-MM-DD")
      }

      let masterTarif = await Tarif15Service.api.byCompany(organization.company.id, param)

      this.setState({masterTarif: masterTarif})
    } catch (error) {

    }
  }

  async calculate(e, v, pv, key) {
    if(this.typingObj) clearTimeout(this.typingObj);

    this.typingObj = setTimeout(async ()=> {
      if(v) {
        let formData = this.props.formData;

        if(key) formData[key] = v;

        if(!formData.bruto) formData.bruto = 0;
        if(!formData.tarif) formData.tarif = 0;
        let bruto = parseFloat(formData.bruto);
        let tarif = parseFloat(formData.tarif);


        let pph =  Math.floor(bruto * tarif / 100);
        this.props.change('pph', pph);

        var terbilangRes = '';
        try {
          terbilangRes = await terbilangAsync(pph);
        } catch (error) {}

        this.props.change('terbilang', terbilangRes);
      }
    }, this.typingTimeout);
  }

  async getTarif(evt, value) {
    let masterTarif = this.state.masterTarif
    let tarif = 0

    switch (value) {
      case 'DN1':
      case 'DN21':
      case 'DN22':
        tarif = masterTarif.tarifPlDn
        break;
      case 'LN3':
        tarif = masterTarif.tarifPlPbLn
        break;
      case 'DN5':
        tarif = masterTarif.tarifPbDn
        break;
    }

    await this.props.change('tarif', tarif);
    this.calculate({}, '0');
  }

  async beforeSave(val) {
    var date;
    if(typeof val.tgl === 'string') {
      if(val.tgl.search('/') != -1) {
        date = moment(val.tgl, 'DD/MM/YYYY');
      } else {
        date = moment(val.tgl, 'YYYY-MM-DD');
      }
    } else if(typeof val.tgl === 'object') {
      date = moment(val.tgl)
    }

    if(!(date.year() == this.props.spt.data.year && date.month()+1 == this.props.spt.data.month)) {
      this.toast.warning({title:'Warning', message:'Tanggal bukti potong harus sesuai dengan masa SPT'})
      return false;
    }

    if(this.props.match.params.id != 'new') {
      if(this.state.prevData.type != val.type) {
        await this.service.api.deleteAll([{id:this.state.prevData.id, type: this.state.prevData.type}]);

        delete val.id;
      }
    }
    return true;
  }

  async afterSave(res, val) {
    let organization = this.props.spt.data.organization
    let {npwp, nama, alamat, email} = this.props.formData;

    var data = {
      npwp,
      nama,
      alamat,
      email,
      organization,
    }
    if(data.id) delete data.id;
    await MasterLawanTransaksi15Service.api.findOrCreate(data)
  }

  formView() {
    var formData = {};
    var formDataProps = {};
    if(this.props.formData && this.props.formData.values) formData = this.props.formData.values
    if(this.props.formData) formDataProps = this.props.formData
    return (
      <div>
        <LawanTransaksiDialog
          spt      = {this.props.spt.data}
          onSelect = {(d)=> {
            var formData = {...this.props.formData};

            formData.npwp = d.npwp
            formData.nama = d.nama
            formData.alamat = d.alamat
            formData.email = d.email

            this.setState({npwpNotValid: false})

            this.props.initialize(formData);
          }}
          service = {MasterLawanTransaksi15Service}
          visible = {this.state.lkDialog}
          onHide  = {()=> this.setState({lkDialog: false})}
        />

        <Card>
          <div className="md-grid">
            <Field
              label='No.Bukti Potong'
              name='no'
              className="md-cell md-cell--6"
              component={Textfield}
              validate={validation.required}
            />
            <Field
              label='Tanggal Bukti Potong'
              name='tgl'
              id="BP15Form-noBp"
              className="md-cell md-cell--6"
              component={Datepicker}
              validate={validation.required}
            />
            <Field
              label='Referensi'
              name='referensi'
              id="BP15Form-referensi"
              className="md-cell md-cell--12"
              component={Textfield}
            />
          </div>
        </Card>

        <br/>

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='A. Lawan Transaksi' />
          <Divider/>
          <div className="md-grid">
            <div className='md-cell md-cell--6'>
            <div className='mpk-layout'>
              <Field
                label      = 'NPWP'
                className  = 'flex'
                name       = 'npwp'
                component  = {TextfieldMask}
                maskFormat = "##.###.###.#-###-###"
                mask       = "_"
                length     = {15}
                onChange   = {this.npwpValidation.bind(this)}
                validate   = {validation.required}
              />
              <Button icon primary swapTheming style={{marginTop: 10}} onClick={()=> this.setState({lkDialog: true})}>search</Button>
              </div>
              <Field
                label     = 'Nama'
                name      = 'nama'
                disabled  = {this.state.npwpNotValid}
                component = {Textfield}
                validate  = {validation.required}
              />
            </div>
            <div className='md-cell md-cell--6'>
              <Field
                label = 'Alamat'
                name  = 'alamat'

                disabled  = {this.state.npwpNotValid}
                component = {Textfield}
                validate  = {validation.required}
              />
              <Field
                label = 'Email'
                name  = 'email'

                disabled  = {this.state.npwpNotValid}
                component = {Textfield}
              />
            </div>
          </div>
        </Card>



        <br/>

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='B. WAJIB PAJAK' />
          <Divider/>
          <div className="md-grid">
            <Field
              label     = 'NPWP'
              name      = 'npwpPemotong'
              className = "md-cell md-cell--6"
              disabled
              component  = {TextfieldMask}
              maskFormat = "##.###.###.#-###-###"
              mask       = "_"
              validate   = {validation.required}
            />
            <Field
              label     = 'Nama'
              name      = 'namaPemotong'
              className = "md-cell md-cell--6"
              disabled
              component = {Textfield}
              validate  = {validation.required}
            />

            <Field
              label     = 'Alamat'
              name      = 'alamatPemotong'
              className = "md-cell md-cell--12"
              disabled
              component = {Textfield}
              validate  = {validation.required}
            />
          </div>
        </Card>

        <br/>

        {this.state.showCalculateProgress && <LinearProgress id='lin_pro' style={{margin: 0}} />}
        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='C. DETAIL' />
          <Divider/>

          <div className="md-grid">
          <Field
              label='Jenis Form'
              name='type'
              className='md-cell md-cell--12'
              component={Searchfield}
              options={[{
                id: 'DN1',
                name: 'Imbalan Yang Dibayarkan/Terutang Kepada Perusahaan Pelayaran DN'
              }, {
                id: 'DN21',
                name: 'Imbalan Yang Diterima/Diperoleh Sehubungan Dengan Pengangkutan Orang Dan/Atau Barang Termasuk Penyewaan Kapal Laut Oleh Perusahaan Pelayaran DN (Penghasilan Dari Indonesia)'
              }, {
                id: 'DN22',
                name: 'Imbalan Yang Diterima/Diperoleh Sehubungan Dengan Pengangkutan Orang Dan/Atau Barang Termasuk Penyewaan Kapal Laut Oleh Perusahaan Pelayaran DN (Penghasilan Dari Luar Indonesia)'
              }, {
                id: 'LN3',
                name: 'Imbalan Charter Kapal Laut Dan/Atau Pesawat Udara Yang Dibayarakan/Terutang Kepada Perusahaan Pelayaran Dan/Atau Penerbangan LN'
              }, {
                id: 'DN5',
                name: 'Imbalan Charter Pesawat Udara Yang Dibayarkan/ Terutang Kepada Perusahaan Penerbangan DN'
              }]}
              onChange={this.getTarif.bind(this)}
            />
          </div>

          <DataTable plain fixedScrollWrapperStyle={{overflow: 'inherit'}} style={{overflow: 'inherit'}}>
            <TableHeader>
              <TableRow>
                <TableColumn>No.</TableColumn>
                <TableColumn className='md-text-right'>Jumlah Bruto Imbalan (Rp)</TableColumn>
                <TableColumn className='md-text-right'>Tarif (%)</TableColumn>
                <TableColumn className='md-text-right'>PPH(Rp)</TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableColumn>1.</TableColumn>
                <TableColumn>
                  <Field
                    name='bruto'
                    component={TextfieldMask}
                    className='md-text-right'
                    money={','}
                    onChange  = {(e, v, pv, key) => this.calculate(e, v, pv, key)}
                    disabled = {!formDataProps.type}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    name='tarif'
                    component={TextfieldMask}
                    className='md-text-right'
                    money={','}
                    onChange  = {(e, v, pv, key) => this.calculate(e, v, pv, key)}
                    disabled
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    name='pph'
                    component={TextfieldMask}
                    className='md-text-right'
                    money={','}
                    disabled
                  />
                </TableColumn>
              </TableRow>
            </TableBody>
          </DataTable>

          <Divider/>
          <div className="md-grid">
            <Field
              label     = 'Terbilang'
              name      = 'terbilang'
              className='md-cell md-cell--12'
              component = {Textfield}
              disabled
            />
          </div>
        </Card>

        <br/>
      </div>
    )
  }

}
