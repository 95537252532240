import ApiService from '../../../../../services/api.service';
import { splitDecimal } from 'react-number-format/lib/utils';

class ExportCsv21Service extends ApiService {
  name= 'ExportCsv21';
  path= 'spt21';
  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api={
    getInfo: async (sptId) => {
      return await this.http.get(`${this.apiLocation}/${this.path}/info/export/bp/${sptId}`)
    },
    getSummary: async(sptId)=> {
      return await this.http.get(`${this.apiLocation}/spt21/info/${sptId}`)
    },
    getTFTotal: async(sptId)=> {
      return await this.http.get(`${this.apiLocation}/spt21/tf/${sptId}/page`, {
        params: {
          page: 0,
        }
      })
    },
    getFTotal: async(sptId)=> {
      return await this.http.get(`${this.apiLocation}/spt21/f/${sptId}/page`, {
        params: {
          page: 0,
        }
      })
    },
    getA1Total: async(sptId)=> {
      return await this.http.get(`${this.apiLocation}/spt21/a1/${sptId}/page`, {
        params: {
          page: 0,
        }
      })
    },
    getA2Total: async(sptId)=> {
      return await this.http.get(`${this.apiLocation}/spt21/a2/${sptId}/page`, {
        params: {
          page: 0,
        }
      })
    }
  }
}

export default new ExportCsv21Service();
