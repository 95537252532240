import React from 'react';
import _ from 'lodash';
import {connect} from 'react-redux';
import counterpart from 'counterpart';
import {Field, reduxForm, getFormValues, initialize} from 'redux-form';
import moment from 'moment';
import download from 'downloadjs';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  CardActions,
  Button,
  LinearProgress,
  List,
  ListItem,
  FontIcon,
  DialogContainer
} from 'react-md';
import FormView from '../../../components/entity/form.view';
import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Checkbox} from '../../../components/form';
import UtilService from '../../../services/utils.service'
import SummaryService from './Summary.service';
import OrganizationService from '../Administrator/Organization/Organization.service';
import { OrganizationView } from '../Administrator/Organization/Organization.view';
import iziToast from 'izitoast';
import ApplicationInfoService from '../Application/ApplicationInfo.service';

@connect(OrganizationService.stateConnectSetting(), OrganizationService.actionConnectSetting())
class OrganizationPicker extends OrganizationView {
  viewType=2;

  _barActions = [
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => {

        window.location.hash = window.location.hash.split('?')[0]
        this.fetchData()
      }
    },
  ]

  _tableActions = [
    {
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    }
  ]

  _viewContainer(props) {
    return <div>
      {props.children}
    </div>
  }

}

@reduxForm({form: 'summary', destroyOnUnmount: true, initialValues: {organizations: []}})
@connect((state) => ({
  ...OrganizationService.stateConnectSetting()(state),
  access      : state.entity.access.api.find,
  organization: state.auth.currentOrganization,
  spt         : state.auth.currentSPT,
  formData    : getFormValues('summary')(state)
}))
export default class SummaryForm extends FormView {
  service  = SummaryService
  viewType = 2;

  translate = false

  titleHeader() {
    var title                                          = `Summary Pasal ${this.props.match.params.pasal}`;
    if (this.props.match.params.pasal === 'SSP') title = 'SSP';
    if (this.props.match.params.pasal === '4a2') title = 'Summary Pasal 4(2)';
    if (this.props.match.params.pasal === 'SSPPBK') title = 'Summary SSP/PBK';
    if (this.props.match.params.pasal === 'INDUK_21') title = 'Summary Induk Pasal 21';
    if (this.props.match.params.pasal === 'UNIT_21') title = 'Summary Unit Pasal 21';
    return title;
  }

  initialData={
  }

  constructor(props) {
    super(props);

    this.state= {
      ...this.state,
      tahunOptions: [],
      summaryBp: false,
      summaryPphPerUser: false,
      showOrganizationPicker: false,
    }
  }

  async componentDidMount() {
    let appInfo = await ApplicationInfoService.api.getApplicationInfo();

    let { data } = appInfo

    if (data) {
      let { summaryBp, summaryPphPerUser } = data

      this.setState({summaryBp, summaryPphPerUser})
    }
  }

  async handleSave(type) {
    let formData = this.props.formData

    let fromYear = formData.fromYear
    let toYear = formData.toYear
    let fromMonth = formData.fromMonth
    let toMonth = formData.toMonth
    let calculate = formData.calculate
    let allNpwp = formData.allNpwp

    let organizationIds = [];

    formData.organizations.forEach(x => {
      organizationIds.push(x.id);
    })

    let pasal = this.props.match.params.pasal

    if(pasal === '4a2') {
      pasal = '4A2'
    }

    let statuses = []

    if(formData.process) {
      statuses.push('PROCESS')
    }

    if(formData.waiting) {
      statuses.push('WAITING')
    }

    if(formData.finish) {
      statuses.push('FINISH')
    }

    if (!allNpwp) {
      if (organizationIds.length === 0) {
        iziToast.warning({
          title: 'Gagal',
          message: 'Anda harus memilih minimal 1 organisasi.'
        })

        return
      }
    }

    let data = {
      organizationIds,
      type,
      pasal,
      fromYear,
      toYear,
      fromMonth,
      toMonth,
      statuses,
      calculate,
      allNpwp
    }

    try {
      var summary = await this.service.api.generate(data)

      var filename = summary.headers.filename

      download(summary.data, filename)
    } catch (e) {
      let message = 'File summary gagal dibuat atau tidak ada data.'

      iziToast.warning({
        title: 'Gagal',
        message: message
      })
    }
  }

  async initData() {
    this.props.initialize({organizations:[]});
  }

  tahunOptions = [];

  masaOptions = [
    {id:1, name: 'Januari'}, {id:2, name: 'Februari'}, {id:3, name: 'Maret'}, {id:4, name: 'April'}, {id:5, name: 'Mei'}, {id:6, name: 'Juni'},
    {id:7, name: 'Juli'}, {id:8, name: 'Agustus'}, {id:9, name: 'September'}, {id:10, name: 'Oktober'}, {id:11, name: 'November'}, {id:12, name: 'Desember'}
  ]

  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;

    let selectedOrganizations = this.props.formData.organizations

    return (
      <div>
        {(this.props.match.params.pasal !== 'SSPPBK' && this.props.match.params.pasal !== 'INDUK_21' && this.props.match.params.pasal !== 'UNIT_21') &&
            <Button secondary raised primary onClick={() => this.handleSave('GABUNG')} style={{marginRight: 16}}>Gabung</Button>
        }

        {(this.props.match.params.pasal !== 'SSPPBK' && this.props.match.params.pasal !== 'INDUK_21' && this.props.match.params.pasal !== 'UNIT_21') &&
            <Button secondary raised primary onClick={()=> this.handleSave('PISAH')} style={{marginRight: 16}}>Pisah</Button>
        }

        {(this.props.match.params.pasal !== 'SSPPBK' && this.props.match.params.pasal !== 'INDUK_21' && this.props.match.params.pasal !== 'UNIT_21') &&
            <Button secondary raised primary onClick={()=> this.handleSave('REKAP')} style={{marginRight: 16}}>Rekap</Button>
        }

        {(this.props.match.params.pasal !== 'SSPPBK' && this.props.match.params.pasal !== 'INDUK_21' && this.props.match.params.pasal !== 'UNIT_21') &&
            <Button secondary raised primary onClick={()=> this.handleSave('PPH')} style={{marginRight: 16}}>PPh</Button>
        }

        {(this.props.match.params.pasal === '23' || this.props.match.params.pasal === '26' || this.props.match.params.pasal === '4A2') &&
            <Button secondary raised primary onClick={()=> this.handleSave('PPH_PER_USER')} style={{marginRight: 16}}>PPh Per User</Button>
        }

        {((this.state.summaryBp && (this.props.match.params.pasal === '21' || this.props.match.params.pasal === '23' || this.props.match.params.pasal === '26' || this.props.match.params.pasal === '4A2' || this.props.match.params.pasal === '22' || this.props.match.params.pasal === '15'))) &&
            <Button secondary raised primary onClick={()=> this.handleSave('BP')} style={{marginRight: 16}}>Bukti Potong</Button>
        }

        {((this.state.summaryBp && (this.props.match.params.pasal === '21' || this.props.match.params.pasal === '23' || this.props.match.params.pasal === '26' || this.props.match.params.pasal === '4A2' || this.props.match.params.pasal === '22' || this.props.match.params.pasal === '15'))) &&
            <Button secondary raised primary onClick={()=> this.handleSave('BP_ONE_SHEET')} style={{marginRight: 16}}>Bukti Potong 1 File</Button>
        }

        {this.props.match.params.pasal === 'SSPPBK' &&
            <Button secondary raised primary onClick={() => this.handleSave('SSP')} style={{marginRight: 16}}>SSP</Button>
        }

        {this.props.match.params.pasal === 'SSPPBK' &&
            <Button secondary raised primary onClick={() => this.handleSave('PBK')} style={{marginRight: 16}}>PBK</Button>
        }

        {this.props.match.params.pasal === 'INDUK_21' &&
            <Button secondary raised primary onClick={() => this.handleSave('INDUK_21')} style={{marginRight: 16}}>Download</Button>
        }

        {this.props.match.params.pasal === 'UNIT_21' &&
        <Button secondary raised primary onClick={() => this.handleSave('UNIT_21')} style={{marginRight: 16}}>Download</Button>
        }
      </div>
    )
  }

  async handleMultiSelectOrganization() {
    var formData = this.props.formData;
    var organizations = formData.organizations;
    this.props.table.selected.forEach((d, i)=> {
      var check = _.find(organizations, {id: d.id});
      if(!check) {
        organizations.push(d);
      }
    })

    this.props.change('organizations', organizations);
  }

  async handleDelete(i) {
    var formData = this.props.formData;
    var organizations = formData.organizations;
    organizations.splice(i, 1);
    this.props.change('organizations', organizations);
  }

  formView() {
    var tahun = new Date().getFullYear();
    var tahunOptions = [];

    for (let index = 0; index < 11; index++) {
      tahunOptions.push({
        id: tahun - index,
        name: tahun - index
      })
    }

    var formData                      = {};
    if (this.props.formData) formData = this.props.formData.values
    return (
      <div>

        <Card>
          <div className='md-grid'>
            <Field
              className="md-cell md-cell--6"
              label     = 'Dari Masa'
              name      = 'fromMonth'
              component = {Searchfield}
              options   = {this.masaOptions}
            />
            <Field
              className="md-cell md-cell--6"
              label     = 'Sampai Masa'
              name      = 'toMonth'
              component = {Searchfield}
              options   = {this.masaOptions}
            />

            <Field
              className="md-cell md-cell--6"
              label     = 'Dari Tahun'
              name      = 'fromYear'
              component = {Searchfield}
              options   = {tahunOptions}
            />

            <Field
              className="md-cell md-cell--6"
              label     = 'Sampai Tahun'
              name      = 'toYear'
              component = {Searchfield}
              options   = {tahunOptions}
            />

          </div>

          <b style={{margin: '20px 15px'}}>STATUS SPT</b>
          <Divider  />
          <div className="md-grid">
            <Field
              className="md-cell md-cell--6"
              label     = 'PROCESS'
              name      = 'process'
              component = {Checkbox}
            />
            {/*<Field
              className="md-cell md-cell--6"
              label     = 'APPROVE'
              name      = 'approve'
              component = {Checkbox}
            />*/}
            <Field
              className="md-cell md-cell--6"
              label     = 'WAITING'
              name      = 'waiting'
              component = {Checkbox}
            />
            <Field
              className="md-cell md-cell--6"
              label     = 'FINISH'
              name      = 'finish'
              component = {Checkbox}
            />
          </div>

          <div className="md-grid">
            <Field
              className="md-cell md-cell--6"
              label='Semua Organisasi'
              name='allNpwp'
              component={Switch}
            />

            <Field
              className="md-cell md-cell--6"
              label='Kalkulasi Induk'
              name='calculate'
              component={Switch}
            />
          </div>

          <div className="md-grid">
            <Button raised primary onClick={()=> {
              this.setState({showOrganizationPicker: true})
            }} >Multi Select Organization</Button>

            <DialogContainer
              id="simple-action-dialog"
              width={800}
              // height={586}
              contentStyle={{padding:0}}
              visible={this.state.showOrganizationPicker}
              onHide={()=> {
                this.setState({showOrganizationPicker: false})
              }}
              actions={[
                <Button flat onClick={async ()=> {
                  this.setState({showOrganizationPicker: false})
                }}>Close</Button>,
                <Button raised primary onClick={async ()=> {
                  await this.handleMultiSelectOrganization();
                  this.setState({showOrganizationPicker: false})
                }}>Confirm</Button>

              ]}
              // title="Change something?"
            >
              <OrganizationPicker />
            </DialogContainer>
            <List className="md-cell--12 md-paper md-paper--1">
              <Subheader primaryText={<div>Selected </div>} ></Subheader>
              {this.props.formData.organizations.map((d, i)=> {

                let primaryText = (i + 1) + '. ' + d.name

                let namaAlias = d.aliasName

                if (namaAlias != null) {
                  namaAlias = namaAlias.trim()

                  if (namaAlias !== '' && namaAlias !== '-') {
                    primaryText += ' - ' + namaAlias
                  }
                }

                return <ListItem
                  key={i}
                  rightIcon={<FontIcon onClick={()=> this.handleDelete(i)}>delete</FontIcon>}
                  primaryText={primaryText}
                  secondaryText={d.npwp}
                />

              })}
            </List>
          </div>
        </Card>

      </div>
    )
  }

}
