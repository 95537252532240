import ApiService from '../../../../../services/api.service'
import http from '../../../../../services/http.service'
import moment from 'moment';
import React from 'react';
import { Chip } from 'react-md';

class Bupot26Service extends ApiService {
  name= 'Bupot26';
  path= 'ebupot/bp26';
  rootPath=true
  advancedSearch=true

  columns = [
    {label: "word.id", searchField: "id", value: "id", isDefaultSort:false, isSortable:true, show:false, isSearchable:true},
    {label: "word.noBp", searchField: "no", value: "no", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.rev", searchField: "rev", value: "rev", type: 'number', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {
      searchField: "status",
      label: "word.status",
      type: 'func',
      value: (d)=> {
        var style = {
          background: 'grey'
        }
        switch (d.status) {
          case 'CREATED':
            style.background = 'grey'
            break;
          case 'PROCESS':
            style.background = 'orange'
            break;
          case 'FAILED':
            style.background = 'orangered'
            break;
          case 'FINISH':
            style.background = 'limegreen'
            break;
          case 'COMPLETED':
            style.background = 'dodgerblue'
            break;
          case 'DELETED':
            style.background = 'darkred'
            break;
          case 'UPLOADING':
            style.background = 'yellow'
            break;
          default:
            break;
        }
        return <Chip label={d.status} style={style} />
      },
      className: "mpk-font-size-S", show:true, isSearchable:true},
    {label: "word.report", searchField: "report", value: "report", type: 'boolean', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.npwpProfiles", searchField: "spt.organization.npwp", value: "spt.organization.npwp", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.namaProfiles", searchField: "spt.organization.nama", value: "spt.organization.nama", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.masa", searchField: "spt.masa", value: "spt.masa", type: 'number', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.tahun", searchField: "spt.tahun", value: (d)=> { return d.spt.tahun + "" }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.pembetulan", searchField: "spt.pembetulan", value: "spt.pembetulan", type: 'number', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.message", searchField: "message", value: "message", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.tin", searchField: "tin", value: "tin", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.message", searchField: "message", value: "message", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.nama", searchField: "nama", value: "nama", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.email", searchField: "email", value: "email", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.code", searchField: "code", type:'func', value: (val)=> { return <div style={{width: 100}}>{val.kode}</div> }, isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.bruto", searchField: "bruto", type: "number", value: "bruto", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.pph", searchField: "pph", type: "number", value: "pph", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.nSkdDtp", searchField: "nSkbDtp", type: "func", value: (d)=> {
      var nomor = ""
      if(d.fasilitas == 0){
        nomor = "N"
      } else if(d.fasilitas == 1){
        nomor = "SKD"
      } else if (d.fasilitas == 2){
        nomor = "DTP"
      }
      return nomor
    }, isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.refXmlId", searchField: "refXmlId", value: "refXmlId", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.refLogFileId", searchField: "refLogFileId", value: "refLogFileId", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.createdBy", searchField: "createdBy", value: "createdBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.createdDate", searchField: "createdDate", value: (d)=> {
      if(d){
        return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
      } else {
        return "-"
      }
    }, type: 'func', isDefaultSort:true, isSortable:true, show:true, isSearchable:true},
    {label: "word.lastModifiedBy", searchField: "lastModifiedBy", value: "lastModifiedBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.lastModifiedDate", searchField: "lastModifiedDate", value: (d)=> {
      if(d){
        return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
      } else {
        return "-"
      }
    }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
  ]

  api= {
    requestCetak: async(id) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${id}/request`)

      return res
    },
    sendEmail: async(id) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${id}/mail`)

      return res
    },
    sendEmailBulk: async (data)=> {
      var ids = []
      data.map((d)=> {
        ids.push(d.id)
      })
      var res = await this.http.post(`${this.apiLocation}/${this.path}/mail`, ids)
      
      return res;
    },
    submitDelete: async (data)=> {
      var ids = []
      data.map((d)=> {
        ids.push(d.id)
      })
      var res = await this.http.post(`${this.apiLocation}/${this.path}/delete`, ids)
      
      return res;
    },
    summary: async (esptId) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${esptId}/espt23/summary`)

      return res.data;
    },
    calculate: async (param) => {
      var res = await this.http.post(this.apiLocation+'/calculation/pph23/f', param)

      return res.data;
    },
    total: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/total/${sptId}`)

      return res.data;
    },
    cetakReportBulk: async (ids) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/report`, ids)

      return res;
    },
    cetak: async (id) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${id}/report`)

      return res;
    },
    cetakBulkMerge: async (ids) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/report/bulk/merge`, ids, {
        responseType: 'blob'
      })

      return res;
    },
    cetakDaftar: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/spt23/bp-23/daftar/report/${sptId}`, {
        responseType: 'blob'
      })

      return res;
    },
    downloadCsv: async (sptId)=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/csv/${sptId}`, {
        responseType: 'blob'
      })

      return res;
    },
    email: async (category, ids) => {
      return await this.http.post(`${this.apiLocation}/email?category=${category}`, ids)
    },
    emailSpt: async (category, sptId, ids) => {
      return await this.http.post(`${this.apiLocation}/email?category=${category}&sptId=${sptId}`, ids)
    },
    deleteBulk: async (sptId, ids)=> {
      let res = await http.post(`${this.apiLocation}/${this.path}/${sptId}/delete`, ids);

      return res;
    },
    summarySpt: async (data) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/summary/excel?orgIds=${data.npwp}&masa1=${data.masa1}&masa2=${data.masa2}&tahun1=${data.tahun1}&tahun2=${data.tahun2}`, {
        responseType: 'blob',
        data: data
      })

      return res;
    },
  }

  constructor() {
    super()
    this.init()
    this.initApi()
  }
}


export default new Bupot26Service();
