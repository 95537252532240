import ApiService from '../../../../services/api.service';

class SettingExportAdminService extends ApiService {
  name= 'SettingExportAdmin';
  path= 'setting/export-admin/page';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api = {
    get: async ()=> {
      return await this.http.get(`${this.apiLocation}/setting/export-admin`)
    },
    update: async (formData)=> {
      return await this.http.put(`${this.apiLocation}/setting/export-admin`, formData)
    }
  }
}

export default new SettingExportAdminService();
