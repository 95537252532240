import React, { Component } from 'react';
import { connect } from 'react-redux';

import FormDialog from './Master21SspDescription.dialog.view';
import Master21SspDescriptionService from './Master21SspDescription.service';
import ListView from '../../../../../components/entity/listView';
import ColumnService from '../../../../../services/column.service';

@connect(Master21SspDescriptionService.stateConnectSetting(), Master21SspDescriptionService.actionConnectSetting())
export default class Master21SspDescriptionView extends ListView {
  service=Master21SspDescriptionService
  FormDialog=FormDialog

  columns=[
    {isSortable: true, label: "word.id", searchField: "id", value: function(d){ return d.id }, type: "func",  show:false, isSearchable:true},
    {isSortable: true, label: "word.code", searchField: "code", value: function(d){ return d.code }, type: "func",  show:true, isSearchable:true},
    {isSortable: true, label: "word.name", searchField: "name", value: function(d){ return d.name }, type: "func",  show:true, isSearchable:true},
  ]   
}
