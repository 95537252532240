import ApiService from '../../../../services/api.service';

class SettingEbillingGlobalService extends ApiService {
  name= 'SettingEbillingGlobal';
  path= 'ebilling/setting-global/page';

  constructor() {
    super();
    this.init();
    this.initApi()
  }

  api = {
    get: async ()=> {
      return await this.http.get(`${this.apiLocation}/ebilling/setting-global`)
    },
    update: async (formData)=> {
      return await this.http.put(`${this.apiLocation}/ebilling/setting-global`, formData)
    }
  }
}

export default new SettingEbillingGlobalService();
