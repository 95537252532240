import ApiService from '../../../../services/api.service';

class RetryService extends ApiService {
  name= 'Retry';
  path= 'ebilling/retry';

  api = {
    inquiry: async (data) => {
      var res = await this.http.post(`${this.apiLocation + "/" + this.path}/inquiry`, data)

      return res.data;
    },
    billing: async (data) => {
      var res = await this.http.post(`${this.apiLocation + "/" + this.path}/billing`, data)

      return res.data;
    },
  }

  constructor() {
    super()
    this.init()
    this.initApi()
  }
}


export default new RetryService();
