import React, { Component } from 'react';
import { DialogForm } from 'react-mpk';

export default class DialogView extends Component {
  service;
  size= 'normal'
  translate = true;
  initialData = {}
  titleHeader() {
    return `entities.${this.service.name}.title`;
  }

  formStyle={}

  actionButton=null;

  constructor(props){
    super(props);
    this.state = {
      onProgress: false
    };
  }

  async componentWillReceiveProps(nextProps) {
    if(this.props.formData.id != nextProps.formData.id) {
      await this.initData(nextProps)
    }

    // if(!nextProps.isEditDialog) {
    //   this.props.initialize({});
    // }
  }

  componentDidMount() {
    this.initData(this.props);
  }

  initData(props) {
    if(props.formData.id) {
      this.props.initialize(props.formData);
    } else {
      this.props.initialize(this.initialData);
    }
  }

  handleClose() {
    this.props.onClose()
  }

  async beforeSave(val) {
    return true;
  }

  async afterSave(res, val) {

  }

  async handleSave(callback, value) {
    var beforeSave = await this.beforeSave(value);
    var res = null;
    if(beforeSave) {
      if(typeof beforeSave === 'object') value = beforeSave;

      var newVal = this.transform(value);
      try {
        this.setState({onProgress: true})
        if(value.id) {
          await this.service.api.update(newVal)
          this.props.change(this.props.form, value)
        } else {
          await this.service.api.save(newVal)
          this.props.reset(this.props.form)
        }
        this.setState({onProgress: false})
        this.afterSave(res, value)
        callback()
        this.props.onSuccess()
        this.props.onClose()
      } catch(e) {
        var msg = e.message;
        if(e.response) msg = e.response.data.message
        callback(true, msg)
      }
    }
    else {
      callback()
      this.setState({onProgress: false})
    }
  }

  transform(value) {
    return value;
  }

  _handleSubmit(callback) {
    const { handleSubmit, submitting, valid, pristine } = this.props;
    if(valid) {
      handleSubmit(this.handleSave.bind(this, callback))()
    } else {
      callback(true, "Please check your form again");
    }
  }

  render() {
    return <DialogForm
      formStyle={this.formStyle}
        title={this.titleHeader()}
        visible={this.props.visible}
        onCancel={()=> this.handleClose()}
        onSubmit={(callback) => {this._handleSubmit(callback)}}
        translate={this.translate}
        submitLabel={this.submitLabel}
        cancelLabel={this.cancelLabel}
        size={this.size}>
      {this.formView()}
    </DialogForm>
  }

  formView() {
    return <div></div>
  }
}
