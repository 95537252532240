import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {connect} from 'react-redux';

import ValidationLogService from './ValidationLog.service';
import ListView from '../../../../components/entity/listView';
import {DataTable, TableBody, TableColumn, TableHeader, TableRow} from "react-md";
import download from "downloadjs";
import izitoast from "izitoast";
import LogEfillingService from "../../Efilling/LogEfilling.service";

@reduxForm({form: 'VALIDATION_LOG', destroyOnUnmount: true})
@connect((state) => ({
  ...ValidationLogService.stateConnectSetting()(state)
}), ValidationLogService.actionConnectSetting())
export default class ValidationLog extends ListView {
  service = ValidationLogService
  FormDialog=()=> <div/>
  // viewType=2

  constructor(props) {
    super(props);

    this.state = {
      showTableSetting: true,
      showDialogConfirmDeleteSelected: false,
      showForm: false,
      formData: {},
      data: [],
      onProgress: false,
      isError: false,
      errorMessage: ''
    }
  }

  _barActions = [
  ]

  _tableActions = []

  columns = [
    {isSortable: true, label: "word.method",  value: "method", isDefaultSort:false, show:true, isSearchable:false},
    {isSortable: true, label: "word.url",  value: "url", isDefaultSort:false, show:true, isSearchable:false},
    {isSortable: true, label: "word.value",  value: "value", isDefaultSort:false, show:true, isSearchable:false},
    {isSortable: true, label: "word.message",  value: "message", isDefaultSort:false, show:true, isSearchable:false},
    {isSortable: true, label: "word.ip",  value: "ip", isDefaultSort:false, show:true, isSearchable:false},
    {isSortable: true, label: "word.user",  type: "func", value: (d) => {
      if (d.user)
        return d.user.login
      return '-'
    }, isDefaultSort:false, show:true, isSearchable:false},
    {isSortable:true, label: "word.createdDate", value: "createdDate", show:true, isSearchable:true, type:"date", isDefaultSort: true},
  ]

  defaultColumns = [
  ]

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='Method'
          name='method__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='URL'
          name='url__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Value'
          name='value__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Message'
          name='message__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='IP'
          name='ip__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Username'
          name='userLogin__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
      </this.SearchContainer>
    )
  }

}
