import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  Tab,
  Tabs,
  SelectionControlGroup,
  Checkbox,
  Button
} from 'react-md';
import axios from 'axios'

import FormView from '../../../../../components/entity/form.view';
import ImportService from './Import.service';
import {validation, Textfield, Searchfield, Switch, Datepicker, TextfieldMask, Fileinput, convert} from '../../../../../components/form';
import { SearchField } from 'react-mpk';
import SelectField from 'react-md/lib/SelectFields/SelectField';
import TextField from 'react-md/lib/TextFields/TextField';
import izitoast from 'izitoast';

@reduxForm({form: 'IMPORT_21_Form', destroyOnUnmount: true})
@connect((state) => ({
  access: state.entity.access.api.find,
  organization: state.auth.currentOrganization,
  spt: state.auth.currentSPT
}))
export default class BP21SSPForm extends FormView {
  service=ImportService
  viewType =2;

  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;

    return <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>Import</Button>
  }

  async handleSave(value) {
    var _this = this
    try {
      this.setState({onProgress: true})
      let formData = new FormData();
      formData.append("type", value.type)
      formData.append("category", value.category)
      formData.append("file", value.file)
      axios.post(`/api/${ImportService.path}`, formData).then(function (res){
        _this.setState({onProgress: false})
        izitoast.success({title: 'Import Data', message: 'Berhasil'})
      }).catch(function (e){
        throw e
      })
    } catch(e) {
      var msg = e.message;
      if(e.response) msg = e.response.data.message;
      this.setState({
        isError: true,
        onProgress: false,
        errorMessage: msg
      })
    }
  }

  formView() {
    return (
        <Card>
          <div className='md-grid'>
            <div className="md-cell md-cell--12">
              <Field
                label='Select a File'
                name='file'
                component={Fileinput}
                validate={validation.required}
              />
            </div>
            <div className="md-cell md-cell--12">
              <Field
                label='Kategori'
                name='category'
                component={Searchfield}
                options={[
                    {id: "BP_21_TF", name:"Bukti Potong Pasal 21 Tidak Final"},
                    {id: "BP_21_F", name:"Bukti Potong Pasal 21 Final"},
                    {id: "BP_21_A1", name:"Bukti Potong Pasal 21 A1"},
                    {id: "BP_21_A2", name:"Bukti Potong Pasal 21 A2"},
                    {id: "BP_21_SATU_MASA", name:"Bukti Potong Satu Masa"},
                    {id: "SSP_21", name:"SSP Pasal 21"},
                    {id: "DAFTAR_BIAYA_21", name:"Daftar Biaya Pasal 21"},
                    {id: "IMAGE_TANDA_TANGAN", name:"Gambar Tanda Tangan"},
                ]}
                validate={validation.required}
              />
            </div>
            <div className="md-cell md-cell--12">
              <Field
                label='Perhitungan'
                name='type'
                component={Searchfield}
                options={[
                    {id: "A", name:"Otomatis"},
                    {id: "M", name:"Manual"}
                ]}
                validate={validation.required}
              />
            </div>
          </div>
        </Card>
    )
  }

}
