import React from 'react';
import _ from 'lodash';
import {connect} from 'react-redux';
import counterpart from 'counterpart';
import { Field, reduxForm, getFormValues} from 'redux-form';
import moment from 'moment';
import download from 'downloadjs';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  CardActions,
  Button,
  LinearProgress,
  List,
  ListItem,
  FontIcon,
  DialogContainer
} from 'react-md';
import FormView from '../../../components/entity/form.view';
import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Checkbox} from '../../../components/form';
import UtilService from '../../../services/utils.service'
import KalkulasiIndukService from './KalkulasiInduk.service';
import OrganizationService from '../Administrator/Organization/Organization.service';
import { OrganizationView } from '../Administrator/Organization/Organization.view';
import iziToast from 'izitoast';

@connect(OrganizationService.stateConnectSetting(), OrganizationService.actionConnectSetting())
class OrganizationPicker extends OrganizationView {
  viewType=2;

  _barActions = [
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => {

        window.location.hash = window.location.hash.split('?')[0]
        this.fetchData()
      }
    },
  ]

  _tableActions = [
    {
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    }
  ]

  _viewContainer(props) {
    return <div>
      {props.children}
    </div>
  }

}

@reduxForm({form: 'kalkulasiInduk', destroyOnUnmount: true, initialValues: {organizations: []}})
@connect((state) => ({
  ...OrganizationService.stateConnectSetting()(state),
  access      : state.entity.access.api.find,
  organization: state.auth.currentOrganization,
  spt         : state.auth.currentSPT,
  formData    : getFormValues('kalkulasiInduk')(state)
}))
export default class KalkulasiIndukForm extends FormView {
  service  = KalkulasiIndukService
  viewType = 2;

  translate = false

  titleHeader() {
    var title                                          = `Kalkulasi Induk Pasal ${this.props.match.params.pasal}`;
    if (this.props.match.params.pasal === '4a2') title = 'Kalkulasi Induk Pasal 4(2)';
    return title;
  }

  initialData={
  }

  constructor(props) {
    super(props);

    this.state= {
      ...this.state,
      tahunOptions: [],

      showOrganizationPicker: false
    }
  }

  async handleSave() {
    let formData = this.props.formData

    let fromYear = formData.fromYear
    let toYear = formData.toYear
    let fromMonth = formData.fromMonth
    let toMonth = formData.toMonth

    let organizationIds = [];

    formData.organizations.forEach(x => {
      organizationIds.push(x.id);
    })

    let pasal = this.props.match.params.pasal

    if(pasal === '4a2') {
      pasal = '4A2'
    }

    let statuses = []

    if(formData.process) {
      statuses.push('PROCESS')
    }

    if(formData.waiting) {
      statuses.push('WAITING')
    }

    if(formData.finish) {
      statuses.push('FINISH')
    }

    let data = {
      organizationIds,
      pasal,
      fromYear,
      toYear,
      fromMonth,
      toMonth,
      statuses
    }

    try {
      var kalkulasiInduk = await this.service.api.induk(data)

      iziToast.success({
        title: 'Sukses',
        message: message
      })
    } catch (e) {

    }
  }

  async initData() {
    this.props.initialize({organizations:[]});
  }

  tahunOptions = [];

  masaOptions = [
    {id:1, name: 'Januari'}, {id:2, name: 'Februari'}, {id:3, name: 'Maret'}, {id:4, name: 'April'}, {id:5, name: 'Mei'}, {id:6, name: 'Juni'},
    {id:7, name: 'Juli'}, {id:8, name: 'Agustus'}, {id:9, name: 'September'}, {id:10, name: 'Oktober'}, {id:11, name: 'November'}, {id:12, name: 'Desember'}
  ]

  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;

    let selectedOrganizations = this.props.formData.organizations

    return (
      <div>
        <Button secondary raised primary onClick={() => this.handleSave()} style={{marginRight: 16}} disabled={selectedOrganizations.length === 0}>Kalkulasi</Button>
      </div>
    )
  }

  async handleOrganization(v) {
    var formData = this.props.formData;
    var organizations = formData.organizations;
    var check = _.find(organizations, {id: v.id});

    if(!check) {
      organizations.push(v);

      this.props.change('organizations', organizations);
    }
  }

  async handleMultiSelectOrganization() {
    var formData = this.props.formData;
    var organizations = formData.organizations;
    this.props.table.selected.forEach((d, i)=> {
      var check = _.find(organizations, {id: d.id});
      if(!check) {
        organizations.push(d);
      }
    })

    this.props.change('organizations', organizations);
  }

  async handleDelete(i) {
    var formData = this.props.formData;
    var organizations = formData.organizations;
    organizations.splice(i, 1);
    this.props.change('organizations', organizations);
  }

  formView() {
    var tahun = new Date().getFullYear();
    var tahunOptions = [];

    for (let index = 0; index < 11; index++) {
      tahunOptions.push({
        id: tahun - index,
        name: tahun - index
      })
    }

    var formData                      = {};
    if (this.props.formData) formData = this.props.formData.values
    return (
      <div>

        <Card>
          <div className='md-grid'>
            <Field
              className="md-cell md-cell--6"
              label     = 'Dari Masa'
              name      = 'fromMonth'
              component = {Searchfield}
              options   = {this.masaOptions}
            />
            <Field
              className="md-cell md-cell--6"
              label     = 'Sampai Masa'
              name      = 'toMonth'
              component = {Searchfield}
              options   = {this.masaOptions}
            />

            <Field
              className="md-cell md-cell--6"
              label     = 'Dari Tahun'
              name      = 'fromYear'
              component = {Searchfield}
              options   = {tahunOptions}
            />

            <Field
              className="md-cell md-cell--6"
              label     = 'Sampai Tahun'
              name      = 'toYear'
              component = {Searchfield}
              options   = {tahunOptions}
            />

          </div>

          <b style={{margin: '20px 15px'}}>STATUS SPT</b>
          <Divider  />
          <div className="md-grid">
            <Field
              className="md-cell md-cell--6"
              label     = 'PROCESS'
              name      = 'process'
              component = {Checkbox}
            />
            {/*<Field
              className="md-cell md-cell--6"
              label     = 'APPROVE'
              name      = 'approve'
              component = {Checkbox}
            />*/}
            <Field
              className="md-cell md-cell--6"
              label     = 'WAITING'
              name      = 'waiting'
              component = {Checkbox}
            />
            <Field
              className="md-cell md-cell--6"
              label     = 'FINISH'
              name      = 'finish'
              component = {Checkbox}
            />
          </div>

          <b style={{margin: '20px 15px'}}>NPWP</b>
          <Divider  />

          <div className="md-grid">
          <Field
            label='Organization'
            name='organization'
            id="spt2126-organization"
            className="md-cell md-cell--12"
            // disabled={this.props.organization.id != null}
            valueField='parent'
            service={OrganizationService}
            searchField  = {['name', 'aliasName', 'npwp']}
            component={Searchfield}
            onChange={(v)=> {
              this.handleOrganization(v)
            }}
            itemTemplate={function(d) {
              let nameAndAlias = ""

              if (d.name)
                nameAndAlias = d.name

              if (d.aliasName)
                nameAndAlias += " - " + d.aliasName

              return {
                primaryText  : nameAndAlias,
                secondaryText: d.npwp,
                onClick: () => {
                  if(!this.props.disabled){
                    this.handleItemClick(d); this.hide()
                  }
                }
              }
            }}
            />

            <Button raised primary onClick={()=> {
              this.setState({showOrganizationPicker: true})
            }} >Multi Select Organization</Button>
            <DialogContainer
              id="simple-action-dialog"
              width={800}
              // height={586}
              contentStyle={{padding:0}}
              visible={this.state.showOrganizationPicker}
              onHide={()=> {
                this.setState({showOrganizationPicker: false})
              }}
              actions={[
                <Button flat onClick={async ()=> {
                  this.setState({showOrganizationPicker: false})
                }}>Close</Button>,
                <Button raised primary onClick={async ()=> {
                  await this.handleMultiSelectOrganization();
                  this.setState({showOrganizationPicker: false})
                }}>Confirm</Button>

              ]}
              // title="Change something?"
            >
              <OrganizationPicker />
            </DialogContainer>
            <List className="md-cell--12 md-paper md-paper--1">
              <Subheader primaryText={<div>Selected </div>} ></Subheader>
              {this.props.formData.organizations.map((d, i)=> {

                let primaryText = (i + 1) + '. ' + d.name

                let namaAlias = d.aliasName

                if (namaAlias != null) {
                  namaAlias = namaAlias.trim()

                  if (namaAlias !== '' && namaAlias !== '-') {
                    primaryText += ' - ' + namaAlias
                  }
                }

                return <ListItem
                  key={i}
                  rightIcon={<FontIcon onClick={()=> this.handleDelete(i)}>delete</FontIcon>}
                  primaryText={primaryText}
                  secondaryText={d.npwp}
                />

              })}
            </List>
          </div>
        </Card>

      </div>
    )
  }

}
