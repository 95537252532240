import React from 'react';
import {connect} from 'react-redux';
import Bluebird from 'bluebird';
import { Field, reduxForm, getFormValues} from 'redux-form';
import moment from 'moment';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  CardActions,
  Button,
  LinearProgress,
  Tab,
  Tabs
} from 'react-md';
import FormView from '../../../../../../components/entity/form.view';
import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Checkbox} from '../../../../../../components/form';
import Master26TarifService from './Master26Tarif.service';
import iziToast from 'izitoast';


@reduxForm({form: 'Master26TarifForm', destroyOnUnmount: true, initialValues: {
  pasal23: {},
  pasal23Lain: [],
  pasal26: {},
  lain: {}
}})
@connect(state => ({
  company: state.auth.currentCompany,
  formData:getFormValues('Master26TarifForm')(state)
}))
export default class Master23TarifForm extends FormView {
  service=Master26TarifService
  viewType=2

  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      activeTab: 0
    }
  }

  async initData() {
    if(this.props.match.params.id == 'new') {

      var master = await Master26TarifService.api.getMaster();
      if(master) {
        delete master.id;
      }

      var initialData = {
        company: this.props.company,
        ...master
      }

      this.props.initialize(initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id);
      this.props.initialize(res.data);
    }
  }

  formView() {
    return (
      <div className="mpk-layout column fill mpk-tab-clear">
        <div className="flex">
        {this.pasal26Form()}
        </div>
      </div>
    )
  }

  pasal23Form() {
    return <div style={{marginBottom: 20}}>
      <div className='md-card md-paper md-paper md-paper--1 md-card md-background--card'>

        <div className="md-grid">
          <Field
            className="md-cell md-cell--6"
            label='Start Date'
            name='start'
            inline={true}
            component={Datepicker}
            validate={validation.required}
          />
          <Field
            className='md-cell md-cell--6'
            label='End Date'
            name='end'
            inline={true}
            component={Datepicker}
            validate={validation.required}
          />
        </div>
        <DataTable plain fixedScrollWrapperStyle={{overflow: 'inherit'}} style={{overflow: 'inherit'}}>
          <TableHeader>
            <TableRow>
              <TableColumn>No.</TableColumn>
              <TableColumn>Jenis Penghasilan</TableColumn>
              <TableColumn>Tarif(%)</TableColumn>
            </TableRow>
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableColumn>
                1.
              </TableColumn>
              <TableColumn>Dividen</TableColumn>
              <TableColumn>
                <Field
                  // label='Tarif'
                  name='dividen'
                  component={TextfieldMask}
                  className='md-text-right'
                />
              </TableColumn>
            </TableRow>
            <TableRow>
              <TableColumn>
                2.
              </TableColumn>
              <TableColumn>Bunga</TableColumn>
              <TableColumn>
                <Field
                  // label='Tarif'
                  name='bunga'
                  component={TextfieldMask}
                  className='md-text-right'
                />
              </TableColumn>
            </TableRow>
            <TableRow>
              <TableColumn>
                3.
              </TableColumn>
              <TableColumn>Royalti</TableColumn>
              <TableColumn>
                <Field
                  // label='Tarif'
                  name='royalti'
                  component={TextfieldMask}
                  className='md-text-right'
                />
              </TableColumn>
            </TableRow>
            <TableRow>
              <TableColumn>
                4.
              </TableColumn>
              <TableColumn>Hadiah dan Penghargaan</TableColumn>
              <TableColumn>
                <Field
                  // label='Tarif'
                  name='hadiahDanPenghargaan'
                  component={TextfieldMask}
                  className='md-text-right'
                />
              </TableColumn>
            </TableRow>
            <TableRow>
              <TableColumn>
                5.
              </TableColumn>
              <TableColumn>Sewa dan Penghasilan Lain</TableColumn>
              <TableColumn>
                <Field
                  // label='Tarif'
                  name='sewaDanPenghasilanLain'
                  component={TextfieldMask}
                  className='md-text-right'
                />
              </TableColumn>
            </TableRow>
            <TableRow>
              <TableColumn>
                6.
              </TableColumn>
              <TableColumn>Jasa Teknik</TableColumn>
              <TableColumn>
                <Field
                  // label='Tarif'
                  name='jasaTeknik'
                  component={TextfieldMask}
                  className='md-text-right'
                />
              </TableColumn>
            </TableRow>
            <TableRow>
              <TableColumn>
                7.
              </TableColumn>
              <TableColumn>Jasa Managemen</TableColumn>
              <TableColumn>
                <Field
                  // label='Tarif'
                  name='jasaManagemen'
                  component={TextfieldMask}
                  className='md-text-right'
                />
              </TableColumn>
            </TableRow>
            <TableRow>
              <TableColumn>
                8.
              </TableColumn>
              <TableColumn>Jasa Konsultan</TableColumn>
              <TableColumn>
                <Field
                  // label='Tarif'
                  name='jasaKonsultan'
                  component={TextfieldMask}
                  className='md-text-right'
                />
              </TableColumn>
            </TableRow>
            <TableRow>
              <TableColumn>
                9.
              </TableColumn>
              <TableColumn>Jasa Lain</TableColumn>
              <TableColumn>
                <Field
                  // label='Tarif'
                  name='jasaLain'
                  component={TextfieldMask}
                  className='md-text-right'
                />
              </TableColumn>
            </TableRow>
          </TableBody>
        </DataTable>
      </div>
      <br/>
      {/* <div className='md-card md-paper md-paper md-paper--1 md-card md-background--card'>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle='Tarif Jasa Lain ex Peraturan Menteri Keuangan No.244/PMK/03/2008
                    ' />
        <Divider/>

        <DataTable plain fixedScrollWrapperStyle={{overflow: 'inherit'}} style={{overflow: 'inherit'}}>
          <TableHeader>
            <TableRow>
              <TableColumn>Kode Jasa.</TableColumn>
              <TableColumn>Nama Jasa</TableColumn>
              <TableColumn>Tarif(%)</TableColumn>
            </TableRow>
          </TableHeader>
          <TableBody>
            {this.props.formData.pasal23Lain.map((d, i)=> {
              return <TableRow>
                <TableColumn>
                  {i+1}.
                </TableColumn>
                <TableColumn>{d.nama}</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name={'lain.'+d.id+'.tarif'}
                    component={TextfieldMask}
                    className='md-text-right'
                  />
                </TableColumn>
              </TableRow>
            })}

          </TableBody>
        </DataTable>
      </div> */}
    </div>
  }

  pasal26Form() {
    return <div className='md-card md-paper md-paper md-paper--1 md-card md-background--card' style={{marginBottom: 20}}>

    <div className="md-grid">
      <Field
        className="md-cell md-cell--6"
        label='Start Date'
        name='start'
        inline={true}
        component={Datepicker}
        />
      <Field
        className='md-cell md-cell--6'
        label='End Date'
        name='end'
        inline={true}
        component={Datepicker} />
    </div>
    <DataTable plain fixedScrollWrapperStyle={{overflow: 'inherit'}} style={{overflow: 'inherit'}}>
       <TableHeader>
         <TableRow>
           <TableColumn>No.</TableColumn>
           <TableColumn>Jenis Penghasilan</TableColumn>
           <TableColumn>Tarif(%)</TableColumn>
         </TableRow>
       </TableHeader>
       <TableBody>
         <TableRow>
           <TableColumn>
             1.
           </TableColumn>
           <TableColumn>Dividen</TableColumn>
           <TableColumn>
             <Field
               // label='Tarif'
               name='dividen'
               component={TextfieldMask}
               className='md-text-right'
             />
           </TableColumn>
         </TableRow>
         <TableRow>
           <TableColumn>
             2.
           </TableColumn>
           <TableColumn>Bunga</TableColumn>
           <TableColumn>
             <Field
               // label='Tarif'
               name='bunga'
               component={TextfieldMask}
               className='md-text-right'
             />
           </TableColumn>
         </TableRow>
         <TableRow>
           <TableColumn>
             3.
           </TableColumn>
           <TableColumn>Royalti</TableColumn>
           <TableColumn>
             <Field
               // label='Tarif'
               name='royalti'
               component={TextfieldMask}
               className='md-text-right'
             />
           </TableColumn>
         </TableRow>
         <TableRow>
           <TableColumn>
             4.
           </TableColumn>
           <TableColumn>Sewa dan Penghasilan Lain</TableColumn>
           <TableColumn>
             <Field
               // label='Tarif'
               name='sewaDanPenghasilanLain'
               component={TextfieldMask}
               className='md-text-right'
             />
           </TableColumn>
         </TableRow>
         <TableRow>
           <TableColumn>
             5.
           </TableColumn>
           <TableColumn>Imbalan</TableColumn>
           <TableColumn>
             <Field
               // label='Tarif'
               name='imbalan'
               component={TextfieldMask}
               className='md-text-right'
             />
           </TableColumn>
         </TableRow>
         <TableRow>
           <TableColumn>
             6.
           </TableColumn>
           <TableColumn>Hadiah dan Penghargaan</TableColumn>
           <TableColumn>
             <Field
               // label='Tarif'
               name='hadiahDanPenghargaan'
               component={TextfieldMask}
               className='md-text-right'
             />
           </TableColumn>
         </TableRow>
         <TableRow>
           <TableColumn>
             7.
           </TableColumn>
           <TableColumn>Pensiun dan Pembayaran Berkala</TableColumn>
           <TableColumn>
             <Field
               // label='Tarif'
               name='pensiunDanPembayaranBerkala'
               component={TextfieldMask}
               className='md-text-right'
             />
           </TableColumn>
         </TableRow>
         <TableRow>
           <TableColumn>
             8.
           </TableColumn>
           <TableColumn>Premi Swap</TableColumn>
           <TableColumn>
             <Field
               // label='Tarif'
               name='premiSwap'
               component={TextfieldMask}
               className='md-text-right'
             />
           </TableColumn>
         </TableRow>
         <TableRow>
           <TableColumn>
             9.
           </TableColumn>
           <TableColumn>Pembebasan Utang</TableColumn>
           <TableColumn>
             <Field
               // label='Tarif'
               name='pembebasanUtang'
               component={TextfieldMask}
               className='md-text-right'
             />
           </TableColumn>
         </TableRow>
         <TableRow>
           <TableColumn>
             10.
           </TableColumn>
           <TableColumn>Penjualan Harta</TableColumn>
           <TableColumn>
             <Field
               // label='Tarif'
               name='penjualanHarta'
               component={TextfieldMask}
               className='md-text-right'
             />
           </TableColumn>
         </TableRow>
         <TableRow>
           <TableColumn>
             11.
           </TableColumn>
           <TableColumn>Premi Asuransi</TableColumn>
           <TableColumn>
             <Field
               // label='Tarif'
               name='premiAsuransi'
               component={TextfieldMask}
               className='md-text-right'
             />
           </TableColumn>
         </TableRow>
         <TableRow>
           <TableColumn>
             12.
           </TableColumn>
           <TableColumn>Penghasilan Penjualan Saham</TableColumn>
           <TableColumn>
             <Field
               // label='Tarif'
               name='penghasilanPenjualanSaham'
               component={TextfieldMask}
               className='md-text-right'
             />
           </TableColumn>
         </TableRow>
         <TableRow>
           <TableColumn>
             12.
           </TableColumn>
           <TableColumn>Penghasilan Kena Pajak BUT Sesudah Dikurangi Pajak</TableColumn>
           <TableColumn>
             <Field
               // label='Tarif'
               name='penghasilanKenaPajakBUT'
               component={TextfieldMask}
               className='md-text-right'
             />
           </TableColumn>
         </TableRow>
       </TableBody>
     </DataTable>
   </div>
  }
}
