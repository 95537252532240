import React, { Component } from 'react'; import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {TabsContainer, Tabs, Tab} from 'react-md';
import { Field } from 'redux-form';
import moment from 'moment'

import AllEbupot26Service from './AllEbupot26.service';
import ListView from '../../../../../components/entity/ListViewEbupot';
import AuthService from '../../../../../services/auth.service'
import ListViewEbupot from '../../../../../components/entity/ListViewEbupot';

@connect((state)=> ({
  ...AllEbupot26Service.stateConnectSetting()(state)
}), AllEbupot26Service.actionConnectSetting())
export default class AllEbupot26View extends ListViewEbupot {
  service=AllEbupot26Service;
  editDialog = false;
  addDialog = false;

  beforeFetch(params) {
    delete params.startDate
    delete params.endDate
    delete params.column
    delete params.total
    delete params.keyword
    params.sortBy = "id"
    params.sort = "id,ASC"
    // params.sortBy = 'id'
    // params.sort = 1
  }

  _barActions = [
    // {
    //   label:'word.create',
    //   iconClassName:'mdi mdi-plus',
    //   onClick:() => this.addItem()
    // },
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => this.fetchData()
    },
  ]

  _tableActions = [
    {
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    },
    {label:"divider", iconClassName:"-"}
  ]

  columns=AllEbupot26Service.columns

}
