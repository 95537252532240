import ApiService from '../../../../services/api.service';

class MasterLawanTransaksiPPService extends ApiService {
  name= 'masterLawanTransaksiPp';
  path= 'master/spt21/lt/pp';

  constructor() {
    super();
    this.init()
  }

  api = {
    findOrCreate: async (data)=> {
      var res = await this.http.post(this.apiLocation+'/'+this.path+'/findOrCreate', data)
      return res.data;
    },
    downloadTemplate: async ()=> {
      var res = await this.http.get(this.apiLocation+'/'+this.path+'/download-template');
      return res;
    },
  }
}


export default new MasterLawanTransaksiPPService();
