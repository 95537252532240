import React, { Component } from 'react';
import { Grid, Cell } from 'react-md';
import { Field, reduxForm , propTypes, formValueSelector } from 'redux-form';
import { validation, Textfield, TextfieldMask, convert } from '../../../../components/form';
import DialogView from '../../../../components/entity/dialog.view';
import MasterTarifPasal17BerlapisService from './MasterTarifPasal17Berlapis.service';

@reduxForm({form: 'master-tarif-pasal-17-berlapis', destroyOnUnmount: false})
export default class MasterTarifPasal17BerlapisDialogView extends DialogView {
  service=MasterTarifPasal17BerlapisService
  initialData = {
  }

  formView() {
    return (
      <Grid style={{padding:'none'}}>
        <Field
          label='Code'
          name='code'
          id="master-tarif-pasal-17-berlapis-code"
          className="md-cell md-cell--12"
          component={Textfield}
          validate={validation.required}
        />
        <Field
          label='Start'
          name='start'
          id="master-tarif-pasal-17-berlapis-start"
          className="md-cell md-cell--6"
          component={TextfieldMask}
          money={true}
          normalize={convert.money}
          validate={validation.required}
        />
        <Field
          label='To'
          name='to'
          id="master-tarif-pasal-17-berlapis-to"
          className="md-cell md-cell--6"
          component={TextfieldMask}
          money={true}
          normalize={convert.money}
          validate={validation.required}
        />
        <Field
          label='(%)'
          name='percent'
          type="number"
          max="100"
          id="master-tarif-pasal-17-berlapis-persen"
          className="md-cell md-cell--6"
          component={Textfield}
          validate={validation.required}
        />
      </Grid>
    )
  }
}
