import React from 'react'
import AuthService from './../../services/auth.service'
import { TextField, Button, FontIcon } from 'react-md';
import authService from './../../services/auth.service';
import izitoast from 'izitoast';
import ContainerPaper from './__components/ContainerPaper'

export default class ResetPassword extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            password: '',
            verifyPassword: ''
        }
      }

    handleInput(name, value){
        var value = value
        var state = this.state
        state[name] = value
        this.setState(state)
    }

    async resetPassword() {
      let search = this.props.location.search
      let key = search.replace('?key=', '')
      var newPassword = this.refs.password.value;
      var newPasswordConfirmation = this.refs.newPasswordConfirmation.value;

      /*if (newPassword !== newPasswordConfirmation) {
        izitoast.error({title: 'Error', message: 'Password doesnt match.'})

        return
      }*/

      let data = {key, newPassword, newPasswordConfirmation}

      try {
        let res = await AuthService.api.forgotPasswordFinish(data);
        this.props.history.push('/account/login')
      } catch (ex) {
        // izitoast.error({title: 'Error', message: 'Failed to reset password.'})
      }

    }

    enter(e) {
      if (e.key === 'Enter') {
        this.login()
      }
    }

    render(){
        return (
            <div className="mpk-full width height account">
                <div className="bg">
                <div className="parallelogram-1"/>
                <div className="parallelogram-2"/>
                <div className="parallelogram-3"/>
                <div className="parallelogram-4"/>
                <div className="parallelogram-5"/>
                </div>
                <div className="account-main mpk-content mpk-position relative" style={{top:0, left: 0}}>
                <div className="account-header mpk-layout flex-none mpk-position absolute">
                    <div className="flex"/>
                    <div style={{position: 'relative', top: 0, right: 0}}>
                    </div>
                </div>
                    <ContainerPaper
                        headerText="Reset Password"
                        deck=""
                    >
                        <form>
                            <div className="title"></div>
                            <TextField
                                ref="password"
                                id="user-password"
                                label="Password"
                                placeholder="Enter your password"
                                lineDirection="center"
                                type="password"
                                onKeyPress={(e) => this.enter(e)}
                                passwordIcon={(
                                  <FontIcon
                                    iconClassName="mdi mdi-eye"
                                    style={{fontSize: 16}}
                                  />
                                )}
                                required={true}
                            />
                            <TextField
                                ref="newPasswordConfirmation"
                                id="user-password-confirmation"
                                label="Konfirmasi Password"
                                placeholder="Enter your password confirmation"
                                lineDirection="center"
                                type="password"
                                onKeyPress={(e) => this.enter(e)}
                                passwordIcon={(
                                  <FontIcon
                                    iconClassName="mdi mdi-eye"
                                    style={{fontSize: 16}}
                                  />
                                )}
                                required={true}
                            />
                            <br />
                            <Button
                                onClick={this.resetPassword.bind(this)}
                                style={{width:"100%"}}
                                flat primary swapTheming
                            >
                                Reset Password
                            </Button>
                            <br />
                            <br />
                            <div className="opt" style={{textAlign: 'center'}}>
                                <a className="mpk-link" href="">Login</a>
                            </div>
                        </form>
                    </ContainerPaper>
                </div>
            </div>
        )
    }
}
