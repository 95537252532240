import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Field, reduxForm } from 'redux-form';
import { Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab } from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Switch as Switch2} from '../../../../../../components/form';

import ListView from '../../../../../../components/entity/listView';
import ColumnService from '../../../../../../services/column.service';
// import ImportService from './../Import/Import.service';
import axios from 'axios';
import izitoast from 'izitoast';
import FormDialog from './ImportPBK15.dialog';
import ImportPBK15Service from './ImportPBK15.service';
import download from "downloadjs";
import ListViewImport from '../../../../../../components/entity/ListViewImport';

@reduxForm({form: 'ImportPBK15Form', destroyOnUnmount: true})
@connect(ImportPBK15Service.stateConnectSetting(), ImportPBK15Service.actionConnectSetting())
export default class ImportPBK15View extends ListViewImport {
  service=ImportPBK15Service
  FormDialog=FormDialog

  category='PBK_15'
  importPath='/api/import/spt/15'
  showAutomatisTab=false
  showPembetulan=true
  // viewType=2

  beforeFetch(params) {
    params["category.equals"] = "PBK_15"
  }

  async handleDownloadTemplate() {
    var report = await ImportPBK15Service.api.downloadTemplate()
    download(report.data, 'TEMPLATE_PBK_15.csv');
  }

}
