import ApiService from '../../../../../services/api.service';

class Master21KapService extends ApiService {
  name= 'Master21Kap';
  path= 'master/spt21/kap';

  constructor() {
    super();
    this.init()
  }
}


export default new Master21KapService();

