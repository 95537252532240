import ApiService from '../../../../../../services/api.service';

class ImportBp25Service extends ApiService {
  name = 'ImportBp25';
  path = 'log-import-csv/page';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api = {
    validasi: async (fileId, page) => {
      if (page) {
        page = page
      } else {
        page = 0
      }
      var res = await this.http.get(this.apiLocation + '/validation-csv', {
        params: {
          fileId: fileId,
          page: page,
          size: 100
        }
      })

      return res.data;
    },
    downloadTemplate: async () => {
      var res = await this.http.get(`${this.apiLocation}/spt25/bp25/download-template`)
      return res;
    },
  }
}


export default new ImportBp25Service();
