import * as CONSTANT from '../../config/constant';

const pph21 = [
  {
  label: {
    id: 'PPh 21',
    en: 'PPh 21'
  },
  children: [{
      label: {
        id: 'Daftar SPT',
        en: 'SPT List'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/SPT_21',
      permission: 'SPT_21'
    },
    {
      label: {
        id: 'BP Final',
        en: 'BP Final'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/BP_21_F',
      permission: 'BP_21_F',
      user: true
    },
    {
      label: {
        id: 'BP Tidak Final',
        en: 'BP Tidak Final'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/BP_21_TF',
      permission: 'BP_21_TF',
      user: true
    },
    {
      label: {
        id: 'BP 1721 A1',
        en: 'BP 1721 A1'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/BP_21_A1',
      permission: 'BP_21_A1',
      user: true
    },
    {
      label: {
        id: 'BP 1721 A2',
        en: 'BP 1721 A2'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/BP_21_A2',
      permission: 'BP_21_A2',
      user: true
    },
    {
      label: {
        id: 'BP Satu Masa',
        en: 'BP Satu Masa'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/ALL_BP_21_SATU_MASA',
      permission: 'BP_21_SATU_MASA',
      user: true
    },
    {
      label: {
        id: 'BP Satu Masa',
        en: 'BP Satu Masa'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/ALL_BP_21_SATU_MASA',
      permission: 'BP_21_SATU_MASA',
      user: true
    },
    {
      label: {
        id: 'BP Satu Tahun',
        en: 'BP Satu Tahun'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/BP_21_SATU_TAHUN',
      permission: 'BP_21_SATU_TAHUN',
      user: true
    },
    {
      label: {
        id: 'Daftar Biaya',
        en: 'Daftar Biaya'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/DAFTAR_BIAYA_21',
      permission: 'DAFTAR_BIAYA_21',
      decemberOnly: true,
      user: true
    },
    {
      label: {
        id: 'Daftar SSP',
        en: 'Daftar SSP'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/ALL_BP_21_SSP',
      permission: 'DAFTAR_SSP_21',
      user: true
    },
    {
      label: {
        id: 'SPT Induk',
        en: 'SPT Induk'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/SPT_INDUK_21',
      permission: 'SPT_INDUK_21',
      user: true
    }
  ]
  },
  {
    label: {
      id: 'Import PPh 21',
      en: 'Import PPh 21'
    },
    children: [
      {
        label: {
          id: 'BP Tidak Final',
          en: 'BP Tidak Final'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_21_TF',
        permission: 'IMPORT_BP_21_TF',
        user: true
      },
      {
        label: {
          id: 'BP Final',
          en: 'BP Final'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_21_F',
        permission: 'IMPORT_BP_21_F',
        user: true
      },
      {
        label: {
          id: 'BP 1721 A1',
          en: 'BP 1721 A1'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_21_A1',
        permission: 'IMPORT_BP_21_A1',
        user: true
      },
      {
        label: {
          id: 'BP 1721 A2',
          en: 'BP 1721 A2'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_21_A2',
        permission: 'IMPORT_BP_21_A2',
        user: true
      },
      {
        label: {
          id: 'Pemotongan Satu Masa Pajak',
          en: 'Pemotongan Satu Masa Pajak'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_21_SATU_MASA',
        permission: 'IMPORT_BP_21_SATU_MASA',
        user: true
      },{
        label: {
          id: 'Pemotongan Satu Masa Pajak TL',
          en: 'Pemotongan Satu Masa Pajak TL'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_21_SATU_MASA_TIDAK_LEBIH',
        permission: 'IMPORT_BP_21_SATU_MASA_TIDAK_LEBIH',
        user: true
      },
      {
        label: {
          id: 'SSP Pasal 21',
          en: 'SSP Pasal 21'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/SSP_21',
        permission: 'IMPORT_SSP_21',
        user: true
      },
      {
        label: {
          id: 'Daftar Biaya Pasal 21',
          en: 'Daftar Biaya Pasal 21'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/DAFTAR_BIAYA_21',
        permission: 'IMPORT_DAFTAR_BIAYA_21',
        user: true
      },
      {
        label: {
          id: 'Induk Pasal 21',
          en: 'Induk Pasal 21'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/INDUK_21',
        permission: 'IMPORT_INDUK_21',
        user: true
      },
    ]
  }
]

const setup = [{
  label: {
    id: 'Master Data Tarif Pajak Pasal 21',
    en: 'Master Data Tarif Pajak Pasal 21'
  },
  children: [
    {
      label: {
        id: 'Pasal 17 Berlapis',
        en: 'Pasal 17 Berlapis'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/master/pasal-21-pasal-17-berlapis',
      permission: '',
      admin: true
    },
    {
      label: {
        id: 'Biaya Jabatan',
        en: 'Biaya Jabatan'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/master/pasal-21-biaya-jabatan',
      permission: '',
      admin: true
    },
    {
      label: {
        id: 'Biaya Pensiun',
        en: 'Biaya Pensiun'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/master/pasal-21-biaya-pensiun',
      permission: '',
      admin: true
    },
    {
      label: {
        id: 'PTKP',
        en: 'PTKP'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/master/pasal-21-ptkp',
      permission: '',
      admin: true
    },
    {
      label: {
        id: 'Tarif Pensiun',
        en: 'Tarif Pensiun'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/master/pasal-21-pensiun',
      permission: '',
      admin: true
    },
    {
      label: {
        id: 'Upah Harian',
        en: 'Upah Harian'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/master/pasal-21-upah-harian',
      permission: '',
      admin: true
    },
    {
      label: {
        id: 'Upah Kumulatif',
        en: 'Upah Kumulatif'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/master/pasal-21-upah-kumulatif',
      permission: '',
      admin: true
    },
    {
      label: {
        id: 'Final Pesangon',
        en: 'Final Pesangon'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/master/pasal-21-final-pesangon',
      permission: '',
      admin: true
    },
    {
      label: {
        id: 'Honor/Imbalan PNS',
        en: 'Honor/Imbalan PNS'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/master/pasal-21-honor-pns',
      permission: '',
      admin: true
    }
  ]
  },
  {
    label: {
      id: 'Master Data Kode SPT Pasal 21',
      en: 'Master Data Kode SPT Pasal 21'
    },
    children: [
      {
        label: {
          id: 'Status Karyawan A1',
          en: 'Status Karyawan A1'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-status-karyawan-a1',
        permission: '',
        admin: true
      },
      {
        label: {
          id: 'Status Karyawan A2',
          en: 'Status Karyawan A2'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-status-karyawan-a2',
        permission: '',
        admin: true
      },
      {
        label: {
          id: 'KAP',
          en: 'KAP'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-kap',
        permission: '',
        admin: true
      },
      {
        label: {
          id: 'KJS',
          en: 'KJS'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-kjs',
        permission: '',
        admin: true
      },
      {
        label: {
          id: 'SSP Description',
          en: 'SSP Description'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-ssp-description',
        permission: '',
        admin: true
      },
      {
        label: {
          id: 'Golongan A2',
          en: 'Golongan A2'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-golongan-a2',
        permission: '',
        admin: true
      }
    ]
  },
  {
    label: {
      id: 'Administrator',
      en: 'Administrator'
    },
    children: [{
        label: {
          id: 'User',
          en: 'User'
        },
        iconClassName: 'mdi mdi-contacts',
        path: '/user',
        admin: true
      },
      {
        label: {
          id: 'Permissions',
          en: 'Perizinan'
        },
        iconClassName: 'mdi mdi-contacts',
        path: '/permissions',
        admin: true
      },
      // {
      //   label: {
      //     id: 'Akses',
      //     en: 'Access'
      //   },
      //   iconClassName: 'mdi mdi-contacts',
      //   path: '/access',
      //   admin: true
      // },
      {
        label: {
          id: 'Akses Kontrol',
          en: 'Access Control'
        },
        iconClassName: 'mdi mdi-contacts',
        path: '/access-control',
        admin: true
      }
    ]
  }
]

const organization = [{
  label: {
    id: 'Organisasi',
    en: 'Organization'
  },
  children: [{
    label: {
      id: 'Perusahaan',
      en: 'Company'
    },
    iconClassName: 'mdi mdi-contacts',
    path: '/company',
    admin: true
  },{
    label: {
      id: 'Organisasi',
      en: 'Organization'
    },
    iconClassName: 'mdi mdi-contacts',
    path: '/organization',
    admin: true
  },{
    label: {
      id: 'Flow Approval',
      en: 'Flow Approval'
    },
    iconClassName: 'mdi mdi-contacts',
    path: '/mainFlow',
    admin: true
  }]
  },
  // ORGANISASI OWNER
  {
    label: {
      id: 'Organisasi',
      en: 'Organization'
    },
    children: [{
        label: {
          id: 'Organisasi',
          en: 'Organization'
        },
        iconClassName: 'mdi mdi-view-list',
        rootPath: '/company',
        path: '/organization',
        permission: 'organization',
        owner: true
    },{
      label: {
        id: 'Flow Approval',
        en: 'Flow Approval'
      },
      iconClassName: 'mdi mdi-contacts',
      path: '/mainFlow',
      owner: true
    }]
  },
  {
  label: {
    id: 'Pengguna',
    en: 'User'
  },
  children: [{
      label: {
        id: 'Pengguna',
        en: 'User'
      },
      rootPath: '/company',
      iconClassName: 'mdi mdi-view-list',
      path: '/user',
      permission: 'user',
      owner: true
  },{
    label: {
      id: 'Pemilik',
      en: 'Owner'
    },
    iconClassName: 'mdi mdi-view-list',
    rootPath: '/company',
    path: '/owner',
    permission: 'owner',
    owner: true
  }]
}]

const none = [
  //   {
  //   label: {
  //     id: 'Home',
  //     en: 'Home'
  //   },
  //   children: [{
  //     label: {
  //       id: 'Dashboard',
  //       en: 'Dashboard'
  //     },
  //     iconClassName: 'mdi mdi-view-list',
  //     path: '/',
  //     permission: ''
  //   }]
  // }
  ]

  export default {
    //root
    pph21,
    setup,
    none,
    organization,
  }
