import ApiService from '../../../../services/api.service';

class MasterCustomImportHeaderDetailService extends ApiService {
  name= 'masterCustomImportHeaderDetail';
  path= 'master/custom-import-header-details';
  constructor() {
    super();
    this.init()
  }

  api = {
    findAllByHeaderId: async (id) => {
      return await this.http.get(`${this.apiLocation}/${this.path}/byHeader/${id}`)
    }
  }
}

export default new MasterCustomImportHeaderDetailService();
