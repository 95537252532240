import React, { Component } from 'react'; import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {TabsContainer, Tabs, Tab} from 'react-md';
import { Field } from 'redux-form';

import ClientService from './Client.service';
import ListView from '../../../../../components/entity/ListViewEbupot';
import AuthService from '../../../../../services/auth.service'
import ListViewEbupot from '../../../../../components/entity/ListViewEbupot';
import ClientDialog from './Client.dialog.view';
import ApplicationService from '../Application/Application.service';

@connect((state)=> ({
  ...ClientService.stateConnectSetting()(state)
}), ClientService.actionConnectSetting())
export default class ClientView extends ListViewEbupot {
  service=ClientService;
  FormDialog=ClientDialog
  rootPath = this.props.match.url
  translate=false
  fetchOption() {
    return {
      path: `${this.service.path}/${this.props.match.params.appId}/application`
    }
  }

  titleHeader() {
    return 'LIST CLIENT '+ this.state.appData.name.toUpperCase();
  }

  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      appData: {name: ''}
    }
  }

  async componentDidMount() {
    let app = await ApplicationService.api.findOne(this.props.match.params.appId);
    this.setState({appData: app.data})
  }

  _tableActions = [
    {
      label:"Delete",
      iconClassName:"mdi mdi-delete",
      onClick:(item, callback) => this.deleteItem(item, callback),
      confirmation:{
        title:"sentence.custom.deleteItem",
        message:"sentence.custom.deleteItemConfirmation"
      }
    }
  ]

  columns=ClientService.columns

  view1() {
    return (
      <this.viewContainer>
        <div>
          {this.commandBar()}
          {this.belowTopBar()}
        </div>

        <div className="flex mpk-layout mpk-padding-N all">
          {this.tableView()}
          {this.tableSetting()}

          <this.FormDialog
            appId={this.props.match.params.appId}
            height={400}
            visible={this.state.showForm}
            add={this.props.tableActions.addDataItem}
            formData={this.state.formData}
            onSuccess={()=> this.fetchData()}
            onClose={() => this.setState({showForm:false})}
          />

          {this.dialogConfirm()}
          {this.additionalForm()}
        </div>
      </this.viewContainer>
    )
  }

  deleteItem = async (item, callback) => {
    try {
      await this.service.api.delete(item.clientId);
      callback()
      await this.fetchData()
    } catch(e) {
      callback(e, e)
    }
  }

}
