import React from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  Tab,
  Tabs,
  SelectionControlGroup,
  Checkbox,
  Button
} from 'react-md';

import FormView from '../../../../../components/entity/form.view';
import BP21A1Service from './BP_21_A1.service';
import NomorBPService from '../nomorBP.service';
import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Radio} from '../../../../../components/form';
import UtilService from '../../../../../services/utils.service'
import Master21StatusKaryawanA1Service from '../Master21StatusKaryawanA1/Master21StatusKaryawanA1.service';
import SPT21SignerService from '../SPT21Signer/SPT21Signer.service';
import NegaraService from './../../../Master/MasterNegara/MasterNegara.service';
import NpwpService from './../../NpwpService';
import FormViewSpt from '../../../../../components/entity/form.view.spt';
import LawanTransaksiDialog from '../../../../../components/entity/LawanTransaksi.dialog';
import MasterLawanTransaksiA1Service from '../../../Master/MasterLawanTransaksiA1/MasterLawanTransaksiA1.service';
import SPT_INDUK_21Service from '../SPT_INDUK_21/SPT_INDUK_21.service';

@reduxForm({form: 'BP_21_A1_Form', destroyOnUnmount: true})
@connect((state) => ({
  access: state.entity.access.api.find,
  organization: state.auth.currentOrganization,
  spt: state.auth.currentSPT,
  formData:state.form.BP_21_A1_Form
}))
export default class BP21A1Form extends FormViewSpt {
  service=BP21A1Service
  viewType =2;


  typingTimeout=1000;
  typingObj=null;

  constructor(props) {
    super(props);

    this.state= {
      ...this.state,
      showCalculateProgress: false,
      npwpNotValid: true,
      no13Disabled: true,
      lkDialog: false
    }
  }

  async npwpValidation(evt, value){

    if(this.typingObj) clearTimeout(this.typingObj);
    //this.typingObj = setTimeout(async ()=> {
      if(value){
        var res = await NpwpService.api.validate(value)
        if(res.valid){
          this.setState({npwpNotValid: false})

          var lk = await MasterLawanTransaksiA1Service.api.page({
            'npwp.equals': value,
            'organizationId.equals': this.props.spt.data.organization.id
          })

          if(lk.data) {
            var formData = {...this.props.formData.values};

            if(lk.data.length > 0) {
              var d = lk.data[0];
              formData.bagA = {
                "npwp": d.npwp,
                "nik": d.nik,
                "nama": d.nama,
                "alamat": d.alamat,
                "jenisKelamin": d.jenisKelamin,
                "statusPtkp": d.statusPtkp,
                "jmlPtkp": d.jmlTanggungan,
                "namaJabatan": d.jabatan,
                "karyawanAsing": d.wpLuarNegeri,

              };

              if (d.kodeNegara) {
                formData.kodeNegara =  {id: d.kodeNegara};
              }

              if (formData.bagA.karyawanAsing === null) {
                formData.bagA.karyawanAsing = false
              }
            } else {
              formData.bagA = {
                "npwp": value,
                "nik": "",
                "nama": "",
                "alamat": "",
                "jenisKelamin": "",
                "statusPtkp": "TK",
                "jmlPtkp": 0,
                "namaJabatan": "",
                "karyawanAsing": false
              }
              this.toast.warning({title: "Lawan Transaksi", message: `Lawan Transaksi dengan npwp ${value} tidak ditemukan`})
            }

            this.props.initialize(formData);
          }
        } else {
          this.setState({npwpNotValid: true})
          this.toast.warning({title: "Validasi Npwp", message: "npwp tidak valid"})
        }
      }
    //}, 500);

  }

  initialData={
  "no": "",
  "masaPerolehanAwal": 1,
  "masaPerolehanAkhir": 12,
  "npwp": "000000000000000",
  "nama": "",
  "kodeNegara": null,
  grossUp: false,
  "bagA": {
    "npwp": null,
    "nik": "",
    "nama": "",
    "alamat": "",
    "jenisKelamin": "",
    "statusPtkp": "TK",
    "jmlPtkp": 0,
    "namaJabatan": "",
    "karyawanAsing": false
  },
  "bagB": {
		"kodeObjekPajak": "21-100-01",
		"gajiPensiun": 0,
		"tunjanganPph": 0,
		"tunjanganLainya": 0,
		"honorarium": 0,
		"premiAsuransi": 0,
		"penerimaanNatura": 0,
		"tantiemBonus": 0,
		"jmlBruto": 0,
		"biayaJabatan": 0,
		"iuranPensiun": 0,
		"jmlPengurangan": 0,
		"jmlPenghasilanNetto": 0,
		"penghasilanNettoSebelumnya": 0,
		"penghasilanSetahunkan": 0,
		"ptkp": 0,
		"penghasilanKenaPajak": 0,
		"pph21AtasPenghasilan": 0,
		"pph21DipotongSebelumnya": 0,
		"pph21Terutang":0,
		"pph21Dilunasi": 0
  },
  "bagC": {
    // "npwp": "000000008000000",
    // "nama": "",
    // "tanggal": "2018-02-15",
    // "ttd": ""
  },
  "spt21": {  },
  // "status": {

  // }
  }

  async initData() {
    if(this.props.match.params.id == 'new') {
      let nomorBP = await NomorBPService.api.get(this.props.spt.data.organization.id, "BP_21_A1")

      let signer = {};

      try {
        signer = await SPT_INDUK_21Service.api.getSigner({
          orgId: this.props.spt.data.organization.id,
          tgl  : moment().format("YYYY-MM-DD")
        })
      } catch(e) {

      }


      this.initialData = {
        ...this.initialData,
        no: '1.1-'+UtilService.date.MM(this.props.spt.data.month)+'.'+UtilService.date.YY(this.props.spt.data.year)+'-'+nomorBP.data.no,
        bagC:{
          nama: signer.namaPenandaTanganBp,
          npwp: signer.npwpPenandaTanganBp,
          tanggal: new Date()
        },
        nama: this.props.organization.name,
        npwp: this.props.organization.npwp,
        spt21: this.props.spt.data
      }
      this.props.initialize(this.initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id);
      let data = res.data

      if (data.bagA.karyawanAsing === null) {
        data.bagA.karyawanAsing = false
      }

      this.props.initialize(data);
      // this.npwpValidation(null, res.data.bagA.npwp)
      this.setState({npwpNotValid: false})
    }
  }

  async calculate(field, value, e, f, d) {
    await this.props.change(`bagB.${field}`, value);

    var formData = this.props.formData.values;

    if (field === 'status') {
      if (value.code === '05') {
        this.setState({no13Disabled: false})
      }
      else {
        this.setState({no13Disabled: true})
      }
    }

    if (field === 'masaPerolehanAkhir') {
      let nomorBuktiPotong = formData.no

      let awal = nomorBuktiPotong.substr(0, 6)

      let bulan = '' + value
      bulan = bulan.padStart(2, '0')

      let replace = '1.1-' + bulan

      nomorBuktiPotong = nomorBuktiPotong.replace(awal, replace)

      formData.no = nomorBuktiPotong;
      this.props.initialize(formData)
    }

    if(this.typingObj) clearTimeout(this.typingObj);

    this.typingObj = setTimeout(async ()=> {
      this.setState({showCalculateProgress: true})

      try {
        var execute = true;

        if(field) {
          if(field.search('bagA.') >= 0) {
            formData.bagA[field.replace('bagA.', '')] = value
          } else if (field.search('bagB.') >= 0) {
            if(formData.bagB[field] || formData.bagB[field] === 0) {
              formData.bagB[field] = value;
            }
          } else {
            formData[field] = value;
          }
        }

        var statusCode = null;
        if(formData.status) {
          statusCode = formData.status.code;
        }

        if(!(formData.masaPerolehanAwal === 1 && formData.masaPerolehanAkhir === 12) && !statusCode){
          execute = false;
        }

        var param = {
          objekHitungA1: {
            masaPerolehanAwal: formData.masaPerolehanAwal,
            masaPerolehanAkhir: formData.masaPerolehanAkhir,
            tanpaNpwp: false,
            statusA1: statusCode,
            grossUp: formData.grossUp,
            partA: {
              statusPtkp: formData.bagA.statusPtkp,
              jmlPtkp: formData.bagA.jmlPtkp,
            },
            partB: formData.bagB
          },
          spt21: this.props.spt.data
        }


        if(formData.bagA.npwp === '000000000000000' || formData.bagA.npwp === "") param.objekHitungA1.tanpaNpwp = true;

        param.spt21.orgId = param.spt21.organization.id;

        if(execute) {
          var res = await BP21A1Service.api.calculate(param);

          if(res) {
            formData.bagB = res.partB;
            this.props.initialize(formData)
          }
        }

      } catch(e) {}

      this.setState({showCalculateProgress: false})
    }, this.typingTimeout)
  }

  async beforeSave(value) {
    return true;
  }

  async afterSave(res, val) {
    var formData = this.props.formData.values;

    var data = {
      ...formData.bagA,
      organization: this.props.spt.data.organization,
      wpLuarNegeri: formData.bagA.karyawanAsing,
      jabatan: formData.bagA.namaJabatan,
      jmlTanggungan: formData.bagA.jmlPtkp,
    }
    if(data.id) delete data.id;
    await MasterLawanTransaksiA1Service.api.findOrCreate(data)
  }

  formView() {
    var formData = {bagB: {}};
    if(this.props.formData && this.props.formData.values) formData = this.props.formData.values

    var masaOptions = [
      {id:1, name: 1}, {id:2, name: 2}, {id:3, name: 3}, {id:4, name: 4}, {id:5, name: 5}, {id:6, name: 6},
      {id:7, name: 7}, {id:8, name: 8}, {id:9, name: 9}, {id:10, name: 10}, {id:11, name: 11}, {id:12, name: 12}
    ]

    var hideNegara = false;
    if(this.props.formData){
      if(this.props.formData.values){
        if(this.props.formData.values.bagA.karyawanAsing){
          hideNegara = true
        }
      }
    }
    //total bruto
    return (
      <div>
        <Card>
          <div className="md-grid">
            <Field
              label='No.Bukti Potong'
              name='no'
              className="md-cell md-cell--3"
              component={Textfield}
              validate={validation.required}
            />
            <Field
              label='Tanggal Bukti Potong'
              name='bagC.tanggal'
              className="md-cell md-cell--3"
              component={Datepicker}
              validate={validation.required}

              onDateChange = { async (d)=> {
                let signer = null;

                try {
                  signer = await SPT_INDUK_21Service.api.getSigner({
                    orgId: this.props.spt.data.organization.id,
                    tgl  : moment(d).format("YYYY-MM-DD")
                  })
                } catch (error) {
                }

                this.props.change('bagC.nama', signer.namaPenandaTanganBp)
                this.props.change('bagC.npwp', signer.npwpPenandaTanganBp)
              }}
            />
            <Field
              label='Masa Perolehan Awal'
              name='masaPerolehanAwal'
              id="BP21A1Form-dariMasa"
              className="md-cell md-cell--3"
              component={Searchfield}
              validate={validation.required}
              options={masaOptions}
              onChange={(e, v)=> this.calculate('masaPerolehanAwal', v)}
            />
            <Field
              label='Masa Perolehan Akhir'
              name='masaPerolehanAkhir'
              id="BP21A1Form-sampaiMasa"
              className="md-cell md-cell--3"
              component={Searchfield}
              validate={validation.required}
              options={masaOptions}
              onChange={(e, v)=> this.calculate('masaPerolehanAkhir', v)}
            />

             {!(formData.masaPerolehanAwal === 1 && formData.masaPerolehanAkhir === 12) && <Field
              label='Status'
              name='status'
              className="md-cell md-cell--12"
              component={Searchfield}
              validate={validation.required}
              service={Master21StatusKaryawanA1Service}
              valueField='parent'
              onChange={(e, v)=> this.calculate('status', v)}
            />}
            <Field
              label='Referensi'
              name='referensi'
              id="BP21A1Form-sampaiMasa"
              className="md-cell md-cell--12"
              component={Textfield}
            />
          </div>
        </Card>
        <br/>

        <Card>
          <div className='md-grid'>
            <Field
              label='NPWP Pemotong'
              name='npwp'
              className="md-cell md-cell--12"
              component={TextfieldMask}
              disabled={true}
              maskFormat="##.###.###.#-###-###"
              mask="_"
              length="15"
              validate={validation.required}
            />
            <Field
              label='Nama Pemotong'
              name='nama'
              disabled={true}
              className="md-cell md-cell--12"
              component={Textfield}
              validate={validation.required}
            />
          </div>
        </Card>
        <br/>

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='A. IDENTITAS PENERIMA PENGHASILAN YANG DIPOTONG ' />
          <Divider/>

          <LawanTransaksiDialog
            spt={this.props.spt.data}
            onSelect={(d)=> {
              var formData = {...this.props.formData.values};
              if(!d.wpLuarNegeri) d.wpLuarNegeri = false;

              formData.bagA = {
                "npwp": d.npwp,
                "nik": d.nik,
                "nama": d.nama,
                "alamat": d.alamat,
                "email": d.email,
                "jenisKelamin": d.jenisKelamin,
                "statusPtkp": d.statusPtkp,
                "jmlPtkp": d.jmlTanggungan,
                "namaJabatan": d.jabatan,
                "karyawanAsing": d.wpLuarNegeri,
              };

              if (d.kodeNegara) {
                formData.kodeNegara =  {id: d.kodeNegara};
              }

              this.setState({npwpNotValid: false})

              this.props.initialize(formData);

            }}
            service={MasterLawanTransaksiA1Service}
            visible={this.state.lkDialog}
            onHide={()=> this.setState({lkDialog: false})}
          />

          <div className='md-grid'>
            <div className="md-cell md-cell--6">
              <div className='mpk-layout'>
                <Field
                  className='flex'
                  label='NPWP'
                  name='bagA.npwp'
                  // onChange={(e, v)=> this.calculate('bagA.npwp', v)}
                  onChange={async (e, v) => {
                    await this.npwpValidation(e, v);
                    await this.calculate('bagA.npwp', v);
                  }}
                  component={TextfieldMask}
                  maskFormat="##.###.###.#-###-###"
                  mask="_"
                  length="15"
                  validate={validation.required}
                />
                <Button icon primary swapTheming style={{marginTop: 10}} onClick={()=> this.setState({lkDialog: true})}>search</Button>
              </div>

              <Field
                label='Nama'
                name='bagA.nama'
                component={Textfield}
                disabled={this.state.npwpNotValid}
                validate={validation.required}
              />
              <Field
                label='NIK'
                name='bagA.nik'
                id="BP21A1Form-nik"
                disabled={this.state.npwpNotValid}
                component={Textfield}
              />
              <Field
                label='Email'
                name='bagA.email'
                id="BP21A1Form-email"
                disabled={this.state.npwpNotValid}
                component={Textfield}
              />
              <Field
                label='Alamat'
                name='bagA.alamat'
                disabled={this.state.npwpNotValid}
                component={Textfield}
              />
            </div>

            <div className="md-cell md-cell--6">
              <Field
                label='Jenis Kelamin'
                name='bagA.jenisKelamin'
                disabled={this.state.npwpNotValid}
                component={Searchfield}
                options={[{id: 'L', name: 'Laki-laki'}, {id:'P', name: 'Perempuan'}]}
              />
              <Field
                label='Status PTKP'
                onChange={(e, v)=> this.calculate('bagA.statusPtkp', v)}
                name='bagA.statusPtkp'
                disabled={this.state.npwpNotValid}
                component={Searchfield}
                options={[{id: 'TK', name: 'TK'}, {id: 'K', name: 'K'}, {id: 'K/I', name: 'K/I'}, {id: 'HB', name: 'HB'}]}
              />
              <Field
                label='Jumlah Tanggungan'
                name='bagA.jmlPtkp'
                onChange={(e, v)=> this.calculate('bagA.jmlPtkp', v)}
                disabled={this.state.npwpNotValid}
                component={Searchfield}
                options={[{id: 0, name: '0'}, {id: 1, name: '1'}, {id: 2, name: '2'}, {id: 3, name: '3'}]}
              />

              <Field
                label='Nama Jabatan'
                name='bagA.namaJabatan'
                disabled={this.state.npwpNotValid}
                component={Textfield}
              />

              <Field
                label='Warga Negara Asing'
                name='bagA.karyawanAsing'
                disabled={this.state.npwpNotValid}
                component={Switch}
              />

              {hideNegara &&
                <Field
                  label='Kode Negara Domisili'
                  name='kodeNegara'
                  disabled={this.state.npwpNotValid}
                  component={Searchfield}
                  service={NegaraService}
                  valueField="parent"
                />
              }

              {/* <Field
                label='Penandatangan dan Kuasa'
                name='penandaTangan'
                id="BP21A1Form-penandaTangan"
                component={Switch}
              /> */}
            </div>
          </div>
        </Card>
        <br/>

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='B. Rincian Penghasilan Dan Perhitungan PPh Pasal 21' />
          <Divider/>
          <DataTable plain>
            <TableHeader>
              <TableRow>
                <TableColumn style={{width: 5}}></TableColumn>
                <TableColumn>URAIAN</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn className='md-text-right'>JUMLAH (Rp)</TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableColumn colSpan="2">
                  KODE OBJEK PAJAK:
                </TableColumn>

                <TableColumn>
                  <Field
                    component={Radio}
                    controlStyle={{display: 'inline-block'}}
                    name="bagB.kodeObjekPajak"
                    type="radio"
                    key={formData.bagB.kodeObjekPajak}
                    value={formData.bagB.kodeObjekPajak}
                    controls={[{
                      label: '21-100-01',
                      value: '21-100-01',
                    }, {
                      label: '21-100-02',
                      value: '21-100-02',
                    }]}
                  />
                </TableColumn>
                <TableColumn></TableColumn>
              </TableRow>

              <TableRow><TableColumn colSpan='4'><b>PENGHASILAN BRUTO</b></TableColumn></TableRow>

              <TableRow>
                <TableColumn>1.</TableColumn>
                <TableColumn>Gaji/Pensiun atau THT/JHT</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.gajiPensiun'
                    onChange={(e, v)=> this.calculate('gajiPensiun', v, this)}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>2.</TableColumn>
                <TableColumn>Tunjangan PPH</TableColumn>
                <TableColumn>
                  <Field
                    label='Gross Up'
                    onChange={(e, v)=> this.calculate('grossUp', v)}
                    name='grossUp'
                    component={Switch}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.tunjanganPph'
                    onChange={(e, v)=> this.calculate('tunjanganPph', v)}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>3.</TableColumn>
                <TableColumn>Tunjangan Lainya, Uang Lembur dan sebagainya</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.tunjanganLainya'
                    onChange={(e, v)=> this.calculate('tunjanganLainya', v)}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>4.</TableColumn>
                <TableColumn>Honorarium dan imbalan lain sejenisnya</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.honorarium'
                    onChange={(e, v)=> this.calculate('honorarium', v)}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>5.</TableColumn>
                <TableColumn>Premi asuransi yang dibayar pemberi kerja</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.premiAsuransi'
                    onChange={(e, v)=> this.calculate('premiAsuransi', v)}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>6.</TableColumn>
                <TableColumn>Penerimaan Dalam Bentuk Natura atau Kenikmatan Lainya yang dikenakan pemotongan PPH Pasal 21</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.penerimaanNatura'
                    onChange={(e, v)=> this.calculate('penerimaanNatura', v)}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>7.</TableColumn>
                <TableColumn>Tantiem, Bonus, Gratifikasi, Jasa Produksi dan THR</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.tantiemBonus'
                    onChange={(e, v)=> this.calculate('tantiemBonus', v)}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>8.</TableColumn>
                <TableColumn>Jumlah Penghasilan Bruto (1 + 2 + 3 + 4 + 5 + 6 + 7)</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    disabled={true}
                    name='bagB.jmlBruto'
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow><TableColumn colSpan='4'><b>PENGURANGAN</b></TableColumn></TableRow>

              <TableRow>
                <TableColumn>9.</TableColumn>
                <TableColumn>Biaya Jabatan / Biaya Pensiun</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.biayaJabatan'
                    onChange={(e, v)=> this.calculate('biayaJabatan', v)}
                    className='md-text-right'
                    disabled={true}
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>10.</TableColumn>
                <TableColumn>Iuran Pensiun atau iuran THT/JHT</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.iuranPensiun'
                    onChange={(e, v)=> this.calculate('iuranPensiun', v)}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>11.</TableColumn>
                <TableColumn>Jumlah Pengurangan (9 + 10)</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    disabled={true}
                    name='bagB.jmlPengurangan'
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow><TableColumn colSpan='4'><b>PERHITUNGAN PPh PASAL 21</b></TableColumn></TableRow>
              <TableRow>
                <TableColumn>12.</TableColumn>
                <TableColumn>Jumlah Penghasilan Neto (8 - 11)</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.jmlPenghasilanNetto'
                    disabled={true}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>13.</TableColumn>
                <TableColumn>Penghasilan Neto Masa Sebelumnya</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.penghasilanNettoSebelumnya'
                    disabled={this.state.no13Disabled}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>14.</TableColumn>
                <TableColumn>Jumlah Penghasilan Neto untuk PPH Pasal 21 (Setahunkan/ Disetahunkan)</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.penghasilanSetahunkan'
                    disabled={true}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>15.</TableColumn>
                <TableColumn>Penghasilan Tidak Kena Pajak (PTKP)</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.ptkp'
                    disabled={true}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>16.</TableColumn>
                <TableColumn>Penghasilan Kena Pajak Setahun/Disetahunkan</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.penghasilanKenaPajak'
                    disabled={true}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>17.</TableColumn>
                <TableColumn>PPH Pasal 21 atas Penghasilan Kena Pajak Setahun/Disetahunkan</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.pph21AtasPenghasilan'
                    disabled={true}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>18.</TableColumn>
                <TableColumn>PPH Pasal 21 yang telah dipotong Masa sebelumnya</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.pph21DipotongSebelumnya'
                    onChange={(e, v)=> this.calculate('pph21DipotongSebelumnya', v)}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>19.</TableColumn>
                <TableColumn>PPH Pasal 21 Terhutang</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.pph21Terutang'
                    disabled={true}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>20.</TableColumn>
                <TableColumn>PPH Pasal 21 dan PPH Pasal 26 yang telah dipotong/dilunasi</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.pph21Dilunasi'
                    disabled={true}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>
            </TableBody>
          </DataTable>
        </Card>
        <br/>

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='C. IDENTITAS PEMOTONG' />
          <Divider/>
          <div className='md-grid'>
            <Field
              label='NPWP Pemotong'
              name='bagC.npwp'
              className="md-cell md-cell--12"
              component={TextfieldMask}
              maskFormat="##.###.###.#-###-###"
              mask="_"
              length={15}
              validate={validation.required}
            />
            <Field
              label='Nama Pemotong'
              name='bagC.nama'
              className="md-cell md-cell--12"
              component={Textfield}
              validate={validation.required}
            />
          </div>
        </Card>
      </div>
    )
  }
}
