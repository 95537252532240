import ApiService from '../../../../services/api.service';

class MasterLawanTransaksi22Service extends ApiService {
  name= 'masterLawanTransaksi22';
  path= 'spt22/lt/22';

  constructor() {
    super();
    this.init()
  }

  api = {
    findOrCreate: async (data)=> {
      var res = await this.http.post(this.apiLocation+'/'+this.path+'/findOrCreate', data)
      return res.data;
    }
  }
}


export default new MasterLawanTransaksi22Service();
