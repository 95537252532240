import ApiService from '../../../../../services/api.service'

class KodeBuktiPotong26Service extends ApiService {
  name= 'KodeBuktiPotong26'
  path= 'ebupot/kode-bukti-potong-26'
  advancedSearch=true

  constructor(){
    super()
    this.init()
    this.initApi()
  }

}

export default new KodeBuktiPotong26Service();
