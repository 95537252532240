import ApiService from './../../../../../../services/api.service'
import http from './../../../../../../services/http.service'
import moment from 'moment'
import React from 'react';
import { Chip } from 'react-md';

class Espt23Service extends ApiService {
  name= 'Espt23'
  path= 'ebupot/espt23'
  advancedSearch=true

  constructor(){
    super()
    this.init()
    this.initApi()
  }

  columns = [
    {isSortable: true, label: "word.id", searchField: "organization.id", value: "organization.id", show:false, isSearchable:true},
    {
      searchField: "status",
      label: "word.statusSpt",
      type: 'func',
      value: (d)=> {
        var style = {
          background: 'grey'
        }
        switch (d.status) {
          case 'CREATED':
            style.background = 'grey'
            break;
          case 'SUBMIT':
            style.background = '#2196F3'
            break;
          case 'POSTING':
            style.background = '#D32F2F'
            break;
          case 'SENDING':
            style.background = '#FFEB3B'
            break;
          case 'FINISH':
            style.background = '#4CAF50'
            break;
          default:
            break;
        }
        return <Chip label={d.status} style={style} />
      },
      className: "mpk-font-size-S", show:true, isSearchable:true},
    {isSortable: true, label: "word.name", searchField: "organization.nama", value: "organization.nama", show:true, isSearchable:true},
    {isSortable: true, label: "word.npwp", searchField: "organization.npwp", value: "organization.npwp", show:true, isSearchable:true},
    {isSortable: true, label: "word.month", searchField: "masa", value: "masa", show:true, isSearchable:true, type: 'number'},
    {isSortable: true, label: "word.year",  searchField: "tahun", value: (d)=> d.tahun, show:true, isSearchable:true, type: 'func'},
    {isSortable: true, label: "word.pembetulan", searchField: "pembetulan", value: "pembetulan", show:true, isSearchable:true, type: 'number'},
    {isSortable: true, label: "word.message", searchField: "message", value: "message", show:true, isSearchable:true},
    {label: "word.createdBy", searchField: "createdBy", value: "createdBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.createdDate", searchField: "createdDate", value: (d)=> {
      if(d){
        return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
      } else {
        return "-"
      }
    }, type: 'func', isDefaultSort:true, isSortable:true, show:true, isSearchable:true},
    {label: "word.lastModifiedBy", searchField: "lastModifiedBy", value: "lastModifiedBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.lastModifiedDate", searchField: "lastModifiedDate", value: (d)=> {
      if(d){
        return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
      } else {
        return "-"
      }
    }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
  ]

  api = {
    bulkSending: async (id)=> {
      let res = await http.post(`/api/ebupot/espt23/sending`, id)
      return res
    },
    bulkCheck: async (id)=> {
      let res = await http.post(`/api/ebupot/espt23/check`, id)
      return res
    },
    bulkRevision: async (id)=> {
      let res = await http.post(`/api/ebupot/espt23/revision`, id)
      return res
    },
    bulkPosting: async (id)=> {
      let res = await http.post(`/api/ebupot/espt23/posting`, id)
      return res
    },
    bulkDelete: async (id)=> {
      let res = await http.delete(`/api/ebupot/espt23/delete`, {
        data: id
      })
      return res
    },
    revisionSpt: async (id)=> {
      let res = await http.get(`/api/ebupot/espt23/${id}/revision`)

      return res
    },
    querySpt23: async (param)=> {
      // {
      //   organizationId,
      //   masa,
      //   tahun,
      //   pembetulan
      // }
      let res = await http.get(`/api/ebupot/espt23/query`, {
        params: param
      })
      return res
    },
    submitXml: async (item)=> {
      let res = await http.get(`/api/ebupot/espt23/${item.id}/generate`, {
        params: {
          rebuild: item.rebuild
        }
      })
      return res
    },
    getCheckById: async (item)=> {
      let res = await http.get(`/api/ebupot/espt23/${item.id}/check`)
      return res
    },
    getDebtById: async (item)=> {
      let res = await http.get(`/api/ebupot/espt23/${item.id}/debt`)
      return res
    },
    getDetailById: async (item)=> {
      let res = await http.get(`/api/ebupot/espt23/${item.id}/detail`)
      return res
    },
    getGenerateById: async (item)=> {
      let res = await http.get(`/api/ebupot/espt23/${item.id}/generate`)
      return res
    },
    getInfoById: async (item)=> {
      let res = await http.get(`/api/ebupot/espt23/${item.id}/info`)
      return res
    },
    getPostingById: async (item)=> {
      let res = await http.get(`/api/ebupot/espt23/${item.id}/posting`)
      return res
    },
    getSendingById: async (item)=> {
      let res = await http.get(`/api/ebupot/espt23/${item.id}/sending`)
      return res
    },
    cetakBpe: async (id) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${id}/bpe`, {
        responseType: 'blob'
      })

      return res;
    },
    summarySpt: async (data) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/summary/excel?orgIds=${data.npwp}&masa1=${data.masa1}&masa2=${data.masa2}&tahun1=${data.tahun1}&tahun2=${data.tahun2}`, {
        responseType: 'blob',
        data: data
      })

      return res;
    },
    rekapSpt: async (data) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/summary/rekap/excel?orgIds=${data.npwp}&masa1=${data.masa1}&masa2=${data.masa2}&tahun=${data.tahun}`, {
        responseType: 'blob',
        data: data
      })

      return res;
    },
    summarySspSpt: async (data) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/summary/ssp/excel?orgIds=${data.npwp}&masa1=${data.masa1}&masa2=${data.masa2}&tahun1=${data.tahun1}&tahun2=${data.tahun2}`, {
        responseType: 'blob',
        data: data
      })

      return res;
    },
    summaryPisah: async (data) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/summary/pisah/excel?orgIds=${data.npwp}&masa1=${data.masa1}&masa2=${data.masa2}&tahun1=${data.tahun1}&tahun2=${data.tahun2}`, {
        responseType: 'blob',
        data: data
      })

      return res;
    },
    summaryGabung: async (data) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/summary/gabung/excel?orgIds=${data.npwp}&masa1=${data.masa1}&masa2=${data.masa2}&tahun1=${data.tahun1}&tahun2=${data.tahun2}`, {
        responseType: 'blob',
        data: data
      })

      return res;
    },
    cetakDaftarBp: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/report`)
      return res;
    },
    cetakSptInduk: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/induk/${sptId}/report`)
      return res;
    },
    exportSpt: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/export`)
      return res
    }
  }

}

export default new Espt23Service();


// /api/ebupot/espt23/{id}/check
// check bupot xml by spt id.

// GET /api/ebupot/espt23/{id}/debt
// getDebtById

// GET /api/ebupot/espt23/{id}/detail
// getDetailById

// GET /api/ebupot/espt23/{id}/generate
// Generate bupot xml by spt id.

// GET /api/ebupot/espt23/{id}/info
// getInfoById

// GET /api/ebupot/espt23/{id}/posting
// posting by spt id.

// GET /api/ebupot/espt23/{id}/sending
