import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Field, reduxForm } from 'redux-form';
import { Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab } from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Switch as Switch2} from '../../../../../../components/form';

import ListView from '../../../../../../components/entity/listView';
import ColumnService from '../../../../../../services/column.service';
// import ImportService from './../Import/Import.service';
import axios from 'axios';
import izitoast from 'izitoast';
import FormDialog from './ImportInduk4a2PenghasilanTertentu.dialog';
import ImportInduk4a2PenghasilanTertentuservice from './ImportInduk4a2PenghasilanTertentu.service';
import download from "downloadjs";
import ListViewImport from '../../../../../../components/entity/ListViewImport';

@reduxForm({form: 'ImportInduk4a2PenghasilanTertentuForm', destroyOnUnmount: true})
@connect(ImportInduk4a2PenghasilanTertentuservice.stateConnectSetting(), ImportInduk4a2PenghasilanTertentuservice.actionConnectSetting())
export default class ImportInduk4a2PenghasilanTertentuView extends ListViewImport {
  service=ImportInduk4a2PenghasilanTertentuservice
  FormDialog=FormDialog

  category='INDUK_PENGHASILAN_TERTENTU_4A2'
  importPath='/api/import/spt/4a2'
  showAutomatisTab=false
  showPembetulan=true
  // viewType=2

  beforeFetch(params) {
    params["category.equals"] = "INDUK_PENGHASILAN_TERTENTU_4A2"
  }

  async handleDownloadTemplate() {
    var report = await ImportInduk4a2PenghasilanTertentuservice.api.downloadTemplate()
    download(report.data, 'TEMPLATE_INDUK_4A2_PENGHASILAN_TERTENTU.csv');
  }

}
