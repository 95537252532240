import ApiService from '../../../../services/api.service';

class ValidationLogService extends ApiService {
  name= 'ValidationLog';
  path= 'log/validation';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api={
  }
}

export default new ValidationLogService();
