import ApiService from '../../../../../services/api.service';

class ImportService extends ApiService {
  name= 'Import21';
  path= 'import';
  pathImportUser= 'import/user';
  pathImportOrganization= 'import/organization';
  pathImportDownloadExcel= 'import/download/excel';
  pathEbillingImport= 'ebilling/import';

  constructor() {
    super();
    this.init()
  }

  api= {
    downloadExcel: async (category)=> {
      let formData = new FormData();
      formData.append("category", category)

      var res = await this.http.post(this.apiLocation + '/' + this.pathImportDownloadExcel, formData, {
        responseType: 'blob'
      });

      return res;
    }
  }
}


export default new ImportService();
