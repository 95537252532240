import React, { Component } from 'react'; import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {TabsContainer, Tabs, Tab, Button} from 'react-md';
import { Field, reduxForm, initialize } from 'redux-form';
import axios from 'axios'

import LogFileValidasiService from './LogFileValidasi.service';
import ListView from '../../../../../components/entity/ListViewEbupot';
import AuthService from '../../../../../services/auth.service'
import ListViewEbupot from '../../../../../components/entity/ListViewEbupot';
import OrganizationService from '../Organization/Organization.service'
import {validation, FileInputNext, Searchfieldv2, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Radio, convert, CheckboxSelection} from '../../../../../components/form';
import iziToast from 'izitoast';

@reduxForm({form: 'LogFileValidasiForm', destroyOnUnmount: true})
@connect((state)=> ({
  ...LogFileValidasiService.stateConnectSetting()(state)
}), LogFileValidasiService.actionConnectSetting())
export default class LogFileValidasiView extends ListViewEbupot {
  service=LogFileValidasiService;
  addDialog=false
  editDialog=false

  fetchOption() {
    try {
      if(this.props.dialogParam.status == "CHECK_DJP"){
        return {
          path: 'ebupot/log/file/'+this.props.dialogParam.id+'/check-detail'
        }
      } else {
        return {
          path: 'ebupot/log/file/'+this.props.dialogParam.id+'/detail'
        }
      }
    } catch(e){
      return {
        path: 'ebupot/log/file/'+this.props.dialogParam.id+'/detail'
      }
    }
  }

  componentDidMount(){
    document.getElementsByClassName('md-paper')[1].classList.remove('md-paper--1')
  }

  beforeFetch(params) {
    delete params.startDate
    delete params.endDate
    delete params.column
    delete params.total
    delete params.keyword
    // params.sortBy = 'id'
    // params.sort = 1
  }

  belowTopBar(){
    return (
      <div></div>
    )
  }

  columns=LogFileValidasiService.columns

  _barActions = [
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => this.fetchData()
    },
  ]

  _tableActions = []

  // NEW TAB MENU
  async hapusPencarian(){
    try {
      var params = Object.assign({}, params=this.props.table.params)
      this.fetchData(params)
      await this.props.dispatch(initialize(this.service.name+'_search', {}));
    } catch(e){
    }
  }

  async handleSearching(values){
    try {
      var formData = this.props.tableFilter2
      var params = Object.assign({}, params=this.props.table.params)

      if(formData.sheet){
        params['sheet.contains'] = formData.sheet
      }

      if(formData.line){
        params['line.contains'] = formData.line
      }

      if(formData.message){
        params['message.contains'] = formData.message
      }

      this.fetchData(params)
    } catch(e){
      iziToast.info({
        title: "Pencarian",
        message: "Data tidak ditemukan, " + e.message
      })
    }
  }

  defaultSearchForm(){
    const { handleSubmit, submitting, valid, pristine } = this.props;
    return (
      <div className="md-grid" style={{overflow: 'auto'}}>
        <Field
          label     = 'Sheet'
          name      = 'sheet'
          className = "md-cell md-cell--12"
          component = {Textfield}
        />
        <Field
          label     = 'Line'
          name      = 'line'
          className = "md-cell md-cell--12"
          component = {Textfield}
        />
        <Field
          label     = 'Message'
          name      = 'message'
          className = "md-cell md-cell--12"
          component = {Textfield}
        />
        <hr />
        <Button raised primary className="md-cell md-cell--12" onClick={handleSubmit(this.handleSearching.bind(this))}>Cari</Button>
        <hr />
        <Button raised primary className="md-cell md-cell--12" onClick={handleSubmit(this.hapusPencarian.bind(this))}>Hapus Pencarian</Button>
      </div>
    )
  }
  // yg ditambah
  // NEW TAB MENU

}
