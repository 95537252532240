
import React, { Component } from 'react'; import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import routes from './routes';

import AuthService from './../../../../../../services/auth.service'
import ESPT23Service from './../SPT23/SPT23.service'
import OrganizationService from './../../Organization/Organization.service'

@connect(state => ({
  organization: state.auth.currentOrganization,
  user: state.auth.user,
  spt: state.auth.currentSPT
}))
export default class Ebupot23Main extends Component{
  async componentDidMount() {
    try {
      let spt = await ESPT23Service.api.findOne(this.props.match.params.sptId);
      AuthService.api.changeSPT({
        type: '23_26',
        data: spt.data
      }, this.props.dispatch)
      AuthService.api.changeMenuType('ebupot', this.props.dispatch)
      let organization = await OrganizationService.api.findOne(spt.data.organization.id)
      AuthService.api.changeOrganization(organization.data, this.props.dispatch)
    } catch(e) {
    }
  }
  render() {
    return(
        <div className="mpk-layout column fill">
          {routes(this.props)}
        </div>
    )
  }
}
