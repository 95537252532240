import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Field, reduxForm } from 'redux-form';
import { Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab } from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Switch as Switch2} from '../../../../components/form';

import ListView from '../../../../components/entity/listView';
import axios from 'axios';
import izitoast from 'izitoast';
import download from "downloadjs";
import PengaturanNoBPService from './PengaturanNoBP.service';
import PengaturanNoBpDialog from './PengaturanNoBp.dialog';

@reduxForm({form: 'PengaturanNOBP_Form', destroyOnUnmount: true})
@connect((state)=> ({
  ...PengaturanNoBPService.stateConnectSetting()(state),
  user: state.auth.user,
  company: state.auth.currentCompany
}),PengaturanNoBPService.actionConnectSetting())
export default class PengaturanNoBPView extends ListView {
  service=PengaturanNoBPService
  translate=false
  FormDialog=PengaturanNoBpDialog

  titleHeader() {
    return `Pengaturan Nomor BP Pasal ${this.props.match.params.pasal}`;
  }

  fetchOption() {
    return {
      path: `spt${this.props.match.params.pasal.toLowerCase()}/nobupot/page`
    }
  }

  beforeFetch(params) {
    // params["category.equals"] = "EFILING"
  }


  columns() {
    if(this.props.match.params.pasal === '21') {
      return [
        {label: "word.name",  value: "organization.name", show:true, isSearchable:true},
        {label: "word.npwp",  value: "organization.npwp", show:true, isSearchable:true},
        {label: "word.bpA1",  value: "bpA1", show:true, isSearchable:true},
        {label: "word.bpA2",  value: "bpA2", show:true, isSearchable:true},
        {label: "word.bpFinal",  value: "bpFinal", show:true, isSearchable:true},
        {label: "word.bpTidakFinal",  value: "bpTidakFinal", show:true, isSearchable:true}
      ]
    } else {
      return [
        {label: "word.name",  value: "organization.name", show:true, isSearchable:true},
        {label: "word.npwp",  value: "organization.npwp", show:true, isSearchable:true},
        {label: "word.prefix",  value: "prefix", show:true, isSearchable:true},
        {label: "word.suffix",  value: "suffix", show:true, isSearchable:true},
        {label: "word.inc",  value: "inc", type:'boolean', show:true, isSearchable:true},
        {label: "word.mode",  value: "mode", show:true, isSearchable:true}
      ]
    }
  }

  _barActions = [{
    label:'Refresh',
    iconClassName:'mdi mdi-refresh',
    onClick:() => {

      window.location.hash = window.location.hash.split('?')[0]
      this.fetchData()
    }
  },]
}
