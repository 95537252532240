import ApiService from '../../../../services/api.service';

class MasterCustomImportService extends ApiService {
  name= 'masterCustomImport';
  path= 'master/custom-import-headers/page';
  constructor() {
    super();
    this.init()
  }

  api = {
    findAllByCategory: async (category) => {
      return await this.http.get(`${this.apiLocation}/master/custom-import-headers/byCategory/${category}`)
    },
    delete: async (id) => {
      return await this.http.delete(`${this.apiLocation}/master/custom-import-headers/${id}`)
    },
    deleteAll: async (ids) => {
      return await this.http.post(`${this.apiLocation}/master/custom-import-headers/delete`, ids)
    },
  }
}

export default new MasterCustomImportService();
