import React, { Component } from 'react';
import { Grid, Cell,
  Button,
  Divider
} from 'react-md';
import { connect } from 'react-redux';
import { Field, reduxForm , propTypes, formValueSelector } from 'redux-form';
import { DialogForm } from 'react-mpk';
import {validation, Textfield, Searchfield, Switch} from '../../../../components/form';
import FormView from '../../../../components/entity/form.view';
import MainFlowService from './MainFlow.service';
import OrganizationService from '../../Administrator/Organization/Organization.service';
import MainFlowDialog from './MainFlow.dialog'
import _ from 'lodash';

import {DiagramEngine, DiagramModel, DefaultNodeModel, DiagramWidget, DefaultPortModel, DefaultLinkModel, NodeModel} from 'storm-react-diagrams';
import CompanyService from '../../Administrator/Company/Company.service';

@reduxForm({form: 'mainFlow_form', destroyOnUnmount: true})
@connect((state) => ({
  company: state.auth.currentCompany
}))
export default class MainFlowForm extends FormView {
  service=MainFlowService
  viewType=2

  engine:DiagramEngine;
  model:DiagramModel;

  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      nodeDialog: false,
      isEditDialog: false,
      selectedItem: {},
      editType: 'State',
    }

    this.engine = new DiagramEngine();
    this.engine.installDefaultFactories();
    this.model = new DiagramModel()

    var startNode = new DefaultNodeModel("Start", "#BDBDBD")
    var startPort = startNode.addOutPort("Out");
    startNode.extras = {
      id:startNode.id,
      name: 'Start',
      stateType: 'Start',
      status: 'WAITING'
    }
    startNode.setPosition(100, 100);

    var endApprovedNode = new DefaultNodeModel("End Approved", "#66BB6A");
    let endApprovedPort = endApprovedNode.addInPort("In");
    endApprovedNode.extras = {
      id: endApprovedNode.id,
      name: 'End Approved',
      stateType: 'End',
      status: 'FINISH'
    }
    endApprovedNode.setPosition(300, 100);

    var endRejectedNode = new DefaultNodeModel("End Rejected", "#EF5350");
    let endRejectedPort = endRejectedNode.addInPort("In");
    endRejectedNode.extras = {
      id: endRejectedNode.id,
      name: 'End Rejected',
      stateType: 'End',
      status: 'PROCESS'
    }
    endRejectedNode.setPosition(300, 200);

    var link1 = startPort.link(endRejectedPort);
    link1.addLabel("Reject");
    link1.extras.name = "Reject";
    link1.id = link1.id;
    link1.extras.fromState = {
      id: startNode.id
    }
    link1.extras.toState = {
      id: endRejectedNode.id
    }
    var link2 = startPort.link(endApprovedPort);
    link2.addLabel("Approve")
    link2.extras.name = "Approve";
    link2.extras.id = link2.id;
    link2.extras.fromState = {
      id: startNode.id
    }
    link2.extras.toState = {
      id: endApprovedNode.id
    }

    // this.model.addAll(startNode, endApprovedNode, endRejectedNode, link1, link2);

    this.engine.setDiagramModel(this.model);

  }

  async initData() {
    if(this.props.match.params.id == 'new') {

      this.props.initialize({
        company: this.props.company,
        formType: 'Global'
      });
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id);
      this.props.initialize(res.data);

      this.model = new DiagramModel();
      var json = JSON.parse(res.data.jsonModel);
      this.model.deSerializeDiagram(json, this.engine);
      json.links.forEach((d)=> {
        if(this.model.links[d.id].labels[0]) {
          this.model.links[d.id].labels[0].label = d.extras.name
        } else {

        }
      })

      this.engine.setDiagramModel(this.model);
      this.forceUpdate()
    }
  }

  async beforeSave(val) {

    var diagramJson = this.model.serializeDiagram();

    val = {
      mainFlow: val,
      states: [],
      actions: []
    }

    val.mainFlow.jsonModel = JSON.stringify(diagramJson)

    diagramJson.nodes.forEach((d:DefaultNodeModel, i)=> {
      val.states.push(d.extras)
    })

    diagramJson.links.forEach((d:DefaultLinkModel, i) => {
      if(!d.extras.fromState) {
        d.extras.fromState = {
          id: d.source
        }
        d.extras.toState = {
          id: d.target
        }
      }
      val.actions.push(d.extras)
    })
    return val;
  }

  showAddNodeDialog() {
    this.setState({nodeDialog: true, isEditDialog: false, selectedItem: {}, editType: 'State'});
  }
  showEditNodeDialog() {
    var items = this.model.getSelectedItems();
    if(items.length > 0) {
      var item = items[0];
      if(item.sourcePort) {
        this.setState({nodeDialog: true, isEditDialog: true, selectedItem: item, editType: 'Action'});
      } else {
        this.setState({nodeDialog: true, isEditDialog: true, selectedItem: item, editType: 'State'});
      }

    }
    this.model.clearSelection()
  }

  handleNodeDialog(val, callback) {

    if(this.state.isEditDialog && this.state.selectedItem.id) {

      if(this.state.editType === 'State') {
        var item:DefaultNodeModel = this.model.getNode(this.state.selectedItem.id)
        item.extras = _.cloneDeep(val);


        var color = "#BDBDBD";
        if(val.status == 'FINISH') color = "#66BB6A"
        if(val.status == 'PROCESS') color = "#EF5350"
        item.color = color;
        item.name = val.name;
      } else {
        var item:DefaultLinkModel = this.model.getLink(this.state.selectedItem.id);
        item.extras = _.cloneDeep(val);
        item.name = val.name;
        if(item.labels.length == 0) {
          item.addLabel(val.name)
        } else {
          item.labels[0].label = val.name;
        }
      }


      this.forceUpdate()

    } else {
      var color = "#BDBDBD";

      if(val.status == 'FINISH') color = "#66BB6A"
      if(val.status == 'PROCESS') color = "#EF5350"

      var newNode = new DefaultNodeModel(val.name, color);
      if(val.stateType != 'End') newNode.addOutPort("Out");
      if(val.stateType != 'Start') newNode.addInPort("In");
      newNode.setPosition(0, 0);

      newNode.extras = _.cloneDeep(val);
      newNode.extras.id = newNode.id;

      this.model.addNode(newNode);
    }

    callback()
  }

  organizationField() {
    if(this.props.company) {
      if(this.props.company.id) {
        return <Field
        label='Organization'
        name='organization'
        className="md-cell md-cell--6"
        valueField='parent'
        fetchOption={{
          path:OrganizationService.name+'/company/'+this.props.company.id
        }}
        service={OrganizationService}
        component={Searchfield}
        />
      } else {
        return <Field
        label='Organization'
        name='organization'
        className="md-cell md-cell--6"
        valueField='parent'
        service={OrganizationService}
        component={Searchfield}
        />
      }
    }
  }

  companyField() {
    if(this.props.company && !this.props.company.id) {
      return <Field
      label='Company'
      name='company'
      className="md-cell md-cell--6"
      valueField='parent'
      apiPath='page'
      viewField='nama'
      disabled={this.props.company.id}
      service={CompanyService}
      component={Searchfield}
      />
    }
  }

  formView() {
    return (
      <div>
        <div className='md-card md-paper md-paper md-paper--1 md-card md-background--card'>
        <Grid>
          {this.companyField()}
          {/* {this.organizationField()} */}
          <Field
            label='Nama'
            name='name'
            className="md-cell md-cell--6"
            component={Textfield}
            validate={validation.required}
          />
          {/* <Field
            label='Jenis Form'
            name='formType'
            className="md-cell md-cell--6"
            component={Searchfield}
            options={[
              {id: 'Global', name: 'Global'},
              {id: 'PPH 21', name: 'PPH 21'},
              {id: 'PPH 23/26', name: 'PPH 23/26'},
              {id: 'PPH 4(2)', name: 'PPH 4(2)'}
            ]}
            validate={validation.required}
          /> */}
          <Field
            label='Description'
            name='description'
            className="md-cell md-cell--6"
            component={Textfield}
            // validate={validation.required}
          />
            {/* <Field
            label='Default'
            name='useDefault'
            component={Switch}
            /> */}
        </Grid>
        </div>
        <br/>
        {this.diagramView()}
      </div>
    )
  }

  diagramView() {
    return (
      <div className='md-card md-paper md-paper md-paper--1 md-card mpk-bluepring-bg'>
        <MainFlowDialog
          dialogType={this.state.editType}
          isEditDialog={this.state.isEditDialog}
          formData={this.state.selectedItem}
          visible={this.state.nodeDialog}
          onClose={()=> this.setState({nodeDialog: false})}
          onSubmit={(value, callback)=> this.handleNodeDialog(value, callback)}
        />
        <div className='mpk-commandbar mpk-layout align-center justify-between mpk-border bottom solid dark'>
          <Button icon onClick={() => this.showAddNodeDialog()}>add</Button>

          <Divider vertical />

          <Button icon onClick={()=> this.showEditNodeDialog() }>edit</Button>

          <div className='flex' />
          {/* <Button flat onClick={()=> console.log(this.model.serializeDiagram())}>Test</Button> */}
          <Button icon onClick={()=> this.engine.zoomToFit()} >zoom_out_map</Button>
        </div>
        <DiagramWidget diagramEngine={this.engine} />
      </div>
    )
  }
}

