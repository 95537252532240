import ApiService from '../../../services/api.service';

class NpwpService extends ApiService {
  name= 'NpwpValidator';
  path= 'check-npwp';

  api= {
    validate: async (npwp) => {
      var res = await this.http.get(this.apiLocation+'/check-npwp', {
          params: {
            npwp: npwp
          }
      })

      return res.data;
    }
  }
  constructor() {
    super()
    this.init()
    this.initApi()
  }
}


export default new NpwpService();
