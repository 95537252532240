import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation} from '../../../../components/form';
import FormView from '../../../../components/entity/form.view';
import BuktiPenerimaanService from '../BuktiPenerimaan/BuktiPenerimaan.service';
import iziToast from 'izitoast';
import download from 'downloadjs';

@reduxForm({form: 'PaymentNotificationDetailSspForm', destroyOnUnmount: true, initialValues: {}})
@connect((state) => ({
  access: state.entity.access.api.find,
  formData: getFormValues('PaymentNotificationDetailSspForm')(state)
}))
export default class PaymentNotificationDetailSspForm extends FormView {
  translate = false
  service = BuktiPenerimaanService

  viewType=2

  constructor(props){
    super(props);
    this.state = {
      ...this.state
    }

    this.state.data = {}
  }

  titleHeader() {
    return `Detail BPN`;
  }

  _barItem() {
    return (
      <div className='actions mpk-layout'>
        <Button icon tooltipLabel="Cetak BPN" onClick={() => this.print()}>print</Button>
        <Button icon tooltipLabel="Back" onClick={()=> {this.props.history.goBack()}}>arrow_back</Button>
      </div>
    )
  }

  async initData() {
    let res = await this.service.api.findByMasterEtax(this.props.match.params.id);

    this.setState({data: res});
  }

  print = async () => {
    let dialog = await this.context.showDialog((props, res, rej) =>({
      title: 'Cetak',
      okText: 'Cetak',
      width: 600,
      text: 'Apakah anda yakin ingin cetak BPN?',
    }));

    if (dialog) {
      try {
        let data = this.state.data

        let report = await this.service.api.print(data.buktiPenerimaan.id)
        let filename = report.headers.filename

        download(report.data, filename);
      } catch (e) {
        iziToast.warning({
          title: e.name,
          message: e.message
        })
      }
    }
  }

  formView() {
    let data = this.state.data

    if(data.buktiPenerimaan) {
      return (
        <div>
          <Card>
            <CardTitle title='' style={{padding: '10px 16px'}} subtitle='Data Pembayaran'/>

            <Divider/>

            <Grid noSpacing={true}>
              <Cell size={6}>
                <List>
                  <ListItem
                    primaryText="Kode SSP"
                    secondaryText={data.buktiPenerimaan.taxIdNo}
                  />
                  <ListItem
                    primaryText="Order Party Ref No"
                    secondaryText={data.buktiPenerimaan.orderPartyRefNo}
                  />
                  <ListItem
                    primaryText="TRX No Ref"
                    secondaryText={data.buktiPenerimaan.trxRefNo}
                  />
                </List>
              </Cell>

              <Cell size={6}>
                <List>
                  <ListItem
                    primaryText="NTPN"
                    secondaryText={data.buktiPenerimaan.taxNtpn}
                  />
                  <ListItem
                    primaryText="NTB"
                    secondaryText={data.buktiPenerimaan.taxNtb}
                  />
                  <ListItem
                    primaryText="Tanggal Dieksekusi"
                    secondaryText={data.buktiPenerimaan.executedDt}
                  />
                </List>
              </Cell>
            </Grid>
          </Card>

          <br/>

          <Card>
            <CardTitle title='' style={{padding: '10px 16px'}} subtitle='Data Setoran'/>

            <Divider/>

            <Grid noSpacing={true}>
              <Cell size={6}>
                <List>
                  <ListItem
                    primaryText="NPWP"
                    secondaryText={data.buktiPenerimaan.masteretax.suratsetoran.npwp}
                  />
                  <ListItem
                    primaryText="Nama"
                    secondaryText={data.buktiPenerimaan.taxPayerName}
                  />
                  <ListItem
                    primaryText="Kota"
                    secondaryText={data.buktiPenerimaan.taxPayerCity }
                  />
                  <ListItem
                    primaryText="Alamat"
                    secondaryText={data.buktiPenerimaan.taxPayerAddress}
                  />
                  <ListItem
                    primaryText="ID Billing"
                    secondaryText={data.buktiPenerimaan.taxbillingId}
                  />
                </List>
              </Cell>

              <Cell size={6}>
                <List>
                  <ListItem
                    primaryText="Jenis Pajak"
                    secondaryText={data.buktiPenerimaan.taxJenisPajak}
                  />
                  <ListItem
                    primaryText="Jenis Setoran"
                    secondaryText={data.buktiPenerimaan.taxJenisSetoran}
                  />
                  <ListItem
                    primaryText="Bulan"
                    secondaryText={data.buktiPenerimaan.periodMonth}
                  />
                  <ListItem
                    primaryText="Tahun"
                    secondaryText={data.buktiPenerimaan.periodYear}
                  />
                  <ListItem
                    primaryText="Jumlah Setor"
                    secondaryText={data.buktiPenerimaan.masteretax.suratsetoran.jumlahsetor}
                  />
                </List>
              </Cell>
            </Grid>
          </Card>
        </div>
      )
    }
  }
}
