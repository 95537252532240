import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Field, reduxForm } from 'redux-form';
import {Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab, Chip} from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Switch as Switch2} from '../../../../../components/form';

import Email21LogService from './Email21Log.service';
import ListView from '../../../../../components/entity/listView';
import ColumnService from '../../../../../services/column.service';
import ImportService from './../Import/Import.service';
import axios from 'axios';
import izitoast from 'izitoast';
import download from "downloadjs";
import BP21TFService from "../BP_21_TF/BP_21_TF.service";

// @reduxForm({form: 'EMAIL_LOG', destroyOnUnmount: true})
@connect((state)=> ({
  ...Email21LogService.stateConnectSetting()(state),
  spt: state.auth.currentSPT,
  user: state.auth.user
}), Email21LogService.actionConnectSetting())
export default class Email21Log extends ListView {
  service=Email21LogService
  FormDialog=()=> <div/>
  // viewType=2

  constructor(props){
    super(props);
    this.state = {
      showTableSetting:true,
      showDialogConfirmDeleteSelected:false,
      showForm:false,
      formData: {},
      data: [],
      onProgress: false,
      isError: false,
      errorMessage: ''
    }
  }

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='Judul'
          name='subject__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Recipient'
          name='to__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='WAITING'
          name='status__in_WAITING'
          className="md-cell md-cell--6"
          component={this.ig.Checkbox}
        />
        <this.ig.Field
          label='PROCESS'
          name='status__in_PROCESS'
          className="md-cell md-cell--6"
          component={this.ig.Checkbox}
        />
        <this.ig.Field
          label='FINISH'
          name='status__in_FINISH'
          className="md-cell md-cell--6"
          component={this.ig.Checkbox}
        />
        <this.ig.Field
          label='FAILED'
          name='status__in_FAILED'
          className="md-cell md-cell--6"
          component={this.ig.Checkbox}
        />
      </this.SearchContainer>
    )
  }

  beforeFetch(params) {
    // params["category.equals"] = "BP_21_A1"
    if(this.props.match.params.companyId) params["companyId.equals"] = this.props.match.params.companyId;
    if(this.props.user.company && this.props.user.company.id) params["companyId.equals"] = this.props.user.company.id;
  }

  async handleExportAll() {

    if (this.props.table.selected.length > 0) {
      var selected = this.props.table.selected
      var ids = [];
      selected.map(function (i) {
        ids.push(i.id)
      })

      var res = await this.service.api.exportSummary(ids);
      var filename = res.headers.filename
      download(res.data, filename);
    }
  }

  async handleResend() {
    if (this.props.table.selected.length > 0) {
      var selected = this.props.table.selected
      var ids = [];

      selected.map(function (i) {
        ids.push(i.id)
      })

      var res = await this.service.api.resend(ids);

      let {message} = res.data

      iziToast.success({
        title: 'Sukses',
        message: message
      })
    }
  }

  replaceHtmlTag(content) {
    let tagsToReplace = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;'
    };

    content = content.replace('&', '&amp;')
    content = content.replace('<', '&lt;')
    content = content.replace('>', '&gt;')

    return content
  }

  _barActions = [
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => this.fetchData()
    },
    {
      label:'word.exportCsv',
      iconClassName:'mdi mdi-file-excel',
      disabledFunc:() => this.props.table.selected.length === 0,
      onClick:() => this.handleExportAll()
    },
    {
      label:'word.resend',
      iconClassName:'mdi mdi-send',
      disabledFunc:() => this.props.table.selected.length === 0,
      onClick:() => this.handleResend()
    },
  ]

  _tableActions = []

  columns=[
    {isSortable: true, label: "word.company",  value: (d)=> { return d.company.nama }, type:"func", isDefaultSort:false, show:false, isSearchable:true},
    {isSortable: true, label: "word.sender",  value: "from", isDefaultSort:false, show:true, isSearchable:true},
    {isSortable: true, label: "word.recipient",  value: "to", isDefaultSort:false, show:true, isSearchable:true},
    {isSortable: true, label: "word.subject",  value: "subject", isDefaultSort:false, show:true, isSearchable:true},
    {isSortable: true, label: "word.content", type: 'func', value: (d) => {
      return d.content
    }, isDefaultSort:false, show:false, isSearchable:true},
    {isSortable: true, label: "word.pathFile",  value: "pathFile", isDefaultSort:false, show:false, isSearchable:true},
    {isSortable: true, label: "word.status",  type: 'func', value: (d)=> {
        var style = {
          background: 'grey'
        }

        switch (d.status) {
          case 'PROCESS':
            style.background = '#2196F3'
            break;
          case 'FINISH':
            style.background = '#4CAF50'
            break;
          case 'FAILED':
            style.background = '#f44336'
            break;
          default:
            break;
        }

        return <div style={{width: 200}}>
          <Chip label={d.status} style={style} />
        </div>
      }, isDefaultSort:false, show:true, isSearchable:true},
    {isSortable: true, label: "word.message",type: "func",  value: (d) => {
        let message = d.message
        if (message) {
          message = message.replace('<', '(')
          message = message.replace('>', ')')
        }
        return message
      },  isDefaultSort:false, show:true, isSearchable:true},
    {isSortable: true, label: "word.scheduledDate",  value: "scheduledDate", isDefaultSort:false, show:true, isSearchable:true},
    {isSortable: true, label: "word.executedDate",  value: "executedDate", isDefaultSort:false, show:true, isSearchable:true},
    {isSortable: true, label: "word.startDate",  value: "startDate",  type: "date", show:true, isSearchable:true},
    {isSortable: true, label: "word.endDate",  value: "endDate", type: "date", isDefaultSort:false, show:true, isSearchable:true},
  ]

}
