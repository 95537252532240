import ApiService from '../../../../services/api.service';

class FileUploadService extends ApiService {
  name= 'FileUpload';
  path= 'file/upload';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api = {
    multiUpload: async (data)=> {
      var res = await this.http.post(this.apiLocation+`/file/upload-multiple`, data);
      return res;
    },
    upload: async (data)=> {
      var res = await this.http.post(this.apiLocation+`/file/upload`, data);
      return res;
    }
  }
}

export default new FileUploadService();
