import React from 'react'
import {connect} from 'react-redux';
import { Field, reduxForm, getFormValues} from 'redux-form';
import moment from 'moment';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  CardActions,
  Button,
  LinearProgress
} from 'react-md';
import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Checkbox} from '../../../../../components/form';

@reduxForm({form: 'BP_26_Form_Dokumen', destroyOnUnmount: true})
@connect((state) => ({
  access      : state.entity.access.api.find,
  organization: state.auth.currentOrganization,
  spt         : state.auth.currentSPT,
  formData    : getFormValues('BP_26_Form_Dokumen')(state)
}))
export default class BPEbupot26DokumenForm extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            data: []
        } 
    }

    static contextTypes = {
        showDialog: React.PropTypes.func,
    };    

    async handleForm(){
        var res = await this.context.showDialog(()=> ({
            title: null,
            initialValue: {},
            width: 800,
            height: 500,
            contentProps: {
              style: {
                maxHeight: 300
              }
            },
            text: (
              <div>
                <Field
                  label='Nama Dokumen'
                  name='name'
                  className="md-cell md-cell--12"
                  component={Textfield}
                  validate={validation.required}
                />
                <Field
                  label='Nomor Dokumen'
                  name='nomor'
                  className="md-cell md-cell--12"
                  component={Textfield}
                  validate={validation.required}
                />
                <Field
                  label='Tanggal Dokumen'
                  name='tanggal'
                  className="md-cell md-cell--12"
                  component={Datepicker}
                  validate={validation.required}
                />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
              </div>
            )
        }))
        if(res){
            var data = this.state.data
            data.push(res)
            this.setState({ data: data })
        }
    }

    async handleHapus(index){
        var data = this.state.data
        data.splice(index, 1)
        this.setState({ data: data })
    }

    render(){
        return (
            <div className="md-grid">
                <div className="md-cell--12">
                    <Button primary raised onClick={this.handleForm.bind(this)} >Tambah Data</Button>
                    <br />
                    <DataTable plain>
                        <TableHeader>
                            <TableRow>
                                <TableColumn>Nama Dokumen</TableColumn>
                                <TableColumn>Nomor Dokumen</TableColumn>
                                <TableColumn>Tanggal Dokumen</TableColumn>
                                <TableColumn>Aksi</TableColumn>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {this.state.data.map((d, i)=> {
                                return (
                                    <TableRow key={i}>
                                        <TableColumn>{d.name}</TableColumn>
                                        <TableColumn>{d.nomor}</TableColumn>
                                        <TableColumn>{moment(new Date(d.tanggal)).format('DD-MM-YYYY')}</TableColumn>
                                        <TableColumn><Button primary raised onClick={this.handleHapus.bind(this, i)}>Hapus</Button></TableColumn>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </DataTable>
                    <hr />
                    <p>Menampilkan {this.state.data.length}</p>
                </div>
            </div>
        )
    }
}