import React, { Component } from 'react';
import {connect} from 'react-redux';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  CardActions,
  Button,
  LinearProgress
} from 'react-md';
import { Field, reduxForm, getFormValues} from 'redux-form';

import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Checkbox} from '../../../../../../components/form';
import terbilangService from '../../../../../../services/terbilang.service';

@connect((state) => ({
  formData: getFormValues('BP_4a2_Form')(state)
}))
export default class BP4a2HadiahForm extends Component {

  componentDidMount() {
    this.props.connectCmp(this.reCalculate.bind(this))
  }

  async reCalculate() {
  }

  async calculate(e,v,pv,f, parent) {
    var form = this.props.formData;
    var d    = form.hadiahUndian[parent];

    var bruto = parseFloat(d.bruto);
    var tarif = parseFloat(d.tarif);

    if(f.search('bruto') >= 0) {
      bruto = parseFloat(v)
    } else if(f.search('tarif') >= 0) {
      tarif = parseFloat(v)
    }

    if(!bruto) bruto = 0;
    if(!tarif) tarif = 0;

    var pph = Math.floor(bruto * tarif / 100)

    await this.props.change(`hadiahUndian.${parent}.pph`, pph);
    this.calculateTotal()
  }

  async calculateTotal() {
    var form = this.props.formData;
    var d    = form.hadiahUndian;

    var jmlPph   = 0;
    var jmlBruto = 0;
    Object.keys(d).forEach((k, i) => {
      if(k === 'b01' || k === 'b02' || k === 'b03' || k === 'b04' || k === 'b05' || k === 'b06') {
        var x = d[k];

        jmlPph   = jmlPph+ parseFloat(x.pph);
        jmlBruto = jmlBruto+ parseFloat(x.bruto)
      }
    })

    jmlBruto = Math.floor(jmlBruto)
    jmlPph = Math.floor(jmlPph)

    this.props.change('bruto', jmlBruto)
    this.props.change('pph', jmlPph);

    this.props.change('terbilang', terbilangService(parseInt(jmlPph)));

  }

  render() {
    return <DataTable plain fixedScrollWrapperStyle={{overflow: 'inherit'}} style={{overflow: 'inherit'}}>
      <TableHeader>
        <TableRow>
          <TableColumn>No.</TableColumn>
          <TableColumn>Jenis Hadiah Undian</TableColumn>
          <TableColumn className='md-text-right'>Bruto(Rp)</TableColumn>
          <TableColumn className='md-text-right'>Tarif(%)</TableColumn>
          <TableColumn className='md-text-right'>PPH Di Potong(Rp)</TableColumn>
        </TableRow>
      </TableHeader>
      <TableBody>
        <TableRow>
          <TableColumn>1.</TableColumn>
          <TableColumn>
            <Field
              name      = 'hadiahUndian.b01.jenisHadiahUndian'
              component = {Textfield}
              className = 'md-text-right'
            />
          </TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'hadiahUndian.b01.bruto'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'b01')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'hadiahUndian.b01.tarif'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'b01')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'hadiahUndian.b01.pph'
              component = {TextfieldMask}
              className = 'md-text-right'
              money     = {','}
            />
          </TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn>2.</TableColumn>
          <TableColumn>
            <Field
              name      = 'hadiahUndian.b02.jenisHadiahUndian'
              component = {Textfield}
              className = 'md-text-right'
            />
          </TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'hadiahUndian.b02.bruto'
              component = {TextfieldMask}
              className = 'md-text-right'
              money     = {','}
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'b02')}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'hadiahUndian.b02.tarif'
              component = {TextfieldMask}
              className = 'md-text-right'
              money     = {','}
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'b02')}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'hadiahUndian.b02.pph'
              component = {TextfieldMask}
              className = 'md-text-right'
              money     = {','}
            />
          </TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn>3.</TableColumn>
          <TableColumn>
            <Field
              name      = 'hadiahUndian.b03.jenisHadiahUndian'
              component = {Textfield}
              className = 'md-text-right'
            />
          </TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'hadiahUndian.b03.bruto'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'b03')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'hadiahUndian.b03.tarif'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'b03')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'hadiahUndian.b03.pph'
              component = {TextfieldMask}
              className = 'md-text-right'
              money     = {','}
            />
          </TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn>4.</TableColumn>
          <TableColumn>
            <Field
              name      = 'hadiahUndian.b04.jenisHadiahUndian'
              component = {Textfield}
              className = 'md-text-right'
            />
          </TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'hadiahUndian.b04.bruto'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'b04')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'hadiahUndian.b04.tarif'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'b04')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'hadiahUndian.b04.pph'
              component = {TextfieldMask}
              className = 'md-text-right'
              money     = {','}
            />
          </TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn>5.</TableColumn>
          <TableColumn>
            <Field
              name      = 'hadiahUndian.b05.jenisHadiahUndian'
              component = {Textfield}
              className = 'md-text-right'
            />
          </TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'hadiahUndian.b05.bruto'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'b05')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'hadiahUndian.b05.tarif'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'b05')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'hadiahUndian.b05.pph'
              component = {TextfieldMask}
              className = 'md-text-right'
              money     = {','}
            />
          </TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn>6.</TableColumn>
          <TableColumn>
            <Field
              name      = 'hadiahUndian.b06.jenisHadiahUndian'
              component = {Textfield}
              className = 'md-text-right'
            />
          </TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'hadiahUndian.b06.bruto'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'b06')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'hadiahUndian.b06.tarif'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'b06')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'hadiahUndian.b06.pph'
              component = {TextfieldMask}
              className = 'md-text-right'
              money     = {','}
            />
          </TableColumn>
        </TableRow>

        <TableRow style={{background: '#ddd'}}>
          <TableColumn></TableColumn>
          <TableColumn>
            Jumlah
          </TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'bruto'
              component = {TextfieldMask}
              className = 'md-text-right'
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'pph'
              component = {TextfieldMask}
              className = 'md-text-right'
              money     = {','}
            />
          </TableColumn>
        </TableRow>

        <TableRow style={{background: '#ddd'}}>
          <TableColumn></TableColumn>
          <TableColumn>
            Terbilang
          </TableColumn>
          <TableColumn colSpan={3}>
            <Field
              // label='Bruto'
              name      = 'terbilang'
              component = {Textfield}
            />
          </TableColumn>
        </TableRow>
      </TableBody>
    </DataTable>
  }
}
