import React, { Component } from 'react';
import { connect } from 'react-redux';

import ListView from '../../../../../components/entity/listView';
import ColumnService from '../../../../../services/column.service';
import TextField, { Button, Divider, Chip, Card, CardTitle, CardActions, DataTable, TableHeader, TableBody, TableRow, TableColumn, Switch, Paper } from 'react-md';
import {validation, Searchfield, TextfieldMask, Datepicker} from '../../../../../components/form';
import { Field, reduxForm } from 'redux-form';
import download from 'downloadjs';
import uuid from 'uuid';
import iziToast from 'izitoast';
import FileLapor22Service from './FileLapor22.service';

@reduxForm({form: 'FILE_LAPOR_22', destroyOnUnmount: true})
@connect((state)=> ({
  spt: state.auth.currentSPT
}), FileLapor22Service.actionConnectSetting())
export default class FileLapor22View extends ListView {
  service=FileLapor22Service
  viewType=2;

  constructor(props){
    super(props);
    this.state = {
      showTableSetting:false,
      showDialogConfirmDeleteSelected:false,
      showForm:false,
      formData: {},
      data: [],
      cetak: false
    }
  }

  columns=[]
  _barActions=[]
  _barItem() {}

  async componentDidMount(){
    var sptId = this.props.spt.data.id
    var res = await this.service.api.getSummary(sptId);
    if(res.data) {
      this.setState({formData : res.data});
      this.props.initialize(res.data)
    }

  }

  async handleCetak(){
    this.setState({ cetak: true })

    var report = null;

    var sptId = this.props.spt.data.id
    var res = await this.service.api.cetakTest(sptId);
    if(res.data.success) {
      report = await this.service.api.cetak(res.data.id)
    } else {
      iziToast.warning({"title": "warning", "message": res.data.message})
    }

    if(report) {
      try {
        var filename = report.headers.filename;
        download(report.data, filename);
        this.setState({ cetak: false })
      } catch (e){

        window.haahha = e
        this.setState({ cetak: false })
        iziToast.warning({"title": e.name, "message": e.message})
      }
    }

  }

  _barItem() {
    return (
      <div>
        <Button disabled={this.state.cetak} primary raised style={{margin: 5}} onClick={this.handleCetak.bind(this)}>Unduh File Lapor</Button>
      </div>
    )
  }

  _tableView(props) {

      return (
        // <div className='mpk-layout row'>
        //   <br/>
          <Card className='flex' style={{marginBottom: 32, marginRight: 10}}>
            <CardTitle title='' style={{padding: '10px 16px'}} subtitle='Detail'/>
            <Divider/>
            <DataTable
                plain
                baseId="simple-selectable-table">

                  <TableBody>
                    <TableRow>
                      <TableColumn>Jumlah PPh Terutang</TableColumn>
                      <TableColumn className='md-text-right'>
                        { ColumnService.money(this.state.formData.pphTerhutang)  }
                      </TableColumn>
                    </TableRow>

                    <TableRow>
                      <TableColumn>Jumlah PPH Disetor Sendiri</TableColumn>
                      <TableColumn className='md-text-right'>
                        { ColumnService.money(this.state.formData.sspDibayarSendiri)  }
                      </TableColumn>
                    </TableRow>

                     <TableRow>
                      <TableColumn>Jumlah SSP Yang Telah Dibayar</TableColumn>
                      <TableColumn className='md-text-right'>
                        { ColumnService.money(this.state.formData.ssp)  }
                      </TableColumn>
                    </TableRow>

                    <TableRow>
                      <TableColumn>Jumlah PBK</TableColumn>
                      <TableColumn className='md-text-right'>
                        { ColumnService.money(this.state.formData.pbk)  }
                      </TableColumn>
                    </TableRow>

                    <TableRow style={{borderTop: 'solid 3px #555'}}>
                      <TableColumn><b>Total</b></TableColumn>
                      <TableColumn className='md-text-right'>
                        <b>{ ColumnService.money(this.state.formData.total)  }</b>
                      </TableColumn>
                    </TableRow>

                  </TableBody>

              </DataTable>
            </Card>
        // </div>
      )

  }
}
