import React, { Component } from 'react';
import { connect } from 'react-redux';
import {} from 'react-md';

import OrganizationUserEbupotService from './OrganizationUserEbupot.service';
import ListView from '../../../../../components/entity/listView';

@connect(OrganizationUserEbupotService.stateConnectSetting(), OrganizationUserEbupotService.actionConnectSetting(), null, {withRef: true})
export default class OrganizationUserEbupotForm extends ListView {
  service=OrganizationUserEbupotService
  addDialog=false
  editDialog=false

  rootPath='organization-users'

  columns=[
    {label: "word.npwp",  value: "npwp", isDefaultSort:true, show:true, isSearchable:true},
    {label: "word.login",  value: "user.login", isDefaultSort:true, show:true, isSearchable:true},
    {label: "word.firstName",  value: "user.firstName", isDefaultSort:true, show:true, isSearchable:true},
    {label: "word.lastName",  value: "user.lastName", isDefaultSort:true, show:true, isSearchable:true},
    {label: "word.email",  value: "user.email", isDefaultSort:true, show:true, isSearchable:true}
  ]

  fetchOption() {
    return {
      path: this.service.path + '/page'
    }
  }


  beforeFetch(param) {
  }

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='NPWP'
          name='npwp__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Nama Depan'
          name='userFirstName__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Nama Belakang'
          name='userLastName__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Sign In'
          name='userLogin__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Email'
          name='userEmail__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
      </this.SearchContainer>
    )
  }

}
