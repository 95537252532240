import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Button, Chip, Switch} from 'react-md';

//import FormDialog from './Organization.dialog.view';
import ListView from '../../../../../../components/entity/listView';
import UtilService from '../../../../../../services/utils.service';
import download from 'downloadjs';
import uuid from 'uuid';
import iziToast from 'izitoast';
import Master15TarifService from './Master15Tarif.service';
import FormDialog from './Master15Tarif.dialog';

@connect((state)=> ({
  ...Master15TarifService.stateConnectSetting()(state),
}), Master15TarifService.actionConnectSetting())
export default class Master15TarifView extends ListView {
  service=Master15TarifService
  FormDialog=FormDialog

  rootPath = '/master/tarif-15'
  apiPath = 'page';
  addDialog=true
  editDialog=true

  columns = [
    {isSortable: true, label: "word.kode",  value: "kode", isDefaultSort:false, show:true, isSearchable:false},
    {isSortable: true, label: "word.jenisPenghasilan",  value: "jenisPenghasilan", isDefaultSort:false, show:true, isSearchable:false},
    {isSortable: true, label: "word.tarif",  type:'func', value: (data)=> {
      return data.tarif
    }, isDefaultSort:false, show:true, isSearchable:false},
    {isSortable: true, label: "word.seq",  value: "seq", type: "number", isDefaultSort:true, show:true, isSearchable:false},
  ]

  defaultColumns = [
  ]

}
