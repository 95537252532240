import ApiService from '../../../../services/api.service';

class MasterNegaraService extends ApiService {
  name= 'masterNegara';
  path= 'master/negara';
  constructor() {
    super();
    this.init()
  }
}


export default new MasterNegaraService();
