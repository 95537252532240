import React, { Component } from 'react';
import { connect } from 'react-redux';

import FormDialog from './Master21UpahHarian.dialog.view';
import Master21UpahHarianService from './Master21UpahHarian.service';
import ListView from '../../../../../components/entity/listView';
import ColumnService from '../../../../../services/column.service';

@connect(Master21UpahHarianService.stateConnectSetting(), Master21UpahHarianService.actionConnectSetting())
export default class Master21UpahHarianView extends ListView {
  service=Master21UpahHarianService
  FormDialog=FormDialog

  columns=[
    {isSortable: true, label: "word.id",    searchField: "id", value: function(d){ return d.id }, type: "func",  show:false, isSearchable:true},
    {isSortable: true, label: "word.start", searchField: "start", value: function(d){ return d.start }, type: "func",  show:true, isSearchable:true},
    {isSortable: true, label: "word.end",   searchField: "end", value: function(d){ return d.end }, type: "func",  show:true, isSearchable:true},
    {isSortable: true, label: "word.jumlah",searchField: "jumlah", value: function(d){ return ColumnService.money(d.jumlah) }, type: "func",  show:true, isSearchable:true},
  ]
}
