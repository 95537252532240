import ApiService from '../../../../../../services/api.service';

class AllWpHakAtasTanah4a2Service extends ApiService {
  name= 'AllWpHakAtasTanah4a2';
  path= 'spt4a2/wp-hak-atas-tanah/organization';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api= {
    email: async (category, ids) => {
      return await this.http.post(`${this.apiLocation}/email?category=${category}`, ids)
    }
  }

}

export default new AllWpHakAtasTanah4a2Service();
