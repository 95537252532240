import ApiService from '../../../../../services/api.service';

class Master21PtkpService extends ApiService {
  name= 'Master21Ptkp';
  path= 'master/tarif-21-ptkp';

  constructor() {
    super();
    this.init()
  }
}


export default new Master21PtkpService();
