import ApiService from '../../../services/api.service';
import http from "../../../services/http.service";

class LogEfillingService extends ApiService {
  name= 'LogEfilling';
  path= 'log/efiling';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api={
    downloadBpe: async (params)=> {
      var res = await this.http.get(`${this.apiLocation}/efiling/download/bpe`, {
        responseType: 'blob',
        params
      })

      return res;
    },
    checkStatus: async (params)=> {
      var res = await this.http.post(`${this.apiLocation}/efiling/upload/check`, {}, {
        params
      })

      return res.data;
    },
    reqNoBpeChecked: async (datas)=> {
      var res = await this.http.post(`${this.apiLocation}/efiling/req-no/bpe/bulk`, datas)

      return res.data;
    },
    downloadBpeChecked: async (datas)=> {
      var res = await this.http.post(`${this.apiLocation}/efiling/download/bpe`, datas, {
        responseType: 'blob',
      })

      return res;
    },
    checkStatusChecked: async (datas)=> {
      var res = await this.http.post(`${this.apiLocation}/efiling/upload/check/bulk`, datas)

      return res.data;
    },
    getNtpaChecked: async (datas)=> {
      var res = await this.http.post(`${this.apiLocation}/efiling/ntpa/check/bulk`, datas)

      return res.data;
    },
    getNtteChecked: async (datas)=> {
      var res = await this.http.post(`${this.apiLocation}/efiling/ntte/check/bulk`, datas)

      return res.data;
    },
    forceUploadChecked: async (datas)=> {
      var res = await this.http.post(`${this.apiLocation}/efiling/upload/force/bulk`, datas)

      return res.data;
    },
    setFailedChecked: async (datas)=> {
      var res = await this.http.post(`${this.apiLocation}/efiling/failed/bulk`, datas)

      return res.data;
    },
    setSkemaLamaChecked: async (datas)=> {
      var res = await this.http.post(`${this.apiLocation}/efiling/skema-lama/bulk`, datas)

      return res.data;
    },
    setSkemaBaruChecked: async (datas)=> {
      var res = await this.http.post(`${this.apiLocation}/efiling/skema-baru/bulk`, datas)

      return res.data;
    },
    upload: async(data) => {
      var res = await this.http.post(`${this.apiLocation}/efiling/upload/csv`, data)

      return res.data;
    },

    exportBulkExcel: async (data) => {
      var res = await this.http.post(`${this.apiLocation}/efiling/excel/bulk`, data, {
        responseType: 'blob'
      })
      return res;
    },

    exportByParamExcel: async (data) => {
      let res = await this.http.get(`${this.apiLocation}/efiling/excel/param`, {
        params: data,
        responseType: 'blob'
      });
      return res;
    },

    exportBulkExcelNtpn: async (data) => {
      var res = await this.http.post(`${this.apiLocation}/efiling/excel/ntpn/bulk`, data, {
        responseType: 'blob'
      })
      return res;
    },

    exportByParamExcelNtpn: async (data) => {
      let res = await this.http.get(`${this.apiLocation}/efiling/excel/ntpn/param`, {
        params: data,
        responseType: 'blob'
      });
      return res;
    },

    exportBulkExcelUnit: async (data) => {
      var res = await this.http.post(`${this.apiLocation}/efiling/excel/unit/bulk`, data, {
        responseType: 'blob'
      })
      return res;
    },

    exportByParamExcelUnit: async (data) => {
      let res = await this.http.get(`${this.apiLocation}/efiling/excel/unit/param`, {
        params: data,
        responseType: 'blob'
      });
      return res;
    },

    downloadAttachmentBulk: async (ids) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/download/bulk`, ids, {
        responseType: 'blob'
      })
      return res;
    },

    deleteSelected: async (ids)=> {
      let res = await http.post(`${this.apiLocation}/${this.path}/delete`, ids);

      return res;
    },
  }
}


export default new LogEfillingService();
