import ApiService from '../../../../../services/api.service';

class SPT22IndukService extends ApiService {
  name = 'SPT22Induk';
  path = 'spt22/induk';
  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api={
    getSigner: async (data)=> {
      var res = await this.http.get(`${this.apiLocation}/spt22/signer/${data.orgId}/check`, {
        params: data
      });
      return res.data;
    },
    getBySptId: async(sptId)=> {
      return await this.http.get(`${this.apiLocation}/spt22/induk/${sptId}`)
    },
    saveAndCallculate: async(sptId, d)=> {
      return await this.http.post(`${this.apiLocation}/spt22/induk/${sptId}/calculate`, d);
    },
    cetak: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/spt22/induk/${sptId}/report`, {
        responseType: 'blob'
      })

      return res;
    },
    cetakBulk: async (ids) => {
      var res = await this.http.post(`${this.apiLocation}/spt22/induk/report/bulk`, ids, {
        responseType: 'blob'
      })

      return res;
    },
    calculate: async(sptId, d)=> {
      return await this.http.get(`${this.apiLocation}/spt22/induk/calculate/${sptId}`, d);
    },
    downloadCsv: async (sptId)=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/csv/${sptId}`, {
        responseType: 'blob'
      })

      return res;
    },
  }
}


export default new SPT22IndukService();
