import React, { Component } from 'react';
import { Field, reduxForm , propTypes, formValueSelector } from 'redux-form';
import { validation, Textfield, TextfieldMask, Datepicker, convert } from '../../../../../../components/form';
import Service from './ImportBP4a2.service';
import ImportDialogTemplateView from '../../../../../../components/entity/Import.dialog.template.view';
ImportDialogTemplateView

@reduxForm({form: 'ImportBp4a2-form', destroyOnUnmount: false})
export default class ImportBp4a2DialogView extends ImportDialogTemplateView {
  service=Service
}
