import React, { Component } from 'react';
import { connect } from 'react-redux';
import izitoast from 'izitoast';
import {
  Button,
  Divider,
  Chip,
  Card,
  CardTitle,
  CardActions,
  TextField as Textfield2,
  Switch
} from 'react-md';
import { Field, reduxForm } from 'redux-form';
import UtilService from '../../../../../services/utils.service';
import {validation, Textfield, Searchfield, TextfieldMask} from '../../../../../components/form';
import download from 'downloadjs';
import uuid from 'uuid';
import iziToast from 'izitoast';

//import FormDialog from './Organization.dialog.view';
import BP21SATUMASAService from './BP_21_SATU_MASA.service';
import ListView from '../../../../../components/entity/listView';
import ListViewSpt from '../../../../../components/entity/listViewSpt';

@reduxForm({form: 'BP_21_SATU_MASA_view', destroyOnUnmount: true})
@connect((state)=> ({
  ...BP21SATUMASAService.stateConnectSetting()(state),
  spt: state.auth.currentSPT,
  formData: state.form.BP_21_SATU_MASA_view
}), BP21SATUMASAService.actionConnectSetting())
export default class BP21SATUMASAView extends ListViewSpt {
  service=BP21SATUMASAService
  FormDialog=()=> <div/>

  constructor(props){
    super(props);
    this.state = {
      ...this.state,
      cetakDaftar: false,
      total: {},
    }
  }

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='Nama'
          name='nama__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='NPWP'
          name='npwp__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Kode Pajak'
          name='kode__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Pembuat'
          name='createdUserLogin__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
      </this.SearchContainer>
    )
  }

  addDialog=false
  editDialog=false

  viewType=2

  fetchOption() {
    return {
      path: BP21SATUMASAService.path+'/'+this.props.spt.data.id+'/page'
    }
  }

  columns=[
    /*{isSortable: true, label: "word.reference",  value: "referensi", show:false, isSearchable:true},*/
    {isSortable: true, label: "word.npwp", value: "npwp", show:true, isSearchable:true},
    {isSortable: true, label: "word.name", value: "nama", show:true, isSearchable:true},
    {isSortable: true, label: "word.taxCode", value: "kode", show:true, isSearchable:true},
    {isSortable: true, label: "word.bruto", value: "bruto", show:true, isSearchable:true, type: 'number'},
    {isSortable: true, label: "word.pph", value: "pph", show:true, isSearchable:true, type: 'number'},
    {isSortable:true, label: "word.createdBy", type: "func", value: (value) => {
      if (value.createdUser) {
        let {login} = value.createdUser

        if (login !== 'system') {
          return login
        }
      }

      return value.createdBy
    }, show:true, isSearchable:true},
    {isSortable: true, label: "word.createdDate",  value:"createdDate", type:"date", show:true, isSearchable:true},
  ]

  async componentDidMount() {
    this.rootPath = this.props.match.url;
    this.fetchB()

    var sptId = this.props.spt.data.id
    var total = await this.service.api.total(sptId)
    this.setState({
      total: total
    })
  }

  async fetchB() {
    if(this.props.spt.data.id) {
      let pegawai = await BP21SATUMASAService.api.getPegawai(this.props.spt.data.id);
      let pensiun = await BP21SATUMASAService.api.getPensiun(this.props.spt.data.id);
      this.props.initialize({
        pegawai: pegawai.data,
        pensiun: pensiun.data
      });
    }
  }

  /*async handleCalculate(value) {

    let sptId = this.props.spt.data.id
    await BP21SATUMASAService.api.calculatePegawai(sptId);
    await BP21SATUMASAService.api.calculatePensiun(sptId);
    await this.fetchB()
    iziToast.success({title: 'Data', message: 'Berhasil dikalkulasi.'})
  }*/

  async handleSave(value) {
    await BP21SATUMASAService.api.updatePegawai(value.pegawai);
    await BP21SATUMASAService.api.updatePensiun(value.pensiun);
    iziToast.success({title: 'Data', message: 'Berhasil di simpan.'})
  }

  totalBruto() {
    var total = 0;
    if(this.state.total.bruto){
      total = this.state.total.bruto;
    }
    return total;
  }

  totalPph() {
    var total = 0;
    if(this.state.total.pph){
      total = this.state.total.pph;
    }
    return total;
  }

  header() {
    return (
      <div>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle='A. PEGAWAI TETAP DAN PENERIMA PENSIUN ATAU THT/JHT SERTA PNS, ANGGOTA TNI/POLRI, PEJABAT NEGARA DAN PENSIUNANNYA YANG PENGHASILANNYA MELEBIHI PENGHASILAN TIDAK KENA PAJAK (PTKP)
' />
        <Divider/>
      </div>
    )
  }

  async handleCetakDaftar(){
    this.setState({ cetakDaftar: true })
    try {
      var sptId = this.props.spt.data.id
      var report = await this.service.api.cetakDaftar(sptId)
      var filename = report.headers.filename
      download(report.data, filename);
      this.setState({ cetakDaftar: false })
    } catch (e) {
      const reader = new FileReader();

      /// This fires after the blob has been read/loaded.
      reader.addEventListener('loadend', (e) => {
        const text = e.srcElement.result
        let json = JSON.parse(text)

        if (json.detail)
          iziToast.error({
            title: 'Error',
            message: json.detail
          })
      });

      reader.readAsText(e.response.data)

      this.setState({cetakDaftar: false})
    }
  }

  async deleteSelectedDataItems(){
    var selected = this.props.table.selected

    var ids = [];

    selected.map(function (i){
      ids.push(i.id)
    })

    const sptId = this.props.match.params.sptId

    var res = await this.service.api.deleteBulk(sptId, ids)

    this.fetchData()
  }

  _barItem() {
    return (
      <div>
        <Button disabled={this.state.cetakDaftar} primary raised style={{margin: 5}} onClick={this.handleCetakDaftar.bind(this)}>Cetak Daftar</Button>
        <Switch
          style={{float: "right"}}
          id="switch-table-setting"
          name="Switch Table Setting"
          label=""
          checked={this.state.showTableSetting}
          onChange={() => {
            this.setState({
              showTableSetting:!this.state.showTableSetting
            })
          }}
        />
      </div>
    )
  }

  footer() {
    return <div className="flex-none mpk-border thin solid top dark">
      <div className="mpk-padding-N left right">
        <table style={{width: '100%'}}>
          <tfoot className="md-table-footer">
            <tr className="md-table-column md-text-left md-table-column--data md-table-column--adjusted md-text ">
              <td>Total Jumlah Penghasilan Bruto</td>
              <td className=" md-text-right">{UtilService.number.format(this.totalBruto(), {precision: 0, thousand: '.', decimal: ','})}</td>
            </tr>
            <tr className="md-table-column md-text-left md-table-column--data md-table-column--adjusted md-text ">
              <td>Total Jumlah PPH Dipotong</td>
              <td className=" md-text-right">{UtilService.number.format(this.totalPph(), {precision: 0, thousand: '.', decimal: ','})}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  }

  additionalContent() {
    const { handleSubmit, submitting, valid, pristine } = this.props;
    var formData = {pegawai: {}, pensiun:{}};
    if(this.props.formData && this.props.formData.values) formData = this.props.formData.values
    let FINISHED = false;
    if(this.props.spt.data.status === "FINISH"){
      FINISHED = true
    }

    return (
      <div>
        <br/>
        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='B. PEGAWAI TETAP DAN PENERIMA PENSIUN ATAU THT/JHT SERTA PNS, ANGGOTA TNI/POLRI, PEJABAT NEGARA
DAN PENSIUNANNYA YANG PENGHASILANNYA TIDAK MELEBIHI PTKP' />
          <Divider/>
          <div className='md-grid'>
            <div className="md-cell md-cell--6">
              <Field
                label='Jumlah Pegawai'
                name='pegawai.count'
                component={Textfield}
                validate={validation.required}
              />
              <Field
                label='Jumlah Pensiunan'
                name='pensiun.count'
                component={Textfield}
                validate={validation.required}
              />
            </div>

            <div className="md-cell md-cell--6">
              <Field
                label='Bruto Pegawai'
                name='pegawai.bruto'
                className='md-text-right'
                component={TextfieldMask}
                money={true}
                validate={validation.required}
              />
              <Field
                label='Bruto Pensiunan'
                name='pensiun.bruto'
                className='md-text-right'
                component={TextfieldMask}
                money={true}
                validate={validation.required}
              />
            </div>
          </div>
          <CardActions className="md-divider-border md-divider-border--top">
            {/*<Button disabled={FINISHED} flat secondary onClick={this.handleCalculate.bind(this)}>Calculate</Button>*/}
            <Button disabled={FINISHED} flat secondary onClick={handleSubmit(this.handleSave.bind(this))}>Save</Button>
          </CardActions>
        </Card>

        <br/>
        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='C. TOTAL (JUMLAH A + B)' />
          <Divider/>
          <div className='md-grid'>
            <div className="md-cell md-cell--6"></div>
            <div className="md-cell md-cell--6">
              <Textfield2
                label='Total Jumlah Penghasilan Bruto'
                name='totalJumlahPenghasilanBruto'
                className='md-text-right'
                component={TextfieldMask}
                money={true}
                value={UtilService.number.format(this.totalBruto()+parseInt(formData.pegawai.bruto)+parseInt(formData.pensiun.bruto), {precision: 0, thousand: '.', decimal: ','})}
              />
              <Textfield2
                label='Total Jumlah PPh Di Potong'
                name='totalJumlahPenghasilanDipotong'
                className='md-text-right'
                component={TextfieldMask}
                money={true}
                value={UtilService.number.format(this.totalPph(), {precision: 0, thousand: '.', decimal: ','})}
              />
            </div>
          </div>
        </Card>
      </div>
    )

  }

}
