import ApiService from '../../../../services/api.service';

class PaymentOrderService extends ApiService {
  name= 'PaymentOrder';
  path= 'ebilling/etaxs';

  api = {
    findByInvoice: async (params) => {
      let invoiceId = params.invoiceId

      delete params.invoiceId

      if(params.page)
        params.page = params.page - 1
      else
        params.page = 0

      var res = await this.http.get(`${this.apiLocation + "/" + this.path}/byInvoice/${invoiceId}`, { params })

      return res;
    },
    cancel: async (id) => {
      var res = await this.http.put(`${this.apiLocation + "/" + this.path}/cancel/${id}`)

      return res.data;
    },
    cancelChecked: async (data) => {
      var res = await this.http.post(`${this.apiLocation + "/" + this.path}/cancel/checked`, data)

      return res.data;
    },
    deleteChecked: async (data) => {
      var res = await this.http.post(`${this.apiLocation + "/" + this.path}/delete/checked`, data)

      return res.data;
    },
  }

  constructor() {
    super()
    this.init()
    this.initApi()
  }
}


export default new PaymentOrderService();
