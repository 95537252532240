import React, { Component } from 'react'; import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {Button, Chip, Switch, DialogContainer} from 'react-md';

//import FormDialog from './Organization.dialog.view';
import Bupot23Service from './Bupot23.service'
import ListView from '../../../../../components/entity/ListViewEbupot'
import UtilService from '../../../../../services/utils.service'
import download from 'downloadjs';
import uuid from 'uuid';
import iziToast from 'izitoast';
import moment from 'moment'

import { Field, reduxForm, initialize } from 'redux-form';
import {validation, FileInputNext, Searchfieldv2, Textfield, Searchfield, TextfieldMask, Datepicker, Radio, convert, CheckboxSelection, Multiselect} from '../../../../../components/form';
import OrganizationService from '../Organization/Organization.service';
// import LoadingOverlay from 'react-loading-overlay';
// import Loader from 'react-spinners/ScaleLoader';
import MDSpinner from "react-md-spinner";

@reduxForm({form: 'Bp23ExportForm', destroyOnUnmount: true})
@connect((state)=> ({
  ...Bupot23Service.stateConnectSetting()(state),
  spt: state.auth.currentSPT
}), Bupot23Service.actionConnectSetting())
export default class Bupot23FView extends ListView {
  service=Bupot23Service
  FormDialog=()=> <div/>

  constructor(props){
    super(props);
    this.state = {
      ...this.state,
      total: {},
      cetak: false,
      cetakDaftar: false,
      loadingPage: false
    }
  }

  addDialog=false
  editDialog=false

  addItem = () => {
    this.props.history.push(this.props.match.url + "/new");
  }

  editItem = (item) => {
    this.props.history.push(this.props.match.url + "/" + item.spt.id + "/" + item.id);
  }

  fetchOption() {
    const sptId = this.props.match.params.sptId
    return {
      path: Bupot23Service.path
    }
  }

  columns=Bupot23Service.columns

  totalBruto() {
    var total = 0;
    if(this.state.total.bruto){
      total = this.state.total.bruto;
    }
    return total;
  }

  totalPph() {
    var total = 0;
    if(this.state.total.pph){
      total = this.state.total.pph;
    }
    return total;
  }

  async handleCetakSingle(){
    this.setState({ cetak: true })
    // if(this.props.table.selected.length === 0){
    //   iziToast.warning({
    //     title: 'Pemberitahuan !',
    //     message: 'Pilih salah satu data yang akan dicetak',
    //   });
    //   this.setState({ cetak: false })
    // } else if(this.props.table.selected.length > 1) {
    //   iziToast.warning({
    //     title: 'Pemberitahuan !',
    //     message: 'Hanya satu data yang dapat dicetak',
    //   });
    //   this.setState({ cetak: false })
    // } else {

    // }
    try {
      var selected = this.props.table.selected
      // alert(JSON.stringify(selected))

      // var ids = [];
      // selected.map(function (i) {
      //   ids.push(i.id)
      // })

      // var sptId = this.props.spt.data.id
      if(selected.length > 1){
        var ids = [];
        selected.map(function (i) {
          ids.push(i.id)
        })
        var report = await this.service.api.cetakReportBulk(ids)
        window.open(report.data.url);
      } else {
        var selectedById = selected[0]
        var report = await this.service.api.cetak(selectedById.id)
        window.open(report.data.url);
      }
      // var filename = report.headers.filename
      // download(report.data, filename);
      this.setState({cetak: false})
    } catch (e) {
      this.setState({cetak: false})
    }
  }

  async handleCetak(){
    this.setState({ cetak: true })
    if(this.props.table.selected.length === 0){
      iziToast.warning({
        title: 'Pemberitahuan !',
        message: 'Pilih salah satu data yang akan dicetak',
      });
      this.setState({ cetak: false })
    } else {
      try {
        var selected = this.props.table.selected
        var ids = [];
        selected.map(function (i) {
          ids.push(i.id)
        })

        var sptId = this.props.spt.data.id
        var report = await this.service.api.cetakBulk(sptId, ids)
        var filename = report.headers.filename
        download(report.data, filename);
        this.setState({cetak: false})
      } catch (e) {
        this.setState({cetak: false})
      }
    }
  }

  async handleCetakDaftar(){
    this.setState({ cetakDaftar: true })
    try {
      var sptId = this.props.spt.data.id
      var report = await Bupot23Service.api.cetakDaftar(sptId)
      var filename = report.headers.filename
      download(report.data, filename);
      this.setState({ cetakDaftar: false })
    } catch (e){
      this.setState({ cetakDaftar: false })
    }
  }

  async handleSendEmail(){
    try {
      let category = "BP_23"
      var selected = this.props.table.selected
      var ids = [];
      selected.map(function (i){
          ids.push(i.id)
      })
      var sptId = this.props.spt.data.id
      await this.service.api.emailSpt(category, sptId, ids)
      iziToast.info({
        title: "Kirim Email",
        message: "email sedang diproses silahkan cek log untuk lebih detil"
      })
    } catch (e){
      iziToast.warning({
        title: e.name,
        message: e.message
      })
    }
  }

  async deleteSelectedDataItems(){
    var selected = this.props.table.selected

    var ids = [];

    selected.map(function (i){
      ids.push(i.id)
    })

    const sptId = this.props.match.params.sptId

    var res = await this.service.api.deleteBulk(sptId, ids)

    this.fetchData()
  }

  handlePembetulan(){
    var selected = this.props.table.selected
    var filterFinish = selected.filter((d)=> {
      return d.status != "FINISH"
    })
    if(filterFinish.length > 0){
      iziToast.info({
        title: "Validasi Pembetulan",
        message: "Selain BP dengan status Finish tidak dapat dilakukan pembetulan, aksi ini hanya bisa dilakukan pada bupot dengan status FINISH karena bupot telah dilaporkan SPT nya"
      })
    } else {
      localStorage.setItem("pembetulanBp23", JSON.stringify(selected))
      var dispatchName = this.service.name.toUpperCase() + "/TABLE_SET_PROPERTIES"
      this.props.dispatch(
        {
          type: dispatchName,
          properties: {
            index: 0,
            isSelect: false,
            selected: []
          }
        }
      )
      this.props.history.push('bp-23-pembetulan')
    }
  }

  handleEdit(){
    var selected = this.props.table.selected
    var filterFinish = selected.filter((d)=> {
      return d.status != 'COMPLETED'
    })
    if(filterFinish.length > 0){
      iziToast.info({
        title: "Validasi Edit",
        message: "Selain BP dengan status Complete tidak dapat dilakukan Edit, aksi ini hanya bisa dilakukan pada bupot dengan status COMPLETED karena bupot belum  pernah di laporkan SPT nya"
      })
    } else {
      localStorage.setItem("editBp23", JSON.stringify(selected))
      var dispatchName = this.service.name.toUpperCase() + "/TABLE_SET_PROPERTIES"
      this.props.dispatch(
        {
          type: dispatchName,
          properties: {
            index: 0,
            isSelect: false,
            selected: []
          }
        }
      )
      this.props.history.push('bp-23-edit')
    }
  }

  static contextTypes = {
    showDialog: PropTypes.func.isRequired,
  };

  async handleDelete(){
    var selected = this.props.table.selected
    var filterFinish = selected.filter((d)=> {
      return d.status != "FINISH"
    })
    var filterCompleted = selected.filter((d)=> {
      return d.status != "COMPLETED"
    })
    if(filterFinish.length > 0 && filterCompleted.length > 0){
      iziToast.info({
        title: "Validasi Delete",
        message: "Hanya status BP dengan status Complete dan Finish bisa dilakukan Delete"
      })
    } else {
      // handle delete
      var dispatchName = this.service.name.toUpperCase() + "/TABLE_SET_PROPERTIES"
      this.props.dispatch(
        {
          type: dispatchName,
          properties: {
            index: 0,
            isSelect: false,
            selected: []
          }
        }
      )
      var askSubmit = await this.context.showDialog(()=> ({
        title: null,
        width: 500,
        height: 132,
        text: (
          <div>
            <p>Apa anda yakin akan menghapus BP terpilih ?</p>
          </div>
        )
      }))
      if(askSubmit){
        this.setState({ showLoading: true })
        try {
          var data = selected
          var res = await this.service.api.submitDelete(data)
          // clear check box
          var mpkTable = document.getElementsByClassName('mpk-full')[0]
          var mpkRow = mpkTable.getElementsByClassName('md-fake-btn')
          var mpkCounter = 0
          while(mpkCounter < mpkRow.length){
              var checkbox = mpkRow[mpkCounter]
              var checkboxIcon = checkbox.getElementsByClassName('md-icon')[0]
              checkbox.classList.remove("md-text--theme-secondary")
              checkboxIcon.innerText = 'check_box_outline_blank'
              mpkCounter++;
          }
          // clear check box
          iziToast.success({
            title: "Success",
            message: "proses menghapus success"
          })
          this.setState({ showLoading: false })
          this.fetchData()
        } catch (e){
          iziToast.error({
            title: "Error",
            message: "proses menghapus gagal, " + e.message
          })
          this.setState({ showLoading: false })
        }
      }
      // handle delete
    }
  }

  historyItem(item){
    this.props.history.push(`/bupot23/history/${item.id}`)
  }

  logEmailItem(item){
    this.props.history.push(`/bupot23/email/${item.id}`)
  }

  async sendEmail(item){
    try {
      let res = await this.service.api.sendEmail(item.id)
      iziToast.success({
        title: "Success",
        message: "Email berhasil dikirim"
      })
    } catch(e){
      iziToast.error({
        title: "Failed",
        message: "Email gagal dikirim, " +e.message
      })
    }
  }

  async requestCetak(item){
    try {
      let res = await this.service.api.requestCetak(item.id)
      iziToast.success({
        title: "Success",
        message: "Request proses cetak berhasil"
      })
    } catch(e){
      iziToast.error({
        title: "Failed",
        message: "Request proses cetak gagal, " +e.message
      })
    }
  }

  _tableActions = [
    {
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    },
    {
      label:"History",
      iconClassName:"mdi mdi-file",
      onClick: (item) => this.historyItem(item)
    },
    // {
    //   label:"Request Cetak",
    //   iconClassName:"mdi mdi-file-pdf",
    //   onClick: (item) => this.requestCetak(item)
    // },
    {
      label:"Send Email",
      iconClassName:"mdi mdi-email",
      onClick: (item) => this.sendEmail(item)
    },
    {
      label:"Log Email",
      iconClassName:"mdi mdi-email",
      onClick: (item) => this.logEmailItem(item)
    },
    {label:"divider", iconClassName:"-"},
    {
      label:"Delete",
      iconClassName:"mdi mdi-delete",
      onClick:(item, callback) => this.deleteItem(item, callback),
      confirmation:{
        title:"sentence.custom.deleteItem",
        message:"sentence.custom.deleteItemConfirmation"
      }
    }
  ]

  _barActions = [
    {
      label:'word.create',
      iconClassName:'mdi mdi-plus',
      onClick:() => this.addItem(),
      disabledFunc:() => {
        try {
          var checkSptFinish = this.props.table.selected.filter((d)=> {
            return d.spt.status == 'FINISH'
          })
          if(checkSptFinish.length > 0){
            return true
          } else {
            return false
          }
        } catch(e){
          return true
        }
      }
    },
    {
      label:'word.delete',
      iconClassName:'mdi mdi-delete',
      onClick:() => this.handleDelete(),
      disabledFunc:() => {
        try {
          var checkSptFinish = this.props.table.selected.filter((d)=> {
            return d.spt.status == 'FINISH'
          })
          if(checkSptFinish.length > 0){
            return true
          } else {
            if(this.props.table.selected.length > 0){
              var mapFilterFinish = this.props.table.selected.filter((d)=> {
                return d.status == 'UPLOADING'
              })
              if(mapFilterFinish.length > 0 || this.props.table.selected.length === 0){
                return true
              } else {
                return false
              }
            } else {
              return true
            }
          }
        } catch(e){
          return true
        }
      }
    },
    {
      label:'word.edit',
      iconClassName:'mdi mdi-pencil',
      onClick:() => this.handleEdit(),
      disabledFunc:() => {
        try {
          var checkSptFinish = this.props.table.selected.filter((d)=> {
            return d.spt.status == 'FINISH'
          })
          if(checkSptFinish.length > 0){
            return true
          } else {
            if(this.props.table.selected.length > 0){
              var mapFilterFinish = this.props.table.selected.filter((d)=> {
                return d.status == 'UPLOADING'
              })
              if(mapFilterFinish.length > 0 || this.props.table.selected.length === 0){
                return true
              } else {
                return false
              }
            } else {
              return true
            }
          }
        } catch(e){
          return true
        }
      }
    },
    {
      label:'word.pembetulan',
      iconClassName:'mdi mdi-note-plus',
      onClick:() => this.handlePembetulan(),
      disabledFunc:() => {
        try {
          var checkSptFinish = this.props.table.selected.filter((d)=> {
            return d.spt.status == 'FINISH'
          })
          if(checkSptFinish.length > 0){
            return true
          } else {
            if(this.props.table.selected.length > 0){
              var mapFilterFinish = this.props.table.selected.filter((d)=> {
                return d.status == 'UPLOADING'
              })
              if(mapFilterFinish.length > 0 || this.props.table.selected.length === 0){
                return true
              } else {
                return false
              }
            } else {
              return true
            }
          }
        } catch(e){
          return true
        }
      }
    },
    {
      label:'word.report',
      iconClassName:'mdi mdi-file-pdf',
      onClick:() => this.handleCetakSingle(),
      disabledFunc:() => {
        if(this.props.table.selected.length === 0){
          return true
        } else if(this.state.cetak){
          return true
        } else if(this.props.table.selected.length > 0){
          try {
            if(this.props.table.selected[0].report == true){
              return false
            } else { return true }
          } catch(e){ return true }
        } else {
          return false
        }
      }
    },
    {
      label:'word.bulkEmail',
      iconClassName:'mdi mdi-email',
      onClick:() => this.handleBulkEmail(),
      disabledFunc:() => {
        if(this.props.table.selected.length === 0){
          return true
        } else { return false }
      }
    },
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => this.fetchData()
    },
  ]

  async handleBulkEmail(){
    var ids = []
    var selected = this.props.table.selected
    var desc = "mengirim email"
    var confirmation = await this.context.showDialog(()=> ({
      title: null,
      width: 500,
      height: 132,
      text: (
        <div>
          <p>Apa anda yakin akan {desc} bp terpilih ?</p>
        </div>
      )
    }))
    if(confirmation){
      this.setState({ loadingPage: true })
      try {
        var res = await this.service.api.sendEmailBulk(selected)
        iziToast.success({
          title: "Success",
          message: "proses "+ desc +" bp berhasil"
        })
        this.removeCheckbox()
        this.fetchData()
        this.setState({ loadingPage: false })
      } catch (e){
        iziToast.error({
          title: "Error",
          message: "proses "+ desc +" bp gagal, " + e.message
        })
        this.removeCheckbox()
        this.setState({ loadingPage: false })
      }
    }
  }

  _barItem() {
    return (
      <div>
        {/* <Button secondary raised style={{margin: 5}} onClick={this.handleSendEmail.bind(this)}>Kirim</Button>
        <Button disabled={this.state.cetakDaftar} primary raised style={{margin: 5}} onClick={this.handleCetakDaftar.bind(this)}>Cetak Daftar</Button>
        <Button disabled={this.state.cetak} primary raised style={{margin: 5}} onClick={this.handleCetakSingle.bind(this)}>Cetak</Button> */}
        <Switch
          style={{float: "right"}}
          id="switch-table-setting"
          name="Switch Table Setting"
          label=""
          checked={this.state.showTableSetting}
          onChange={() => {
            this.setState({
              showTableSetting:!this.state.showTableSetting
            })
          }}
        />
      </div>
    )
  }

  belowTopBar(){
    return (
      <DialogContainer
        visible={this.state.loadingPage}
        dialogStyle={{ width: '200px', height: '200px', borderRadius: '30px', textAlign: 'center', paddingTop: '50px' }}
      >
        <MDSpinner size={45} />
      </DialogContainer>
    )
  }

  // NEW TAB MENU
  async hapusPencarian(){
    try {
      var params = Object.assign({}, params=this.props.table.params)
      this.fetchData(params)
      await this.props.dispatch(initialize(this.service.name+'_search', {}));
    } catch(e){
    }
  }

  async handleSearching(values){
    try {
      var formData = this.props.tableFilter2
      if(!formData) formData = {}
      var params = Object.assign({}, params=this.props.table.params)
      if(formData.npwps){
        var npwps = ""
        formData.npwps.map((d, index)=> {
          if(index == (formData.npwps.length - 1)){
            npwps += d.id
          } else {
            npwps += d.id  + ","
          }
        })
        params['organizationId.in'] = npwps
      }
      if(formData.pembetulan){
        params['pembetulan.equals'] = formData.pembetulan
      }
      if(formData.masa1){
        params['masa.equals'] = formData.masa1
      }
      if(formData.masa2){
        params['masa.equals'] = formData.masa2
      }
      if(formData.tahun1){
        params['tahun.equals'] = formData.tahun1
      }
      if(formData.tahun2){
        params['tahun.equals'] = formData.tahun2
      }

      if(formData.orgNpwp){
        params['organizationNpwp.contains'] = formData.orgNpwp
      } else { delete params['organizationNpwp.contains'] }
      if(formData.orgName){
        params['organizationName.contains'] = formData.orgName
      } else { delete params['organizationName.contains'] }

      if(formData.no){
        params['no.contains'] = formData.no
      } else { delete params['no.contains'] }

      if(formData.isReport){
        if(formData.isReport == 'TRUE'){
          params['isReport.equals'] = true
        } else if(formData.isReport == 'FALSE'){
          params['isReport.equals'] = false
        } else {
          delete params['isReport.equals']
        }
      } else { delete params['isReport.equals'] }

      if(formData.status && formData.status.length > 0){
        var statuss = ""
        formData.status.map((d, index)=> {
          if(index == (formData.status.length - 1)){
            statuss += d
          } else {
            statuss += d  + ","
          }
        })
        params['status.in'] = statuss
      } else { delete params['status.in'] }

      if(formData.npwp){
        params['npwp.contains'] = formData.npwp
      } else { delete params['npwp.contains'] }

      if(formData.email){
        params['email.contains'] = formData.email
      } else { delete params['email.contains'] }

      if(formData.nama){
        params['nama.contains'] = formData.nama
      } else { delete params['nama.contains'] }

      if(formData.alamat){
        params['alamat.contains'] = formData.alamat
      } else { delete params['alamat.contains'] }

      if(formData.tgl){
        params['tgl.equals'] = formData.tgl
      } else { delete params['tgl.equals'] }

      if(formData.kode){
        params['kode.contains'] = formData.kode
      } else { delete params['kode.contains'] }

      if(formData.ntpn){
        params['ntpn.contains'] = formData.ntpn
      } else { delete params['ntpn.contains'] }

      if(formData.noDtp){
        params['noDtp.contains'] = formData.noDtp
      } else { delete params['noDtp.contains'] }

      if(formData.noSkb){
        params['noSkb.contains'] = formData.noSkb
      } else { delete params['noSkb.contains'] }

      if(formData.refXmlId){
        params['refXmlId.contains'] = formData.refXmlId
      } else { delete params['refXmlId.contains'] }

      if(formData.createdBy){
        params['createdBy.contains'] = formData.createdBy
      } else { delete params['createdBy.contains'] }

      if(formData.lastModifiedBy){
        params['lastModifiedBy.contains'] = formData.lastModifiedBy
      } else { delete params['lastModifiedBy.contains'] }

      if(formData.createdDate1){
        params['createdDate.greaterOrEqualThan'] = moment(formData.createdDate1).format('YYYY-MM-DD')
      } else { delete params['createdDate.greaterOrEqualThan'] }

      if(formData.createdDate2){
        params['createdDate.lessOrEqualThan'] = moment(formData.createdDate2).format('YYYY-MM-DD')
      } else { delete params['createdDate.lessOrEqualThan'] }

      if(formData.lastModifiedDate1){
        params['lastModifiedDate.greaterOrEqualThan'] = moment(formData.lastModifiedDate1).format('YYYY-MM-DD')
      } else { delete params['lastModifiedDate.greaterOrEqualThan'] }

      if(formData.lastModifiedDate2){
        params['lastModifiedDate.lessOrEqualThan'] = moment(formData.lastModifiedDate2).format('YYYY-MM-DD')
      } else { delete params['lastModifiedDate.lessOrEqualThan'] }
      this.fetchData(params)
    } catch(e){
      iziToast.info({
        title: "Pencarian",
        message: "Data tidak ditemukan, " + e.message
      })
    }
  }

  defaultSearchForm(){
    const { handleSubmit, submitting, valid, pristine } = this.props;
    return (
      <div className="md-grid" style={{overflow: 'auto'}}>
        <Field
          label='Organization'
          name='npwps'
          className="md-cell md-cell--12"
          apiParam     = {{}}
          viewField="nama"
          remoteSearch = {true}
          valueField='parent'
          service={OrganizationService}
          searchField  = {['nama']}
          component={Multiselect}
          itemTemplate={function(d) {
              let nameAndAlias = ""
              if (d.nama)
              nameAndAlias = d.nama
              if (d.aliasNama)
              nameAndAlias += " - " + d.aliasNama
              return {
                primaryText  : nameAndAlias,
                secondaryText: d.npwp,
                onClick: () => {
                    if(!this.props.disabled){
                      this.handleItemClick(d);
                    }
                }
            }
          }}
        />
        <Field
          label     = 'Masa'
          name      = 'masa1'
          className = "md-cell md-cell--12"
          component = {Searchfieldv2}
          options   = {[
            {id: 1, name: 'Januari'},
            {id: 2, name: 'Februari'},
            {id: 3, name: 'Maret'},
            {id: 4, name: 'April'},
            {id: 5, name: 'Mei'},
            {id: 6, name: 'Juni'},
            {id: 7, name: 'Juli'},
            {id: 8, name: 'Agustus'},
            {id: 9, name: 'September'},
            {id: 10, name: 'Oktober'},
            {id: 11, name: 'November'},
            {id: 12, name: 'Desember'}
          ]}
        />
        {/* <Field
          label     = 'Ke Masa'
          name      = 'masa2'
          className = "md-cell md-cell--6"
          component = {Searchfieldv2}
          options   = {[
            {id: 1, name: 'Januari'},
            {id: 2, name: 'Februari'},
            {id: 3, name: 'Maret'},
            {id: 4, name: 'April'},
            {id: 5, name: 'Mei'},
            {id: 6, name: 'Juni'},
            {id: 7, name: 'Juli'},
            {id: 8, name: 'Agustus'},
            {id: 9, name: 'September'},
            {id: 10, name: 'Oktober'},
            {id: 11, name: 'November'},
            {id: 12, name: 'Desember'}
          ]}
        /> */}
        <Field
          label     = 'Tahun'
          name      = 'tahun1'
          className = "md-cell md-cell--12"
          component = {Textfield}
        />
        {/* <Field
          label     = 'Ke Tahun'
          name      = 'tahun2'
          className = "md-cell md-cell--6"
          component = {Textfield}
        /> */}
        <Field
          label     = 'Pembetulan'
          name      = 'pembetulan'
          className = "md-cell md-cell--12"
          component = {Textfield}
        />
        <Field
          label     = 'Npwp Organisasi'
          name      = 'orgNpwp'
          className = "md-cell md-cell--12"
          component = {Textfield}
        />
        <Field
          label     = 'Nama Organisasi'
          name      = 'orgName'
          className = "md-cell md-cell--12"
          component = {Textfield}
        />
        <Field
          label     = 'No Bupot'
          name      = 'no'
          className = "md-cell md-cell--12"
          component = {Textfield}
        />
        <Field
          label     = 'Cetak'
          name      = 'isReport'
          className = "md-cell md-cell--12"
          component = {Searchfield}
          options   = {[
            { id: null, name: '-' },
            { id: 'TRUE', name: 'TRUE' },
            { id: 'FALSE', name: 'FALSE' }
          ]}
        />
        <Field
          label     = 'Status'
          name      = 'status'
          className = "md-cell md-cell--12"
          component = {Multiselect}
          noChip    = {true}
          options   = {[
            { id: null, name: ' ' },
            { id: 'UPLOADING', name: 'UPLOADING' },
            { id: 'PROCESS', name: 'PROCESS' },
            { id: 'COMPLETED', name: 'COMPLETED' },
            { id: 'FAILED', name: 'FAILED' },
            { id: 'CANCELED', name: 'CANCELED' },
            { id: 'DELETING', name: 'DELETING' },
            { id: 'DELETED', name: 'DELETED' },
            { id: 'FINISH', name: 'FINISH' },
            { id: 'EDITED', name: 'EDITED' },
            { id: 'REVISED', name: 'REVISED' }
          ]}
        />
        <Field
          label     = 'Npwp'
          name      = 'npwp'
          className = "md-cell md-cell--6"
          component = {Textfield}
        />
        <Field
          label     = 'Nik'
          name      = 'nik'
          className = "md-cell md-cell--6"
          component = {Textfield}
        />
        <Field
          label     = 'Email'
          name      = 'email'
          className = "md-cell md-cell--6"
          component = {Textfield}
        />
        <Field
          label     = 'Nama'
          name      = 'nama'
          className = "md-cell md-cell--6"
          component = {Textfield}
        />
        <Field
          label     = 'Alamat'
          name      = 'alamat'
          className = "md-cell md-cell--6"
          component = {Textfield}
        />
        <Field
          label     = 'Tanggal'
          name      = 'tgl'
          className = "md-cell md-cell--6"
          component = {Textfield}
        />
        <Field
          label     = 'Kode'
          name      = 'kode'
          className = "md-cell md-cell--6"
          component = {Textfield}
        />
        <Field
          label     = 'NTPN'
          name      = 'ntpn'
          className = "md-cell md-cell--6"
          component = {Textfield}
        />
        <Field
          label     = 'NO DTP'
          name      = 'noDtp'
          className = "md-cell md-cell--6"
          component = {Textfield}
        />
        <Field
          label     = 'NO SKB'
          name      = 'noSkb'
          className = "md-cell md-cell--6"
          component = {Textfield}
        />
        <Field
          label     = 'XML ID'
          name      = 'refXmlId'
          className = "md-cell md-cell--6"
          component = {Textfield}
        />
        <Field
          label     = 'Dibuat Oleh'
          name      = 'createdBy'
          className = "md-cell md-cell--6"
          component = {Textfield}
        />
        <Field
          label     = 'Diubah Oleh'
          name      = 'lastModifiedBy'
          className = "md-cell md-cell--6"
          component = {Textfield}
        />
        <Field
          label     = 'Dibuat dari'
          name      = 'createdDate1'
          className = "md-cell md-cell--12"
          component = {Datepicker}
        />
        <Field
          label     = 'Dibuat ke'
          name      = 'createdDate2'
          className = "md-cell md-cell--12"
          component = {Datepicker}
        />
        <Field
          label     = 'Diubah dari'
          name      = 'lastModifiedDate1'
          className = "md-cell md-cell--12"
          component = {Datepicker}
        />
        <Field
          label     = 'Diubah ke'
          name      = 'lastModifiedDate2'
          className = "md-cell md-cell--12"
          component = {Datepicker}
        />
        <hr />
        <Button raised primary className="md-cell md-cell--12" onClick={handleSubmit(this.handleSearching.bind(this))}>Cari</Button>
        <hr />
        <Button raised primary className="md-cell md-cell--12" onClick={handleSubmit(this.hapusPencarian.bind(this))}>Hapus Pencarian</Button>
      </div>
    )
  }
  // yg ditambah
  tabWidth = 280
  tabs= ["search", "column"]

  // tabComponents() {
  //   var obj = this;
  //   return {
  //     summary: this.report.bind(obj)
  //   }
  // }

  async handleExport(value){
    try {
      var desc = "download summary"
      this.setState({ loadingPage: true })
      var formData = {}
      formData.masa1 = value.masa1
      formData.masa2 = value.masa2
      formData.tahun1 = value.tahun1
      formData.tahun2 = value.tahun2
      formData.npwp = []
      value.npwp.map((d)=> {
        formData.npwp.push(d.id)
      })
      var report = await this.service.api.summarySpt(formData)
      var filename = report.headers.filename
      download(report.data, filename);
      iziToast.success({
        title: "Success",
        message: "proses "+ desc +" spt berhasil"
      })
      this.setState({ loadingPage: false })
    } catch (e){
      iziToast.error({
        title: "Error",
        message: "proses "+ desc +" spt gagal, " + e.message
      })
      this.setState({ loadingPage: false })
    }
  }
  report(){
    const { handleSubmit, submitting, valid, pristine } = this.props;
    return (
        <div className='mpk-layout column fill'>
          <div className='flex'>
            <div className='md-grid'>
              <Field
                label        = 'Organization'
                name         = 'npwp'
                className    = "md-cell md-cell--12"
                component    = {Multiselect}
                valueField   = 'parent'
                viewField    = 'nama'
                service      = {OrganizationService}
                remoteSearch = {true}
                searchField  = {['nama']}
                validate     = {validation.required}
              />
              <Field
                label     = 'Dari Masa'
                name      = 'masa1'
                className = "md-cell md-cell--6"
                component = {Searchfieldv2}
                options   = {[
                   {id: 1, name: 'Januari'},
                   {id: 2, name: 'Februari'},
                   {id: 3, name: 'Maret'},
                   {id: 4, name: 'April'},
                   {id: 5, name: 'Mei'},
                   {id: 6, name: 'Juni'},
                   {id: 7, name: 'Juli'},
                   {id: 8, name: 'Agustus'},
                   {id: 9, name: 'September'},
                   {id: 10, name: 'Oktober'},
                   {id: 11, name: 'November'},
                   {id: 12, name: 'Desember'}
                ]}
                validate  = {validation.required}
              />
              <Field
                label     = 'Ke Masa'
                name      = 'masa2'
                className = "md-cell md-cell--6"
                component = {Searchfieldv2}
                options   = {[
                   {id: 1, name: 'Januari'},
                   {id: 2, name: 'Februari'},
                   {id: 3, name: 'Maret'},
                   {id: 4, name: 'April'},
                   {id: 5, name: 'Mei'},
                   {id: 6, name: 'Juni'},
                   {id: 7, name: 'Juli'},
                   {id: 8, name: 'Agustus'},
                   {id: 9, name: 'September'},
                   {id: 10, name: 'Oktober'},
                   {id: 11, name: 'November'},
                   {id: 12, name: 'Desember'}
                ]}
                validate  = {validation.required}
              />
              <Field
                label     = 'Dari Tahun'
                name      = 'tahun1'
                className = "md-cell md-cell--6"
                component = {Textfield}
                type      = "number"
                validate  = {validation.required}
              />
              <Field
                label     = 'Ke Tahun'
                name      = 'tahun2'
                className = "md-cell md-cell--6"
                component = {Textfield}
                type      = "number"
                validate  = {validation.required}
              />
              <div className="md-cell md-cell--12">
                  <Button raised primary onClick={handleSubmit(this.handleExport.bind(this))}>Export</Button>
              </div>
            </div>
          </div>
        </div>
     )
   }
  // NEW TAB MENU

}
