import React, { Component } from 'react';
import { connect } from 'react-redux';

import FormDialog from './Master21HonorPns.dialog.view';
import Master21HonorPnsService from './Master21HonorPns.service';
import ListView from '../../../../../components/entity/listView';
import ColumnService from '../../../../../services/column.service';

@connect(Master21HonorPnsService.stateConnectSetting(), Master21HonorPnsService.actionConnectSetting())
export default class Master21HonorPnsView extends ListView {
  service=Master21HonorPnsService
  FormDialog=FormDialog

  columns=[
    {isSortable: true, label: "word.id",    searchField: "id", value: function(d){ return d.id }, type: "func",  show:false, isSearchable:true},
    {isSortable: true, label: "word.start", searchField: "start", value: function(d){ return d.start }, type: "func",  show:true, isSearchable:true},
    {isSortable: true, label: "word.end",   searchField: "end", value: function(d){ return d.end }, type: "func",  show:true, isSearchable:true},
    {isSortable: true, label: "word.gol1",  searchField: "gol1", value: function(d){ return ColumnService.money(d.gol1) }, type: "func",  show:true, isSearchable:true},
    {isSortable: true, label: "word.gol2",  searchField: "gol2", value: function(d){ return ColumnService.money(d.gol2) }, type: "func",  show:true, isSearchable:true},
    {isSortable: true, label: "word.gol3",  searchField: "gol3", value: function(d){ return ColumnService.money(d.gol3) }, type: "func",  show:true, isSearchable:true},
    {isSortable: true, label: "word.gol4",  searchField: "gol4", value: function(d){ return ColumnService.money(d.gol4) }, type: "func",  show:true, isSearchable:true},
  ]   
}
