import ApiService from '../../../../../../services/api.service';

class AllBp25Service extends ApiService {
  name= 'AllBp25';
  path= 'spt25/bp25/organization';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api= {
    email: async (category, ids) => {
      return await this.http.post(`${this.apiLocation}/email?category=${category}`, ids)
    }
  }

}

export default new AllBp25Service();
