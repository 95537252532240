import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Chip,
  Switch,
  List,
  ListItem,
  Subheader,
  FontIcon,
  Avatar,
  Divider, DialogContainer
} from 'react-md';

import {validation, Textfield, Searchfield, TextfieldMask, Datepicker, Radio, Checkbox} from '../../../../components/form';
import Bluebird from 'bluebird';
import { Field, reduxForm, getFormValues, change  } from 'redux-form';
import EbillingAccountService from '../EbillingAccountService';
//import FormDialog from './Organization.dialog.view';
import UtilService from '../../../../services/utils.service';
import download from 'downloadjs';
import iziToast from 'izitoast';
import ListView from '../../../../components/entity/listView';
import DataESSPService from './DataESSP.service';

@reduxForm({form: 'dataEssp', destroyOnUnmount: true, initialValues: {organizations: []}})
@connect((state)=> ({
  ...DataESSPService.stateConnectSetting()(state),
}), DataESSPService.actionConnectSetting())
export default class DataESSPView extends ListView {
  service=DataESSPService
  ebillingAccountService=EbillingAccountService
  FormDialog=() => <div/>

  _tableActions = [
    /*{
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    },
    {label:"divider", iconClassName:"-"},*/
    {
      label:"Delete",
      iconClassName:"mdi mdi-delete",
      onClick:(item, callback) => this.deleteItem(item, callback),
      confirmation:{
        title:"sentence.custom.deleteItem",
        message:"sentence.custom.deleteItemConfirmation"
      }
    }
  ]

  _barActions = [
    {
      label:'word.delete',
      iconClassName:'mdi mdi-delete',
      onClick:() => {
        this.setState({showDialogConfirmDeleteSelected:true})
      },
      disabledFunc:() => this.props.table.selected.length === 0
    },
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => this.fetchData()
    },
  ]

  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      organizations: []
    }

    this.state.ebillingUser = {}
    this.state.calculateIsRunning = true
    this.state.calculateIsRunningEbupot = true
  }

  convertParams(params) {
    if(params) {
      for (let key in params) {
        // key ada __contains
        if(key.includes('__contains')) {
          let value = params[key]
          let fixedKey = key.replace('__', '.')

          params[fixedKey] = value

          delete params[key]
        }
      }

      let paramsType = [];
      let paramsSubmitted = [];

      if(params["import"]) {
        paramsType.push("IMPORT")
      }

      if(params["hitung"]) {
        paramsType.push("HITUNG")
      }

      if(params["sudah_submit"]) {
        paramsSubmitted.push("Y")
      }

      if(params["belum_submit"]) {
        paramsSubmitted.push("N")
      }

      if(paramsType.length > 0) {
        params["type.in"] = paramsType.join()
      }

      if(paramsSubmitted.length > 0) {
        params["submitted.in"] = paramsSubmitted.join()
      }

      return params
    }
  }

  beforeFetch(params) {
    this.checkCalculateIsRunning()

    params = this.convertParams(params)

    if(params["sortBy"] === "createdAt") {
      params["sortBy"] = "createdDate"
      params["sort"] = "DESC"
    }
  }

  async initData() {
    this.props.initialize({organizations:[]});
  }

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='NPWP'
          name='npwp__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Pasal'
          name='pasal__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Kode Pajak'
          name='kodePajak__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Kode Setoran'
          name='kodeSetoran__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Masa'
          name='masa__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Tahun'
          name='tahun__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Pembetulan'
          name='pembetulan__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Pembuat'
          name='createdUserLogin__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Import'
          name='import'
          value='IMPORT'
          className="md-cell md-cell--6"
          component={this.ig.Checkbox}
        />
        <this.ig.Field
          label='Hitung'
          name='hitung'
          value='HITUNG'
          className="md-cell md-cell--6"
          component={this.ig.Checkbox}
        />
        <this.ig.Field
          label='Sudah Submit'
          name='sudah_submit'
          value='Y'
          className="md-cell md-cell--6"
          component={this.ig.Checkbox}
        />
        <this.ig.Field
          label='Belum Submit'
          name='belum_submit'
          value='N'
          className="md-cell md-cell--6"
          component={this.ig.Checkbox}
        />
      </this.SearchContainer>
    )
  }

  addDialog=false
  editDialog=false

  fetchOption() {
    return {
      path: DataESSPService.path + '/page'
    }
  }

  columns=[
    {isSortable: true, label: "word.reference", value: "reference", show:false, isSearchable: true},
    {isSortable: true, label: "word.npwp", value: "npwp", show: true, isSearchable: true},
    {isSortable: true, label: "word.pasal", value: "pasal", show: true, isSearchable: true},
    {isSortable: true, label: "entities.DataESSP.kodePajak", value: "kodePajak", show: true, isSearchable: true},
    {isSortable: true, label: "entities.DataESSP.kodeSetoran", value: "kodeSetoran", show: true, isSearchable: true},
    {isSortable: true, label: "entities.DataESSP.masa", value: "masa", show: true, isSearchable: true},
    {isSortable: true, label: "entities.DataESSP.tahun", value: "tahun", show: true, isSearchable: true},
    {isSortable: true, label: "entities.DataESSP.pembetulan", value: "pembetulan", show: true, isSearchable: true},
    {isSortable: true, label: "word.noSk",  value: "noSk", show:true, isSearchable:true},
    {isSortable: true, label: "entities.DataESSP.jumlahSetor", value: "jumlahSetor", type: "number", show: true, isSearchable: true},
    {isSortable: true, label: "entities.DataESSP.npwpLt",  value: "npwpLt", show:false, isSearchable:true},
    {isSortable: true, label: "entities.DataESSP.namaLt",  value: "namaLt", show:false, isSearchable:true},
    {isSortable: true, label: "entities.DataESSP.alamatLt",  value: "alamatLt", show:false, isSearchable:true},
    {isSortable: true, label: "entities.DataESSP.emailLt",  value: "emailLt", show:false, isSearchable:true},
    {isSortable: true, label: "entities.DataESSP.teleponLt",  value: "teleponLt", show:false, isSearchable:true},
    {isSortable: true, label: "entities.DataESSP.kodePosLt",  value: "kodePosLt", show:false, isSearchable:true},
    {isSortable: true, label: "entities.DataESSP.type", type: "func", value: (d) => {
      switch (d.type) {
        case 'HITUNG':
          return <Button flat swapTheming style={{backgroundColor: '#0D47A1', color: 'white'}}>{d.type}</Button>
        case 'IMPORT':
          return <Button flat swapTheming style={{backgroundColor: '#4caf50', color: 'white'}}>{d.type}</Button>
        default:
          break;
      }
    }, show: true, isSearchable: true},
    {isSortable: true, label: "entities.DataESSP.ebupot", type: "func", value: (d) => {
      if (d.ebupot) {
        return <Button flat swapTheming style={{backgroundColor: '#4caf50', color: 'white'}}>YA</Button>
      }

      return <Button flat swapTheming style={{backgroundColor: '#0D47A1', color: 'white'}}>TIDAK</Button>
    }, show: true, isSearchable: true},
    {isSortable: true, label: "entities.DataESSP.submitted", type: "func", value: (d) => {
      switch (d.submitted) {
        case 'Y':
          return <Button flat swapTheming style={{backgroundColor: '#0D47A1', color: 'white'}}>SUDAH</Button>
        case 'N':
          return <Button flat swapTheming style={{backgroundColor: '#4caf50', color: 'white'}}>BELUM</Button>
        default:
          break;
      }
    }, show: true, isSearchable: true},
  ]

  async checkConnection() {
    let res = await this.ebillingAccountService.api.me()

    this.setState({ebillingUser: res.data});
  }

  async checkCalculateIsRunning() {
    let res = await this.service.api.calculateIsRunning()

    this.setState({calculateIsRunning: res.running});
  }

  async checkCalculateIsRunningEbupot() {
    let res = await this.service.api.calculateIsRunningEbupot()

    this.setState({calculateIsRunningEbupot: res.running});
  }

  async checkLicense(permissionCode) {
    let res = await this.ebillingAccountService.api.countLicenseByPermission(permissionCode)

    this.setState({license: res.data});
  }

  async componentDidMount() {
    this.rootPath = this.props.match.url;

    try {
      this.checkConnection()
      this.checkLicense('create_billing_lengkap')
      this.checkCalculateIsRunning()
      this.checkCalculateIsRunningEbupot()
    } catch (e){
      this.toast.warning({
        title: e.name,
        message: e.message
      })
    }
  }

  generateBillingByChecklist = async () => {
    let selected = this.props.table.selected

    let sspList = []

    selected.forEach(item => {
      sspList.push(item)
    })

    if(sspList.length > 0) {
      try {
        let res = await this.service.api.generate(sspList)

        iziToast.success({
          title: 'Sukses',
          message: 'Generate id billing sedang diproses, silahkan cek halaman payment order.'
        })
      } catch (e) {
        iziToast.warning({
          title: e.name,
          message: e.message
        })
      }
    } else {
      iziToast.warning({
        title: 'Gagal',
        message: 'Anda harus menceklis minimal satu data.'
      })
    }
  }

  generateBillingByFilter = async () => {
    let params = this.props.tableFilter2

    params = this.convertParams(params)

    try {
      let res = await this.service.api.generateByFilter(params)

      iziToast.success({
        title: 'Sukses',
        message: 'Generate id billing sedang diproses, silahkan cek halaman payment order.'
      })
    } catch (e) {
      iziToast.warning({
        title: e.name,
        message: e.message
      })
    }
  }

  saveSspByChecklist = async () => {
    let selected = this.props.table.selected

    let sspList = []

    selected.forEach(item => {
      sspList.push(item)
    })

    if(sspList.length > 0) {
      try {
        let res = await this.service.api.save(sspList)

        iziToast.success({
          title: 'Sukses',
          message: 'SSP sukses disimpan, silahkan cek halaman payment order.'
        })
      } catch (e) {
        iziToast.warning({
          title: e.name,
          message: e.message
        })
      }
    } else {
      iziToast.warning({
        title: 'Gagal',
        message: 'Anda harus menceklis minimal satu data.'
      })
    }
  }

  saveBillingByFilter = async () => {
    let params = this.props.tableFilter2

    params = this.convertParams(params)

    try {
      let res = await this.service.api.saveByFilter(params)

      iziToast.success({
        title: 'Sukses',
        message: 'SSP sukses disimpan, silahkan cek halaman payment order.'
      })
    } catch (e) {
      iziToast.warning({
        title: e.name,
        message: e.message
      })
    }
  }

  csvBySelected = async () => {
    let selected = this.props.table.selected

    let idList = []

    selected.forEach(item => {
      idList.push(item.id)
    })

    if(idList.length > 0) {
      let data = {
        idList: idList
      }

      try {
        let res = await this.service.api.csvBySelected(data)

        iziToast.success({
          title: 'Sukses',
          message: 'Silahkan cek di halaman log export essp.'
        })
      } catch (e) {
        iziToast.warning({
          title: e.name,
          message: e.message
        })
      }
    } else {
      iziToast.warning({
        title: 'Gagal',
        message: 'Anda harus menceklis minimal satu data.'
      })
    }
  }

  csvByFilter = async () => {
    let data = this.convertParams(this.props.tableFilter2)

    try {
      let res = await this.service.api.csvByFilter(data)

      iziToast.success({
        title: 'Sukses',
        message: 'Silahkan cek di halaman log export essp.'
      })
    } catch (e) {
      iziToast.warning({
        title: e.name,
        message: e.message
      })
    }
  }

  _barItem() {
    let selected = this.props.table.selected

    return (
      <div className='actions mpk-layout'>
        {/*<Button icon tooltipLabel="Generate" onClick={() => this.generateBillingByChecklist()} disabled={selected.length === 0}>play_arrow</Button>
        <Button icon tooltipLabel="Generate All" onClick={() => this.generateBillingByFilter()}>fast_forward</Button>*/}
        <Button icon tooltipLabel="Save" onClick={() => this.saveSspByChecklist()} disabled={selected.length === 0}>save</Button>
        <Button icon tooltipLabel="Save All" onClick={() => this.saveBillingByFilter()}>dns</Button>
        <Button icon tooltipLabel="Hitung" onClick={() => this.onHitung()} disabled={this.state.calculateIsRunning}>library_books</Button>
        {/*<Button icon tooltipLabel="Hitung eBuPot" onClick={() => this.onHitungEbuPot()} disabled={this.state.calculateIsRunningEbupot}>library_add</Button>*/}
        <Button icon tooltipLabel="CSV By Selected" onClick={() => this.csvBySelected()} disabled={selected.length === 0}>print</Button>
        <Button icon tooltipLabel="CSV By Filter" onClick={() => this.csvByFilter()}>print</Button>
        <Switch
          style={{float: "right"}}
          id="switch-table-setting"
          name="Switch Table Setting"
          label=""
          checked={this.state.showTableSetting}
          onChange={() => {
            this.setState({
              showTableSetting:!this.state.showTableSetting
            })
          }}
        />
      </div>
    )
  }

  // right bar
  tabWidth = 280
  tabs= ["status", "search", "column"]

  tabComponents() {
    let obj = this;

    return {
      status: this.status.bind(obj)
    }
  }

  status() {
    const { handleSubmit, submitting, valid, pristine } = this.props;

    let license = this.state.license
    let licenseColor = 'red'

    if (license) {
      if (license >=1 && license <= 100) {
        licenseColor = 'yellow'
      }

      if (license > 100) {
        licenseColor = 'green'
      }

      license = license.toLocaleString().replace(/,/g, '.')
    }

    return (
      <div className='mpk-layout column fill'>
        <div className='flex'>
          <List className="">
            <Subheader primaryText="Status Koneksi" />

            { this.state.ebillingUser.login &&
              <ListItem
                leftAvatar={<Avatar  suffix="green" icon={<FontIcon>done</FontIcon>} />}
                primaryText="Terhubung"
                secondaryText={"Terhubung sebagai " + this.state.ebillingUser.login + "."}
              />
            }

            { !this.state.ebillingUser.login &&
              <ListItem
                leftAvatar={<Avatar suffix="red" icon={<FontIcon>clear</FontIcon>} />}
                primaryText="Tidak Terhubung"
                secondaryText="Status Koneksi"
              />
            }

            {/*{ (this.state.ebillingUser.login && license) &&
              <ListItem
                leftAvatar={<Avatar suffix={licenseColor} icon={<FontIcon>device_hub</FontIcon>} />}
                primaryText="Lisensi"
                secondaryText={`Sisa: ${license}`}
              />
            }*/}

            {/*<Subheader primaryText="Status Sistem" />

            <ListItem
              leftAvatar={<Avatar  suffix="green" icon={<FontIcon>done</FontIcon>} />}
              primaryText="Sistem Active"
              secondaryText="Status Sistem"
            />*/}
          </List>
        </div>
      </div>
    )
  }

  // actions
  async onHitung() {
    this.props.history.push('/EBILLING/DataESSP/hitung')

    /*let kodeMap = [{
      name: 'pasal21',
      label: 'Pasal 21',
      child: ['411121 - 100', '411121 - 401', '411121 - 402', '411127 - 104']
    }, {
      name: 'pasal23',
      label: 'Pasal 23',
      child: [
        '411124 - 100', '411124 - 101', '411124 - 102', '411124 - 103', '411124 - 104',
        '411127 - 100', '411127 - 101', '411127 - 102', '411127 - 103', '411127 - 104', '411127 - 105',
      ]
    }, {
      name: 'pasal4a2',
      label: 'Pasal 4 (2)',
      child: [
        '411128 - 401', '411128 - 402', '411128 - 403', '411128 - 404', '411128 - 405', '411128 - 406', '411128 - 407', '411128 - 409',
        '411128 - 417', '411128 - 418', '411128 - 419'
      ]
    }, {
      name: 'pasal15',
      label: 'Pasal 15',
      child: ['411129 - 101', '411128 - 410', '411128 - 411']
    }, {
      name: 'pasal22',
      label: 'Pasal 22',
      child: ['411122 - 100', '411122 - 401', '411122 - 403']
    }]

    let hitung = await this.context.showDialog((props, res, rej) =>({
      title: 'Hitung Data eSSP Terutang',
      initialValue:{},
      okText: 'Hitung',
      width: 600,
      text: (
        <div className='md-grid'>
          <Field
            label='NPWP'
            name='npwp'
            className="md-cell md-cell--12"
            component={Textfield}
          />
          <Field
              label='Masa'
              name='month'
              className="md-cell md-cell--6"
              component={Searchfield}
              options={[
                {id:1, name: 'Januari'}, {id:2, name: 'Februari'}, {id:3, name: 'Maret'}, {id:4, name: 'April'}, {id:5, name: 'Mei'}, {id:6, name: 'Juni'},
                {id:7, name: 'Juli'}, {id:8, name: 'Agustus'}, {id:9, name: 'September'}, {id:10, name: 'Oktober'}, {id:11, name: 'November'}, {id:12, name: 'Desember'}
              ]}
            />
            <Field
              label='Tahun'
              name='year'
              className="md-cell md-cell--6"
              component={Textfield}
            />

            {kodeMap.map((d)=> {
              return <div className='md-grid' style={{padding: 0, margin:0}}>
                <Divider style={{width: '100%'}}/>
                <Field
                  label={d.label}
                  name={d.name}
                  className="md-cell md-cell--12"
                  component={Checkbox}
                  onChange={async (ev, v) => {
                    await Bluebird.map(d.child, async (c)=> {
                      let name = c.split(' - ');
                      await this.props.dispatch(change('global_dialog', `${name[0]}_${name[1]}`, v));
                    })
                  }}
                />

                {d.child.map((c)=> {
                  let name = c.split(' - ');
                  return <Field
                    label={c}
                    name={`${name[0]}_${name[1]}`}
                    className="md-cell md-cell--4"
                    component={Checkbox}
                  />
                })}
              </div>
            })}
        </div>
      )
    }));

    if(hitung) {
      let kodeSetorans = [];

      for (let key in hitung) {
        if(hitung[key] === true) {
          kodeSetorans.push(key)
        }
      }

      let data = {}

      if(hitung.npwp) {
        data.npwp = hitung.npwp
      }

      if(hitung.month) {
        data.month = hitung.month
      }

      if(hitung.year) {
        data.year = hitung.year
      }

      if(kodeSetorans.length) {
        data.kodeSetorans = kodeSetorans
      }

      await this.service.api.calculate(data)
    }*/
  }

  async onHitungEbuPot() {
    this.props.history.push('/EBILLING/DataESSP/hitung/ebupot')
  }

}
