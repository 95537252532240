import ApiService from '../../../services/api.service';

class KalkulasiIndukService extends ApiService {
  name= 'kalkulasiInduk';
  path= '/calculate';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api={
    induk: async (data) => {
      var res = await this.http.post(`${this.apiLocation + this.path}/induk`, data)
      return res;
    },
  }
}


export default new KalkulasiIndukService();
