import ApiService from "../../../../../../services/api.service";

class Master23TarifLainService extends ApiService {
  name= 'Master23TarifLain';
  path= 'spt23/master/tarif-23-lain';

  constructor() {
    super();
    this.init()
  }

  api = {
    byCompany: async (params) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}`);

      return res;
    },

    exportBulkCsv: async (data) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/csv/bulk`, data, {
        responseType: 'blob'
      })
      return res;
    },

    exportByParamCsv: async (data) => {
      let res = await this.http.get(`${this.apiLocation}/${this.path}/csv/param`, {
        params: data,
        responseType: 'blob'
      });
      return res;
    },
  }
}


export default new Master23TarifLainService();
