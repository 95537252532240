import React, { Component } from 'react';
import { Field, reduxForm , propTypes, formValueSelector } from 'redux-form';
import { validation, Textfield, TextfieldMask, Datepicker, convert } from '../../../../../../components/form';
import Service from './ImportPBK15.service';
import ImportDialogTemplateView from '../../../../../../components/entity/Import.dialog.template.view';
ImportDialogTemplateView

@reduxForm({form: 'ImportPBK15-form', destroyOnUnmount: false})
export default class ImportPBK15DialogView extends ImportDialogTemplateView {
  service=Service
}
