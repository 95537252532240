import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  Tab,
  Tabs,
  SelectionControlGroup,
  Checkbox,
  Button
} from 'react-md';

import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Radio, convert} from '../../../../../components/form';
import UtilService from '../../../../../services/utils.service'
import DialogView from '../../../../../components/entity/dialog.view';
import ClientService from './Client.service'
import moment from 'moment'
import counterpart from 'counterpart'
import uuid from 'uuid';

@reduxForm({form: 'ClientForm', destroyOnUnmount: true, initialValues: {}})
@connect((state) => ({
  formData:getFormValues('ClientForm')(state)
}))
export default class ClientDialog extends DialogView {
  service=ClientService
  initialData={}

  transform(value) {
    value.appId = this.props.appId;
    return value;
  }

  initData(props) {
    if(props.formData.id) {
      this.props.initialize(props.formData);
    } else {
      this.props.initialize(this.initialData);
    }
  }

  formView() {
    return (
      <div className='md-grid'>
        <Button onClick={()=> this.handleGenerate()} flat primary swapTheming className='md-cell md-cell--12'>Generate</Button>
        <Divider />
        <Field
          label='Client ID'
          name='clientId'
          className="md-cell md-cell--12"
          component={Textfield}
          validate={validation.required}
        />
        <Field
          label={'Client Secret'}
          name='clientSecret'
          className="md-cell md-cell--12"
          component={Textfield}
          validate={validation.required}
        />
      </div>
    )
  }

  handleGenerate() {
    this.props.initialize({
      clientId: uuid(),
      clientSecret: uuid()
    })
  }
}
