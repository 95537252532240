import React, { Component } from 'react';
import { Grid, Cell } from 'react-md';
import { connect } from 'react-redux';
import { Field, reduxForm , propTypes, formValueSelector } from 'redux-form';
import { validation, Textfield, TextfieldMask, Searchfield, Switch, Multiselect, convert } from '../../../../components/form';
import DialogView from '../../../../components/entity/dialog.view';
import UserService from './User.service';
import CompanyService from '../Company/Company.service';

@connect(state => ({
  isLoginIn: state.auth.isLoginIn,
  user: state.auth.user,
  menuType: state.auth.menuType,
  company: state.auth.currentCompany
}))
@reduxForm({form: 'user', destroyOnUnmount: false})
export default class UserDialogView extends DialogView {
  service=UserService
  initialData = {
  }

  initData(props) {

    if(props.formData.id) {
      this.props.initialize(props.formData);
    } else {
      if(this.props.location.pathname && this.props.location.pathname.search('open/user') >= 0) {
        this.initialData.authorities = [{name: 'ROLE_USER', id: "ROLE_USER"}];
      }
      if(this.props.location.pathname && this.props.location.pathname.search('open/owner') >= 0) {
        this.initialData.authorities= [{name: 'ROLE_OWNER', id: "ROLE_OWNER"}];
      }
      this.initialData.company = this.props.company;
      this.props.initialize(this.initialData);
    }
  }

  transform(value) {
    if(!Array.isArray(value.authorities)){
      value.authorities = [value.authorities]
    }
    try {
      value.authorities.map((d, i)=> {
        d.name = d.name.replace("EBUPOT", "EPPT")
      })
    } catch(e){}
    return value;
  }

  formView() {

    var authoritieOptions= [
      {name: 'ROLE_ADMIN', id: "ROLE_ADMIN"},
      {name: 'ROLE_USER', id: "ROLE_USER"},
      {name: 'ROLE_OWNER', id: "ROLE_OWNER"}
    ]

    var hideAuth = false;
    if(this.props.location.pathname && this.props.location.pathname.search('open/user') >= 0) {
      authoritieOptions= [{name: 'ROLE_USER', id: "ROLE_USER"}]
      hideAuth = true;
    }

    if(this.props.location.pathname && this.props.location.pathname.search('open/owner') >= 0) {
      authoritieOptions= [ {name: 'ROLE_OWNER', id: "ROLE_OWNER"}]
      hideAuth = true;
    }

    return (
      <Grid style={{padding:'none'}}>
        {!this.props.company.id &&
          <Field
            label='Company'
            name='company'
            className="md-cell md-cell--12"
            apiPath="page"
            viewField="nama"
            valueField="parent"
            disabled={this.props.company.id}
            component={Searchfield}
            service={CompanyService}
            validate={validation.required}
          />
        }


        {!this.props.formData.id &&
          <Field
            label='Username'
            name='login'
            id="user-login"
            className="md-cell md-cell--6"
            component={Textfield}
            validate={validation.required}
          />
        }

        {!this.props.formData.id &&
          <Field
            label='Password'
            type='password'
            name='password'
            id="user-password"
            className="md-cell md-cell--6"
            component={Textfield}
          />
        }

        <Field
          label='Email'
          name='email'
          id="user-email"
          className="md-cell md-cell--12"
          component={Textfield}
          validate={validation.required}
        />
        <Field
          label='First Name'
          name='firstName'
          id="user-firstName"
          className="md-cell md-cell--6"
          component={Textfield}
          validate={validation.required}
        />
        <Field
          label='Last Name'
          name='lastName'
          id="user-lastName"
          className="md-cell md-cell--6"
          component={Textfield}
          validate={validation.required}
        />
        <Field
          label='Language'
          name='langKey'
          id="user-langKey"
          className="md-cell md-cell--12"
          component={Searchfield}
          options={[
            {name: 'English', id: "en"},
            {name: 'Indonesia', id: "id"},
          ]}
          validate={validation.required}
        />
        {this.props.formData.id &&
          <Field
            label='Banned'
            name='banned'
            id="user-banned"
            className="md-cell md-cell--12"
            component={Switch}
            // validate={validation.required}
          />
        }
        {this.props.formData.id &&
          <Field
            label='Alasan Banned'
            name='bannedReason'
            id="user-banned-reason"
            className="md-cell md-cell--12"
            component={Textfield}
          />
        }
        <Field
          label='Active'
          name='activated'
          id="user-activated"
          className="md-cell md-cell--6"
          component={Switch}
          // validate={validation.required}
        />
        <Field
          label='Admin View'
          name='adminView'
          id="user-admin-view"
          className="md-cell md-cell--6"
          component={Switch}
          // validate={validation.required}
        />
        {!hideAuth &&
          <Field
          label='Authorities'
          name='authorities'
          id="user-authorities"
          className="md-cell md-cell--12"
          valueField="parent"
          component={Multiselect}
          options={authoritieOptions}
          validate={validation.required}
        />
        }

      </Grid>
    )
  }
}
