import React, { Component } from 'react';
import {connect} from 'react-redux';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  CardActions,
  Button,
  LinearProgress
} from 'react-md';
import { Field, reduxForm, getFormValues} from 'redux-form';

import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Checkbox} from '../../../../../../components/form';
import terbilangService from '../../../../../../services/terbilang.service';

@connect((state) => ({
  formData: getFormValues('BP_4a2_Form')(state)
}))
export default class BP4a2JasaKonstruksiForm extends Component {

  componentDidMount() {
    this.props.connectCmp(this.reCalculate.bind(this))
  }

  async reCalculate() {
    var form = {...this.props.formData}
    var d    = form.jasaKonstruksi;

    Object.keys(d).forEach((k, i) => {
      if(!(k == 'jmlBruto' || k == 'jmlPph' || k == 'terbilang' || k == 'lastModifiedBy' || k == 'lastModifiedDate' || k == 'id' || k == 'createdDate' || k == 'createdBy')) {
        var x = d[k];

        if(typeof x == 'object') {
          if(x.bruto) x.pph = x.bruto*x.tarif/100
        }
      }
    })

    await this.props.initialize(form);
    await this.calculateTotal()
  }

  async calculate(e,v,pv,f, parent) {
    var form = this.props.formData;
    var d    = form.jasaKonstruksi[parent];

    var bruto = parseFloat(d.bruto);
    var tarif = parseFloat(d.tarif);

    if(f.search('bruto') >= 0) {
      bruto = parseFloat(v)
    } else if(f.search('tarif') >= 0) {
      tarif = parseFloat(v)
    }

    if(!bruto) bruto = 0;
    if(!tarif) tarif = 0;

    var pph = Math.floor(bruto * tarif / 100)

    await this.props.change(`jasaKonstruksi.${parent}.pph`, pph);
    this.calculateTotal()
  }

  async calculateTotal() {
    var form = this.props.formData;
    var d    = form.jasaKonstruksi;

    var jmlPph   = 0;
    var jmlBruto = 0;
    Object.keys(d).forEach((k, i) => {
      if(k === 'jasaPelaksanaanDenganKualifikasi' || k === 'jasaPelaksanaanSelainNo1Dan2' || k === 'jasaPelaksanaanTanpaKualifikasi' || k === 'jasaPengawasanDenganKualifikasi' || k === 'jasaPengawasanTanpaKualifikasi') {
        var x = d[k];

        jmlPph   = jmlPph+ parseFloat(x.pph);
        jmlBruto = jmlBruto+ parseFloat(x.bruto)
      }
    })

    jmlBruto = Math.floor(jmlBruto)
    jmlPph = Math.floor(jmlPph)

    this.props.change('bruto', jmlBruto)
    this.props.change('pph', jmlPph);

    this.props.change('terbilang', terbilangService(parseInt(jmlPph)));

  }

  render() {
    return <DataTable plain fixedScrollWrapperStyle={{overflow: 'inherit'}} style={{overflow: 'inherit'}}>
      <TableHeader>
        <TableRow>
          <TableColumn>No.</TableColumn>
          <TableColumn>Uraiian</TableColumn>
          <TableColumn className='md-text-right'>Bruto(Rp)</TableColumn>
          <TableColumn className='md-text-right'>Tarif(%)</TableColumn>
          <TableColumn className='md-text-right'>PPH Di Potong(Rp)</TableColumn>
        </TableRow>
      </TableHeader>
      <TableBody>
        <TableRow>
          <TableColumn>1.</TableColumn>
          <TableColumn>Jasa Pelaksanaan konstruksi oleh penyedia jasa dengan kualifikasi usaha kecil</TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'jasaKonstruksi.jasaPelaksanaanDenganKualifikasi.bruto'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'jasaPelaksanaanDenganKualifikasi')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'jasaKonstruksi.jasaPelaksanaanDenganKualifikasi.tarif'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'jasaPelaksanaanDenganKualifikasi')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'jasaKonstruksi.jasaPelaksanaanDenganKualifikasi.pph'
              component = {TextfieldMask}
              className = 'md-text-right'
              disabled
              money = {','}
            />
          </TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn>2.</TableColumn>
          <TableColumn>Jasa Pelaksanaan konstruksi oleh penyedia jasa yang tidak memiliki kualifikasi usaha</TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'jasaKonstruksi.jasaPelaksanaanTanpaKualifikasi.bruto'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'jasaPelaksanaanTanpaKualifikasi')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'jasaKonstruksi.jasaPelaksanaanTanpaKualifikasi.tarif'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'jasaPelaksanaanTanpaKualifikasi')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'jasaKonstruksi.jasaPelaksanaanTanpaKualifikasi.pph'
              component = {TextfieldMask}
              className = 'md-text-right'
              disabled
              money = {','}
            />
          </TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn>3.</TableColumn>
          <TableColumn>Jasa Pelaksanaan konstruksi oleh penyedia selain angka 1 dan angka 2 di atas</TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'jasaKonstruksi.jasaPelaksanaanSelainNo1Dan2.bruto'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'jasaPelaksanaanSelainNo1Dan2')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'jasaKonstruksi.jasaPelaksanaanSelainNo1Dan2.tarif'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'jasaPelaksanaanSelainNo1Dan2')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'jasaKonstruksi.jasaPelaksanaanSelainNo1Dan2.pph'
              component = {TextfieldMask}
              className = 'md-text-right'
              disabled
              money = {','}
            />
          </TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn>4.</TableColumn>
          <TableColumn>
            <Field
              label     = 'Jenis Penghasilan'
              name      = 'jasaKonstruksi.jasaPengawasanDenganKualifikasi.opsi'
              component = {Searchfield}
              options   = {[{
                id  : 1,
                name: 'Jasa Perencanaan konstruksi oleh penyedia jasa yang memiliki kualifikasi usaha'
              }, {
                id  : 2,
                name: 'Jasa Pengawasan konstruksi oleh penyedia jasa yang memiliki kualifikasi usaha'
              }]}
            />
          </TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'jasaKonstruksi.jasaPengawasanDenganKualifikasi.bruto'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'jasaPengawasanDenganKualifikasi')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'jasaKonstruksi.jasaPengawasanDenganKualifikasi.tarif'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'jasaPengawasanDenganKualifikasi')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'jasaKonstruksi.jasaPengawasanDenganKualifikasi.pph'
              component = {TextfieldMask}
              className = 'md-text-right'
              disabled
              money = {','}
            />
          </TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn>5.</TableColumn>
          <TableColumn>
            <Field
              label     = 'Jenis Penghasilan'
              name      = 'jasaKonstruksi.jasaPengawasanTanpaKualifikasi.opsi'
              component = {Searchfield}
              options   = {[{
                id  : 1,
                name: 'Jasa Perencanaan konstruksi oleh penyedia jasa yang tidak memiliki kualifikasi usaha'
              }, {
                id  : 2,
                name: 'Jasa Pengawasan konstruksi oleh penyedia jasa yang tidak memiliki kualifikasi usaha'
              }]}
            />
          </TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'jasaKonstruksi.jasaPengawasanTanpaKualifikasi.bruto'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'jasaPengawasanTanpaKualifikasi')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'jasaKonstruksi.jasaPengawasanTanpaKualifikasi.tarif'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'jasaPengawasanTanpaKualifikasi')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'jasaKonstruksi.jasaPengawasanTanpaKualifikasi.pph'
              component = {TextfieldMask}
              className = 'md-text-right'
              money     = {','}
              disabled
            />
          </TableColumn>
        </TableRow>

        <TableRow style={{background: '#ddd', borderTop: '3px solid black'}}>
          <TableColumn></TableColumn>
          <TableColumn>
            Jumlah
          </TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'bruto'
              component = {TextfieldMask}
              className = 'md-text-right'
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'pph'
              component = {TextfieldMask}
              className = 'md-text-right'
              money     = {','}
            />
          </TableColumn>
        </TableRow>

        <TableRow style={{background: '#ddd'}}>
          <TableColumn></TableColumn>
          <TableColumn>
            Terbilang
          </TableColumn>
          <TableColumn colSpan={3}>
            <Field
              // label='Bruto'
              name      = 'terbilang'
              component = {Textfield}
            />
          </TableColumn>
        </TableRow>
      </TableBody>
    </DataTable>
  }
}
