import ApiService from '../../../../services/api.service';

class UserService extends ApiService {
  name= 'user';
  path= 'users';

  constructor() {
    super();
    this.init()
  }

  api= {
    searchByCompany: async (params)=> {
      if(!params['login.contains']) params['login.contains'] = '';
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${params.companyId}/company`, {
        params: {
          login: params['login.contains']
        }
      })

      return res;
    },

    access: async (orgId, access, permission)=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/access/${orgId}/permission`, {
        params: {
          access: access,
          permission: permission
        }
      })

      return res.data;
    },

    resetCache: async (companyId, userIds) => {
      let res = await this.http.post(`${this.apiLocation}/${this.path}/user/${companyId}/resetCache`, userIds);
      return res;
    },

    exportOrganizationUserBulk: async (companyId, data) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/user/${companyId}/organization/csv/bulk`, data, {
        responseType: 'blob'
      });
      return res;
    },

    exportOrganizationUserParam: async (companyId, data) => {
      let res = await this.http.get(`${this.apiLocation}/${this.path}/user/${companyId}/organization/csv/param`, {
        params: data,
        responseType: 'blob'
      });
      return res;
    },
  }
}


export default new UserService();
