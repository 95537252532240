import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  Tab,
  Tabs,
  SelectionControlGroup,
  Checkbox,
  Avatar,
  FontIcon,
  List,
  ListItem,
  Paper
} from 'react-md';

import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Radio, convert} from '../../../../../components/form';
import UtilService from '../../../../../services/utils.service'
import FormView from '../../../../../components/entity/form.view';
import DialogView from '../../../../../components/entity/dialog.view';
import OrganizationUserEbupotService from './OrganizationUserEbupot.service'
import UserService from '../../User/User.service'
import OrganizationService from '../../../Modules/EbupotModule/Organization/Organization.service'
import moment from 'moment'
import counterpart from 'counterpart'

@reduxForm({form: 'OrganizationUserEbupotForm', destroyOnUnmount: true})
@connect((state) => ({
  formData:state.form.OrganizationUserEbupotForm,
  user: state.auth.user
}))
export default class OrganizationUserEbupotForm extends FormView {
  service=OrganizationUserEbupotService
  userService=UserService
  viewType=2;

  initialData={
    organization: {},
    user: {}
  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.initData()
  }

  async beforeSave(val) {
    val.npwp = val.organization.npwp
    return true;
  }

  async initData() {
    if(this.props.match.params.id == 'new') {
      this.initialData.organization = this.props.organization;
      this.props.initialize(this.initialData);
    } else {
      try {
        let res = await this.service.api.findOne(this.props.match.params.id);
        var initialData = res.data
        let organization = await OrganizationService.api.getOrgByNpwp(res.data.npwp)
        organization = organization.data

        if (Array.isArray(organization)) {
          organization = organization[0]
        }

        initialData.organization = organization
        this.props.initialize(initialData);
      } catch (e) {
      }
    }
  }

  formView() {
    var formData = {}
    if(this.props.formData) formData = this.props.formData.values;
    var organization = {};
    if(formData && formData.organization) organization = formData.organization;

    return (
        <Card>
        <div className="md-grid">
          <div className="md-cell md-cell--12">
            <Field
              label='Organization'
              name='organization'
              id="org-user-ebupot-organization"
              className="md-cell md-cell--12"
              // disabled={this.props.organization.id != null}
              params     = {{
                  size: 50
              }}
              remoteSearch = {true}
              viewField='npwp'
              valueField='parent'
              service={OrganizationService}
              searchField  = {['nama']}
              searchFieldInteger  = {['npwp']}
              component={Searchfield}
              itemTemplate={function(d) {
                  let nameAndAlias = ""
                  if (d.nama)
                    nameAndAlias = d.nama
                  if (d.aliasNama)
                    nameAndAlias += " - " + d.aliasNama
                  return {
                    primaryText  : nameAndAlias,
                    secondaryText: d.npwp,
                    onClick: () => {
                        if(!this.props.disabled){
                          this.handleItemClick(d); this.hide()
                        }
                    }
                }
              }}
            />
          </div>

          <div className="md-cell md-cell--6">
            <Field
              label='User'
              name='user'
              id="org-user-ebupot-user"
              className="md-cell md-cell--12"
              // disabled={this.props.organization.id != null}
              params     = {{
                size: 50
              }}
              remoteSearch = {true}
              viewField='login'
              valueField='parent'
              service={UserService}
              searchField  = {['login']}
              component={Searchfield}
              itemTemplate={function(d) {
                return {
                  primaryText  : d.login,
                  secondaryText: d.firstName + ' ' + d.lastName,
                  onClick: () => {
                    if(!this.props.disabled){
                      this.handleItemClick(d); this.hide()
                    }
                  }
                }
              }}
            />
          </div>

          <div className="md-cell md-cell--6">
            <List className="md-paper md-paper--1" >
                <Subheader primaryText="ORGANISASI" />
                <ListItem
                primaryText   = {organization.npwp}
                secondaryText = "NPWP"
                />
                <ListItem
                primaryText   = {organization.nama}
                secondaryText = "NAME"
                />
                {/*<ListItem
                primaryText   = {organization.aliasNama}
                secondaryText = "ALIAS NAME"
                />*/}
                <ListItem
                primaryText   = {organization.kota}
                secondaryText = "CITY"
                />
                <ListItem
                primaryText   = {organization.kodePos}
                secondaryText = "POSTAL CODE"
                />
                <ListItem
                primaryText   = {organization.email}
                secondaryText = "EMAIL"
                />
                <ListItem
                primaryText   = {organization.noTelp}
                secondaryText = "PHONE"
                />
                <ListItem
                primaryText   = {organization.alamat}
                secondaryText = "ADDRESS"
                />
                <ListItem
                primaryText   = {(organization.active) ? 'YES' : 'NO'}
                secondaryText = "ACTIVE"
                />
            </List>
          </div>
        </div>
      </Card>
    )
  }

}
