import ApiService from '../../../../../../services/api.service';

class Master23KapService extends ApiService {
  name= 'Master23Kap';
  path= 'spt23/master/kap';

  constructor() {
    super();
    this.init()
  }
}


export default new Master23KapService();

