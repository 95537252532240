import React from 'react';
import {connect} from 'react-redux';
import {
  Button,
  Chip,
  Switch,
  List,
  ListItem,
  Subheader,
  FontIcon,
  Avatar,
  Divider
} from 'react-md';
import {DialogConfirm} from 'react-mpk';
//import FormDialog from './Organization.dialog.view';
import DataInquiryService from './DataInquiry.service';
import EbillingAccountService from '../EbillingAccountService';
import EbillingInquiryService from '../EbillingInquiryService';
import ListView from '../../../../components/entity/listView';
import download from 'downloadjs';
import iziToast from 'izitoast';

@connect((state) => ({
  ...DataInquiryService.stateConnectSetting()(state),
}), DataInquiryService.actionConnectSetting())
export default class DataInquiryView extends ListView {
  service = DataInquiryService
  ebillingAccountService=EbillingAccountService
  ebillingInquiryService=EbillingInquiryService
  FormDialog = () => <div/>

  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      cetak: false,
    }

    this.state.ebillingUser = {}
  }

  beforeFetch(params) {
    if(params["sortBy"] === "createdAt") {
      params["sortBy"] = "createdDate"
      params["sort"] = "DESC"
    }
  }

  _barActions = [
    {
      label:'ebilling.inquiry',
      iconClassName:'mdi mdi-account-convert',
      onClick:() => {
        this.checkInquiry()
      },
      disabledFunc:() => {
        let selected = this.props.table.selected
        let deleteable = []

        selected.forEach(data => {
          if(!data.nama) {
            deleteable.push(data.id)
          }
        })

        return deleteable.length === 0
      }
    },
    {
      label:'word.delete',
      iconClassName:'mdi mdi-delete',
      onClick:() => {
        this.setState({showDialogConfirmDeleteFinishFailedSelected:true})
      },
      disabledFunc:() => {
        let selected = this.props.table.selected
        let deleteable = []

        selected.forEach(data => {
          deleteable.push(data.id)
        })

        return deleteable.length === 0
      }
    },
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => this.fetchData()
    }
  ]

  _dialogConfirmDeleteFinishFailedSelected() {
    return <DialogConfirm
      title={'word.deletes'}
      message={'sentence.custom.deleteSelectedItems'}
      visible={this.state.showDialogConfirmDeleteFinishFailedSelected}
      onSubmit={async (callback) => {
        let items = this.props.table.selected
        let deleteable = []

        items.forEach(data => {
          deleteable.push(data)
        })

        await this.deleteSelected(deleteable, callback)
      }}
      onCancel={() => this.setState({showDialogConfirmDeleteFinishFailedSelected:false})}
      translate={true}
    />
  }

  async checkConnection() {
    let res = await this.ebillingAccountService.api.me()

    this.setState({ebillingUser: res.data});
  }

  async checkInquiry() {
    let items = this.props.table.selected

    let npwpList = []

    items.forEach(item => {
      if(item.npwp) {
        npwpList.push(item.npwp)
      }
    })

    let data = {
      npwpList
    }

    let res = await this.ebillingInquiryService.api.checkInquiry(data)

    this.fetchData()
  }

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='NPWP'
          name='npwp__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Nama'
          name='name__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Kota'
          name='city__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Alamat'
          name='address__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
      </this.SearchContainer>
    )
  }

  addDialog = false
  editDialog = false

  fetchOption() {
    return {
      path: DataInquiryService.path + '/page'
    }
  }

  columns = [
    {isSortable: true, label: "word.npwp", value: "npwp", show: true, isSearchable: true},
    {isSortable: true, label: "word.name", value: "name", show: true, isSearchable: true},
    {isSortable: true, label: "word.city", value: "city", show: true, isSearchable: true},
    {isSortable: true, label: "word.address", value: "address", show: true, isSearchable: true},
    {isSortable: true, label: "word.status", type:'func',  value: (d)=> {
        if(d.success) {
          return <Button flat swapTheming primary style={{backgroundColor: '#4CAF50'}}>SUCCESS</Button>
        } else {
          if (!d.name && d.message) {
            return <Button flat swapTheming primary style={{backgroundColor: '#F44336'}}>FAILED</Button>
          }
          else {
            return <Button flat swapTheming primary style={{backgroundColor: '#0D47A1'}}>PROCESS</Button>
          }
        }
      }, isDefaultSort:false, show:true, isSearchable:false},
    {isSortable: true, label: "word.message", value: "message", show: true, isSearchable: false},
  ]

  async componentDidMount() {
    this.rootPath = this.props.match.url;

    try {
      this.checkConnection()
    } catch (e){
      this.toast.warning({
        title: e.name,
        message: e.message
      })
    }
  }

  csvBySelected = async () => {
    let selected = this.props.table.selected

    let idList = []

    selected.forEach(item => {
      idList.push(item.id)
    })

    if(idList.length > 0) {
      let data = {
        idList: idList
      }

      try {
        let res = await this.service.api.csvBySelected(data)

        iziToast.success({
          title: 'Sukses',
          message: 'Silahkan cek di halaman log export inquiry.'
        })
      } catch (e) {
        iziToast.warning({
          title: e.name,
          message: e.message
        })
      }
    } else {
      iziToast.warning({
        title: 'Gagal',
        message: 'Anda harus menceklis minimal satu data.'
      })
    }
  }

  csvByFilter = async () => {
    let data = this.convertParams(this.props.tableFilter2)

    try {
      let res = await this.service.api.csvByFilter(data)

      iziToast.success({
        title: 'Sukses',
        message: 'Silahkan cek di halaman log export inquiry.'
      })
    } catch (e) {
      iziToast.warning({
        title: e.name,
        message: e.message
      })
    }
  }

  convertParams(params) {
    if(params) {
      for (let key in params) {
        // key ada __contains
        if(key.includes('__contains')) {
          let value = params[key]
          let fixedKey = key.replace('__', '.')

          params[fixedKey] = value

          delete params[key]
        }
      }

      return params
    }

    return {}
  }

  // right bar
  tabWidth = 280
  tabs= ["status", "search", "column"]

  tabComponents() {
    var obj = this;
    return {
      status: this.status.bind(obj)
    }
  }

  status() {
    const { handleSubmit, submitting, valid, pristine } = this.props;
    return (
      <div className='mpk-layout column fill'>
        <div className='flex'>
          <List className="">
            <Subheader primaryText="Status Koneksi" />

            { this.state.ebillingUser.login &&
              <ListItem
                leftAvatar={<Avatar  suffix="green" icon={<FontIcon>done</FontIcon>} />}
                primaryText="Terhubung"
                secondaryText={"Terhubung sebagai " + this.state.ebillingUser.login + "."}
              />
            }

            { !this.state.ebillingUser.login &&
              <ListItem
                leftAvatar={<Avatar suffix="red" icon={<FontIcon>clear</FontIcon>} />}
                primaryText="Tidak Terhubung"
                secondaryText="Status Koneksi"
              />
            }

            {/*<Subheader primaryText="Status Sistem" />

            <ListItem
              leftAvatar={<Avatar  suffix="green" icon={<FontIcon>done</FontIcon>} />}
              primaryText="Sistem Active"
              secondaryText="Status Sistem"
            />*/}
          </List>
        </div>
      </div>
    )
  }

  _barItem() {
    let selected = this.props.table.selected

    return (
      <div className='actions mpk-layout'>
        <Button icon tooltipLabel="CSV By Selected" onClick={() => this.csvBySelected()} disabled={selected.length === 0}>print</Button>
        <Button icon tooltipLabel="CSV By Filter" onClick={() => this.csvByFilter()}>print</Button>

        <Switch
          style={{float: "right"}}
          id="switch-table-setting"
          name="Switch Table Setting"
          label=""
          checked={this.state.showTableSetting}
          onChange={() => {
            this.setState({
              showTableSetting: !this.state.showTableSetting
            })
          }}
        />
      </div>
    )
  }

}
