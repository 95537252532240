import ApiService from "../../../../../../services/api.service";

class Master23TarifService extends ApiService {
  name= 'Master23Tarif';
  path= 'spt23/tarif-23';

  constructor() {
    super();
    this.init()
  }

  api = {
    byCompany: async (data) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/company`, {
        params: data
      });
      return res.data;
    },

    getMaster: async () => {
      var res = await this.http.get(`${this.apiLocation}/spt23/master/tarif-23`);
      return res.data;
    }
  }
}


export default new Master23TarifService();
