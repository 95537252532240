import React, { Component } from 'react';
import { connect } from 'react-redux';

import ListView from '../../../../../../components/entity/listView';
import TextField, { Button, Divider, Chip, Card, CardTitle, CardActions, DataTable, TableHeader, TableBody, TableRow, TableColumn, Switch, Paper } from 'react-md';
import { Field, reduxForm } from 'redux-form';
import download from 'downloadjs';
import uuid from 'uuid';
import iziToast from 'izitoast';
import { Commandbar, Table, TableSetting, Empty, DialogConfirm } from 'react-mpk';
import http from '../../../../../../services/http.service'
import ExportCsv25Service from '../ExportCsv25/ExportCsv25.service';
import BP25Service from '../../Bp25/Bp25.service';
import SSP25Service from '../../SSP/SSP25.service';

@reduxForm({form: 'EXPORT_CSV_25', destroyOnUnmount: true})
@connect((state)=> ({
  ...ExportCsv25Service.stateConnectSetting()(state),
  spt: state.auth.currentSPT
}), ExportCsv25Service.actionConnectSetting())
export default class FileLapor25View extends ListView {
  service=ExportCsv25Service

  viewType=2;

  constructor(props){
    super(props);
    this.state = {
      showTableSetting:false,
      showDialogConfirmDeleteSelected:false,
      showForm:false,
      formData: {},
      data: [],
      cetak: false,
      showDialog: false,
      category: null,
      BP_25: 0,
      SSP_25: 0,
    }
  }

  columns=[]
  _barActions=[]
  _barItem() {}

  handleExport(category){
      this.setState({ showDialog: true })
      this.setState({ category: category })
  }

  async export(){
    var sptId = this.props.spt.data.id
    var category = this.state.category
    var csv = {};
    try {
      switch (category) {
        case 'BP_25':
          csv = await BP25Service.api.downloadCsv(sptId, category);
          break;
        case 'SSP_25':
          csv = await SSP25Service.api.downloadCsv(sptId);
          break;
        default:
          break;
      }
      var filename = csv.headers.filename
      download(csv.data, filename);
    } catch (error) {

    }
  }

  async componentDidMount(){
    var sptId = this.props.spt.data.id
    let info = await this.service.api.getInfo(sptId);

    if(info.data) {
      this.setState({
        BP_25: info.data.bp25,
        SSP_25: info.data.ssp,
      })
    }
  }

  _tableView(props) {
      const _this = this
      return (
        <Card className="flex mpk-layout">
            <DialogConfirm
                title={'word.export'}
                message={'sentence.custom.export'}
                visible={this.state.showDialog}
                onSubmit={(callback) => {
                    _this.export()
                    callback();
                }}
                onCancel={() => this.setState({showDialog:false})}
                translate={true}
            />
            <CardTitle title='' style={{padding: '10px 16px'}} subtitle='Informasi File Export'/>
            <Divider/>
            <DataTable plain
            fixedHeader
            fixedFooter>
              <TableHeader>
                <TableRow>
                    <TableColumn>Jumlah Data</TableColumn>
                    <TableColumn></TableColumn>
                </TableRow>
              </TableHeader>
              <TableBody>
                <TableRow>
                    <TableColumn>Daftar Angsuran 25</TableColumn>
                    <TableColumn></TableColumn>
                </TableRow>
                <TableRow>
                    <TableColumn>{this.state.BP_25} Unit</TableColumn>
                    <TableColumn>
                        <Button disabled={this.state.BP_25 == 0} onClick={this.handleExport.bind(this, 'BP_25')} style={{width: '100%'}} primary raised>EXPORT DATA DAFTAR ANGSURAN 25</Button>
                    </TableColumn>
                </TableRow>
                <TableRow>
                    <TableColumn>Lainnya</TableColumn>
                    <TableColumn></TableColumn>
                </TableRow>
                <TableRow>
                    <TableColumn>{this.state.SSP_25} Unit</TableColumn>
                    <TableColumn>
                        <Button disabled={this.state.SSP_25 == 0} onClick={this.handleExport.bind(this, 'SSP_25')} style={{width: '100%'}} primary raised>EXPORT DATA SSP</Button>
                    </TableColumn>
                </TableRow>
              </TableBody>
            </DataTable>
        </Card>
      )
  }
}
