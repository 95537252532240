import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Field, reduxForm } from 'redux-form';
import { Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab } from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Switch as Switch2} from '../../../../../components/form';

import ListView from '../../../../../components/entity/listView';
import ColumnService from '../../../../../services/column.service';
import axios from 'axios';
import izitoast from 'izitoast';
import LogExportDataSSPService from './LogExportDataSSP.service';
import download from 'downloadjs';

@connect((state)=> ({
  ...LogExportDataSSPService.stateConnectSetting()(state),
  spt: state.auth.currentSPT,
  user: state.auth.user
}), LogExportDataSSPService.actionConnectSetting())
export default class LogExportDataSSPView extends ListView {
  service=LogExportDataSSPService
  // viewType=2

  constructor(props){
    super(props);
    this.state = {
      showTableSetting:true,
      showDialogConfirmDeleteSelected:false,
      showForm:false,
      formData: {},
      data: [],
      onProgress: false,
      isError: false,
      errorMessage: ''
    }
  }

  beforeFetch(params) {
    if(params["sortBy"] === "createdAt") {
      params["sortBy"] = "createdDate"
      params["sort"] = "DESC"
    }
  }

  _barActions = [
    {
      label:'word.delete',
      iconClassName:'mdi mdi-delete',
      onClick:() => {
        this.setState({showDialogConfirmDeleteFinishFailedSelected:true})
      },
      disabledFunc:() => {
        let selected = this.props.table.selected
        let deleteable = []

        selected.forEach(data => {
          if(data.status == 'FINISH' || data.status == 'FAILED') {
            deleteable.push(data.id)
          }
        })

        return deleteable.length === 0
      }
    },
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => this.fetchData()
    }
  ]

  tableActions = (data) => {
    let actions = []

    let divider = {
      label:"divider",
      iconClassName:"-"
    }

    if(data.status === 'FINISH') {
      let downloadAction = {
        label:"Download",
        iconClassName:"mdi mdi-download",
        onClick:(item, callback) => this.download(item, callback),
        confirmation:{
          title:"sentence.custom.downloadItem",
          message:"sentence.custom.downloadItemConfirmation"
        }
      }

      actions.push(downloadAction)
      actions.push(divider)
    }

    if(data.status === 'FINISH' || data.status === 'FAILED') {
      let deleteAction = {
        label:"Delete",
        iconClassName:"mdi mdi-delete",
        onClick:(item, callback) => this.deleteItem(item, callback),
        confirmation:{
          title:"sentence.custom.deleteItem",
          message:"sentence.custom.deleteItemConfirmation"
        }
      }

      actions.push(deleteAction)
    }

    return actions
  }

  download = async (item, callback) => {
    try {
      var res = await this.service.api.download(item.id);
      var filename = res.headers.filename

      download(res.data, filename);

      callback()
    } catch(e) {
      callback(e, e)
    }
  }

  columns=[
    {isSortable: true, label: "word.total", value: "total", type: "number", show: true, isSearchable: true},
    {isSortable: true, label: "word.status", type: "func", value: (d) => {
      switch (d.status) {
        case 'WAITING':
          return <Button flat swapTheming style={{backgroundColor: '#9e9e9e', color: 'white'}}>WAITING</Button>
        case 'FAILED':
          return <Button flat swapTheming style={{backgroundColor: '#f44336', color: 'white'}}>FAILED</Button>
        case 'FINISH':
          return <Button flat swapTheming style={{backgroundColor: '#4caf50', color: 'white'}}>FINISH</Button>
        default:
          break;
      }
    }, show: true, isSearchable:false},
    {isSortable: true, label: "word.pesan", value: "message", show: true, isSearchable: true},
    {isSortable: true, label: "word.startDate", value: "startDate", type: "date", show: true, isSearchable: true},
    {isSortable: true, label: "word.endDate", value: "endDate", type: "date", show: true, isSearchable: true},
  ]

}
