import React, { Component } from 'react';
import { connect } from 'react-redux';
import {TabsContainer, Tabs, Tab} from 'react-md';
import { Field } from 'redux-form';

import OrganizationUserService from '../../Administrator/Organization/User/Organization.user.service';
import ListView from '../../../../components/entity/listView';
import { Textfield } from '../../../../components/form'
import navService from "react-mpk/services/navService";

export class OrganizationUserAllView extends ListView {
  service=OrganizationUserService;

  editDialog = false;

  fetchOption() {
    let companyId = this.props.match.params.companyId

    return {
      path: 'organization/user/all/'+companyId+'/page'
    }
  }

  _barActions = [
    {
      label:'word.delete',
      iconClassName:'mdi mdi-delete',
      onClick:() => {
        this.setState({showDialogConfirmDeleteSelected:true})
      },
      disabledFunc:() => this.props.table.selected.length === 0
    },
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => {

        window.location.hash = window.location.hash.split('?')[0]
        this.fetchData()
      }
    },
  ]

  columns=[
    {label: "word.npwp", value: "organization.npwp", isDefaultSort:true, isSortable:true, show:true, isSearchable:true, isDefaultSearchColumn:true},
    {label: "word.name", value: "organization.name", isSortable:true, show:true, isSearchable:true},
    {label: "word.aliasName", value: "organization.aliasName", isSortable:true, show:true, isSearchable:true},
    {label: "word.firstName", value: "user.firstName", isSortable:true, show:true, isSearchable:true},
    {label: "word.lastName", value: "user.lastName", isSortable:true, show:true, isSearchable:true},
    {label: "word.login", value: "user.login", isSortable:true, show:true, isSearchable:true},
    {label: "word.email", value: "user.email", isSortable:true, show:true, isSearchable:true},
  ]

  editItem = (item) => {
    let organizationId = item.organization.id

    navService.redirectTo(`/organization/${organizationId}`)
  }

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='NPWP Organisasi'
          name='organizationNpwp__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Nama Organisasi'
          name='organizationName__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Nama Alias Organisasi'
          name='organizationAliasName__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Nama Depan'
          name='userFirstName__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Nama Belakang'
          name='userLastName__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Sign In'
          name='userLogin__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Email'
          name='userEmail__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
      </this.SearchContainer>
    )
  }

  view1() {
    return (
      <this.viewContainer>
        {this.commandBar()}
        <div className="flex mpk-layout mpk-padding-N all">
          {this.tableView()}
          {this.tableSetting()}

          <this.FormDialog
            location={this.props.location}
            height={400}
            visible={this.state.showForm}
            add={this.props.tableActions.addDataItem}
            formData={this.state.formData}
            onSuccess={()=> this.fetchData()}
            onClose={() => this.setState({showForm:false})}
          />

          {this.dialogConfirm()}
        </div>
      </this.viewContainer>
    )
  }

  deleteItem = async (item, callback) => {
    try {
      await this.service.api.delete(item.id);
      callback()
      await this.fetchData()
    } catch(e) {
      callback(e, e)
    }
  }

}

export default connect(OrganizationUserService.stateConnectSetting(), OrganizationUserService.actionConnectSetting())(OrganizationUserAllView)
