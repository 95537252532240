import React, { Component } from 'react'; import PropTypes from 'prop-types';
import { Button, DialogContainer, TextField } from 'react-md';
import uuid from 'uuid';
import _ from 'lodash';
import { Field, reduxForm, getFormValues,  } from 'redux-form';
import {connect} from 'react-redux';

@reduxForm({form: 'global_dialog', initialValues:{}})
@connect((state) => ({
  formValue: getFormValues('global_dialog')(state),
  formError: state.form.global_dialog.syncErrors
}))
export default class DialogService extends Component {
  static childContextTypes = {
    showDialog: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      dialog: null,
      resolve: null,
      reject: null,
      initialValue: null,
      action2: null, 
      action3: null
    };
  }

  _resolve = (result) => {
    const { resolve } = this.state;
    this.setState({dialog: null, resolve: null, reject: null, initialValue: null});
    if (resolve) {
      resolve(result);
    }
  };

  _reject = (reason) => {
    const { reject } = this.state;
    this.setState({dialog: null, resolve: null, reject: null, initialValue: null});
    if (reject) {
      reject(reason);
    }
  };

  submitForm = (props, option) => {
    props.resolve(this.props.formValue, option)
  }

  showDialog = (create) => {
    var opt = (create(this, this._resolve, this._reject));
    var dialogOpt = {
      ...opt
    }
    if(dialogOpt.text) delete dialogOpt.text;
    if(dialogOpt.initialValue) delete dialogOpt.initialValue;
    if(dialogOpt.okText) delete dialogOpt.okText;
    if(dialogOpt.cancelText) delete dialogOpt.cancelText;

    // set initial valueForm
    if(opt.initialValue) {
      this.props.initialize(_.cloneDeep(opt.initialValue))
    } else {
      this.props.initialize({})
    }

    const validateForm = function (props){
      props.resolve(_parent.props.formValue)
    }

    // build dialog
    var _parent = this;
    var component = React.createElement(function(props) {
      var opacity = 1
      if(opt.opacity) opacity = opt.opacity
      var actions = [
        {
          secondary: true,
          children: opt.okText ? opt.okText: 'Ok',
          onClick: ()=> {
            if(_parent.props.formError){
              _parent.props.handleSubmit(_parent.submitForm.bind(_parent, props))
            } else {
              validateForm(props)
            }
          }
        },
        {
          children: opt.cancelText ? opt.cancelText: 'Cancel',
          onClick: ()=> {props.resolve(false)}
        }
      ]
      if(opt.okText2){
        actions.unshift({
          secondary: true,
          children: opt.okText2 ? opt.okText2: 'Ok',
          onClick: ()=> {
            _parent.setState({action2: true})
            localStorage.setItem("action2", true)
            _parent.props.change("action2", true)
            if(_parent.props.formError){
              _parent.props.handleSubmit(_parent.submitForm.bind(_parent, props))
            } else {
              validateForm(props)
            }
          }
        })
      }
      if(opt.okText3){
        actions.unshift({
          secondary: true,
          children: opt.okText3 ? opt.okText3: 'Ok',
          onClick: ()=> {
            _parent.setState({action2: true})
            localStorage.setItem("action3", true)
            _parent.props.change("action3", true)
            if(_parent.props.formError){
              _parent.props.handleSubmit(_parent.submitForm.bind(_parent, props))
            } else {
              validateForm(props)
            }
          }
        })
      }
      return <DialogContainer
      id={'globalDialog'}
      visible={true}
      onHide={()=> {props.reject()}}
      dialogStyle={{ opacity: opacity }}
      actions={actions}
      title="Change something?"
      {...dialogOpt}
      >{opt.text}</DialogContainer>
    })

    const promise = new Promise((resolve, reject) => {
      this.setState({dialog: component, resolve, reject, initialValue: opt.initialValue});
    });
    return promise;
  };

  getChildContext() {
    return {
      showDialog: this.showDialog
    };
  }

  render() {
    const { dialog, resolve, reject } = this.state;
    const scrimClass = dialog ? 'scrim active' : 'scrim';
    let dialogComponent = null;
    if (dialog) {
      dialogComponent = React.cloneElement(dialog, {
        resolve: this._resolve.bind(this),
        reject: this._reject.bind(this)
      });
    }
    try {
      var x = document.getElementById('globalDialog')
      x.style.top = null
    } catch(e){ }
    return (
      <div className='container' style={{width: '100%', height: '100%'}}>
        <div className={scrimClass}>
          {dialogComponent}
        </div>
        {this.props.children}
      </div>
    );
  }
}
// import React, { Component } from 'react';
// import { Button, DialogContainer, TextField } from 'react-md';
// import uuid from 'uuid';
// import _ from 'lodash';
// import { Field, reduxForm, getFormValues,  } from 'redux-form';
// import {connect} from 'react-redux';

// @reduxForm({form: 'global_dialog', initialValues:{}})
// @connect((state) => ({
//   formValue: getFormValues('global_dialog')(state)
// }))
// export default class DialogService extends Component {
//   static childContextTypes = {
//     showDialog: React.PropTypes.func,
//   };

//   constructor(props) {
//     super(props);
//     this.state = {
//       dialog: null,
//       resolve: null,
//       reject: null,
//       initialValue: null,

//       dialogData: {}
//     };
//   }

//   _resolve = (result) => {
//     const { resolve } = this.state;
//     this.setState({dialog: null, resolve: null, reject: null, initialValue: null});
//     if (resolve) {
//       resolve(result);
//     }
//   };

//   _reject = (reason) => {
//     const { reject } = this.state;
//     this.setState({dialog: null, resolve: null, reject: null, initialValue: null});
//     if (reject) {
//       reject(reason);
//     }
//   };

//   showDialog = (create) => {
//     var opt = (create(this, this._resolve, this._reject));
//     var dialogOpt = {
//       ...opt
//     }
//     if(dialogOpt.text) delete dialogOpt.text;
//     if(dialogOpt.initialValue) delete dialogOpt.initialValue;
//     if(dialogOpt.okText) delete dialogOpt.okText;
//     if(dialogOpt.cancelText) delete dialogOpt.cancelText;

//     // set initial valueForm
//     if(opt.initialValue) {
//       this.props.initialize(_.cloneDeep(opt.initialValue))
//     } else {
//       this.props.initialize({})
//     }

//     const validateForm = function (props){
//       props.resolve(_parent.props.formValue)
//     }

//     // build dialog
//     var _parent = this;
//     var component = React.createElement(function(props) {

//       var actions = [{
//         secondary: true,
//         children: opt.okText ? opt.okText: 'Ok',
//         onClick: ()=> {
//           _parent.props.handleSubmit(validateForm(props))
//         }
//       },{

//         children: opt.cancelText ? opt.cancelText: 'Cancel',
//         onClick: ()=> {props.resolve(false)}
//       }];

//       if(_parent.props.actions) actions = this.props.actions;

//       return <DialogContainer
//       id={'globalDialog'}
//       visible={true}
//       onHide={()=> {props.reject()}}
//       width={props.width}
//       actions={actions}
//       {...dialogOpt}
//       >{opt.text}</DialogContainer>
//     })

//     const promise = new Promise((resolve, reject) => {
//       this.setState({dialog: component, resolve, reject, initialValue: opt.initialValue});
//     });
//     return promise;
//   };

//   getChildContext() {
//     return {
//       showDialog: this.showDialog
//     };
//   }

//   render() {
//     const { dialog, resolve, reject } = this.state;
//     const scrimClass = dialog ? 'scrim active' : 'scrim';
//     let dialogComponent = null;
//     if (dialog) {
//       dialogComponent = React.cloneElement(dialog, {
//         resolve: this._resolve.bind(this),
//         reject: this._reject.bind(this),
//         dialogData: this.state.dialogData
//       });
//     }
//     try {
//       var x = document.getElementById('globalDialog')
//       x.style.top = null
//     } catch(e){ }
//     return (
//       <div className='container' style={{width: '100%', height: '100%'}}>
//         <div className={scrimClass}>
//           {dialogComponent}
//         </div>
//         {this.props.children}
//       </div>
//     );
//   }
// }
