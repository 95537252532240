import ApiService from '../../../services/api.service';
import env from 'env';

class LogExportService extends ApiService {
  name= 'LogExport';
  path= 'export';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api={
    download: async (id) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/download/${id}`, {
        responseType: 'blob'
      })

      return res;
    },

    downloadPdf: async (ids, type) => {
      var res = await this.http.post(`${this.apiLocation}/log/${type}/`, ids, {
        responseType: 'blob'
      })

      return res;
    },

    downloadFoldering: async (ids, type) => {
      var res = await this.http.post(`${this.apiLocation}/log/${type}/foldering`, ids, {
        responseType: 'blob'
      })

      return res;
    },

    downloadStream: async (ids, type) => {
      return window.open(`${env.host.default}/${this.apiLocation}/log/${type}/stream?ids=${ids}`)
    },

    downloadFolderingStream: async (ids, type) => {
      return window.open(`${env.host.default}/${this.apiLocation}/log/${type}/foldering/stream?ids=${ids}`)
    },

    refresh: async (ids) => {
      // var res = await this.http.get(`${this.apiLocation}/export/csv`, {params: {spt, pasal}});

      var res = await this.http.post(`${this.apiLocation}/${this.path}/check`, ids);
      return res;
    },

    export: async (path, id, rebuild=false) => {
      // var res = await this.http.get(`${this.apiLocation}/export/csv`, {params: {spt, pasal}});

      var res = await this.http.post(`${this.apiLocation}/${path}/csv`, {id, rebuild}, {params: {id, rebuild}});
      return res;
    },

    exportCsvBySptId: async (path, id) => {
      var res = await this.http.post(`${this.apiLocation}/${path}/csv/${id}`);
      return res;
    },

    exportPdf: async (path, id, rebuild=false) => {
      // var res = await this.http.get(`${this.apiLocation}/export/csv`, {params: {spt, pasal}});

      var res = await this.http.post(`${this.apiLocation}/${path}/pdf`, {id, rebuild}, {params: {id, rebuild}});
      return res;
    },

    exportPdfBySptId: async (path, id) => {
      var res = await this.http.post(`${this.apiLocation}/${path}/pdf/${id}`);
      return res;
    },

    exportPdfIndukBySptId: async (path, id) => {
      var res = await this.http.post(`${this.apiLocation}/${path}/pdf/${id}/induk-only`);
      return res;
    },

    exportBulk: async (path, data) => {
      // var res = await this.http.get(`${this.apiLocation}/export/csv`, {params: {spt, pasal}});

      var res = await this.http.post(`${this.apiLocation}/${path}/csv/bulk`, data, {
        // responseType: 'blob'
      });
      return res;
    },

    exportBulkPdf: async (path, data) => {
      // var res = await this.http.get(`${this.apiLocation}/export/csv`, {params: {spt, pasal}});

      var res = await this.http.post(`${this.apiLocation}/${path}/pdf/bulk`, data);
      return res;
    },

    exportBulkCsv: async (path, data) => {
      var res = await this.http.post(`${this.apiLocation}/${path}/csv/bulk`, data, {
        responseType: 'blob'
      })
      return res;
    },

    exportBulkExcel: async (path, data) => {
      var res = await this.http.post(`${this.apiLocation}/${path}/excel/bulk`, data, {
        responseType: 'blob'
      })
      return res;
    },

    exportBulkReport: async (path, data) => {
      var res = await this.http.post(`${this.apiLocation}/${path}/report/bulk`, data, {
        responseType: 'blob'
      });
      return res;
    },

    exportBulkReportMerge: async (path, data) => {
      var res = await this.http.post(`${this.apiLocation}/${path}/report/bulk/merge`, data, {
        responseType: 'blob'
      });
      return res;
    },

    exportByParamCsv: async (path, data) => {
      let res = await this.http.get(`${this.apiLocation}/${path}/csv/param`, {
        params: data,
      });
      return res;
    },

    exportByParamExcel: async (path, data) => {
      let res = await this.http.get(`${this.apiLocation}/${path}/excel/param`, {
        params: data,
        responseType: 'blob'
      });
      return res;
    },

    exportByParamPdf: async (path, data) => {
      let res = await this.http.get(`${this.apiLocation}/${path}/pdf/param`, {
        params: data,
      });
      return res;
    },

    exportByParamPdfInduk: async (path, data) => {
      let res = await this.http.get(`${this.apiLocation}/${path}/pdf/param/induk-only`, {
        params: data,
      });
      return res;
    },

    exportByParamReport: async (path, data) => {
      let res = await this.http.get(`${this.apiLocation}/${path}/report/param`, {
        params: data,
        responseType: 'blob'
      });
      return res;
    },

    exportByParamReportMerge: async (path, data) => {
      let res = await this.http.get(`${this.apiLocation}/${path}/report/param/merge`, {
        params: data,
        responseType: 'blob'
      });
      return res;
    },

    exportBulkBpn: async (path, data) => {
      var res = await this.http.post(`${this.apiLocation}/${path}/bpn/bulk`, data, {
      })
      return res;
    },

    exportByParamBpn: async (path, data) => {
      let res = await this.http.get(`${this.apiLocation}/${path}/bpn/param`, {
        params: data
      });
      return res;
    },

    downloadBulkBpn: async (path, data) => {
      var res = await this.http.post(`${this.apiLocation}/${path}/bpn/download/bulk`, data, {
        responseType: 'blob'
      })
      return res;
    },

    downloadByParamBpn: async (path, data) => {
      let res = await this.http.get(`${this.apiLocation}/${path}/bpn/download/param`, {
        params: data,
        responseType: 'blob'
      });
      return res;
    },

    deleteSelected: async (ids, type) => {
      var res = await this.http.post(`${this.apiLocation}/log/${type}/delete`, ids)

      return res;
    },

    deleteBulkBpn: async (path, data) => {
      var res = await this.http.post(`${this.apiLocation}/${path}/bpn/delete/bulk`, data)
      return res;
    },

  }
}


export default new LogExportService();
