import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Button, SVGIcon } from 'react-md';
import ListView from '../../../../../../components/entity/listView';
import {Switch} from 'react-md';
import iziToast from 'izitoast';
import AllSpt4a2IndukService from './AllSpt4a2Induk.service';
import Spt4a2IndukService from "../../Induk/Spt4a2Induk.service";
import download from "downloadjs";

@connect(AllSpt4a2IndukService.stateConnectSetting(), AllSpt4a2IndukService.actionConnectSetting())
export default class AllSpt4a2IndukView extends ListView {
  service=AllSpt4a2IndukService

  constructor(props){
    super(props);
    this.state = {
      showTableSetting:false,
      showDialogConfirmDeleteSelected:false,
      showForm:false,
      formData: {},
      data: []
    }
  }

  editDialog = false;
  _barActions = [];

  editItem = (item) => {
    if(this.editDialog) {
      this.showFormDialog(item);
    } else {
      if(this.rootPath) {
        this.props.history.push(this.rootPath+"/"+item.id)
      } else {
        this.props.history.push('/'+this.service.name+"/"+item.spt.id);
      }
    }
  }

  async handleSendEmail(){
    try {
      let category = "BP_23"
      var selected = this.props.table.selected
      var ids = [];
      selected.map(function (i){
          ids.push(i.id)
      })
      await this.service.api.email(category, ids)
      iziToast.info({
        title: "Kirim Email",
        message: "email sedang diproses silahkan cek log untuk lebih detil"
      })
    } catch (e){
      iziToast.warning({
        title: e.name,
        message: e.message
      })
    }
  }

  async handleCetak() {
    this.setState({cetak: true})
    if (this.props.table.selected.length === 0) {
      iziToast.warning({
        title: 'Pemberitahuan !',
        message: 'Pilih salah satu data yang akan dicetak',
      });
    }

    else if (this.props.table.selected.length > 1) {
      try {
        var selected = this.props.table.selected
        var ids = [];
        selected.map(function (i) {
          ids.push(i.spt.id)
        })

        var report = await Spt4a2IndukService.api.cetakBulk(ids)
        var filename = report.headers.filename
        download(report.data, filename);
        this.setState({cetak: false})
      } catch (e) {
        const reader = new FileReader();

        /// This fires after the blob has been read/loaded.
        reader.addEventListener('loadend', (e) => {
          const text = e.srcElement.result
          let json = JSON.parse(text)

          if (json.detail)
            iziToast.error({
              title: 'Error',
              message: json.detail
            })
        });

        reader.readAsText(e.response.data)

        this.setState({cetak: false})
      }
    }

    else {
      try {
        var selection = this.props.table.selected[0]
        var sptId = selection.spt.id
        var report = await Spt4a2IndukService.api.cetak(sptId)
        var filename = report.headers.filename
        download(report.data, filename);
        this.setState({cetak: false})
      } catch (e) {
        const reader = new FileReader();

        /// This fires after the blob has been read/loaded.
        reader.addEventListener('loadend', (e) => {
          const text = e.srcElement.result
          let json = JSON.parse(text)

          if (json.detail)
            iziToast.error({
              title: 'Error',
              message: json.detail
            })
        });

        reader.readAsText(e.response.data)

        this.setState({cetak: false})
      }
    }
  }

  barActions() {
    return [
      ...this._barActions,
      {
        label:'word.exportCsv',
        iconClassName:'mdi mdi-file-excel',
        disabledFunc:() => this.props.table.selected.length === 0,
        onClick:() => this.handleExportAll()
      },
      {
        label:'word.exportPdf',
        iconClassName:'mdi mdi-file-pdf',
        disabledFunc:() => this.props.table.selected.length === 0,
        onClick:() => this.handleCetak()
      },
      {
        label:'word.email',
        iconClassName:'mdi mdi-email',
        disabledFunc:() => this.props.table.selected.length === 0,
        onClick:() => this.handleSendEmail()
      }
    ]
  }

  columns=[
    /*{isSortable: true, label: "word.reference",  value: "referensi", show:false, isSearchable:true},*/
    {isSortable: true, label: "word.year",  searchField: "year", value: (d)=> d.spt.year, type: "func", show:true, isSearchable:true},
    {isSortable: true, label: "word.month",  value: "spt.month",searchField:'masa', type: "number", show:true, isSearchable:true},
    {isSortable: true, label: "word.pembetulan",  value: "spt.pembetulan", type: "number", show:true, isSearchable:true},
    {isSortable: true, label: "word.npwp",  value: "bagA.npwp", show:true, isSearchable:true},
    {isSortable: true, label: "word.nik",  value: "bagA.nik", show:false, isSearchable:true},
    {isSortable: true, label: "word.name",  value: "bagA.nama", show:true, isSearchable:true},

  ]

  _tableActions = [
    {
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    }
  ]

}
