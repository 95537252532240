import ApiService from '../../../../../services/api.service';

class Master21BiayaPensiunService extends ApiService {
  name= 'Master21BiayaPensiun';
  path= 'master/tarif-21-biaya-pensiun';

  constructor() {
    super();
    this.init()
  }
}


export default new Master21BiayaPensiunService();
