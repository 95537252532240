import ApiService from '../../../../../services/api.service';

class Master21UpahHarianService extends ApiService {
  name= 'Master21UpahHarian';
  path= 'master/tarif-21-upah-harian';

  constructor() {
    super();
    this.init()
  }
}


export default new Master21UpahHarianService();
