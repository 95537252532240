/**
 * Created by dwiargo on 12/11/17.
 */

import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { Sidebar } from 'react-mpk';

import routes from './routes';

class Entities extends Component{
  render(){
    return(
      <div className="mpk-layout fill mpk-relative-position">
        <Sidebar
          menus={[
              {
                label: 'Main menu',
                children: [
                  {label: 'Dashboard', iconClassName:'mdi mdi-view-grid', path:'/main/entities/dashboard'},
                  {label: 'Report', iconClassName:'mdi mdi-chart-donut'},
                ]
              },
              {
                label: 'Manage',
                children: [
                  {label: 'User', iconClassName:'mdi mdi-account', path:'/main/entities/manageUser'},
                  {label: 'Info', iconClassName:'mdi mdi-information-outline'},
                  {label: 'Follow Up', iconClassName:'mdi mdi-arrow-down'},
                ]
              }
            ]}
          />

      </div>
    )
  }
}

export default Entities;
