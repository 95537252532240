import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Divider,
  Chip,
  Card,
  CardTitle,
  CardActions,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Switch
} from 'react-md';
import { Field, reduxForm, getFormValues } from 'redux-form';
import {validation, Textfield, Searchfield} from '../../../../../components/form';
import ColumnService from '../../../../../services/column.service';
import utilsService from '../../../../../services/utils.service';
import download from 'downloadjs';
import uuid from 'uuid';
import iziToast from 'izitoast';

//import FormDialog from './Organization.dialog.view';
import ListView from '../../../../../components/entity/listView';
import ListViewSpt from '../../../../../components/entity/listViewSpt';
import SSP15Service from './SSP15.service';
import Spt15Service from '../SPT/Spt15.service';
// import FileLapor21Service from '../FileLapor21/FileLapor21.service';

@reduxForm({form: 'SSP15_view', destroyOnUnmount: false})
@connect((state)=> ({
  ...SSP15Service.stateConnectSetting()(state),
  spt: state.auth.currentSPT,
  formData: getFormValues('SSP15_view')(state)
}), SSP15Service.actionConnectSetting())
export default class SSP15View extends ListViewSpt {
  service=SSP15Service
  FormDialog=()=> <div/>

  addDialog=false
  editDialog=false

  constructor(props){
    super(props);
    this.state = {
      ...this.state,
      cetak: false,
      formData: {ssp: []}
    }
  }

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='Kode Setoran'
          name='kjs__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='NTPN'
          name='ntpn__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Pembuat'
          name='createdUserLogin__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
      </this.SearchContainer>
    )
  }

  viewType=2

  header() {
    return (
      <div>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle='DATA SSP TELAH DIBAYAR' />
        <Divider/>
      </div>
    )
  }

  fetchOption() {
    const sptId = this.props.match.params.sptId
    return {
      path: SSP15Service.path+'/'+sptId+'/page'
    }
  }

  columns=[
    {isSortable: true, label: "word.jenisSetoran", value: "kjs.kode", show:true, isSearchable:true},
    {isSortable: true, label: "word.date",  value: "tgl", show:true, isSearchable:true},
    {isSortable: true, label: "word.ntpn",  value: "ntpn", show:true, isSearchable:true},
    {isSortable: true, label: "word.total", value: "total", show:true, isSearchable:true, type: 'number'},
    {isSortable:true, label: "word.createdBy", type: "func", value: (value) => {
      if (value.createdUser) {
        let {login} = value.createdUser

        if (login !== 'system') {
          return login
        }
      }

      return value.createdBy
    }, show:true, isSearchable:true},
    {isSortable: true, label: "word.createdDate",  value:"createdDate", type:"date", show:true, isSearchable:true},
  ]

  async componentDidMount() {
    const sptId = this.props.match.params.sptId

    this.rootPath = this.props.match.url;

    var res = await Spt15Service.api.getSummary(sptId);
    if(res.data) {

      this.setState({formData: res.data})
    }
  }

  async handleCetakDaftar(){
    this.setState({ cetak: true })
    try {
      const sptId = this.props.match.params.sptId
      var report = await this.service.api.cetak(sptId)
      var filename = report.headers.filename
      download(report.data, filename);
      this.setState({ cetak: false })
    } catch (e) {
      const reader = new FileReader();

      /// This fires after the blob has been read/loaded.
      reader.addEventListener('loadend', (e) => {
        const text = e.srcElement.result
        let json = JSON.parse(text)

        if (json.detail)
          iziToast.error({
            title: 'Error',
            message: json.detail
          })
      });

      reader.readAsText(e.response.data)

      this.setState({cetak: false})
    }
  }

  async deleteSelectedDataItems(){
    var selected = this.props.table.selected

    var ids = [];

    selected.map(function (i){
      ids.push(i.id)
    })

    const sptId = this.props.match.params.sptId

    var res = await this.service.api.deleteBulk(sptId, ids)

    this.fetchData()
  }

  _barItem() {
    return (
      <div>
        {/* <Button disabled={this.state.cetak} primary raised style={{margin: 5}} onClick={this.handleCetakDaftar.bind(this)}>Cetak Daftar</Button> */}
        <Switch
          style={{float: "right"}}
          id="switch-table-setting"
          name="Switch Table Setting"
          label=""
          checked={this.state.showTableSetting}
          onChange={() => {
            this.setState({
              showTableSetting:!this.state.showTableSetting
            })
          }}
        />
      </div>
    )
  }

  additionalTopContent() {
    return (
      <div className='mpk-layout row'>
        <br/>
        <Card className='flex' style={{marginBottom: 32, marginRight: 10}}>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='DAFTAR SSP PASAL 15'/>
          <Divider/>
            <DataTable plain baseId="simple-selectable-table" indeterminate fixedHeader
                // fixedFooter
                fixedHeight={300}>
                <TableHeader>
                <TableRow>
                    <TableColumn>Uraian</TableColumn>
                    <TableColumn>PPH Terutang</TableColumn>
                    <TableColumn>SSP yang disetor</TableColumn>
                    {/*<TableColumn>Request ID Biling</TableColumn>*/}
                </TableRow>
                </TableHeader>
                <TableBody>
                  {this.state.formData.ssp.map((d) => {
                    return <TableRow>
                      <TableColumn>{d.map}</TableColumn>
                      <TableColumn className='md-text-right'>
                        { ColumnService.money2(d.jumlah)  }
                      </TableColumn>
                      <TableColumn className='md-text-right'>
                        { ColumnService.money2(d.terbayar)  }
                      </TableColumn>
                      {/*<TableColumn>-</TableColumn>*/}
                    </TableRow>
                  })}

                  <TableRow>
                    <TableColumn><strong>TOTAL</strong></TableColumn>
                    <TableColumn className='md-text-right'>
                      <strong>{ ColumnService.money2(this.state.formData.jumlah)  }</strong>
                    </TableColumn>
                    <TableColumn className='md-text-right'>
                      <strong>{ ColumnService.money2(this.state.formData.jumlahTerbayar)  }</strong>
                    </TableColumn>
                    {/*<TableColumn>-</TableColumn>*/}
                  </TableRow>
                </TableBody>
            </DataTable>
        </Card>
      </div>
    )

  }

}
