import React, { Component } from 'react'; import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {TabsContainer, Tabs, Tab, Button} from 'react-md';
import { Field, reduxForm, initialize } from 'redux-form';

import OrganizationService from './Organization.service';
import ListView from '../../../../../components/entity/ListViewEbupot';
import AuthService from '../../../../../services/auth.service'
import ListViewEbupot from '../../../../../components/entity/ListViewEbupot';
import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Radio, convert, FileInput5, Multiselect} from '../../../../../components/form';
import http from './../../../../../services/http.service'
import counterpart from 'counterpart'
import izitoast from 'izitoast'
import FileInputNext from '../../../../../components/form/FileInputNext';

@reduxForm({form: 'EbupotOrg', destroyOnUnmount: true})
@connect((state)=> ({
  ...OrganizationService.stateConnectSetting()(state)
}), OrganizationService.actionConnectSetting())
export default class OrganizationView extends ListViewEbupot {
  service=OrganizationService;
  addDialog=false
  editDialog=false

  static contextTypes = {
    showDialog: PropTypes.func.isRequired,
  };

  columns=OrganizationService.columns

  async uploadCertificate(item){
    var res = await this.context.showDialog(()=> ({
      title: "Upload Certificate",
      initialValue: {
        npwp: item.npwp,
        password: null
      },
      okText: "Upload",
      width: 800,
      text: (
        <div>
          <Field
            label='Npwp'
            name='npwp'
            className="md-cell md-cell--12"
            component={this.ig.Textfield}
            disabled={true}
          />
          <Field
            label='Password'
            name='password'
            className="md-cell md-cell--12"
            component={this.ig.Textfield}
            validate={validation.required}
          />
          <br />
          <Field
            label='Certificate'
            name='file'
            className="md-cell md-cell--12"
            component={FileInputNext}
            validate={validation.required}
          />
        </div>
      )
    }))
    if(res){
      let formData = new FormData();
      formData.append("file", res.file)
      formData.append("npwp", res.npwp)
      formData.append("password", res.password)
      http.post(`/api/ebupot/cert/upload`, formData).then(function (res){
        izitoast.success({title: 'Import File', message: counterpart('custom.doneUpload') })
      }).catch(function (e){
        izitoast.error({title: 'Import File', message: counterpart('custom.failUpload') })
      })
    }
  }

  async signer(item){
    this.props.history.push("/signer/"+item.id)
  }

  _tableActions = []
  _barActions = []

  // NEW TAB MENU
  async hapusPencarian(){
    try {
      var params = Object.assign({}, params=this.props.table.params)
      this.fetchData(params)
      await this.props.dispatch(initialize(this.service.name+'_search', {}));
    } catch(e){
    }
  }

  async handleSearching(values){
    try {
      var formData = this.props.tableFilter2
      var params = Object.assign({}, params=this.props.table.params)
      if(formData.npwp && formData.npwp.length > 0){
        var npwps = ""
        formData.npwp.map((d, index)=> {
          if(index == (formData.npwp.length - 1)){
            npwps += d.npwp
          } else {
            npwps += d.npwp  + ","
          }
        })
        params['organizationNpwp.in'] = npwps
      } else { delete params['organizationNpwp.in'] }
      if(formData.nama){
        params['nama.contains'] = formData.nama
      } else { delete params['nama.contains'] }
      this.fetchData(params)
    } catch(e){
      iziToast.info({
        title: "Pencarian",
        message: "Data tidak ditemukan, " + e.message
      })
    }
  }

  defaultSearchForm(){
    const { handleSubmit, submitting, valid, pristine } = this.props;
    return (
      <div className="md-grid" style={{ overflow: 'auto' }}>
        <Field
          label        = 'Organization'
          name         = 'npwp'
          className    = "md-cell md-cell--12"
          component    = {Multiselect}
          valueField   = 'parent'
          viewField    = 'nama'
          service      = {OrganizationService}
          remoteSearch = {true}
        />
        <Field
          label     = 'Nama'
          name      = 'nama'
          className = "md-cell md-cell--12"
          component = {Textfield}
        />
        <hr />
        <Button raised primary className="md-cell md-cell--12" onClick={handleSubmit(this.handleSearching.bind(this))}>Cari</Button>
        <hr />
        <Button raised primary className="md-cell md-cell--12" onClick={handleSubmit(this.hapusPencarian.bind(this))}>Hapus Pencarian</Button>
      </div>
    )
  }
  // yg ditambah
  // NEW TAB MENU

  // _tableActions = [
  //   {
  //     label:"More Info",
  //     iconClassName:"mdi mdi-bell",
  //     onClick: (item) => this.editItem(item)
  //   },
  //   {
  //     label:"Add Certificate",
  //     iconClassName:"mdi mdi-bell",
  //     onClick: (item) => this.uploadCertificate(item)
  //   },
  //   {
  //     label:"Add Signer",
  //     iconClassName:"mdi mdi-bell",
  //     onClick: (item) => this.signer(item)
  //   },
  //   {label:"divider", iconClassName:"-"},
  //   {
  //     label:"Delete",
  //     iconClassName:"mdi mdi-delete",
  //     onClick:(item, callback) => this.deleteItem(item, callback),
  //     confirmation:{
  //       title:"sentence.custom.deleteItem",
  //       message:"sentence.custom.deleteItemConfirmation"
  //     }
  //   }
  // ]

}
