import React, { Component } from 'react';
import { connect } from 'react-redux';
import {TabsContainer, Tabs, Tab} from 'react-md';
import { Field } from 'redux-form';

import FormDialog from './User.dialog.view';
import UserService from './User.service';
import ListView from '../../../../components/entity/listView';
import { Textfield } from '../../../../components/form'
import LogExportService from "../../Export/LogExport.service";
import download from "downloadjs";
import ParamService from "../../../../services/param.service";
import izitoast from 'izitoast';

export class UserView extends ListView {
  service=UserService;
  FormDialog=FormDialog

  userPath='user'

  async handleExportAll() {
    let selected = this.props.table.selected

    if (selected.length > 0) {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export CSV',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: 'Apakah anda yakin akan meng-export CSV data yang diceklis?'
      }));

      if(dialog) {
        var ids = [];
        selected.map(function (i) {
          ids.push(i.id)
        })

        var res = await LogExportService.api.exportBulk(`${this.service.path}`, ids);
        var filename = res.headers.filename
        download(res.data, filename);
      }
    }
    else {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export CSV',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: (
          <div>
            <this.ig.Field
              label='Nama Depan'
              name='firstName__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Nama Belakang'
              name='lastName__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Login'
              name='login__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Email'
              name='email__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
          </div>
        )
      }));

      if (dialog === false)
        return

      if (dialog === undefined)
        dialog = {}

      if (dialog) {
        let param = ParamService.convert(dialog)

        let res = await LogExportService.api.exportByParamCsv(`${this.service.path}/${this.userPath}/${this.props.match.params.companyId}`, param);

        let filename = res.headers.filename;

        download(res.data, filename);
      }
    }
  }

  async handleResetCache() {
    let selected = this.props.table.selected

    if (selected.length > 0) {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Reset Cache',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: 'Apakah anda yakin akan reset cache user yang diceklis?'
      }));

      if(dialog) {
        var ids = [];

        selected.map(function (i) {
          ids.push(i.id)
        })

        let companyId = this.props.match.params.companyId

        var res = await UserService.api.resetCache(companyId, ids);

        izitoast.success({title: 'Sukses', message: res.data.message})
      }
    }
  }

  async handleExportOrganizationUser() {
    let companyId = this.props.match.params.companyId

    let selected = this.props.table.selected

    if (selected.length > 0) {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export CSV Organization User',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: 'Apakah anda yakin akan meng-export CSV data yang diceklis?'
      }));

      if(dialog) {
        var ids = [];
        selected.map(function (i) {
          ids.push(i.id)
        })

        var res = await UserService.api.exportOrganizationUserBulk(companyId, ids);
        var filename = res.headers.filename
        download(res.data, filename);
      }
    }
    else {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export CSV Organization User',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: (
          <div>
            <this.ig.Field
              label='Login'
              name='userLogin__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='First Name'
              name='userFirstName__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Last Name'
              name='userLastName__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Email'
              name='userEmail__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
          </div>
        )
      }));

      if (dialog === false)
        return

      if (dialog === undefined)
        dialog = {}

      if (dialog) {
        let param = ParamService.convert(dialog)

        let res = await UserService.api.exportOrganizationUserParam(companyId, param);

        let filename = res.headers.filename;

        download(res.data, filename);
      }
    }
  }

  barActions() {
    return [
      ...this._barActions,
      {
        label:'word.exportCsv',
        iconClassName:'mdi mdi-file-excel',
        // disabledFunc:() => this.props.table.selected.length === 0,
        onClick:() => this.handleExportAll()
      },
      {
        label:'word.exportCsvOrganizationUser',
        iconClassName:'mdi mdi-account-group',
        // disabledFunc:() => this.props.table.selected.length === 0,
        onClick:() => this.handleExportOrganizationUser()
      },
      {
        label:'word.resetCache',
        iconClassName:'mdi mdi-alert-decagram',
        disabledFunc:() => this.props.table.selected.length === 0,
        onClick:() => this.handleResetCache()
      },
    ]
  }

  columns=[
    {label: "word.firstName", value: "firstName", isDefaultSort:true, isSortable:true, show:true, isSearchable:true, isDefaultSearchColumn:true},
    {label: "word.lastName", value: "lastName", isDefaultSort:true, isSortable:true, show:true, isSearchable:true, isDefaultSearchColumn:true},
    {label: "word.login", value: "login", isDefaultSort:true, isSortable:true, show:true, isSearchable:true, isDefaultSearchColumn:true},
    {label: "word.email", value: "email", isSortable:true, className: "mpk-font-size-S", show:true, isSearchable:true},
    {label: "word.company", value: "company.nama", isSortable:true, className: "mpk-font-size-S", show:true, isSearchable:true},
    {label: "word.active", value: "activated", isSortable:true, type: 'boolean', onClick:() => {}, show:true, isSearchable:true},
    {label: "word.adminView", value: "adminView", isSortable:true, type: 'boolean', onClick:() => {}, show:true, isSearchable:true},
    {label: "word.banned", value: "banned", isSortable:true, type: 'boolean', onClick:() => {}, show:true, isSearchable:true},
  ]

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='Nama Depan'
          name='firstName__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Nama Belakang'
          name='lastName__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Login'
          name='login__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Email'
          name='email__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
      </this.SearchContainer>
    )
  }

  view1() {
    return (
      <this.viewContainer>
        {this.commandBar()}
        <div className="flex mpk-layout mpk-padding-N all">
          {this.tableView()}
          {this.tableSetting()}

          <this.FormDialog
            location={this.props.location}
            height={400}
            visible={this.state.showForm}
            add={this.props.tableActions.addDataItem}
            formData={this.state.formData}
            onSuccess={()=> this.fetchData()}
            onClose={() => this.setState({showForm:false})}
          />

          {this.dialogConfirm()}
        </div>
      </this.viewContainer>
    )
  }

  deleteItem = async (item, callback) => {
    try {
      await this.service.api.delete(item.id);
      callback()
      await this.fetchData()
    } catch(e) {
      callback(e, e)
    }
  }

}

export default connect(UserService.stateConnectSetting(), UserService.actionConnectSetting())(UserView)
