import ApiService from '../../../../../services/api.service'
import http from '../../../../../services/http.service'
import moment from 'moment'

class LogCheckService extends ApiService {
  name= 'LogCheck'
  path= 'ebupot/log/log/check'
  repoType="QueryDsl"
  advancedSearch=true
  
  columns=[
    {label: "word.id", searchField: "id", value: "id", isDefaultSort:false, isSortable:true, show:false, isSearchable:true},
    {label: "word.sptId", searchField: "sptId", value: "sptId", isDefaultSort:false, isSortable:true, show:false, isSearchable:true},
    {label: "word.xmlId", searchField: "xmlId", value: "xmlId", isDefaultSort:false, isSortable:true, show:false, isSearchable:true},
    {label: "word.success", searchField: "success", value: "success", type: 'boolean', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.status", searchField: "status", value: "status", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.masa", searchField: "masa", value: "masa", type:'number', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.tahun", searchField: "tahun", type: 'func', value: (d)=> { return d.tahun }, isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.nama", searchField: "nama", value: "nama", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.npwp", searchField: "npwp", value: "npwp", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.pembetulan", searchField: "pembetulan", value: "pembetulan", type: 'number', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.sebelumnya", searchField: "sebelumnya", value: "sebelumnya", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.message", searchField: "message", value: "message", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.createdBy", searchField: "createdBy", value: "createdBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.createdDate", searchField: "createdDate", value: (d)=> {
      if(d){
        return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
      } else {
        return "-"
      }
    }, type: 'func', isDefaultSort:true, isSortable:true, show:true, isSearchable:true},
    {label: "word.lastModifiedBy", searchField: "lastModifiedBy", value: "lastModifiedBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.lastModifiedDate", searchField: "lastModifiedDate", value: (d)=> {
      if(d){
        return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
      } else {
        return "-"
      }
    }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
  ]

  constructor(){
    super()
    this.init()
    this.initApi()
  }

  api = {
    download: async(fileId)=> {
      let res = await http.get(`/api/ebupot/log/log/check/${fileId}/download`, {
        responseType: 'blob'
      })
      return res
    }
  }

}

export default new LogCheckService();