import React, { Component } from 'react';
import { connect } from 'react-redux';

import SettingEmailService from './SettingEmail.service';
import ListView from '../../../../../components/entity/listView';
import ColumnService from '../../../../../services/column.service';

@connect(SettingEmailService.stateConnectSetting(), SettingEmailService.actionConnectSetting())
export default class SettingEmailView extends ListView {
  service=SettingEmailService
  editDialog=false

  _barActions = [
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => this.fetchData()
    },
    {
      label:'word.delete',
      iconClassName:'mdi mdi-delete',
      onClick:() => {
        this.setState({showDialogConfirmDeleteSelected:true})
      },
      disabledFunc:() => this.props.table.selected.length === 0
    },
  ]

  editItem = (item) => {
    this.props.history.push("SETTING_EMAIL"+"/"+item.company.id)
  }

  columns=[
    {isSortable: true, label: "word.id", searchField: "id", value: function(d){ return d.id }, type: "func",  show:false, isSearchable:true},
    {isSortable: true, label: "word.company", searchField: "company", value: function(d){ return d.company.nama }, type: "func",  show:true, isSearchable:true},
    {isSortable: true, label: "word.host", searchField: "host", value: function(d){ return d.host }, type: "func",  show:true, isSearchable:true},
    {isSortable: true, label: "word.port", searchField: "port", value: function(d){ return d.port }, type: "func",  show:true, isSearchable:true},
    {isSortable: true, label: "word.from",searchField: "from",  value: function(d){ return d.from }, type: "func",  show:true, isSearchable:true},
    {isSortable: true, label: "word.username",searchField: "username",  value: function(d){ return d.username }, type: "func",  show:false, isSearchable:true},
    {isSortable: true, label: "word.password",searchField: "password",  value: function(d){ return d.password }, type: "func",  show:false, isSearchable:true},
    {isSortable: true, label: "word.protocol",searchField: "protocol",  value: function(d){ return d.protocol }, type: "func",  show:false, isSearchable:true},
    {isSortable: true, label: "word.tls",searchField: "tls",  value: 'tls', type: "boolean",  show:true, isSearchable:true},
    {isSortable: true, label: "word.auth",searchField: "auth",  value: 'auth', type: "boolean",  show:false, isSearchable:true}
  ]
}
