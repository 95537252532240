import React, { Component } from 'react';
import { connect } from 'react-redux';

import {Field, getFormValues, reduxForm} from 'redux-form';
import { Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab } from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Switch as Switch2} from '../../../../../../components/form';

import ImportBP2326Service from './ImportBP2326.service';
import ListView from '../../../../../../components/entity/listView';
import ColumnService from '../../../../../../services/column.service';
// import ImportService from './../Import/Import.service';
import axios from 'axios';
import izitoast from 'izitoast';
import FormDialog from './ImportBP2326.dialog';
import download from "downloadjs";
import ListViewImport from '../../../../../../components/entity/ListViewImport';
import ImportBP23Service from "../ImportBP23/ImportBP23.service";

@reduxForm({form: 'ImportBP2326Form', destroyOnUnmount: true})
@connect((state) => ({
  ...ImportBP2326Service.stateConnectSetting()(state),
  formData: getFormValues('ImportBP2326Form')(state)
}), ImportBP2326Service.actionConnectSetting())
export default class ImportBP2326View extends ListViewImport {
  service=ImportBP2326Service
  FormDialog=FormDialog

  category='BP_2326'
  importPath='/api/import/spt/23'
  showAutomatisTab=false
  showPenomoranAuto=true
  showFormatEspt=true
  showPembetulan=true
  // viewType=2

  beforeFetch(params) {
    params["category.equals"] = "BP_2326"
  }

  async handleDownloadTemplate() {
    var report = await ImportBP2326Service.api.downloadTemplate()
    download(report.data, 'TEMPLATE_BP_2326.csv');
  }

}
