import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab } from 'react-md';

import FormDialog from './LogImportCsvByApp.dialog.view';
import LogImportCsvByAppService from './LogImportCsvByApp.service';
import ListView from '../../../../components/entity/listView';
import axios from "axios";
import izitoast from "izitoast";
import Bluebird from "bluebird";
import ValidationCsvByAppService from "../../Import/ValidationCsvByApp.service";
import download from "downloadjs";
import {getFormValues, reduxForm} from "redux-form";

@reduxForm({form: 'LOG_IMPORT_CSV_BY_APP_Form', destroyOnUnmount: true})
@connect((state) => ({
  ...LogImportCsvByAppService.stateConnectSetting()(state),
  formData: getFormValues('LOG_IMPORT_CSV_BY_APP_Form')(state)
}), LogImportCsvByAppService.actionConnectSetting())
export default class LogImportCsvByAppView extends ListView {
  service=LogImportCsvByAppService
  FormDialog=FormDialog

  tableActions = (d) => {
    var items = [{
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    }]

    if(d.status === 'FAILED') {
      items.push({
        label:"Download CSV",
        iconClassName:"mdi mdi-bell",
        onClick: (item) => this.downloadCsv(item)
      })
    }

    if(d.status === 'FAILED' || d.status === 'FINISH') {
      items.push({
        label:"Delete",
        iconClassName:"mdi mdi-delete",
        onClick: (item) => this.handleDeleteImport(item)
      })
    }

    return items;
  }

  _barActions = [
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => this.fetchData()
    },{
      label:'word.delete',
      iconClassName:'mdi mdi-delete',
      onClick: async () => {
        await this.handleDeleteImportSelected()
      },
      disabledFunc:() => this.props.table.selected.length === 0
    },
  ]

  async handleDeleteImport(d) {

    var dialog = await this.context.showDialog((props, res, rej) =>({
      title: 'Delete',
      initialValue: _.cloneDeep({}),
      width: 400,
      text: 'Apakah anda yakin akan menghapus data ini?'
    }));

    if(dialog) {
      await axios.delete('/api/upload-by-app/'+d.upload.id);
      izitoast.success('Data telah terhapus')
      this.fetchData()
    }

  }

  async handleDeleteImportSelected() {
    var dialog = await this.context.showDialog((props, res, rej) =>({
      title: 'Delete',
      initialValue: {},
      height: 'auto',
      width: 400,
      okText: 'Ya',
      text: 'Apakah anda yakin akan menghapus data yang terpilih?'
    }));

    if(dialog) {

      var ids = this.props.table.selected.reduce((p, c)=> {
        if(c.status == 'FINISH' || c.status == 'FAILED') {
          p.push(c.upload.id)
        }
        return p;
      }, []);

      await Bluebird.mapSeries(ids, async (d, i)=> {
        await axios.delete('/api/upload-by-app/'+d);
      })

      izitoast.success('Data telah terhapus')
      this.fetchData()

    }
  }

  async downloadCsv(d) {
    var file = await ValidationCsvByAppService.api.downloadCsv(d.upload.id);

    var filename = file.headers.filename
    download(file.data, filename);
  }

  defaultColumns = [
    {isSortable:true, label: "word.id",  value: "id", show:false, isSearchable:true, isDefaultSort: false},
  ]

  columns=[
    {isSortable: true, label: "word.id",  value: "upload.id", isDefaultSort:false, show:true, isSearchable:true},
    {isSortable: true, label: "word.category",  value: "category", show:true, isSearchable:true},
    {isSortable: true, label: "word.filename",  value: "upload.originalFilename", show:true, isSearchable:true, searchField: 'filename'},
    {isSortable: true, label: "word.startDate",  value: "startDate",  type: "date", show:true, isSearchable:true},
    {isSortable: true, label: "word.endDate",  value: "endDate", type: "date", isDefaultSort:false, show:true, isSearchable:true},
    {isSortable: true, label: "word.status", searchField: 'status', type:'func',  value: (d)=> {
        if(d.status === 'FINISH') {
          return <Button flat swapTheming primary style={{backgroundColor: '#4CAF50'}}>FINISH</Button>
        } else if(d.status === 'FAILED') {
          return <Button tooltipLabel="Click to download csv" onClick={()=> this.downloadCsv(d)} flat swapTheming primary style={{backgroundColor: '#F44336'}}>FAILED</Button>
        } else {
          return <Button flat>{d.status}</Button>
        }
      }, isDefaultSort:false, show:true, isSearchable:false},
    {isSortable: true, label: "word.message",  value: "message", show:true, isSearchable:true},
    {isSortable: true, label: "word.step",  value: "step", isDefaultSort:false, show:true, isSearchable:true, type: "number"},
    {isSortable: true, label: "word.count",  value: "count", isDefaultSort:false, show:true, isSearchable:true, type: "number"},
    {isSortable: true, label: "word.total",  value: "total", isDefaultSort:false, show:true, isSearchable:true, type: "number"},
    {isSortable: true, label: "word.application",  searchField: 'applicationName', type:'func',  value: (d)=> {
        return d.application.name
    }, isDefaultSort:false, show:true, isSearchable:false},
  ]
}
