import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import {Divider, Subheader, CardTitle,
  Button,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  Tab,
  Tabs,
  SelectionControlGroup,
  Checkbox
} from 'react-md';

import FormView from '../../../../../components/entity/form.view';
import BP21SATUMASAService from './BP_21_SATU_MASA.service';
import NomorBPService from '../nomorBP.service';
import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Radio} from '../../../../../components/form';
import UtilService from '../../../../../services/utils.service'
import NegaraService from './../../../Master/MasterNegara/MasterNegara.service';
import NpwpService from './../../NpwpService';
import FormViewSpt from '../../../../../components/entity/form.view.spt';
import LawanTransaksiDialog from '../../../../../components/entity/LawanTransaksi.dialog';
import MasterLawanTransaksiPpService from '../../../Master/MasterLawanTransaksiPp/MasterLawanTransaksiPp.service';

@reduxForm({form: 'BP_21_SATUMASA_Form', destroyOnUnmount: true})
@connect((state) => ({
  access: state.entity.access.api.find,
  organization: state.auth.currentOrganization,
  spt: state.auth.currentSPT,
  formData:state.form.BP_21_SATUMASA_Form
}))
export default class BP21SATUMASAForm extends FormViewSpt {
  service=BP21SATUMASAService
  viewType =2;

  typingObj=null;
  typingTimeout=1000;

  constructor(props){
    super(props)
    this.state = {
      ...this.state,
      npwpNotValid: true,

      lkDialog: false
    }
  }

  initialData= {
    "bruto": 0,
    "kode": "21-100-01",
    "kodeNegara": "",
    "nama": null,
    "npwp": null,
    "pph": 0,
    "spt21": {
    },
    "wpAsing": false
  }

  async initData() {
    if(this.props.match.params.id == 'new') {
      this.initialData = {
        ...this.initialData,
        spt21: this.props.spt.data
      }
      this.props.initialize(this.initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id);
      this.props.initialize(res.data);
      // this.npwpValidation(null, res.data.npwp)
      this.setState({npwpNotValid: false})
    }
  }

  async npwpValidation(evt, value){
    if(this.typingObj) clearTimeout(this.typingObj);

    this.typingObj = setTimeout(async ()=> {
      if(value){
        var res = await NpwpService.api.validate(value)
        if(res.valid){
          this.setState({npwpNotValid: false})

          var lk = await MasterLawanTransaksiPpService.api.page({
            'npwp.equals': value,
            'organizationId.equals': this.props.spt.data.organization.id
          })

          if(lk.data) {
            var formData = {...this.props.formData.values};
            if(lk.data.length > 0) {
              var d = lk.data[0];
              formData.nama = d.nama;
              formData.npwp = d.npwp;
              formData.wpAsing = d.wpLuarNegeri;
              formData.kodeNegara = d.kodeNegara;
            } else {
              formData.nama = '';
              formData.wpAsing = false;
              formData.kodeNegara = null;

              this.toast.warning({title: "Lawan Transaksi", message: `Lawan Transaksi dengan npwp ${value} tidak ditemukan`})
            }
            this.props.initialize(formData);
          }
        } else {
          this.setState({npwpNotValid: true})
          this.toast.warning({title: "Validasi Npwp", message: "npwp tidak valid"})
        }
      }
    }, this.typingTimeout);
  }

  async beforeSave(value) {
    var validate = true

    return validate
  }

  formView() {
    var npwpNotValid = this.state.npwpNotValid
    var hideNegara = false;
    if(this.props.formData){
      if(this.props.formData.values){
        if(this.props.formData.values.wpAsing){
          hideNegara = true
        }
      }
    }
    return (
      <div>
         <LawanTransaksiDialog
          spt={this.props.spt.data}
          onSelect={(d)=> {
            var formData = {...this.props.formData.values};

            formData.nama = d.nama;
            formData.npwp = d.npwp;
            formData.wpAsing = d.wpLuarNegeri;
            formData.kodeNegara = d.kodeNegara;

            this.setState({npwpNotValid: false})

            this.props.initialize(formData);

          }}
          service={MasterLawanTransaksiPpService}
          visible={this.state.lkDialog}
          onHide={()=> this.setState({lkDialog: false})}
        />
        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='A. IDENTITAS PENERIMA PENGHASILAN YANG DIPOTONG ' />
          <Divider/>

          <div className='md-grid'>
            <div className="md-cell md-cell--6">
              <div className='mpk-layout'>
                <Field
                  className='flex'
                  label='NPWP'
                  name='npwp'
                  component={TextfieldMask}
                  maskFormat="##.###.###.#-###-###"
                  mask="_"
                  length={15}
                  onChange={this.npwpValidation.bind(this)}
                  validate={validation.required}
                />
                <Button icon primary swapTheming style={{marginTop: 10}} onClick={()=> this.setState({lkDialog: true})}>search</Button>
              </div>
              <Field
                label='Nama'
                name='nama'
                component={Textfield}
                disabled={npwpNotValid}
                validate={validation.required}
              />
              {/* <Field
                label='NIK'
                name='nik'
                component={Textfield}
                validate={validation.required}
              />
              <Field
                label='Alamat'
                name='alamat'
                component={Textfield}
              />
              <Field
                label='Jenis Kelamin'
                name='jenisKelamin'
                component={Searchfield}
                validate={validation.required}
                options={[{id: 'L', name: 'Laki-laki'}, {id:'P', name: 'Perempuan'}]}
              /> */}
            </div>

            <div className="md-cell md-cell--6">
            {/* <Field
                label='Status PTKP'
                name='statusPtkp'
                id="BP21A2Form-status"
                component={Searchfield}
                validate={validation.required}
                options={[
                  {id: 'K', name: 'K'},
                  {id: 'TK', name: 'TK'},
                  {id: 'K/I', name: 'K/I'},
                  {id: 'HB', name: 'HB'}
                ]}
              />
              <Field
                label='Jumlah Tanggungan'
                name='jmlPtkp'
                component={Searchfield}
                options={[{id: 0, name: '0'}, {id: 1, name: '1'}, {id: 2, name: '2'}, {id: 3, name: '3'}]}
              />

              <Field
                label='Nama Jabatan'
                name='jabatan'
                id="BP21A1Form-jabatan"
                component={Textfield}
              /> */}

              <Field
                label='Warga Negara Asing'
                name='wpAsing'
                component={Switch}
                disabled={npwpNotValid}
              />

              {hideNegara &&
              <Field
                label='Kode Negara Domisili'
                name='kodeNegara'
                component={Searchfield}
                service={NegaraService}
                disabled={npwpNotValid}
                valueField="code"
              />
              }

              {/* <Field
                label='Penandatangan dan Kuasa'
                name='penandaTangan'
                id="BP21A1Form-penandaTangan"
                component={Switch}
              /> */}
            </div>
          </div>
        </Card>
        <br/>

         <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='B. PPH PASAL 21/26 YANG DIPOTONG' />
          <Divider/>

          <DataTable plain style={{height: 250}}>
            <TableHeader>
              <TableRow>
                <TableColumn>Kode Objek Pajak</TableColumn>
                <TableColumn className='md-text-right'>Bruto(Rp)</TableColumn>
                <TableColumn className='md-text-right'>PPH Di Potong(Rp)</TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableColumn>
                  <Field
                    label='Objek Pajak'
                    name='kode'
                    component={Searchfield}
                    options={[{id: '21-100-01', name: '21-100-01'}, {id: '21-100-02', name: '21-100-02'}]}
                    validate={validation.required}
                    disabled={npwpNotValid}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    label='Bruto'
                    name='bruto'
                    money={true}
                    component={TextfieldMask}
                    validate={validation.required}
                    disabled={npwpNotValid}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    label='PPH'
                    name='pph'
                    money={true}
                    component={TextfieldMask}
                    validate={validation.required}
                    disabled={npwpNotValid}
                  />
                </TableColumn>
              </TableRow>
            </TableBody>
          </DataTable>
          <br/>
        </Card>
      </div>
    )
  }

}
