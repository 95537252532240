import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm, getFormValues} from 'redux-form';
import moment from 'moment';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  CardActions,
  Button,
  LinearProgress
} from 'react-md';

import FormView from '../../../../../components/entity/form.view';
// import NomorBPService from '../nomorBP.service';
import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Checkbox} from '../../../../../components/form';
import UtilService from '../../../../../services/utils.service'
// import SPT21SignerService from '../SPT21Signer/SPT21Signer.service';
import NpwpService from './../../NpwpService';
import FormViewSpt from '../../../../../components/entity/form.view.spt';
import terbilangService from '../../../../../services/terbilang.service';
import BP4a2DSJasaKonstruksiService from './BP4a2DSJasaKonstruksi.service';

@reduxForm({form: 'BP4a2DSJasaKonstruksiForm', destroyOnUnmount: true})
@connect((state) => ({
  access: state.entity.access.api.find,
  organization: state.auth.currentOrganization,
  spt: state.auth.currentSPT,
  formData:getFormValues('BP4a2DSJasaKonstruksiForm')(state)
}))
export default class BP4a2BSSewaTanahForm extends FormViewSpt {
  service=BP4a2DSJasaKonstruksiService
  viewType =2;

  initialData={
    bagA: {},
  }

  constructor(props) {
    super(props);

    this.state= {
      ...this.state,
      showCalculateProgress: false,
      npwpNotValid: true
    }
  }

  async npwpValidation(evt, value){
    if(value){
      var res = await NpwpService.api.validate(value)
      if(res.valid){
        this.setState({npwpNotValid: false})
      } else {
        this.setState({npwpNotValid: true})
        this.toast.warning({title: "Validasi Npwp", message: "npwp tidak valid"})
      }

      var nonNpwp = 0;
      if(value === '000000000000000' || value == '' || !value) {
        nonNpwp = 1;
      }

      var form = this.props.formData;
      var d = form.bagB;

      Object.keys(d).forEach((k, i) => {
        if(!(k == 'jmlBruto' || k == 'jmlPph' || k == 'terbilang')) {
          var x = d[k];

          x.nonNpwp = nonNpwp
        }
      })

      await this.props.change('bagB', d)
    }
  }

  async beforeSave(value) {
    if(value.totalBruto == 0 || value.totalBruto == '') {
      this.toast.warning({title:'Warning', message:'Isi bruto harus lebih besar dari 0'})
      return false;
    }
    return true;
  }

  async initData() {

    if(this.props.match.params.id == 'new') {
      let nomorBPReq = 0; //await nomorBP23Service.api.get(this.props.spt.data.organization.id)
      var nomorBP = "";

      // if(nomorBPReq.data.mode === 'PREF_SUF') {
      //   nomorBP = nomorBPReq.data.prefix+nomorBPReq.data.suffix;
      // } else {
      //   nomorBP = nomorBPReq.data.suffix+nomorBPReq.data.prefix;
      // }
      let signer = {}; //await SPT21SignerService.api.getSigner(this.props.spt.data.id);

      this.initialData = {
        ...this.initialData,
        bagA:{
          nama: this.props.organization.name,
          npwp: this.props.organization.npwp,
          alamat: this.props.organization.address
        },
        tanggal: new Date(), //.data.no
        "brutoPerencanaKonstruksi": 0,
        "pphPerencanaKonstruksi": 0,
        "brutoPelaksanaKonstruksi": 0,
        "pphPelaksanaKonstruksi": 0,
        "brutoPengawasKonstruksi": 0,
        "pphPengawasKonstruksi": 0,
        "totalBruto": 0,
        "totalPPh": 0,
        spt: this.props.spt.data,
      }
      this.props.initialize(this.initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id);
      this.props.initialize(res.data);
      // this.npwpValidation(null, res.data.bagA.npwp)
    }
  }

  async calculate(e,v,pv,f, parent) {
    await this.props.change(f, v);
    this.calculateTotal()
  }

  async calculateTotal() {
    var form = this.props.formData;
    var d = form;

    var jmlPph = 0;
    var jmlBruto = 0;

    Object.keys(d).forEach((k, i) => {
      if(k.search('bruto') >= 0 && k !== 'totalBruto') {
        var x = d[k];

        jmlBruto = jmlBruto+ parseFloat(x);
      }

      if(k.search('pph') >= 0 && k !== 'totalPPh') {
        var y = d[k];

        jmlPph = jmlPph+ parseFloat(y);
      }
    })

    jmlBruto = Math.floor(jmlBruto)
    jmlPph = Math.floor(jmlPph)

    this.props.change('totalBruto', jmlBruto)
    this.props.change('totalPPh', jmlPph);

    this.props.change('terbilang', terbilangService(parseInt(jmlPph)));

  }

  formView() {
    var formData = {bagB: {}};
    if(this.props.formData && this.props.formData.values) formData = this.props.formData.values
    return (
      <div>
        <Card>
          <div className="md-grid">
            <Field
              label='NTPN'
              name='ntpn'
              className="md-cell md-cell--6"
              component={Textfield}
              mask="_"
              length={16}
              maxLength={16}
              maskFormat="################"
              validate={validation.required}
            />
            <Field
              label='Tanggal Pemotongan'
              name='tanggal'
              className="md-cell md-cell--6"
              component={Datepicker}
              validate={validation.required}
            />
            {/* <Field
              label='Referensi'
              name='referensi'
              id="BP21FForm-referensi"
              className="md-cell md-cell--12"
              component={Textfield}
            /> */}
          </div>
        </Card>

        <br/>

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='A. WAJIB PAJAK' />
          <Divider/>
          <div className="md-grid">
            <div className='md-cell md-cell--6'>
              <Field
                disabled
                label='NPWP'
                name='bagA.npwp'
                id="BP21FForm-npwp"
                component={TextfieldMask}
                maskFormat="##.###.###.#-###-###"
                mask="_"
                length={15}
                onChange={this.npwpValidation.bind(this)}
                validate={validation.required}
              />
              <Field
                label='Nama'
                name='bagA.nama'

                disabled={this.state.npwpNotValid}
                component={Textfield}
                validate={validation.required}
              />
              {/* <Field
                label='NIK'
                name='bagA.nik'

                disabled={this.state.npwpNotValid}
                component={Textfield}
              /> */}
              {/* <Field
                label='Telepon'
                name='name'
                id="BP21FForm-name"
                component={Textfield} */}

            </div>
            <div className='md-cell md-cell--6'>
              {/* <Field
                label='Kode Poss'
                name='npwp'
                id="BP21FForm-codePoss"
                component={Textfield}
              /> */}
              <Field
                label='Alamat'
                name='bagA.alamat'

                disabled={this.state.npwpNotValid}
                component={Textfield}
                validate={validation.required}
              />
              {/* <Field
                label='Email'
                name='bagA.email'

                disabled={this.state.npwpNotValid}
                component={Textfield}
              /> */}
            </div>
          </div>
        </Card>

        <br/>

        {this.state.showCalculateProgress && <LinearProgress id='lin_pro' style={{margin: 0}} />}
        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='B. DETAIL' />
          <Divider/>

          {/* <div className="md-grid">
            <Field
              label='Alamat Unit Tanah dan/Atau Bangunan : (Luas Meter Persegi)'
              name='bagA.alamat'
              className='md-cell md-cell--12'
              component={Textfield}
            />
          </div> */}

          <DataTable plain fixedScrollWrapperStyle={{overflow: 'inherit'}} style={{overflow: 'inherit'}}>
            <TableHeader>
              <TableRow>
                <TableColumn>No.</TableColumn>
                <TableColumn>Uraian</TableColumn>
                <TableColumn className='md-text-right'>Jumlah Bruto Penjualan	(Rp)</TableColumn>
                <TableColumn className='md-text-right'>PPH(Rp)</TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableColumn>1.</TableColumn>
                <TableColumn>Perencana Konstruksi</TableColumn>
                <TableColumn>
                  <Field
                    name='brutoPerencanaKonstruksi'
                    component={TextfieldMask}
                    className='md-text-right'
                    money={','}
                    onChange={(e, v, vp, f) => this.calculate(e, v, vp, f, 'data1')}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    name='pphPerencanaKonstruksi'
                    component={TextfieldMask}
                    className='md-text-right'
                    money={','}
                    onChange={(e, v, vp, f) => this.calculate(e, v, vp, f, 'data1')}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>2.</TableColumn>
                <TableColumn>Pelaksana Konstruksi</TableColumn>
                <TableColumn>
                  <Field
                    name='brutoPelaksanaKonstruksi'
                    component={TextfieldMask}
                    className='md-text-right'
                    money={','}
                    onChange={(e, v, vp, f) => this.calculate(e, v, vp, f, 'data2')}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    name='pphPelaksanaKonstruksi'
                    component={TextfieldMask}
                    className='md-text-right'
                    money={','}
                    onChange={(e, v, vp, f) => this.calculate(e, v, vp, f, 'data2')}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>3.</TableColumn>
                <TableColumn>Pengawas Konstruksi</TableColumn>
                <TableColumn>
                  <Field
                    name='brutoPengawasKonstruksi'
                    component={TextfieldMask}
                    className='md-text-right'
                    money={','}
                    onChange={(e, v, vp, f) => this.calculate(e, v, vp, f, 'data3')}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    name='pphPengawasKonstruksi'
                    component={TextfieldMask}
                    className='md-text-right'
                    money={','}
                    onChange={(e, v, vp, f) => this.calculate(e, v, vp, f, 'data3')}
                  />
                </TableColumn>
              </TableRow>
              <TableRow style={{borderTop: '3px solid black'}}>
                <TableColumn></TableColumn>
                <TableColumn>Jumlah</TableColumn>
                <TableColumn>
                  <Field
                    name='totalBruto'
                    component={TextfieldMask}
                    className='md-text-right'
                    money={','}
                    disabled
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    name='totalPPh'
                    component={TextfieldMask}
                    className='md-text-right'
                    money={','}
                    disabled
                  />
                </TableColumn>
              </TableRow>
              <TableRow style={{borderTop: '3px solid black'}}>
              <TableColumn></TableColumn>
                <TableColumn>Terbilang</TableColumn>
                <TableColumn colSpan={2}>
                  <Field
                    label='Terbilang'
                    name='terbilang'
                    component={Textfield}
                    disabled
                  />
                </TableColumn>
              </TableRow>
            </TableBody>
          </DataTable>
        </Card>

        <br />
      </div>
    )
  }

}
