/**
 * @author Ismail Sunny
 * @email ismiletea@gmail.com
 * @create date 2018-01-17 10:39:10
 * @modify date 2018-01-17 10:39:10
 * @desc [description]
*/
import axios from 'axios';
import env from 'env';
import izitoast from 'izitoast';

import { loadProgressBar } from 'axios-progress-bar'

loadProgressBar()

izitoast.settings({
  timeout: 10000,
  pauseOnHover: true,
  resetOnHover: true,
});

export const configureHttp = function() {
  axios.defaults.baseURL = env.host.default;
  let token = localStorage.getItem('token');
  if(token) {
    token = JSON.parse(token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${token.access_token}`
    axios.defaults.withCredentials = true;
  }

  //handling error
  axios.interceptors.request.use(function (config) {
    return config;
  }, function (error) {
    izitoast.error({title: 'Warning', message: error})
    return Promise.reject(error);
  });

  // Add a response interceptor
  axios.interceptors.response.use(function (response) {
    return response;
  }, function (error) {

    var err = error.message;
    var title = 'Warning';
    if(error.response) {
      if(error.response.data) {
        err = error.response.data.error_description;
        if(error.response.statusText) err = error.response.statusText;
        if(error.response.data.message)  err = error.response.data.message;
        if(error.response.data.detail) err = error.response.data.detail
        if(error.response.data.title) title = error.response.data.title
        if(error.response.data.error_description) err = error.response.data.error_description
        if(error.response.data.fieldErrors) {
          if(error.response.data.message === 'error.validation') {
            title = "Error Validation"
            err = '';
            error.response.data.fieldErrors.forEach(element => {
              err += `${element.field} ${element.message}<br/>`
            });
          }
        }
      }
    }
    izitoast.error({title: title, message: err})

    return Promise.reject(error);
  });
}


export default axios;
