import ApiService from '../../../services/api.service';

class ApplicationInfoService extends ApiService {
  name= 'applicationInfo';
  path= 'application-info';

  constructor() {
    super();
    this.init()
  }

  api = {
    getApplicationInfo: async () => {
      var res = await this.http.get(`${this.apiLocation + "/" + this.path}`)

      return res;
    },
  }
}


export default new ApplicationInfoService();
