import ApiService from '../../../../../services/api.service';

class OrganizationUserEbupotService extends ApiService {
  name= 'organizationUserEbupot';
  path= 'ebupot/organization-users';

  constructor() {
    super();
    this.init()
  }
}


export default new OrganizationUserEbupotService();
