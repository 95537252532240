import React, { Component } from 'react';
import { connect } from 'react-redux';
import {TabsContainer, Tabs, Tab, Chip} from 'react-md';
import { Field } from 'redux-form';

import FormDialog from './Unit.dialog.view';
import UnitService from './Unit.service';
import ListView from '../../../components/entity/listView';
import { Textfield } from '../../../components/form'
import download from "downloadjs";
import izitoast from 'izitoast';
import LogExportService from "../Export/LogExport.service";
import ParamService from "../../../services/param.service";

export class UnitView extends ListView {
  service=UnitService;
  FormDialog=FormDialog

  async handleExportAll() {
    let selected = this.props.table.selected

    if (selected.length > 0) {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export CSV',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: 'Apakah anda yakin akan meng-export CSV data yang diceklis?'
      }));

      if(dialog) {
        var ids = [];
        selected.map(function (i) {
          ids.push(i.id)
        })

        var res = await LogExportService.api.exportBulk(`${this.service.path}`, ids);
        var filename = res.headers.filename
        download(res.data, filename);
      }
    }
    else {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export CSV',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: (
          <div>
            <this.ig.Field
              label='Kode Perusahaan'
              name='kodePerusahaan__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Nama Unit'
              name='namaUnit__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Kode Wilayah Bisnis'
              name='kodeWilayahBisnis__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Nama Wilayah Bisnis'
              name='namaWilayahBisnis__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='NPWP'
              name='npwp__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Nama Sesuai NPWP'
              name='namaSesuaiNpwp__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
          </div>
        )
      }));

      if (dialog === false)
        return

      if (dialog === undefined)
        dialog = {}

      if (dialog) {
        let param = ParamService.convert(dialog)

        let res = await LogExportService.api.exportByParamCsv(`${this.service.path}`, param);

        let filename = res.headers.filename;

        download(res.data, filename);
      }
    }
  }

  barActions() {
    return [
      ...this._barActions,
      {
        label:'word.exportCsv',
        iconClassName:'mdi mdi-file-excel',
        // disabledFunc:() => this.props.table.selected.length === 0,
        onClick:() => this.handleExportAll()
      },
    ]
  }

  columns=[
    {label: "word.kodePerusahaan",  value: "kodePerusahaan", show:true, isSearchable:true},
    {label: "word.namaUnit",  value: "namaUnit", show:true, isSearchable:true},
    {label: "word.kodeWilayahBisnis",  value: "kodeWilayahBisnis", show:true, isSearchable:true},
    {label: "word.namaWilayahBisnis",  value: "namaWilayahBisnis", show:true, isSearchable:true},
    {label: "word.npwp",  value: "npwp", show:true, isSearchable:true},
    {label: "word.namaSesuaiNpwp",  value: "namaSesuaiNpwp", show:true, isSearchable:true},
    {label: "word.report",  searchField: "status", show:true, isSearchable:true, type: 'func',
      value: (d)=> {
        if (!d.status)
          return <Chip label="TIDAK" style={{background: '#f44336'}} />

        return <Chip label="YA" style={{background: '#4CAF50'}} />
      }
    },
  ]

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='Kode Perusahaan'
          name='kodePerusahaan__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Nama Unit'
          name='namaUnit__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Kode Wilayah Bisnis'
          name='kodeWilayahBisnis__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Nama Wilayah Bisnis'
          name='namaWilayahBisnis__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='NPWP'
          name='npwp__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Nama Sesuai NPWP'
          name='namaSesuaiNpwp__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
      </this.SearchContainer>
    )
  }

  view1() {
    return (
      <this.viewContainer>
        {this.commandBar()}
        <div className="flex mpk-layout mpk-padding-N all">
          {this.tableView()}
          {this.tableSetting()}

          <this.FormDialog
            location={this.props.location}
            height={400}
            visible={this.state.showForm}
            add={this.props.tableActions.addDataItem}
            formData={this.state.formData}
            onSuccess={()=> this.fetchData()}
            onClose={() => this.setState({showForm:false})}
          />

          {this.dialogConfirm()}
        </div>
      </this.viewContainer>
    )
  }

  deleteItem = async (item, callback) => {
    try {
      await this.service.api.delete(item.id);
      callback()
      await this.fetchData()
    } catch(e) {
      callback(e, e)
    }
  }

}

export default connect(UnitService.stateConnectSetting(), UnitService.actionConnectSetting())(UnitView)
