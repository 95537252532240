import ApiService from '../../../../../services/api.service';

class ALLBP21A2Service extends ApiService {
  name= 'ALL_BP_21_A2';
  path= 'spt21/a2/organization';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api= {
    email: async (category, ids) => {
      return await this.http.post(`${this.apiLocation}/email?category=${category}`, ids)
    }
  }

}

export default new ALLBP21A2Service();
