import ApiService from '../../../../../services/api.service'
import http from './../../../../../services/http.service'
import moment from 'moment'

class SignerService extends ApiService {
  name= 'Signer'
  path= 'ebupot/signer'
  advancedSearch=true

  constructor(){
    super()
    this.init()
    this.initApi()
  }

  columns = [
    {label: "word.id", searchField: "id", value: "id", isDefaultSort:false, isSortable:true, show:false, isSearchable:true},
    {label: "word.npwpPenandaTangan", searchField: "npwpPenandaTangan", value: "npwpPenandaTangan", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.namaPenandaTangan", searchField: "namaPenandaTangan", value: "namaPenandaTangan", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.signAs", searchField: "signAs", value: "signAs", type: 'boolean', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.active", searchField: "active", value: "active", type: 'boolean', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.createdBy", searchField: "createdBy", value: "createdBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.createdDate", searchField: "createdDate", value: (d)=> {
      if(d){
        return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
      } else {
        return "-"
      }
    }, type: 'func', isDefaultSort:true, isSortable:true, show:true, isSearchable:true},
    {label: "word.lastModifiedBy", searchField: "lastModifiedBy", value: "lastModifiedBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.lastModifiedDate", searchField: "lastModifiedDate", value: (d)=> {
      if(d){
        return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
      } else {
        return "-"
      }
    }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
  ]

  api = {
    getByOrganization: async (orgId)=> {
      let res = await http.get(`/api/ebupot/signer/${orgId}/organization`)
      var active = {
        npwpPenandaTangan: '',
        namaPenandaTangan: '',
        signAs: false
      }
      if(res.data.length == 0) {
        iziToast.warning({
          title: counterpart("custom.warning"),
          message: counterpart("custom.signerKosongActive")
        })
      }
      if(res.data){
        var filter = res.data.filter((d)=> {
          return d.active == true
        })
        if(filter.length > 0){
          active = filter[0]
        } else {
          if(res.data.length > 0) {
            iziToast.warning({
              title: counterpart("custom.warning"),
              message: counterpart("custom.signerKosong")
            })
          }
        }
      }
      return active
    },
    getByOrganizationQuery: async(stringQuery)=> {
      var orgId = stringQuery.organizationId
      if(stringQuery['namaPenandaTangan.contains']){
        let res = await http.get(`/api/ebupot/signer/${orgId}/organization?namaPenandaTangan.contains=${stringQuery['namaPenandaTangan.contains']}`)
        return res
      } else {
        let res = await http.get(`/api/ebupot/signer/${orgId}/organization`)
        return res
      }
    },
    searchIDF: async (stringQuery)=> {
      let res = await this.http.get(`${this.apiLocation}/${this.path}?${stringQuery}`)
      return res.data[0]
    }
  }

}

export default new SignerService();
