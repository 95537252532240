import React, { Component } from 'react';
import { connect } from 'react-redux';

import FormDialog from './Master21Kap.dialog.view';
import Master21KapService from './Master21Kap.service';
import ListView from '../../../../../components/entity/listView';
import ColumnService from '../../../../../services/column.service';

@connect(Master21KapService.stateConnectSetting(), Master21KapService.actionConnectSetting())
export default class Master21KapView extends ListView {
  service=Master21KapService
  FormDialog=FormDialog

  columns=[
    {isSortable: true, label: "word.id",   searchField: "id",   value: function(d){ return d.id }, type: "func",  show:false, isSearchable:true},
    {isSortable: true, label: "word.code", searchField: "code", value: function(d){ return d.code }, type: "func",  show:true, isSearchable:true},
    {isSortable: true, label: "word.name", searchField: "name", value: function(d){ return d.name }, type: "func",  show:true, isSearchable:true},
  ]   
}
