import ApiService from '../../../../../../services/api.service';

class ExportCsv4a2LogService extends ApiService {
  name= 'ExportCsv4a2Log';
  path= 'log-export-csv/page';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api= {
    validasi: async (fileId, page) => {
      if(page){
        page = page
      } else {
        page = 0
      }
      var res = await this.http.get(this.apiLocation+'/validation-csv', {
        params: {
          fileId : fileId,
          page: page,
          size: 100
        }
      })

      return res.data;
    },
    getInfo: async (sptId) => {
      var res = await this.http.get(this.apiLocation+'/spt4a2/info/export/bp/'+sptId);
      return res;
    }
  }
}


export default new ExportCsv4a2LogService();
