import ApiService from '../../../../../../services/api.service';

class ImportLK15Service extends ApiService {
  name= 'ImportLK15';
  path= 'log-import-csv/page';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api= {
    validasi: async (fileId, page) => {
      if(page){
        page = page
      } else {
        page = 0
      }
      var res = await this.http.get(this.apiLocation+'/validation-csv', {
        params: {
          fileId : fileId,
          page: page,
          size: 100
        }
      })

      return res.data;
    },
    downloadTemplate: async ()=> {
      var res = await this.http.get(this.apiLocation+'/spt15/lt/download-template')

      return res;
    }
  }
}


export default new ImportLK15Service();
