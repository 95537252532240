import ApiService from '../../../../../services/api.service';

class Master21StatusKaryawanA1Service extends ApiService {
  name= 'Master21StatusKaryawanA1';
  path= 'master/spt21/status-karyawan-a1';

  constructor() {
    super();
    this.init()
  }
}


export default new Master21StatusKaryawanA1Service();
