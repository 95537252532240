import React from 'react';
import _ from 'lodash';
import {connect} from 'react-redux';
import counterpart from 'counterpart';
import {Field, reduxForm, getFormValues, change} from 'redux-form';
import moment from 'moment';
import download from 'downloadjs';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  CardActions,
  Button,
  LinearProgress,
  List,
  ListItem,
  FontIcon,
  DialogContainer
} from 'react-md';
import FormView from '../../../../components/entity/form.view';
import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Checkbox} from '../../../../components/form';
import UtilService from '../../../../services/utils.service'
import DataESSPService from './DataESSP.service';
import OrganizationService from '../../Administrator/Organization/Organization.service';
import { OrganizationView } from '../../Administrator/Organization/Organization.view';
import iziToast from 'izitoast';
import Bluebird from "bluebird";

@connect(OrganizationService.stateConnectSetting(), OrganizationService.actionConnectSetting())
class OrganizationPicker extends OrganizationView {
  viewType=2;

  _barActions = [
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => {

        window.location.hash = window.location.hash.split('?')[0]
        this.fetchData()
      }
    },
  ]

  _tableActions = [
    {
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    }
  ]

  _viewContainer(props) {
    return <div>
      {props.children}
    </div>
  }
}

@reduxForm({form: 'dataEssp', destroyOnUnmount: true, initialValues: {organizations: []}})
@connect((state) => ({
  ...OrganizationService.stateConnectSetting()(state),
  access      : state.entity.access.api.find,
  organization: state.auth.currentOrganization,
  spt         : state.auth.currentSPT,
  formData    : getFormValues('dataEssp')(state)
}))
export default class DataESSPForm extends FormView {
  service  = DataESSPService
  viewType = 2;

  translate = false

  titleHeader() {
    return 'Hitung Data ESSP';
  }

  initialData={
  }

  constructor(props) {
    super(props);

    this.state= {
      ...this.state,
      tahunOptions: [],
      showOrganizationPicker: false,
    }
  }

  async initData() {
    this.props.initialize({organizations:[]});
  }

  masaOptions = [
    {id:1, name: 'Januari'}, {id:2, name: 'Februari'}, {id:3, name: 'Maret'}, {id:4, name: 'April'}, {id:5, name: 'Mei'}, {id:6, name: 'Juni'},
    {id:7, name: 'Juli'}, {id:8, name: 'Agustus'}, {id:9, name: 'September'}, {id:10, name: 'Oktober'}, {id:11, name: 'November'}, {id:12, name: 'Desember'}
  ]

  _barItem() {
    return (
      <div>
        <Button secondary flat onClick={()=> {this.props.history.goBack()}} style={{marginRight: 16}}>Back</Button>

        <Button secondary raised primary onClick={() => this.handleCalculate()} style={{marginRight: 16}}>Hitung</Button>
      </div>
    )
  }

  async handleMultiSelectOrganization() {
    var formData = this.props.formData;
    var organizations = formData.organizations;
    this.props.table.selected.forEach((d, i)=> {
      var check = _.find(organizations, {id: d.id});
      if(!check) {
        organizations.push(d);
      }
    })

    this.props.change('organizations', organizations);
  }

  async handleDelete(i) {
    var formData = this.props.formData;
    var organizations = formData.organizations;
    organizations.splice(i, 1);
    this.props.change('organizations', organizations);
  }

  formView() {
    let kodeMap = [
      {
        name: 'pasal21',
        label: 'Pasal 21',
        child: ['411121 - 100', '411121 - 401', '411121 - 402', '411127 - 104']
      },
      {
        name: 'pasal23',
        label: 'Pasal 23',
        child: [
          '411124 - 100', '411124 - 101', '411124 - 102', '411124 - 103', '411124 - 104',
          '411127 - 100', '411127 - 101', '411127 - 102', '411127 - 103', '411127 - 104', '411127 - 105',
        ]
      },
      {
        name: 'pasal4a2',
        label: 'Pasal 4 (2)',
        child: [
          '411128 - 401', '411128 - 402', '411128 - 403', '411128 - 404', '411128 - 405', '411128 - 406', '411128 - 407', '411128 - 409',
          '411128 - 417', '411128 - 418', '411128 - 419'
        ]
      },
      {
        name: 'pasal15',
        label: 'Pasal 15',
        child: ['411129 - 101', '411128 - 410', '411128 - 411']
      },
      {
        name: 'pasal22',
        label: 'Pasal 22',
        child: ['411122 - 100', '411122 - 401', '411122 - 403']
      },
      {
        name: 'pasal25',
        label: 'Pasal 25',
        child: ['411126 - 100', '411126 - 300', '411126 - 310', '411126 - 320']
      },
    ]

    return (
      <div>
        <Card>
          <div className='md-grid'>
            <Button raised primary onClick={()=> {
              this.setState({showOrganizationPicker: true})
            }} >Multi Select Organization</Button>

            <DialogContainer
              id="simple-action-dialog"
              width={800}
              // height={586}
              contentStyle={{padding:0}}
              visible={this.state.showOrganizationPicker}
              onHide={()=> {
                this.setState({showOrganizationPicker: false})
              }}
              actions={[
                <Button flat onClick={async ()=> {
                  this.setState({showOrganizationPicker: false})
                }}>Close</Button>,
                <Button raised primary onClick={async ()=> {
                  await this.handleMultiSelectOrganization();
                  this.setState({showOrganizationPicker: false})
                }}>Confirm</Button>

              ]}
              // title="Change something?"
            >
              <OrganizationPicker />
            </DialogContainer>

            <List className="md-cell--12 md-paper md-paper--1">
              <Subheader primaryText={<div>Selected </div>} ></Subheader>

              {this.props.formData.organizations.map((d, i)=> {

                let primaryText = (i + 1) + '. ' + d.name

                let namaAlias = d.aliasName

                if (namaAlias != null) {
                  namaAlias = namaAlias.trim()

                  if (namaAlias !== '' && namaAlias !== '-') {
                    primaryText += ' - ' + namaAlias
                  }
                }

                return <ListItem
                  key={i}
                  rightIcon={<FontIcon onClick={()=> this.handleDelete(i)}>delete</FontIcon>}
                  primaryText={primaryText}
                  secondaryText={d.npwp}
                />

              })}
            </List>
          </div>

          <div className="md-grid">
            <Field
              className="md-cell md-cell--4"
              label='Semua Organisasi'
              name='allNpwp'
              component={Switch}
            />

            <Field
              label='Masa'
              name='month'
              className="md-cell md-cell--4"
              component={Searchfield}
              options={[
                {id:1, name: 'Januari'}, {id:2, name: 'Februari'}, {id:3, name: 'Maret'}, {id:4, name: 'April'}, {id:5, name: 'Mei'}, {id:6, name: 'Juni'},
                {id:7, name: 'Juli'}, {id:8, name: 'Agustus'}, {id:9, name: 'September'}, {id:10, name: 'Oktober'}, {id:11, name: 'November'}, {id:12, name: 'Desember'}
              ]}
              validate={validation.required}
            />

            <Field
              label='Tahun'
              name='year'
              className="md-cell md-cell--4"
              component={Textfield}
              validate={validation.required}
            />
          </div>

          {kodeMap.map((d)=> {
            return <div className='md-grid' style={{padding: 0, margin:0}}>
              <Divider style={{width: '100%'}}/>
              <Field
                label={d.label}
                name={d.name}
                className="md-cell md-cell--12"
                component={Checkbox}
                onChange={async (ev, v) => {
                  await Bluebird.map(d.child, async (c)=> {
                    let name = c.split(' - ');
                    await this.props.dispatch(change('dataEssp', `${name[0]}_${name[1]}`, v));
                  })
                }}
              />

              {d.child.map((c)=> {
                let name = c.split(' - ');
                return <Field
                  label={c}
                  name={`${name[0]}_${name[1]}`}
                  className="md-cell md-cell--4"
                  component={Checkbox}
                />
              })}
            </div>
          })}
        </Card>
      </div>
    )
  }

  // actions
  async handleCalculate() {
    var formData = this.props.formData

    if (!formData.allNpwp) {
      if (formData.organizations.length === 0) {
        iziToast.warning({
          title: 'Validasi',
          message: 'Anda harus memilih minimal 1 organisasi.'
        })

        return
      }
    }

    if (!formData.month) {
      iziToast.warning({
        title: 'Validasi',
        message: 'Input masa harus diisi.'
      })

      return
    }

    if (!formData.year) {
      iziToast.warning({
        title: 'Validasi',
        message: 'Input tahun harus diisi.'
      })

      return
    }

    let kodeSetorans = [];

    for (let key in formData) {
      if(formData[key] === true && key !== 'allNpwp') {
        kodeSetorans.push(key)
      }
    }

    let data = {}

    if(formData.organizations) {
      let organizationIds = [];

      formData.organizations.forEach(organization => {
        organizationIds.push(organization.id)
      })

      data.organizationIds = organizationIds
    }

    data.allNpwp = formData.allNpwp
    data.month = formData.month
    data.year = formData.year

    if(kodeSetorans.length) {
      data.kodeSetorans = kodeSetorans
    }

    try {
      await this.service.api.calculate(data)

      this.props.history.push('/EBILLING/DataESSP')
    } catch (e) {

    }

  }

}
