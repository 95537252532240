import ApiService from '../../../../services/api.service';

class BuktiPenerimaanService extends ApiService {
  name= 'BuktiPenerimaan';
  path= 'ebilling/buktiPenerimaans';

  api = {
    pullAll: async () => {
      var res = await this.http.get(`${this.apiLocation + "/" + this.path}/pull`)

      return res.data;
    },
    pullByInvoiceIds: async (data) => {
      var res = await this.http.post(`${this.apiLocation + "/" + this.path}/pull/invoiceIds`, data)

      return res.data;
    },
    findByMasterEtax: async (id) => {
      var res = await this.http.get(`${this.apiLocation + "/" + this.path}/etax/${id}`)

      return res.data;
    },
    print: async (id) => {
      var res = await this.http.get(`${this.apiLocation + "/" + this.path}/print/${id}`, {
        responseType: 'blob'
      })

      return res;
    },
  }

  constructor() {
    super()
    this.init()
    this.initApi()
  }
}


export default new BuktiPenerimaanService();
