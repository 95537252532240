import React, { Component } from 'react';
import { Grid, Cell } from 'react-md';
import { Field, reduxForm , propTypes, formValueSelector } from 'redux-form';
import { validation, Textfield, TextfieldMask, Datepicker, convert, Switch, Searchfield } from '../../../../../components/form';
import DialogView from '../../../../../components/entity/dialog.view';
import Master21Pasal17ProgService from './Master21Pasal17Prog.service';
import Master21Pasal17Service from './../Master21Pasal17/Master21Pasal17.service';
import TextField from 'react-md/lib/TextFields/TextField';

@reduxForm({form: 'master-21-pasal-17-prog', destroyOnUnmount: false})
export default class Master21Pasal17ProgDialogView extends DialogView {
  service=Master21Pasal17ProgService
  initialData = {
  }

  componentDidMount() {
    var paramUrl = window.location.hash.split("/")
    var param  = paramUrl[paramUrl.length - 1]
    this.initialData = {
      awal: 0,
      akhir: 0,
      lapis: 0,
      tarif: 0,
      adaAkhir: false,
      tarif21Pasal17: {
        id: param
      }
    }
    this.initData(this.props);
  }

  formView() {
    return (
      <Grid style={{padding:'none'}}>
        {/*<Field
          label='Tarif Pasal 17'ya
          name='tarif21Pasal17'
          className="md-cell md-cell--6"
          component={Searchfield}
          service={Master21Pasal17Service}
          valueField='parent'
          viewField='id'
          disabled={true}
          validate={validation.required}
        />*/}
        <Field
          label='Awal'
          name='awal'
          className="md-cell md-cell--6"
          component={TextfieldMask}
          money={true}
          normalize={convert.money}
          // validate={validation.required}
        />
        <Field
          label='Akhir'
          name='akhir'
          className="md-cell md-cell--6"
          component={TextfieldMask}
          money={true}
          normalize={convert.money}
          // validate={validation.required}
        />
        <Field
          label='Lapis'
          name='lapis'
          className="md-cell md-cell--6"
          component={TextfieldMask}
          validate={validation.required}
        />
        <Field
          label='Tarif'
          name='tarif'
          className="md-cell md-cell--6"
          component={TextfieldMask}
          validate={validation.required}
        />
        <Field
          label='Ada Akhir'
          name='adaAkhir'
          className="md-cell md-cell--6"
          component={Switch}
          // validate={validation.required}
        />
      </Grid>
    )
  }
}
