import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  Tab,
  Tabs,
  SelectionControlGroup,
  Checkbox,
  Button
} from 'react-md';

import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Radio, convert} from '../../../../../components/form';
import UtilService from '../../../../../services/utils.service'
import FormView from '../../../../../components/entity/form.view';
import DialogView from '../../../../../components/entity/dialog.view';
import ApplicationEmailService from './ApplicationEmail.service'
import moment from 'moment'
import counterpart from 'counterpart'
import UserService from './../User/User.service'
import iziToast from 'izitoast';

@reduxForm({form: 'ApplicationEmailForm', destroyOnUnmount: true})
@connect((state) => ({
  formData: getFormValues('ApplicationEmailForm')(state),
  user: state.auth.user
}))
export default class ApplicationEmailFormView extends FormView {
  service=ApplicationEmailService
  viewType=2;

  redirectTo=false

  initialData = {
      useProxy: false,
      protocol: 'smtp'
  }

  async initData() {
    try {
      let getApp = await this.service.api.getApp()
      let application = getApp.data.application.id
      if(this.props.match.params.id == 'new') {
        this.props.initialize(this.initialData);
      } else {
        let res = await this.service.api.getOne(application);
        this.props.initialize(res.data);
      }
    } catch(e){
      iziToast.warning({
        title: "Warning",
        message: "Applikasi tidak ditemukan, " + e.message
      })
    }
  }

  componentDidMount() {
    this.initData()
  }

  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;
    return (
      <div>
        <Button secondary flat onClick={()=> {this.props.history.push('/application')}} style={{marginRight: 16}}>Back</Button>
        <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>Save</Button>
      </div>
    )
  }

  formView() {
    var useProxy = false
    if(this.props.formData && this.props.formData.useProxy) useProxy = this.props.formData.useProxy
    return (
      <div>
        <Card>
          <div className='md-grid'>
            <Field
              label={counterpart('word.host')}
              name='host'
              className="md-cell md-cell--6"
              component={Textfield}
              validate={validation.required}
            />
            <Field
              label={counterpart('word.username')}
              name='username'
              className="md-cell md-cell--6"
              component={Textfield}
            />
            <Field
              label={counterpart('word.email')}
              name='from'
              className="md-cell md-cell--6"
              component={Textfield}
              validate={validation.required}
            />
            <Field
              label={counterpart('word.password')}
              name='password'
              className="md-cell md-cell--6"
              component={Textfield}
              type='password'
            />
            <Field
              label={counterpart('word.protocol')}
              name='protocol'
              className="md-cell md-cell--6"
              component={Textfield}
              validate={validation.required}
            />
            <Field
              label={counterpart('word.port')}
              name='port'
              className="md-cell md-cell--6"
              component={Textfield}
              validate={validation.required}
            />
            <Field
              label={counterpart('word.useProxy')}
              name='useProxy'
              className="md-cell md-cell--3"
              component={Switch}
            />
            <Field
              label={counterpart('word.enableTls')}
              name='tls'
              className="md-cell md-cell--3"
              component={Switch}
            />
            <Field
              label={counterpart('word.enableAuth')}
              name='auth'
              className="md-cell md-cell--3"
              component={Switch}
            />
            {useProxy &&
                <div className='md-grid' style={{padding: 0, margin: 0, width: '100%'}}>
                    <Field
                        label={counterpart('word.proxyHost')}
                        name='proxyHost'
                        className="md-cell md-cell--6"
                        component={Textfield}
                    />
                    <Field
                        label={counterpart('word.proxyUsername')}
                        name='proxyUsername'
                        className="md-cell md-cell--6"
                        component={Textfield}
                    />
                    <Field
                        label={counterpart('word.proxyPort')}
                        name='proxyPort'
                        className="md-cell md-cell--6"
                        component={Textfield}
                    />
                    <Field
                        label={counterpart('word.proxyPassword')}
                        name='proxyPassword'
                        className="md-cell md-cell--6"
                        type="password"
                        component={Textfield}
                    />
                    <Field
                        label={counterpart('word.proxyType')}
                        controlStyle={{display: 'inline-block'}}
                        name='proxyType'
                        className="md-cell md-cell--6"
                        type="password"
                        component={Radio}
                        controls={[
                            { label: 'HTTP', value: 'HTTP'}, 
                            { label: 'SOCKS', value: 'SOCKS'}
                        ]}
                    />
                </div>
            }
          </div>
        </Card>
      </div>
    )
  }
}


// {
//     "application": {
//     },
//     "auth": true,
//     "from": "string",
//     "host": "string",
//     "password": "string",
//     "port": 0,
//     "protocol": "string",
//     "tls": true,
//     "useProxy": true,
//     "username": "string"

//     "proxyHost": "string",
//     "proxyPassword": "string",
//     "proxyPort": 0,
//     "proxyType": "string",
//     "proxyUsername": "string",
//   }