import ApiService from '../../../../../services/api.service';

class FileLapor22Service extends ApiService {
  name= 'FileLapor22';
  path= 'spt22';
  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api={
    getSummary: async(sptId)=> {
      // return await this.http.get(`${this.apiLocation}/spt22/ssp-22/detail/${sptId}`)
      return await this.http.get(`${this.apiLocation}/${this.path}/spt22/info/${sptId}/lapor`)
    },
    cetakTest: async (sptId) => {
      return await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/lapor`)
    },
    cetak: async (sptId) => {
      return await this.http.get(`${this.apiLocation}/download/${sptId}`, {
        responseType: 'blob'
      })
    },
  }
}

export default new FileLapor22Service();
