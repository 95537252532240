import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Field, reduxForm } from 'redux-form';
import { Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab } from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Switch as Switch2} from '../../../../../../components/form';

import ListView from '../../../../../../components/entity/listView';
import ColumnService from '../../../../../../services/column.service';
// import ImportService from './../Import/Import.service';
import axios from 'axios';
import izitoast from 'izitoast';
import FormDialog from './ImportPBK4a2.dialog';
import ImportPBK4a2Service from './ImportPBK4a2.service';
import download from "downloadjs";
import ListViewImport from '../../../../../../components/entity/ListViewImport';

@reduxForm({form: 'ImportPBK4a2Form', destroyOnUnmount: true})
@connect(ImportPBK4a2Service.stateConnectSetting(), ImportPBK4a2Service.actionConnectSetting())
export default class ImportPBK4a2View extends ListViewImport {
  service=ImportPBK4a2Service
  FormDialog=FormDialog

  category='PBK_4A2'
  importPath='/api/import/spt/4a2'
  showAutomatisTab=false
  showPembetulan=true
  // viewType=2

  beforeFetch(params) {
    params["category.equals"] = "PBK_4A2"
  }

  async handleDownloadTemplate() {
    var report = await ImportPBK4a2Service.api.downloadTemplate()
    download(report.data, 'TEMPLATE_PBK_4A2.csv');
  }

}
