import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  Tab,
  Tabs,
  SelectionControlGroup,
  Checkbox
} from 'react-md';

import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Radio, convert, Multiselect} from '../../../../../components/form';
import UtilService from '../../../../../services/utils.service'
import FormView from '../../../../../components/entity/form.view';
import DialogView from '../../../../../components/entity/dialog.view';
import UserService from './User.service'
import moment from 'moment'
import counterpart from 'counterpart'

@reduxForm({form: 'UserForm', destroyOnUnmount: true})
@connect((state) => ({
  formData:state.form.UserForm,
}))
export default class UserFormView extends FormView {
  service=UserService
  viewType=2;

  formView() {
    return (
      <div>
        <Card>
          <div className='md-grid'>
            <Field
              label={counterpart('word.username')}
              name='login'
              className="md-cell md-cell--6"
              component={Textfield}
              validate={validation.required}
            />
            <Field
              label={counterpart('word.email')}
              name='email'
              className="md-cell md-cell--6"
              component={Textfield}
              validate={validation.email}
            />
            <Field
              label={counterpart('word.firstName')}
              name='firstName'
              className="md-cell md-cell--6"
              component={Textfield}
              validate={validation.required}
            />
            <Field
              label={counterpart('word.lastName')}
              name='lastName'
              className="md-cell md-cell--6"
              component={Textfield}
              validate={validation.required}
            />
            <Field
              label={counterpart('word.langKey')}
              name='langKey'
              className="md-cell md-cell--6"
              component={Searchfield}
              options={[
                {id: 'id', name: 'Indonesia'},
                {id: 'en', name: 'English'}
              ]}
              validate={validation.required}
            />
            <Field
              label={counterpart('word.activated')}
              name='activated'
              className="md-cell md-cell--6"
              component={Switch}
              validate={validation.required}
            />
            <Field
              label={counterpart('word.role')}
              name='authorities'
              className="md-cell md-cell--12"
              component={Multiselect}
              options={[
                {id: 'ROLE_ADMIN', name: 'ADMIN'},
                {id: 'ROLE_USER', name: 'USER'}
              ]}
              validate={validation.required}
            />
          </div>
        </Card>
      </div>
    )
  }
} 

