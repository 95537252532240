import React, { Component } from 'react';
import { connect } from 'react-redux';

import authService from '../../../../../services/auth.service';
import * as constant from '../../../../../config/constant';
import Spt25Service from './Spt25.service';
import ListViewSptRoot from '../../../../../components/entity/ListViewSptRoot';
import {Chip} from "react-md";

@connect((state)=> ({
  ...Spt25Service.stateConnectSetting()(state),
  user: state.auth.user,
  orgAccess: state.auth.orgAccess,
  company: state.auth.currentCompany
}), Spt25Service.actionConnectSetting())
export default class SPT25View extends ListViewSptRoot {
  service=Spt25Service
  FormDialog=()=> <div/>

  addDialog=false
  editDialog=false

  urlPath="SPT_25"
  formTypeName="PPH 25"
  shortPasalName="25"

  fetchOption() {
    var userAuth = authService.api.getAuth(this.props.user);
    var path = null;
    if(userAuth === constant.ROLE_OWNER) {
      path =  Spt25Service.path+'/company/page/'+this.props.company.id
    }

    if(this.props.match.params.companyId) path =  Spt25Service.path+'/company/page/'+this.props.match.params.companyId;
    // if(this.props.user.company && this.props.user.company.id) path =  SPT2126Service.path+'/company/page/'+this.props.user.company.id;

    return {
      path: path
    }
  }

  barActions() {
    return [
      {
        label:'word.create',
        iconClassName:'mdi mdi-plus',
        onClick:() => this.addItem()
      },
      {
        label:'word.refresh',
        iconClassName:'mdi mdi-refresh',
        onClick:async () => {
          window.location.hash = window.location.hash.split('?')[0]
          setTimeout( async ()=> {
            await this.fetchData()
          }, 500)

        }
      },
      {
        label:'word.delete',
        iconClassName:'mdi mdi-delete',
        onClick: async () => {
          await this.deleteSelected()
        },
        disabledFunc:() => this.props.table.selected.length === 0
      },
      {
        label:'word.pembetulan',
        iconClassName:'mdi mdi-wrench',
        onClick: async () => {
          await this.pembetulanSelected()
        },
        disabledFunc:() => this.props.table.selected.length === 0
      },
      {
        label:'word.exportCsv',
        iconClassName:'mdi mdi-file-excel',
        // disabledFunc:() => this.props.table.selected.length === 0,
        onClick:() => this.handleExportAll()
      },
      {
        label:'word.exportExcel',
        iconClassName:'mdi mdi-clipboard-text',
        // disabledFunc:() => this.props.table.selected.length === 0,
        onClick:() => this.handleExportExcel()
      },
      {
        label:'word.pengajuanApproval',
        iconClassName:'mdi mdi-clipboard-check-outline',
        disabledFunc:() => this.props.table.selected.length === 0,
        onClick:() => this.handleApproval()
      },
      {
        label:'word.approval',
        iconClassName:'mdi mdi-format-list-checks',
        disabledFunc:() => this.props.table.selected.length === 0,
        onClick:() => this.handleApproval('Approval')
      },
      {
        label:'word.cancelApproval',
        iconClassName:'mdi mdi-close',
        disabledFunc:() => this.props.table.selected.length === 0,
        onClick:() => this.handleCancelApproval()
      },
    ]
  }

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='Nama'
          name='nama__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Nama Alias'
          name='namaAlias__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='NPWP'
          name='npwp__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Dari Bulan'
          name='masa__greaterOrEqualThan'
          className="md-cell md-cell--6"
          component={this.ig.Searchfield}
          options={[
            {id: 1, name: "1"},
            {id: 2, name: "2"},
            {id: 3, name: "3"},
            {id: 4, name: "4"},
            {id: 5, name: "5"},
            {id: 6, name: "6"},
            {id: 7, name: "7"},
            {id: 8, name: "8"},
            {id: 9, name: "9"},
            {id: 10, name: "10"},
            {id: 11, name: "11"},
            {id: 12, name: "12"}
          ]}
        />
        <this.ig.Field
          label='Sampai Bulan'
          name='masa__lessOrEqualThan'
          className="md-cell md-cell--6"
          component={this.ig.Searchfield}
          options={[
            {id: 1, name: "1"},
            {id: 2, name: "2"},
            {id: 3, name: "3"},
            {id: 4, name: "4"},
            {id: 5, name: "5"},
            {id: 6, name: "6"},
            {id: 7, name: "7"},
            {id: 8, name: "8"},
            {id: 9, name: "9"},
            {id: 10, name: "10"},
            {id: 11, name: "11"},
            {id: 12, name: "12"}
          ]}
        />

        <this.ig.Field
          label='Bulan'
          name='masa__equals'
          className="md-cell md-cell--12"
          component={this.ig.Searchfield}
          options={[
            {id: 1, name: "1"},
            {id: 2, name: "2"},
            {id: 3, name: "3"},
            {id: 4, name: "4"},
            {id: 5, name: "5"},
            {id: 6, name: "6"},
            {id: 7, name: "7"},
            {id: 8, name: "8"},
            {id: 9, name: "9"},
            {id: 10, name: "10"},
            {id: 11, name: "11"},
            {id: 12, name: "12"}
          ]}
        />

        <this.ig.Field
          label='Tahun'
          name='tahun__equals'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Status SPT'
          name='status__equals'
          className="md-cell md-cell--12"
          component={this.ig.Searchfield}
          options={[
            {id: "FINISH", name: "FINISH"},
            {id: "WAITING", name: "WAITING"},
            {id: "PROCESS", name: "PROCESS"}
          ]}
        />
        <this.ig.Field
          label='FINISH'
          name='status__in_FINISH'
          className="md-cell md-cell--6"
          component={this.ig.Checkbox}
        />
        <this.ig.Field
          label='WAITING'
          name='status__in_WAITING'
          className="md-cell md-cell--6"
          component={this.ig.Checkbox}
        />
        <this.ig.Field
          label='PROCESS'
          name='status__in_PROCESS'
          className="md-cell md-cell--6"
          component={this.ig.Checkbox}
        />
      </this.SearchContainer>
    )
  }

  columns=[
    {isSortable: true, label: "word.name",  value: "nama", show:true, isSearchable:true},
    {isSortable: true, label: "word.aliasName",  value: "namaAlias", show:true, isSearchable:true},
    {isSortable: true, label: "word.npwp", value: "npwp", show:true, isSearchable:true},
    {isSortable: true, label: "word.month",  value: "masa", show:true, isSearchable:true, type: 'number'},
    {isSortable: true, label: "word.year",  searchField: "tahun", value: (d)=> d.tahun, show:true, isSearchable:true, type: 'func'},
    {isSortable: true, label: "word.pembetulan",  value: "pembetulan", show:true, isSearchable:true, type: 'number'},
    {
      searchField: "status",
      label: "word.statusSpt",
      type: 'func',
      value: (d)=> {
        var style = {
          background: 'grey'
        }
        switch (d.status) {
          case 'PROCESS':
            style.background = '#2196F3'
            break;
          case 'FINISH':
            style.background = '#4CAF50'
            break;
          default:
            break;
        }
        return <Chip label={d.status} style={style} />
      },
      className: "mpk-font-size-S", show:true, isSearchable:true
    },

    {isSortable: true, label: "word.currentState",  value: "currentState", show:true, isSearchable:true},
    // {isSortable: true, label: "word.pasal",  value: , show:false, isSearchable:true},

    // {isSortable: true, label: "word.step",  show:true, searchField: "step", value: (d)=> d.step, show:true, isSearchable:true, type: 'func'},

    {isSortable: true, label: "word.owner",  value: "createdBy", show:false, isSearchable:true},
    {isSortable: true, label: "word.createdAt",  value: "createdDate", show:false, isSearchable:true, isDefaultSort: true},
  ]
}
