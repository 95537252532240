import ApiService from '../../../../../services/api.service';

class FileLapor23Service extends ApiService {
  name= 'FileLapor23';
  path= 'spt23';
  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api={
    getSummary: async(sptId)=> {
      // return await this.http.get(`${this.apiLocation}/spt23/ssp-23-detail/${sptId}`)
      return await this.http.get(`${this.apiLocation}/${this.path}/info/${sptId}`)
    },

    cetakTest: async (sptId) => {
      return await this.http.get(`${this.apiLocation}/${this.path}/lapor/${sptId}`)
    },
    cetak: async (sptId) => {
      return await this.http.get(`${this.apiLocation}/download/${sptId}`, {
        responseType: 'blob'
      })
    },
  }
}

export default new FileLapor23Service();
