/**
 * Created by dwiargo on 11/29/17.
 */

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import LogEfillingView from './Efilling/LogEfilling.view';
import LogEfillingPpnView from './Efilling/LogEfillingPpn.view';
import LogEfillingBadanView from './Efilling/LogEfillingBadan.view';
import LogExportView from './Export/LogExport.view';
import SummaryForm from './Summary/Summary.form';
import SummaryAdminForm from './SummaryAdmin/SummaryAdmin.form';
import KalkulasiIndukForm from './KalkulasiInduk/KalkulasiInduk.form';
import EditProfileForm from './EditProfile/EditProfile.form';
import EditPasswordForm from './EditPassword/EditPassword.form';
import PengaturanNoBPView from './Setting/PengaturanNoBP/PengaturanNoBP.view';
import KeyView from './Setting/Key/Key.view';
import UnitView from './Unit/Unit.view';

import SPT23MainView            from "./Modules/EbupotModule/PPh/SPT23In/main";
import SPT23FormView            from './Modules/EbupotModule/PPh/SPT23/SPT23.form.view';
import SPT23View                from './Modules/EbupotModule/PPh/SPT23/SPT23.view';
import UserFormView             from './Modules/EbupotModule/User/User.form.view';
import UserView                 from './Modules/EbupotModule/User/User.view';
import ApplicationFormView      from './Modules/EbupotModule/Application/Application.form.view';
import ApplicationView          from './Modules/EbupotModule/Application/Application.view';
import ApplicationEmailFormView from './Modules/EbupotModule/ApplicationEmail/ApplicationEmail.form.view';
import ClientView               from './Modules/EbupotModule/Client/Client.view';
import OrganizationFormView     from './Modules/EbupotModule/Organization/Organization.form.view';
import OrganizationView         from './Modules/EbupotModule/Organization/Organization.view';
import OrganizationUserView     from './Administrator/Organization/UserEbupot/OrganizationUserEbupot.view';
import OrganizationUserForm     from './Administrator/Organization/UserEbupot/OrganizationUserEbupot.form';
import LogSubmitFormView        from './Modules/EbupotModule/LogSubmit/LogSubmit.form.view';
import LogSubmitView            from './Modules/EbupotModule/LogSubmit/LogSubmit.view';
import LogCheckFormView         from './Modules/EbupotModule/LogCheck/LogCheck.form.view';
import LogCheckView             from './Modules/EbupotModule/LogCheck/LogCheck.view';
import LogFileValidasiView      from './Modules/EbupotModule/LogFileValidasi/LogFileValidasi.view';
import LogFileCsvView           from './Modules/EbupotModule/LogFileCsv/LogFileCsv.view';
import LogFileView              from './Modules/EbupotModule/LogFile/LogFile.view';
import SignerFormView           from './Modules/EbupotModule/Signer/Signer.form.view';
import SignerView               from './Modules/EbupotModule/Signer/Signer.view';
import KodeNegaraFormView       from './Modules/EbupotModule/KodeNegara/KodeNegara.form.view';
import KodeNegaraView           from './Modules/EbupotModule/KodeNegara/KodeNegara.view';
import ProvincesFormView        from './Modules/EbupotModule/Provinces/Provinces.form.view';
import ProvincesView            from './Modules/EbupotModule/Provinces/Provinces.view';
import KodeBuktiPotongFormView  from './Modules/EbupotModule/KodeBuktiPotong/KodeBuktiPotong.form.view';
import KodeBuktiPotongView      from './Modules/EbupotModule/KodeBuktiPotong/KodeBuktiPotong.view';
import JenisDokumenFormView     from './Modules/EbupotModule/JenisDokumen/JenisDokumen.form.view';
import JenisDokumenView         from './Modules/EbupotModule/JenisDokumen/JenisDokumen.view';
import RegencyFormView          from './Modules/EbupotModule/Regencies/Regencies.form.view';
import RegencyView              from './Modules/EbupotModule/Regencies/Regencies.view';
import DistrictFormView         from './Modules/EbupotModule/Districts/Districts.form.view';
import DistrictView             from './Modules/EbupotModule/Districts/Districts.view';
import VillagesFormView         from './Modules/EbupotModule/Villages/Villages.form.view';
// const AsyncHome = asyncComponent(() => import("./Dashboard/Blank"));
import VillageView              from './Modules/EbupotModule/Villages/Villages.view';
import KodeBuktiPotong26View    from './Modules/EbupotModule/KodeBuktiPotong26/KodeBuktiPotong.view';
import AllEbupot23View          from './Modules/EbupotModule/AllEbupot23/AllEbupot23.view';
import AllEbupot26View          from './Modules/EbupotModule/AllEbupot26/AllEbupot26.view';
import Bupot23FormView          from './Modules/EbupotModule/Bupot23/Bupot23.form.view';
import Bupot26FormViewView      from './Modules/EbupotModule/Bupot26/Bupot26.form.view';
import Bupot23View              from './Modules/EbupotModule/Bupot23/Bupot23.view';
import Bupot26View              from './Modules/EbupotModule/Bupot26/Bupot26.view';
import Bupot23HistoryView       from './Modules/EbupotModule/Bupot23History/Bupot23History.view';
import Bupot26HistoryView       from './Modules/EbupotModule/Bupot26History/Bupot26History.view';
import Bupot23HistoryFormView   from './Modules/EbupotModule/Bupot23History/Bupot23History.form.view';
import Bupot26HistoryFormView   from './Modules/EbupotModule/Bupot26History/Bupot26History.form.view';
import Bupot23EmailView         from './Modules/EbupotModule/Bupot23Email/Bupot23Email.view';
import Bupot26EmailView         from './Modules/EbupotModule/Bupot26Email/Bupot26Email.view';
import Bupot23EmailFormView     from './Modules/EbupotModule/Bupot23Email/Bupot23Email.form.view';
import Bupot26EmailFormView     from './Modules/EbupotModule/Bupot26Email/Bupot26Email.form.view';
import LawanTransaksi26FormView from './Modules/EbupotModule/LawanTransaksi26/LawanTransaksi26.form.view';
import LawanTransaksi26View     from './Modules/EbupotModule/LawanTransaksi26/LawanTransaksi26.view';
import LawanTransaksi23FormView from './Modules/EbupotModule/LawanTransaksi23/LawanTransaksi23.form.view';
import LawanTransaksi23View     from './Modules/EbupotModule/LawanTransaksi23/LawanTransaksi23.view';
import Dashboard                from './Modules/EbupotModule/Dashboard/Blank';
import BpeView                  from './Modules/EbupotModule/PPh/Bpe/Bpe.view';
import BpeFormView              from './Modules/EbupotModule/PPh/Bpe/Bpe.form.view';

import Bp23EditFormView         from './Modules/EbupotModule/PPh/SPT23In/Bupot23InEdit/Bupot23InEdit.form.view';
import Bp23EditView             from './Modules/EbupotModule/PPh/SPT23In/Bupot23InEdit/Bupot23InEdit.view';
import Bp23PembetulanFormView   from './Modules/EbupotModule/PPh/SPT23In/Bupot23InPembetulan/Bupot23InPembetulan.form.view';
import Bp23PembetulanView       from './Modules/EbupotModule/PPh/SPT23In/Bupot23InPembetulan/Bupot23InPembetulan.view';
import Bp26EditFormView         from './Modules/EbupotModule/PPh/SPT23In/Bupot26InEdit/Bupot26InEdit.form.view';
import Bp26EditView             from './Modules/EbupotModule/PPh/SPT23In/Bupot26InEdit/Bupot26InEdit.view';
import Bp26PembetulanFormView   from './Modules/EbupotModule/PPh/SPT23In/Bupot26InPembetulan/Bupot26InPembetulan.form.view';
import Bp26PembetulanView       from './Modules/EbupotModule/PPh/SPT23In/Bupot26InPembetulan/Bupot26InPembetulan.view';

export default (actions, global) => (
  <Switch>
    <Route path="/user" exact component={require('./Administrator/User/User.view').default} />
    <Route path="/access-control" exact component={require('./Administrator/AccessControl/AccessControl.form').default} />
    <Route path="/access-control/:id" exact component={require('./Administrator/Access/Access.form').default} />
    <Route path="/permissions" exact component={require('./Administrator/Permissions/Permissions.view').default} />
    <Route path="/access" exact component={require('./Administrator/Access/Access.view').default} />
    <Route path="/templateRole" exact component={require('./Administrator/TemplateRole/TemplateRole.view').default} />
    <Route path="/loggingEvent" exact component={require('./Administrator/LoggingEvent/LoggingEvent.view').default} />
    <Route path="/signInLog" exact component={require('./Administrator/SignInLog/SignInLog.view').default} />
    <Route path="/validationLog" exact component={require('./Administrator/ValidationLog/ValidationLog.view').default} />
    <Route path="/logApp" exact component={require('./Administrator/LogApp/LogApp.view').default} />
    <Route path="/logEfiling" exact component={require('./Administrator/LogEfilling/LogEfilling.view').default} />

    <Route path="/company" exact component={require('./Administrator/Company/Company.view').default} />
    <Route path="/company/:companyId/open" component={require('./Company/main').default}/>
    <Route path="/aboutCompany" component={require('./Company/About/About.company.view').default} />

    <Route path="/organization" exact component={require('./Administrator/Organization/Organization.view').default} />
    <Route path="/organization/:id" component={require('./Administrator/Organization/Organization.form.view').default} />

    <Route path="/logImportCsvByApp" exact component={require('./Administrator/LogImportCsvByApp/LogImportCsvByApp.view').default} />
    <Route path="/master/applications" exact component={require('./Master/MasterApplication/MasterApplication.view').default} />
    <Route path="/master/kpp" exact component={require('./Master/MasterKpp/MasterKpp.view').default} />
    <Route path="/master/pesangon" exact component={require('./Master/MasterPesangon/MasterPesangon.view').default} />
    <Route path="/master/ptkp" exact component={require('./Master/MasterPtkp/MasterPtkp.view').default} />
    <Route path="/master/biaya-jabatan" exact component={require('./Master/MasterBiayaJabatan/MasterBiayaJabatan.view').default} />
    <Route path="/master/tarif-pasal-17-berlapis" exact component={require('./Master/MasterTarifPasal17Berlapis/MasterTarifPasal17Berlapis.view').default} />
    <Route path="/master/uu-pajak-penghasilan" exact component={require('./Master/MasterUUPajakPenghasilan/MasterUUPajakPenghasilan.view').default} />
    <Route path="/lawanTransaksi/15" exact component={require('./Master/MasterLawanTransaksi15/MasterLawanTransaksi15.view').default} />
    <Route path="/lawanTransaksi/22" exact component={require('./Master/MasterLawanTransaksi22/MasterLawanTransaksi22.view').default} />
    <Route path="/lawanTransaksi/4a2" exact component={require('./Master/MasterLawanTransaksi4a2/MasterLawanTransaksi4a2.view').default} />
    <Route path="/lawanTransaksi/pph23" exact component={require('./Master/MasterLawanTransaksi23/MasterLawanTransaksi23.view').default} />
    <Route path="/lawanTransaksi/pph21/a1" exact component={require('./Master/MasterLawanTransaksiA1/MasterLawanTransaksiA1.view').default} />
    <Route path="/lawanTransaksi/pph21/a2" exact component={require('./Master/MasterLawanTransaksiA2/MasterLawanTransaksiA2.view').default} />
    <Route path="/lawanTransaksi/pph21/pp" exact component={require('./Master/MasterLawanTransaksiPp/MasterLawanTransaksiPp.view').default} />

    <Route path="/dashboard" component={require('./Dashboard/Dashboard.view').default}/>
    <Route path="/entities" component={require('./Entities/Entities').default}/>

    {/* FLOW */}
    <Route path="/mainFlow" exact component={require('./Flow/MainFlow/MainFlow.view').default}/>
    <Route path="/mainFlow/:id" component={require('./Flow/MainFlow/MainFlow.form').default}/>
    {/* END FLOW */}

    {/* IMPORT */}
    <Route path="/IMPORT/ORGANIZATION" exact component={require('./Import/ImportOrganization/ImportOrganization.view').default}/>
    <Route path="/IMPORT/USER" exact component={require('./Import/ImportUser/ImportUser.view').default}/>
    <Route path="/IMPORT/ORGANIZATION/USER" exact component={require('./Import/ImportOrganizationUser/ImportOrganizationUser.view').default}/>
    <Route path="/IMPORT/ORGANIZATION/KPP" exact component={require('./Import/ImportOrganizationKpp/ImportOrganizationKpp.view').default}/>
    <Route path="/IMPORT/SIGNER23" exact component={require('./pph/23_26/Import/ImportSigner/ImportSigner23.view').default}/>
    <Route path="/IMPORT/SIGNER21" exact component={require('./pph/21_26/ImportSigner/ImportSigner21.view').default}/>
    <Route path="/IMPORT/SIGNER4A2" exact component={require('./pph/4a2/Import/ImportSigner/ImportSigner4a2.view').default}/>
    <Route path="/IMPORT/SIGNER22" exact component={require('./pph/22/Import/ImportSigner/ImportSigner22.view').default}/>
    <Route path="/IMPORT/SIGNER15" exact component={require('./pph/15/Import/ImportSigner/ImportSigner15.view').default}/>
    <Route path="/IMPORT/EFILING" exact component={require('./Import/ImportEfilling/ImportEfilling.view').default}/>
    <Route path="/IMPORT/UNIT" exact component={require('./Import/ImportUnit/ImportUnit.view').default}/>
    <Route path="/IMPORT/CREDENTIAL_EBILLING" exact component={require('./Import/ImportEbillingCredential/ImportEbillingCredential.view').default}/>
    {/* END IMPORT */}

    {/* 21_26 */}
    <Route path="/SPT_21" exact component={require('./pph/21_26/spt/21_26.spt.view').default}/>
    <Route path="/SPT_21/:sptId" exact component={require('./pph/21_26/spt/21_26.spt.form').default}/>
    <Route path="/SPT_21/:sptId/open" component={require('./pph/21_26/main').default}/>
    {/* END 21_26 */}

    {/* ALL_21_26 */}
    <Route path={"/ALL_BP_21_F/:id"} component={require('./pph/21_26/BP_21_F/BP_21_F.form').default} />
    <Route path={"/BP_21_F"} component={require('./pph/21_26/ALL_BP_21_F/ALL_BP_21_F.view').default} />
    <Route path={"/ALL_BP_21_TF/:id"} component={require('./pph/21_26/BP_21_TF/BP_21_TF.form').default} />
    <Route path={"/BP_21_TF"} component={require('./pph/21_26/ALL_BP_21_TF/ALL_BP_21_TF.view').default} />
    <Route path={"/ALL_BP_21_A1/:id"} component={require('./pph/21_26/BP_21_A1/BP_21_A1.form').default} />
    <Route path={"/BP_21_A1"} component={require('./pph/21_26/ALL_BP_21_A1/ALL_BP_21_A1.view').default} />
    <Route path={"/ALL_BP_21_A2/:id"} component={require('./pph/21_26/BP_21_A2/BP_21_A2.form').default} />
    <Route path={"/BP_21_A2"} component={require('./pph/21_26/ALL_BP_21_A2/ALL_BP_21_A2.view').default} />
    <Route path={"/ALL_BP_21_SSP/:id"} component={require('./pph/21_26/BP_21_SSP/BP_21_SSP.form').default} />
    <Route path={"/ALL_BP_21_SSP"} component={require('./pph/21_26/ALL_BP_21_SSP/ALL_BP_21_SSP.view').default} />
    <Route path={"/ALL_BP_21_SATU_MASA/:id"} component={require('./pph/21_26/BP_21_SATU_MASA/BP_21_SATU_MASA.form').default} />
    <Route path={"/ALL_BP_21_SATU_MASA"} component={require('./pph/21_26/ALL_BP_21_SATU_MASA/ALL_BP_21_SATU_MASA.view').default} />
    <Route path={"/ALL_BP_21_DAFTAR_BIAYA/:sptId"} component={require('./pph/21_26/BP_21_DAFTAR_BIAYA/BP_21_DAFTAR_BIAYA.form').default} />
    <Route path={"/DAFTAR_BIAYA_21"} component={require('./pph/21_26/ALL_BP_21_DAFTAR_BIAYA/ALL_BP_21_DAFTAR_BIAYA.view').default} />
    <Route path={"/BP_21_SATU_TAHUN"} component={require('./pph/21_26/ALL_BP_21_SATU_TAHUN/ALL_BP_21_SATU_TAHUN.view').default} />
    <Route path={"/ALL_BP_21_INDUK/:sptId"} component={require('./pph/21_26/SPT_INDUK_21/SPT_INDUK_21.form').default} />
    <Route path={"/SPT_INDUK_21"} component={require('./pph/21_26/ALL_BP_21_INDUK/ALL_BP_21_INDUK.view').default} />
    {/* END ALL_21_26 */}

    {/* IMPORT_21 */}
    <Route path="/IMPORT_21" exact component={require('./pph/21_26/Import/Import.form').default} />
    <Route path="/IMPORT/BP_21_TF" exact component={require('./pph/21_26/ImportBp21TF/ImportBp21TF.view').default} />
    <Route path="/IMPORT/BP_21_F" exact component={require('./pph/21_26/ImportBp21F/ImportBp21F.view').default} />
    <Route path="/IMPORT/BP_21_A1" exact component={require('./pph/21_26/ImportBp21A1/ImportBp21A1.view').default} />
    <Route path="/IMPORT/BP_21_A2" exact component={require('./pph/21_26/ImportBp21A2/ImportBp21A2.view').default} />
    <Route path="/IMPORT/DAFTAR_BIAYA_21" exact component={require('./pph/21_26/ImportBp21DaftarBiaya/ImportBp21DaftarBiaya.view').default} />
    <Route path="/IMPORT/BP_21_SATU_MASA" exact component={require('./pph/21_26/ImportBP21SatuMasa/ImportBP21SatuMasa.view').default} />
    <Route path="/IMPORT/BP_21_SATU_MASA_TIDAK_LEBIH" exact component={require('./pph/21_26/ImportBP21SatuMasaTL/ImportBP21SatuMasaTL.view').default} />
    <Route path="/IMPORT/SSP_21" exact component={require('./pph/21_26/ImportBp21Ssp/ImportBp21Ssp.view').default} />
    <Route path="/IMPORT/INDUK_21" exact component={require('./pph/21_26/ImportBp21Induk/ImportBp21Induk.view').default} />
    <Route path="/IMPORT/LK_21" exact component={require('./pph/21_26/ImportLK/ImportLK21.view').default} />
    {/* END IMPORT_21 */}

    {/* MASTER DATA 21_26 */}
    <Route path="/master/pasal-21-upah-harian" exact component={require('./pph/21_26/Master21UpahHarian/Master21UpahHarian.view').default} />
    <Route path="/master/pasal-21-upah-kumulatif" exact component={require('./pph/21_26/Master21UpahKumulatif/Master21UpahKumulatif.view').default} />
    <Route path="/master/pasal-21-golongan-a2" exact component={require('./pph/21_26/Master21GolonganA2/Master21GolonganA2.view').default} />
    <Route path="/master/pasal-21-ssp-description" exact component={require('./pph/21_26/Master21SspDescription/Master21SspDescription.view').default} />
    <Route path="/master/pasal-21-status-karyawan-a1" exact component={require('./pph/21_26/Master21StatusKaryawanA1/Master21StatusKaryawanA1.view').default} />
    <Route path="/master/pasal-21-status-karyawan-a2" exact component={require('./pph/21_26/Master21StatusKaryawanA2/Master21StatusKaryawanA2.view').default} />
    <Route path="/master/pasal-21-kap" exact component={require('./pph/21_26/Master21Kap/Master21Kap.view').default} />
    <Route path="/master/pasal-21-kjs" exact component={require('./pph/21_26/Master21Kjs/Master21Kjs.view').default} />
    <Route path="/master/pasal-21-pasal-17-berlapis" exact component={require('./pph/21_26/Master21Pasal17/Master21Pasal17.view').default} />
    <Route path="/master/pasal-21-pasal-17-berlapis/:id" exact component={require('./pph/21_26/Master21Pasal17Prog/Master21Pasal17Prog.view').default} />
    <Route path="/master/pasal-21-biaya-jabatan" exact component={require('./pph/21_26/Master21BiayaJabatan/Master21BiayaJabatan.view').default} />
    <Route path="/master/pasal-21-biaya-pensiun" exact component={require('./pph/21_26/Master21BiayaPensiun/Master21BiayaPensiun.view').default} />
    <Route path="/master/pasal-21-pensiun" exact component={require('./pph/21_26/Master21Pensiun/Master21Pensiun.view').default} />
    <Route path="/master/pasal-21-ptkp" exact component={require('./pph/21_26/Master21Ptkp/Master21Ptkp.view').default} />
    <Route path="/master/pasal-21-honor-pns" exact component={require('./pph/21_26/Master21HonorPns/Master21HonorPns.view').default} />
    <Route path="/master/pasal-21-final-pesangon" exact component={require('./pph/21_26/Master21FinalPesangon/Master21FinalPesangon.view').default} />
    <Route path="/master/pasal-21-final-pesangon/:id" exact component={require('./pph/21_26/Master21FinalPesangonProg/Master21FinalPesangonProg.view').default} />
    {/* END MASTER DATA 21_26 */}

    {/* MASTER DATA 23 */}
    <Route path="/master/pasal-23-kap" exact component={require('./pph/23_26/master/Kap/Master23Kap.view').default} />
    <Route path="/master/pasal-23-kjs" exact component={require('./pph/23_26/master/Kjs/Master23Kjs.view').default} />

    <Route path="/master/tarif-23-admin/:id" exact component={require('./pph/23_26/master/Tarif23/Master23TarifAdmin.form').default} />
    <Route path="/master/tarif-23-admin" exact component={require('./pph/23_26/master/Tarif23/Master23TarifAdmin.view').default} />

    <Route path="/master/tarif-23/:id" exact component={require('./pph/23_26/master/Tarif23/Master23Tarif.form').default} />
    <Route path="/master/tarif-23" exact component={require('./pph/23_26/master/Tarif23/Master23Tarif.view').default} />

    <Route path="/master/tarif-23-lain" exact component={require('./pph/23_26/master/Tarif23/Master23TarifLain.view').default} />

    <Route path="/master/tarif-26-admin/:id" exact component={require('./pph/23_26/master/Tarif23/Master26TarifAdmin.form').default} />
    <Route path="/master/tarif-26-admin" exact component={require('./pph/23_26/master/Tarif23/Master26TarifAdmin.view').default} />

    <Route path="/master/tarif-26/:id" exact component={require('./pph/23_26/master/Tarif23/Master26Tarif.form').default} />
    <Route path="/master/tarif-26" exact component={require('./pph/23_26/master/Tarif23/Master26Tarif.view').default} />
    {/* MASTER DATA 23 LAIN */}

    {/* MASTER DATA 22 */}
    <Route path="/master/komoditi-22" exact component={require('./pph/22/master/komoditi/Master22Komoditi.view').default} />
    <Route path="/master/tarif-22" exact component={require('./pph/22/master/tarif/Master22Tarif.view').default} />

    <Route path="/company/tarif-22-admin/:id" exact component={require('./pph/22/company/tarif/Tarif22Admin.form').default} />
    <Route path="/company/tarif-22-admin" exact component={require('./pph/22/company/tarif/Tarif22Admin.view').default} />

    <Route path="/company/tarif-22/:id" exact component={require('./pph/22/company/tarif/Tarif22.form').default} />
    <Route path="/company/tarif-22" exact component={require('./pph/22/company/tarif/Tarif22.view').default} />
    {/* END MASTER DATA 22 */}

    {/* MASTER DATA 15 */}
    <Route path="/master/tarif-15" exact component={require('./pph/15/master/tarif/Master15Tarif.view').default} />

    <Route path="/company/tarif-15-admin/:id" exact component={require('./pph/15/company/tarif/Tarif15Admin.form').default} />
    <Route path="/company/tarif-15-admin" exact component={require('./pph/15/company/tarif/Tarif15Admin.view').default} />

    <Route path="/company/tarif-15/:id" exact component={require('./pph/15/company/tarif/Tarif15.form').default} />
    <Route path="/company/tarif-15" exact component={require('./pph/15/company/tarif/Tarif15.view').default} />
    {/* END MASTER DATA 15 */}

    {/* MASTER DATA CUSTOM IMPORT */}
    <Route path="/master/custom-import-headers" exact component={require('./Master/MasterCustomImport/MasterCustomImport.view').default} />
    {/* END MASTER DATA CUSTOM IMPORT */}

    {/* LOG 21 */}
    <Route path={"/LOG_EXPORT_CSV_21"} component={require('./pph/21_26/ExportCsv21Log/ExportCsv21Log.view').default} />
    <Route path={"/LOG_EMAIL"} component={require('./pph/21_26//Email21Log/Email21Log.view').default} />
    <Route path={"/LOG_FILE_LAPOR_21"} component={require('./pph/21_26/FileLapor21Log/FileLapor21Log.view').default} />
    {/* END LOG 21 */}


    {/* 23_26 */}
    <Route path="/SPT_23" exact component={require('./pph/23_26/SPT/23_26.spt.view').default}/>
    <Route path="/SPT_23/:sptId" exact component={require('./pph/23_26/SPT/23_26.spt.form').default}/>
    <Route path="/SPT_23/:sptId/open" component={require('./pph/23_26/main').default}/>
    {/* END 21_26 */}

    {/* Module Ebupot */}
    <Route path="/bp-26-pembetulan/:sptId/:id"   component={Bp26PembetulanFormView} />
    <Route path="/bp-26-pembetulan/:id"   component={Bp26PembetulanFormView} />
    <Route path="/bp-26-pembetulan"       component={Bp26PembetulanView} />
    <Route path="/bp-26-edit/:sptId/:id"         component={Bp26EditFormView} />
    <Route path="/bp-26-edit/:id"         component={Bp26EditFormView} />
    <Route path="/bp-26-edit"             component={Bp26EditView} />
    <Route path="/bp-23-pembetulan/:sptId/:id"   component={Bp23PembetulanFormView} />
    <Route path="/bp-23-pembetulan/:id"   component={Bp23PembetulanFormView} />
    <Route path="/bp-23-pembetulan"       component={Bp23PembetulanView} />
    <Route path="/bp-23-edit/:sptId/:id"         component={Bp23EditFormView} />
    <Route path="/bp-23-edit/:id"         component={Bp23EditFormView} />
    <Route path="/bp-23-edit"             component={Bp23EditView} />
    <Route path="/ebupot-23/bpe/form/:id" component={BpeFormView} />
    <Route path="/ebupot-23/bpe/:sptId"   component={BpeView} />
    <Route path="/ebupot-23/:sptId/open"  component={SPT23MainView} />
    <Route path="/ebupot-23/:sptId"       component={SPT23FormView} />
    <Route path="/ebupot-23"              component={SPT23View} />
    <Route path="/users/:id"              component={UserFormView} />
    <Route path="/users"                  component={UserView} />
    <Route path="/application-email"      component={ApplicationEmailFormView} />
    <Route path="/application/:id"        component={ApplicationFormView} />
    <Route path="/application"            component={ApplicationView} />
    <Route path="/client/:appId"          component={ClientView} />
    <Route path="/ebupot/organization-users/:id" component={OrganizationUserForm} />
    <Route path="/ebupot/organization-users" component={OrganizationUserView} />
    <Route path="/ebupot/organization/:id"component={OrganizationFormView} />
    <Route path="/ebupot/organization"    component={OrganizationView} />
    <Route path="/LogSubmit/:id"          component={LogSubmitFormView} />
    <Route path="/log-submit"             component={LogSubmitView} />
    <Route path="/LogCheck/:id"           component={LogCheckFormView} />
    <Route path="/log-check"              component={LogCheckView} />
    <Route path="/log-file-validasi"      component={LogFileValidasiView} />
    <Route path="/log-file-csv"           component={LogFileCsvView} />
    <Route path="/log-file"               component={LogFileView} />
    <Route path="/signer/:org/:id"        component={SignerFormView} />
    <Route path="/signer/:id"             component={SignerView} />
    <Route path="/kodeNegara/:id"         component={KodeNegaraFormView} />
    <Route path="/kodeNegara"             component={KodeNegaraView} />
    <Route path="/provinces/:id"          component={ProvincesFormView} />
    <Route path="/provinces"              component={ProvincesView} />
    <Route path="/kodeBuktiPotong/:id"    component={KodeBuktiPotongFormView} />
    <Route path="/kodeBuktiPotong"        component={KodeBuktiPotongView} />
    <Route path="/jenisDokumen/:id"       component={JenisDokumenFormView} />
    <Route path="/jenisDokumen"           component={JenisDokumenView} />
    <Route path="/regencies/:id"          component={RegencyFormView} />
    <Route path="/regencies"              component={RegencyView} />
    <Route path="/districts/:id"          component={DistrictFormView} />
    <Route path="/districts"              component={DistrictView} />
    <Route path="/villages/:id"           component={VillagesFormView} />
    <Route path="/villages"               component={VillageView} />
    <Route path="/kode-bukti-potong-26"   component={KodeBuktiPotong26View} />
    <Route path="/all-ebupot-23"          component={AllEbupot23View} />
    <Route path="/all-ebupot-26"          component={AllEbupot26View} />
    <Route path="/bupot23/history/:bpId/:id" component={Bupot23HistoryFormView} />
    <Route path="/bupot26/history/:bpId/:id" component={Bupot26HistoryFormView} />
    <Route path="/bupot23/history/:id"    component={Bupot23HistoryView} />
    <Route path="/bupot26/history/:id"    component={Bupot26HistoryView} />
    <Route path="/bupot23/email/:bpId/:id" component={Bupot23EmailFormView} />
    <Route path="/bupot26/email/:bpId/:id" component={Bupot26EmailFormView} />
    <Route path="/bupot23/email/:id"       component={Bupot23EmailView} />
    <Route path="/bupot26/email/:id"       component={Bupot26EmailView} />
    <Route path="/bp-23/:sptId/:id"              component={Bupot23FormView} />
    <Route path="/bp-26/:sptId/:id"              component={Bupot26FormViewView} />
    <Route path="/bp-23/:id"              component={Bupot23FormView} />
    <Route path="/bp-26/:id"              component={Bupot26FormViewView} />
    <Route path="/bp-23"                  component={Bupot23View} />
    <Route path="/bp-26"                  component={Bupot26View} />
    <Route path="/lawan-transaksi-26/:id" component={LawanTransaksi26FormView} />
    <Route path="/lawan-transaksi-26"     component={LawanTransaksi26View} />
    <Route path="/lawan-transaksi-23/:id" component={LawanTransaksi23FormView} />
    <Route path="/lawan-transaksi-23"     component={LawanTransaksi23View} />
    <Route path="/ebupot-dashboard" component={Dashboard}/>

    {/* EBUPOT 23_26 */}
    <Route path="/EBUPOT_23"             exact component={require('./pph/23_26.Ebupot/SPT/23_26.Ebupot.spt.view').default}/>
    <Route path="/EBUPOT_23/:sptId"      exact component={require('./pph/23_26.Ebupot/SPT/23_26.Ebupot.spt.form').default}/>
    <Route path="/EBUPOT_23/:sptId/open"       component={require('./pph/23_26.Ebupot/main').default}/>
    {/* END EBUPOT 23_26 */}

    {/* ALL EBUPOT 23_26 */}
    <Route path={"/ALL_EBUPOT_23/:id"}    component={require('./pph/23_26.Ebupot/BP_Ebupot_23/BP_Ebupot_23.form').default} />
    <Route path={"/ALL_EBUPOT_26/:id"}    component={require('./pph/23_26.Ebupot/BP_Ebupot_26/BP_Ebupot_26.form').default} />
    <Route path={"/ALL_EBUPOT_23"}        component={require('./pph/23_26.Ebupot/all/ALL_BP_23_EBUPOT/ALLBP23.Ebupot.view').default} />
    <Route path={"/ALL_EBUPOT_26"}        component={require('./pph/23_26.Ebupot/all/ALL_BP_26_EBUPOT/ALLBP26.Ebupot.view').default} />
    {/* ALL EBUPOT 23_26 */}

    {/* BP 22 */}
    <Route path="/SPT_22" exact component={require('./pph/22/SPT/SPT22.view').default}/>
    <Route path="/SPT_22/:sptId" exact component={require('./pph/22/SPT/SPT22.form').default}/>
    <Route path="/SPT_22/:sptId/open" component={require('./pph/22/main').default}/>
    {/* END OF BP 22   */}


    {/* 4a2 */}
    <Route path="/SPT_4a2" exact component={require('./pph/4a2/SPT/Spt4a2.view').default}/>
    <Route path="/SPT_4a2/:sptId" exact component={require('./pph/4a2/SPT/SPt4a2.form').default}/>
    <Route path="/SPT_4a2/:sptId/open" component={require('./pph/4a2/main').default}/>

    <Route path={"/ALLBP4A2/:id"} component={require('./pph/4a2/BP4a2/BP4a2.form').default} />
    <Route path={"/ALLBP4A2"} component={require('./pph/4a2/all/AllBp4a2/AllBp4a2View').default} />
    <Route path={"/ALL_SEWA_TANAH_4A2"} component={require('./pph/4a2/all/AllSewaTanah4a2/AllSewaTanah4a2.view').default} />
    <Route path={"/ALL_JASA_KONSTRUKSI_4A2"} component={require('./pph/4a2/all/AllJasaKonstruksi4a2/AllJasaKonstruksi4a2.view').default} />
    <Route path={"/ALL_WP_HAK_ATAS_TANAH_4A2"} component={require('./pph/4a2/all/AllWpHakAtasTanah4a2/AllWpHakAtasTanah4a2.view').default} />
    <Route path={"/ALLSSP4A2/:id"} component={require('./pph/4a2/SSP/SSP4a2.view').default} />
    <Route path={"/ALLSSP4A2"} component={require('./pph/4a2/all/AllSsp/AllSsp4a2.view').default} />
    <Route path={"/ALLSPT4a2Induk/:id"} component={require('./pph/4a2/Induk/Spt4a2Induk.form').default} />
    <Route path={"/ALLSPT4a2Induk"} component={require('./pph/4a2/all/AllInduk/AllSpt4a2Induk.view').default} />
    <Route path={"/ALLPBK4A2"} component={require('./pph/4a2/all/AllPbk4a2/AllPbk4a2.view').default} />
    <Route path={"/ALLPBK4A2/:id"} component={require('./pph/4a2/PBK/PBK4a2.form').default} />

    <Route path="/master/tarif-4a2-admin/:id" exact component={require('./pph/4a2/master/tarif/Tarif4a2Admin.form').default} />
    <Route path="/master/tarif-4a2-admin" exact component={require('./pph/4a2/master/tarif/Tarif4a2Admin.view').default} />

    <Route path="/master/tarif-4a2/:id" exact component={require('./pph/4a2/master/tarif/Tarif4a2.form').default} />
    <Route path="/master/tarif-4a2" exact component={require('./pph/4a2/master/tarif/Tarif4a2.view').default} />
    {/* END 4a2 */}

    {/* ALL 23_26 */}
    <Route path={"/ALLBP23/:id"} component={require('./pph/23_26/BP_23/BP_23.form').default} />
    <Route path={"/ALLBP23"} component={require('./pph/23_26/all/ALL_BP_23/ALLBP23.view').default} />
    <Route path={"/ALLBP26/:id"} component={require('./pph/23_26/BP_26/BP_26.form').default} />
    <Route path={"/ALLBP26"} component={require('./pph/23_26/all/ALL_BP_26/ALLBP26.view').default} />
    <Route path={"/ALLSSP23/:id"} component={require('./pph/23_26/SSP/SSP23.form').default} />
    <Route path={"/ALLSSP23"} component={require('./pph/23_26/all/ALL_SSP/ALLSSP23.view').default} />
    <Route path={"/ALLSPT23Induk/:id"} component={require('./pph/23_26/Induk/Spt23Induk.form').default} />
    <Route path={"/ALLSPT23Induk"} component={require('./pph/23_26/all/ALL_Induk/ALLSPT23Indu.view').default} />
    {/* END ALL 23_26 */}

    {/* 15 */}
    <Route path="/SPT_15" exact component={require('./pph/15/SPT/Spt15.view').default}/>
    <Route path="/SPT_15/:sptId" exact component={require('./pph/15/SPT/Spt15.form').default}/>
    <Route path="/SPT_15/:sptId/open" component={require('./pph/15/main').default}/>
    {/* 15 */}

    {/* 25 */}
    <Route path="/SPT_25" exact component={require('./pph/25/SPT/Spt25.view').default}/>
    <Route path="/SPT_25/:sptId" exact component={require('./pph/25/SPT/Spt25.form').default}/>
    <Route path="/SPT_25/:sptId/open" component={require('./pph/25/main').default}/>

    <Route path={"/ALLBP25"} component={require('./pph/25/all/AllBp25/AllBp25View').default} />
    <Route path={"/ALLSSP25"} component={require('./pph/25/all/AllSsp25/AllSsp25.view').default} />

    <Route path={"/IMPORT/BP_25"} component={require('./pph/25/Import/ImportBp25/ImportBp25.view').default} />
    <Route path={"/IMPORT/SSP_25"} component={require('./pph/25/Import/ImportSsp25/ImportSsp25.view').default} />
    {/* 25 */}

    {/* IMPORT 4a2 */}
    <Route path={"/IMPORT/BP_4a2"} component={require('./pph/4a2/Import/ImportBP4a2/ImportBP4a2.view').default} />
    <Route path={"/IMPORT/BP_4a2_GABUNG"} component={require('./pph/4a2/Import/ImportBP4a2Gabung/ImportBP4a2Gabung.view').default} />
    <Route path={"/IMPORT/DAFTAR_PEMOTONGAN_4a2"} component={require('./pph/4a2/Import/ImportDaftarPemotongan4a2/ImportDaftarPemotongan4a2.view').default} />
    <Route path={"/IMPORT/PBK_4a2"} component={require('./pph/4a2/Import/ImportPBK/ImportPBK4a2.view').default} />
    <Route path={"/IMPORT/SSP_4a2"} component={require('./pph/4a2/Import/ImportSSP/ImportSSP4a2.view').default} />
    <Route path={"/IMPORT/INDUK_PT_4a2"} component={require('./pph/4a2/Import/ImportInduk4a2PenghasilanTertentu/ImportInduk4a2PenghasilanTertentu.view').default} />
    <Route path={"/IMPORT/LK_4a2"} component={require('./pph/4a2/Import/ImportLK/ImportLK4a2.view').default} />
    <Route path="/IMPORT/INDUK_4a2" exact component={require('./pph/4a2/ImportBp4a2Induk/ImportBp4a2Induk.view').default} />
    {/* END OF 4a2 */}

    {/* IMPORT 23_26 */}
    <Route path={"/IMPORT/BP_23"} component={require('./pph/23_26/Import/ImportBP23/ImportBP23.view').default} />
    <Route path={"/IMPORT/BP_26"} component={require('./pph/23_26/Import/ImportBP26/ImportBP26.view').default} />
    <Route path={"/IMPORT/BP_2326"} component={require('./pph/23_26/Import/ImportBP2326/ImportBP2326.view').default} />
    <Route path={"/IMPORT/IMPORT_PBK"} component={require('./pph/23_26/Import/ImportPBK/ImportPBK23.view').default} />
    <Route path={"/IMPORT/SSP_23"} component={require('./pph/23_26/Import/ImportSSP/ImportSSP23.view').default} />
    <Route path={"/IMPORT/IMPORT_PBK23"} component={require('./pph/23_26/Import/ImportLK/ImportLK23.view').default} />
    <Route path="/IMPORT/INDUK_23" exact component={require('./pph/23_26/ImportBp23Induk/ImportBp23Induk.view').default} />
    <Route path={"/IMPORT/23_TARIF_JASA_LAIN"} component={require('./pph/23_26/Import/Import23TarifJasaLain/Import23TarifJasaLain.view').default} />
    {/* END OF IMPORT 23_26 */}

    {/* IMPORT 22 */}
    <Route path={"/IMPORT/BP_22"} component={require('./pph/22/Import/ImportBP22AtasImpor/ImportBP22AtasImpor.view').default} />
    <Route path={"/IMPORT/BP_22_industri_eksportir"} component={require('./pph/22/Import/ImportBP22IndustriEksportir/ImportBP22IndustriEksportir.view').default} />
    <Route path={"/IMPORT/BP_22_industri_importir"} component={require('./pph/22/Import/ImportBP22IndustriImportir/ImportBP22IndustriImportir.view').default} />
    <Route path={"/IMPORT/BP_22_badan_tertentu"} component={require('./pph/22/Import/ImportBP22BadanTertentu/ImportBP22BadanTertentu.view').default} />
    <Route path={"/IMPORT/SSP_22_dibayar_sendiri"} component={require('./pph/22/Import/ImportSSP22DibayarSendiri/ImportSSP22DibayarSendiri.view').default} />
    <Route path={"/IMPORT/SSP_22_bank_devisa"} component={require('./pph/22/Import/ImportSSP22BankDevisa/ImportSSP22BankDevisa.view').default} />
    <Route path={"/IMPORT/SSP_22_bendaharawan"} component={require('./pph/22/Import/ImportSSP22Bendaharawan/ImportSSP22Bendarawan.view').default} />
    <Route path={"/IMPORT/SSP_22"} component={require('./pph/22/Import/ImportSSP/ImportSSP22.view').default} />
    <Route path={"/IMPORT/PBK_22"} component={require('./pph/22/Import/ImportPBK/ImportPBK22.view').default} />
    <Route path={"/IMPORT/LK_22"} component={require('./pph/22/Import/ImportLK/ImportLK22.view').default} />
    <Route path="/IMPORT/INDUK_22" exact component={require('./pph/22/ImportBp22Induk/ImportBp22Induk.view').default} />

    <Route path={"/ALLBP22"} component={require('./pph/22/All/AllBp22/AllBp22View').default} />
    <Route path={"/ALL_SSP22_PEMBELIAN_BARANG"} component={require('./pph/22/All/AllSsp22PembelianBarang/AllSsp22PembelianBarang.view').default} />
    <Route path={"/ALL_SSP22_OLEH_BANK_DEVISA"} component={require('./pph/22/All/AllSsp22ImportBankDevisa/AllSsp22ImportBankDevisa.view').default} />
    <Route path={"/ALL_SSP22_DIBAYAR_SENDIRI"} component={require('./pph/22/All/AllSsp22DibayarSendiri/AllSsp22DibayarSendiri.view').default} />
    <Route path={"/ALLSSP22"} component={require('./pph/22/All/AllSsp/AllSsp22.view').default} />
    {/* END OF IMPORT 22 */}

    {/* IMPORT 15 */}
    <Route path={"/IMPORT/BP_15"} component={require('./pph/15/Import/ImportBp15/ImportBp15.view').default} />
    <Route path={"/IMPORT/BP_24"} component={require('./pph/15/Import/ImportBp24/ImportBp24.view').default} />
    <Route path={"/IMPORT/SSP_15_SETOR_SENDIRI"} component={require('./pph/15/Import/ImportSSPSetorSendiri/ImportSSP15SetorSendiri.view').default} />
    <Route path={"/IMPORT/SSP_15"} component={require('./pph/15/Import/ImportSSP/ImportSSP15.view').default} />
    <Route path={"/IMPORT/PBK_15"} component={require('./pph/15/Import/ImportPBK/ImportPBK15.view').default} />
    <Route path={"/IMPORT/LK_15"} component={require('./pph/15/Import/ImportLK/ImportLK15.view').default} />
    <Route path="/IMPORT/INDUK_15" exact component={require('./pph/15/ImportBp15Induk/ImportBp15Induk.view').default} />

    <Route path={"/ALLBP15DipotongPihakLain"} component={require('./pph/15/All/AllDipotongPihakLain/AllBP15DipotongPihakLain.view').default} />
    <Route path={"/ALLBP15PihakLainDipotong"} component={require('./pph/15/All/AllPihakLainDipotong/AllBP15PihakLainDipotong.view').default} />
    <Route path={"/ALLBP24"} component={require('./pph/15/All/AllPasal24/AllBP15Pasal24.view').default} />
    <Route path={"/ALLSSP15"} component={require('./pph/15/All/AllSsp/AllSsp15.view').default} />
    <Route path={"/ALLSSP15SETORSENDIRI"} component={require('./pph/15/All/AllSsp15SetorSendiri/AllSsp15SetorSendiri.view').default} />
    {/* END OF IMPORT 15 */}

    {/* Efilling */}
    <Route path={"/eFiling/lainnya"} render={(props) => (
      <LogEfillingPpnView {...props} />
    )} />
    <Route path={"/eFiling/tahunan"} render={(props) => (
      <LogEfillingBadanView {...props} />
    )} />
    <Route path={"/eFiling/:pasal"} render={(props) => (
      <LogEfillingView key={props.match.params.pasal} {...props} />
    )} />
    <Route path={"/eFiling"} render={(props) => (
      <LogEfillingView {...props} />
    )} />
    {/* END Efilling */}

    {/* PengaturanBP */}
    <Route path={"/nomorBp/:pasal"} render={(props) => (
      <PengaturanNoBPView key={props.match.params.pasal} {...props} />
    )} />
    {/* PengaturanBP */}

    <Route path={"/file"} component={require('./Setting/FileUpload/FileUpload.view').default} />

    {/* log export */}
    <Route path={"/logExport/:pasal"} render={(props) => (
      <LogExportView key={props.match.params.pasal} {...props} />
    )} />
    {/* END log export */}

    {/* Summary */}

    <Route path={"/summary-admin"} component={require('./SummaryAdmin/SummaryAdmin.form').default} />

    <Route path={"/summary/:pasal"} render={(props) => (
      <SummaryForm key={props.match.params.pasal} {...props} />
    )} />

    <Route path={"/kalkulasiInduk/:pasal"} render={(props) => (
      <KalkulasiIndukForm key={props.match.params.pasal} {...props} />
    )} />

    {/* SETTING */}
    <Route path={"/SETTING_EMAIL"} component={require('./pph/21_26/SettingEmail/SettingEmail.view').default} />
    <Route path={"/SETTING_EMAIL_CONTENT"} component={require('./Setting/SettingEmailContent/SettingEmailContent.view').default} />
    <Route path={"/SETTING_EMAIL_SCHEDULE"} component={require('./Setting/SettingEmailSchedule/SettingEmailSchedule.view').default} />
    <Route path={"/SETTING_EFILING/:companyId"} component={require('./pph/21_26/SettingEfiling/SettingEfiling.view').default} />
    <Route path={"/SETTING_EFILING"} component={require('./pph/21_26/SettingEfiling/SettingEfiling.view').default} />
    <Route path={"/SETTING_EBUPOT/:companyId"} component={require('./pph/21_26/SettingEbupot/SettingEbupot.view').default} />
    <Route path={"/SETTING_EBUPOT"} component={require('./pph/21_26/SettingEbupot/SettingEbupot.view').default} />
    <Route path={"/SETTING_VIEW"} component={require('./Setting/SettingView/SettingView.view').default} />
    <Route path={"/SETTING_IMPORT"} component={require('./Setting/SettingImport/SettingImport.view').default} />
    <Route path={"/SETTING_IMPORT_GLOBAL"} component={require('./Setting/SettingImportGlobal/SettingImportGlobal.view').default} />
    <Route path={"/SETTING_EXPORT"} component={require('./Setting/SettingExport/SettingExport.view').default} />

    <Route path={"/SETTING_EMAIL/:companyId"} component={require('./pph/21_26/SettingEmail/SettingEmail.view').default} />
    <Route path={"/SETTING_EMAIL_CONTENT/:companyId"} component={require('./Setting/SettingEmailContent/SettingEmailContent.view').default} />
    <Route path={"/SETTING_EMAIL_SCHEDULE/:companyId"} component={require('./Setting/SettingEmailSchedule/SettingEmailSchedule.view').default} />
    <Route path={"/SETTING_VIEW/:companyId"} component={require('./Setting/SettingView/SettingView.view').default} />
    <Route path={"/SETTING_IMPORT/:companyId"} component={require('./Setting/SettingImport/SettingImport.view').default} />
    <Route path={"/SETTING_EXPORT/:companyId"} component={require('./Setting/SettingExport/SettingExport.view').default} />

    <Route path={"/SETTING_EMAIL_ALL"} component={require('./pph/21_26/SettingEmail/SettingEmail.view.all').default} />
    <Route path={"/SETTING_EMAIL_CONTENT_ALL"} component={require('./Setting/SettingEmailContent/SettingEmailContent.view.all').default} />
    <Route path={"/SETTING_EMAIL_SCHEDULE_ALL"} component={require('./Setting/SettingEmailSchedule/SettingEmailSchedule.view.all').default} />
    <Route path={"/SETTING_EFILING_ALL"} component={require('./pph/21_26/SettingEfiling/SettingEfiling.view.all').default} />
    <Route path={"/SETTING_VIEW_ALL"} component={require('./Setting/SettingView/SettingView.view.all').default} />
    <Route path={"/SETTING_IMPORT_ALL"} component={require('./Setting/SettingImport/SettingImport.view.all').default} />
    <Route path={"/SETTING_EXPORT_ALL"} component={require('./Setting/SettingExport/SettingExport.view.all').default} />
    <Route path={"/SETTING_EXPORT_ADMIN"} component={require('./Setting/SettingExportAdmin/SettingExportAdmin.view').default} />

    <Route path={"/logged-in"} component={require('./Setting/LogginIn/LogginIn.view').default} />
    {/* END SETTING */}

    {/* EBILLING */}
    <Route path="/EBILLING/SETTING" exact component={require('./Ebilling/SettingEbilling/SettingEbilling.view').default} />
    <Route path="/EBILLING/IMPORT/INQUIRY" exact component={require('./Ebilling/ImportInquiry/ImportInquiry.view').default}/>
    <Route path={"/EBILLING/INQUIRY/:id"} component={require('./Ebilling/DataInquiry/DataInquiry.form').default}/>
    <Route path="/EBILLING/INQUIRY" exact component={require('./Ebilling/DataInquiry/DataInquiry.view').default}/>


    <Route path="/EBILLING/DataESSP" exact component={require('./Ebilling/DataESSP/DataESSP.view').default}/>
    <Route path="/EBILLING/DataESSP/duplicate" exact component={require('./Ebilling/DataESSP/DataESSPDuplicate.view').default}/>
    <Route path="/EBILLING/DataESSP/hitung" exact component={require('./Ebilling/DataESSP/DataESSPHitung.form').default}/>
    <Route path="/EBILLING/DataESSP/hitung/ebupot" exact component={require('./Ebilling/DataESSP/DataESSPHitungEbupot.form').default}/>
    <Route path="/EBILLING/IMPORT/ESSP" exact component={require('./Ebilling/ImportEssp/ImportEssp.view').default}/>
    <Route path="/EBILLING/IMPORT/ESSP-WP-PEREDARAN-TERTENTU" exact component={require('./Ebilling/ImportEsspWpPeredaranTertentu/ImportEsspWpPeredaranTertentu.view').default}/>
    <Route path="/EBILLING/paymentOrder/bniDirect" exact component={require('./Ebilling/PaymentOrder/BniDirect.form').default}/>
    <Route path="/EBILLING/paymentOrder/paymentBriCms" exact component={require('./Ebilling/PaymentOrder/paymentBriCms.form').default}/>
    <Route path="/EBILLING/paymentOrder/:id" exact component={require('./Ebilling/PaymentOrder/PaymentOrder.form').default}/>
    <Route path="/EBILLING/paymentOrder" exact component={require('./Ebilling/PaymentOrder/PaymentOrder.view').default}/>
    <Route path="/EBILLING/paymentNotification/:id/bpn" exact component={require('./Ebilling/PaymentNotification/PaymentNotification.bpn.form').default}/>
    <Route path="/EBILLING/paymentNotification/:id" exact component={require('./Ebilling/PaymentNotification/PaymentNotification.detail.view').default}/>
    <Route path="/EBILLING/paymentNotification" exact component={require('./Ebilling/PaymentNotification/PaymentNotification.view').default}/>
    <Route path="/EBILLING/fileTransaksi/:id" exact component={require('./Ebilling/PaymentNotification/FileTransaksi.form').default}/>
    <Route path="/EBILLING/akunPembayaran" exact component={require('./Ebilling/AkunPembayaran/AkunPembayaran.view').default}/>
    {/* END EBILLING */}

    {/* LOG EBILLING */}
    <Route path="/EBILLING/LOG/LogExportDataInquiry" exact component={require('./Ebilling/log/LogExportDataInquiry/LogExportDataInquiry.view').default}/>
    <Route path="/EBILLING/LOG/LogExportDataSSP" exact component={require('./Ebilling/log/LogExportDataSSP/LogExportDataSSP.view').default}/>
    <Route path="/EBILLING/LOG/LogCetakPaymentOrder" exact component={require('./Ebilling/log/LogCetakPaymentOrder/LogCetakPaymentOrder.view').default}/>
    <Route path="/EBILLING/LOG/LogCetakPaymenNotification" exact component={require('./Ebilling/log/LogCetakPaymentNotification/LogCetakPaymentNotification.view').default}/>
    <Route path="/EBILLING/LOG/LogCetakBPN" exact component={require('./Ebilling/log/LogCetakBPN/LogCetakBPN.view').default}/>
    <Route path="/EBILLING/LOG/LogCetakLaporanPO" exact component={require('./Ebilling/log/LogCetakLaporanPO/LogCetakLaporanPO.view').default}/>
    <Route path="/EBILLING/LOG/LogCetakLaporanPN" exact component={require('./Ebilling/log/LogCetakLaporanPN/LogCetakLaporanPN.view').default}/>
    {/* END LOG EBILLING */}

    {/* ACCOUNT */}
    <Route path={"/EDIT_PROFILE"} render={(props) => (
      <EditProfileForm {...props} />
    )} />

    <Route path={"/EDIT_PASSWORD"} render={(props) => (
      <EditPasswordForm {...props} />
    )} />
    {/* END ACCOUNT */}

    {/* KEY */}
    <Route path={"/key/:pasal"} render={(props) => (
      <KeyView key={props.match.params.pasal} {...props} />
    )} />
    {/* END KEY */}

    {/* UNIT */}
    <Route path={"/unit"} render={(props) => (
      <UnitView {...props} />
    )} />
    {/* END UNIT */}

    <Route path="/" component={require('./Dashboard/Dashboard.view').default}/>
  </Switch>
)





