import ApiService from '../../../../../services/api.service';

class Master21KjsService extends ApiService {
  name= 'Master21Kjs';
  path= 'master/spt21/kjs';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api= {
    findByKap: async (kapId) => {
      var res = await this.http.get(this.apiLocation+'/master/spt21/kjs/kap/'+kapId)

      return res.data;
    }
  }
}


export default new Master21KjsService();

