import React, { Component } from 'react'; import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {TabsContainer, Tabs, Tab, Button} from 'react-md';
import { Field, initialize, reduxForm } from 'redux-form';
import {validation, FileInputNext, Searchfieldv2, Textfield, Searchfield, TextfieldMask, Datepicker, Radio, convert, CheckboxSelection, Multiselect} from '../../../../../../src/components/form';
import moment from 'moment'

import LawanTransaksi23Service from './LawanTransaksi23.service';
import ListView from '../../../../../components/entity/ListViewEbupot';
import AuthService from '../../../../../services/auth.service'
import ListViewEbupot from '../../../../../components/entity/ListViewEbupot';

@reduxForm({form: 'Lt23Ebupot', destroyOnUnmount: false})
@connect((state)=> ({
  ...LawanTransaksi23Service.stateConnectSetting()(state)
}), LawanTransaksi23Service.actionConnectSetting())
export default class LawanTransaksi23View extends ListViewEbupot {
  service=LawanTransaksi23Service;
  editDialog = false;
  addDialog = false;

  beforeFetch(params) {
    delete params.startDate
    delete params.endDate
    delete params.column
    delete params.total
    delete params.keyword
    params.sortBy = "id"
    params.sort = "id,ASC"
    // params.sortBy = 'id'
    // params.sort = 1
  }

  addItem = () => {
    if(this.addDialog) {
      this.setState({showForm:true, formData: {}})
    } else {
      if(this.rootPath) {
        this.props.history.push(this.rootPath+"/new");
      } else {
        this.props.history.push('/'+'lawan-transaksi-23'+"/new");
      }

    }
  }

  editItem = (item) => {
    if(this.editDialog) {
      this.showFormDialog(item);
    } else {
      if(this.rootPath) {
        this.props.history.push(this.rootPath+"/"+item.id)
      } else {
        this.props.history.push('/'+'lawan-transaksi-23'+"/"+item.id);
      }
    }
  }

  plainTable(){
    if(this.props.dialogMode){
      return true
    } else {
      return false
    }
  }

  _tableActionsFunc(i){
    if(this.props.dialogMode){
      return []
    } else {
      return [
        {
          label:"More Info",
          iconClassName:"mdi mdi-bell",
          onClick: (item) => this.editItem(item)
        },
      ]
    }
  }

  async handleItemClick(d){
    try { this.props.parent.props.change("nama", d.nama)           } catch(e){  }
    try { this.props.parent.props.change("alamat", d.alamat)       } catch(e){  }
    try { this.props.parent.props.change("npwp", d.npwp)           } catch(e){  }
    try { this.props.parent.props.change("nik", d.nik)             } catch(e){  }
    try { this.props.parent.props.change("identity", d.identity)   } catch(e){  }
    try { this.props.parent.props.change("email", d.email)         } catch(e){  }
    try { this.props.parent.props.change("noTelp", d.noTelp)       } catch(e){  }
    try { this.props.parent.props.change("kodePos", d.kodePos)     } catch(e){  }
    try { this.props.parent.props.change("provinsi", d.provinsi)   } catch(e){  }
    try { this.props.parent.props.change("kabupaten", d.kabupaten) } catch(e){  }
    try { this.props.parent.props.change("kecamatan", d.kecamatan) } catch(e){  }
    try { this.props.parent.props.change("kelurahan", d.kelurahan) } catch(e){  }
    this.props.dialogProps._resolve(true)
  }

  _tableColumns(){
    var columns = this.columns
    if(this.props.dialogMode){
      var cekPilih = columns.filter((d)=> {
        return d.extended == true
      })
      if(cekPilih.length == 0){
        columns.unshift({
          extended: true, label: "word.pilih", searchField: "pilih", value: (d)=> {
            return <Button onClick={this.handleItemClick.bind(this, d)} flat primary label="Pilih"></Button>
          }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true
        })
      }
    }
    return columns
  }

  columns=LawanTransaksi23Service.columns

  barActions(){
    var _barActions = [
      {
        label:'word.create',
        iconClassName:'mdi mdi-plus',
        onClick:() => this.addItem()
      },
      {
        label:'word.refresh',
        iconClassName:'mdi mdi-refresh',
        onClick:() => this.fetchData()
      },
    ]
    if(this.props.dialogMode) _barActions = []
    return _barActions
  }

  // NEW TAB MENU
  async hapusPencarian(){
    try {
      var params = Object.assign({}, params=this.props.table.params)
      this.fetchData(params)
      await this.props.dispatch(initialize(this.service.name+'_search', {}));
    } catch(e){
    }
  }

  async handleSearching(values){
    try {
      var formData = this.props.tableFilter2
      var params = Object.assign({}, params=this.props.table.params)

      if(formData.nama){
        params['nama.contains'] = formData.nama
      } else { delete params['nama.contains'] }

      if(formData.nik){
        params['nik.contains'] = formData.nik
      } else { delete params['nik.contains'] }

      if(formData.npwp){
        params['npwp.contains'] = formData.npwp
      } else { delete params['npwp.contains'] }

      if(formData.alamat){
        params['alamat.contains'] = formData.alamat
      } else { delete params['alamat.contains'] }

      if(formData.provinsi){
        params['provinsi.contains'] = formData.provinsi
      } else { delete params['provinsi.contains'] }

      if(formData.kabupaten){
        params['kabupaten.contains'] = formData.kabupaten
      } else { delete params['kabupaten.contains'] }

      if(formData.kecamatan){
        params['kecamatan.contains'] = formData.kecamatan
      } else { delete params['kecamatan.contains'] }

      if(formData.kelurahan){
        params['kelurahan.contains'] = formData.kelurahan
      } else { delete params['kelurahan.contains'] }

      if(formData.kodePos){
        params['kodePos.contains'] = formData.kodePos
      } else { delete params['kodePos.contains'] }

      if(formData.noTelp){
        params['noTelp.contains'] = formData.noTelp
      } else { delete params['noTelp.contains'] }

      if(formData.createdBy){
        params['createdBy.contains'] = formData.createdBy
      } else { delete params['createdBy.contains'] }

      if(formData.lastModifiedBy){
        params['lastModifiedBy.contains'] = formData.lastModifiedBy
      } else { delete params['lastModifiedBy.contains'] }

      if(formData.createdDate1){
        params['createdDate.greaterOrEqualThan'] = moment(formData.createdDate1).format()
      } else { delete params['createdDate.greaterOrEqualThan'] }

      if(formData.createdDate2){
        params['createdDate.lessOrEqualThan'] = moment(formData.createdDate2).format()
      } else { delete params['createdDate.lessOrEqualThan'] }

      if(formData.lastModifiedDate1){
        params['lastModifiedDate.greaterOrEqualThan'] = moment(formData.lastModifiedDate1).format()
      } else { delete params['lastModifiedDate.greaterOrEqualThan'] }

      if(formData.lastModifiedDate2){
        params['lastModifiedDate.lessOrEqualThan'] = moment(formData.lastModifiedDate2).format()
      } else { delete params['lastModifiedDate.lessOrEqualThan'] }

      this.fetchData(params)
    } catch(e){
      iziToast.info({
        title: "Pencarian",
        message: "Data tidak ditemukan, " + e.message
      })
    }
  }

  defaultSearchForm(){
    const { handleSubmit, submitting, valid, pristine } = this.props;
    return (
      <div className="md-grid" style={{ overflow: 'auto' }}>
        <Field
          label     = 'Nama'
          name      = 'nama'
          className = "md-cell md-cell--6"
          component = {Textfield}
        />
        <Field
          label     = 'Nik'
          name      = 'nik'
          className = "md-cell md-cell--6"
          component = {Textfield}
        />
        <Field
          label     = 'Npwp'
          name      = 'npwp'
          className = "md-cell md-cell--6"
          component = {Textfield}
        />
        <Field
          label     = 'Alamat'
          name      = 'alamat'
          className = "md-cell md-cell--6"
          component = {Textfield}
        />
        <Field
          label     = 'Provinsi'
          name      = 'provinsi'
          className = "md-cell md-cell--6"
          component = {Textfield}
        />
        <Field
          label     = 'Kabupaten'
          name      = 'kabupaten'
          className = "md-cell md-cell--6"
          component = {Textfield}
        />
        <Field
          label     = 'Kecamatan'
          name      = 'kecamatan'
          className = "md-cell md-cell--6"
          component = {Textfield}
        />
        <Field
          label     = 'Kelurahan'
          name      = 'kelurahan'
          className = "md-cell md-cell--6"
          component = {Textfield}
        />
        <Field
          label     = 'Kode Pos'
          name      = 'kodePos'
          className = "md-cell md-cell--6"
          component = {Textfield}
        />
        <Field
          label     = 'No Telepon'
          name      = 'noTelp'
          className = "md-cell md-cell--6"
          component = {Textfield}
        />
        <Field
          label     = 'Dibuat Oleh'
          name      = 'createdBy'
          className = "md-cell md-cell--6"
          component = {Textfield}
        />
        <Field
          label     = 'Diubah Oleh'
          name      = 'lastModifiedBy'
          className = "md-cell md-cell--6"
          component = {Textfield}
        />
        <Field
          label     = 'Dibuat dari'
          name      = 'createdDate1'
          className = "md-cell md-cell--12"
          component = {Datepicker}
        />
        <Field
          label     = 'Dibuat ke'
          name      = 'createdDate2'
          className = "md-cell md-cell--12"
          component = {Datepicker}
        />
        <Field
          label     = 'Diubah dari'
          name      = 'lastModifiedDate1'
          className = "md-cell md-cell--12"
          component = {Datepicker}
        />
        <Field
          label     = 'Diubah ke'
          name      = 'lastModifiedDate2'
          className = "md-cell md-cell--12"
          component = {Datepicker}
        />
        <Button raised primary className="md-cell md-cell--12" onClick={handleSubmit(this.handleSearching.bind(this))}>Cari</Button>
        <hr />
        <Button raised primary className="md-cell md-cell--12" onClick={handleSubmit(this.hapusPencarian.bind(this))}>Hapus</Button>
      </div>
    )
  }
  // yg ditambah

}
