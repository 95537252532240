import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import moment from 'moment';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  CardActions,
  Button,
  LinearProgress
} from 'react-md';

import FormView from '../../../../../components/entity/form.view';
import BP21FService from './BP_21_F.service';
import NomorBPService from '../nomorBP.service';
import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker} from '../../../../../components/form';
import UtilService from '../../../../../services/utils.service'
import SPT21SignerService from '../SPT21Signer/SPT21Signer.service';
import NpwpService from './../../NpwpService';
import FormViewSpt from '../../../../../components/entity/form.view.spt';
import LawanTransaksiDialog from '../../../../../components/entity/LawanTransaksi.dialog';
import MasterLawanTransaksiPpService from '../../../Master/MasterLawanTransaksiPp/MasterLawanTransaksiPp.service';

@reduxForm({form: 'BP_21_F_Form', destroyOnUnmount: true})
@connect((state) => ({
  access: state.entity.access.api.find,
  organization: state.auth.currentOrganization,
  spt: state.auth.currentSPT,
  formData:state.form.BP_21_F_Form
}))
export default class BP21FForm extends FormViewSpt {
  service=BP21FService
  viewType =2;

  typingObj=null;
  typingTimeout=1000;

  initialData={
    bagA: {},
    bagB: {bruto: 0, isBertahap: false, brutoSebelumnya: 0},
    bagC: {}
  }

  constructor(props) {
    super(props);

    this.state= {
      ...this.state,
      showCalculateProgress: false,
      npwpNotValid: true,

      lkDialog: false
    }
  }

  async npwpValidation(evt, value){
    if(this.typingObj) clearTimeout(this.typingObj);

    this.typingObj = setTimeout(async ()=> {
      if(value){
        var res = await NpwpService.api.validate(value)
        if(res.valid){
          this.setState({npwpNotValid: false})

          var lk = await MasterLawanTransaksiPpService.api.page({
            'npwp.equals': value,
            'organizationId.equals': this.props.spt.data.organization.id
          })

          if(lk.data) {
            if(lk.data.length > 0) {
              var d = lk.data[0];
              var formData = {...this.props.formData.values};
              formData.bagA = {
                "npwp" : d.npwp,
                "nik" : d.nik,
                "nama" : d.nama,
                "alamat" : d.alamat,
                "wpLuarNegeri" : d.wpLuarNegeri,
                "kodeNegara" : d.kodeNegara,
                "email" : d.email
              };
            } else {
              formData.bagA = {
                "npwp" : d.value,
              }
              this.toast.warning({title: "Lawan Transaksi", message: `Lawan Transaksi dengan npwp ${value} tidak ditemukan`})
            }
            this.props.initialize(formData);
          }
        } else {
          this.setState({npwpNotValid: true})
          this.toast.warning({title: "Validasi Npwp", message: "npwp tidak valid"})
        }
      }
    }, this.typingTimeout);
  }

  async beforeSave(value) {
    if(value.bagB.bruto == 0 || value.bagB.bruto == '') {
      this.toast.warning({title:'Warning', message:'Isi bruto harus lebih besar dari 0'})
      return false;
    }

    var date;
    if(typeof value.bagC.tanggal === 'string') {
      if(value.bagC.tanggal.search('/') != -1) {
        date = moment(value.bagC.tanggal, 'DD/MM/YYYY');
      } else {
        date = moment(value.bagC.tanggal, 'YYYY-MM-DD');
      }
    } else if(typeof value.bagC.tanggal === 'object') {
      date = moment(value.bagC.tanggal)
    }

    if(!(date.year() == this.props.spt.data.year && date.month()+1 == this.props.spt.data.month)) {
      this.toast.warning({title:'Warning', message:'Tanggal bukti potong harus sesuai dengan masa SPT'})
      return false;
    }


    return true;
  }

  async afterSave(res, val) {
    var formData = this.props.formData.values;

    var data = {
      ...formData.bagA,
      organization: this.props.spt.data.organization
    }

    if(data.id) delete data.id;
    await MasterLawanTransaksiPpService.api.findOrCreate(data)
  }

  async initData() {
    if(this.props.match.params.id == 'new') {
      let nomorBP = await NomorBPService.api.get(this.props.spt.data.organization.id, "BP_21_F")
      // let signer = await SPT21SignerService.api.getSigner(this.props.spt.data.id);

      this.initialData = {
        ...this.initialData,
        no: '1.4-'+UtilService.date.MM(this.props.spt.data.month)+'.'+UtilService.date.YY(this.props.spt.data.year)+'-'+nomorBP.data.no,
        bagC:{
          nama: this.props.organization.name,
          npwp: this.props.organization.npwp,
          tanggal: new Date()
        },
        spt21: this.props.spt.data
      }
      this.props.initialize(this.initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id);
      this.props.initialize(res.data);
      // this.npwpValidation(null, res.data.bagA.npwp)
      this.setState({npwpNotValid: false})
    }
  }

  async calculate() {
    this.setState({showCalculateProgress: true})
    var formData = this.props.formData.values;
    try {
      var param = {
        objekHitungFinal: {
          kodeObjekPajak: formData.bagB.kode,
          golonganPns: formData.bagB.golongan,
          bruto: formData.bagB.bruto,
          grossUp: !(!formData.bagB.grossUp),
          tanpaNpwp: false
        },
        spt21: this.props.spt.data,
        isBertahap: formData.bagB.isBertahap,
        brutoSebelumnya: formData.bagB.brutoSebelumnya,
      }
      param.spt21.orgId = param.spt21.organization.id;

      if(formData.bagA.npwp === '000000000000000' || formData.bagA.npwp === "") param.objekHitungFinal.tanpaNpwp = true;

      var res = await BP21FService.api.calculate(param)

      formData.bagB.tarif = res.tarif;
      formData.bagB.pph = res.pph;
      formData.bagB.bruto = res.bruto;

      this.props.initialize(formData)
    } catch(e) {

    }

    this.setState({showCalculateProgress: false})
  }

  async calculatePph(e,v,pv,f) {
    let form = this.props.formData.values;
    let d    = form.bagB;

    let bruto = parseFloat(d.bruto);
    let tarif = parseFloat(d.tarif);

    if(f.search('bruto') >= 0) {
      bruto = parseFloat(v)
    } else if(f.search('tarif') >= 0) {
      tarif = parseFloat(v)
    }

    if(!bruto) bruto = 0;
    if(!tarif) tarif = 0;

    let pph = Math.floor(bruto * tarif / 100)

    if (d.kode !== '21-401-01' && d.kode !== '21-401-02')
      await this.props.change(`bagB.pph`, pph);
  }

  formView() {
    var formData = {bagB: {}};
    if(this.props.formData && this.props.formData.values) formData = this.props.formData.values
    return (
      <div>

        <LawanTransaksiDialog
          spt={this.props.spt.data}
          onSelect={(d)=> {
            var formData = {...this.props.formData.values};
            if(!d.wpLuarNegeri) d.wpLuarNegeri = false;

            formData.bagA = {
              "npwp" : d.npwp,
              "nik" : d.nik,
              "nama" : d.nama,
              "alamat" : d.alamat,
              "wpLuarNegeri" : d.wpLuarNegeri,
              "kodeNegara" : d.kodeNegara,
              "email" : d.email
            };

            this.setState({npwpNotValid: false})

            this.props.initialize(formData);

          }}
          service={MasterLawanTransaksiPpService}
          visible={this.state.lkDialog}
          onHide={()=> this.setState({lkDialog: false})}
        />
        <Card>
          <div className="md-grid">
            <Field
              label='No.Bukti Potong'
              name='no'
              className="md-cell md-cell--6"
              component={Textfield}
              validate={validation.required}
            />
            <Field
              label='Tanggal Bukti Potong'
              name='bagC.tanggal'
              id="BP21FForm-noBp"
              className="md-cell md-cell--6"
              component={Datepicker}
              validate={validation.required}
            />
            <Field
              label='Referensi'
              name='referensi'
              id="BP21FForm-referensi"
              className="md-cell md-cell--12"
              component={Textfield}
            />
          </div>
        </Card>

        <br/>

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='A. IDENTITAS PENERIMA PENGHASILAN YANG DIPOTONG ' />
          <Divider/>
          <div className="md-grid">
            <div className='md-cell md-cell--6'>
              <div className='mpk-layout'>
                <Field
                  className='flex'
                  label='NPWP'
                  name='bagA.npwp'
                  id="BP21FForm-npwp"
                  component={TextfieldMask}
                  maskFormat="##.###.###.#-###-###"
                  mask="_"
                  length={15}
                  onChange={this.npwpValidation.bind(this)}
                  validate={validation.required}
                />
                <Button icon primary swapTheming style={{marginTop: 10}} onClick={()=> this.setState({lkDialog: true})}>search</Button>
              </div>
              <Field
                label='Nama'
                name='bagA.nama'

                disabled={this.state.npwpNotValid}
                component={Textfield}
                validate={validation.required}
              />
              <Field
                label='NIK'
                name='bagA.nik'

                disabled={this.state.npwpNotValid}
                component={Textfield}
              />
              {/* <Field
                label='Telepon'
                name='name'
                id="BP21FForm-name"
                component={Textfield} */}

            </div>
            <div className='md-cell md-cell--6'>
              {/* <Field
                label='Kode Poss'
                name='npwp'
                id="BP21FForm-codePoss"
                component={Textfield}
              /> */}
              <Field
                label='Alamat'
                name='bagA.alamat'

                disabled={this.state.npwpNotValid}
                component={Textfield}
                validate={validation.required}
              />
              <Field
                label='Email'
                name='bagA.email'

                disabled={this.state.npwpNotValid}
                component={Textfield}
              />
            </div>
          </div>
        </Card>

        <br/>

        {this.state.showCalculateProgress && <LinearProgress id='lin_pro' style={{margin: 0}} />}
        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='B. PPH PASAL 21/26 YANG DIPOTONG' />
          <Divider/>

          <DataTable plain fixedScrollWrapperStyle={{overflow: 'inherit'}} style={{overflow: 'inherit'}}>
            <TableHeader>
              <TableRow>
                <TableColumn>Kode Objek Pajak</TableColumn>
                { formData.bagB.kode === '21-402-01' &&
                  <TableColumn>Golongan</TableColumn>
                }
                <TableColumn className='md-text-right'>Bruto(Rp)</TableColumn>
                <TableColumn className='md-text-right'>Tarif(%)</TableColumn>
                <TableColumn className='md-text-right'>PPH Di Potong(Rp)</TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableColumn>
                  <Field
                    label='Objek Pajak'
                    name='bagB.kode'
                    id="BP21FForm-codePoss"
                    component={Searchfield}
                    options={[
                      {id: '21-401-01', name:'21-401-01 (Uang Pesangon yang Dibayarkan Sekaligus)'},
                      {id: '21-401-02', name:'21-401-02 (Uang Manfaat Pensiun, Tunjangan Hari Tua, atau Jaminan Hari Tua yang Dibayarkan Sekaligus)'},
                      {id: '21-402-01', name:'21-402-01 (Honor dan Imbalan Lain yang Dibebankan kepada APBN atau APBD yang Diterima oleh PNS, Anggota TNI/POLRI, Pejabat Negara dan Pensiunannya)'},
                      {id: '21-499-99', name:'21-499-99 (Objek PPh Pasal 21 Final Lainnya)'}]}
                    validate={validation.required}
                  />
                </TableColumn>
                {formData.bagB.kode === '21-402-01' &&
                  <TableColumn>
                    <Field
                      label='Golongan'
                      name='bagB.golongan'
                      id="BP21FForm-golongan"
                      component={Searchfield}
                      options={[
                        {id: 'I', name: 'I'},
                        {id: 'II', name: 'II'},
                        {id: 'III', name: 'III'},
                        {id: 'IV', name: 'IV'}
                      ]}
                      validate={validation.required}
                    />
                  </TableColumn>
                }
                <TableColumn>
                  <Field
                    label='Bruto'
                    name='bagB.bruto'
                    component={TextfieldMask}
                    money={','}
                    validate={validation.required}
                    onChange  = {(e, v, vp, f) => this.calculatePph(e, v, vp, f)}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    label='Tarif'
                    name='bagB.tarif'
                    disabled={formData.bagB.kode !== '21-499-99'}
                    component={Textfield}
                    onChange  = {(e, v, vp, f) => this.calculatePph(e, v, vp, f)}

                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    label='PPH'
                    name='bagB.pph'
                    component={TextfieldMask}
                    money={','}
                    disabled={formData.bagB.kode !== '21-499-99'}

                  />
                </TableColumn>
              </TableRow>

              {(formData.bagB.kode === '21-401-01' || formData.bagB.kode === '21-401-02') &&
                <TableRow>
                  <TableColumn>
                    <Field
                      label='Bertahap dalam jangka waktu paling lama 2 tahun'
                      name='bagB.isBertahap'
                      component={Switch}
                    />
                  </TableColumn>

                  {formData.bagB.isBertahap &&
                    <TableColumn>
                      <Field
                        label='Penghasilan bruto yang telah dibayarkan sebelumnya'
                        name='bagB.brutoSebelumnya'
                        component={TextfieldMask}
                        money={','}
                      />
                    </TableColumn>
                  }
                </TableRow>
              }
            </TableBody>
          </DataTable>

          {formData.bagB.kode !== '21-499-99' &&
          <CardActions className='md-divider-border md-divider-border--top mpk-layout'>
            <Field
              label='Gross Up'
              name='bagB.grossUp'
              component={Switch}
            />
            <div className='flex' />
            <Button flat secondary onClick={()=> this.calculate()}>Calculate</Button>
          </CardActions>
          }
        </Card>

        <br />

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='C. IDENTITAS PEMOTONG ' />
          <Divider/>

          <div className="md-grid">
          <Field
            label='NPWP'
            name='bagC.npwp'
            className="md-cell md-cell--6"
            disabled
            component={TextfieldMask}
            maskFormat="##.###.###.#-###-###"
            mask="_"
            validate={validation.required}
          />
          <Field
            label='Nama'
            name='bagC.nama'
            className="md-cell md-cell--6"
            disabled
            component={Textfield}
            validate={validation.required}
          />
          </div>
        </Card>

      </div>
    )
  }

}
